import React, { useLayoutEffect, useState } from "react";
import "./PO_Pricing.css";
import { Plans } from "./Data";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import PleaseNoteDisclaimer from "../../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";
import { withRouter } from "react-router-dom";

function PO_Pricing() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  return (
    <div className="popricing-container">
      <PageBanner title="Property Owner Pricing" />
      <div className="header">
        <p className="title">
          <b>Explore Our Property Requirement Features</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Kindly select the choice of action as per your preferences from the following features
        </p>
      </div>
      <div id="pricing-plans" className="pricing-plans">
        {Plans.map((plan, index) => {
          return (
            <div
              style={{
                width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
              }}
              key={index}
              id={index}
              className="plan"
            >
              <p className="title">
                <b>{plan.Name}</b>
              </p>
              <p className="price">
                <b>{plan.Price}</b>
              </p>
              <br />
              <div className="features">
                <ul>
                  {plan.Features.map((feature, index) => {
                    return (
                      <li key={index}>
                        <i className="fas fa-check"></i>
                        <p className="feature">{feature}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="btn">
                <a href={plan.Route}>
                <button className="pricing-btn">
                  Select
                </button>
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <br />
      <br />

        <PleaseNoteDisclaimer />

    </div>
  );
}

export default withRouter(PO_Pricing);
