import Axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { DeleteUploadProjectImageDetails, SP_UpdatePreviouslyUploadedProjects } from "../../../../../../../../../_actions/project_actions";

import LoadingIcon from "../../../../../../../../utils/LoadingIcon/LoadingIcon";
import Carousel from "./Carousel/Carousel";

import "./EditProject.css";
import UploadedProjectModal from "./UploadedProjectModal/UploadedProjectModal";

const stateData = [
  "Delhi",
  "Gujarat",
  "Karnataka",
  "Maharashtra",
  "National Capital Region (NCR)",
  "Tamil Nadu",
  "Telangana",
  "West Bengal",
];
const cityData = [
  {
    State: "Karnataka",
    Cities: ["Bangalore"],
  },
  {
    State: "Gujarat",
    Cities: ["Ahmedabad"],
  },
  {
    State: "Tamil Nadu",
    Cities: ["Chennai"],
  },
  {
    State: "Delhi",
    Cities: ["Delhi"],
  },
  {
    State: "National Capital Region (NCR)",
    Cities: [
      "Faridabad",
      "Ghaziabad",
      "Greater Noida",
      "Gurgaon",
      "Meerut",
      "Noida",
      "Panipat",
      "Sonipat",
    ],
  },
  {
    State: "Telangana",
    Cities: ["Hyderabad"],
  },
  {
    State: "West Bengal",
    Cities: ["Kolkata"],
  },
  {
    State: "Maharashtra",
    Cities: ["Mumbai", "Pune"],
  },
];
function EditProject(props) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);

  // console.log("Props Project Details", props.ProjectDetails);

  const [Images, setImages] = useState(props.ProjectDetails.Images);
  const [ProjectName, setProjectName] = useState(
    props.ProjectDetails.Project_Name
  );
  const [ClientName, setClientName] = useState(
    props.ProjectDetails.Project_Client_Name
  );
  const [ProjectCompletion, setProjectCompletion] = useState(
    props.ProjectDetails.Project_Completion
  );
  const [ProjectValue, setProjectValue] = useState(
    props.ProjectDetails.Project_Value
  );
  const [ProjectLink, setProjectLink] = useState(
    props.ProjectDetails.Project_Page_Link
  );
  const [Description, setDescription] = useState(
    props.ProjectDetails.Project_Description
  );
  const [ServiceRequirement, setServiceRequirement] = useState(
    props.ProjectDetails.Service_Requirement
  );
  const [ProjectType, setProjectType] = useState(
    props.ProjectDetails.Project_Type
  );
  const [ProjectTypeSubcategory1, setProjectTypeSubcategory1] = useState(
    props.ProjectDetails.Project_Type_Subcategory_1
  );
  const [ProjectTypeSubcategory2, setProjectTypeSubcategory2] = useState(
    props.ProjectDetails.Project_Type_Subcategory_2
  );
  const [ProjectArchitectureStyle, setProjectArchitectureStyle] = useState(
    props.ProjectDetails.Project_Architecture_Style
  );
  const [HouseName, setHouseName] = useState(
    props.ProjectDetails.Address.House_Building_Plot_Land
  );

  const [Areaname, setAreaname] = useState(
    props.ProjectDetails.Address.Areaname
  );

  const [State, setState] = useState(props.ProjectDetails.Address.State);
  const [City, setCity] = useState(props.ProjectDetails.Address.City);
  const [cities, setcities] = useState([]);

  const [ImageDetails, setImageDetails] = useState(
    props.ProjectDetails.Imagedetails
  );

  const [savecontinueclicked, setsavecontinueclicked] = useState(false);

  // const [loading, setloading] = useState(false);
  // console.log(props.ProjectDetails.Imagedetails);
  useEffect(() => {
    setcities(
      cityData.filter((cities) => cities.State === State).length > 0
        ? cityData.filter((cities) => cities.State === State)[0].Cities
        : []
    );
  }, [State]);

  const onDrop = (files) => {
    // console.log("Form", files);
    const config = {
      header: { "content-type": "multipart/form-data" },
    };

    files.map(async (index) => {
      let formData = new FormData();
      formData.append("file", index);
      try {
        const response = await Axios.post(
          "/api/project/uploadProjects",
          formData,
          config
        );
        // console.log(response.data.data.Location);
        setImages((prev) => {
          return [...prev, response.data.data.Location];
        });
        setImageDetails((prev) => {
          return [
            ...prev,
            {
              Id: response.data.data.Location.slice(
                response.data.data.Location.length / 2,
                response.data.data.Location.length
              ),
              Image: response.data.data.Location,
              Title: "",
              Description: "",
              Category: [],
            },
          ];
        });
      } catch (error) {
        alert("Fail to save the images");
      }
    });
  };

  // useLayoutEffect(() => {
  // window.scrollTo(0, 0);
  // })

  const onDelete = (image, e) => {
    const imgtoremove = e.currentTarget.parentElement;
    imgtoremove.style.bottom = "100px";
    imgtoremove.style.opacity = "0";
    imgtoremove.style.visibility = "hidden";

    // setTimeout(() => {
    // const currentIndex = Images.indexOf(image);
    // let newImages = [...Images];
    // newImages.splice(currentIndex, 1);
    setTimeout(() => {
      setImages(Images.filter((img) => img !== image));
      imgtoremove.style.bottom = "0px";
      imgtoremove.style.opacity = "1";
      imgtoremove.style.visibility = "visible";
    }, 350);
    // Updating Images After Deletion
    setImageDetails(
      ImageDetails.filter((imagedetail) => imagedetail.Image !== image)
    );
    // console.log("Remove", image);
    // console.log("Image Removed", image.slice(50));
    const variable = {
      projectId: props.ProjectDetails._id,
      imageKey: image.slice(61),
      imageAddress: image,
    };
    dispatch(DeleteUploadProjectImageDetails(variable)).then((response) => {
      // console.log("Response After delete", response.payload.projectDetails);
    });
    // }, 300);
  };

  useEffect(() => {
    // console.log(ImageDetails);
  }, [ImageDetails]);

  const onHandleProjectName = (e) => {
    // console.log(e.target.value);
    setProjectName(e.target.value);
  };

  const onHandleProjectClientName = (e) => {
    // console.log(e.target.value);
    setClientName(e.target.value);
  };

  const onHandleProjectCompletion = (e) => {
    // console.log(e.target.value);
    setProjectCompletion(e.target.value);
  };

  const onHandleProjectValue = (e) => {
    // console.log(e.target.value);
    setProjectValue(e.target.value);
  };

  const onHandleWebsite = (e) => {
    // console.log(e.target.value);
    setProjectLink(e.target.value);
  };

  const onHandleProjectDescription = (e) => {
    // console.log(e.target.value);
    setDescription(e.target.value);
  };

  const onHandleProjectType = (e) => {
    // console.log(e.target.value);
    setProjectType(e.target.value);
  };

  const OnHandleHouseNameChange = (e) => {
    // console.log(e.target.value);
    setHouseName(e.target.value);
  };

  const OnHandleAreaNameChange = (e) => {
    // console.log(e.target.value);
    setAreaname(e.target.value);
  };
  const [details, setdetails] = useState();

  const handelist = (e) => {
    const list = e.currentTarget.nextSibling;
    const heading = e.currentTarget;
    list.style.transform = "scale(1)";
    if (list.style.opacity === "0") {
      list.style.transform = "scale(1)";
      list.style.opacity = "1";
      heading.childNodes[1].innerText = "expand_less";
    } else {
      list.style.transform = "scale(0)";
      list.style.opacity = "0";
      heading.childNodes[1].innerText = "expand_more";
    }
  };

  const handlelistitem = (e) => {
    const list = e.currentTarget.parentElement;
    const listitem = e.currentTarget;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0];

    list.childNodes.forEach((e) => {
      e.style.color = "black";
      e.style.backgroundColor = "rgb(226, 225, 225)";
    });
    heading.childNodes[0].innerText = listitem.innerText;
    list.style.transform = "scale(0)";
    heading.childNodes[1].innerText = "expand_more";
    list.style.opacity = 0;
    listitem.style.color = "white";
    listitem.style.backgroundColor = "#fe5631";

    if (list.id === "project-service-requirement") {
      setServiceRequirement(listitem.innerText);
    }
    if (
      list.id === "interior-project-type" ||
      list.id === "construction-project-type"
    ) {
      setProjectTypeSubcategory1(listitem.innerText);
    }
    if (
      list.id === "residential-property-type" ||
      list.id === "commercial-property-type"
    ) {
      setProjectTypeSubcategory2(listitem.innerText);
    }
    if (list.id === "architectural-styles") {
      setProjectArchitectureStyle(listitem.innerText);
    }
    if (list.id === "state-list") {
      setState(listitem.innerText);
      setCity(cityData[listitem.innerText]);
      resetvalues(e);
    }
    if (list.id === "city-list") {
      setCity(listitem.innerText);
    }
  };
  // RESETTING VALUES
  const resetvalues = (e) => {
    const heading =
      e.currentTarget.parentElement.parentElement.parentElement.nextSibling
        .childNodes[1].childNodes[0].childNodes[0];
    heading.innerText = "Select";
    const items = heading.parentElement.nextSibling.childNodes;
    items.forEach((item) => {
      item.style.color = "black";
      item.style.backgroundColor = "rgb(226, 225, 225)";
    });
  };

  const handleclosemodal = (e) => {
    setsavecontinueclicked(false);
  };

  const OnHandleSubmitButton = (ImgDetails) => {
    const variables = {
      Project_Name: ProjectName,
      Project_Client_Name: ClientName,
      Project_Completion: ProjectCompletion,
      Project_Value: ProjectValue,
      Project_Page_Link: ProjectLink,
      Project_Description: Description,
      Service_Requirement: ServiceRequirement,
      Project_Type: ProjectType,
      Project_Type_Subcategory_1: ProjectTypeSubcategory1,
      Project_Type_Subcategory_2: ProjectTypeSubcategory2,
      Project_Architecture_Style: ProjectArchitectureStyle,
      House_Building_Plot_Land: HouseName,
      State: State,
      City: City,
      Areaname: Areaname,
      Images: Images,
      ImageDetails: ImgDetails,
    };
    // console.log("Data To Submit ", variables);
    dispatch(
      SP_UpdatePreviouslyUploadedProjects(props.ProjectDetails._id, variables)
    ).then((response) => {
      // console.log("Response ", response);
      if (response.payload.success) {
        props.history.push(`/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/${props.ProjectID}`)
        // alert("Successfully Updated Project Details");
        setsavecontinueclicked(false);
      } else {
        alert("Failed in Updating Project Details");
      }
    });
  };

  // useEffect(() => {
  //   ImageDetails.forEach(ImageDetail => {
  //     Images.push(ImageDetail.Image)
  //   })
  // },[])

  if (company.companyData && company.companyData.isAuth) {
    return (
      <div className="sp-upload-project-container">
        {Images.length > 0 ? (
          <Carousel
            NotArrows={false}
            widthtomove={100}
            Auto={false}
            Images={Images}
          />
        ) : null}
        <UploadedProjectModal
          backgroundcolor={
            savecontinueclicked
              ? "rgba(132, 132, 132, 0.5)"
              : "rgba(132, 132, 132, 0)"
          }
          visibility={savecontinueclicked ? "visible" : "hidden"}
          opacity={savecontinueclicked ? 1 : 0}
          transform={savecontinueclicked ? "scale(1)" : "scale(0.5)"}
          handleclosemodal={handleclosemodal}
          details={details}
          Imagedetails={ImageDetails}
          OnHandleSubmitButton={OnHandleSubmitButton}
        />

        {/* <h2 className="upload-project-title">Upload New Project</h2> */}
        <div className="upload-project-details-container">
          {/* Project Details */}
          <p className="upload-project-details-heading">Project Details</p>
          <div className="upload-project-detail">
            <div className="detail">
              <label className="detail-title">Project Name</label>
              <input
                className="detail-input"
                onChange={onHandleProjectName}
                defaultValue={ProjectName}
              />
            </div>
            <div className="detail">
              <label className="detail-title">Project Client Name</label>
              <input
                className="detail-input"
                defaultValue={ClientName}
                onChange={onHandleProjectClientName}
              />
            </div>
            <div className="detail">
              <label className="detail-title">Project Year of Completion</label>
              <input
                className="detail-input"
                type="text"
                defaultValue={ProjectCompletion}
                onChange={onHandleProjectCompletion}
              />
            </div>
            <div className="detail">
              <label className="detail-title">
                Project Value in Indian Rupee
              </label>
              <input
                className="detail-input"
                defaultValue={ProjectValue}
                onChange={onHandleProjectValue}
              />
            </div>
            <div className="detail">
              <label className="detail-title">
                Project Page link to Company Website / Company YouTube Channel
                URL
              </label>
              <input
                className="detail-input"
                defaultValue={ProjectLink}
                onChange={onHandleWebsite}
              />
            </div>
            <div className="detail">
              <label className="detail-title">Project Description</label>
              <textarea
                row={5}
                defaultValue={Description}
                onChange={onHandleProjectDescription}
              />
            </div>
          </div>
          <br />

          {/* Project Service, Type & Style */}

          <p className="upload-project-details-heading">
            Project Service, Type & Style
          </p>
          <div className="upload-project-detail">
            <div className="detail">
              <label className="detail-title">
                Project Service Requirement
              </label>
              <ul className="detail-select-list-container">
                <li onClick={handelist} className="selected-heading">
                  <p className="heading">
                    {ServiceRequirement ? ServiceRequirement : "Select"}
                  </p>
                  <span class="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="project-service-requirement"
                  className="detail-select-list"
                >
                  <li onClick={handlelistitem}>Only Designs</li>
                  <li onClick={handlelistitem}>Only Execution</li>
                  <li onClick={handlelistitem}>Both - Designs & Execution</li>
                </ul>
              </ul>
            </div>

            <div className="detail">
              <div className="detail-titles-container">
                <label className="detail-title">Project Type & Style</label>
                <label id="project-styles-title" className="detail-title">
                  {ProjectType === "Interior"
                    ? "Project Interior Style"
                    : ProjectType === "Construction"
                    ? "Project Architecture Style"
                    : null}
                </label>
              </div>

              <br />
              <div className="radio-inputs">
                <div className="inputs">
                  <input
                    type="radio"
                    name="projecttype"
                    value="Interior"
                    defaultChecked={ProjectType === "Interior" ? true : false}
                    onChange={onHandleProjectType}
                  />
                  &nbsp; Interior &nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="projecttype"
                    defaultChecked={
                      ProjectType === "Construction" ? true : false
                    }
                    value="Construction"
                    onChange={onHandleProjectType}
                  />
                  &nbsp; Construction&nbsp;
                </div>
                <div className="select-inputs">
                  {ProjectType === "Interior" ? (
                    <>
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">
                            {ProjectTypeSubcategory1
                              ? ProjectTypeSubcategory1
                              : "Select"}
                          </p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="interior-project-type"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Residential</li>
                          <li onClick={handlelistitem}>Commercial</li>
                        </ul>
                      </ul>
                      {/* <select onChange={onHandleProjectTypeSubcategort1}>
                      <option selected disabled>
                        select
                      </option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> */}
                    </>
                  ) : null}
                  {ProjectType === "Construction" ? (
                    <>
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">
                            {ProjectTypeSubcategory1
                              ? ProjectTypeSubcategory1
                              : "Select"}
                          </p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="construction-project-type"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Residential</li>
                          <li onClick={handlelistitem}>Commercial</li>
                        </ul>
                      </ul>
                      {/* <select onChange={onHandleProjectTypeSubcategort1}>
                      <option selected disabled>
                        select
                      </option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> */}
                    </>
                  ) : null}
                  {ProjectTypeSubcategory1 === "Residential" ? (
                    <>
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">
                            {ProjectTypeSubcategory2
                              ? ProjectTypeSubcategory2
                              : "Select"}
                          </p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="residential-property-type"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Apartment</li>
                          <li onClick={handlelistitem}>Penthouse</li>
                          <li onClick={handlelistitem}>Stand Alone Building</li>
                          <li onClick={handlelistitem}>Independent Home</li>
                          <li onClick={handlelistitem}>Villa</li>
                          <li onClick={handlelistitem}>Bungalow</li>
                        </ul>
                      </ul>
                      {/* <select onChange={onHandleProjectTypeCatrgory2Change}>
                      <option selected disabled>
                        select
                      </option>
                      <option value="Apartment">Apartment</option>
                      <option value="Penthouse">Penthouse</option>
                      <option value="Stand Alone Building">
                        Stand Alone Building
                      </option>
                      <option value="Independent Home">Independent Home</option>
                      <option value="Villa">Villa</option>
                      <option value="Bungalow">Bungalow</option>
                    </select> */}
                    </>
                  ) : null}
                  {ProjectTypeSubcategory1 === "Commercial" ? (
                    <>
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">
                            {ProjectTypeSubcategory2
                              ? ProjectTypeSubcategory2
                              : "Select"}
                          </p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="commercial-property-type"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Retail</li>
                          <li onClick={handlelistitem}>Hospitality</li>
                          <li onClick={handlelistitem}>Healthcare</li>
                          <li onClick={handlelistitem}>
                            {" "}
                            Office – Corporate and Tech
                          </li>
                          <li onClick={handlelistitem}>Institutional</li>
                          <li onClick={handlelistitem}>Hospitality</li>
                          <li onClick={handlelistitem}>Industrial</li>
                        </ul>
                      </ul>
                      {/* <select onChange={onHandleProjectTypeCatrgory2Change}>
                      <option selected disabled>
                        select
                      </option>
                      <option value="Retail">Retail</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Office – Corporate and Tech">
                        Office – Corporate and Tech
                      </option>
                      <option value="Institutional">Institutional</option>
                      <option value="Industrial">Industrial</option>
                    </select> */}
                    </>
                  ) : null}
                  <label id="last-select-list-title" className="detail-title">
                    {" "}
                    {ProjectType === "Interior"
                      ? "Project Interior Style"
                      : ProjectType === "Construction"
                      ? "Project Architecture Style"
                      : null}
                  </label>
                  {ProjectType === "Interior" ? (
                    <ul className="detail-select-list-container">
                      <li onClick={handelist} className="selected-heading">
                        <p className="heading">
                          {ProjectArchitectureStyle
                            ? ProjectArchitectureStyle
                            : "Select"}
                        </p>
                        <span class="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="architectural-styles"
                        className="detail-select-list"
                      >
                        <li onClick={handlelistitem}>Asian</li>
                        <li onClick={handlelistitem}>Beach / Nautical</li>
                        <li onClick={handlelistitem}>
                          Contemporary Home Décor
                        </li>

                        <li onClick={handlelistitem}>Craftsman</li>
                        <li onClick={handlelistitem}>Eclectic</li>
                        <li onClick={handlelistitem}>Eco - Friendly</li>
                        <li onClick={handlelistitem}>Farmhouse</li>
                        <li onClick={handlelistitem}>Hollywood Glam</li>
                        <li onClick={handlelistitem}>Industrial</li>
                        <li onClick={handlelistitem}>Mediterranean</li>
                        <li onClick={handlelistitem}>Mid – Century Modern</li>
                        <li onClick={handlelistitem}>Minimalist</li>
                        <li onClick={handlelistitem}>Modern</li>
                        <li onClick={handlelistitem}>Rustic</li>
                        <li onClick={handlelistitem}>Scandinavian</li>
                        <li onClick={handlelistitem}>Shabby – Chic</li>
                        <li onClick={handlelistitem}>Southwestern</li>
                        <li onClick={handlelistitem}>Traditional</li>
                        <li onClick={handlelistitem}>Transitional</li>
                        <li onClick={handlelistitem}>Tropical</li>
                      </ul>
                    </ul>
                  ) : null}
                  {ProjectType === "Construction" ? (
                    <ul className="detail-select-list-container">
                      <li onClick={handelist} className="selected-heading">
                        <p className="heading">
                          {ProjectArchitectureStyle
                            ? ProjectArchitectureStyle
                            : "Select"}
                        </p>
                        <span class="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        className="detail-select-list"
                      >
                        <li onClick={handlelistitem}>Art Deco</li>
                        <li onClick={handlelistitem}>Bauhaus</li>
                        <li onClick={handlelistitem}>Constructivism (Art)</li>
                        <li onClick={handlelistitem}>
                          Constructivist Architecture
                        </li>
                        <li onClick={handlelistitem}>
                          Contemporary Architecture
                        </li>
                        <li onClick={handlelistitem}>
                          Contemporary Architecture
                        </li>
                        <li onClick={handlelistitem}>Functionalism</li>
                        <li onClick={handlelistitem}>Futurist Architecture</li>
                        <li onClick={handlelistitem}>
                          High - Tech Architecture
                        </li>
                        <li onClick={handlelistitem}>
                          Industrial Architecture
                        </li>
                        <li onClick={handlelistitem}>International Style</li>
                        <li onClick={handlelistitem}>Mid - Century Modern</li>
                        <li onClick={handlelistitem}>Minimalism</li>
                        <li onClick={handlelistitem}>Modern Architecture</li>
                        <li onClick={handlelistitem}>New Objectivity</li>
                        <li onClick={handlelistitem}>Organic Architecture</li>
                        <li onClick={handlelistitem}>
                          Postmodern Architecture
                        </li>
                        <li onClick={handlelistitem}>Ranch - Style House</li>
                        <li onClick={handlelistitem}>Streamline Modern</li>
                        <li onClick={handlelistitem}>
                          Sustainable Architecture
                        </li>
                      </ul>
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* Address */}
          <p className="upload-project-details-heading">Address</p>
          <div className="upload-project-detail">
            <div className="detail">
              <label className="detail-title">
                House Name / Building Name / Plot Name / Land Name (Property
                Name)
              </label>
              <input
                className="detail-input"
                type="text"
                defaultValue={HouseName}
                onChange={OnHandleHouseNameChange}
              />
            </div>
            <div className="detail">
              <label className="detail-title">State</label>
              <ul className="detail-select-list-container">
                <li onClick={handelist} className="selected-heading">
                  <p className="heading">{State ? State : "Select"}</p>
                  <span class="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="state-list"
                  className="detail-select-list"
                >
                  {stateData.map((state, index) => {
                    return (
                      <li key={index} onClick={handlelistitem}>
                        {state}
                      </li>
                    );
                  })}
                </ul>
              </ul>
              {/* <select
            defaultValue={stateData[0]}
            onChange={onHandleStateChange}
            style={{ width: 120 }}
          >
            {stateData.map((index) => (
              <option key={index}>{index}</option>
            ))}
          </select> */}
            </div>
            <div className="detail">
              <label className="detail-title">City</label>
              {/* <input 
                                          type='text'
                                      /> */}
              <ul className="detail-select-list-container">
                <li onClick={handelist} className="selected-heading">
                  <p className="heading">{City ? City : "Select"}</p>
                  <span class="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="city-list"
                  className="detail-select-list"
                >
                  {cities.map((city, index) => (
                    <li onClick={handlelistitem} key={index}>
                      {city}
                    </li>
                  ))}
                </ul>
              </ul>
              {/* <select onChange={onHandleCityNameChange}>
                  {City.map((index) => (
                    <option key={index}>{index}</option>
                  ))}
                </select> */}
            </div>
            <div className="detail">
              <label className="detail-title">Area Name</label>
              <input
                className="detail-input"
                type="text"
                defaultValue={Areaname}
                onChange={OnHandleAreaNameChange}
              />
            </div>
          </div>
          {/* Upload Images */}
          <br />
          <p className="upload-project-details-heading">Upload Images</p>
          <div className="upload-and-images-container">
            <div className="upload-btn">
              <Dropzone onDrop={onDrop} multiple={true} maxSize={800000000000}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {/* <Icon type="plus" style={{ fontSize: '3rem'}} /> */}
                    <span class="material-icons">add_box</span>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="images-container">
              {Images.map((img, index) => {
                return (
                  <div key={index} className="uploaded-img">
                    <img src={img} alt="uploaded-img" />
                    <span
                      onClick={(e) => {
                        onDelete(img, e);
                      }}
                      className="material-icons"
                    >
                      remove
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          <br />
          <button
            className="save-and-continue-btn"
            style={{
              pointerEvents: Images.length > 0 ? "auto" : "none",
              opacity: Images.length > 0 ? 1 : 0.5,
            }}
            onClick={() => {
              setsavecontinueclicked(true);
            }}
            type="primary"
          >
            Proceed
          </button>
        </div>
        <br />

        {/* <div
            style={{
              display: "flex",
              width: "100%",
              height: "240px",
              overflowX: "scroll",
            }}
          >
            {Images.map((image, index) => (
              <div key={index} onClick={() => onDelete(image)}>
                <Row gutter={[16, 16]}>
                  <Col lg={8}>
                    <img
                      key={image}
                      width={200}
                      src={image}
                      alt={`productImg-${index}`}
                    />
                  </Col>
                </Row>
              </div>
            ))}
          </div> */}
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}

export default withRouter(EditProject);
