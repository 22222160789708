import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DownloadLeadPDF } from "../../../../../_actions/company_actions";
// import { SP_UpdateSelectedLeadStatus } from "../../../../../_actions/company_analytics";
import "./Utils.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LeadsInvoice from "../../../ServiceProfessional/LeadsInvoice/LeadsInvoice";

function LeadsSelected(props) {
  // console.log("Business Profile Productivity", props.LeadsDetails);
  const dispatch = useDispatch();

  // const ChangeStatusOfLead = (LeadId, StatusToChange, OrderId) => {
  //   console.log("Lead Id ", LeadId);
  //   var variables = {
  //     LeadId: LeadId,
  //     StatusToChange: StatusToChange,
  //     OrderId: OrderId,
  //   };
  //   dispatch(SP_UpdateSelectedLeadStatus(variables)).then((response) => {
  //     console.log("Response ", response);
  //     window.location.reload();
  //   });
  // };

  const [invoicedetail, setinvoicedetail] = useState({
    UserDetails: {
      EmailId: "",
      PhoneNumber: 0,
      ProfileNumber: ""
    },
    PersonalDetails: {
      PropertyOwnerName: "",
      AlternativeEmailId: "",
      AlternativePhoneNumber: 0,
      PersonalWhatsappNumber: 0,
    },
    Address: {
      HouseName: "",
      State: "",
      City: "",
      AreaName: "",
      Pincode: "",
    },
    ServiceTypeRequired: {
      ServiceType: "",
      PropertyType: "",
      PropertyStatus: "",
      ServiceRequirement: "",
    },
    TimeFrame: "",
    PropertyInformation: {
      PropertyRequirementNumber: "",
      PropertyDimensionType: "",
      PropertyArea: "",
      PropertyCondition: "",
    },
    Budget: "",
    PropertyRequirements: "",
  });

  const [changed, setchanged] = useState(false);

  const DownloadLeadDetails = async (ProductId, UserID) => {
    // console.log("Download Leads ", ProductId, UserID);
    let variables = {
      LeadId: ProductId,
      UserID: UserID,
    };
    dispatch(DownloadLeadPDF(variables)).then((response) => {
      // console.log("Response ", response);
      const result = response.payload.result
      // console.log("Result ", result);
      setinvoicedetail({

        UserDetails: {
          EmailId: result.userDetails.email,
          PhoneNumber: result.userDetails.phonenumber,
          ProfileNumber: result.userDetails._id
        },
        PersonalDetails: {
          PropertyOwnerName: result.userDetails.name,
          AlternativeEmailId: result.userDetails.Personal_Details.Alternative_Email_Id,
          AlternativePhoneNumber: result.userDetails.Personal_Details.Alternative_Phone_Number,
          PersonalWhatsappNumber: result.userDetails.Personal_Details.Personal_WhatsApp_Number,
        },
        Address: {
          HouseName: result.productDetails.Land_Building,
          State: result.productDetails.State,
          City: result.productDetails.City,
          AreaName: result.productDetails.Area_Name,
          Pincode: result.productDetails.Pin_Code,
        },
        ServiceTypeRequired: {
          ServiceType: result.productDetails.Project_Type,
          PropertyType: result.productDetails.BuildingType,
          PropertyStatus: result.productDetails.Status_Of_Project,
          ServiceRequirement: result.productDetails.Service_Type_Required,
        },
        TimeFrame: result.productDetails.Beginning_The_Project,
        PropertyInformation: {
          PropertyRequirementNumber: result.productDetails._id,
          PropertyDimensionType: result.productDetails.Dimension_Type,
          PropertyArea: result.productDetails.Project_Area,
          PropertyCondition: result.productDetails.Property_Condition,
        },
        Budget: result.productDetails.Budget,
        PropertyRequirements: result.productDetails.description,
      })
      setchanged(true)
    })
  }

  useEffect(() => {
    if(changed) {
      handledownloadpdf()
      setchanged(false);
    }
  }, [changed])

  const handledownloadpdf = () => {

    // setdata(e).then(() => {
    // console.log("set");
    const invoicecontainer = document.getElementById(
      "leads-invoice-container"
    );
    invoicecontainer.style.display = "block";
    html2canvas(document.getElementById("leads-invoice-container"), {
      useCORS: true,
      scale: 1,
    }).then((canvas) => {
      const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);
      // console.log(canvas.width)
      // console.log(canvas.height)
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      // console.log(width)
      // console.log(height)
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("download.pdf");
      invoicecontainer.style.display = "none";
    });
    // });
  };

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2}>
            Sl. No.{" "}
          </td>
          <td className="heading" rowSpan={2}>
            Lead Selection Order ID{" "}
          </td>
          <td className="heading" colSpan={2}>
            Property Owner
          </td>
          <td className="heading" colSpan={2}>
            Property Requirement{" "}
          </td>
          <td className="heading" rowSpan={2}>
            {props.dataandtimeheading ? props.dataandtimeheading : null}
          </td>

          {props.thisleadwas ? (
            <td className="heading" rowSpan={2} colSpan={1}>
              This lead was
            </td>
          ) : null}

          <td className="heading" rowSpan={2}>
            State
          </td>
          <td className="heading" rowSpan={2}>
            City
          </td>
          <td className="heading" colSpan={2}>
            Download
          </td>
        </tr>
        <tr>
          <td className="subheading">Profile Number</td>
          <td className="subheading">Name</td>
          <td className="subheading">Property Number</td>
          <td className="subheading">Property Name</td>
          {/* <td className="subheading">Invoice</td> */}
          <td className="subheading">Lead Details</td>
        </tr>
        {props.LeadsDetails.map((listanddetail, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {/* <td>{listanddetail.id}</td> */}
              <td>{listanddetail.OrderID}</td>
              {/* <td>{listanddetail.writer}</td> */}
              <td>
                <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.writer}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>View Details</a>
              </td>
              <td>
              <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.writer}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
              {listanddetail.name}
              </a>
              </td>
              <td>
              <a href={`/ServiceProfessional/PropertyRequirementsDetails/${listanddetail.id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
              {listanddetail.id}
              </a>
              </td>
              <td>{listanddetail.Land_Building}</td>
              <td>{listanddetail.timeOfPurchase}</td>
              {/* <td>{listanddetail.LeadStatus}</td> */}
              {listanddetail.LeadStatus === "UnKnown" ? (
                <td>
                  <button
                  className="converted-btn"
                    style={{ backgroundColor: "green", color: "white" }}
                    // onClick={() =>
                    //   ChangeStatusOfLead(
                    //     listanddetail.id,
                    //     "Converted",
                    //     listanddetail.OrderID
                    //   )
                    // }
                    onClick={()=> props.ChangeofLeadStatus(listanddetail.id, "Converted", listanddetail.OrderID)}
                  >
                    Converted
                  </button>
                  <button
                  className="converted-btn"
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={()=> props.ChangeofLeadStatus(listanddetail.id, "Rejected", listanddetail.OrderID)}
                    // onClick={() =>
                    //   ChangeStatusOfLead(
                    //     listanddetail.id,
                    //     "Rejected",
                    //     listanddetail.OrderID
                    //   )
                    // }
                  >
                    Rejected
                  </button>
                </td>
              ) : (
                <>
                  {listanddetail.LeadStatus === "Converted" ? (
                    <td style={{ color: "green" }}>Converted</td>
                  ) : (
                    <td style={{ color: "red" }}>Rejected</td>
                  )}
                </>
              )}
              <td>{listanddetail.State}</td>
              <td>{listanddetail.City}</td>
              {/* <td>Download</td> */}
              <td>
              <span onClick={() => DownloadLeadDetails(listanddetail.id, listanddetail.writer)} className="material-icons" style={{ color: 'green' }}>
                  download
              </span>
              </td>
            </tr>
          );
        })}{" "}
      </table>
      <LeadsInvoice detailstopass={invoicedetail} SPName={props.SPName} />
    </div>
  );
}

export default LeadsSelected;
