import React, { useRef } from "react";
import "./PageBanner.css";
import PageBannerImg from '../../asserts/OtherImages/PageBanner.png'

export default function PageBanner(props) {
  const imgref = useRef(null);
  const pagebannertitleref = useRef(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     imgref.current.style.left = 0;
  //   }, 10);
  //   setTimeout(() => {
  //     pagebannertitleref.current.style.right = 0;
  //   }, 200);
  // }, []);

  return (
    <div className="pagebanner-container">
      <div className="page-banner">
        <div
          ref={pagebannertitleref}
          id="page-banner-title"
          className="banner-title"
        >
          <h1>{props.title}</h1>
          <br />
          <ul>
            <li style={{ listStyleType: 'none' }}>Home</li>
            <li>Pages</li>
            <li>{props.title}</li>
          </ul>
        </div>
        <div ref={imgref} id="img" className="img">
          <img src={PageBannerImg} alt="banner" />
        </div>
      </div>
    </div>
  );
}
