import React from "react";
import "./ImageUpload.css";

export default function DeletePopup(props) {
  return (
    <div
      style={{
        visibility: props.visibility,
      }}
      className="delete-popup-container"
    >
      <div style={{ transform: props.transform }} className="delete-popup">
        <p className="alert-title">Are you sure to remove the image ? </p>
        <div className="yes-no-btns">
          <p onClick={props.handleyes} className="yes-btn">
            Yes
          </p>
          <p onClick={props.handleno} className="no-btn">
            No
          </p>
        </div>
      </div>
    </div>
  );
}
