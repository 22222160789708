import React from "react";
import "./Utils.css";

export default function EnquireReceived(props) {
  // console.log("Service Professional Property Requirement Enquires Received", props.ProfileDatas);
  const arr = ["", "", "", "", "", "", ""]

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2} scope="row" >Sl. No.{" "}</td>
          <td className="heading" colSpan={2} scope="col">Property Owner{" "}</td>
          <td className="heading" colSpan={2} scope="col">Service Professional{" "}</td>
          <td className="heading" rowSpan={2} scope="row" >Profession</td>
          <td className="heading" rowSpan={2} scope="row">Company Name{" "}</td>
          <td style={{ minWidth: "400px" }} className="heading" rowSpan={2} scope="row">{props.dataandtimeheading}</td>
        </tr>
        <tr>
          <td className="subheading" scope="row">Requirement ID</td>
          <td className="subheading" scope="row">Land_BUilding</td>
          <td className="subheading" scope="row">Profile Number</td>
          <td className="subheading" scope="row">Name</td>
        </tr>

        {props.ProfileDatas.map((listanddetail, index) => {
          return (
            <>
            <tr>
                <td rowspan={listanddetail.Companies_Buy.length + 1}> {index + 1} </td>
                <td rowspan={listanddetail.Companies_Buy.length + 1}> <a href={`/PropertyOwner/PropertyRequirementsDetails/${listanddetail._id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>{listanddetail._id}</a></td>
                <td rowspan={listanddetail.Companies_Buy.length + 1}> <a href={`/PropertyOwner/PropertyRequirementsDetails/${listanddetail._id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>{listanddetail.Land_Building}</a></td>
            </tr>
            {listanddetail.Companies_Buy.map(subRow => {
              return (
            <tr>
                <td><a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${subRow.id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>{ subRow.id }</a></td>
                <td><a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${subRow.id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>{ subRow.Name }</a></td>
                <td>{ subRow.Profession }</td>
                <td>{ subRow.Company_Name }</td>
                <td>{ subRow.Date }</td>
            </tr>
              )
            })}
            </>
          )
        })}
      </table>
    </div>
  );
}
