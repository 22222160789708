module.exports.table = {
  titles: [
    {
      Name: "SL NO.",
      rowspan: 2,
    },
    {
      Name: "Invoice Payment ID",
      rowspan: 2,
    },
    {
      Name: "Purchase Order ID",
      rowspan: 2,
    },
    {
      Name: "Date & Time Of Payment",
      rowspan: 2,
    },
    {
      Name: "Subscribed Plan Details",
      colspan : 8
    },
    {
      Name: "Payment Status",
      rowspan: 2,
    },
    {
      Name: "Payment Mode",
      rowspan: 2,
    },
    {
      Name: "Download Invoice",
      rowspan: 2,
    },
  ],
  subtitles: [
    "Plan Name",
    "Plan Validity",
    "Original Amount (Exclusive of G.S.T.)",
    "Discounted Amount (Exclusive of G.S.T.)",
    "G.S.T. Amount (18%)",
    "Discounted Amount (Inclusive of G.S.T.)",
    "Total Savings",
    // "Plan Expires On",
    "Plan Status",
  ],
};
//Demo Data
module.exports.transactiondata = [
    {
        PurchaseOrderId : 1233412312314,
        DateTimeofPayment : "03/03/2022 1:15 PM",
        SubscriptionPlanDetails : {
            PlanName : "Premium Plan",
            PlanValidity : "1 Year",
            AmountPerMonth : "$ 99",
            TotalAmount : "$ 1200",
            PlanExpires : "04/03/2023",
            PlanStatus : "Active",
            PaymentMode : "NetBanking",
            DownloadInvoice : ""
        }
    },
    {
        PurchaseOrderId : 46455234124,
        DateTimeofPayment : "08/01/2022 1:15 PM",
        SubscriptionPlanDetails : {
            PlanName : "Premium Plan",
            PlanValidity : "1 Year",
            AmountPerMonth : "$ 99",
            TotalAmount : "$ 1200",
            PlanExpires : "08/01/2022",
            PlanStatus : "Active",
            PaymentMode : "NetBanking",
            DownloadInvoice : ""
        }
    },
    {
        PurchaseOrderId : 1233412312314,
        DateTimeofPayment : "15/02/2022 1:15 PM",
        SubscriptionPlanDetails : {
            PlanName : "Premium Plan",
            PlanValidity : "1 Year",
            AmountPerMonth : "$ 99",
            TotalAmount : "$ 1200",
            PlanExpires : "15/02/2023",
            PlanStatus : "Active",
            PaymentMode : "NetBanking",
            DownloadInvoice : ""
        }
    },
    {
        PurchaseOrderId : 1233412312314,
        DateTimeofPayment : "03/03/2022 1:15 PM",
        SubscriptionPlanDetails : {
            PlanName : "Starter Plan",
            PlanValidity : "1 Year",
            AmountPerMonth : "$ 99",
            TotalAmount : "$ 1200",
            PlanExpires : "04/03/2022",
            PlanStatus : "Active",
            PaymentMode : "NetBanking",
            DownloadInvoice : ""
        }
    },
    {
        PurchaseOrderId : 456345233,
        DateTimeofPayment : "03/03/2022 1:15 PM",
        SubscriptionPlanDetails : {
            PlanName : "Advanced Plan",
            PlanValidity : "1 Year",
            AmountPerMonth : "$ 99",
            TotalAmount : "$ 1200",
            PlanExpires : "04/03/2022",
            PlanStatus : "Active",
            PaymentMode : "NetBanking",
            DownloadInvoice : ""
        }
    },
]
