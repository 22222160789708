import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SP_ForgotPasswordAction } from "../../../../_actions/company_actions";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./SP_ForgotPassword.css"

function SP_ForgotPassword() {
  const dispatch = useDispatch();
  const [EmailEntered, setEmailEntered] = useState("");

  const OnEmailInput = (e) => {
    // console.log(e.target.value);
    setEmailEntered(e.target.value);
  };

  const onSubmitEmail = () => {
    // console.log(EmailEntered);
    let variable = {
      email: EmailEntered,
    };
    dispatch(SP_ForgotPasswordAction(variable)).then((response) => {
      // console.log("Response ", response);
      alert("Reset Link has been sent.")
    });
  };

  return (
    <div className="sp-forgot-password-container">
      <PageBanner
        title="Service Professional Forgot Password Page"
      />
      <div className="header">
        <p className="title">
          <b>Kindly enter your NebourHood registered email to receive the details and reset your password:</b>
        </p>
      </div>
      <div className="sp-forgot-password">
          <label>Email</label>
          <input onChange={OnEmailInput} type="text" placeholder="Enter your mail" />
          <button onClick={onSubmitEmail} className="reset-link-btn">
            Send Reset Password Link
          </button>
      </div>
    </div>
  );
}

export default SP_ForgotPassword;
