import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SUBSCRIPTION_SERVER } from '../../../../Config';
import { onBuyIndividual, onSubscriptionPackageBuyFailed } from '../../../../_actions/company_actions';

const INDIVIDUAL_URL = `${SUBSCRIPTION_SERVER}/individuallead`;

function IndividualPricing(props) {
    // console.log("Razorpay props: " , props);
    const dispatch = useDispatch();

    function onTransactionSuccess(data) {
        // console.log("Data Success: " , data);
                    dispatch(onBuyIndividual({cartDetail: props.CartDetails, paymentData: data})).then((response) => {
                        if (response.payload.success) {
                            // console.log("Response Success: " , response);
                          setTimeout(function () {
                            window.location.replace("/ServiceProfessional/MyPurchasedLead");
                          }, 500);
                        }
                      });
    }

    function CallexpireFunction(expdate, planName) {
        const planValidity = 0;
        let expireDate = new Date(expdate);
        expireDate.setDate(expireDate.getDate() + planValidity);
        return expireDate.toString();
    }

    function onTransactionFailed(data) {
        var dataToSubmit = {
            data: data,
            planname: props.Name,
            Validity: 0
        }
        dispatch(onSubscriptionPackageBuyFailed(dataToSubmit))
            .then(response => {
                // console.log("Response dispatch", response);
            })
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () =>{
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
    }

    function setValidity(value) {
        return 0;
    }
    function setTotalLeads(value) {
        return 0;
    }
  
    async function callfunction() {
        // console.log("Function Call to Display Razorpay First call", props.Name, props.Validity);
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if(!res) {
            alert("Error in loading Razorpay Window");
            return;
        }
       const result = await axios.post(INDIVIDUAL_URL);
       if(!result) {
        alert("Server Error, Are you online? Please try again later.");
       }
       const { amount, id: order_id, currency } = result.data;
       const options = {
                "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                "amount": amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": currency,
                "name": "NebourHood",
                "description": "Plan Subscription",
                "image": "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/NebourHoodHomeLogo.jpg",
                "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response){
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        planname: props.Name,
                        Validity: setValidity(props.Name),
                        TotalLeads: setTotalLeads(props.Name),
                        Subscribed_On_Date: new Date().toLocaleDateString(), 
                        Subscribed_On_Time: new Date().toTimeString(),
                        // Plan_Expire_Date: new Date().toLocaleDateString(),
                        Plan_Subscribed_On: new Date(),
                        Success: true
                    };
                    // console.log("Data ", data);
                    const verifyTransaction = await axios.post(`${SUBSCRIPTION_SERVER}/payment/success`, data);
                    // console.log("Verified transaction : ", verifyTransaction);
                    if(verifyTransaction.data.success) {
                        onTransactionSuccess(data);
                    } else {
                        alert("Invalid Signature");
                    }
                },
                "notes": {
                    "address": "NebourHood Plan Subscription Payment"
                },
                "theme": {
                    "color": "#44A4E6"
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response){
                onTransactionFailed(response);
            });
            paymentObject.open();
    }


    return (
    <p style={{ color: 'white', margin: 0 }} onClick={callfunction}>Proceed to Pay ₹ {props.Price}</p>
  )
}

export default withRouter(IndividualPricing);