import React from 'react'
import { useLayoutEffect } from 'react'
import PageBanner from '../../../utils/PageBanner/PageBanner'
import "./Rules.css"

const PORules = () => {

    useLayoutEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        })

    return (
        <div className='rules-container'>
            <PageBanner title="Property Owner Rules" />
            <br />

            <br />
            <div className="rules">
                <h1>PROPERTY OWNER RULES FOR PROFILE COMPLETION / EDITING & </h1>
                <h1>PROPERTY REQUIREMENT POSTING / EDITING / RENEWAL</h1>
                <br /><br />
                <ul className='main-list'>
                    <li className='heading' style={{ listStyleType: 'none' }}>Information Guidelines:</li>
                    <ul className='sub-list'>
                        <li>            Please do not input your contact information (Phone Number, WhatsApp Number, Email ID, Social Media links, etc.) in any other input box, apart from the ones that they have been asked for.
                        </li>
                        <li>            Please do not share your contact information like Phone Number, WhatsApp Number, Email ID, Social Media links, etc., with any NebourHood employee. NebourHood never asks you for your contact information, except during sign up (to create your account), during log in and while completing your profile. It may also be asked if found necessary / during any emergency.
                        </li>
                        <li>            We request you to upload only the appropriate & relevant details and kindly refrain from uploading any personal, private, sensitive, profane and / or obscene information in any form, as it would hamper the reputation and business interests of your profile / property requirements.
                        </li>
                    </ul>
                </ul>
                <br />
                <ul className='main-list'>
                    <li className='heading' style={{ listStyleType: 'none' }} >            Please Note:
                    </li>
                    <ul className='sub-list'>
                        <li>            Information entered that does not meet the above-mentioned Information Guidelines would be removed.
                        </li>
                        <li>            NebourHood may suspend and / or terminate the Property Owner Profile / Property Requirements at any time without prior notice, if the Property Owner Profile / Property Requirements do not meet the above-mentioned Information Guidelines. No further queries and / or complaints shall be entertained in this regard, whatsoever.
                        </li>
                    </ul>
                </ul>


            </div>

        </div>
    )
}

export default PORules