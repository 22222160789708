import React, { useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './MyStats.css'
import { useSelector } from 'react-redux';
import PageBanner from '../../../utils/PageBanner/PageBanner';
import MembershipDisclaimer from '../../ServiceProfessional/MembershipDescription/MembershipDisclaimer';

function MyStats(props) {

  const company = useSelector(state => state.company)

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);


  function getReplacementValue(leadLeft, replacementValue){
    var result = leadLeft - replacementValue;
    if(result < 0){
      return result * -1;
    }
    return result;
  }

  return (
    <div>
      <PageBanner title="My Stats" />
        <div className='mystats-header'>
          <p>My Stats</p>
        </div>
        <div className='mystats-div1'>
          <div className='mystats-div1-ptag'>
            <p>Here, you can view the Currently <span style={{ color:' green' }}>Active</span> Stats of your NebourHood account:</p>
          </div>
          <div className='mystats-div2-table'>
            <table className='table1'>
              <tr>
                <th>Stats Name</th>
                <th>Plan Name</th>
                <th>Plan Leads Left</th>
                <th>Replacement Leads Left</th>
                <th>Overall Leads Left</th>
                <th>Overall Validity Left</th>
              </tr>
              {company.companyData && company.companyData.isAuth && company.companyData.Subscribed ? 
                <tr style={{ color: 'green' }}>
                  <td style={{ fontWeight: 700 }}>{company.companyData.currentPackage === "" ? 'Single Property Requirement Lead' : 'Lead Plan'}</td>
                  <td>{company.companyData.currentPackage === "" ? '-' : company.companyData.currentPackage}</td>
                  <td>{company.companyData.package_replacement_leads}</td>
                  <td>{getReplacementValue(company.companyData.leadLeft, company.companyData.package_replacement_leads)}</td>
                  <td>{company.companyData.leadLeft}</td>
                  <td>{company.companyData.Subscription_Time_Left === 1 ? company.companyData.Subscription_Time_Left + "  Day" : company.companyData.Subscription_Time_Left + " Days" }</td>
                </tr>
                : null}
              {company.companyData && company.companyData.isAuth && company.companyData.Membership ? 
                  <tr style={{ color: 'green' }}>
                    <td style={{ fontWeight: 700 }}>Prime Membership</td>
                    <td>{company.companyData.currentMembership === '' ? '-' : company.companyData.currentMembership }</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{company.companyData.MembershipValidity === 1 ? company.companyData.MembershipValidity + ' Day' : company.companyData.MembershipValidity + ' Days'}</td>
                  </tr>
                : null}
            </table>
          </div>
          <div className='my-stats-div-notetag'>
            <p className='ptag-note'>Please Note:</p>
              <li style={{ marginTop: '5px' }} className='pleasenote-listitem'>
                <b>For Single Property Requirement Lead & Lead Plan:</b> Overall Validity Left = Plan Validity Left + Replacement Period Validity Left
              </li>
              <li className='pleasenote-listitem'>
               <b>For Prime Membership:</b> Overall Validity Left = Plan Validity Left
              </li>
              {company.companyData && company.companyData.isAuth ?
              <>
                <div className='mystats-replacement-ptag'>
                  <p>Here, you can view the Currently <span style={{ color: '#fe5631' }}>Pending</span> Replacement Stats of your NebourHood account:</p>
                </div>
                <div className='mystats-replacement-table'>
                  <table className='table1'>
                    <tr>
                      <th>Sl No.</th>
                      <th>Stats Name</th>
                      <th>Plan Name</th>
                      <th>Replacement Period Validity Left</th>
                      <th>Replacement Leads Left</th>
                    </tr>
                    {company.companyData.pending_replacement.map((data, index) => {
                      return (
                      <tr style={{ color: '#fe5631' }}>
                        <td style={{ fontWeight: 700 }}>{index + 1}</td>
                        <td>NQA Double Replacement</td>
                        <td style={{ fontWeight: 700 }}>{data.planName === "" ? 'Single Property Requirement Lead' : data.planName}</td>
                        <td>120 Days</td>
                        <td>2</td>
                      </tr>
                      )
                    })}
                  </table>
                </div>
              </>
              : null}
          <div className='mystats-planDescription'>
            <div className='mystats-planDescription-div1'>
            <p className='mystats-planDescription-div1-header'>We have Single Property Requirement Leads, where:</p>
                <p className='mystats-div1-ptag-description'>1 Property Requirement Lead can be purchased by 3 Service Professionals.</p>
              <button onClick={()=>props.history.push(`/ServiceProfessional/PropertyRequirements`)} className='mystats-sharing-button1'>
                <p className='mystats-link'>Click here to buy the latest Sharing 1:3 Leads on NebourHood Now!</p>
              </button>
            </div>
            <div className='mystats-planDescription-div2'>
              <p className='mystats-planDescription-div2-header'>We have the following Subscription Plan Series:</p>
                <p className='mystats-div1-ptag-description'>The Non-Sharing Roaring Tiger Plan Series: Here, 1 Property Requirement Lead can be exclusively selected by only 1 Service Professional, after purchasing any plan under this series.</p>
                <br />
                <button onClick={()=>props.history.push(`/ServiceProfessional/Pricing`)} className='mystats-sharing-button2'>
                  <p className='mystats-link'>Click here to buy the latest Non-Sharing Exclusive 1:1 Leads on NebourHood Now!</p>
                </button>
            </div>
          </div>
          <MembershipDisclaimer page='MyStats' />
          </div>
        </div>
    </div>
  )
}

export default withRouter(MyStats)