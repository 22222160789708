import React, { useLayoutEffect, useState } from "react";
import "./Banner.css";
import HomePage from '../../../asserts/Home/HomePage.jpg'
// import { Data } from "./Data";

export default function Banner() {
  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  return (
    <div className="banner-container">
      <div
        style={{
          width: screenwidth <= 1165 ? screenwidth - 10 + "px" : null,
        }}
        className="banner"
      >
        <div className="headingandimage">
          <div className="heading">
            <h1>
              <b>
                Thinking of Building your Property or Designing it’s Interiors? 
              </b>
            </h1>
              <br />
            <p className="welcome">Welcome to NebourHood.</p>
            <p className="banner-desc">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              We help Property Owners and Interior Design, Construction & Architecture Service Professionals connect with each 
              other in a direct & simplified way by using our user – friendly platform. 
            </p>
          </div>

          <div className="image">
            <img
              style={{
                width: screenwidth <= 1165 ? screenwidth - 100 + "px" : null,
                height: screenwidth <= 1165 ? screenwidth - 100 + "px" : null,
              }}
              src={HomePage}
              alt="banner"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
