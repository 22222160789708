import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Subscription(props){

  const company = useSelector((state) => state.company);

  if(company.companyData && company.companyData.isAuth)
  {
    return <div>
        {company.companyData.Subscribed? 
        <>
          {/* <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
            <a style={{ color: 'white' }} onClick={() => props.history.push('/ServiceProfessional/Pricing')} >Lead Plan: <span>{company.companyData.Subscription_Time_Left} Days Left</span></a>
          </div> */}
        </>
          :
          <>
          {/* {!company.companyData.Membership ? 
          <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
            <a style={{ color: 'white' }} onClick={() => props.history.push('/ServiceProfessional/Pricing/Membership')}>Join Our Community Now!</a>
          </div>
           : */}
           <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
            <a style={{ color: 'white' }} onClick={() => props.history.push('/ServiceProfessional/Pricing')}>Non-Sharing Lead Plans</a>
          </div>
          {/* } */}
          </>
          }
    </div>
  } else {
    return null;
  }

}

export default withRouter(Subscription);