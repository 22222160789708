import React, { useEffect, useLayoutEffect, useState } from "react";
import { casestudies } from "./Casestudies";
import "./CaseStudy.css";

export default function CaseStudy() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  const [projectimgwidth, setprojectimgwidth] = useState();

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  useEffect(() => {

  setTimeout(() => {
    setprojectimgwidth(
      document.getElementById("project-img").getBoundingClientRect().width
    );
  }, 500);
  },[])


  const handlearrows = (e) => {
    const clickedarrow = e.target.id;
    const projectimages = document.getElementById("project-images");
    const transformwidth = parseInt(
      projectimages.style.transform.replace(/[^\d.]/g, "")
    );
    // console.log(transformwidth);

    var widthtomove = projectimgwidth;
    var casestudieslength = casestudies.length - 3;
    if (screenwidth < 1030) {
      casestudieslength = casestudies.length - 2;
    }
    if (screenwidth < 630) {
      casestudieslength = casestudies.length - 1;
    }

    if (clickedarrow === "left-arrow") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        projectimages.style.transform = `translateX(-${
          (widthtomove + 25) * casestudieslength
        }px)`;
      } else {
        projectimages.style.transform = `translateX(-${
          transformwidth - widthtomove - 25
        }px)`;
      }
    }

    if (clickedarrow === "right-arrow") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        projectimages.style.transform = `translateX(-${widthtomove + 25}px)`;
      }
      if (transformwidth > widthtomove * casestudieslength) {
        projectimages.style.transform = `translateX(-${0}px)`;
      } else {
        projectimages.style.transform = `translateX(-${
          transformwidth + 25 + widthtomove
        }px)`;
      }
    }
  };

  return (
    <div className="project-images-container">
      <h1 className="title">
        Explore Our Creative Collection of Project Images
      </h1>
      <br />
      <p className="description">
        Check out amazing Project pictures accomplished by well - versed
        professionals{" "}
      </p>
      <br />
      <div  style={{
                width:screenwidth < 630 ? screenwidth-30+'px':null
              }} className="project-images-carousel">
        <div onClick={handlearrows} className="arrows">
          <i id="left-arrow" class="fas fa-arrow-left"></i>
          <i id="right-arrow" class="fas fa-arrow-right"></i>
        </div>
        <div id="project-images" className="images-carousel">
          {casestudies.map((projectimg, index) => {
            return (
              <div style={{
                width:screenwidth < 630 ? screenwidth-30+'px':null
              }} id="project-img" key={index} className="project-img">
                <img src={projectimg.Link} alt={projectimg.Name} />
                <a href="/projects" className="project-img-name">
                  {projectimg.Name}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
