import React, { useLayoutEffect, useState } from "react";
import { facilites } from "./Data";
import "./Facilities.css";

export default function Facilities() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  return (
    <div className="facilities-container">
      <p className="title">
        <b>Our Mission & Vision</b>
      </p>
      <p
        style={{ width: screenwidth <= 820 ? screenwidth - 50 + "px" : null }}
        className="description"
      >
        Our mission is to support and inspire each other in order to bring
        dreams to reality, 
        <br />
        Hence being recognized in the marketplace and serving
        healthy client relationships by providing a measurable improvement in
        building assets.
      </p>
      <div
        style={{ width: screenwidth <= 820 ? screenwidth - 40 + "px" : null }}
        className="facilites"
      >
        {facilites.map((facility, index) => {
          return (
            <div
              style={{
                width: screenwidth <= 820 ? screenwidth - 50 + "px" : null,
              }}
              key={index}
              className="facility"
            >
              <div className="img">
                <img src={facility.Image} alt={facility.Facility} />
              </div>
              <p className="facility-name">
                <b>{facility.Facility}</b>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
