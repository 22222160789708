import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { User_ResetPassword } from "../../../../_actions/user_actions";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./PO_ForgotPassword.css";

function PO_ResetPassword(props) {
  const token = props.match.params.token;
  const dispatch = useDispatch();
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");

  const onResetButton = (e) => {
    // console.log(e.target.value);
    setPassword(e.target.value);
  };

  const onResetPassword = () => {
    // console.log(Password);
    if (Password !== ConfirmPassword) {
      alert("Passwords doesn't Match");
    } else {
      let variables = {
        token: token,
        password: Password,
      };
      dispatch(User_ResetPassword(variables)).then((response) => {
        if(response){
          alert("Your Password has been reset successfully !!!")
        } else {
          alert("Please Try Again Later !!!")
        }
      });
    }
  };

  const MatchPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="sp-forgot-password-container">
      <PageBanner
        title="Property Owner Reset Password Page"
      />
      <div className="header">
        <p className="title">
          <b>Kindly enter and confirm your new password for your NebourHood account:</b>
        </p>
      </div>
      <div className="sp-forgot-password">
        <label>Password</label>
        <input
          onChange={onResetButton}
          type="text"
          placeholder="New Password"
        />
        <input
          onChange={MatchPassword}
          type="text"
          placeholder="Confirm Password"
        />
        <button onClick={onResetPassword} className="reset-link-btn">
          Reset Password
        </button>
      </div>
    </div>
  );
}

export default PO_ResetPassword;
