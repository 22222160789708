import React from 'react'
import { useSelector } from 'react-redux'
import LoadingIcon from '../../../utils/LoadingIcon/LoadingIcon'
import PostPropertyReq from './PostPropertyReq/PostPropertyReq.js'
import UserProfileofOwner from '../../Profiles/Property_Owner/User Profile/UserProfileofOwner'

function VerifyBeforePostProperty() {

    const user = useSelector(state => state.user)

  
    if(user.userData && user.userData.isAuth)
    {
        if(user.userData && user.userData.isAuth && user.userData.Profile_Details_Filled)
        {
            return(
                <PostPropertyReq userID={user.userData._id} userData = {user.userData} />
            )
        } else {
            return(
                <>
                    <UserProfileofOwner userData = {user.userData} />
                    <PostPropertyReq userID={user.userData._id} userData = {user.userData} />
                </>
            )
        }
    } else {
        return(
            <LoadingIcon />
        )
    }
  
}

export default VerifyBeforePostProperty