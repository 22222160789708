import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCartItems,
  onSuccessBuy,
  removeCartItem,
} from "../../../_actions/company_actions";
import { withRouter } from "react-router-dom";
import SuccessPage from "./SuccessPage/SuccessPage";
import CartPageTable from "./CartPageTable/CartPageTable";
import EmptyCart from "./emptycart.png";
import "./CartPage.css";
import IndividualPricing from "../../utils/Razorpay/IndividualPricing/IndividualPricing";
// import PleaseNoteDisclaimer from "../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";
import CartPricingDisclamer from "../../utils/CartPricingDisclamer/CartPricingDisclamer";
import MembershipDescription from "./MembershipDescription/MembershipDescription";
import MarqueeBanner from "../../utils/MarqueeBanner/MarqueeBanner";

function CartPage(props) {
  const dispatch = useDispatch();
  const [Total, setTotal] = useState(0);
  const [GST, setGST] = useState(0);
  const [ShowTotal, setShowTotal] = useState(false);
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [NotAvailable, setNotAvailable] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let cartItems = [];
    if (props.company.companyData && props.company.companyData.cart) {
      if (props.company.companyData.cart.length > 0) {
        props.company.companyData.cart.forEach((item) => {
          cartItems.push(item.id);
        });
        dispatch(getCartItems(cartItems, props.company.companyData.cart));
      }
    }
  }, [props.company.companyData]);

  useEffect(() => {
    if (props.company.cartDetail && props.company.cartDetail.length > 0) {
      calculateTotal(props.company.cartDetail);
      checkforAvailableLeads(props.company.cartDetail, props.company.companyData.currentPackage);
    }
  }, [props.company.cartDetail]);

  const checkforAvailableLeads = (cartDetail, currentPackage) => {
    var notForSale = 0;
    if(currentPackage === "Startup Tiger" || props.currentPackage === "Elite Bear" 
      || props.currentPackage === "Classic Bear" || props.currentPackage === "Baby Bear" 
      || currentPackage === "Weby Tiger" || currentPackage === "Startup Tiger" 
      || currentPackage === "Startup Tiger Prime" || currentPackage === "Elite Tiger Prime" 
      || currentPackage === "Classic Tiger Prime" || currentPackage === "Baby Tiger Prime"){
      cartDetail.map((index) => {
        if(index.sold < 3){
          notForSale++;
        }
      })
    }
    if(currentPackage === "Startup Bear" || currentPackage === "Elite Bear" 
      || currentPackage === "Classic Bear" || currentPackage === "Baby Bear" 
      || currentPackage === "Weby Bear" || currentPackage === "Startup Bear" 
      || currentPackage === "Startup Tiger Prime" || currentPackage === "Elite Tiger Prime" 
      || currentPackage === "Classic Tiger Prime" || currentPackage === "Baby Bear Prime"){
      cartDetail.map((index) => {
        if(index.sold <= 0){
          notForSale++;
        }
      })
    }
    if(currentPackage !== "Startup Tiger" || currentPackage !== "Elite Tiger" 
    || currentPackage !== "Classic Tiger" || currentPackage !== "Baby Tiger" 
    || currentPackage !== "Weby Tiger" || currentPackage !== "Startup Tiger" 
    || currentPackage !== "Baby Bear" || currentPackage !== "Weby Bear" 
    || currentPackage !== "Startup Bear" || currentPackage !== "Baby Bear Prime" 
    || currentPackage !== "Startup Tiger Prime" || currentPackage !== "Elite Tiger Prime" 
    || currentPackage !== "Classic Tiger Prime" || currentPackage !== "Baby Tiger Prime"){
      console.log("Current package");
      cartDetail.map((index) => {
        if(index.sold <= 0){
          notForSale++;
        }
      })
    }
    setNotAvailable(notForSale === 0 ? false : true);
  }

  const calculateTotal = (cartDetail) => {
    let total = 0;
    let gst = 0;
    cartDetail.map((item) => {
      // total += parseInt(item.price, 10) * item.quantity;
      total += props.company.companyData.Membership ? 324 : 399;
    });
    gst = (total * 18) / 100;
    setGST(gst);
    setTotal(total);
    setShowTotal(true);
  };

  // Remove from cart
  const removeFromCart = (productId) => {
    dispatch(removeCartItem(productId)).then((response) => {
      if (response.payload.cartDetail.length <= 0) {
        setShowTotal(false);
      } else {
        calculateTotal(response.payload.cartDetail);
      }
    });
  };

  const transactionSuccess = () => {
    dispatch(onSuccessBuy({cartDetail: props.company.cartDetail})).then((response) => {
      if (response.payload.success) {
        setShowSuccess(true);
        setShowTotal(false);
        setTimeout(function () {
          window.location.replace("/ServiceProfessional/MyPurchasedLead");
        }, 500);
      }
    });
  };

  return (
    <>
        <MarqueeBanner text="Buy 1 Lead, Get 2 Replacement Leads - No Questions Asked, with the NebourHood Guarantee! For more details, click here" />
      <div className="cart-page-container">
        <h1 className="heading">My Cart</h1>
        <br />

        {ShowTotal ? (
          <div className="cart-page-table-containerr">
            <CartPageTable
              removeItem={removeFromCart}
              products={props.company.cartDetail}
              currentPackage={props.company.companyData.currentPackage}
              Subscribed={props.company.companyData.Subscribed}
              Leadleft={props.company.companyData.leadLeft}
              Membership={props.company.companyData.Membership}
              onClick={() =>{setNotAvailable(false)}}
            />

            {props.company.companyData.Subscribed && props.company.companyData.leadLeft > 0 ? (
              <>
              {props.company.companyData.cart.length > props.company.companyData.leadLeft ? 
              (
                <p className="notification-header">
                  You have selected additional leads. Kindly subscribe to any NebourHood plan to view the Property Requirement Details and get in touch with Additional Clients.
                  </p>
              ) : <>
              {NotAvailable ? 
              <>
              <p className="notification-header">
                Kindly remove the unavailable items to proceed.
              </p>
              </> 
              :
              <>
                <button id="rzp-button1" onClick={transactionSuccess} className="proceedtocheckoutbtn"> Proceed To Checkout </button>
                <br />
                <br />
                <CartPricingDisclamer />
              </>
              }
              </>}
              </>
            ) : (
              <>
              <br />
              {!NotAvailable ? 
              <>
              <div style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div className="div-total-header">
                <p className="div-total-header-ptag"><b>Total Amount To Be Paid (in Rs.)</b></p>
                </div>
                <div className="div-table-container1">
                <table className="table-alignment">
                <tr>
                  <th>Total Leads</th>
                  <th style={{color:'red'}}>Original Amount (Exclusive of G.S.T.)</th> 
                  <th>Discounted Amount (Exclusive of G.S.T.)</th> 
                  <th>G.S.T. Amount (18%)</th>
                  <th style={{color: '#1F80E0'}}>Discounted Amount (Inclusive of G.S.T.)</th> 
                  <th style={{color:'green'}}>NQA Double Replacement Lead Savings</th> 
                  <th style={{color:'green'}}>Non-Prime Member Savings</th> 
                  <th style={{color:'green'}}>NebourHood Prime S.P. Savings</th> 
                  <th style={{color:'green'}}>Total Savings</th>
                </tr>
                    <tr>
                      <td>{props.company.cartDetail.length}</td>
                      <td><s style={{color:'red'}}>{524 * props.company.cartDetail.length}</s></td>
                      <td>{(props.company.companyData.Membership ? 324 : 399) * props.company.cartDetail.length}</td>
                      <td>0</td>
                      <td style={{color: '#1F80E0'}}>{(props.company.companyData.Membership ? 324 : 399) * props.company.cartDetail.length}</td>
                      <td style={{color:'green'}}>{(props.company.companyData.Membership ? 324 : 399) * props.company.cartDetail.length}</td>
                      <td style={{color:'green'}}>{(props.company.companyData.Membership ? 200 : 125) * props.company.cartDetail.length}</td>
                      <td style={{color:'green'}}>{(props.company.companyData.Membership ? 75 : 0) * props.company.cartDetail.length}</td>
                      <td style={{color:'green'}}>{(props.company.companyData.Membership ? 599 : 524) * props.company.cartDetail.length}</td>
                    </tr>
                </table>
                </div>
              </div>
              <button
                id="rzp-button1"
                type="primary"
                className="subscribe-button"
              >
                <IndividualPricing
                    Name={"Individual Leads"}
                    Price={Total}
                    Validity={2}
                    CartDetails={props.company.cartDetail}
                  />
              </button>
              <br />
              <br />
              <MembershipDescription
              Membership={props.company.companyData.Membership}
              cartLength={props.company.cartDetail.length}
              />
              <br />
              <br />
              <br />
              <CartPricingDisclamer /> 
              </> :  
              <p className="notification-header">
                Kindly remove the unavailable items to proceed.
              </p>
            }
              </>
            )}
          </div>
        ) : ShowSuccess ? (
          <SuccessPage PurchaseDetails={"Successfully Purchased Project"} Redirect={true} />
        ) : (
          <div>
            <center>
              <img  className="emptycart" src={EmptyCart} alt="Cart is Empty" />
              <br />
              <br />
              <br />
              <button
                id="rzp-button1"
                onClick={() =>
                  props.history.push("/ServiceProfessional/PropertyRequirements")
                }
                type="primary"
                className="add-properties-button"
              >
                Add Property Requirements to Cart
              </button>
            </center>
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(CartPage);