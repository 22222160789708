import React from "react";
import EditProfileandOverview from "./EditProfileandOverview/EditProfileandOverview";
import ImageUpload from "./ImageUpload/ImageUpload";
import "./SP_Profile.css"

export default function SP_Profile(){
    return <div className="sp-profile-container">
        <div className="sp-profile">
        <ImageUpload/>
        <EditProfileandOverview/>
        </div>
     
    </div>
}