import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SUBSCRIPTION_SERVER } from '../../../../Config';
import { onPrimeMembershipBuyFailed, primeMembershipSuccess } from '../../../../_actions/company_actions';
import CommunityLink from '../../../CommunityLink/CommunityLink';

const THREE_MONTH_URL = `${SUBSCRIPTION_SERVER}/threeMonthMembership`;
const SIX_MONTH_URL = `${SUBSCRIPTION_SERVER}/sixMonthMembership`;
const NINE_MONTH_URL = `${SUBSCRIPTION_SERVER}/nineMonthMembership`;
const ONE_YEAR_URL = `${SUBSCRIPTION_SERVER}/oneYearMonthMembership`;

function MembershipPricing(props) {
    // console.log("Razorpay props: " , props);
    const [ShowLink, setShowLink] = useState('none');
    const dispatch = useDispatch();

    function hideCommunityModal() {
        setShowLink('none');
    }

    function onTransactionSuccess(data) {
                // console.log("Community Payment Response Success: " , data);
                    dispatch(primeMembershipSuccess(data)).then((response) => {
                            // console.log("Community Payment Response Success: " , response);
                            setShowLink('block')
                            setTimeout(() => {
                                hideCommunityModal();
                            }, 60000);
                            setTimeout(() => {
                                window.location.replace("/ServiceProfessional/spsuccess");
                            }, 61000);
                      });
    }

    function onTransactionFailed(data) {
        var dataToSubmit = {
            razorpayPaymentId: data.error.metadata.payment_id,
            razorpayOrderId: data.error.metadata.order_id,
            planname: props.Name,
            Subscribed_On_Date: new Date().toLocaleDateString(), 
            Subscribed_On_Time: new Date().toTimeString(),
            Plan_Subscribed_On: new Date(),
            Membership_Date: new Date().toLocaleString(),
            Success: false,
        }
        dispatch(onPrimeMembershipBuyFailed(dataToSubmit)).then((response) => {
            if(response.payload.success) {
                setTimeout(function () {
                    window.location.replace("/ServiceProfessional/Pricing/Membership");
                  }, 500);
            }
        })
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () =>{
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
    }

    async function callfunction() {
        // console.log("Function Call to Display Razorpay First call", props.Name, props.Validity);
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if(!res) {
            alert("Error in loading Razorpay Window");
            return;
        }
       const result = await axios.post(
            props.Name === "NebourHood Prime S.P. 3 Months" ? THREE_MONTH_URL :
            props.Name === "NebourHood Prime S.P. 6 Months" ? SIX_MONTH_URL :
            props.Name === "NebourHood Prime S.P. 9 Months" ? NINE_MONTH_URL :
            ONE_YEAR_URL
        );
       if(!result) {
        alert("Server Error, Are you online? Please try again later.");
       }
       const { amount, id: order_id, currency } = result.data;
       const options = {
                "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                "amount": amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": currency,
                "name": "NebourHood",
                "description": "Membership Subscription",
                "image": "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/NebourHoodHomeLogo.jpg",
                "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response){
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        planname: props.Name,
                        Subscribed_On_Date: new Date().toLocaleDateString(), 
                        Subscribed_On_Time: new Date().toTimeString(),
                        Plan_Subscribed_On: new Date(),
                        Membership_Date: new Date().toLocaleString(),
                        Success: true,
                        // phoneNumber: props.Number
                    };
                    // console.log("Data ", data);
                    const verifyTransaction = await axios.post(`${SUBSCRIPTION_SERVER}/payment/success`, data);
                    if(verifyTransaction.data.success) {
                        onTransactionSuccess(data);
                    } else {
                        alert("Invalid Signature");
                    }
                },
                "notes": {
                    "address": "NebourHood Plan Subscription Payment"
                },
                "theme": {
                    "color": "#44A4E6"
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response){
                onTransactionFailed(response);
            });
            paymentObject.open();
    }


    return (
        <>
        <div style={{ display: ShowLink }}>
            <CommunityLink directto={"/"} ShowButton={false} />
        </div>
    <p style={{ color: 'white', margin: 0 }} onClick={callfunction}>Proceed to Pay</p>
        </>
  )
}

export default withRouter(MembershipPricing);