import axios from 'axios';
import { PRODUCT_SERVER, USER_SERVER } from '../Config';
import { LOGIN_USER, AUTH_USER, LOGOUT_USER, POST_PROPERTY_REQUIREMENTS, USER_PROFILE_UPDATE, REGISTER_USER, POST_PROPERTY_REQUIREMENTS_WITH_PAYMENT, PO_PHONENUMBER_VERIFICATION, PO_OTP_VERIFY_PHONENUMBER, PROPERTY_OWNER_FORGOT_PASSWORD, PROPERTY_OWNER_RESET_PASSWORD, RENEWAL_PROPERTY_REQUIREMENTS_WITH_PAYMENT, PROPERTY_OWNER_POSTED_PROPERTY_REQUIREMENTS, PO_PAYMENT_FAILED, POST_PROPERTY_REQUIREMENTS_WITHOUT_AUTHORIZATION } from './types';


export function registerUser(dataToSubmit){
    const request = axios.post(`${USER_SERVER}/register`,dataToSubmit)
        .then(response => response.data);
    
    return {
        type: REGISTER_USER,
        payload: request
    }
}

export function loginUser(dataToSubmit){
    const request = axios.post(`${USER_SERVER}/login`,dataToSubmit)
                .then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function loginUserGoogle(googleData){
    const request = axios.post(`${USER_SERVER}/googleLogin`, googleData)
                    .then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function auth(){
    const request = axios.get(`${USER_SERVER}/auth`)
    .then(response => response.data);

    return {
        type: AUTH_USER,
        payload: request
    }
}

export function logoutUser(){
    const request = axios.get(`${USER_SERVER}/logout`)
    .then(response => response.data);

    return {
        type: LOGOUT_USER,
        payload: request
    }
}

export function postpropertyrequirements(dataToSubmit) {
    // console.log("User Action", dataToSubmit);
    const request = axios.post(`${PRODUCT_SERVER}/uploadProduct`, dataToSubmit)
        .then(response => response.data);

        return {
            type: POST_PROPERTY_REQUIREMENTS,
            payload: request
        }
}

export function postpropertyrequirementswithoutauth(dataToSubmit) {
    // console.log("User Action", dataToSubmit);
    const request = axios.post(`${PRODUCT_SERVER}/uploadProductWithoutAuth`, dataToSubmit)
        .then(response => response.data);

        return {
            type: POST_PROPERTY_REQUIREMENTS_WITHOUT_AUTHORIZATION,
            payload: request
        }
}
    
export function updateuserprofile(dataToSubmit) {
    // console.log("data to submit", dataToSubmit);
    const request = axios.post(`${USER_SERVER}/updateusercontactinfo`, dataToSubmit)
        .then(response => response.data);

    return {
        type: USER_PROFILE_UPDATE,
        payload: request
    }
}

export function secondPropertyWithPayment(dataToSubmit) {
    // console.log("data to submit", dataToSubmit);
    const request = axios.post(`${PRODUCT_SERVER}/postPropertywithPayment`, dataToSubmit)
        .then(response => response.data);

    return {
        type: POST_PROPERTY_REQUIREMENTS_WITH_PAYMENT,
        payload: request
    }
}

export function renewPropertyWithPayment(dataToSubmit) {
    // console.log("data to submit", dataToSubmit);
    const request = axios.post(`${PRODUCT_SERVER}/renewalPropertywithPayment`, dataToSubmit)
        .then(response => response.data);

    return {
        type: RENEWAL_PROPERTY_REQUIREMENTS_WITH_PAYMENT,
        payload: request
    }
}

export function UserOTP_PhoneNumber(dataToSubmit) {
    // console.log("User Action OTP Generating call", dataToSubmit);
    const request = axios.post(`${USER_SERVER}/UserOTP_PhoneNumber`, dataToSubmit)
        .then(response => response.data);

    return {
        type: PO_PHONENUMBER_VERIFICATION,
        payload: request
    }
}

export function UserOTP_VerifyNumberCall(dataToSubmit) {
    // console.log("User Action OTP Generating call", dataToSubmit);
    const request = axios.post(`${USER_SERVER}/userVerifyPhoneNumberViaOTP`, dataToSubmit)
        .then(response => response.data);

    return {
        type: PO_OTP_VERIFY_PHONENUMBER,
        payload: request
    }
}

export function User_ForgotPassword(dataToSubmit) {
    // console.log("User Action forgot password", dataToSubmit);
    const request = axios.post(`${USER_SERVER}/forgotPassword`, dataToSubmit)
        .then(response => response.data);

    return {
        type: PROPERTY_OWNER_FORGOT_PASSWORD,
        payload: request
    }
}

export function User_ResetPassword(dataToSubmit) {
    // console.log("User Action Reset password", dataToSubmit);
    const request = axios.post(`${USER_SERVER}/reset/token`, dataToSubmit)
        .then(response => response.data);

    return {
        type: PROPERTY_OWNER_RESET_PASSWORD,
        payload: request
    }
}

export function User_PostedPropertyRequirements() {
    // console.log("User Action Posted Property Requirements");
    const request = axios.get(`${USER_SERVER}/getproperty`)
        .then(response => response.data);

    return {
        type: PROPERTY_OWNER_POSTED_PROPERTY_REQUIREMENTS,
        payload: request
    }
}

export function User_PaymentFailure() {
    // console.log("User Action Payment Failure");
    const request = axios.post(`${USER_SERVER}/paymentFailed`)
        .then(response => response.data);

    return {
        type: PO_PAYMENT_FAILED,
        payload: request
    }
}