const neighborhoods = [
  {
    Name: "Bangalore",
    Properties: 10,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-1.jpg",
  },
  {
    Name: "Chennai",
    Properties: 15,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-2.jpg",
  },
  {
    Name: "Delhi",
    Properties: 13,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-3.jpg",
  },
  {
    Name: "Hyderabad",
    Properties: 20,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-6.jpg",
  },
  {
    Name: "Mumbai",
    Properties: 12,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-5.jpg",
  },
  {
    Name: "Pune",
    Properties: 5,
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/FindTheNeighbourhoodForYou/image-4.jpg",
  },
];
module.exports.neighborhoods = neighborhoods;
