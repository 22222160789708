import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { RemoveLogoImage, UploadCoverProfileLogo } from "../../../../../../../../_actions/company_actions";
import LoadingIcon from "../../../../../../../utils/LoadingIcon/LoadingIcon";
import "./CompanyLogo.css";

function CompanyLogo(props) {
  const dispatch = useDispatch();
  const [ImagesURL, setImagesURL] = useState(props.Image_Src);
  const [loading, setloading] = useState(false);

  const onDrop = (files) => {
    let formData = new FormData();
    const config = {
      header: { "centent-type": "multipart/form-data" },
    };
    formData.append("file", files[0]);
    formData.append("value", "Logo");
    setloading(true);
    // saving the image inside the node server
    dispatch(UploadCoverProfileLogo(formData, config)).then((response) => {
      if (response.payload.success) {
        // console.log("Result", response);
        setImagesURL(response.payload.result.Location);
        setloading(false);
      } else {
        alert("Failed to save the image");
      }
    });
  };

  const handledeleteimg = (image) => {
    // console.log("Delete image", image);
    const variable = {
      imageKey: image.slice(61),
    };
    dispatch(RemoveLogoImage(variable)).then((response) => {
      if(response.payload.success){
        setImagesURL("");
      } else {
        alert("Failed To Update Changes!!! Please Try Again Later")
      }
    })
  };

  return (
    <div className="company-logo-container">
      {ImagesURL ? (
        loading ?      <LoadingIcon
        position="absolute"
        width={"100%"}
        height={"100%"}
        outerwidth={"50px"}
        outerheight={"50px"}
        innerdisplay={"none"}
    
      /> :
        <div className="company-logo">
          <img src={ImagesURL} />
          {props.nochange ? null : 
          <div className="company-logo-options">
            <span className="material-icons">open_in_full</span>
            <>
              <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000000}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="material-icons">photo_camera</span>
                  </div>
                )}
              </Dropzone>
            </>
            <span onClick={() => {handledeleteimg(ImagesURL)}} className="material-icons">
              delete_outline
            </span>
          </div>
          }
        </div>
      ) : (
        <div className="no-img-logo-upload">
          <>
            <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000000}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="title">
                    <span className="material-icons">add</span>Company Logo
                  </p>{" "}
                </div>
              )}
            </Dropzone>
          </>
        </div>
      )}
    </div>
  );
}

export default CompanyLogo;
