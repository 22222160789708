import React from "react";
import PORazorpay from "../../../../../utils/Razorpay/PO_Posting_Renew/PORazorpay";
import "./PaymentModal.css";

export default function PaymentModal(props) {
  var modalopen = props.modalopen;
  const details = props.filleddetails;
  // console.log("Data To Post ", details);

  return (
    <div
      style={{
        transform: modalopen ? "scale(1)" : "scale(0)",
      }}
      className="payment-modal-container"
    >
      <form className="form">
      <h1 className="title">Post Property Requirements</h1>
        <div className="modal-details">
          <br />
          <br />
          <div className="modal-details">
          <div className="property-details">
            <div className="detail">
              <p className="detail-title">Cost Price (Exclusive of G.S.T.)</p>
              <p className="cost">₹ 499 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">Final Amount (Exclusive of G.S.T.)</p>
              <p className="cost">₹ 499 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">G.S.T. Amount (18%) </p>
              <p className="cost">₹ 0 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">Total Amount</p>
              <p className="cost">₹ 499 /-</p>
            </div>
          </div>
        </div>
        <div className="disclaimer" style={{ marginLeft: '30px' }}>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Please Note:
            </p>
          </div>
          <div>
          <p>•	All payments made to NebourHood at any stage are non-refundable. </p>
          <p>•	For more information, please visit the Replacement, Refund and Cancellation Policies page.</p>
          <p>•	Orders once placed / completed successfully cannot be cancelled. </p>
          <p>•	You would receive the details of your purchase on your NebourHood registered email.</p>
          <p>•	To view more details regarding your purchase on NebourHood, please visit the Transaction Details section of your profile.</p>
          </div>
          <br />
          <br />
          <div className="disclaimer">
            <b>Disclaimer :</b>
            <p>
              •	<b>NebourHood would not be responsible</b> for the
              acceptance or rejection of any deals between the Property Owners
              and the Service Professionals.
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and property owners
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Service professionals and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Or any others involved.
            <br />
            <p>
              •	<b>NebourHood would not be responsible</b> for any
              kind of service or payment terms made between the Property Owners
              and the Service Professionals.
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and property owners
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Service professionals and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Or any others involved.
          </div>
          </div>
        </div>

        <button onClick={props.handlepostreq} className="submit-btn">
          <PORazorpay value="Post" DetailsToPost={props.filleddetails} />
        </button>
        <span
          id="close-icon"
          onClick={props.handleclose}
          className="material-icons"
          style={{
            top: 0,
          }}
        >
          close
        </span>
      </form>
    </div>
  );
}
