import React from "react";
import PageBanner from "../../utils/PageBanner/PageBanner";
import Reviews from "./Reviews/Reviews";
import Testimonials from "./Testimonials/Testimonials";
// import CompaniesListed from "./Companies Listed/CompaniesListed";
import { useLayoutEffect } from "react";

export default function TestimonialsAndReviews() {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    })

  return (
    <div className="testimonialsandreviews-container">
      <PageBanner
        title="Testimonials"
      />
      <Reviews />
      <Testimonials />
      {/* <CompaniesListed /> */}
    </div>
  );
}
