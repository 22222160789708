import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PO_GetAllAnalyticsData } from "../../../../_actions/user_analytics";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import "./PO_Analytics.css";
import EnquireReceived from "./Utils/EnquireReceived";
import ListandDetails from "./Utils/ListandDetails";
import ParentTable from "./Utils/ParentTable";
import SocialMediaTable from "./Utils/SocialMediaTable";

function PO_Analytics() {
  
  const dispatch = useDispatch();
  const [ShowLoading, setShowLoading] = useState(true);
  const [SP_Profile_Visited, setSP_Profile_Visited] = useState([]);
  // const [SP_Profile_Visited_By, setSP_Profile_Visited_By] = useState([]);
  const [SP_Profile_Received, setSP_Profile_Received] = useState([]);
  const [SocialMediaConnects, setSocialMediaConnects] = useState([]);
  const [EnquireProperties, setEnquireProperties] = useState([]);

  // PROPERTY REQUIREMENTS COUNTS
  let Posted_Count = 0;
  let Edited_Count = 0;
  let Renewed_Count = 0;
  let Phonecall_Count = 0;
  let Whatsapp_Count = 0;
  let Website_Count = 0;
  let Facebook_Count = 0;
  let Instagram_Count = 0;
  let Linkedin_Count = 0;
  let Pinterest_Count = 0;
  let Quora_Count = 0;
  let Reddit_Count = 0;
  let Twitter_Count = 0;
  let Youtube_Count = 0;

  // SETTING COUNT FOR SOCIAL MEDIA
  const [PhoneCallCount, setPhoneCallCount] = useState(0);
  const [WhatsAppCount, setWhatsAppCount] = useState(0);
  const [WebsiteCount, setWebsiteCount] = useState(0);
  const [FacebookCount, setFacebookCount] = useState(0);
  const [InstagramCount, setInstagramCount] = useState(0);
  const [LinkedinCount, setLinkedinCount] = useState(0);
  const [PinterestCount, setPinterestCount] = useState(0);
  const [QuoraCount, setQuoraCount] = useState(0);
  const [RedditCount, setRedditCount] = useState(0);
  const [TwitterCount, setTwitterCount] = useState(0);
  const [YoutubeCount, setYoutubeCount] = useState(0);

  // RECORDS OF SOCIAL MEDIA
  const [PhoneCall_Record, setPhoneCall_Record] = useState([]);
  const [WhatsApp_Record, setWhatsApp_Record] = useState([]);
  const [Website_Record, setWebsite_Record] = useState([]);
  const [Facebook_Record, setFacebook_Record] = useState([]);
  const [Instagram_Record, setInstagram_Record] = useState([]);
  const [Linkedin_Record, setLinkedin_Record] = useState([]);
  const [Pinterest_Record, setPinterest_Record] = useState([]);
  const [Quora_Record, setQuora_Record] = useState([]);
  const [Reddit_Record, setReddit_Record] = useState([]);
  const [Twitter_Record, setTwitter_Record] = useState([]);
  const [Youtube_Record, setYoutube_Record] = useState([]);
  
  // SETTING COUNT FOR POSTED REQUIREMENTS
  const [PostedCount, setPostedCount] = useState(0);
  const [EditedCount, setEditedCount] = useState(0);
  const [RenewedCount, setRenewedCount] = useState(0);

  useEffect(() => {
    dispatch(PO_GetAllAnalyticsData()).then((response) => {
      // console.log("Response ", response.payload.result.Propertys);
      setSP_Profile_Visited(response.payload.result.Analytics[0].Analytics.Profile_Visited_To);
      setSP_Profile_Received(response.payload.result.Analytics[0].Analytics.Profile_Visited_By);
      setSocialMediaConnects(response.payload.result.Analytics[0].Analytics.Social_Media_Visited_To);
      setEnquireProperties(response.payload.result.Propertys);
      
      // Count For Property Posted
      response.payload.result.Propertys.map(index => {
        if(index.Posted){
          Posted_Count = Posted_Count + 1;
        }
        if(index.Edited){
          Edited_Count = Edited_Count + 1;
        }
        if(index.Renewed){
          Renewed_Count = Renewed_Count + 1;
        }
      })
      setPostedCount(Posted_Count);
      setEditedCount(Edited_Count);
      setRenewedCount(Renewed_Count);

      response.payload.result.Analytics[0].Analytics.Social_Media_Visited_To.map(index => {
        if(index.Social_Media === "PhoneCall"){
          Phonecall_Count = Phonecall_Count + 1;
          PhoneCall_Record.push(index);
        }
        if(index.Social_Media === "WhatsApp"){
          Whatsapp_Count = Whatsapp_Count + 1;
          WhatsApp_Record.push(index);
        }
        if(index.Social_Media === "Website_URL"){
          Website_Count = Website_Count + 1;
          Website_Record.push(index);
        }
        if(index.Social_Media === "Facebook"){
          Facebook_Count = Facebook_Count + 1;
          Facebook_Record.push(index);
        }
        if(index.Social_Media === "Instagram"){
          Instagram_Count = Instagram_Count + 1;
          Instagram_Record.push(index);
        }
        if(index.Social_Media === "Linkedin"){
          Linkedin_Count = Linkedin_Count + 1;
          Linkedin_Record.push(index);
        }
        if(index.Social_Media === "Pinterest"){
          Pinterest_Count = Pinterest_Count + 1;
          Pinterest_Record.push(index);
        }
        if(index.Social_Media === "Quora"){
          Quora_Count = Quora_Count + 1;
          Quora_Record.push(index);
        }
        if(index.Social_Media === "Reddit"){
          Reddit_Count = Reddit_Count + 1;
          Reddit_Record.push(index);
        }
        if(index.Social_Media === "Twitter"){
          Twitter_Count = Twitter_Count + 1;
          Twitter_Record.push(index);
        }
        if(index.Social_Media === "Youtube"){
          Youtube_Count = Youtube_Count + 1;
          Youtube_Record.push(index);
        }
      })
      setPhoneCallCount(Phonecall_Count);
      setWhatsAppCount(Whatsapp_Count);
      setWebsiteCount(Website_Count);
      setFacebookCount(Facebook_Count);
      setInstagramCount(Instagram_Count);
      setLinkedinCount(Linkedin_Count);
      setPinterestCount(Pinterest_Count);
      setQuoraCount(Quora_Count);
      setRedditCount(Reddit_Count);
      setTwitterCount(Twitter_Count);
      setYoutubeCount(Youtube_Count);

      setShowLoading(false);
    });
  }, []);

  const handlelistview = (e) => {
    const heading = e.currentTarget;
    const analyticscontainer =
      heading.parentElement.parentElement.parentElement.parentElement
        .childNodes;

    if (heading.innerText === "View List and Details") {
      analyticscontainer.forEach((e) => {
        if (e.id === heading.id) {
          e.style.display = "block";
          heading.innerText = "Close List and Details";
          heading.style.color = "red";
          heading.style.textDecorationColor = "red";
          setTimeout(() => {
            e.style.opacity = 1;
            e.style.top = 0;
          }, 10);
        }
      });
    } else {
      analyticscontainer.forEach((e) => {
        if (e.id === heading.id) {
          e.style.opacity = 0;
          e.style.top = "-100px";
          heading.innerText = "View List and Details";
          heading.style.color = "#1dc295";

          heading.style.textDecorationColor = "#1dc295";
          setTimeout(() => {
            e.style.display = "none";
          }, 300);
        }
      });
    }
  };

  if(ShowLoading) {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <div className="po-Analytics-container">
      <div className="header">
           <p className="title">
            <b>Property Owner Profile Analytics</b>{" "}
          </p>
          <p className="description"
          >
            Here, you can view all the Stats related to your Profile & Property Requirements
          </p>
      </div>
        <div className="po-analytics">
       
          <br />
          <br />
          <div className="analytics-container">
            <div className="analytics-table-title">
              <p>Profile Activity</p>
            </div>
            <ParentTable
              mainheading="Property Requirements"
              subheadings={["Posted", "Edited", "Renewed"]}
              data={[PostedCount, EditedCount, RenewedCount]}
              handlelistview={handlelistview}
            />
            <br />
          </div>
  <br/>
          <div className="analytics-container">
          <div className="analytics-table-title">
              <p>Contact Activity & Engagement</p>
            </div>
            <ParentTable
              mainheading="Enquiry Stats"
              subheadings={["Service Professional Property Requirement Enquires Received"]}
              data={[EnquireProperties.length]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <EnquireReceived
              dataandtimeheading="Date & Time of Property Requirement Enquiry Received from the Service Professional Profile"
              id="Service Professional Property Requirement Enquires Received"
              ProfileDatas={EnquireProperties}
            />
            {/* <ListandDetails
              dataandtimeheading="Date & Time of WhatsApp Connect Received"
              id="Whatsapp Call"
            /> */}
            <br />
            <ParentTable
              mainheading="Visitor Stats"
              subheadings={[
                "Service Professional Profiles Visited",
                "Service Professional Profile Visits Received",
              ]}
              data={[SP_Profile_Visited.length, SP_Profile_Received.length]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <ListandDetails
              dataandtimeheading="Date & Time of Visiting the Service Professional Profile"
              id="Service Professional Profiles Visited"
              ProfileDatas={SP_Profile_Visited}
            />
            <ListandDetails
              dataandtimeheading="Date & Time of Profile Visit Received from the Service Professional Profile "
              id="Service Professional Profile Visits Received"
              ProfileDatas={SP_Profile_Received}
            />
            <br />
            <div className="analytics-container">
              <ParentTable
                mainheading="Service Professional Profiles Contacted Via NebourHood On"
                subheadings={[
                  "Phone Call",
                  "WhatsApp",
                  "Website Url",
                  "Facebook",
                  "Instagram",
                  "Linked In",
                  "Pinterest",
                  "Quora",
                  "Reddit",
                  "Twitter",
                  "Youtube",
                ]}
                data={[PhoneCallCount, 
                      WhatsAppCount, 
                      WebsiteCount, 
                      FacebookCount, 
                      InstagramCount, 
                      LinkedinCount, 
                      PinterestCount, 
                      QuoraCount, 
                      RedditCount, 
                      TwitterCount, 
                      YoutubeCount
                      ]}
                listanddetails={true}
                handlelistview={handlelistview}
              />
  
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Phone Call"
              ProfileDatas={PhoneCall_Record}
              MediaState="Phone Call"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="WhatsApp"
              ProfileDatas={WhatsApp_Record}
              MediaState="WhatsApp"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Website Url"
              ProfileDatas={Website_Record}
              MediaState="Website Url"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Facebook"
              ProfileDatas={Facebook_Record}
              MediaState="Facebook"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Instagram"
              ProfileDatas={Instagram_Record}
              MediaState="Instagram"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Linked In"
              ProfileDatas={Linkedin_Record}
              MediaState="Linked In"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Pinterest"
              ProfileDatas={Pinterest_Record}
              MediaState="Pinterest"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Quora"
              ProfileDatas={Quora_Record}
              MediaState="Quora"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Reddit"
              ProfileDatas={Reddit_Record}
              MediaState="Reddit"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Twitter"
              ProfileDatas={Twitter_Record}
              MediaState="Twitter"
            />
            <SocialMediaTable
              dataandtimeheading="Date & Time of Contact by "
              id="Youtube"
              ProfileDatas={Youtube_Record}
              MediaState="Youtube"
            />
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }

}

export default PO_Analytics;
