import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  SPUpdateProfileDetails,
  // SP_Verify_PhoneNumber,
  // SP_Verify_PhoneNumber_ViaOTP,
} from "../../../../../../../../_actions/company_actions";
import Declaration from "../../../../../../../utils/Declaration/Declaration";
import LoadingIcon from "../../../../../../../utils/LoadingIcon/LoadingIcon";
// import VerifyNoModal from "../../../../../../../utils/VerifyNoModal/VerifyNoModal";
import { Location } from "./Data";
import "./EditProfile.css";

function EditProfile(props) {
  const company = useSelector((state) => state.company);
  const dispatch = useDispatch();

  // const [ShowOTPModal, setShowOTPModal] = useState(false);
  // const [VerifiedOTPEntered, setVerifiedOTPEntered] = useState(null);
  // const [verified, setverified] = useState(
  //   company.companyData.Verified_PhoneNumber
  // );

  //Personal Details
  const [serviceprofessionalname, setserviceprofessionalname] = useState(
    company.companyData.name
  );
  const [dob, setdob] = useState(company.companyData.Personal_Details.DOB);

  const [gender, setgender] = useState(
    company.companyData.Personal_Details.Gender
  );
  //languages known
  const [assamese, setassamese] = useState(
    company.companyData.Personal_Details.Languages_Known.Assamese
  );
  const [gujarati, setgujarati] = useState(
    company.companyData.Personal_Details.Languages_Known.Gujarati
  );
  const [malayalam, setmalayalam] = useState(
    company.companyData.Personal_Details.Languages_Known.Malayalam
  );
  const [punjabi, setpunjabi] = useState(
    company.companyData.Personal_Details.Languages_Known.Punjabi
  );
  const [urdu, seturdu] = useState(
    company.companyData.Personal_Details.Languages_Known.Urdu
  );
  const [bengali, setbengali] = useState(
    company.companyData.Personal_Details.Languages_Known.Bengali
  );
  const [hindi, sethindi] = useState(
    company.companyData.Personal_Details.Languages_Known.Hindi
  );
  const [marathi, setmarathi] = useState(
    company.companyData.Personal_Details.Languages_Known.Marathi
  );
  const [tamil, settamil] = useState(
    company.companyData.Personal_Details.Languages_Known.Tamil
  );
  const [english, setenglish] = useState(
    company.companyData.Personal_Details.Languages_Known.English
  );
  const [kannada, setkannada] = useState(
    company.companyData.Personal_Details.Languages_Known.Kannada
  );
  const [odia, setodia] = useState(
    company.companyData.Personal_Details.Languages_Known.Odia
  );
  const [telugu, settelugu] = useState(
    company.companyData.Personal_Details.Languages_Known.Telugu
  );

  const languagesknown = {
    Assamese: assamese,
    Gujarati: gujarati,
    Malayalam: malayalam,
    Punjabi: punjabi,
    Urdu: urdu,
    Bengali: bengali,
    Hindi: hindi,
    Marathi: marathi,
    Tamil: tamil,
    English: english,
    Kannada: kannada,
    Odia: odia,
    Telugu: telugu,
  };

  const handleassamese = () => {
    // console.log(!assamese);
    setassamese(!assamese);
  };

  const handlegujarati = () => {
    // console.log(!gujarati);
    setgujarati(!gujarati);
  };

  const handlemalayalam = () => {
    // console.log(!malayalam);
    setmalayalam(!malayalam);
  };
  const handlepunjabi = () => {
    // console.log(!punjabi);
    setpunjabi(!punjabi);
  };

  const handleurdu = () => {
    // console.log(!urdu);
    seturdu(!urdu);
  };

  const handlebengali = () => {
    // console.log(!bengali);
    setbengali(!bengali);
  };

  const handlehindi = () => {
    // console.log(!hindi);
    sethindi(!hindi);
  };
  const handlemarathi = () => {
    // console.log(!marathi);
    setmarathi(!marathi);
  };

  const handletamil = () => {
    // console.log(!tamil);
    settamil(!tamil);
  };

  const handleenglish = () => {
    // console.log(!english);
    setenglish(!english);
  };

  const handlekannada = () => {
    // console.log(!kannada);
    setkannada(!kannada);
  };

  const handleodia = () => {
    // console.log(!odia);
    setodia(!odia);
  };

  const handletelugu = () => {
    // console.log(!telugu);
    settelugu(!telugu);
  };

  var Hobbies = [];
  var PersonalAwards = [];

  // // const [skillshobbies, setskillshobbies] = useState();
  // const [personalawards, setpersonalawards] = useState([]);
  // const [adhaarno, setadhaarno] = useState(company.companyData.Personal_Details.Aadhaar_Card);
  const [noofhobbies, setnoofhobbies] = useState([]);
  const [noofpersonalawards, setnoofpersonalawards] = useState([]);

  useEffect(() => {
    company.companyData.Personal_Details.Personal_Skills_Hobbies.forEach(
      (hobbie) => noofhobbies.push(hobbie)
    );
    company.companyData.Personal_Details.Personal_Awards_Achievements.forEach(
      (award) => noofpersonalawards.push(award)
    );
  }, []);

  const PersonalDetails = {
    ServiceProfessionalName: serviceprofessionalname,
    DateofBirth: dob,
    Gender: gender,
    Languagesknown: languagesknown,
    SkillsandHobbies: Hobbies,
    PersonalAwards: PersonalAwards,
    // AdhaarNo: adhaarno,
  };

  // Proffessional Details
  var ProfessionalSkills = [];
  var ProfessionalAwards = [];

  const [educationalqualification, seteducationalqualification] = useState(
    company.companyData.Professional_Details.Educational_Qualification
  );
  const [profession, setprofession] = useState(
    company.companyData.Professional_Details.Professional_Profession
  );
  const [professiontype, setprofessiontype] = useState(
    company.companyData.Professional_Details.Profession_Type.Type
  );
  const [freelancer, setfreelancer] = useState(false);
  const [freelancercompany, setfreelancercompany] = useState("");
  const [businessowner, setbusinessowner] = useState(
    company.companyData.Professional_Details.Profession_Type.Gst ? true : false
  );
  const [businessownercompany, setbusinessownercompany] = useState("");
  const [businessownergst, setbusinessownergst] = useState(
    company.companyData.Professional_Details.Profession_Type.Gst
  );
  // company.companyData.Professional_Details.Professional_Profession_Type
  // console.log(company.companyData.Professional_Details);
  // const [professionalskills, setprofessionalskills] = useState([]);
  // const [professionalawards, setprofessionalawards] = useState([]);
  const [overallexperience, setoverallexperience] = useState(
    company.companyData.Professional_Details.Overall_Experience
  );
  const [projectcompleted, setprojectcompleted] = useState(
    company.companyData.Professional_Details.Project_Completed
  );
  const [noofprofessionalskills, setnoofprofessionalskills] = useState([]);
  const [noofprofessionalawards, setnoofprofessionalawards] = useState([]);

  useEffect(() => {
    company.companyData.Professional_Details.Personal_Awards_Achievements.forEach(
      (award) => noofprofessionalawards.push(award)
    );
    company.companyData.Professional_Details.Personal_Skills.forEach((skill) =>
      noofprofessionalskills.push(skill)
    );
  }, []);

  const ProfessionalDetails = {
    EducationalQualification: educationalqualification,
    Profession: profession,
    ProfessionType: {
      Type: professiontype,
      Company: freelancer ? freelancercompany : businessownercompany,
      Gst: businessowner ? businessownergst : "",
    },
    ProfessionalSkills: ProfessionalSkills,
    ProfessionalAwards: ProfessionalAwards,
    OverallExperience: overallexperience,
    ProjectCompleted: projectcompleted,
  };

  // Company Details
  var CompanyAwards = [];

  const [companyname, setcompanyname] = useState(
    company.companyData.Company_Details.Company_Name
  );
  const [yearofestablishment, setyearofestablishment] = useState(
    company.companyData.Company_Details.Year_of_Establishment
  );
  const [noofemployees, setnoofemployees] = useState(
    company.companyData.Company_Details.Number_of_Employees
  );
  // Work Days
  const [monday, setmonday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Monday
  );
  const [tuesday, settuesday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Tuesday
  );
  const [wednesday, setwednesday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Wednesday
  );
  const [thursday, setthursday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Thursday
  );
  const [friday, setfriday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Friday
  );
  const [saturday, setsaturday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Saturday
  );
  const [sunday, setsunday] = useState(
    company.companyData.Company_Details.Workdays_Timings.Days.Sunday
  );

  const [timing, settiming] = useState({
    From: company.companyData.Company_Details.Workdays_Timings.Timing.From,
    To: company.companyData.Company_Details.Workdays_Timings.Timing.To,
  });
  // const [companyawards, setcompanyawards] = useState([]);
  const [aboutthecompany, setaboutthecompany] = useState(
    company.companyData.Company_Details.About_the_Company
  );
  const [noofcompanyawards, setnoofcompanyawards] = useState([]);

  useEffect(() => {
    company.companyData.Company_Details.Company_Award_Achievements.forEach(
      (award) => noofcompanyawards.push(award)
    );
  }, []);

  const handlemonday = (e) => {
    setmonday(e.target.checked);
  };
  const handletuesday = (e) => {
    settuesday(e.target.checked);
  };
  const handlewednesday = (e) => {
    setwednesday(e.target.checked);
  };
  const handlethursday = (e) => {
    setthursday(e.target.checked);
  };
  const handlefriday = (e) => {
    setfriday(e.target.checked);
  };
  const handlesaturday = (e) => {
    setsaturday(e.target.checked);
  };
  const handlesunday = (e) => {
    setsunday(e.target.checked);
  };

  const CompanyDetails = {
    CompnayName: companyname,
    YearofEstablishment: yearofestablishment,
    NoofEmployees: noofemployees,
    WorkDays: {
      Monday: monday,
      Tuesday: tuesday,
      Wednesday: wednesday,
      Thursday: thursday,
      Friday: friday,
      Saturday: saturday,
      Sunday: sunday,
    },
    Timing: {
      From: timing.From,
      To: timing.To,
    },
    CompanyAwards: CompanyAwards,
    AbouttheCompany: aboutthecompany,
  };

  // Contact Details

  const [userphoneno1, setuserphoneno1] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Number
      .User_Phone_Number
  );
  const [userphoneno2, setuserphoneno2] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Number.Phone_Number_2
  );
  const [userphoneno3, setuserphoneno3] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Number.Phone_Number_3
  );
  const [userwhatsappnumber, setuserwhatsappnumber] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Number.Company_WhatsApp
  );
  const [useremailid1, setuseremailid1] = useState(company.companyData.email);
  const [useremailid2, setuseremailid2] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Email.Email_1
  );
  const [useremailid3, setuseremailid3] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Email.Email_2
  );
  const [websiteurl, setwebsiteurl] = useState(
    company.companyData.Contact_Details.Website_URL
  );
  const [facebook, setfacebook] = useState(
    company.companyData.Contact_Details.Social_Media.Facebook
  );
  const [instagram, setinstagram] = useState(
    company.companyData.Contact_Details.Social_Media.Instagram
  );
  const [pinterest, setpinterest] = useState(
    company.companyData.Contact_Details.Social_Media.Pinterest
  );
  const [twitter, settwitter] = useState(
    company.companyData.Contact_Details.Social_Media.Twitter
  );
  const [reddit, setreddit] = useState(
    company.companyData.Contact_Details.Social_Media.Reddit
  );
  const [quora, setquora] = useState(
    company.companyData.Contact_Details.Social_Media.Quora
  );
  const [youtube, setyoutube] = useState(
    company.companyData.Contact_Details.Social_Media.Youtube
  );
  const [linkedin, setlinkedin] = useState(
    company.companyData.Contact_Details.Social_Media.Linkedin
  );
  const [userhousename, setuserhousename] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address
      .House_Builing_Name
  );
  const [userstreetname, setuserstreetname] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address
      .Street_Road_Name
  );
  const [userlocalityname, setuserlocalityname] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.Locality
  );
  const [userlandmark, setuserlandmark] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.Landmark
  );
  const [State, setState] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.State
  );
  const [City, setCity] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.City
  );
  const [userareaname, setuserareaname] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.Area_Name
  );
  const [userpincode, setuserpincode] = useState(
    company.companyData.Contact_Details.Main_Head_Office_Address.Pincode
  );

  const cities =
    Location.Cities.filter((city) => city.State === State).length > 0
      ? Location.Cities.filter((city) => city.State === State)[0].Cities
      : [];

  const ContactDetails = {
    MainHeadOfficeNumber: {
      UserPhoneNo1: userphoneno1,
      UserPhoneNo2: userphoneno2,
      UserPhoneNo3: userphoneno3,
      UserWhatsappNumber: userwhatsappnumber,
    },
    MainHeadOfficeEmailID: {
      UserEmailID1: useremailid1,
      UserEmialID2: useremailid2,
      UserEmailID3: useremailid3,
    },
    WebsiteUrl: websiteurl,
    SocialMediaLinks: {
      Facebook: facebook,
      Instagram: instagram,
      Pinterest: pinterest,
      Twitter: twitter,
      Reddit: reddit,
      Quora: quora,
      Youtube: youtube,
      LinkedIn: linkedin,
    },
    MainHeadOfficeAddress: {
      HouseName: userhousename,
      StreetName: userstreetname,
      LocalityName: userlocalityname,
      Landmark: userlandmark,
      State: State,
      City: City,
      Areaname: userareaname,
      Pincode: userpincode,
    },
  };

  // Branch Details
  const [branches, setbranches] = useState(false);
  const [branchesarray, setbranchesarray] = useState(
    company.companyData.Branches
  );

  const [branchphoneno1, setbranchphoneno1] = useState("");
  const [branchphoneno2, setbranchphoneno2] = useState("");
  const [branchwhatsappphone, setbranchwhatsappphone] = useState("");
  const [branchemailid1, setbranchemailid1] = useState("");
  const [branchemailid2, setbranchemailid2] = useState("");
  const [branchhousename, setbranchhousename] = useState("");
  const [branchstreetname, setbranchstreetname] = useState("");
  const [branchlocalityname, setbranchlocalityname] = useState("");
  const [branchlandmark, setbranchlandmark] = useState("");
  const [branchstate, setbranchstate] = useState("");
  const [branchcity, setbranchcity] = useState("");
  const [branchareaname, setbranchareaname] = useState("");
  const [branchpincode, setbranchpincode] = useState("");

  const [allbranchitemsfilled, setallbranchitemsfilled] = useState(false);

  const [alteredstate, setalteredstate] = useState("");

  const addnewbranchref = useRef(null);
  const BranchDetails = {
    Id: "Branch-" + branchesarray.length,
    BranchOfficePhoneNumber: {
      PhoneNo1: branchphoneno1,
      PhoneNo2: branchphoneno2,
      WhatsappPhoneNo: branchwhatsappphone,
    },
    BranchOfficeEmailID: {
      EmailID1: branchemailid1,
      EmailID2: branchemailid2,
    },
    BranchOfficeAddress: {
      HouseName: branchhousename,
      StreetName: branchstreetname,
      LocalityName: branchlocalityname,
      Landmark: branchlandmark,
      State: branchstate,
      City: branchcity,
      Areaname: branchareaname,
      Pincode: branchpincode,
    },
  };

  const branchcities =
    Location.Cities.filter((city) => city.State === branchstate).length > 0
      ? Location.Cities.filter((city) => city.State === branchstate)[0].Cities
      : [];
  const updatedcities =
    Location.Cities.filter((city) => city.State === alteredstate).length > 0
      ? Location.Cities.filter((city) => city.State === alteredstate)[0].Cities
      : [];

  const branchinputitems = [];
  const branchselectboxtitems = [];
  // const days = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];

  // Project Catered To

  const [interiorresidential, setinteriorresidential] = useState(
    company.companyData.Project_Catered.Interior_Design.Residential
  );
  const [interiorcommercial, setinteriorcommercial] = useState(
    company.companyData.Project_Catered.Interior_Design.Commercial
  );

  const [architecturalresidential, setarchitecturalresidential] = useState(
    company.companyData.Project_Catered.Architectural_Construction.Residential
  );
  const [architecturalcommercial, setarchitecturalcommercial] = useState(
    company.companyData.Project_Catered.Architectural_Construction.Commercial
  );

  const [renovationresidential, setrenovationresidential] = useState(
    company.companyData.Project_Catered.Renovation.Residential
  );
  const [renovationcommercial, setrenovationcommercial] = useState(
    company.companyData.Project_Catered.Renovation.Commercial
  );

  const [consultation, setconsultation] = useState(
    company.companyData.Project_Catered.Consultation
  );

  const ProjectsCateredTo = {
    ProjectType: {
      Interior: {
        Residential: interiorresidential,
        Commercial: interiorcommercial,
      },
      Architectural: {
        Residential: architecturalresidential,
        Commercial: architecturalcommercial,
      },
      Renovation: {
        Residential: renovationresidential,
        Commercial: renovationcommercial,
      },
    },
    Consultation: consultation,
  };

  // Services Provided

  // Initial Consultation

  const [companyoffice, setcompanyoffice] = useState(
    company.companyData.Service_Provided.Initial_Consultation.Company_Office
  );
  const [site, setsite] = useState(
    company.companyData.Service_Provided.Initial_Consultation.Site
  );

  const initialconsultationfuncs = [setcompanyoffice, setsite];

  const handlecompanyoffice = (e) => {
    setcompanyoffice(e.target.checked);
  };

  const handlesite = (e) => {
    setsite(e.target.checked);
  };

  const InitialConsultation = {
    CompanyOffice: companyoffice,
    Site: site,
  };

  // Software used
  const [interiordesign, setinteriordesign] = useState(
    company.companyData.Service_Provided.Software_Used.Interior_Design
  );
  const [architecturalconstruction, setarchitecturalconstruction] = useState(
    company.companyData.Service_Provided.Software_Used
      .Architectural_Construction
  );
  const [civilengineering, setcivilengineering] = useState(
    company.companyData.Service_Provided.Software_Used.Civil_Engineering
  );

  const softwaresusedfuncs = [
    setinteriordesign,
    setarchitecturalconstruction,
    setcivilengineering,
  ];

  const SoftwaresUsed = {
    InteriorDesign: interiordesign,
    ArchitecturalConstruction: architecturalconstruction,
    CivilEngineering: civilengineering,
  };

  const handleinteriordesign = (e) => {
    setinteriordesign(e.target.checked);
  };

  const handlearchitecturalconstruction = (e) => {
    setarchitecturalconstruction(e.target.checked);
  };

  const handlecivilengineering = (e) => {
    setcivilengineering(e.target.checked);
  };

  // Design Schematics

  const [plans, setplans] = useState(
    company.companyData.Service_Provided.Design_Schematics.Plans
  );
  const [drawingsketches, setdrawingsketches] = useState(
    company.companyData.Service_Provided.Design_Schematics.Drawing_Sketches
  );
  const [design, setdesign] = useState(
    company.companyData.Service_Provided.Design_Schematics.Design
  );
  const [dsconsultation, setdsconsultation] = useState(
    company.companyData.Service_Provided.Design_Schematics.Consultation
  );
  const [elevation, setelevation] = useState(
    company.companyData.Service_Provided.Design_Schematics.Elevations
  );
  const [renders, setrenders] = useState(
    company.companyData.Service_Provided.Design_Schematics.Renders
  );
  const [walkthrough, setwalkthrough] = useState(
    company.companyData.Service_Provided.Design_Schematics.Walk_Through
  );

  const designschematicsfuncs = [
    setplans,
    setdrawingsketches,
    setdesign,
    setdsconsultation,
    setelevation,
    setrenders,
    setwalkthrough,
  ];

  const DesignSchematics = {
    Plans: plans,
    DwawingSketches: drawingsketches,
    Design: design,
    DSConsultation: dsconsultation,
    elevation: elevation,
    Renders: renders,
    WalkThrough: walkthrough,
  };

  const handleplans = (e) => {
    setplans(e.target.checked);
  };
  const handledrawingsketches = (e) => {
    setdrawingsketches(e.target.checked);
  };
  const handledesign = (e) => {
    setdesign(e.target.checked);
  };
  const handledsconsultation = (e) => {
    setdsconsultation(e.target.checked);
  };
  const handleelevation = (e) => {
    setelevation(e.target.checked);
  };
  const handlerenders = (e) => {
    setrenders(e.target.checked);
  };
  const handlewalkthrough = (e) => {
    setwalkthrough(e.target.checked);
  };

  // Booking Confirmation

  const [bookingconfirmation, setbookingconfirmation] = useState(
    company.companyData.Service_Provided.Booking_Confirmation
  );
  const [legalagreements, setlegalagreements] = useState(
    company.companyData.Service_Provided.Legal_Agreements
  );
  const [projecttimeframe, setprojecttimeframe] = useState(
    company.companyData.Service_Provided.Project_Timeframe
  );
  const [execution, setexecution] = useState(
    company.companyData.Service_Provided.Execution
  );

  const handlebookingconfirmation = (e) => {
    setbookingconfirmation(e.target.checked);
  };
  const handlelegalagreements = (e) => {
    setlegalagreements(e.target.checked);
  };
  const handleprojecttimeframe = (e) => {
    setprojecttimeframe(e.target.checked);
  };
  const handleexecution = (e) => {
    setexecution(e.target.checked);
  };

  // Types of Properties Served

  // Residential

  const [apartment, setapartment] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Apartment
  );
  const [penthouse, setpenthouse] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Penthouse
  );
  const [standalonebuilding, setstandalonebuilding] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Stan_Alone_Building
  );
  const [independenthome, setindependenthome] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Independent_House
  );
  const [villa, setvilla] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Villa
  );
  const [bungalow, setbungalow] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Residential
      .Bungalow
  );

  const Residential = {
    Apartment: apartment,
    Penthouse: penthouse,
    StandAloneBuilding: standalonebuilding,
    IndependentHome: independenthome,
    Villa: villa,
    Bungalow: bungalow,
  };

  const handleapartment = (e) => {
    setapartment(e.target.checked);
  };
  const handlepenthouse = (e) => {
    setpenthouse(e.target.checked);
  };
  const handlestandalonebuilding = (e) => {
    setstandalonebuilding(e.target.checked);
  };
  const handleindependenthome = (e) => {
    setindependenthome(e.target.checked);
  };
  const handlevilla = (e) => {
    setvilla(e.target.checked);
  };
  const handlebungalow = (e) => {
    setbungalow(e.target.checked);
  };

  // Commercial

  const [retail, setretail] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Retail
  );
  const [hospitality, sethospitality] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Hospitality
  );
  const [healthcare, sethealthcare] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Healthcare
  );
  const [office, setoffice] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Office
  );
  const [industrial, setindustrial] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Industrial
  );

  const Commercial = {
    Retail: retail,
    Hospitality: hospitality,
    HealthCare: healthcare,
    Office: office,
    Industrial: industrial,
  };

  const handleretail = (e) => {
    setretail(e.target.checked);
  };
  const handlehospitality = (e) => {
    sethospitality(e.target.checked);
  };
  const handlehealthcare = (e) => {
    sethealthcare(e.target.checked);
  };
  const handleoffice = (e) => {
    setoffice(e.target.checked);
  };
  const handleindustrial = (e) => {
    setindustrial(e.target.checked);
  };

  // Institutional

  const [educationalsector, seteducationalsector] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Institutional.Educational_Sector
  );
  const [financialsector, setfinancialsector] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Institutional.Financial_Sector
  );
  const [mediaandentertainmentsector, setmediaandentertainmentsector] =
    useState(
      company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
        .Institutional.Media_Entertainment_Sector
    );
  const [researchanddevelopmentsector, setresearchanddevelopmentsector] =
    useState(
      company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
        .Institutional.Research_Development_Sector
    );
  const [transportationsector, settransportationsector] = useState(
    company.companyData.Service_Provided.Type_of_Properties_Served.Commercial
      .Institutional.Transportation_Sector
  );

  const TypesOfPropertiesServedfuncs = {
    Residentialfuncs: [
      setapartment,
      setpenthouse,
      setstandalonebuilding,
      setindependenthome,
      setvilla,
      setbungalow,
    ],
    Commercialfuncs: [
      setretail,
      sethospitality,
      sethealthcare,
      setoffice,
      setindustrial,
    ],
    Institutionalfuncs: [
      seteducationalsector,
      setfinancialsector,
      setmediaandentertainmentsector,
      setresearchanddevelopmentsector,
      settransportationsector,
    ],
  };

  const Instututional = {
    EducationalSector: educationalsector,
    FinancialSector: financialsector,
    MediaandEntertainmentSector: mediaandentertainmentsector,
    ResearchanddDevelopmentSector: researchanddevelopmentsector,
    TransportationSector: transportationsector,
  };

  const handleeducationalsector = (e) => {
    seteducationalsector(e.target.checked);
  };
  const handlefinancialsector = (e) => {
    setfinancialsector(e.target.checked);
  };
  const handlemediaandentertainmentsector = (e) => {
    setmediaandentertainmentsector(e.target.checked);
  };

  const handleresearchanddevelopmentsector = (e) => {
    setresearchanddevelopmentsector(e.target.checked);
  };
  const handletransportationsector = (e) => {
    settransportationsector(e.target.checked);
  };

  const TypesOfPropertiesServed = {
    Residential: Residential,
    Commercial: Commercial,
    Instututional: Instututional,
  };

  // Interior Design Execution Services

  const [hardfinishes, sethardfinishes] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Hard_Finishes
  );
  const [furniturefixturesequipment, setfurniturefixturesequipment] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Furniture
  );
  const [softfinishes, setsoftfinishes] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Soft_Finishes
  );
  const [artanddecor, setartanddecor] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Art_Decore
  );
  const [ecofreindlyservices, setecofreindlyservices] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Eco_Friendly
  );
  const [techsavvy, settechsavvy] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Tech_Savvy
  );
  const [disabledhandicap, setdisabledhandicap] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Disabled_Handicap
  );
  const [healthandsafetyservices, sethealthandsafetyservices] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Health_Safety
  );
  const [securitysystemsservices, setsecuritysystemsservices] = useState(
    company.companyData.Service_Provided.Interior_Design_Execution_Services
      .Security_Systems
  );

  const interiordesignexecutionservicesfuncs = [
    sethardfinishes,
    setfurniturefixturesequipment,
    setsoftfinishes,
    setartanddecor,
    setecofreindlyservices,
    settechsavvy,
    setdisabledhandicap,
    sethealthandsafetyservices,
    setsecuritysystemsservices,
  ];

  const InteriorDesignExecutionServices = {
    HardFinishes: hardfinishes,
    FurnitureFixtureandEquipment: furniturefixturesequipment,
    SoftFinishes: softfinishes,
    ArtandDecor: artanddecor,
    EcoFreindly: ecofreindlyservices,
    TechSavvy: techsavvy,
    DisabledHandicap: disabledhandicap,
    HealthandSafety: healthandsafetyservices,
    SecuritySystem: securitysystemsservices,
  };

  const handlehardfinishes = (e) => {
    sethardfinishes(e.target.checked);
  };
  const handlefurniturefixturesequipment = (e) => {
    setfurniturefixturesequipment(e.target.checked);
  };
  const handlesoftfinishes = (e) => {
    setsoftfinishes(e.target.checked);
  };
  const handleartanddecor = (e) => {
    setartanddecor(e.target.checked);
  };
  const handleecofreindlyservices = (e) => {
    setecofreindlyservices(e.target.checked);
  };
  const handletechsavvy = (e) => {
    settechsavvy(e.target.checked);
  };
  const handledisabledhandicap = (e) => {
    setdisabledhandicap(e.target.checked);
  };
  const handlehealthandsafetyservices = (e) => {
    sethealthandsafetyservices(e.target.checked);
  };
  const handlesecuritysystemsservices = (e) => {
    setsecuritysystemsservices(e.target.checked);
  };

  // Architectural Construction Execution Services

  // Grey Structure

  const [soiltesting, setsoiltesting] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Grey_Structure.Soil_Testing
  );
  const [siteclearance, setsiteclearance] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Grey_Structure.Site_Clearance
  );
  const [sitelayout, setsitelayout] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Grey_Structure.Site_Layout
  );
  const [substructurecontructionphase, setsubstructurecontructionphase] =
    useState(
      company.companyData.Service_Provided.Architectural_Construction_Execution
        .Grey_Structure.Substructure_Construction
    );
  const [superstructurecontructionphase, setsuperstructurecontructionphase] =
    useState(
      company.companyData.Service_Provided.Architectural_Construction_Execution
        .Grey_Structure.Super_Structure
    );

  const ArchitecturalConstructionExecutionServicesfuncs = {
    GreyStructurefuncs: [
      setsoiltesting,
      setsiteclearance,
      setsitelayout,
      setsubstructurecontructionphase,
      setsuperstructurecontructionphase,
    ],
  };

  const ArchitecturalConstructionExecutionServices = {
    GreyStructure: {
      SoilTesting: soiltesting,
      SiteClearance: siteclearance,
      SiteLayout: sitelayout,
      SubstructureConstructionPhase: substructurecontructionphase,
      SuperStructureConstructionPhase: superstructurecontructionphase,
    },
  };

  const handlesoiltesting = (e) => {
    setsoiltesting(e.target.checked);
  };
  const handlesiteclearance = (e) => {
    setsiteclearance(e.target.checked);
  };
  const handlesitelayout = (e) => {
    setsitelayout(e.target.checked);
  };
  const handlesubstructurecontructionphase = (e) => {
    setsubstructurecontructionphase(e.target.checked);
  };
  const handlesuperstructurecontructionphase = (e) => {
    setsuperstructurecontructionphase(e.target.checked);
  };

  const [disabledhandicapservices, setdisabledhandicapservices] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Disabled_Handicap
  );
  const [healthandsafety, sethealthandsafety] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Health_Safety
  );
  const [securitysystems, setsecuritysystems] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Security_System
  );
  const [finishing, setfinishing] = useState(
    company.companyData.Service_Provided.Architectural_Construction_Execution
      .Finishing
  );

  const handledisabledhandicapservices = (e) => {
    setdisabledhandicapservices(e.target.checked);
  };
  const handlehealthandsafety = (e) => {
    sethealthandsafety(e.target.checked);
  };
  const handlesecuritysystems = (e) => {
    setsecuritysystems(e.target.checked);
  };
  const handlefinishing = (e) => {
    setfinishing(e.target.checked);
  };

  // Civil & Renovation Services

  const [sameasinterior, setsameasinterior] = useState(
    company.companyData.Service_Provided.Civil_Renovation.Interior_Design
  );
  const [sameasarchitectural, setsameasarchitectural] = useState(
    company.companyData.Service_Provided.Civil_Renovation
      .Architectural_Construction
  );

  const CivilandRenovation = {
    SameastheaboveselectedInteriorDesignExecutionServices: sameasinterior,
    SameastheaboveselectedArchitecturalConstructionExecutionServices:
      sameasarchitectural,
  };

  const handlesameasinterior = (e) => {
    setsameasinterior(e.target.checked);
  };

  const handlesameasarchitectural = (e) => {
    setsameasarchitectural(e.target.checked);
  };

  // Other Services

  const [assemblingfurniture, setassemblingfurniture] = useState(
    company.companyData.Service_Provided.Other_Services.Assembling
  );
  const [heavyitemliftingandmoving, setheavyitemliftingandmoving] = useState(
    company.companyData.Service_Provided.Other_Services.Heavy_Items
  );
  const [householdcores, sethouseholdcores] = useState(
    company.companyData.Service_Provided.Other_Services.Household
  );
  const [packersandmovers, setpackersandmovers] = useState(
    company.companyData.Service_Provided.Other_Services.Packers_Movers
  );
  const [pestcontrol, setpestcontrol] = useState(
    company.companyData.Service_Provided.Other_Services.Pest_Control
  );

  const otherservicesfuncs = [
    setassemblingfurniture,
    setheavyitemliftingandmoving,
    sethouseholdcores,
    setpackersandmovers,
    setpestcontrol,
  ];

  const OtherServices = {
    AssemblingFurniture: assemblingfurniture,
    HeavyItemLiftinandMoving: heavyitemliftingandmoving,
    HouseHoldCors: householdcores,
    PackersandMovers: packersandmovers,
    PestControl: pestcontrol,
  };

  const handleassemblingfurniture = (e) => {
    setassemblingfurniture(e.target.checked);
  };
  const handleheavyitemliftingandmoving = (e) => {
    setheavyitemliftingandmoving(e.target.checked);
  };
  const handlehouseholdcores = (e) => {
    sethouseholdcores(e.target.checked);
  };
  const handlepackersandmovers = (e) => {
    setpackersandmovers(e.target.checked);
  };
  const handlepestcontrol = (e) => {
    setpestcontrol(e.target.checked);
  };

  // Products and Accessories

  const [appliances, setappliances] = useState(
    company.companyData.Service_Provided.Product_Accessories.Appilances
  );
  const [artwork, setartwork] = useState(
    company.companyData.Service_Provided.Product_Accessories.Artwork
  );
  const [decor, setdecor] = useState(
    company.companyData.Service_Provided.Product_Accessories.Decor
  );
  const [furniture, setfurniture] = useState(
    company.companyData.Service_Provided.Product_Accessories.Furniture
  );
  const [hardware, sethardware] = useState(
    company.companyData.Service_Provided.Product_Accessories.Hardware
  );
  const [mirrors, setmirrors] = useState(
    company.companyData.Service_Provided.Product_Accessories.Mirrors
  );
  const [modularcustomizedfurniture, setmodularcustomizedfurniture] = useState(
    company.companyData.Service_Provided.Product_Accessories.Modular_Customized
  );
  const [painting, setpainting] = useState(
    company.companyData.Service_Provided.Product_Accessories.Painting
  );
  const [upholstery, setupholstery] = useState(
    company.companyData.Service_Provided.Product_Accessories.Upholstery
  );
  const [wallpapers, setwallpapers] = useState(
    company.companyData.Service_Provided.Product_Accessories.Wallpapers
  );

  const productsandappliancesfuncs = [
    setappliances,
    setartwork,
    setdecor,
    setfurniture,
    sethardware,
    setmirrors,
    setmodularcustomizedfurniture,
    setpainting,
    setupholstery,
    setwallpapers,
  ];

  const ProductsandAccessories = {
    Appliances: appliances,
    Artwork: artwork,
    Decor: decor,
    Furniture: furniture,
    Hardware: hardware,
    Mirrors: mirrors,
    ModularCustomizedFurniture: modularcustomizedfurniture,
    Painting: painting,
    Upholstery: upholstery,
    Wallpapers: wallpapers,
  };

  const handleappliances = (e) => {
    setappliances(e.target.checked);
  };
  const handleartwork = (e) => {
    setartwork(e.target.checked);
  };
  const handledecor = (e) => {
    setdecor(e.target.checked);
  };
  const handlefurniture = (e) => {
    setfurniture(e.target.checked);
  };
  const handlehardware = (e) => {
    sethardware(e.target.checked);
  };
  const handlemirrors = (e) => {
    setmirrors(e.target.checked);
  };
  const handlemodularcustomizedfurniture = (e) => {
    setmodularcustomizedfurniture(e.target.checked);
  };
  const handlepainting = (e) => {
    setpainting(e.target.checked);
  };
  const handleupholstery = (e) => {
    setupholstery(e.target.checked);
  };
  const handlewallpapers = (e) => {
    setwallpapers(e.target.checked);
  };

  // Final Review and HandOver

  const [finalbuilinginspection, setfinalbuilinginspection] = useState(
    company.companyData.Service_Provided.Final_Review_Handover.Final_Builing
  );
  const [
    confirmationoffinalpaymentreceived,
    setconfirmationoffinalpaymentreceived,
  ] = useState(
    company.companyData.Service_Provided.Final_Review_Handover
      .Confirmation_Payment
  );
  const [documentation, setdocumentation] = useState(
    company.companyData.Service_Provided.Final_Review_Handover.Documentation
  );
  const [handover, sethandover] = useState(
    company.companyData.Service_Provided.Final_Review_Handover.Handover
  );
  const [photoshootandvideography, setphotoshootandvideography] = useState(
    company.companyData.Service_Provided.Final_Review_Handover
      .Photoshoot_Videography
  );

  const finalreviewandhandoverfuncs = [
    setfinalbuilinginspection,
    setconfirmationoffinalpaymentreceived,
    setdocumentation,
    sethandover,
    setphotoshootandvideography,
  ];

  const FinalReviewandHandOver = {
    FinalBuildingInspection: finalbuilinginspection,
    ConfirmationOfFinalPaymentReceived: confirmationoffinalpaymentreceived,
    Documentation: documentation,
    Handover: handover,
    PhotoshootandVideoGraphy: photoshootandvideography,
  };

  const handlefinalbuilinginspection = (e) => {
    setfinalbuilinginspection(e.target.checked);
  };
  const handleconfirmationoffinalpaymentreceived = (e) => {
    setconfirmationoffinalpaymentreceived(e.target.checked);
  };
  const handledocumentation = (e) => {
    setdocumentation(e.target.checked);
  };
  const handlehandover = (e) => {
    sethandover(e.target.checked);
  };
  const handlephotoshootandvideography = (e) => {
    setphotoshootandvideography(e.target.checked);
  };

  // After - Sales Support

  const [aftersalessupport, setaftersalessupport] = useState(
    company.companyData.Service_Provided.After_Sales
  );
  const handleaftersalessupport = (e) => {
    setaftersalessupport(e.target.checked);
  };

  // Design Specialization

  // Interior Design Styles

  const [asian, setasian] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Asian
  );
  const [beachnautical, setbeachnautical] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Beach_Nautical
  );
  const [contemporyhomedecor, setcontemporyhomedecor] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Contemporary_Home
  );
  const [craftsman, setcraftsman] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Craftsman
  );
  const [eclectric, seteclectric] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Eclectic
  );
  const [ecofreindly, setecofreindly] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Eco_Friendly
  );
  const [farmhouse, setfarmhouse] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Farmhouse
  );
  const [hollywoodglam, sethollywoodglam] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Hollywood_Glam
  );
  const [interiordesignindustrial, setinteriordesignindustrial] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Industrial
  );
  const [mediterranean, setmediterranean] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Mediterranean
  );
  const [midcenturymmodern, setmidcenturymmodern] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Mid_Century
  );
  const [minimalist, setminimalist] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Minimalist
  );
  const [modern, setmodern] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Modern
  );
  const [rustic, setrustic] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Rustic
  );
  const [scandinavian, setscandinavian] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Scandinavian
  );
  const [shabbychic, setshabbychic] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Shabby_Chic
  );
  const [traditional, settraditional] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Traditional
  );
  const [transitional, settransitional] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Transitional
  );
  const [tropical, settropical] = useState(
    company.companyData.Service_Provided.Design_Specialization.Interior_Design
      .Tropical
  );

  const InteriorDesignStyles = {
    Asian: asian,
    BeachNautical: beachnautical,
    ContemporyHomeDecor: contemporyhomedecor,
    Craftsman: craftsman,
    Eclectic: eclectric,
    Ecofreindly: ecofreindly,
    Farmhouse: farmhouse,
    Hollywoodglam: hollywoodglam,
    InteriorDesignIndustrial: interiordesignindustrial,
    Mediterranean: mediterranean,
    MidCenturyModern: midcenturymmodern,
    Minimalist: minimalist,
    Modern: modern,
    Rustic: rustic,
    Scandinavian: scandinavian,
    ShabbyChic: shabbychic,
    Traditional: traditional,
    Tranisitional: transitional,
    Tropical: tropical,
  };

  const handleasian = (e) => {
    setasian(e.target.checked);
  };
  const handlebeachnautical = (e) => {
    setbeachnautical(e.target.checked);
  };
  const handlecontemporyhomedecor = (e) => {
    setcontemporyhomedecor(e.target.checked);
  };
  const handlecraftsman = (e) => {
    setcraftsman(e.target.checked);
  };
  const handleeclectric = (e) => {
    seteclectric(e.target.checked);
  };

  const handleecofreindly = (e) => {
    setecofreindly(e.target.checked);
  };
  const handlefarmhouse = (e) => {
    setfarmhouse(e.target.checked);
  };

  const handlehollywoodglam = (e) => {
    sethollywoodglam(e.target.checked);
  };
  const handleinteriordesignindustrial = (e) => {
    setinteriordesignindustrial(e.target.checked);
  };
  const handlemediterranean = (e) => {
    setmediterranean(e.target.checked);
  };
  const handlemidcenturymmodern = (e) => {
    setmidcenturymmodern(e.target.checked);
  };
  const handleminimalist = (e) => {
    setminimalist(e.target.checked);
  };
  const handlemodern = (e) => {
    setmodern(e.target.checked);
  };

  const handlerustic = (e) => {
    setrustic(e.target.checked);
  };
  const handlescandinavian = (e) => {
    setscandinavian(e.target.checked);
  };
  const handleshabbychic = (e) => {
    setshabbychic(e.target.checked);
  };
  const handletraditional = (e) => {
    settraditional(e.target.checked);
  };
  const handletransitional = (e) => {
    settransitional(e.target.checked);
  };
  const handletropical = (e) => {
    settropical(e.target.checked);
  };

  // Architectural Styles

  const [artdeco, setartdeco] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Art_Deco
  );
  const [bauhaus, setbauhaus] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Bauhaus
  );
  const [constructivism, setconstructivism] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Constructivism
  );
  const [constructivistarchitecture, setconstructivistarchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Constructivist_Architecture
  );
  const [contemporyarchitecture, setcontemporyarchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Contemporary_Architecture
  );
  const [architecturalecofreindly, setarchitecturalecofreindly] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Eco_Friendly
  );
  const [functionalism, setfunctionalism] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Functionalism
  );
  const [futuristarchitecture, setfuturistarchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Futurist_Architecture
  );
  const [hightecharchitecture, sethightecharchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.High_Tech
  );
  const [internationalstyle, setinternationalstyle] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.International
  );
  const [midcenturymodern, setmidcenturymodern] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Mid_Century
  );
  const [minimalism, setminimalism] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Minimalism
  );
  const [newobjectivity, setnewobjectivity] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.New_Objectivity
  );
  const [organicarchitecture, setorganicarchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Organic
  );
  const [postmodernarchitecture, setpostmodernarchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Postmodern
  );
  const [ranchstylehouse, setranchstylehouse] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Ranch
  );
  const [steamlinemodern, setsteamlinemodern] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Streamline
  );
  const [sustainablearchitecture, setsustainablearchitecture] = useState(
    company.companyData.Service_Provided.Design_Specialization
      .Architectural_Style.Sustainable
  );

  const DesginSpecializationfuncs = {
    InteriorDesignStylesfuncs: [
      setasian,
      setbeachnautical,
      setcontemporyhomedecor,
      setcraftsman,
      seteclectric,
      setecofreindly,
      setfarmhouse,
      sethollywoodglam,
      setinteriordesignindustrial,
      setmediterranean,
      setmidcenturymmodern,
      setminimalist,
      setmodern,
      setrustic,
      setscandinavian,
      setshabbychic,
      settraditional,
      settransitional,
      settropical,
    ],
    ArchitecturalStylesfuncs: [
      setartdeco,
      setbauhaus,
      setconstructivism,
      setconstructivistarchitecture,
      setcontemporyarchitecture,
      setarchitecturalecofreindly,
      setfunctionalism,
      setfuturistarchitecture,
      sethightecharchitecture,
      setinternationalstyle,
      setmidcenturymodern,
      setminimalism,
      setnewobjectivity,
      setorganicarchitecture,
      setpostmodernarchitecture,
      setranchstylehouse,
      setsteamlinemodern,
      setsustainablearchitecture,
    ],
  };

  const ArchitecturalStyles = {
    ArtDeco: artdeco,
    Bauhaus: bauhaus,
    Constructivism: constructivism,
    ConstructivityArchitecture: constructivistarchitecture,
    ContemporyArchitecure: contemporyarchitecture,
    EcoFreindly: architecturalecofreindly,
    Functionalism: functionalism,
    FuturisticArchitecure: futuristarchitecture,
    HighArchitecture: hightecharchitecture,
    InternationalStyle: internationalstyle,
    MidCenturyModer: midcenturymodern,
    Minimalism: minimalism,
    NewObjectivity: newobjectivity,
    OrganicArfchitecture: organicarchitecture,
    PostModernArchitecture: postmodernarchitecture,
    RanchStyleHouse: ranchstylehouse,
    SteamLineModern: steamlinemodern,
    SustainableArchitecture: sustainablearchitecture,
  };

  const handleartdeco = (e) => {
    setartdeco(e.target.checked);
  };
  const handlebauhaus = (e) => {
    setbauhaus(e.target.checked);
  };
  const handleconstructivism = (e) => {
    setconstructivism(e.target.checked);
  };
  const handleconstructivistarchitecture = (e) => {
    setconstructivistarchitecture(e.target.checked);
  };
  const handlecontemporyarchitecture = (e) => {
    setcontemporyarchitecture(e.target.checked);
  };
  const handlearchitecturalecofreindly = (e) => {
    setarchitecturalecofreindly(e.target.checked);
  };
  const handlefunctionalism = (e) => {
    setfunctionalism(e.target.checked);
  };
  const handlefuturistarchitecture = (e) => {
    setfuturistarchitecture(e.target.checked);
  };
  const handlehightecharchitecture = (e) => {
    sethightecharchitecture(e.target.checked);
  };
  const handleinternationalstyle = (e) => {
    setinternationalstyle(e.target.checked);
  };
  const handlemidcenturymodern = (e) => {
    setmidcenturymodern(e.target.checked);
  };
  const handleminimalism = (e) => {
    setminimalism(e.target.checked);
  };
  const handlenewobjectivity = (e) => {
    setnewobjectivity(e.target.checked);
  };
  const handleorganicarchitecture = (e) => {
    setorganicarchitecture(e.target.checked);
  };
  const handlepostmodernarchitecture = (e) => {
    setpostmodernarchitecture(e.target.checked);
  };
  const handleranchstylehouse = (e) => {
    setranchstylehouse(e.target.checked);
  };
  const handlesteamlinemodern = (e) => {
    setsteamlinemodern(e.target.checked);
  };
  const handlesustainablearchitecture = (e) => {
    setsustainablearchitecture(e.target.checked);
  };

  const DesginSpecialization = {
    InteriorDesignStyles: InteriorDesignStyles,
    ArchitecturalStyles: ArchitecturalStyles,
  };
  const Services = {
    InitialConsultation: InitialConsultation,
    SoftwaresUsed: SoftwaresUsed,
    DesignSchematics: DesignSchematics,
    BookingConfirmation: bookingconfirmation,
    LegalAgreements: legalagreements,
    ProjectTimeFrame: projecttimeframe,
    Execution: execution,
    TypesOfPropertiesServed: TypesOfPropertiesServed,
    InteriorDesignExecutionServices: InteriorDesignExecutionServices,
    ArchitecturalConstructionExecutionServices:
      ArchitecturalConstructionExecutionServices,
    CivilandRenovation: CivilandRenovation,
    DisabledHandicap: disabledhandicapservices,
    HealthandSafety: healthandsafety,
    SecuritySystem: securitysystems,
    Finishing: finishing,
    OtherServices: OtherServices,
    ProductsandAccessories: ProductsandAccessories,
    FinalReviewandHandOver: FinalReviewandHandOver,
    AfterSalesSupport: aftersalessupport,
    DesginSpecialization: DesginSpecialization,
  };

  const Profile = {
    PersonalDetails: PersonalDetails,
    ProfessionalDetails: ProfessionalDetails,
    CompanyDetails: CompanyDetails,
    ContactDetails: ContactDetails,
    Branches: branchesarray,
    ProjectsCateredTo: ProjectsCateredTo,
    ServicesProvided: Services,
  };

  const handlechange = (e) => {
    const currentelement = e.currentTarget.id;

    if (currentelement === "Service Professional Name") {
      setserviceprofessionalname(e.target.value);
    }
    if (currentelement === "Date of Birth") {
      setdob(e.target.value);
    }
    // if (currentelement === "Aadhaar Card No.") {
    //   setadhaarno(e.target.value);
    // }

    if (currentelement === "profession") {
      setprofession(e.target.value);
    }
    if (currentelement === "freelancer-company") {
      setfreelancercompany(e.target.value);
    }
    if (currentelement === "businessowner-company") {
      setbusinessownercompany(e.target.value);
    }
    if (currentelement === "businessowner-gst") {
      setbusinessownergst(e.target.value);
    }

    if (currentelement === "overallexperience") {
      setoverallexperience(e.target.value);
    }
    if (currentelement === "projectcompleted") {
      setprojectcompleted(e.target.value);
    }
    if (currentelement === "company-name") {
      setcompanyname(e.target.value);
    }
    if (currentelement === "year-of-establishment") {
      setyearofestablishment(e.target.value);
    }
    if (currentelement === "no-of-employees") {
      setnoofemployees(e.target.value);
    }
    if (currentelement === "from-time") {
      settiming((previous) => {
        return {
          From: e.target.value,
          To: previous.To,
        };
      });
    }
    if (currentelement === "to-time") {
      settiming((previous) => {
        return {
          From: previous.From,
          To: e.target.value,
        };
      });
    }
    if (currentelement === "about-company") {
      setaboutthecompany(e.target.value);
    }
    if (currentelement === "userphoneno1") {
      setuserphoneno1(e.target.value);
    }
    if (currentelement === "userphoneno2") {
      setuserphoneno2(e.target.value);
    }
    if (currentelement === "userphoneno3") {
      setuserphoneno3(e.target.value);
    }
    if (currentelement === "userwhatsappno") {
      setuserwhatsappnumber(e.target.value);
    }
    if (currentelement === "useremailid1") {
      setuseremailid1(e.target.value);
    }
    if (currentelement === "useremailid2") {
      setuseremailid2(e.target.value);
    }
    if (currentelement === "useremailid3") {
      setuseremailid3(e.target.value);
    }
    if (currentelement === "websiteurl") {
      setwebsiteurl(e.target.value);
    }

    if (currentelement === "facebook") {
      setfacebook(e.target.value);
    }
    if (currentelement === "instagram") {
      setinstagram(e.target.value);
    }
    if (currentelement === "linkedin") {
      setlinkedin(e.target.value);
    }
    if (currentelement === "pinterest") {
      setpinterest(e.target.value);
    }
    if (currentelement === "quora") {
      setquora(e.target.value);
    }
    if (currentelement === "reddit") {
      setreddit(e.target.value);
    }
    if (currentelement === "twitter") {
      settwitter(e.target.value);
    }
    if (currentelement === "youtube") {
      setyoutube(e.target.value);
    }
    if (currentelement === "userhousename") {
      setuserhousename(e.target.value);
    }
    if (currentelement === "userstreetname") {
      setuserstreetname(e.target.value);
    }
    if (currentelement === "userlocalityname") {
      setuserlocalityname(e.target.value);
    }
    if (currentelement === "userlandmark") {
      setuserlandmark(e.target.value);
    }
    // if (currentelement === "userstate") {
    //   setuserstate(e.target.value);
    // }
    // if (currentelement === "usercity") {
    //   setusercity(e.target.value);
    // }
    if (currentelement === "userareaname") {
      setuserareaname(e.target.value);
    }
    if (currentelement === "userpincode") {
      setuserpincode(e.target.value);
    }
  };
  const handleselectbox = (e) => {
    const list = e.currentTarget.nextSibling;
    if (list.style.opacity === "0" || list.style.opacity === "0.5") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      list.style.pointerEvents = "auto";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "hidden";
      list.style.transform = "scale(.5)";
      list.style.pointerEvents = "none";
    }
  };

  useEffect(() => {
    // console.log(professiontype);
  }, [professiontype]);

  const handlelistitem = (e) => {
    const currentselectedid = e.currentTarget.parentElement.id;
    const itemselected = e.currentTarget.innerText;
    const heading =
      e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    const listitems = e.currentTarget.parentElement.childNodes;
    for (let i = 0; i < listitems.length; i++) {
      listitems[i].style.backgroundColor = "rgb(245, 242, 242)";
      listitems[i].style.color = "black";
    }

    heading.innerText = itemselected;
    e.currentTarget.style.backgroundColor = "#fe5631";
    e.currentTarget.style.color = "white";
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
    e.currentTarget.parentElement.style.pointerEvents = "none";
    if (currentselectedid === "branch-state") {
      setbranchstate(itemselected);
    }
    if (currentselectedid === "branch-city") {
      setbranchcity(itemselected);
    }
    if (currentselectedid === "gender") {
      setgender(itemselected);
    }
    if (currentselectedid === "state") {
      setState(e.currentTarget.innerText);
    }
    if (currentselectedid === "city") {
      setCity(e.currentTarget.innerText);
    }
    if (currentselectedid === "educational-qualification") {
      seteducationalqualification(itemselected);
    }
    if (currentselectedid === "profession") {
      setprofession(itemselected);
    }
    if (currentselectedid === "profession-type") {
      setprofessiontype(itemselected);
      if (itemselected === "FreeLancer") {
        setfreelancer(true);
        setbusinessowner(false);
      } else {
        setbusinessowner(true);
        setfreelancer(false);
      }
    }
    if (currentselectedid === "interior-residential") {
      setinteriorresidential(itemselected);
    }
    if (currentselectedid === "interior-commercial") {
      setinteriorcommercial(itemselected);
    }

    if (currentselectedid === "architectural-residential") {
      setarchitecturalresidential(itemselected);
    }
    if (currentselectedid === "architectural-commercial") {
      setarchitecturalcommercial(itemselected);
    }

    if (currentselectedid === "renovation-residential") {
      setrenovationresidential(itemselected);
    }
    if (currentselectedid === "renovation-commercial") {
      setrenovationcommercial(itemselected);
    }
    if (currentselectedid === "consultation") {
      setconsultation(itemselected);
    }

    //for previous branches
    const previousbranchid =
      e.currentTarget.parentElement.parentElement.parentElement.parentElement
        .parentElement.id;
    const branchtoedit = branchesarray.filter(
      (branch) => branch.Id === previousbranchid
    )[0];
    // console.log(branchesarray);
    // console.log(branchtoedit);
    // console.log(previousbranchid);
    // console.log(e.currentTarget.parentElement.id);
    if (e.currentTarget.parentElement.id === "previous-branch-state") {
      branchtoedit.BranchOfficeAddress.State = e.currentTarget.innerText;
      setalteredstate(e.currentTarget.innerText);
    }
    if (e.currentTarget.parentElement.id === "previous-branch-city") {
      branchtoedit.BranchOfficeAddress.City = e.currentTarget.innerText;
    }
  };

  const handleinputadd = (e) => {
    const currentelement = e.currentTarget.parentElement.childNodes[0].id;
    if (currentelement === "hobbies") {
      setnoofhobbies((previous) => {
        return [...previous, ""];
      });
    }
    if (currentelement === "personal-awards") {
      setnoofpersonalawards((previous) => {
        return [...previous, ""];
      });
    }
    if (currentelement === "professional-skills") {
      setnoofprofessionalskills((previous) => {
        return [...previous, ""];
      });
    }
    if (currentelement === "professional-awards") {
      setnoofprofessionalawards((previous) => {
        return [...previous, ""];
      });
    }
    if (currentelement === "company-awards") {
      setnoofcompanyawards((previous) => {
        return [...previous, ""];
      });
    }
  };

  const handleinputdelete = (e) => {
    const currentelementcontainer = e.currentTarget.parentElement.parentElement;

    // e.currentTarget.parentElement.parentElement.remove(e.currentTarget.parentElement)

    currentelementcontainer.removeChild(e.currentTarget.parentElement);
  };

  const handlechangeitem = (e) => {
    // console.log(e.currentTarget.parentElement);
  };

  const handleradiotype = (e) => {
    const currentelementid = e.currentTarget.id;
    const havebranches = e.target.value;

    if (havebranches === "Yes") {
      setbranches(true);
    }
    if (havebranches === "No") {
      setbranches(false);
    }
  };

  useEffect(() => {
    if (branches) {
      [addnewbranchref.current.childNodes].forEach((e) => {
        e.forEach((details) => {
          if (details.className === "branch-details") {
            [details.childNodes].forEach((detail) => {
              detail.forEach((element) => {
                if (element.childNodes[1].className === "text-input") {
                  branchinputitems.push(element);
                }
                if (element.childNodes[1].className === "selectbox-container") {
                  branchselectboxtitems.push(element);
                }
              });
            });
          }
        });
      });
    }

    if (
      branchinputitems.filter((item) => item.childNodes[1].value.length === 0)
        .length > 0 ||
      branchselectboxtitems.filter(
        (item) =>
          item.childNodes[1].childNodes[0].childNodes[0].innerText ===
          "Select State"
      ).length > 0
    ) {
      setallbranchitemsfilled(false);
    } else {
      setallbranchitemsfilled(true);
    }
  });

  useEffect(() => {
    if (branchesarray.length > 0) {
      setbranches(true);
    }
  }, [branchesarray]);

  const handlebranchdetail = (e) => {
    const currentelement = e.target.id;
    if (currentelement === "branchphone1") {
      setbranchphoneno1(e.target.value);
    }
    if (currentelement === "branchphone2") {
      setbranchphoneno2(e.target.value);
    }
    if (currentelement === "branchwhatsappphone") {
      setbranchwhatsappphone(e.target.value);
    }
    if (currentelement === "branchemail1") {
      setbranchemailid1(e.target.value);
    }
    if (currentelement === "branchemail2") {
      setbranchemailid2(e.target.value);
    }
    if (currentelement === "branchhouseaddress") {
      setbranchhousename(e.target.value);
    }
    if (currentelement === "branchstreetname") {
      setbranchstreetname(e.target.value);
    }
    if (currentelement === "branchloaclityname") {
      setbranchlocalityname(e.target.value);
    }
    if (currentelement === "branchlandmark") {
      setbranchlandmark(e.target.value);
    }
    if (currentelement === "branchareaname") {
      setbranchareaname(e.target.value);
    }
    if (currentelement === "branchpincode") {
      setbranchpincode(e.target.value);
    }
    // if(currentelement === "branchphone1"){
    //   setbranchphoneno1(e.target.value)
    // }
    // if(currentelement === "branchphone1"){
    //   setbranchphoneno1(e.target.value)
    // }
  };

  const handlepreviousbranchdetail = (e) => {
    const previousbranchid =
      e.currentTarget.parentElement.parentElement.parentElement.id;

    const branchtoedit = branchesarray.filter(
      (branch) => branch.Id === previousbranchid
    )[0];
    // console.log(e.currentTarget.id);
    if (e.currentTarget.id === "branchphone1") {
      branchtoedit.BranchOfficePhoneNumber.PhoneNo1 = e.target.value;
    }
    if (e.currentTarget.id === "branchphone2") {
      branchtoedit.BranchOfficePhoneNumber.PhoneNo2 = e.target.value;
    }
    if (e.currentTarget.id === "branchwhatsappphone") {
      branchtoedit.BranchOfficePhoneNumber.WhatsappPhoneNo = e.target.value;
    }
    if (e.currentTarget.id === "branchemail1") {
      branchtoedit.BranchOfficeEmailID.EmailID1 = e.target.value;
    }
    if (e.currentTarget.id === "branchemail2") {
      branchtoedit.BranchOfficeEmailID.EmailID2 = e.target.value;
    }
    if (e.currentTarget.id === "branchhouseaddress") {
      branchtoedit.BranchOfficeAddress.HouseName = e.target.value;
    }
    if (e.currentTarget.id === "branchstreetname") {
      branchtoedit.BranchOfficeAddress.StreetName = e.target.value;
    }
    if (e.currentTarget.id === "branchloaclityname") {
      branchtoedit.BranchOfficeAddress.LocalityName = e.target.value;
    }
    if (e.currentTarget.id === "branchlandmark") {
      branchtoedit.BranchOfficeAddress.Landmark = e.target.value;
    }
    if (e.currentTarget.id === "branchareaname") {
      branchtoedit.BranchOfficeAddress.Areaname = e.target.value;
    }
    if (e.currentTarget.id === "branchpincode") {
      branchtoedit.BranchOfficeAddress.Pincode = e.target.value;
    }
  };

  const handleaddbranch = (e) => {
    setbranchesarray((previous) => {
      return [...previous, BranchDetails];
    });

    const branchdetails = e.currentTarget.parentElement.childNodes;
    const branchnodes = [];
    branchdetails.forEach((element) => {
      if (element.className === "branch-details") {
        branchnodes.push(element);
      }
    });
    branchnodes.forEach((element) => {
      [element.childNodes].forEach((e) => {
        e.forEach((detail) => {
          detail.childNodes[1].value = "";
        });
      });
    });
  };

  const handleselectcheckboxes = (e) => {
    const list = e.currentTarget.nextSibling;

    if (list.style.opacity === "0") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      e.currentTarget.childNodes[1].innerText = "expand_less";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "hidden";
      list.style.transform = "scale(.5)";
      e.currentTarget.childNodes[1].innerText = "expand_more";
    }
  };

  const handleselectall = (e) => {
    const currentitems = e.currentTarget.parentElement.parentElement.childNodes;
    const allselected = e.currentTarget.checked;
    const containerid = e.currentTarget.parentElement.parentElement.id;

    var funcs;
    if (containerid === "design-schematics") {
      funcs = designschematicsfuncs;
    }
    if (containerid === "residential-properties") {
      funcs = TypesOfPropertiesServedfuncs.Residentialfuncs;
    }
    if (containerid === "commercial-properties") {
      funcs = TypesOfPropertiesServedfuncs.Commercialfuncs;
    }
    if (containerid === "institutional-properties") {
      funcs = TypesOfPropertiesServedfuncs.Institutionalfuncs;
    }
    if (containerid === "interior-design-execution-services") {
      funcs = interiordesignexecutionservicesfuncs;
    }
    if (containerid === "grey-structure") {
      funcs =
        ArchitecturalConstructionExecutionServicesfuncs.GreyStructurefuncs;
    }
    if (containerid === "other-services") {
      funcs = otherservicesfuncs;
    }
    if (containerid === "products-and-accessories") {
      funcs = productsandappliancesfuncs;
    }
    if (containerid === "final-review-and-handover") {
      funcs = finalreviewandhandoverfuncs;
    }
    if (containerid === "interior-design-styles") {
      funcs = DesginSpecializationfuncs.InteriorDesignStylesfuncs;
    }
    if (containerid === "architectural-design-styles") {
      funcs = DesginSpecializationfuncs.ArchitecturalStylesfuncs;
    }

    if (allselected) {
      funcs.forEach((func) => {
        func(true);
      });
      for (let i = 1; i < currentitems.length; i++) {
        currentitems[i].childNodes[1].checked = true;
      }
    } else {
      funcs.forEach((func) => {
        func(false);
      });
      for (let i = 1; i < currentitems.length; i++) {
        currentitems[i].childNodes[1].checked = false;
      }
    }

    if (allselected) {
    } else {
    }
  };

  const handleeditbranch = (e) => {
    e.currentTarget.parentElement.parentElement.childNodes.forEach(
      (branchdetails) => {
        if (branchdetails.className === "branch-details") {
          branchdetails.childNodes.forEach((details) => {
            details.childNodes[1].style.opacity = 1;
            details.childNodes[1].style.pointerEvents = "auto";
          });
        }
      }
    );
  };
  var branchesarr = branchesarray;

  const handlesavebranch = (e, index) => {
    const id =
      e.currentTarget.parentElement.parentElement.parentElement.childNodes[
        index
      ].id;

    e.currentTarget.parentElement.parentElement.childNodes.forEach(
      (branchdetails) => {
        if (branchdetails.className === "branch-details") {
          const State =
            branchdetails.childNodes[0].childNodes[1].childNodes[0]
              .childNodes[0].innerText;
          const City =
            branchdetails.childNodes[1].childNodes[1].childNodes[0]
              .childNodes[0].innerText;
          branchdetails.childNodes.forEach((details) => {
            details.childNodes[1].style.opacity = 0.5;
            details.childNodes[1].style.pointerEvents = "none";
          });
          branchesarr[index].BranchOfficeAddress.State = State;
          branchesarr[index].BranchOfficeAddress.City = City;
        }
      }
    );

    setbranchesarray(branchesarr);
  };

  useEffect(() => {
    // console.log(branchesarray);
  }, [branchesarray]);
  const deletebranch = (e, index) => {
    const id = e.currentTarget.parentElement.parentElement.id;
    setbranchesarray(branchesarray.filter((branch) => branch.Id !== id));
    setbranchesarray((prev) => {
      prev.forEach((brnch) => {
        brnch.Id = `Branch-${prev.indexOf(brnch)}`;
      });
      return prev;
    });
  };

  const handlesavechanges = (e) => {
    Hobbies.length = 0;
    document.getElementById("hobbies").childNodes.forEach((e) => {
      if (!Hobbies.includes(e.childNodes[0].value)) {
        Hobbies.push(e.childNodes[0].value);
      }
    });

    PersonalAwards.length = 0;

    document.getElementById("personal-awards").childNodes.forEach((e) => {
      if (!PersonalAwards.includes(e.childNodes[0].value)) {
        PersonalAwards.push(e.childNodes[0].value);
      }
    });

    ProfessionalSkills.length = 0;

    document.getElementById("professional-skills").childNodes.forEach((e) => {
      if (!ProfessionalSkills.includes(e.childNodes[0].value)) {
        ProfessionalSkills.push(e.childNodes[0].value);
      }
    });

    ProfessionalAwards.length = 0;

    document.getElementById("professional-awards").childNodes.forEach((e) => {
      if (!ProfessionalAwards.includes(e.childNodes[0].value)) {
        ProfessionalAwards.push(e.childNodes[0].value);
      }
    });

    CompanyAwards.length = 0;

    document.getElementById("company-awards").childNodes.forEach((e) => {
      if (!CompanyAwards.includes(e.childNodes[0].value)) {
        CompanyAwards.push(e.childNodes[0].value);
      }
    });

    //After saving to DB
    // setTimeout(() => {
    //   window.location.replace('/sp_profile')
    // }, 100);

    dispatch(SPUpdateProfileDetails(Profile)).then((response) => {
      // console.log("Response", response);
      if (response.payload.success) {
        // console.log("Reload the Page");
        // props.history.push('/ServiceProfessional/MyProfile');
        window.location.reload();
      } else {
        alert("Failed to update the profile at the moment");
      }
    });
  };

  // useEffect(() => {
  //   console.log(Profile);
  // }, [Profile]);

  const [agreed, setagreed] = useState(false);

  const handleagreed = (val) => {
    setagreed(val);
  };

  // const VerifyPhoneNumber = () => {
  //   setShowOTPModal(true);
  //   let variable = {
  //     number: userphoneno1,
  //   };
  //   dispatch(SP_Verify_PhoneNumber(variable)).then((response) => {
  //     console.log("Response", response);
  //   });
  // };

  // useEffect(() => {
  //   if (verified) {
  //     document.getElementById("userphoneno1").disabled = true;
  //     document.getElementById("userphoneno1").style.opacity = 0.7;
  //   }
  // }, [verified]);

  // const onOTPEntered = (e) => {
  //   console.log(e.target.value);
  //   setVerifiedOTPEntered(e.target.value);
  // };

  // const onHandleVerifyOTP = () => {
  //   console.log("Entered OTP ", VerifiedOTPEntered);
  //   let variable = {
  //     OTP: VerifiedOTPEntered,
  //     number: userphoneno1,
  //   };
  //   dispatch(SP_Verify_PhoneNumber_ViaOTP(variable)).then((response) => {
  //     console.log("Response After OTP Verification", response);
  //     if (response.payload.success) {
  //       setShowOTPModal(false);
  //       setverified(true);
  //     }
  //   });
  // };

  if (company.companyData && company.companyData.isAuth) {
    return (
      <div className="edit-profile-container">
        <div className="Edit-profile">
          <button
            style={{
              opacity: agreed ? 1 : 0.5,
              pointerEvents: agreed ? "auto" : "none",
            }}
            onClick={handlesavechanges}
            className="edit-proffile-btn"
          >
            Save Changes
          </button>
          {/* PERSONAL DETAILS*/}

          <div className="details-container">
            <p className="details-title">Personal Details</p>
            {/* Service Professional Name */}

            <div className="detail">
              <label className="input-title">
                Service Professional Name <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                defaultValue={serviceprofessionalname}
                id="Service Professional Name"
                className="text-input"
                onChange={handlechange}
                type={"text"}
              />
            </div>
            {/* Date of Birth */}
            <div className="detail">
              <label className="input-title">
                Date of Birth <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="Date of Birth"
                defaultValue={dob}
                onChange={handlechange}
                className="text-input"
                type={"date"}
              />
            </div>
            {/* Gender */}
            <div className="detail">
              <label className="input-title">
                Gender <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul id="Gender" className="selectbox-container">
                <li onClick={handleselectbox} className="heading">
                  {gender ? <p>{gender}</p> : <p> Select Gender</p>}
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="gender"
                  className="select-box-list"
                >
                  <li onClick={handlelistitem}>Male</li>
                  <li onClick={handlelistitem}>Female</li>
                </ul>
              </ul>
            </div>
            {/* Languages Known  */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Languages Known <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="languages" className="detail-items">
                  <li id="language" className="item">
                    <p className="item-name">Assamese</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={assamese}
                      onChange={handleassamese}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Gujarati</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={gujarati}
                      onChange={handlegujarati}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Malayalam</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={malayalam}
                      onChange={handlemalayalam}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Punjabi</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={punjabi}
                      onChange={handlepunjabi}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Urdu</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={urdu}
                      onChange={handleurdu}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Bengali</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={bengali}
                      onChange={handlebengali}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Hindi</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={hindi}
                      onChange={handlehindi}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Marathi</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={marathi}
                      onChange={handlemarathi}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Tamil</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={tamil}
                      onChange={handletamil}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">English</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={english}
                      onChange={handleenglish}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Kannada</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={kannada}
                      onChange={handlekannada}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Odia</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={odia}
                      onChange={handleodia}
                    />
                  </li>
                  <li id="language" className="item">
                    <p className="item-name">Telugu</p>
                    <input
                      className="checkbox-input"
                      type={"checkbox"}
                      defaultChecked={telugu}
                      onChange={handletelugu}
                    />
                  </li>
                </ul>
              </div>
            </div>
            {/* Personal Skills / Hobbies*/}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Personal Skills / Hobbies
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div id="hobbies-container" className="detail-items-to-select">
                <ul id="hobbies" className="detail-items">
                  {noofhobbies.map((hobbie, index) => {
                    return (
                      <li id={hobbie} key={index} className="item">
                        <input
                          class="text-input"
                          id="Text-Input"
                          type="text"
                          onChange={handlechangeitem}
                          defaultValue={hobbie}
                        />
                        <span
                          onClick={handleinputdelete}
                          id="delete-icon"
                          class="material-icons"
                        >
                          cancel
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <span
                  onClick={handleinputadd}
                  id="add-icon"
                  className="material-icons"
                >
                  add_circle
                </span>
              </div>
            </div>
            {/* Personal Awards & Achievements
             */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Personal Awards & Achievements
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="personal-awards" className="detail-items">
                  {noofpersonalawards.map((personalaward, index) => {
                    return (
                      <li id={personalaward} key={index} className="item">
                        <input
                          className="text-input"
                          type={"text"}
                          onChange={handlechangeitem}
                          defaultValue={personalaward}
                        />
                        <span
                          onClick={handleinputdelete}
                          id="delete-icon"
                          className="material-icons"
                        >
                          cancel
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <span
                  onClick={handleinputadd}
                  id="add-icon"
                  className="material-icons"
                >
                  add_circle
                </span>
              </div>
            </div>
            {/* Aadhaar Card No. */}

            {/* <div className="detail">
              <label className="input-title">
                Aadhaar Card No. <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                defaultValue={adhaarno}
                onChange={handlechange}
                id="Aadhaar Card No."
                className="text-input"
                type={"text"}
              />
            </div> */}
          </div>

          
          {/* CONTACT DETAILS*/}

          <div className="details-container">
            <p className="details-title">Contact Details</p>

            {/*  Main Head Office Number */}
            <p className="sub-title">Main Head Office Number</p>
            <div className="short-inputs">
              {/* User Phone Number */}
              <div className="detail">
                <label className="input-title">
                  User Phone Number <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userphoneno1}
                  onChange={handlechange}
                  // disabled={company.companyData.Verified_PhoneNumber}
                  id="userphoneno1"
                  className="text-input"
                  type={"text"}
                />

                {/* <button
                  style={{
                    backgroundColor: verified ? "#1dc295" : null,
                    pointerEvents: verified ? "none" : "auto"
                  }}
                  className="verify-phn-no"
                  onClick={VerifyPhoneNumber}
                >
                  {verified ? "Verified" : "Verify"}
                </button> */}
              </div>
              {/* Phone No. 2 */}
              <div className="detail">
                <label className="input-title">Phone No. 2 : </label>
                <input
                  defaultValue={userphoneno2}
                  onChange={handlechange}
                  id="userphoneno2"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Phone No. 3 */}
              <div className="detail">
                <label className="input-title">Phone No. 3 : </label>
                <input
                  defaultValue={userphoneno3}
                  onChange={handlechange}
                  id="userphoneno3"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Company WhatsApp Business No. */}
              <div className="detail">
                <label className="input-title">
                  Company WhatsApp Business No. :{" "}
                </label>
                <input
                  defaultValue={userwhatsappnumber}
                  onChange={handlechange}
                  id="userwhatsappno"
                  className="text-input"
                  type={"text"}
                />
              </div>
            </div>
            {/*  Main Head Office Email ID */}
            <p className="sub-title">Main Head Office Email ID</p>
            <div className="short-inputs">
              {/* User Email ID*/}
              <div className="detail">
                <label className="input-title">User Email ID : </label>
                <input
                  defaultValue={useremailid1}
                  onChange={handlechange}
                  id="useremailid1"
                  className="text-input"
                  type={"text"}
                  disabled
                />
              </div>
              {/* Email ID 2 */}
              <div className="detail">
                <label className="input-title">
                  Email ID 2<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={useremailid2}
                  onChange={handlechange}
                  id="useremailid2"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Email ID 3 */}
              <div className="detail">
                <label className="input-title">
                  Email ID 3<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={useremailid3}
                  onChange={handlechange}
                  id="useremailid3"
                  className="text-input"
                  type={"text"}
                />
              </div>

              {/* Website Url */}

              <div className="detail">
                <label className="input-title">
                  Website Url <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={websiteurl}
                  onChange={handlechange}
                  id="websiteurl"
                  className="text-input"
                  type={"text"}
                />
              </div>
            </div>

            {/* Social Media Links
             */}
            <p className="sub-title">Social Media Links</p>
            <div className="short-inputs">
              {/* Facebook */}
              <div className="detail">
                <label className="input-title">
                  Facebook <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={facebook}
                  onChange={handlechange}
                  id="facebook"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Instagram */}
              <div className="detail">
                <label className="input-title">
                  Instagram<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={instagram}
                  onChange={handlechange}
                  id="instagram"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* LinkedIn */}
              <div className="detail">
                <label className="input-title">
                  LinkedIn<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={linkedin}
                  onChange={handlechange}
                  id="linkedin"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Pinterest */}
              <div className="detail">
                <label className="input-title">
                  Pinterest<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={pinterest}
                  onChange={handlechange}
                  id="pinterest"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Quora */}
              <div className="detail">
                <label className="input-title">
                  Quora<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={quora}
                  onChange={handlechange}
                  id="quora"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Reddit */}
              <div className="detail">
                <label className="input-title">
                  Reddit<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={reddit}
                  onChange={handlechange}
                  id="reddit"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Twitter */}
              <div className="detail">
                <label className="input-title">
                  Twitter<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={twitter}
                  onChange={handlechange}
                  id="twitter"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* YouTube */}
              <div className="detail">
                <label className="input-title">
                  YouTube<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={youtube}
                  onChange={handlechange}
                  id="youtube"
                  className="text-input"
                  type={"text"}
                />
              </div>
            </div>

            {/*  Main Head Office Address*/}
            <p className="sub-title">Main Head Office Address</p>
            <div className="short-inputs">
              {/* House name / Building Name*/}
              <div className="detail">
                <label className="input-title">
                  House name / Building Name<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userhousename}
                  onChange={handlechange}
                  id="userhousename"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Street Name / Road */}
              <div className="detail">
                <label className="input-title">
                  Street Name / Road<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userstreetname}
                  onChange={handlechange}
                  id="userstreetname"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* Locality Name */}
              <div className="detail">
                <label className="input-title">
                  Locality Name<b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userlocalityname}
                  onChange={handlechange}
                  id="userlocalityname"
                  className="text-input"
                  type={"text"}
                />
              </div>

              {/* Landmark */}

              <div className="detail">
                <label className="input-title">
                  Landmark <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userlandmark}
                  onChange={handlechange}
                  id="userlandmark"
                  className="text-input"
                  type={"text"}
                />
              </div>
              {/* State */}

              {/* <div className="detail" id="Detail">
                <label className="input-title">
                  State <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userstate}
                  className="text-input"
                  type={"text"}
                  id="userstate"
                  onChange={handlechange}
                />
              </div> */}
              <div className="input-container">
                <label className="input-title">
                  State <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="state" className="selectboxx-container">
                  <li onClick={handleselectbox} className="heading">
                    <p> Select State</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="state"
                    className="select-boxx-list"
                  >
                    {Location.States.map((state, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {state}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <div className="input-container">
                <label className="input-title">
                  City <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="city" className="selectboxx-container">
                  <li onClick={handleselectbox} className="heading">
                    <p>Select City</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="city"
                    className="select-boxx-list"
                  >
                    {cities.map((city, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {city}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>


              <div className="detail">
                <label className="input-title">
                  Area Name <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userareaname}
                  onChange={handlechange}
                  className="text-input"
                  id="userareaname"
                  type={"text"}
                />
              </div>

              <div className="detail">
                <label className="input-title">
                  Pin Code <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  defaultValue={userpincode}
                  className="text-input"
                  id="userpincode"
                  onChange={handlechange}
                  type={"text"}
                />
              </div>
            </div>
          </div>
          {/* PROFESSIONAL DETAILS*/}

          <div className="details-container">
            <p className="details-title">Professional Details</p>

            {/*  Educational Qualification */}

            <div className="detail">
              <label className="input-title">
                What is your Educational Qualification?{" "}
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul
                id="educational-qualification"
                className="selectbox-container"
              >
                <li onClick={handleselectbox} className="heading">
                  {educationalqualification ? (
                    <p>{educationalqualification}</p>
                  ) : (
                    <p> Select</p>
                  )}
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="educational-qualification"
                  className="select-box-list"
                >
                  <li onClick={handlelistitem}>Under - Graduate</li>
                  <li onClick={handlelistitem}>Graduate</li>
                  <li onClick={handlelistitem}>Post - Graduate</li>
                </ul>
              </ul>
            </div>

            {/* Profession */}
            <div className="detail">
              <label className="input-title">
                What is your Profession ?<b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul id="profession" className="selectbox-container">
                <li onClick={handleselectbox} className="heading">
                  {profession ? <p>{profession}</p> : <p> Select</p>}
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="profession"
                  className="select-box-list"
                >
                  <li onClick={handlelistitem}>Architect</li>
                  <li onClick={handlelistitem}>Engineer</li>
                  <li onClick={handlelistitem}>Contractor</li>
                  <li onClick={handlelistitem}>Interior Designer</li>
                </ul>
              </ul>
            </div>
            {/* Profession Type */}
            <div className="detail">
              <label className="input-title">
                What is your Profession Type ?<b style={{ color: "red" }}>*</b>{" "}
                :{" "}
              </label>
              <ul id="profession-type" className="selectbox-container">
                <li onClick={handleselectbox} className="heading">
                  {professiontype ? <p>{professiontype}</p> : <p> Select</p>}
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="profession-type"
                  className="select-box-list"
                >
                  <li onClick={handlelistitem}>FreeLancer</li>
                  <li onClick={handlelistitem}>Business Owner</li>
                </ul>
              </ul>
            </div>
            {businessowner ? (
              <div className="detail">
                <label className="input-title">
                  G.S.T. Number of your registered business
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  id="businessowner-gst"
                  defaultValue={businessownergst}
                  onChange={handlechange}
                  className="text-input"
                  type={"text"}
                  maxLength={15}
                />
              </div>
            ) : null}
            {/* Professional Skills  */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Professional Skills
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="professional-skills" className="detail-items">
                  {noofprofessionalskills.map((professionalskill, index) => {
                    return (
                      <li id={professionalskill} key={index} className="item">
                        <input
                          className="text-input"
                          type={"text"}
                          onChange={handlechangeitem}
                          defaultValue={professionalskill}
                        />
                        <span
                          onClick={handleinputdelete}
                          id="delete-icon"
                          className="material-icons"
                        >
                          cancel
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <span
                  onClick={handleinputadd}
                  id="add-icon"
                  className="material-icons"
                >
                  add_circle
                </span>
              </div>
            </div>
            {/* Professional Awards & Achievements
             */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Professional Awards & Achievements
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="professional-awards" className="detail-items">
                  {noofprofessionalawards.map((professionalaward, index) => {
                    return (
                      <li id={professionalaward} key={index} className="item">
                        <input
                          className="text-input"
                          type={"text"}
                          onChange={handlechangeitem}
                          defaultValue={professionalaward}
                        />
                        <span
                          onClick={handleinputdelete}
                          id="delete-icon"
                          className="material-icons"
                        >
                          cancel
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <span
                  onClick={handleinputadd}
                  id="add-icon"
                  className="material-icons"
                >
                  add_circle
                </span>
              </div>
            </div>
            {/* Overall Experience */}

            <div className="detail">
              <label className="input-title">
                Overall Experience In Years <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="overallexperience"
                className="text-input"
                defaultValue={overallexperience}
                onChange={handlechange}
                type={"number"}
                placeholder={overallexperience === 0 ? "Eg: 1 , 2.5" : null}
              />
            </div>
            {/* Project Completed */}

            <div className="detail">
              <label className="input-title">
                Number of Projects Completed <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="projectcompleted"
                defaultValue={projectcompleted}
                onChange={handlechange}
                className="text-input"
                type={"number"}
              />
            </div>
          </div>

          {/* COMPANY DETAILS*/}

          <div className="details-container">
            <p className="details-title">COMPANY DETAILS</p>

            {/*  Company Name*/}

            <div className="detail">
              <label className="input-title">
                Company Name <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="company-name"
                className="text-input"
                type={"text"}
                defaultValue={companyname}
                onChange={handlechange}
              />
            </div>
            {/* Year of Establishment */}
            <div className="detail">
              <label className="input-title">
                Year of Establishment
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="year-of-establishment"
                className="text-input"
                defaultValue={yearofestablishment}
                type={"number"}
                onChange={handlechange}
              />
            </div>
            {/* Number of Employees */}
            <div className="detail">
              <label className="input-title">
                Number of Employees
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <input
                id="no-of-employees"
                className="text-input"
                defaultValue={noofemployees}
                type={"number"}
                onChange={handlechange}
              />
            </div>

            {/* Workdays and Timings  */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Work Days <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="work-days" className="detail-items">
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Monday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={monday}
                      onChange={handlemonday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Tuesday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={tuesday}
                      onChange={handletuesday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Wednesday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={wednesday}
                      onChange={handlewednesday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Thursday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={thursday}
                      onChange={handlethursday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Friday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={friday}
                      onChange={handlefriday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Saturday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={saturday}
                      onChange={handlesaturday}
                      type={"checkbox"}
                    />
                  </li>
                  <li id="work-day" className="item">
                    <p style={{ width: "100px" }} className="item-name">
                      Sunday
                    </p>
                    <input
                      className="checkbox-input"
                      defaultChecked={sunday}
                      onChange={handlesunday}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </div>
            </div>
            {/* Timing */}
            <div className="detail">
              <label className="input-title">
                Timing
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="time-container">
                <div className="from-time">
                  <p>From : </p>
                  <input
                    id="from-time"
                    className="text-input"
                    defaultValue={timing.From}
                    type={"time"}
                    onChange={handlechange}
                  />
                </div>
                <div className="to-time">
                  <p>To : </p>
                  <input
                    id="to-time"
                    className="text-input"
                    defaultValue={timing.To}
                    type={"time"}
                    onChange={handlechange}
                  />
                </div>
              </div>
            </div>
            {/* Company Awards & Achievements
             */}
            <div
              style={{
                alignItems: "normal",
              }}
              className="detail"
            >
              <label className="input-title">
                Company Awards & Achievements
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div className="detail-items-to-select">
                <ul id="company-awards" className="detail-items">
                  {noofcompanyawards.map((companyaward, index) => {
                    return (
                      <li id={companyaward} key={index} className="item">
                        <input
                          className="text-input"
                          type={"text"}
                          defaultValue={companyaward}
                        />
                        <span
                          onClick={handleinputdelete}
                          id="delete-icon"
                          className="material-icons"
                        >
                          cancel
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <span
                  onClick={handleinputadd}
                  id="add-icon"
                  className="material-icons"
                >
                  add_circle
                </span>
              </div>
            </div>

            {/* About the Company */}
            <div className="detail">
              <label className="input-title">
                About the Company
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <textarea
                id="about-company"
                className="text-input"
                type={"text"}
                defaultValue={aboutthecompany}
                onChange={handlechange}
              />
            </div>
          </div>

          {company.companyData && company.companyData.isAuth && company.companyData.Membership ? 
              <>

          {/* Branch Details */}
          <div className="details-container">
            <p className="details-title">Branch Details</p>

            {/* Do you have Branches ?  */}

            <div
              style={{
                rowGap: "10px",
              }}
              className="detail"
            >
              {/* <label className="input-title">
                Do you have branches / provide services in other cities?
                <b style={{ color: "red" }}>*</b> :{" "}
              </label> */}
              {/* <div className="radios">
                <div className="radio">
                  <label>Yes</label>
                  <input
                    onChange={handleradiotype}
                    type={"radio"}
                    value={"Yes"}
                    name="branch"
                  />
                </div>
                <div className="radio">
                  <label>No</label>
                  <input
                    onChange={handleradiotype}
                    type={"radio"}
                    value={"No"}
                    name="branch"
                  />
                </div>
              </div> */}
              {/* <div
                style={{
                  transform: branchesarray.length > 0 ? "scale(1)" : "scale(0)",
                }}
                className="number-of-branches-container"
              >
                <p className="number-of-branches">Branches</p>
                <p className="numbers">{branchesarray.length}</p>
              </div> */}
              {/* <span
                onClick={() => {
                  setbranches(true);
                }}
                id="addd-icon"
                class="material-icons"
              >
                add
              </span> */}
            </div>
            <div className="branchess">
              <div id="branch" className="branch" ref={addnewbranchref}>
                {/* <p className="sub-title" style={{ textAlign: "center" }}>
                    Add New Branch
                  </p> */}
                {/* Branch Office Phone Number */}
                {/* <p className="sub-title">Branch Office Phone Number</p> */}
                {/* <div className="branch-details"> */}
                {/* Phone No. 1 */}
                {/* <div className="detail">
                      <label className="input-title">
                        Phone No. 1<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchphone1"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Phone No. 2 */}
                {/* <div className="detail">
                      <label className="input-title">
                        Phone No. 2<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchphone2"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* WhatsApp No */}
                {/* <div className="detail">
                      <label className="input-title">
                        WhatsApp No.<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchwhatsappphone"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* </div> */}
                {/*  Branch Office Email ID*/}
                {/* <p className="sub-title">Branch Office Email ID</p> */}

                {/* <div className="branch-details"> */}
                {/*  Email ID 1 */}
                {/* <div className="detail">
                      <label className="input-title">
                        Email ID 1<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchemail1"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Email ID 2 */}
                {/* <div className="detail">
                      <label className="input-title">
                        Email ID 2<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchemail2"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* </div> */}

                {/* Office Address */}
                {/* <p className="sub-title">Office Address</p> */}

                {/* <div className="branch-details"> */}
                {/* House Name / Building Name */}
                {/* <div className="detail">
                      <label className="input-title">
                        House Name / Building Name
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchhouseaddress"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Street Name / Road */}
                {/* <div className="detail">
                      <label className="input-title">
                        Street Name / Road<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchstreetname"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Locality Name */}
                {/* <div className="detail">
                      <label className="input-title">
                        Locality Name<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchloaclityname"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Landmark */}
                {/* <div className="detail">
                      <label className="input-title">
                        Landmark<b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        className="text-input"
                        type={"text"}
                        id="branchlandmark"
                        onChange={handlebranchdetail}
                      />
                    </div> */}
                {/* Branch State */}

                <div className="detail">
                  <label className="input-title">
                    State <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <ul
                    id="branch-state"
                    style={{}}
                    className="selectbox-container"
                  >
                    <li onClick={handleselectbox} className="heading">
                      <p> Select State</p>
                      <span className="material-icons">expand_more</span>
                    </li>
                    <ul
                      style={{
                        opacity: 0,
                      }}
                      id="branch-state"
                      className="select-box-list"
                    >
                      {Location.States.map((state, index) => {
                        return (
                          <li key={index} onClick={handlelistitem}>
                            {state}
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>

                {/* Branch City */}

                <div className="detail">
                  <label className="input-title">
                    City <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <ul
                    id="branch-city"
                    style={{}}
                    className="selectbox-container"
                  >
                    <li onClick={handleselectbox} className="heading">
                      <p> Select City</p>
                      <span className="material-icons">expand_more</span>
                    </li>
                    <ul
                      style={{
                        opacity: 0,
                      }}
                      id="branch-city"
                      className="select-box-list"
                    >
                      {branchcities.map((city, index) => {
                        return (
                          <li key={index} onClick={handlelistitem}>
                            {city}
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
               
                <button
                  onClick={handleaddbranch}
                  className="add-anothert-branch-btn"
                  style={{
                    opacity: allbranchitemsfilled ? 1 : 0.5,
                    pointerEvents: allbranchitemsfilled ? "auto" : "none",
                  }}
                >
                  Add Branch
                </button>
              </div>
            </div>

            {/* If branches */}
            {branches ? (
              <div className="branchess">
                {branchesarray.length > 0
                  ? branchesarray.map((branch, index) => {
                      return (
                        <div id={`Branch-${index}`} className="branch">
                          <p
                            className="sub-title"
                          >
                            Branch - {index + 1}
                          </p>
                          <div className="edit-branchh-btn">
                            <span
                              onClick={handleeditbranch}
                              className="material-icons"
                              id="edit"
                            >
                              edit
                            </span>
                            <span
                              onClick={(e) => {
                                handlesavebranch(e, index);
                              }}
                              className="material-icons"
                              id="save"
                            >
                              save
                            </span>
                            <span
                              onClick={(e) => {
                                deletebranch(e, index);
                              }}
                              id="dellete-icon"
                              class="material-icons"
                            >
                              delete
                            </span>
                          </div>
                          <div className="branch-details">
                            <div className="detail">
                              <label className="input-title">
                                State<b style={{ color: "red" }}>*</b> :{" "}
                              </label>
                              <ul
                                id="previous-branch-state"
                                style={{
                                  opacity: 0.5,
                                  pointerEvents: "none",
                                }}
                                className="selectbox-container"
                              >
                                <li
                                  onClick={handleselectbox}
                                  className="heading"
                                >
                                  <p> {branch.BranchOfficeAddress.State}</p>
                                  <span className="material-icons">
                                    expand_more
                                  </span>
                                </li>
                                <ul
                                  style={{
                                    opacity: 0.5,
                                    pointerEvents: "none",
                                  }}
                                  id="previous-branch-state"
                                  className="select-box-list"
                                >
                                  {Location.States.map((state, index) => {
                                    return (
                                      <li key={index} onClick={handlelistitem}>
                                        {state}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </ul>
                            </div>

                            {/* Branch City */}

                            <div className="detail">
                              <label className="input-title">
                                City <b style={{ color: "red" }}>*</b> :{" "}
                              </label>
                              <ul
                                id="previous-branch-city"
                                style={{
                                  opacity: 0.5,
                                  pointerEvents: "none",
                                }}
                                className="selectbox-container"
                              >
                                <li
                                  onClick={handleselectbox}
                                  className="heading"
                                >
                                  <p> {branch.BranchOfficeAddress.City}</p>
                                  <span className="material-icons">
                                    expand_more
                                  </span>
                                </li>
                                <ul
                                  style={{
                                    opacity: 0,

                                    opacity: 0.5,
                                    pointerEvents: "none",
                                  }}
                                  id="previous-branch-city"
                                  className="select-box-list"
                                >
                                  {updatedcities.map((city, index) => {
                                    return (
                                      <li key={index} onClick={handlelistitem}>
                                        {city}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </ul>
                            </div>
                          </div>
                          <br />
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>
              
          {/* Projects Catered To */}
          <div className="details-container">
            <p className="details-title">Projects Catered To</p>
            {/* What kind of projects do you undertake? */}
            <p className="sub-title">What kind of projects do you undertake?</p>
            {/* Interior Design */}
            <div className="short-inputs">
              <p className="sub-title">Interior Design</p>
              {/* Residential */}
              <div className="detail">
                <label className="input-title">
                  Residential <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="interior-residential" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    {interiorresidential ? (
                      <p>{interiorresidential}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="interior-residential"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Commercial */}
              <div className="detail">
                <label className="input-title">
                  Commerical <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="interior-commercial" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    {interiorcommercial ? (
                      <p>{interiorcommercial}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="interior-commercial"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            {/* Architectural Construction */}
            <div className="short-inputs">
              <p className="sub-title">Architectural Construction</p>
              {/* Residential */}
              <div className="detail">
                <label className="input-title">
                  Residential <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul
                  id="architectural-residential"
                  className="selectbox-container"
                >
                  <li onClick={handleselectbox} className="heading">
                    {architecturalresidential ? (
                      <p>{architecturalresidential}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="architectural-residential"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Commercial */}
              <div className="detail">
                <label className="input-title">
                  Commerical <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul
                  id="architectural-commercial"
                  className="selectbox-container"
                >
                  <li onClick={handleselectbox} className="heading">
                    {architecturalcommercial ? (
                      <p>{architecturalcommercial}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="architectural-commercial"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            {/* Renovation */}
            <div className="short-inputs">
              <p className="sub-title">Renovation</p>
              {/* Residential */}
              <div className="detail">
                <label className="input-title">
                  Residential <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="renovation-residential" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    {renovationresidential ? (
                      <p>{renovationresidential}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="renovation-residential"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Commercial */}
              <div className="detail">
                <label className="input-title">
                  Commerical <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="renovation-commercial" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    {renovationcommercial ? (
                      <p>{renovationcommercial}</p>
                    ) : (
                      <p> Select</p>
                    )}
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="renovation-commercial"
                    className="select-box-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>
                      Both - Design and Execution
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div className="detail">
              <label
                style={{
                  fontSize: "17px",
                }}
                className="input-title"
              >
                Do you provide consultation as per Vaastu ?{" "}
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul id="consultation" className="selectbox-container">
                <li onClick={handleselectbox} className="heading">
                  {consultation ? <p>{consultation}</p> : <p> Select</p>}
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="consultation"
                  className="select-box-list"
                >
                  <li onClick={handlelistitem}>Yes</li>
                  <li onClick={handlelistitem}>No</li>
                </ul>
              </ul>
            </div>
          </div>

          {/* Services Provided */}
          <div className="details-container">
            <p className="details-title">Services Provided</p>
            <div className="short-inputs">
              {/* Initial Consultation */}
              <div className="detail">
                <label className="input-title">
                  Initial Consultation
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,

                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="initial-consultation"
                    className="select-box-list"
                  >
                    <li>
                      <p className="list-item-name">Company Office</p>
                      <input
                        onChange={handlecompanyoffice}
                        defaultChecked={companyoffice}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Site</p>
                      <input
                        onChange={handlesite}
                        defaultChecked={site}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Software Used */}

              <div className="detail">
                <label className="input-title">
                  Software Used
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="softwares-used"
                    className="select-box-list"
                  >
                    <li>
                      <p className="list-item-name">Interior Design</p>
                      <input
                        onChange={handleinteriordesign}
                        defaultChecked={interiordesign}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Architectural Construction
                      </p>
                      <input
                        onChange={handlearchitecturalconstruction}
                        defaultChecked={architecturalconstruction}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Civil Engineering</p>
                      <input
                        onChange={handlecivilengineering}
                        defaultChecked={civilengineering}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>

              {/* Design Schematics */}

              <div className="detail">
                <label className="input-title">
                  Design Schematics
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="design-schematics"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Plans</p>
                      <input
                        onChange={handleplans}
                        defaultChecked={plans}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Drawing Sketches</p>
                      <input
                        onChange={handledrawingsketches}
                        defaultChecked={drawingsketches}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Design</p>
                      <input
                        onChange={handledesign}
                        defaultChecked={design}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Consultation</p>
                      <input
                        onChange={handledsconsultation}
                        defaultChecked={dsconsultation}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Elevation</p>
                      <input
                        onChange={handleelevation}
                        defaultChecked={elevation}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Renders</p>
                      <input
                        onChange={handlerenders}
                        defaultChecked={renders}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Walkthrough</p>
                      <input
                        onChange={handlewalkthrough}
                        defaultChecked={walkthrough}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>

              {/* Booking Confirmation */}

              <div className="detail">
                <label className="input-title">
                  Booking Confirmation
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={bookingconfirmation}
                    onChange={handlebookingconfirmation}
                  />
                </div>
              </div>
              {/* Legal Agreements */}

              <div className="detail">
                <label className="input-title">
                  Legal Agreements
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={legalagreements}
                    onChange={handlelegalagreements}
                  />
                </div>
              </div>
              {/* Project Timeframe */}

              <div className="detail">
                <label className="input-title">
                  Project Timeframe
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={projecttimeframe}
                    onChange={handleprojecttimeframe}
                  />
                </div>
              </div>

              {/* Execution */}

              <div className="detail">
                <label className="input-title">
                  Execution
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={execution}
                    onChange={handleexecution}
                  />
                </div>
              </div>
              {/* Types Of Properties Served */}
              <p className="sub-title">Types Of Properties Served</p>
              {/* Residential */}

              <div className="detail">
                <label className="input-title">
                  Residential
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="residential-properties"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Apartment</p>
                      <input
                        onChange={handleapartment}
                        defaultChecked={apartment}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Penthouse</p>
                      <input
                        onChange={handlepenthouse}
                        defaultChecked={penthouse}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Standalone Building</p>
                      <input
                        onChange={handlestandalonebuilding}
                        defaultChecked={standalonebuilding}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Independent Home</p>
                      <input
                        onChange={handleindependenthome}
                        defaultChecked={independenthome}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Villa</p>
                      <input
                        onChange={handlevilla}
                        defaultChecked={villa}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Bungalow</p>
                      <input
                        onChange={handlebungalow}
                        defaultChecked={bungalow}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Commercial */}

              <div className="detail">
                <label className="input-title">
                  Commercial
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="commercial-properties"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Retail</p>
                      <input
                        onChange={handleretail}
                        defaultChecked={retail}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Hospitality</p>
                      <input
                        onChange={handlehospitality}
                        defaultChecked={hospitality}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Healthcare</p>
                      <input
                        onChange={handlehealthcare}
                        defaultChecked={healthcare}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Office</p>
                      <input
                        onChange={handleoffice}
                        defaultChecked={office}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Industrial</p>
                      <input
                        onChange={handleindustrial}
                        defaultChecked={industrial}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Institutional */}

              <div className="detail">
                <label className="input-title">
                  Institutional
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="institutional-properties"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Educational Sector</p>
                      <input
                        onChange={handleeducationalsector}
                        defaultChecked={educationalsector}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Financial Sector</p>
                      <input
                        onChange={financialsector}
                        defaultChecked={financialsector}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Media and Entertainment Sector
                      </p>
                      <input
                        onChange={handlemediaandentertainmentsector}
                        defaultChecked={mediaandentertainmentsector}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Research and Development Sector
                      </p>
                      <input
                        onChange={handleresearchanddevelopmentsector}
                        defaultChecked={researchanddevelopmentsector}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Transportation Sector</p>
                      <input
                        onChange={handletransportationsector}
                        defaultChecked={transportationsector}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            {/* Interior Design Execution Services */}
            <div className="detail">
              <label
                className="input-title"
                style={{
                  fontSize: "17px",
                }}
              >
                Interior Design Execution Services
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul className="checkbox-selectbox">
                <li onClick={handleselectcheckboxes} className="heading">
                  Select
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                    // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                  }}
                  id="interior-design-execution-services"
                  className="select-box-list"
                >
                  <li>
                    <p style={{ fontWeight: "500" }} className="list-item-name">
                      Select all
                    </p>
                    <input onChange={handleselectall} type={"checkbox"} />
                  </li>

                  <li>
                    <p className="list-item-name">Hard Finishes</p>
                    <input
                      onChange={handlehardfinishes}
                      defaultChecked={hardfinishes}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">
                      Furniture, Fixtures & Equipment
                    </p>
                    <input
                      onChange={handlefurniturefixturesequipment}
                      defaultChecked={furniturefixturesequipment}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Soft Finishes</p>
                    <input
                      onChange={handlesoftfinishes}
                      defaultChecked={softfinishes}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Art & Decor</p>
                    <input
                      onChange={handleartanddecor}
                      defaultChecked={artanddecor}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Eco-freindly</p>
                    <input
                      onChange={handleecofreindlyservices}
                      defaultChecked={ecofreindlyservices}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Tech - Savvy</p>
                    <input
                      onChange={handletechsavvy}
                      defaultChecked={techsavvy}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Disabled Handicap</p>
                    <input
                      onChange={handledisabledhandicap}
                      defaultChecked={disabledhandicap}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Health and Safety</p>
                    <input
                      onChange={handlehealthandsafetyservices}
                      defaultChecked={healthandsafetyservices}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Security Systems</p>
                    <input
                      onChange={handlesecuritysystemsservices}
                      defaultChecked={securitysystemsservices}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </ul>
            </div>

            {/* Architectural Construction Execution Services */}
            <p className="sub-title">
              Architectural Construction Execution Services
            </p>
            <div className="short-inputs">
              <div className="detail">
                <label className="input-title">
                  Grey Structure
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="grey-structure"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Soil Testing</p>
                      <input
                        onChange={handlesoiltesting}
                        defaultChecked={soiltesting}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Site Clearance</p>
                      <input
                        onChange={handlesiteclearance}
                        defaultChecked={siteclearance}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Site Layout</p>
                      <input
                        onChange={handlesitelayout}
                        defaultChecked={sitelayout}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Substructure Contruction Phase
                      </p>
                      <input
                        onChange={handlesubstructurecontructionphase}
                        defaultChecked={substructurecontructionphase}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Super Structure Contruction Phase
                      </p>
                      <input
                        onChange={handlesuperstructurecontructionphase}
                        defaultChecked={superstructurecontructionphase}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
              {/* Disabled / Handicap – Accessible Designs & Services */}

              <div className="detail">
                <label className="input-title">
                  Disabled / Handicap – Accessible Designs & Services
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={disabledhandicapservices}
                    onChange={handledisabledhandicapservices}
                  />
                </div>
              </div>

              {/* Health & Safety */}

              <div className="detail">
                <label className="input-title">
                  Health & Safety
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={healthandsafety}
                    onChange={handlehealthandsafety}
                  />
                </div>
              </div>

              {/* Security Systems */}

              <div className="detail">
                <label className="input-title">
                  Security Systems
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={securitysystems}
                    onChange={handlesecuritysystems}
                  />
                </div>
              </div>

              {/* Finishing */}

              <div className="detail">
                <label className="input-title">
                  Finishing
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div style={{ padding: "13px" }}>
                  <input
                    type={"checkbox"}
                    defaultChecked={finishing}
                    onChange={handlefinishing}
                  />
                </div>
              </div>
            </div>
            {/* Civil & Renovation Services */}

            <div className="detail">
              <label className="input-title">
                Civil & Renovation Services
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul className="checkbox-selectbox">
                <li onClick={handleselectcheckboxes} className="heading">
                  Select
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                    // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                  }}
                  id="civil-and-renovation-Services"
                  className="select-box-list"
                >
                  <li>
                    <p className="list-item-name">
                      Same as the above selected Interior Design Execution
                      Services
                    </p>
                    <input
                      defaultChecked={sameasinterior}
                      onChange={handlesameasinterior}
                      type={"checkbox"}
                    />
                  </li>

                  <li>
                    <p className="list-item-name">
                      Same as the above selected Architectural Construction
                      Execution Services{" "}
                    </p>
                    <input
                      defaultChecked={sameasarchitectural}
                      onChange={handlesameasarchitectural}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </ul>
            </div>
            {/* Other Services */}
            <div className="detail">
              <label className="input-title">
                Other Services
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul className="checkbox-selectbox">
                <li onClick={handleselectcheckboxes} className="heading">
                  Select
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                    // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                  }}
                  id="other-services"
                  className="select-box-list"
                >
                  <li>
                    <p style={{ fontWeight: "500" }} className="list-item-name">
                      Select all
                    </p>
                    <input onChange={handleselectall} type={"checkbox"} />
                  </li>

                  <li>
                    <p className="list-item-name">Assembling Furniture</p>
                    <input
                      defaultChecked={assemblingfurniture}
                      onChange={handleassemblingfurniture}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">
                      Heavy Itemlifting and Moving
                    </p>
                    <input
                      onChange={handleheavyitemliftingandmoving}
                      defaultChecked={heavyitemliftingandmoving}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Household Cores</p>
                    <input
                      defaultChecked={householdcores}
                      onChange={handlehouseholdcores}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Packers and Movers</p>
                    <input
                      defaultChecked={packersandmovers}
                      onChange={handlepackersandmovers}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Pest Control</p>
                    <input
                      defaultChecked={pestcontrol}
                      onChange={handlepestcontrol}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </ul>
            </div>

            {/* Products & Accessories */}
            <div className="detail">
              <label className="input-title">
                Products & Accessories
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul className="checkbox-selectbox">
                <li onClick={handleselectcheckboxes} className="heading">
                  Select
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                    // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                  }}
                  id="products-and-accessories"
                  className="select-box-list"
                >
                  <li>
                    <p style={{ fontWeight: "500" }} className="list-item-name">
                      Select all
                    </p>
                    <input onChange={handleselectall} type={"checkbox"} />
                  </li>

                  <li>
                    <p className="list-item-name">Appliances</p>
                    <input
                      defaultChecked={appliances}
                      onChange={handleappliances}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Artwork</p>
                    <input
                      defaultChecked={artwork}
                      onChange={handleartwork}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Decor</p>
                    <input
                      defaultChecked={decor}
                      onChange={handledecor}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Furniture</p>
                    <input
                      defaultChecked={furniture}
                      onChange={handlefurniture}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Hardware</p>
                    <input
                      defaultChecked={hardware}
                      onChange={handlehardware}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Mirrors</p>
                    <input
                      defaultChecked={mirrors}
                      onChange={handlemirrors}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">
                      Modular Customized Furniture
                    </p>
                    <input
                      defaultChecked={modularcustomizedfurniture}
                      onChange={handlemodularcustomizedfurniture}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Painting</p>
                    <input
                      defaultChecked={painting}
                      onChange={handlepainting}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Upholstery</p>
                    <input
                      defaultChecked={upholstery}
                      onChange={handleupholstery}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Wallpapers</p>
                    <input
                      defaultChecked={wallpapers}
                      onChange={handlewallpapers}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </ul>
            </div>

            {/* Final Review & Handover */}
            <div className="detail">
              <label className="input-title">
                Final Review & Handover
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <ul className="checkbox-selectbox">
                <li onClick={handleselectcheckboxes} className="heading">
                  Select
                  <span className="material-icons">expand_more</span>
                </li>
                <ul
                  style={{
                    opacity: 0,
                    // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                  }}
                  id="final-review-and-handover"
                  className="select-box-list"
                >
                  <li>
                    <p style={{ fontWeight: "500" }} className="list-item-name">
                      Select all
                    </p>
                    <input onChange={handleselectall} type={"checkbox"} />
                  </li>

                  <li>
                    <p className="list-item-name">Final Builing Inspection</p>
                    <input
                      defaultChecked={finalbuilinginspection}
                      onChange={handlefinalbuilinginspection}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">
                      Confirmation Of Final Payment Received
                    </p>
                    <input
                      defaultChecked={confirmationoffinalpaymentreceived}
                      onChange={handleconfirmationoffinalpaymentreceived}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Documentation</p>
                    <input
                      defaultChecked={documentation}
                      onChange={handledocumentation}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Handover</p>
                    <input
                      defaultChecked={handover}
                      onChange={handlehandover}
                      type={"checkbox"}
                    />
                  </li>
                  <li>
                    <p className="list-item-name">Photoshoot and Videography</p>
                    <input
                      defaultChecked={photoshootandvideography}
                      onChange={handlephotoshootandvideography}
                      type={"checkbox"}
                    />
                  </li>
                </ul>
              </ul>
            </div>
            {/* After – Sales Support */}
            <div className="detail">
              <label className="input-title">
                After – Sales Support
                <b style={{ color: "red" }}>*</b> :{" "}
              </label>
              <div style={{ padding: "13px" }}>
                <input
                  type={"checkbox"}
                  defaultChecked={aftersalessupport}
                  onChange={handleaftersalessupport}
                />
              </div>
            </div>

            {/* Design Specialization */}
            <p className="sub-title">Design Specialization</p>
            <div className="short-inputs">
              {/* Interior Design Styles */}
              <div className="detail">
                <label className="input-title">
                  Interior Design Styles
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="interior-design-styles"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">Asian</p>
                      <input
                        defaultChecked={asian}
                        onChange={handleasian}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Beachnautical</p>
                      <input
                        defaultChecked={beachnautical}
                        onChange={handlebeachnautical}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Contempory Homedecor</p>
                      <input
                        defaultChecked={contemporyhomedecor}
                        onChange={handlecontemporyhomedecor}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Craftsman</p>
                      <input
                        defaultChecked={craftsman}
                        onChange={handlecraftsman}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Eclectric</p>
                      <input
                        defaultChecked={eclectric}
                        onChange={handleeclectric}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Ecofreindly</p>
                      <input
                        defaultChecked={ecofreindly}
                        onChange={handleecofreindly}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Farmhouse</p>
                      <input
                        defaultChecked={farmhouse}
                        onChange={handlefarmhouse}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Hollywoodglam</p>
                      <input
                        defaultChecked={hollywoodglam}
                        onChange={handlehollywoodglam}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Interior design</p>
                      <input
                        defaultChecked={interiordesignindustrial}
                        onChange={handleinteriordesignindustrial}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Mediterranean</p>
                      <input
                        defaultChecked={mediterranean}
                        onChange={handlemediterranean}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Midcentury Modern</p>
                      <input
                        defaultChecked={midcenturymmodern}
                        onChange={handlemidcenturymmodern}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Minimalist</p>
                      <input
                        defaultChecked={minimalist}
                        onChange={handleminimalist}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Modern</p>
                      <input
                        defaultChecked={modern}
                        onChange={handlemodern}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Rustic</p>
                      <input
                        defaultChecked={rustic}
                        onChange={handlerustic}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Scandinavian</p>
                      <input
                        defaultChecked={scandinavian}
                        onChange={handlescandinavian}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Shabby Chic</p>
                      <input
                        defaultChecked={shabbychic}
                        onChange={handleshabbychic}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Traditional</p>
                      <input
                        defaultChecked={traditional}
                        onChange={handletraditional}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Transitional</p>
                      <input
                        defaultChecked={transitional}
                        onChange={handletransitional}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Tropical</p>
                      <input
                        defaultChecked={tropical}
                        onChange={handletropical}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>

              {/* Architectural Styles */}
              <div className="detail">
                <label className="input-title">
                  Architectural Styles
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul className="checkbox-selectbox">
                  <li onClick={handleselectcheckboxes} className="heading">
                    Select
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                      // width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                    }}
                    id="architectural-design-styles"
                    className="select-box-list"
                  >
                    <li>
                      <p
                        style={{ fontWeight: "500" }}
                        className="list-item-name"
                      >
                        Select all
                      </p>
                      <input onChange={handleselectall} type={"checkbox"} />
                    </li>

                    <li>
                      <p className="list-item-name">artdeco</p>
                      <input
                        defaultChecked={artdeco}
                        onChange={handleartdeco}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Bauhaus</p>
                      <input
                        defaultChecked={bauhaus}
                        onChange={handlebauhaus}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Constructivism</p>
                      <input
                        defaultChecked={constructivism}
                        onChange={handleconstructivism}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Constructivist Architecture
                      </p>
                      <input
                        defaultChecked={constructivistarchitecture}
                        onChange={handleconstructivistarchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Contempory Architecture</p>
                      <input
                        defaultChecked={contemporyarchitecture}
                        onChange={handlecontemporyarchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">
                        Architectural Eco-freindly
                      </p>
                      <input
                        defaultChecked={architecturalecofreindly}
                        onChange={handlearchitecturalecofreindly}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Functionalism</p>
                      <input
                        defaultChecked={functionalism}
                        onChange={handlefunctionalism}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Futurist Architecture</p>
                      <input
                        defaultChecked={futuristarchitecture}
                        onChange={handlefuturistarchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">High Tech Architecture</p>
                      <input
                        defaultChecked={hightecharchitecture}
                        onChange={handlehightecharchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">International Style</p>
                      <input
                        defaultChecked={internationalstyle}
                        onChange={handleinternationalstyle}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Midcentury Modern</p>
                      <input
                        defaultChecked={midcenturymodern}
                        onChange={handlemidcenturymodern}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Minimalism</p>
                      <input
                        defaultChecked={minimalism}
                        onChange={handleminimalism}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">New Objectivity</p>
                      <input
                        defaultChecked={newobjectivity}
                        onChange={handlenewobjectivity}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Organic Architecture</p>
                      <input
                        defaultChecked={organicarchitecture}
                        onChange={handleorganicarchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Post Modern Architecture</p>
                      <input
                        defaultChecked={postmodernarchitecture}
                        onChange={handlepostmodernarchitecture}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Ranch Style House</p>
                      <input
                        defaultChecked={ranchstylehouse}
                        onChange={handleranchstylehouse}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Steamline Modern</p>
                      <input
                        defaultChecked={steamlinemodern}
                        onChange={handlesteamlinemodern}
                        type={"checkbox"}
                      />
                    </li>
                    <li>
                      <p className="list-item-name">Sustainable Architecture</p>
                      <input
                        defaultChecked={sustainablearchitecture}
                        onChange={handlesustainablearchitecture}
                        type={"checkbox"}
                      />
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
            </>
            : null}
          <Declaration agreed={handleagreed} user={"SP"} />
        </div>
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}

export default withRouter(EditProfile);
