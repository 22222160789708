export const Location = {
    States: ["Delhi", "Gujarat", "Karnataka", "Maharashtra", "National Capital Region (NCR)", "Tamil Nadu", "Telangana", "West Bengal"],
    Cities: [
      {
        State: "Karnataka",
        Cities: ["Bangalore"],
      },
      {
        State: "Gujarat",
        Cities: ["Ahmedabad"],
      },
      {
        State: "Tamil Nadu",
        Cities: ["Chennai"],
      },
      {
        State: "Delhi",
        Cities: ["Delhi"],
      },
      {
        State: "National Capital Region (NCR)",
        Cities: ["Faridabad", "Ghaziabad", "Greater Noida", "Gurgaon", "Meerut", "Noida", "Panipat", "Sonipat"],
      },
      {
        State: "Telangana",
        Cities: ["Hyderabad"],
      },
      {
        State: "West Bengal",
        Cities: ["Kolkata"],
      },
      {
        State: "Maharashtra",
        Cities: ["Mumbai", "Pune"],
      },
    ],
  }


  export const budgets = {
    Interior : [
      "0 – 5 Lacs",
      "5 – 10 Lacs",
      "10 – 15 Lacs",
      "15 – 20 Lacs",
      "20 – 25 Lacs",
      "25 – 30 Lacs",
      "Above 30 Lacs"
    ],
    Construction : [
      "0 – 15 Lacs",
      "15 – 30 Lacs",
      "30 – 45 Lacs",
      "45 – 60 Lacs",
      "60 – 75 Lacs",
      "75 Lacs – 1 Cr.",
      "Above 1 Cr."
    ],
  }