const reviews = [
  {
    Name: "Yashwardhan Gowda",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-1.jpg",
    Profession: "Property Owner, Residential Property, Bangalore",
    Review:
      "The NebourHood team is amazing, so we have engaged them for multiple projects. They are client centric, solution oriented and their pragmatic approach helped us bring our dream home to reality. If you want a team that will continuously exceed your expectations while suggesting you with amazing architect professionals, look no further and get in touch with NebourHood to get your dreams fulfilled.",
    Rating: 4.5,
  },
  {
    Name: "Gursewak Singh",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-2.jpg",
    Profession: "Architect, Residential Architecture, Delhi",
    Review:
      "NebourHood is a fantastic portal of architecture and construction Property Requirements. A lot of clients here are looking for high quality work with an artistic touch. Explaining the concepts to them is very easy, thanks to NebourHood.",
    Rating: 4,
  },
  {
    Name: "Mikaela",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-3.jpg",
    Profession: "Property Owner, Commercial Property, Faridabad",
    Review:
      "NebourHood is a leader in the architecture industry and a role model to other architecture companies. Their knowledge, ideas, expertise and forward thinking as to how the property would be utilized in the future & the use of technology in design are admirable. We appreciate their collaboration to get us connected with impressive architects.",
    Rating: 4.5,
  },
  {
    Name: "Kanishk",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-4.jpg",
    Profession: "Interior Designer, Residential Interiors, Ghaziabad",
    Review:
      "Since NebourHood started, it has improved my business 10 x fold. Many of my clients post their requirements on NebourHood and I am easily able to filter & select the interior design Property Requirements, contact the clients and finalize the projects without any delay. The best part is that I usually find clients in a close proximity to my office, which makes it easier for me to meet them.",
    Rating: 5,
  },
  {
    Name: "Trushti",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-5.jpg",
    Profession: "Civil Engineer, Commercial Construction, Greater Noida",
    Review:
      "A lot of customers directly visit our website due to NebourHood and call us. We have executed many projects as a result of this and have saved a lot of marketing expenditure. The ranges of the Property Requirements are fantastic, they help us generate a generous amount of revenue every month. Kudos to the NebourHood team, you guys are fab!",
    Rating: 4,
  },
  {
    Name: "Dulquer Rayhaan",
    Image:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-6.jpg",
    Profession: "Architect, Residential Architecture, Noida",
    Review:
      "I’m a freelance architect. It used to take me at least 2 months to select and finalize the deal with a client. The travelling was taking a toll on my health. But now, with NebourHood, I can easily select the clients and finalize the projects quickly - all of this while working from the comfort zone of my home. And having so many features to choose from, for a freelancer like me, is a real advantage! Cheers NebourHood!",
    Rating: 4,
  },

];

module.exports.reviews = reviews;
