const casestudies = [
  {
    Name: "Apartment",
    Link: "https://i.ibb.co/phP13C8/13-Apartment.jpg",
  },
  {
    Name: "Penthouse",
    Link: "https://i.ibb.co/S6CR2NF/14-Penthouse.jpg",
  },
  {
    Name: "Stand Alone Building",
    Link: "https://i.ibb.co/BTM8R2D/15-Stand-Alone-Building.jpg",
  },
  {
    Name: "Independent Home",
    Link: "https://i.ibb.co/tbBV5hK/16-Independent-Home.jpg",
  },
  {
    Name: "Villa",
    Link: "https://i.ibb.co/PjbcshF/17-Villa.jpg",
  },
  {
    Name: "Bungalow",
    Link: "https://i.ibb.co/SNBTxVp/18-Bungalow.jpg",
  },
  {
    Name: "Retail",
    Link: "https://i.ibb.co/YRXJKJq/19-Retail.jpg",
  },
  {
    Name: "Hospitality",
    Link: "https://i.ibb.co/ZV3xyZ3/20-Hospitality.jpg",
  },
  {
    Name: "HealthCare",
    Link: "https://i.ibb.co/CtVk4fY/21-Healthcare.jpg",
  },
  {
    Name: "Office – Corporate and Tech",
    Link: "https://i.ibb.co/HHHM9Nv/22-Office-Corporate-and-Tech.jpg",
  },
  {
    Name: "Institutional",
    Link: "https://i.ibb.co/Tm3nGWw/23-Institutional.jpg",
  },
  {
    Name: "Industrial",
    Link: "https://i.ibb.co/1zjCvQj/24-Industrial.jpg",
  },
];

module.exports.casestudies = casestudies;