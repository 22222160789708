import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postpropertyrequirements } from "../../../../../_actions/user_actions";
// import PO_Razorpay from "../../../../utils/Razorpay/PO_Posting_Renew/PO_Razorpay";
import "./PostPropertyReq.css";
import { withRouter } from "react-router-dom";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import { budgets, Location } from "./Data";
import PaymentModal from "./PaymentModal/PaymentModal";
import Declaration from "../../../../utils/Declaration/Declaration";

function PostPropertyReq(props) {
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;
  const [Visible, setVisible] = useState(false);
  const [proceedclicked, setproceedclicked] = useState(false);

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  // const intertiorbudgets = [1, 2, 3, 4, 5];
  // const constructionbudgets = [5, 10, 15, 20, 25];

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [interiorselected, setinteriorselected] = useState(false);
  const [constructionselected, setconstructionselected] = useState(false);
  const [residentialselected, setresidentialselected] = useState(false);
  const [commercialselected, setcommercialselected] = useState(false);
  const [newproperty, setnewproperty] = useState(false);
  const [existingproperty, setexistingproperty] = useState(false);

  //values
  const [housename, sethousename] = useState(null);
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);

  const cities =
    Location.Cities.filter((city) => city.State === state).length > 0
      ? Location.Cities.filter((city) => city.State === state)[0].Cities
      : [];

  const [areaname, setareaname] = useState(null);
  const [pincode, setpincode] = useState(null);
  const [servicetype, setservicetype] = useState(null);
  const [interiortype, setinteriortype] = useState("");
  const [constructiontype, setconstructiontype] = useState("");
  const [buildingtype, setbuildingtype] = useState(null);
  const [propertystatus, setpropertystatus] = useState(null);
  const [propertytype, setpropertytype] = useState(null);
  const [servicerequirement, setservicerequirement] = useState(null);
  const [timeframe, settimeframe] = useState(null);
  const [propertydimensiontype, setpropertydimensiontype] = useState(null);
  const [propertyarea, setpropertyarea] = useState(null);
  const [propertycondition, setpropertycondition] = useState(null);
  const [propertyconditionyears, setpropertyconditionyears] = useState("");
  const [propertyconditionmonths, setpropertyconditionmonths] = useState(null);
  const [budget, setbudget] = useState(null);
  const [propertyrequirement, setpropertyrequirement] = useState(null);
  const [validity_till, setvalidity_till] = useState(0);
  const [postrequirementsfilled, setpostrequirementsfilled] = useState(false);

  const requirementsarray = [
    housename,
    state,
    city,
    areaname,
    pincode,
    servicetype,
    buildingtype,
    propertystatus,
    propertytype,
    servicerequirement,
    timeframe,
    propertyarea,
    propertydimensiontype,
    propertycondition,
    propertyconditionyears,
    propertyconditionmonths,
    budget,
    propertyrequirement,
    validity_till,
  ];

  const VariableToPost = {
    Land_Building: housename,
    State: state,
    City: city,
    Area_Name: areaname,
    Pin_Code: pincode,
    Project_Type: servicetype,
    BuildingType: buildingtype,
    Status_Of_Project: propertystatus,
    Area_Type: propertytype,
    Project_Area: propertyarea,
    Service_Type_Required: servicerequirement,
    Beginning_The_Project: timeframe,
    Dimension_Type: propertydimensiontype,
    Property_Condition:
      propertycondition === "New Property"
        ? propertycondition
        : propertyconditionyears.length > 2
        ? propertyconditionyears
        : propertyconditionyears +
          " Years " +
          propertyconditionmonths +
          " Months ",
    Budget: budget,
    description: propertyrequirement,
    Posted_Date: new Date().toLocaleDateString(),
    Posted_Time: new Date().toTimeString(),
    PaymentDone: true,
    Validity_Till: validity_till,
  };

  useEffect(() => {
    const requirementnotfilled = requirementsarray.filter(
      (req) => (req = !req)
    );
    if (requirementnotfilled.length === 0) {
      setpostrequirementsfilled(true);
    } else {
      setpostrequirementsfilled(false);
    }
  });

  useEffect(() => {
    // console.log(propertycondition);
  }, [propertycondition]);

  const handleselectbox = (e) => {
    const list = e.currentTarget.nextSibling;
    if (list.style.opacity === "0") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      list.style.pointerEvents = "auto";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "visible";
      list.style.transform = "scale(.5)";
      list.style.pointerEvents = "none";
    }
  };

  const handlelistitem = (e) => {
    const currentselectedid = e.currentTarget.parentElement.id;

    const itemselected = e.currentTarget.innerText;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    const listitems = e.currentTarget.parentElement.childNodes;
    for (let i = 0; i < listitems.length; i++) {
      listitems[i].style.backgroundColor = "rgb(245, 242, 242)";
      listitems[i].style.color = "black";
    }

    if (e.currentTarget.innerText === "Residential") {
      setresidentialselected(true);
      setcommercialselected(false);
    }
    if (e.currentTarget.innerText === "Commercial") {
      setresidentialselected(false);
      setcommercialselected(true);
    }
    if (currentselectedid === "state") {
      setstate(e.currentTarget.innerText);
    }
    if (currentselectedid === "city") {
      setcity(e.currentTarget.innerText);
    }
    if (currentselectedid === "buildingtype") {
      setbuildingtype(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertytype") {
      setpropertytype(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertystatus") {
      setpropertystatus(e.currentTarget.innerText);
    }
    if (currentselectedid === "servicerequirement") {
      setservicerequirement(e.currentTarget.innerText);
    }
    if (currentselectedid === "timeframe") {
      settimeframe(e.currentTarget.innerText);
      setvalidity_till(parseInt(e.currentTarget.innerText.replace(/\D/g, "")));
    }
    if (currentselectedid === "years") {
      setpropertyconditionyears(e.currentTarget.innerText);
    }
    if (currentselectedid === "state") {
      setstate(e.currentTarget.innerText);
    }
    if (currentselectedid === "months") {
      setpropertyconditionmonths(e.currentTarget.innerText);
    }
    if (currentselectedid === "budget") {
      setbudget(e.currentTarget.innerText);
    }
    heading.innerText = itemselected;
    e.currentTarget.style.backgroundColor = "#fe5631";
    e.currentTarget.style.color = "white";
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
    e.currentTarget.parentElement.style.pointerEvents = "none";
  };

  const handleradiotype = (e) => {
    if (e.target.value === "Interior") {
      setinteriorselected(true);
      setconstructionselected(false);
      setinteriortype("Interior");
      setconstructiontype("");
      setservicetype(e.target.value);
    }
    if (e.target.value === "Construction") {
      setinteriorselected(false);
      setconstructionselected(true);
      setservicetype(e.target.value);
      setconstructiontype("Construction");
      setinteriortype("");
    }
    if (e.target.value === "New Property") {
      setnewproperty(true);
      setexistingproperty(false);
      setpropertycondition(e.target.value);
      setpropertyconditionmonths("null");
      setpropertyconditionyears("null");
    }
    if (e.target.value === "Existing Property") {
      setnewproperty(false);
      setexistingproperty(true);
      setpropertycondition(e.target.value);
    }
    if (e.target.value === "Interior" || e.target.value === "Construction") {
      const selectboxes =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .childNodes[1].childNodes;
      // console.log(selectboxes);
      if (selectboxes) {
        for (let i = 0; i < selectboxes.length; i++) {
          selectboxes[i].childNodes[1].childNodes[0].childNodes[0].innerText =
            "Select";
          selectboxes[i].childNodes[1].childNodes[1].childNodes.forEach(
            (li) => {
              li.style.backgroundColor = "rgb(245, 242, 242)";
              li.style.color = "black";
            }
          );

          selectboxes[i].childNodes.forEach((e) => {
            if (e.className === "selectbox-container") {
              e.childNodes[1].style.opacity = 0;
              e.childNodes[1].style.visibility = "hidden";
              e.childNodes[1].style.transform = "scale(0)";
            }
          });
        }
      }
    }
  };

  const handlehousename = (e) => {
    sethousename(e.target.value);
  };

  const handleareaname = (e) => {
    setareaname(e.target.value);
  };
  const handlepincode = (e) => {
    setpincode(e.target.value);
  };

  const handlepropertydimensiontype = (e) => {
    setpropertydimensiontype(e.target.value);
  };
  const handlepropertyarea = (e) => {
    setpropertyarea(e.target.value);
  };

  const handlepropertyrequirement = (e) => {
    setpropertyrequirement(e.target.value);
  };

  const OnPostRequirements = (e) => {
    e.preventDefault();
    var variables = {
      Land_Building: housename,
      State: state,
      City: city,
      Area_Name: areaname,
      Pin_Code: pincode,
      Project_Type: servicetype,
      BuildingType: buildingtype,
      Status_Of_Project: propertystatus,
      Area_Type: propertytype,
      Project_Area: propertyarea,
      Service_Type_Required: servicerequirement,
      Beginning_The_Project: timeframe,
      Dimension_Type: propertydimensiontype,
      Property_Condition:
        propertycondition === "New Property"
          ? propertycondition
          : propertyconditionyears.length > 2
          ? propertyconditionyears
          : propertyconditionyears +
            " Years " +
            propertyconditionmonths +
            " Months ",
      Budget: budget,
      description: propertyrequirement,
      Posted_Date: new Date().toLocaleDateString(),
      Posted_Time: new Date().toTimeString(),
      Validity_Till: validity_till,
    };
    // console.log("Variable", variables);
    // console.log("User Data", props.userData);

    dispatch(postpropertyrequirements(variables))
      .then((response) => {
        if(response.payload.success){
          // console.log("Response ", response.payload.result._id);
          props.history.push(`/PropertyOwner/PropertyRequirementsDetails/${response.payload.result._id}`)
        } else {
          // console.log("Failed");
          alert("Failed To Upload Property Requirement. Please try again later.")
        }
      })
    // console.log(variables)
  };

  const OnPostRequirements_Payment = (e) => {
    e.preventDefault();
    // console.log("User Data", props.userData);
    setVisible(true);
    setproceedclicked(true);
  };

  const handlepostreq = (e) => {
    e.preventDefault();
    // console.log(e.currentTarget);
  };

  const [agreed, setagreed] = useState(false);

  const handleagreed = (val) => {
    setagreed(val);
  };

  // const detailstopass = [
  //   {
  //     Heading: "HouseName",
  //     Content: housename,
  //   },
  //   {
  //     Heading: "State",
  //     Content: state,
  //   },
  //   {
  //     Heading: "City",
  //     Content: city,
  //   },
  //   {
  //     Heading: "AreaName",
  //     Content: areaname,
  //   },
  //   {
  //     Heading: "Pincode",
  //     Content: pincode,
  //   },
  //   {
  //     Heading: "Service Type",
  //     Content: servicetype,
  //   },
  //   {
  //     Heading: "Building Type",
  //     Content: buildingtype,
  //   },
  //   {
  //     Heading: "Property Status",
  //     Content: propertystatus,
  //   },
  //   {
  //     Heading: "Property Type",
  //     Content: propertytype,
  //   },
  //   {
  //     Heading: "Property Area",
  //     Content: propertyarea,
  //   },
  //   {
  //     Heading: "Property Condition",
  //     Content:
  //       propertycondition === "New Property"
  //         ? propertycondition
  //         : propertyconditionyears.length > 2
  //         ? propertyconditionyears
  //         : propertyconditionyears +
  //           " Years " +
  //           propertyconditionmonths +
  //           " Months ",
  //   },
  //   {
  //     Heading: "Budget",
  //     Content: budget,
  //   },
  //   {
  //     Heading: "property Requirement",
  //     Content: propertyrequirement,
  //   },
  //   {
  //     Heading: "Validity Till",
  //     Content: validity_till,
  //   },
  // ];

  return (
    <div className="post-property-requirements-container">
      <PageBanner title="Post Property Requirements" />
      <div className="header">
        <p className="title">
          <b>Posting Property Requirements for Property Owners</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Please take a few minutes and kindly fill in all the details correctly to get in touch with a suitable Service Professional
        </p>
      </div>
      <br />
      <div className="post-property-requirements">
        <div
          style={{
            width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
          }}
          className="property-requirements"
        >
          <div className="requirement">
            <p className="requirement-title">Address</p>
            <p className="requirement-desc">
              Please fill the details of your property where you want the work
              to be completed
            </p>
            <div className="requirement-details">
              <div className="input-container">
                <label className="input-title">
                  House Name / Building Name / Plot Name / Land Name (Property
                  Name) <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  onChange={handlehousename}
                  className="text-input"
                  type={"text"}
                />
              </div>
              <div className="input-container">
                <label className="input-title">
                  State <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="state" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    <p> Select State</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="state"
                    className="select-box-list"
                  >
                    {Location.States.map((state, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {state}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <div className="input-container">
                <label className="input-title">
                  City <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="city" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    <p>Select City</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="city"
                    className="select-box-list"
                  >
                    {cities.map((city, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {city}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <div className="input-container">
                <label className="input-title">
                  Area Name <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  onChange={handleareaname}
                  className="text-input"
                  type={"text"}
                />
              </div>
              <div className="input-container">
                <label className="input-title">
                  Pin Code <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  onChange={handlepincode}
                  className="text-input"
                  type={"text"}
                  id="pincode"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="requirement">
            <p className="requirement-title">Service Type Required</p>
            <div className="requirement-details">
              <div className="input-container">
                <label className="input-title">
                  Please Select your Service Type, Property Type and Property
                  Status <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div className="radios">
                  <div className="radio">
                    <label>Interior</label>
                    <input
                      onChange={handleradiotype}
                      type={"radio"}
                      value={"Interior"}
                      name="propertytype"
                    />
                  </div>
                  <div className="radio">
                    <label>Construction</label>
                    <input
                      onChange={handleradiotype}
                      type={"radio"}
                      value={"Construction"}
                      name="propertytype"
                    />
                  </div>
                </div>
              </div>
              {interiorselected ? (
                <div className="service-inputs-container" id="interior-inputs">
                  <div className="input-container">
                    <label className="input-title">
                      Building Type <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="buildingtype"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>Residential</li>
                        <li onClick={handlelistitem}>Commercial</li>
                      </ul>
                    </ul>
                  </div>

                  <div className="input-container">
                    <label className="input-title">
                      Property Type <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      {residentialselected ? (
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertytype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Apartment</li>
                          <li onClick={handlelistitem}>Penthouse</li>
                          <li onClick={handlelistitem}>Stand Alone Building</li>
                          <li onClick={handlelistitem}>Independent Home</li>
                          <li onClick={handlelistitem}>Villa</li>
                          <li onClick={handlelistitem}>Bungalow</li>
                        </ul>
                      ) : (
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertytype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Retail</li>
                          <li onClick={handlelistitem}>Hospitality</li>
                          <li onClick={handlelistitem}>Healthcare</li>
                          <li onClick={handlelistitem}>
                            Office - Corporate and Tech
                          </li>
                          <li onClick={handlelistitem}>Institutional</li>
                          <li onClick={handlelistitem}>Industrial</li>
                        </ul>
                      )}
                    </ul>
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Status of Property <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="propertystatus"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>
                          Have Received Possession, Ready to Move-In
                        </li>
                        <li onClick={handlelistitem}>
                          Waiting for Possession and Registration / Work
                          Completion
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Service Requirement <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="servicerequirement"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>Only Designs</li>
                        <li onClick={handlelistitem}>Only Execution</li>
                        <li onClick={handlelistitem}>
                          Both - Design and Execution
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              ) : constructionselected ? (
                <div
                  className="service-inputs-container"
                  id="construction-inputs"
                >
                  <div className="input-container">
                    <label className="input-title">
                      Building Type <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="buildingtype"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>Residential</li>
                        <li onClick={handlelistitem}>Commercial</li>
                      </ul>
                    </ul>
                  </div>

                  <div className="input-container">
                    <label className="input-title">
                      Property Type <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      {residentialselected ? (
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertytype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Apartment</li>
                          <li onClick={handlelistitem}>Penthouse</li>
                          <li onClick={handlelistitem}>Stand Alone Building</li>
                          <li onClick={handlelistitem}>Independent Home</li>
                          <li onClick={handlelistitem}>Villa</li>
                          <li onClick={handlelistitem}>Bungalow</li>
                        </ul>
                      ) : (
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertytype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Retail</li>
                          <li onClick={handlelistitem}>Hospitality</li>
                          <li onClick={handlelistitem}>Healthcare</li>
                          <li onClick={handlelistitem}>
                            Office - Corporate and Tech
                          </li>
                          <li onClick={handlelistitem}>Institutional</li>
                          <li onClick={handlelistitem}>Industrial</li>
                        </ul>
                      )}
                    </ul>
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Status of Property <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="propertystatus"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>
                          Have Received Possession, Ready to Build
                        </li>
                        <li onClick={handlelistitem}>
                          Waiting for Possession and Registration / Work
                          Completion
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Service Requirement <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="servicerequirement"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>Only Designs</li>
                        <li onClick={handlelistitem}>Only Execution</li>
                        <li onClick={handlelistitem}>
                          Both- Design and Execution
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="requirement">
                <p className="requirement-title">Time Frame</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      How soon would you like to get the work started?{" "}
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p> Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="timeframe"
                        className="select-box-list"
                      >
                        <li onClick={handlelistitem}>Urgent- Within 30 Days</li>
                        <li onClick={handlelistitem}>Within 60 Days</li>
                        <li onClick={handlelistitem}>Within 90 Days</li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="requirement">
                <p className="requirement-title">Property Information</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      Property Dimension Type <b style={{ color: "red" }}>*</b>{" "}
                      :{" "}
                    </label>
                    <input
                      onChange={handlepropertydimensiontype}
                      style={{
                        width: "250px",
                      }}
                      className="text-input"
                      placeholder="Example : 2BHK"
                      type={"text"}
                    />
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Property Area (sq. ft.) <b style={{ color: "red" }}>*</b>{" "}
                      :{" "}
                    </label>
                    <input
                      onChange={handlepropertyarea}
                      style={{
                        width: "250px",
                      }}
                      className="text-input"
                      placeholder="Example : 1200"
                      type={"text"}
                    />
                  </div>
                  <div className="input-container">
                    <label className="input-title">
                      Property Condition <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <div className="radios">
                      <div className="radio">
                        <label>New Property (New Requirement)</label>
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"New Property"}
                          name="propertycondition"
                        />
                      </div>
                      <div className="radio">
                        <label>
                          Existing Property (Renovation & Remodelling)
                        </label>
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"Existing Property"}
                          name="propertycondition"
                        />
                      </div>
                    </div>
                  </div>

                  {existingproperty ? (
                    <div className="property-condition-inputs">
                      <div className="input-container">
                        <label className="input-title">
                          Years <b style={{ color: "red" }}>*</b> :{" "}
                        </label>
                        <ul className="selectbox-container">
                          <li onClick={handleselectbox} className="heading">
                            <p>Select</p>
                            <span className="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="years"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>Less than a Year</li>
                            <li onClick={handlelistitem}>1</li>
                            <li onClick={handlelistitem}>2</li>
                            <li onClick={handlelistitem}>3</li>
                            <li onClick={handlelistitem}>4</li>
                            <li onClick={handlelistitem}>5</li>
                            <li onClick={handlelistitem}>6</li>
                            <li onClick={handlelistitem}>7</li>
                            <li onClick={handlelistitem}>8</li>
                            <li onClick={handlelistitem}>9</li>
                            <li onClick={handlelistitem}>10</li>
                            <li onClick={handlelistitem}>More than 10 Years</li>
                          </ul>
                        </ul>
                      </div>

                      <div className="input-container">
                        <label className="input-title">
                          Months <b style={{ color: "red" }}>*</b> :{" "}
                        </label>
                        <ul className="selectbox-container">
                          <li onClick={handleselectbox} className="heading">
                            <p>Select</p>
                            <span className="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="months"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>0</li>
                            <li onClick={handlelistitem}>1</li>
                            <li onClick={handlelistitem}>2</li>
                            <li onClick={handlelistitem}>3</li>
                            <li onClick={handlelistitem}>4</li>
                            <li onClick={handlelistitem}>5</li>
                            <li onClick={handlelistitem}>6</li>
                            <li onClick={handlelistitem}>7</li>
                            <li onClick={handlelistitem}>8</li>
                            <li onClick={handlelistitem}>9</li>
                            <li onClick={handlelistitem}>10</li>
                            <li onClick={handlelistitem}>11</li>
                            <li onClick={handlelistitem}>12</li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="requirement">
                <p className="requirement-title">Budget</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      What is your approximate budget for the scope of work
                      involved?
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul id="state" className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p> Select Budget</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="budget"
                        className="select-box-list"
                      >
                        {interiorselected
                          ? budgets.Interior.map((budget) => {
                              return <li onClick={handlelistitem}>{budget}</li>;
                            })
                          : null}
                        {constructionselected
                          ? budgets.Construction.map((budget) => {
                              return <li onClick={handlelistitem}>{budget}</li>;
                            })
                          : null}
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="requirement">
                <p className="requirement-title">Property Requirements</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      Please describe your property requirements completely and
                      as clearly as possible
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <textarea
                      onChange={handlepropertyrequirement}
                      className="text-area"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Declaration agreed={handleagreed} user={"PO"} />
        </div>

        {props.userData.First_Property ? (
          <button
            style={{
              opacity: postrequirementsfilled && agreed ? 1 : 0.5,
              pointerEvents: postrequirementsfilled && agreed ? "auto" : "none",
            }}
            onClick={OnPostRequirements}
            className="post-property-req-btn"
          >
            Post Property Requirements
          </button>
        ) : (
          <button
            style={{
              opacity: postrequirementsfilled && agreed ? 1 : 0.5,
              pointerEvents: postrequirementsfilled && agreed ? "auto" : "none",
            }}
            onClick={OnPostRequirements_Payment}
            className="post-property-req-btn"
          >
            Post Property Requirements
          </button>
        )}
      </div>

      <PaymentModal
        filleddetails={VariableToPost}
        modalopen={proceedclicked}
        handlepostreq={handlepostreq}
        handleclose={() => {
          setproceedclicked(false);
        }}
      />

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default withRouter(PostPropertyReq);
