import axios from "axios";
import { PROJECT_SERVER } from "../Config";
import { PROJECT_BY_ID, UPLOAD_NEW_PROJECT, 
         PREVIOUSLY_PROJECTUPLOADED, 
         EDIT_PREVIOUSLY_PROJECTUPLOADED, 
         DELETE_PROJECT_SP,
         UPDATE_PREVIOUSLY_UPLOADED_PROJECT,
         DELETE_UPLOAD_NEW_PROJECT_IMAGE,
         DELETE_UPLOADED_PROJECT_IMAGEDETAILS,
         GET_ALL_PROJECT,
         GET_PROJECT_BY_IMAGE_ID
        } from "./types";

export function getAllProject(variables) {
    // console.log("Get All Project Action", variables);
    const request = axios.post(`${PROJECT_SERVER}/getAllProjects`, variables)
    .then(response => response.data)

    return {
        type: GET_ALL_PROJECT,
        payload: request
    }
}

export function projectDetailsByID(id) {
    // console.log("Proejct ID", id);
    const request = axios.get(`${PROJECT_SERVER}/getProjectDetailsByID?projectID=${id}`)
    .then(response => response.data)

    return {
        type: PROJECT_BY_ID,
        payload: request
    }
}

export function UploadNewProject(dataToSubmit) {
    // console.log("Proejct Data Action", dataToSubmit);
    const request = axios.post(`${PROJECT_SERVER}/companyProjectsDeatils`, dataToSubmit)
    .then(response => response.data)

    return {
        type: UPLOAD_NEW_PROJECT,
        payload: request
    }
}

export function DeleteNewUploadProjectImage(dataToSubmit) {
    // console.log("Project Data Action", dataToSubmit);
    const request = axios.post(`${PROJECT_SERVER}/deleteNewUploadProject?id=${dataToSubmit.imageKey}`, dataToSubmit)
    .then(response => response.data)

    return {
        type: DELETE_UPLOAD_NEW_PROJECT_IMAGE,
        payload: request
    }
}

export function DeleteUploadProjectImageDetails(dataToSubmit) {
    // console.log("Project Uploaded Image Details Data Action", dataToSubmit);
    const request = axios.post(`${PROJECT_SERVER}/deleteUploadedProjectImagesDetails?id=${dataToSubmit.imageKey}`, dataToSubmit)
    .then(response => response.data)

    return {
        type: DELETE_UPLOADED_PROJECT_IMAGEDETAILS,
        payload: request
    }
}

export function SP_PreviouslyUploadedProjects() {
    // console.log("Previosuly Uploaded Project Data Action");
    const request = axios.post(`${PROJECT_SERVER}/previouslyUploadedProjects`)
    .then(response => response.data)

    return {
        type: PREVIOUSLY_PROJECTUPLOADED,
        payload: request
    }
}

export function SP_EditPreviouslyUploadedProjects(id) {
    // console.log("Previosuly Uploaded Project Data Action ", id);
    const request = axios.post(`${PROJECT_SERVER}/editPreviouslyUploadedProjects?projectID=${id}`)
    .then(response => response.data)

    return {
        type: EDIT_PREVIOUSLY_PROJECTUPLOADED,
        payload: request
    }
}

export function SP_UpdatePreviouslyUploadedProjects(id, dataToSubmit) {
    // console.log("Previosuly Uploaded Project Data Action ", id, dataToSubmit);
    const request = axios.post(`${PROJECT_SERVER}/updatePreviouslyUploadedProject?projectID=${id}`, dataToSubmit)
    .then(response => response.data)

    return {
        type: UPDATE_PREVIOUSLY_UPLOADED_PROJECT,
        payload: request
    }
}

export function SP_DeleteProject(id, dataToSubmit) {
    // console.log("Delete Uploaded Project Action ", id, dataToSubmit);
    const request = axios.post(`${PROJECT_SERVER}/deleteUploadedProject?projectID=${id}`, dataToSubmit)
    .then(response => response.data)

    return {
        type: DELETE_PROJECT_SP,
        payload: request
    }
}

export function Get_ProjectDetails_By_ProjectID(id) {
    // console.log("Get Project details By Image ID ", id);
    const request = axios.get(`${PROJECT_SERVER}/getProjectByImageID?imageid=${id}`)
    .then(response => response.data)

    return {
        type: GET_PROJECT_BY_IMAGE_ID,
        payload: request
    }
}