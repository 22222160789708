import React from "react";
import LoadingIcon from "../../../../../../../utils/LoadingIcon/LoadingIcon";
import "./SP_CompanyLogo.css";
import defaultLogo from '../../../../../../../asserts/ProfileImages/defaultLogo.jpg'

function SP_CompanyLogo(props) {
  const loading = false;
  // const [loading, setloading] = useState(false);

  return (
    <div className="company-logo-container">
      {loading ?      
        <LoadingIcon
        position="absolute"
        width={"100%"}
        height={"100%"}
        outerwidth={"50px"}
        outerheight={"50px"}
        innerdisplay={"none"}
    
      /> :
        <div className="company-logo">
        {props.Image_Src ? 
          <img src={props.Image_Src} />
        :
          <img src={defaultLogo} />
        }
        </div>
      }
    </div>
  );
}

export default SP_CompanyLogo;
