const Plans = [
  // {
  //   Name: "Startup Bear",
  //   Price: "₹ 175 per lead",
  //   Validity: "7 Days",
  //   Features: [
  //     "Plan Validity: 7 Days",
  //     "Add unlimited Branches",
  //     "Upload unlimited Projects",
  //     "Upload unlimited High Quality Images",
  //     "Select 7 (4 Regular Leads + 3 Extra FREE Leads)",
  //     "1 Lead is shared with 3 Service Professionals",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 7 days",
  //     "Featured Projects in relevant cities & categories for 7 days",
  //   ],
  // },
  // {
  //   Name: "Startup Tiger",
  //   Price: "₹ 375 per lead",
  //   Validity: "7 Days",
  //   Features: [
  //     "Plan Validity: 7 Days",
  //     "Add unlimited Branches",
  //     "Upload unlimited Projects",
  //     "Upload unlimited High Quality Images",
  //     "Select 3 (2 Regular Leads + 1 Extra FREE Lead)",
  //     "1 Lead is shared with 1 Service Professional",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 7 days",
  //     "Featured Projects in relevant cities & categories for 7 days",
  //   ],
  // },
  // {
  //   Name: "Baby Bear",
  //   FalsePrice: "₹ 700",
  //   Price: "₹ 250 per lead",
  //   Validity: "30 Days",
  //   Features: [
  //     "Select 35 (29 Regular Leads + 6 Extra FREE Leads)",
  //     "1 Lead is shared with 3 Service Professionals",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 30 days",
  //     "Featured Projects in relevant cities & categories for 30 days",
  //   ],
  // },
  {
    Name: "Startup Tiger",
    FalsePrice: "₹ 2,296 per lead",
    Price: "₹ 674 per lead",
    Validity: "15 Days",
    Features: [
      "Select 5 Regular Leads",
      "Can Apply For 10 NQA Double Replacement Leads",
      "Total = 15 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 15 days",
      "Featured Projects in relevant cities & categories for 15 days",
    ],
  },
  {
    Name: "Elite Tiger",
    FalsePrice: "₹ 2,296 per lead",
    Price: "₹ 674 per lead",
    Validity: "30 Days",
    Features: [
      "Select 10 Regular Leads",
      "Can Apply For 20 NQA Double Replacement Leads",
      "Total = 30 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 30 days",
      "Featured Projects in relevant cities & categories for 30 days",
    ],
  },
  {
    Name: "Classic Tiger",
    FalsePrice: "₹ 2,296 per lead",
    Price: "₹ 674 per lead",
    Validity: "45 Days",
    Features: [
      "Select 15 Regular Leads",
      "Can Apply For 30 NQA Double Replacement Leads",
      "Total = 45 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 45 days",
      "Featured Projects in relevant cities & categories for 45 days",
    ],
  },
  {
    Name: "Baby Tiger",
    FalsePrice: "₹ 2,296 per lead",
    Price: "₹ 674 per lead",
    Validity: "60 Days",
    Features: [
      "Select 20 Regular Leads",
      "Can Apply For 40 NQA Double Replacement Leads",
      "Total = 60 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 60 days",
      "Featured Projects in relevant cities & categories for 60 days",
    ],
  },
  // {
  //   Name: "Papa Bear",
  //   // Price: "₹ 3,999 + G.S.T. per month",
  //   Price: "₹ 3,999 per month",
  //   Validity: "3 Months",
  //   Features: [
  //     "Plan Validity: 3 months",
  //     "Add unlimited Branches",
  //     "Upload unlimited Projects",
  //     "Upload unlimited High Quality Images",
  //     "Contact unlimited Clients",
  //     "Select unlimited Property Requirements",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 3 months",
  //     "Featured Projects in relevant cities & categories for 3 months",
  //   ],
  // },
  // {
  //   Name: "Mama Bear",
  //   // Price: "₹ 2,999 + G.S.T. per month",
  //   Price: "₹ 2,999 per month",
  //   Validity: "6 Months",
  //   Features: [
  //     "Plan Validity: 6 months",
  //     "Add unlimited Branches",
  //     "Upload unlimited Projects",
  //     "Upload unlimited High Quality Images",
  //     "Contact unlimited Clients",
  //     "Select unlimited Property Requirements",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 6 months",
  //     "Featured Projects in relevant cities & categories for 6 months",
  //   ],
  // },
  // {
  //   Name: "Teddy Bear",
  //   // Price: "₹ 1,999 + G.S.T. per month",
  //   Price: "₹ 1,999 per month",
  //   Validity: "12 Months",
  //   Features: [
  //     "Plan Validity: 12 months",
  //     "Add unlimited Branches",
  //     "Upload unlimited Projects",
  //     "Upload unlimited High Quality Images",
  //     "Contact unlimited Clients",
  //     "Select unlimited Property Requirements",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 12 months",
  //     "Featured Projects in relevant cities & categories for 12 months",
  //   ],
  // },
];

const BabyFeatures = [
  "Plan Validity: 1 month",
  "Add unlimited Branches",
  "Upload unlimited Projects",
  "Upload unlimited High Quality Images",
  "Contact unlimited Clients",
  "Select unlimited Property Requirements",
  "Gain access to Profile Performance & Analytics",
  "Contact additional unlimited clients using Analytics",
  "Featured Profile in relevant cities & categories for 1 month",
  "Featured Projects in relevant cities & categories for 1 month",
];

const PapaFeatures = [
  "Plan Validity: 3 months",
  "Add unlimited Branches",
  "Upload unlimited Projects",
  "Upload unlimited High Quality Images",
  "Contact unlimited Clients",
  "Select unlimited Property Requirements",
  "Gain access to Profile Performance & Analytics",
  "Contact additional unlimited clients using Analytics",
  "Featured Profile in relevant cities & categories for 3 months",
  "Featured Projects in relevant cities & categories for 3 months",
];

const MamaFeatures = [
  "Plan Validity: 6 months",
  "Add unlimited Branches",
  "Upload unlimited Projects",
  "Upload unlimited High Quality Images",
  "Contact unlimited Clients",
  "Select unlimited Property Requirements",
  "Gain access to Profile Performance & Analytics",
  "Contact additional unlimited clients using Analytics",
  "Featured Profile in relevant cities & categories for 6 months",
  "Featured Projects in relevant cities & categories for 6 months",
];

const TeddyFeatures = [
  "Plan Validity: 12 months",
  "Add unlimited Branches",
  "Upload unlimited Projects",
  "Upload unlimited High Quality Images",
  "Contact unlimited Clients",
  "Select unlimited Property Requirements",
  "Gain access to Profile Performance & Analytics",
  "Contact additional unlimited clients using Analytics",
  "Featured Profile in relevant cities & categories for 12 months",
  "Featured Projects in relevant cities & categories for 12 months",
];

module.exports.Plans = Plans;
module.exports.BabyFeatures = BabyFeatures;
module.exports.PapaFeatures = PapaFeatures;;
module.exports.MamaFeatures = MamaFeatures;
module.exports.TeddyFeatures = TeddyFeatures;
