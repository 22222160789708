import React, { useLayoutEffect, useState } from "react";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./ContactUs.css";
import fb from "./Fb.png";
import Insta from "./Insta.png";

const ContactUs = () => {

  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
  }, [screenwidth]);

  return (
    <div className="contact-us-container">
      <PageBanner title="Contact Us" />
      <div className="header">
        <p className="title">
          <b>Get in Touch</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          If you have any queries or suggestions, you can contact us via any of the following communication methods:
        </p>
      </div>
      <div className="container" style={{ marginTop: '20px' }}>
        <a className="link" href="https://www.facebook.com/nebour.hood.23/" target="_blank">
          <img src={fb} alt="instagram" />
          {/* Nebourhood */}
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a className="link" href="https://www.instagram.com/nebour.hood.23/" target="_blank" >
          <img src={Insta} alt="instagram" />
          {/* Nebourhood */}
        </a>
        <br /><br />
        <div className="header">
        <p className="title">Contact Address</p>
        <p className="desc">
          Our office is present in Bangalore.
          <br /><br />
          {/* Building no. 77/A1, 
          <br />
          13th main, 12th cross, 
          <br />
          3rd floor, Door no. 77/D2, 
          <br />
          Virat Nagar, 
          <br />
          Bommanahalli, 
          <br />
          Bangalore - 560068
          <br /> */}
          Email: &nbsp;
          <a
            style={{
              color: "#1dc295",
            }}
            href="mailto:hello@nebourhood.com"
          >
            hello@nebourhood.com
          </a>
        </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
