import React from "react";
import LoadingIcon from "../../../../../../utils/LoadingIcon/LoadingIcon";
import "./SP_Cover_Image.css";
import CoverImage from '../../../../../../asserts/ProfileImages/coverImage.jpg'

function SP_Cover_Image(props) {
  const loading = false;
  // const [loading, setloading] = useState(false);

  return (
    <div className="cover-img-container">
      {loading ? (
          <LoadingIcon
            position="relative"
            width={"100%"}
            height={"100%"}
            outerwidth={"50px"}
            outerheight={"50px"}
            innerdisplay={"none"}
            left={0}
            top={0}
          />
        ) : (
          <div className="cover-img">
            {props.Image_Src ?
            <img src={props.Image_Src} alt="cover-img" />
            :
            <img src={CoverImage} alt="cover-img" />
            }
          </div>
        )
      }

    </div>
  );
}

export default SP_Cover_Image;
