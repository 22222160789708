import React, { useEffect, useLayoutEffect, useState } from "react";
import "./POTransactionPage.css";
import { table } from "./Data";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PropertyRequirementInvoice from "./PropertyRequirementInvoice/PropertyRequirementInvoice";

function POTransactionPage(props) {
  const user = useSelector((state) => state.user);
  const [TransactionDetails, setTransactionDetails] = useState([]);
  const [ShowLoading, setShowLoading] = useState(true);
  const [invoiceDetails, setinvoiceDetails] = useState({
    InvoicePaymentId: "",
    ProfileNumber: "",
    Name: "",
    PropertyNumber: "",
    PropertyName: "",
    LandBuilding: "",
    DateandTimeofApproval: "",
    State: "",
    City: "",
    DurationSelected: "",
    CostPrice: "",
    FinalAmount: "",
    GSTAmount: "",
    TotalAmount: "",
    Status: "",
    PaymentStatus: "",
    PaymentMode: "",
    Posted: "",
    Edited: "",
    Renewed: "",
    ReasonForExpire: "",
  });

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  useEffect(() => {
    Axios.get(`/api/users/getproperty`).then((response) => {
      // console.log("Response", response.data.product);
      setTransactionDetails(response.data.product);
      setShowLoading(false);
    });
  }, [invoiceDetails]);

  const setdata = async(e) => {
    const details = e.currentTarget.parentElement.parentElement.childNodes;
    // console.log("Target Value ", e.currentTarget.parentElement.parentElement.childNodes);

    details.forEach(element => {
      const content = element.innerText;
      setinvoiceDetails((prev) => {
        return {
          InvoicePaymentId: element.id === "paymentid" ? content : prev.InvoicePaymentId,
          ProfileNumber: element.id === "profilenumber" ? content : prev.ProfileNumber,
          Name: element.id === "ownername" ? content : prev.Name,
          PropertyNumber: element.id === "propertyid" ? content : prev.PropertyNumber,
          LandBuilding: element.id === "landbuilding" ? content : prev.LandBuilding,
          DateandTimeofApproval: element.id === "postedon" ? content : prev.DateandTimeofApproval,
          State: element.id === "state" ? content : prev.State,
          City: element.id === "city" ? content : prev.City,
          DurationSelected: element.id === "duration" ? content : prev.DurationSelected,
          CostPrice: element.id === "costprice" ? content : prev.CostPrice,
          FinalAmount: element.id === "finalamount" ? content : prev.FinalAmount,
          GSTAmount: element.id === "gstamount" ? content : prev.GSTAmount,
          TotalAmount: element.id === "totalamount" ? content : prev.TotalAmount,
          Status: element.id === "status" ? content : prev.Status,
          PaymentStatus: element.id === "paymentstatus" ? content : prev.PaymentStatus,
          PaymentMode: element.id === "paymentmode" ? content : prev.PaymentMode,
          ReasonForExpire: element.id === "reasonforexpire" ? content : prev.ReasonForExpire,
          Posted: element.id === "posted" ? content : prev.Posted,
          Edited: element.id === "edited" ? content : prev.Edited,
          Renewed: element.id === "renewed" ? content : prev.Renewed
        }
      })
    });

  }

  const handledownloadpdf = (e) => {
    setdata(e).then(() => {
      // console.log("Lead PDF Details ", );
      const invoicecontainer = document.getElementById("plans-invoice-container");
      invoicecontainer.style.display = "block";
      html2canvas(document.getElementById("plans-invoice-container"), {
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);
  
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
        invoicecontainer.style.display = "none";
      })
    })
  }

  if (user.userData && user.userData.isAuth) {
    let userDetails = user.userData;
    return (
      <>
      {ShowLoading ? 
        <LoadingIcon /> 
        : 
        <div className="po-transaction-table-container">
          <div className="header">
            <p className="title">
              <b>Transaction Details</b>
            </p>
            <p
              style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
              className="description"
            >
              Here, you can view all the Payments related to your Property Requirements
            </p>
          </div>
          <br />
          <br />
          <div
            style={{
              width: screenwidth <= 830 ? screenwidth - 20 + "px" : null,
            }}
            className="transaction-table"
          >
            <table>
              <tr className="table-titles">
                {table.titles.map((title, index) => {
                  return (
                    <th
                      key={index}
                      colSpan={title.colspan}
                      rowSpan={title.rowspan}
                    >
                      {title.Name}
                    </th>
                  );
                })}
              </tr>
              <tr className="table-sub-titles">
                {table.subtitles.map((subtitle, index) => {
                  return <td key={index}>{subtitle}</td>;
                })}
              </tr>

              {TransactionDetails.map((data, index) => {
                return (
                  <tr className="transaction-data">
                    <td>{index + 1}</td>
                    <td id="paymentid">
                      {data.PaymentDone ? (
                        <>{data.PaymentDetails.razorpay_payment_id}</>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td id="profilenumber"><a href={`/PropertyOwner/MyProfile`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>View Details</a></td>
                    <td id="ownername">{userDetails.name}</td>
                    <td id="propertyid"><a href={`/PropertyOwner/PropertyRequirementsDetails/${data._id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>View Details</a></td>
                    <td id="landbuilding"><a href={`/PropertyOwner/PropertyRequirementsDetails/${data._id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>{data.Land_Building}</a></td>
                    <td>
                      {data.Posted ? (
                        <a
                          className="view-btn"
                          onClick={() =>
                            props.history.push(
                              `/PropertyOwner/PostedPropertyDetails/${data._id}`
                            )
                          }
                        >
                          View
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      {data.Edited ? (
                        <a
                          className="view-btn"
                          onClick={() =>
                            props.history.push(
                              `/PropertyOwner/EditedPropertyDetails/${data._id}`
                            )
                          }
                        >
                          View
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      {data.Renewed ? (
                        <a
                          className="view-btn"
                          onClick={() =>
                            props.history.push(
                              `/PropertyOwner/RenewalPropertyDetails/${data._id}`
                            )
                          }
                        >
                          View
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td id="postedon">{data.Posted_Date}</td>
                    <td id="state">{data.State}</td>
                    <td id="city">{data.City}</td>
                    <td id="duration">{data.Beginning_The_Project}</td>
                    <td id="costprice">{data.PaymentDone ? <>499</> : <>-</>}</td>
                    <td id="finalamount">{data.PaymentDone ? <>499</> : <>-</>}</td>
                    <td id="gstamount">{data.PaymentDone ? <>89.82</> : <>-</>}</td>
                    <td id="totalamount">{data.PaymentDone ? <>588.82</> : <>-</>}</td>
                    <td id="status">
                      {data.PaymentDone ? <>Payment Completed</> : <>Free</>}
                    </td>
                    <td id="paymentstatus">
                      {data.PaymentDone ? <>Completed Successfully</> : <>-</>}
                    </td>
                    <td id="paymentmode">{data.PaymentDone ? <>Online</> : <>-</>}</td>
                    <td id="posted">
                        {data.Posted ? 
                          <>
                          {data.sold > 0 ? 
                        <>
                          {data.Validity_Till <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                        :
                        <>
                          {data.sold <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                      }
                          </>
                          :
                          <>-</>
                        }
                    </td>
                    <td id="edited">
                    {data.Edited ? 
                          <>
                          {data.sold > 0 ? 
                        <>
                          {data.Validity_Till <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                        :
                        <>
                          {data.sold <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                      }
                          </>
                          :
                          <>-</>
                        }
                    </td>
                    <td id="renewed">
                    {data.Renewed ? 
                          <>
                          {data.sold > 0 ? 
                        <>
                          {data.Validity_Till <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                        :
                        <>
                          {data.sold <= 0 ? 
                            <>Expired</>
                          :
                          <>Active</>
                          }
                        </>
                      }
                          </>
                          :
                          <>-</>
                        }
                    </td>
                    <td id="reasonforexpire">
                      {data.sold > 0 ? 
                        <>
                          {data.Validity_Till <= 0 ? 
                            <>Validity Expired</>
                          :
                          <>-</>
                          }
                        </>
                        :
                        <>
                          {data.sold <= 0 ? 
                            <>Contact Limit Reached</>
                          :
                          <>-</>
                          }
                        </>
                      }
                    </td>
                    <td className="btn-actions">
                    {data.sold > 0 && data.Validity_Till > 0 ? 
                      <a
                        className="edit-btn"
                        onClick={() =>
                          props.history.push(
                            `/PropertyOwner/PostedPropertyDetails/Edit/${data._id}`
                          )
                        }
                      >
                        Edit
                      </a>
                      : 
                      null
                    }

                      <a
                        className="renew-btn"
                        href={`/PropertyOwner/PostedPropertyDetails/Renew/${data._id}`}
                      >
                        Renew
                      </a>
                    </td>
                    <td>
                        <p onClick={handledownloadpdf}>
                          <span className="material-icons">download</span>
                        </p>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <div className="invoice">
          {<PropertyRequirementInvoice InvoiceDetails={invoiceDetails} userDetails={user} />}
        </div>
        </div>
      }
      </>
    );
  } else {
    return <LoadingIcon />;
  }
}
export default withRouter(POTransactionPage);
