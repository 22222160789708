module.exports.table = {
  titles: [
    {
      Name: "SL NO.",
      rowspan: 2,
    },
    {
      Name: "Invoice Payment ID",
      rowspan: 2,
    },
    {
      Name: "Purchase Order ID",
      rowspan: 2,
    },
    {
      Name: "Date & Time Of Payment",
      rowspan: 2,
    },
    {
      Name: "Subscribed Plan Details",
      colspan : 8
    },
    {
      Name: "Payment Status",
      rowspan: 2,
    },
    {
      Name: "Payment Mode",
      rowspan: 2,
    },
  ],
  subtitles: [
    "Plan Name",
    "Plan Validity",
    "Original Amount (Exclusive of G.S.T.)",
    "Discounted Amount (Exclusive of G.S.T.)",
    "G.S.T. Amount (18%)",
    "Discounted Amount (Inclusive of G.S.T.)",
    "Total Savings",
    // "Plan Expires On",
    "Plan Status",
  ],
};