export const BABY_BEAR_PRICE = 6499;
export const BABY_BEAR_VALIDITY = 30;

export const PAPA_BEAR_PRICE = 11997;
export const PAPA_BEAR_VALIDITY = 90;

export const MAMA_BEAR_PRICE = 17994;
export const MAMA_BEAR_VALIDITY = 180;

export const TEDDY_BEAR_PRICE = 23988;
export const TEDDY_BEAR_VALIDITY = 365;

export const STARTUP_TIGER_PRICE = 3499;
export const STARTUP_TIGER_VALIDITY = 15;

export const ELITE_TIGER_PRICE = 6999;
export const ELITE_TIGER_VALIDITY = 30;

export const CLASSIC_TIGER_PRICE = 10499;
export const CLASSIC_TIGER_VALIDITY = 45;

export const BABY_TIGER_PRICE = 13999;
export const BABY_TIGER_VALIDITY = 60;

export const WEBY_BEAR_PRICE = 3999;
export const WEBY_BEAR_VALIDITY = 30;

export const WEBY_TIGER_PRICE = 3999;
export const WEBY_TIGER_VALIDITY = 30;

export const STARTUP_BEAR_PRICE = 799;
export const STARTUP_BEAR_VALIDITY = 7;

export const POST_BABY_BEAR_PRIME_PRICE = 4999;
export const POST_BABY_BEAR_PRIME_VALIDITY = 30;

export const POST_BABY_TIGER_PRIME_PRICE = 4999;
export const POST_BABY_TIGER_PRIME_VALIDITY = 30;

export const STARTUP_TIGER_PRIME_PRICE = 3199;
export const STARTUP_TIGER_PRIME_VALIDITY = 15;

export const ELITE_TIGER_PRIME_PRICE = 6699;
export const ELITE_TIGER_PRIME_VALIDITY = 30;

export const CLASSIC_TIGER_PRIME_PRICE = 10199;
export const CLASSIC_TIGER_PRIME_VALIDITY = 45;

export const BABY_TIGER_PRIME_PRICE = 13699;
export const BABY_TIGER_PRIME_VALIDITY = 60;

