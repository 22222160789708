import axios from "axios";
import { GALLERY_SERVER } from "../Config";
import { GALLERYPAGEVIEWS } from "./types";

export function gallerypageview(filterdata) {
    // console.log("Gallery Page View Action");
    const request = axios.post(`${GALLERY_SERVER}/galleryPageView`, filterdata)
                .then(response => response.data);

    return {
        type: GALLERYPAGEVIEWS,
        payload: request
    }
}