const resourcesData = [
    {
        Facility : "What is Renovation ?",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/renovate.svg",
        URL: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/Renovation.pdf"
    },
    {
        Facility : "What is Architecture ?",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/architecture.svg",
        URL: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/Architecture.pdf"
    },
    {
        Facility : "What is Construction ?",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/construction.svg",
        URL: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/Construction.pdf"
    },
    {
        Facility : "What is Interior Design ?",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/interiordesign.svg",
        URL: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/FreeResources/InteriorDesign.pdf"
    }
]

module.exports.resourcesData = resourcesData;