import React, { useEffect, useState } from 'react'
import "./OtherViewProfile.css";
import { useDispatch } from 'react-redux';
import Cover_Image from '../../Service_Professional/Profile/Views/CoverImage/Cover_Image';
import PO_Cover_Profile_Pic from '../MyProfile/PO_Cover_Profile_Pic/PO_Cover_Profile_Pic';
import LoadingIcon from '../../../../utils/LoadingIcon/LoadingIcon';
import ProfileOverview from './ProfileOverview/ProfileOverview';
import { Other_View_Profile } from '../../../../../_actions/company_analytics';
import Property from './Property/Property';

function OtherViewProfile(props) {

    const dispatch = useDispatch();
    const [setLoading, setsetLoading] = useState(true);
    const [propertyOwnerName, setpropertyOwnerName] = useState("");
    const [ProfileDetails, setProfileDetails] = useState({});
    const [Products, setProducts] = useState([]);

    const [overviewclicked, setoverviewclicked] = useState(true);
    const [propertyrequirements, setpropertyrequirements] = useState(false);
  

    useEffect(() => {
            dispatch(Other_View_Profile(props.match.params.ProfileID)).then((response) => {
                if(response){
                    // console.log("Response Profile Details ", response.payload.result);
                    setProfileDetails(response.payload.result.ProfileDetails.Personal_Details);
                    setpropertyOwnerName(response.payload.result.ProfileDetails.name)
                    setProducts(response.payload.result.Products);
                    setsetLoading(false);
                } else {
                    alert("Error in Finding Property Owner Profile");
                }
            })
    }, [])

    const handleprofiletabs = (e) => {
        const clickedel = e.target;
        const profiletabs = e.currentTarget.childNodes;
        profiletabs.forEach((tab) => {
          tab.style.backgroundColor = "rgb(233, 232, 232)";
          tab.style.color = "black";
        });
        clickedel.style.backgroundColor = "#1dc295";
        clickedel.style.color = "white";
        setoverviewclicked(false);
        setpropertyrequirements(false);
        if (clickedel.className === "overview") {
          setoverviewclicked(true);
        }
        
        if (clickedel.className === "analytics") {
          setpropertyrequirements(true);
        }
      };
    
      if(setLoading === true) {
        return <LoadingIcon />
      } else {
        return (
            <div className="po-profile-container">
                
                <Cover_Image nochange={true}
                    Image_Src={"https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/DefaultImages/image-1.jpg"}
                />
                
                <PO_Cover_Profile_Pic
                nochange={true}
                userName={propertyOwnerName}
                />

                <div onClick={handleprofiletabs} id="profile-tabs-container" className="profile-tabs-container" >
                <p className="overview">Profile Overview</p>
                <p className="analytics">My Property Requirements</p>
                </div>


                {overviewclicked ? <ProfileOverview UserDetails={ProfileDetails} /> : null }
                {propertyrequirements ? <Property Property={Products} /> : null}
                
            </div>
  )
}

}

export default OtherViewProfile