export const Residential = {
    SubCategories: {
      Titles: [
        "Apartment",
        "Penthouse",
        "Stand Alone building",
        "Independent Home",
        "Villa",
        "Bungalow",
      ],
      Options: [
        {
          Title: "Bathroom",
          SubOptions: ["Bathroom / Washroom"],
        },
        {
          Title: "Bedroom",
          SubOptions: ["Guest Bedroom", "Kid’s Bedroom", "Master Bedroom"],
        },
        {
          Title: "Disabled / Handicap – Accessible Designs & Services",
          SubOptions: [],
        },
        {
          Title: "Entertainment",
          SubOptions: ["Game Room", "Home Theatre / Media Room"],
        },
        {
          Title: "Fitness",
          SubOptions: ["Workout Area / Gym"],
        },
        {
          Title: "Health & Safety",
          SubOptions: [
            "Disaster Management Room",
            "Emergency Panic / Medical Room ",
          ],
        },
        {
          Title: "Kitchen and Dining",
          SubOptions: ["Dining Room", "Kitchen"],
        },
        {
          Title: "Living",
          SubOptions: ["Living Room"],
        },
        {
          Title: "Multi - Faith Space",
          SubOptions: ["Prayer Room / Puja Room"],
        },
        {
          Title: "Outdoor",
          SubOptions: [
            "Backyard",
            "Balcony",
            "Deck",
            "Garden",
            "Landscape",
            "Outdoor Play Area",
            "Patio",
            "Pet House",
            "Porch",
            "Swimming Pool",
            "Terrace",
          ],
        },
        {
          Title: "Passage Ways",
          SubOptions: [
            "Doorways",
            "Elevator",
            "Emergency Exits",
            "Hallway / Corridor / Passage",
            "Home Arch",
            "Staircase",
          ],
        },
        {
          Title: "Productivity",
          SubOptions: ["Home Office / Workstation", "Study Area / Library"],
        },
        {
          Title: "Security Systems",
          SubOptions: [
            "Automated Access Control Systems (AACS) ",
            "Closed Circuit Television Systems (CCTV)",
            "Intrusion Detection Systems (ID) ",
          ],
        },
        {
          Title: "Utility",
          SubOptions: [
            "Basement",
            "Garage",
            "Laundry",
            "Parking",
            "Store Room",
            "Utility Room",
            "Workshop",
          ],
        },
      ],
    },
  }

  export const Commercial = {
    SubCategories: [
      {
        Title: "Retail",
        Options: [
          "Factory Outlets",
          "Malls",
          "Retail Stores",
          "Shopping Centres",
          "Wholesale Stores",
        ],
      },
      {
        Title:
          "Hospitality - (Accommodation, Food & Beverage, Travel & Tourism)",
        Options: [
          "Cafés",
          "Clubs",
          "Convention Centres",
          "Hotels",
          "Resorts",
          "Restaurants",
        ],
      },
      {
        Title: "Healthcare",
        Options: [
          "Child Care Centres",
          "Clinics & Medical Offices ",
          "Disaster Management ",
          "Hospitals",
          "Recreation",
          "Rehabilitation Centres",
          "Spa, Sauna & Therapy Centres",
          "Sports Facilities",
        ],
      },
      {
        Title: "Office - Corporate & Tech",
        Options: ["Co - Working Space", "Private Offices", "Virtual Office "],
      },
      {
        Title: "Industrial",
        Options: ["Factories", "Warehouses"],
      },
      {
        Title: "Institutional",
        Options: [
          {
            Title: "Educational Sector",
            SubOptions: [
              "Colleges ",
              "Institutions",
              "Schools",
              "Universities",
            ],
          },
          {
            Title: "Financial Sector",
            SubOptions: ["Banks"],
          },
          {
            Title: "Media & Entertainment Sector",
            SubOptions: [
              "Art Galleries",
              "Cultural Centres & Events",
              "Film Sets",
              "Studios",
              "Theatres",
            ],
          },
          {
            Title: "Research & Development Sector",
            SubOptions: [
              "Museums",
              "Scientific Research Labs, Campuses & Testing Centres",
            ],
          },
          {
            Title: "Transportation Sector",
            SubOptions: [
              "Airports",
              "Bus Depots ",
              "Metro Stations",
              "Railway Stations",
              "Ship Ports & Docks",
            ],
          },
        ],
      },
    ],
  }

