import React, { useEffect, useLayoutEffect, useState } from "react";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import { propertydetails } from "./Data";
import "./PreviouslyProjectDetailsPage.css";
import { useDispatch } from "react-redux";
import { projectDetailsByID } from "../../../../../_actions/project_actions";
import Carousel from "../../../Profiles/Service_Professional/Profile/Views/EditPreviouslyUploadedProjectDetailedPage/Views/EditProject/Carousel/Carousel";
import ProjectImagesCarousel from "../../../LandingPage/Project_Details/ProjectImagesCarousel/ProjectImagesCarousel";
import { withRouter } from "react-router-dom";

function PreviouslyProjectDetailsPage(props) {
  const propertyid = props.id;
  const dispatch = useDispatch();
  const projectId = props.match.params.ProjectID;

  const [ProjectDetails, setProjectDetails] = useState([]);
  const [ProjectImages, setProjectImages] = useState([]);
  const [ProjectAddress, setProjectAddress] = useState([]);
  const [ServiceProfessionalName, setServiceProfessionalName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [ServiceProfessionalID, setServiceProfessionalID] = useState();
  const [ProfessionalDetails, setProfessionalDetails] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState("");

  const currentproperty = propertydetails[0];

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [dotelement, setdotelement] = useState("dots");

  const [name, setname] = useState(null);
  const [phone, setphone] = useState(null);
  const [description, setdescription] = useState(null);
  const [formfilled, setformfilled] = useState(false);
  const [submitclicked, setsubmitsclicked] = useState(false);
  const [requestsubmittedbackend, setrequestsubmittedbackend] = useState(false);
  const requestdetails = {
    Name: name,
    Phone: phone,
    Description: description,
  };
  const [projectcarousel, setprojectcarousel] = useState(false);

  useEffect(() => {
    dispatch(projectDetailsByID(projectId)).then((response) => {
      // console.log("Response", response);
      setProjectDetails(response.payload.result.ProjectDetails[0]);
      setProjectImages(response.payload.result.ProjectDetails[0].Images);
      setProjectAddress(response.payload.result.ProjectDetails[0].Address);
      setServiceProfessionalName(response.payload.result.CompanyDetails.name);
      setCompanyName(response.payload.result.CompanyDetails.Company_Details.Company_Name);
      setServiceProfessionalID(response.payload.result.CompanyDetails._id);
      setProfessionalDetails(response.payload.result.CompanyDetails.Professional_Details.Professional_Profession);
      setCompanyLogo(response.payload.result.CompanyDetails.Personal_Details.Profile_Pic);
      setprojectcarousel(true);
      // console.log("Company Name ", response.payload.result.CompanyDetails.Company_Details.Company_Name);
    });
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
    {
      /* For Others */
    }

    // setTimeout(() => {
    //   if (screenwidth < 1290) {
    //     setdotelement("bottom-dots");
    //   } else {
    //     setdotelement("dots");
    //   }
    //   const dots = document.getElementById(`${dotelement}`).childNodes;
    //   dots[0].style.opacity = 1;
    //   dots[0].style.transform = "scale(1.3)";
    //   console.log(dots[0]);
    //   for (let i = 0; i < dots.length; i++) {
    //     dots[i].addEventListener("click", (e) => {
    //       for (let i = 0; i < dots.length; i++) {
    //         dots[i].style.opacity = 0.5;
    //         dots[i].style.transform = "scale(1)";
    //       }
    //       e.currentTarget.style.opacity = 1;
    //       e.currentTarget.style.transform = "scale(1.3)";
    //       if (screenwidth < 1290 && screenwidth > 1080) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-350 * i}px)`;
    //       }
    //       if (screenwidth < 1080 && screenwidth > 800) {
    //         console.log(screenwidth);
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 110) * i}px)`;
    //       }
    //       if (screenwidth < 800) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 35) * i}px)`;
    //       }
    //       if (screenwidth > 1290) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-450 * i}px)`;
    //       }
    //     });
    //   }
    // }, 10);
  }, [screenwidth, dotelement]);

  const handlename = (e) => {
    setname(e.target.value);
  };
  const handlephone = (e) => {
    setphone(e.target.value);
  };
  const handledescription = (e) => {
    setdescription(e.target.value);
  };
  useEffect(() => {
    if (name && phone && description) {
      setformfilled(true);
    } else {
      setformfilled(false);
    }
  }, [name, phone, description]);

  const handlesubmitform = (e) => {
    e.preventDefault();
    if (formfilled) {
      // console.log(requestdetails);
      setsubmitsclicked(true);
      setTimeout(() => {
        setsubmitsclicked(false);
        setrequestsubmittedbackend(true);
      }, 2000);
    } else {
      setsubmitsclicked(false);
    }
  };

  // console.log(ProjectDetails)

  return (
    <div className="project-details-container">
      <PageBanner
        title="Project Details"
      />
      <Carousel imgwidth="50%" widthtomove={50} Images={ProjectImages} />
      <div className="project-details">
        <div className="Details">
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="property"
          >
            <div className="about">
              <div className="tagsandprice">
                <div className="tags">
                  <p className="featured">{ProjectDetails.Project_Type}</p>
                  <p className="rent">
                    {ProjectDetails.Project_Type_Subcategory_1}
                  </p>
                </div>
                <div className="price-container">
                  <p className="price">
                    Uploaded on: {ProjectDetails.Uploaded_Date}
                  </p>
                </div>
              </div>
              <p className="address">
                {ProjectAddress.House_Building_Plot_Land},{" "}
                {ProjectAddress.State}, {ProjectAddress.City},{" "}
                {ProjectAddress.Areaname}
              </p>
              <div className="dtl">
                <h2 className="title">Project Name: </h2>
                <p className="ans">{ProjectDetails.Project_Name}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Service Professional Name:</h2>
                <p className="ans">
                  {ServiceProfessionalName}
                </p>
              </div>
              <div className="dtl">
                <h2 className="title">Company Name:</h2>
                <p className="ans">{CompanyName}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Project No:</h2>
                <p className="ans">{ProjectDetails._id}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Property Type:</h2>
                <p className="ans">
                  {ProjectDetails.Project_Type_Subcategory_2}
                </p>
              </div>

              <div className="otherdetailsandrating">
                <div className="other-details">
                  <p>
                    <b>Project Service Requirement:</b> <br />
                    {ProjectDetails.Service_Requirement}
                  </p>
                  <br />
                  <p>
                    <b>Project Year of Completion:</b> <br />
                    {ProjectDetails.Project_Completion}
                  </p>
                  <br />
                  <p>
                    <b>No. of Images:</b> <br />
                    {ProjectImages.length}
                  </p>
                </div>
                {/* <div className="rating">
                  <p>Verified</p>
                </div> */}
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>User Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Service Professional Profile Number :</p>
                  <p className="input">{ServiceProfessionalID}</p>
                </div>
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>Personal Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Service Professional Name :</p>
                  <p className="input">
                    &nbsp;&nbsp;
                    {ServiceProfessionalName}
                  </p>
                </div>
              </div>
            </div>
            <br />

            <div className="address-container">
              <h2>Address</h2>
              <p className="title">
                Details of the property where the work must be completed :
              </p>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">
                      House Name / Building Name / Plot Name / Land Name
                      (Property Name):
                    </li>
                    <li>{ProjectAddress.House_Building_Plot_Land}</li>
                    <li className="head">State:</li>
                    <li>{ProjectAddress.State}</li>
                    <li className="head">City:</li>
                    <li>{ProjectAddress.City}</li>
                    <li className="head">Area Name:</li>
                    <li>{ProjectAddress.Areaname}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Professional Details</h2>
              <p className="title">Profession: </p>
              <div className="features">
                <p className="item">{ProfessionalDetails}</p>
              </div>
              <br />
            </div>
            <br />
            <div className="address-container">
              <h2>Company Details</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Company Name:</li>
                    <li>{CompanyName}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="address-container">
              <h2>Project Information</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Project Number:</li>
                    <li>{ProjectDetails._id}</li>
                    <li className="head">Project Name:</li>
                    <li>{ProjectDetails.Project_Name}</li>
                    <li className="head">Project Client Name:</li>
                    <li>{ProjectDetails.Project_Client_Name}</li>
                    <li className="head">Project Year of Completion:</li>
                    <li>{ProjectDetails.Project_Completion}</li>
                    <li className="head">Project Value in Indian Rupee:</li>
                    <li>{ProjectDetails.Project_Value}</li>
                    <li className="head">
                      Project Page link to Company Website / Company YouTube
                      Channel URL:
                    </li>
                    <li>
                      <a href={ProjectDetails.Project_Page_Link}>
                      {ProjectDetails.Project_Page_Link}
                      </a>
                    </li>
                    <li className="head">Project Description:</li>
                    <li>{ProjectDetails.Project_Description}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Project Service, Type & Style</h2>
              <p className="title">Project Service Requirement: </p>
              <div className="features">
                <p className="item">{ProjectDetails.Service_Requirement}</p>
              </div>
              <br />
              <p className="title">Project Type & Style: </p>
              <div className="features">
                <p className="item">{ProjectDetails.Project_Type}</p>
                <p className="item">
                  {ProjectDetails.Project_Type_Subcategory_1}
                </p>
                <p className="item">
                  {ProjectDetails.Project_Type_Subcategory_2}
                </p>
                <p className="item">
                  {ProjectDetails.Project_Architecture_Style}
                </p>
              </div>
            </div>
            <br />
            <br />
            {projectcarousel ? (
              <div className="address-container">
                <h2>Images</h2>
                <ProjectImagesCarousel
                  ProjectDetails={ProjectDetails}
                  categories={[1, 2, 3, 4]}
                  Images={ProjectImages}
                />
              </div>
            ) : null}
            <br />
            <div className="address-container">
                <h2>Declaration Agreed</h2>
                <br />
                  <p>
                  The Service Professional has agreed to this&nbsp; 
                  <a href="/ServiceProfessional/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                    Declaration.
                  </a>
                  </p>
              </div>
          </div>
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="contact-or-share"
          >
            <div className="shareanddownload">
              <button id="edit-project" className="add-to-cart-btn" onClick={() => props.history.push(`/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/EditProject/${projectId}`)} >
                Edit Project
              </button>
            </div>
            <br />
            <div className="shareanddownload">
              <button id="edit-project" className="add-to-cart-btn">
                Share Project
              </button>
            </div>
            <br />
            <div className="contact">
              <div className="dealer-details">
                <img src={CompanyLogo} alt={currentproperty.Dealer.Name} />
                <div className="dealer-name">
                  <h3>Uploaded By</h3>
                  <a href={`/ServiceProfessional/Profile/OtherView/${ProjectDetails.writer}`}>
                    {ServiceProfessionalName}
                  </a>
                </div>
              </div>
            </div>
            <br />
            <div className="tagcloud">
              <h2>Tags Cloud</h2>
              <div className="tags">
                <ul>
                  <li>
                    <a href="/ServiceProfessional/Projects">Apartment</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Penthouse</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Stand Alone Building</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Independent Home</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Villa</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Bungalow</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Retail</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Hospitality</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Healthcare</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Office – Corporate and Tech</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Institutional</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/Projects">Industrial</a>
                  </li>
                </ul>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PreviouslyProjectDetailsPage);