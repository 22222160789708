import React, { useLayoutEffect, useState } from "react";
import Carousel from "../../Profiles/Service_Professional/Profile/Views/EditPreviouslyUploadedProjectDetailedPage/Views/EditProject/Carousel/Carousel";
import { testimonials } from "./Data";
import "./Testimonials.css";

export default function Testimonials() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  // setTimeout(() => {
  //   const Testimonials = document.getElementById("Testimonials");

  //   // const transformwidth = parseInt(
  //   //   testimonial.style.transform.replace(/[^\d.]/g, "")
  //   // );

  //   var widthtomove = 100;
  //   setInterval(() => {
  //     Testimonials.style.transform = `translateX(-${widthtomove}%)`;
  //     widthtomove = widthtomove + 100;
  //     if (widthtomove > (Testimonials.childNodes.length - 1) * 100) {
  //       widthtomove = 0;
  //     }
  //   }, 5000);
  // }, 50);

  const handlearrow = (e) => {
    const Testimonials =
      e.currentTarget.parentElement.parentElement.childNodes[1].childNodes[0]
        .childNodes;
    const clickedarrow = e.currentTarget.className;
    const transformwidth = parseInt(
      document
        .getElementById("Testimonials")
        .style.transform.replace(/[^\d.]/g, "")
    );
    var widthtomove = 100;
    var lengthtomove = Testimonials.length - 1;

    if (clickedarrow === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById(
          "Testimonials"
        ).style.transform = `translateX(-${widthtomove * lengthtomove}%)`;
      } else {
        document.getElementById(
          "Testimonials"
        ).style.transform = `translateX(-${transformwidth - widthtomove}%)`;
      }
    }
    // console.log(transformwidth);

    if (clickedarrow === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById(
          "Testimonials"
        ).style.transform = `translateX(-${widthtomove}%)`;
      }
      if (transformwidth >= widthtomove * lengthtomove) {
        document.getElementById(
          "Testimonials"
        ).style.transform = `translateX(0px)`;
      } else {
        document.getElementById(
          "Testimonials"
        ).style.transform = `translateX(-${widthtomove + transformwidth}%)`;
      }
    }
  };

  return (
    <div className="Testimonials-container">
      <div
        style={{
          width: screenwidth < 890 ? screenwidth - 60 + "px" : null,
        }}
        className="arrows"
      >
        <i onClick={handlearrow} id="left" className="fas fa-arrow-left"></i>
        <i onClick={handlearrow} id="right" className="fas fa-arrow-right"></i>
      </div>
      <div
        style={{ width: screenwidth < 890 ? screenwidth - 50 + "px" : null }}
        className="alltestimonials"
      >
        <div id="Testimonials" className="Testimonials">
          {testimonials.map((testimonial, index) => {
            return (
              <div
                style={{
                  minWidth: screenwidth < 890 ? screenwidth - 50 + "px" : null,
                }}
                id="Testimonial"
                key={index}
                className="Testimonial"
              >
                <div className="leftside">
                  <div className="review">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {testimonial.Review}
                  </div>
                  <div className="author">
                    <p className="author-name">
                      {testimonial.Author.Name}, {testimonial.Author.Profession}
                    </p>
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i
                        style={{
                          display: testimonial.Rating === 4 ? "none" : null,
                        }}
                        className="fas fa-star"
                      ></i>
                    </div>
                  </div>
                </div>

                <Carousel
                  NotArrows={true}
                  imgwidth="100%"
                  widthtomove={100}
                  Images={testimonial.PropertyImages}
                  Auto={true}
                  Full={true}
                />
              </div>
            );
          })}
        </div>
      </div>
      <br />
    </div>
  );
}
