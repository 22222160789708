// Property Owner
export const REGISTER_USER = 'register_user';
export const LOGIN_USER = 'login_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const USER_PROFILE_UPDATE = "user_profile_update"
export const POST_PROPERTY_REQUIREMENTS = 'post_property_requirements'
export const OTHER_VIEW_PROFILE = 'other_view_profile'
export const PROPERTY_OWNER_POSTED_PROPERTY_REQUIREMENTS = 'property_owner_posted_property_requirements'
export const PO_PAYMENT_FAILED = 'po_payment_failed'
export const POST_PROPERTY_REQUIREMENTS_WITHOUT_AUTHORIZATION = 'post_property_requirements_without_authorization'

// Company (Service Professionals)
export const LOGIN_COMPANY = 'login_company';
export const REGISTER_COMPANY = 'register_company';
export const AUTH_COMPANY = 'auth_company';
export const LOGOUT_COMPANY = 'logout_company';
export const ADD_TO_CART_USER = 'add_to_cart_user';
export const GET_CART_ITEMS = 'get_cart_items';
export const REMOVE_CART_ITEM_USER = 'remove_cart_item_user';
export const ON_SUCCESS_BUY_USER = 'on_success_buy_user';
export const ON_SUCCESS_BUY_INDIVIDUAL_LEADS = 'on_success_buy_individual_leads';
export const ON_SUCCESS_BUY_COMMUNITY_MEMBERSHIP = 'on_success_buy_community_membership';
export const ON_SUCCESS_BUY_PRIME_MEMBERSHIP = 'on_success_buy_prime_membership';
export const ON_SERVICE_PROFESSIONAL_PROFILE_OTHERVIEW = 'on_service_professional_profile_otherview';

// VERIFY PHONE NUMBER
export const SP_PHONENUMBER_VERIFICATION = 'sp_phonenumber_verification';
export const SP_OTP_VERIFY_PHONENUMBER = 'sp_otp_verify_phonenumber';
export const PO_PHONENUMBER_VERIFICATION = 'po_phonenumber_verification';
export const PO_OTP_VERIFY_PHONENUMBER = 'po_otp_verify_phonenumber';


// SUBSCRIPTION (service Professionals)
export const ON_SUBSCRIPTION_PAYMENT_SUCCESS = 'on_subscription_payment_success';
export const ON_SUBSCRIPTION_PAYMENT_FAILED = 'on_subscription_payment_failed';
export const ON_PRIME_PAYMENT_FAILED = 'on_prime_payment_failed';

// PROPERTY DETAILS (For Service Professionals)
export const PROPERTY_BY_ID = 'propertybyID'
export const OWNER_PROPERTY_BY_ID = 'owner_property_by_ID'
export const GET_ALL_PROPERTY = 'get_all_property'
export const GET_ALL_PROPERTY_WITHOUT_AUTH = 'get_all_property_without_auth'

// PROJECT DETAILS (For Common View)
export const GET_ALL_PROJECT = 'get_all_project';
export const PROJECT_BY_ID = 'project_by_id';
export const UPLOAD_NEW_PROJECT = "upload_new_project";
export const DELETE_UPLOAD_NEW_PROJECT_IMAGE = "delete_upload_new_project_image";
export const DELETE_UPLOADED_PROJECT_IMAGEDETAILS = "delete_uploaded_project_imagedetails";
export const PREVIOUSLY_PROJECTUPLOADED = "previously_projectuploaded";
export const EDIT_PREVIOUSLY_PROJECTUPLOADED = "edit_previously_projectuploaded";
export const UPDATE_PREVIOUSLY_UPLOADED_PROJECT = "update_previously_uploaded_project";
export const DELETE_PROJECT_SP = "delete_project_sp";
export const GET_PROJECT_BY_IMAGE_ID = "get_project_by_image_id";

// PRODUCTS DETAILS BY PRODUCT ID
export const PRODUCT_BY_ID = 'product_by_id'
export const UPDATE_PROPERTY_DETAILS = 'update_property_details'
export const POST_PROPERTY_REQUIREMENTS_WITH_PAYMENT = 'post_property_requirements_with_payment'
export const RENEWAL_PROPERTY_REQUIREMENTS_WITH_PAYMENT = 'renewal_property_requirements_with_payment'
export const TOP_FIVE_PROPERTIES = 'top_five_properties'
export const FIND_NEIGHBOURHOOD_COUNT = 'find_neighbourhood_count'


// DOWNLOAD PDF PRODUCT
export const DOWNLOAD_PRODUCT_DETAILS_PDF = 'download_product_details_pdf'


// SERVICE PROFESSIONAL UPLOAD PROFILE DATA
export const UPLOAD_COVER_PROFILE_LOGO = 'upload_cover_profile_logo'
export const SP_UPLOAD_PROFILE_DETAILS = 'sp_upload_profile_details'
export const REMOVE_COVER_IMAGE = 'remove_cover_image'
export const REMOVE_PROFILE_IMAGE = 'remove_profile_image'
export const REMOVE_LOGO_IMAGE = 'remove_logo_image'
export const VIEW_ALL_SERVICE_PROFESSIONAL = 'view_all_service_professional'

// GALLERY PAGE
export const GALLERYPAGEVIEWS = 'gallerypageviews'

// USER ANALYTICS
export const USER_VISIT_COMPANY_PROFILE_ANALYTICS = 'user_visit_company_profile_analytics'
export const USER_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS = 'user_visit_company_social_media_analytics'
export const USER_GET_ALL_ANALYTICS = 'user_get_all_analytics'

// COMPANY ANALYTICS
export const COMPANY_VISIT_COMPANY_PROFILE_ANALYTICS = 'company_visit_company_profile_analytics'
export const COMPANY_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS = 'company_visit_company_social_media_analytics'
export const COMPANY_GET_ALL_ANALYTICS = 'company_get_all_analytics'
export const UPDATE_SELECTED_LEAD_STATUS = 'update_selected_lead_status'

//FORGOT PASSWORD
export const PROPERTY_OWNER_FORGOT_PASSWORD = 'property_owner_forgot_password'
export const PROPERTY_OWNER_RESET_PASSWORD = 'property_owner_reset_password'
export const SERVICE_PROFESSIONAL_FORGOT_PASSWORD = 'service_professional_forgot_password'
export const SERVICE_PROFESSIONAL_RESET_PASSWORD = 'service_professional_reset_password'

// REPLACEMENT LEAD
export const REPLACEMENT_LEAD = 'replacement_lead'

// GET ALL SERVICE PROFESSIONAL ADMIN DETAILS 
export const SERVICE_PROFESSIONAL_ADMIN_DETAILS = 'service_professional_admin_details'
export const UPDATE_SERVICE_PROFESSIONAL_REPLACEMENT = 'update_service_professional_replacement'
export const UNSUBSCRIBED_FOR_EMAIL_NOTIFICATION = 'unsubscribed_for_email_notification'