const options = [
  {
    title: "Build or Design Your Property ",
    link: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/Navigation/image-1.png",
    description: "Post your property requirements in a simplified & hassle-free manner, thereby giving a visibility boost to your property.",
    action: "Post Property Requirements",
  },
  {
    title: "Visit Our Gallery",
    link: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/Navigation/image-2.png",
    description: "View creatively designed interiors or gracefully structured buildings and choose the ones that you would love to take inspirations from. ",
    action: "View Gallery",
  },
  {
    title: "Search For Service Professionals",
    link: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/Navigation/image-3.png",
    description: "View the Business profiles of Interior Designers, Architects, Engineers or Contractors and contact them directly to save time & effort.",
    action: "Find Professionals",
  },
];

module.exports.Options = options;
