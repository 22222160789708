const testimonials = [
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-1.jpg",
    ],
    Review:
      "The NebourHood team is amazing, so we have engaged them for multiple projects. They are client centric, solution oriented and their pragmatic approach helped us bring our dream home to reality. If you want a team that will continuously exceed your expectations while suggesting you with amazing architect professionals, look no further and get in touch with NebourHood to get your dreams fulfilled.",
    Author: {
      Name: "Yashwardhan Gowda",
      Profession: "Property Owner, Residential Property, Bangalore",
    },
    Rating: 4.5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-8.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-7.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-2.jpg",
    ],
    Review:
      "I had recently started my interior design firm and was looking for a reliable client source. I tried to get exposure to my website through digital marketing and other social media platforms, but I wasn’t receiving the client traffic that I was expecting. I purchased some interior design leads from a few other sources, but it so turned out that they had sold the same lead list to many other design firms, due to which I faced intense competition and many client rejections. The clients from some of these sources refused to pay me for my designs and estimates, even after I had completed all the work that they had engaged me for. I was very frustrated because of this. Then one day, a colleague of mine suggested me to try NebourHood, as he was getting good conversions from their platform and showed me the details of how it worked. I decided to go ahead with the plan and spoke to some clients from NebourHood. Alas! I got good conversions and references because of them and till date, 90% of my business comes from NebourHood. As an entrepreneur, I would highly recommend that you try NebourHood for your interior design business.",
    Author: {
      Name: "Amyra Hussein",
      Profession: "Interior Designer, Commercial Interiors, Chennai",
    },
    Rating: 5,
  },

  {
    PropertyImages: ["https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-3.jpg"],
    Review:
      "NebourHood is a fantastic portal of architecture and construction Property Requirements. A lot of clients here are looking for high quality work with an artistic touch. Explaining the concepts to them is very easy, thanks to NebourHood.",
    Author: {
      Name: "Gursewak Singh",
      Profession: "Architect, Residential Architecture, Delhi",
    },
    Rating: 4,
  },

  {
    PropertyImages: ["https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-4.jpg"],
    Review:
      "NebourHood is a leader in the architecture industry and a role model to other architecture companies. Their knowledge, ideas, expertise and forward thinking as to how the property would be utilized in the future & the use of technology in design are admirable. We appreciate their collaboration to get us connected with impressive architects.",
    Author: {
      Name: "Mikaela",
      Profession: "Property Owner, Commercial Property, Faridabad",
    },
    Rating: 4.5,
  },

  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-5.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-9.jpg",
    ],
    Review:
      "Since NebourHood started, it has improved my business 10 x fold. Many of my clients post their requirements on NebourHood and I am easily able to filter & select the interior design Property Requirements, contact the clients and finalize the projects without any delay. The best part is that I usually find clients in a close proximity to my office, which makes it easier for me to meet them.",
    Author: {
      Name: "Kanishk",
      Profession: "Interior Designer, Residential Interiors, Ghaziabad",
    },
    Rating: 5,
  },

  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-10.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-11.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-6.jpg",
    ],
    Review:
      "A lot of customers directly visit our website due to NebourHood and call us. We have executed many projects as a result of this and have saved a lot of marketing expenditure. The ranges of the Property Requirements are fantastic, they help us generate a generous amount of revenue every month. Kudos to the NebourHood team, you guys are fab!",

    Author: {
      Name: "Trushti",
      Profession: "Civil Engineer, Commercial Construction, Greater Noida",
    },
    Rating: 4,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-14.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-15.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-12.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-13.jpg",
    ],
    Review:
      "For the complete renovation of our 3rd floor - starting from the guest room, office, master bedroom, walk-in closet and bathroom, NebourHood suggested us with exceptional interior designers. It was easy to relate to our ideas about the space and make smart decisions because of the options presented. Their experience and knowledge of the project helped make the process go smoothly. Thank you NebourHood, we enjoy our renovated space every day.",

    Author: {
      Name: "Kaizaad",
      Profession: "Property Owner, Residential Property, Gurgaon",
    },
    Rating: 4.5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-20.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-16.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-17.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-18.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-19.jpg",
    ],
    Review:
      "Since I have started using NebourHood, I have nothing to say but thanks to the outstanding team working so smartly to help small entrepreneurs like me to get the exact and actual requirements of the property owner, without the involvement of any third parties for commissions. I never imagined that I would close deals for such projects that put me in direct competition with big and reputed names of the interior designing industry. The clients give a positive feedback to my designs and appoint me happily, because NebourHood helps me to establish a pleasant bond with them.",

    Author: {
      Name: "Simran Desai",
      Profession: "Interior Designer, Commercial Interiors, Meerut",
    },
    Rating: 5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-22.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-21.jpg",
    ],
    Review:
      "I’m a freelance architect. It used to take me at least 2 months to select and finalize the deal with a client. The travelling was taking a toll on my health. But now, with NebourHood, I can easily select the clients and finalize the projects quickly - all of this while working from the comfort zone of my home. And having so many features to choose from, for a freelancer like me, is a real advantage! Cheers NebourHood!",

    Author: {
      Name: "Dulquer Rayhaan",
      Profession: "Architect, Residential Architecture, Noida",
    },
    Rating: 4,
  },
  {
    PropertyImages: ["https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-23.jpg"],
    Review:
      "NebourHood, you have gone the extra mile during the construction of my urban office. The little things, such as getting back to me while I was on vacation and notifying me about the architects, demonstrates your patience, passion and the care that you have for your clients. You and your team are like precious stones which are rare and hard to find. I consider myself very privileged to know you and this is a small token to let you know how much you are appreciated.",
    Author: {
      Name: "Meher Kaur",
      Profession: "Property Owner, Commercial Property, Panipat",
    },
    Rating: 4.5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-24.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-25.jpg",
    ],
    Review:
      "NebourHood is a very valuable company to both – property owners and interior designers. Their network circle comprises of interior designers, architects, engineers, contractors, etc. and I have become an integral part of it. This has brought me much closer to achieving & accomplishing the targets & goals that I set for myself and my team every month. A lot of professionals recommend our work to other clients, even though they are my competitors. NebourHood has brought us all together as one big family and showed us that we can all grow together by helping and supporting one another. Thank you for the love and support NebourHood.",
    Author: {
      Name: "Tony D’Costa",
      Profession: "Interior Designer, Residential Interiors, Sonipat",
    },
    Rating: 5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-28.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-26.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-27.jpg",
    ],
    Review:
      "The quality of construction leads on NebourHood is outstanding. Many of the architecture leads that I received till date were high – profile. I respect the fact that NebourHood as a company delivers what it promises, as I get very few competitors while connecting with clients on NebourHood. Their business analytics feature for professionals is fantastic, it helps me monitor the productivity of my firm and also helps me keep a track of additional clients. They have made my workflow so smooth that now I don’t have to worry about finding clients again. They have displayed all the tailored information about the basic and major aspects of the property on their platform and it shows how strongly they value their work ethics. I see NebourHood having an amazing future ahead and would love to continue to be a part of their community. Superb job, keep it up team NebourHood!",
    Author: {
      Name: "Trigya",
      Profession: "Civil Engineer, Commercial Construction, Hyderabad",
    },
    Rating: 5,
  },
  {
    PropertyImages: ["https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-29.jpg"],
    Review:
      "Awesome choice – NebourHood. A fantastic portal to get your property construction done. Their team follows a very professional and friendly work behavior. They are quick to address all your queries and concerns. It’s remarkable as to how well they coordinate and handle the doubts. The amazing part about finalizing the architect firm on NebourHood was that from the initial phase to execution and handover, everything went flawlessly. I would recommend NebourHood to anyone looking for experienced and professional architects. They are doing great and I wish them all the best for their future endeavors. Thank you so much NebourHood for creating and building my beautiful dream home.",
    Author: {
      Name: "Devesh",
      Profession: "Property Owner, Residential Property, Kolkata",
    },
    Rating: 4.5,
  },
  {
    PropertyImages: [
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-31.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-32.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-33.jpg",
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-30.jpg",
    ],
    Review:
      "I have received 17 projects in a span of 5 months. And all the credit for this goes to NebourHood. One of the projects is a sea – facing 6000 sqft beach resort, with a breath – taking scenery and this project literally has nature on it’s side to glorify and enhance its beauty! I am pretty sure that I would never have closed a project like this on my own. Their plans are very pocket friendly and I feel lucky to have them by my side. I can’t thank you enough for the fabulous work that you’re doing NebourHood. Cheers!",
    Author: {
      Name: "Perizaad",
      Profession: "Interior Designer, Commercial Interiors, Mumbai",
    },
    Rating: 5,
  },
  {
    PropertyImages: ["https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Testimonials/image-34.jpg"],
    Review:
      "We initiate a minimum of 5 projects every month. Initially, it was hard to find clients who would understand the concept of how the construction industry works. With the growing era of technology and so much information on our fingertips, the clients have become much more knowledgeable about the working process of our industry. And for a smart clientele, we need to keep up with the changing dynamics. NebourHood does exactly that. By using technological advancements, they have made the cumbersome job of finding clients feel so effortless. Today, most of the Leads that I buy from NebourHood helps me finalize them in a short while. The clients go through my company profile and projects on NebourHood, even before I contact them. This way, they already know about what my company does, hence communicating with them becomes much simpler for me. Thank you NebourHood, you have bridged the gap between professionals and clients in a very smart and effective way.",
    Author: {
      Name: "Bharat",
      Profession: " Architect, Residential Architecture, Pune",
    },
    Rating: 5,
  },
  
];

module.exports.testimonials = testimonials;
