module.exports.table = {
  titles: [
    {
      Name: "SL NO.",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Invoice Payment ID",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Purchase / Selection / Replacement Order ID",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Date & Time Of Payment / Selection / Replacement Selection",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Property Owner",
      colspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Property Requirement",
      colspan: 2,
      colour: "#24292E"
      
    },
    // {
    //   Name: "State",
    //   rowspan: 2,
    // },
    {
      Name: "City",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Category",
      rowspan: 2,
      colour: "#24292E"
    },
    // {
    //   Name: "Quantity (Limited to 1 per user per product)",
    //   rowspan: 2,
    // },
    {
      Name: "Original Amount (Exclusive of G.S.T.)",
      rowspan: 2,
      colour: "red"
    },
    {
      Name: "Discounted Amount (Exclusive of G.S.T.)",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "G.S.T. Amount (18%)",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Discounted Amount (Inclusive of G.S.T.)",
      rowspan: 2,
      colour: "#1F80E0"
    },
    {
      Name: "NQA Double Replacement Lead Savings",
      rowspan: 2,
      colour: "green"
    },
    {
      Name: "Non-Prime Member Savings",
      rowspan: 2,
      colour: "green"
    },
    {
      Name: "NebourHood Prime S.P. Savings",
      rowspan: 2,
      colour: "green"
    },
    {
      Name: "Total Savings",
      rowspan: 2,
      colour: "green"
    },
    // {
    //   Name: "Payment Status",
    //   rowspan: 2,
    // },
    {
      Name: "Replacement Request",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Date & Time Of Replacement Initiation",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Date & Time Of Replacement Approval",
      rowspan: 2,
      colour: "#24292E"
    },
    {
      Name: "Download",
      rowspan: 2,
      colour: "#24292E"
    },
  ],
  subtitles: [
    "Profile Number",
    "Name",
    "Property Number",
    "Property Name",
  ],
};

module.exports.transactiondata = [
  {
      PurchaseOrderId : 1233412312314,
      DateTimeofPayment : "03/03/2022 1:15 PM",
      SubscriptionPlanDetails : {
          PlanName : "Premium Plan",
          PlanValidity : "1 Year",
          AmountPerMonth : "$ 99",
          TotalAmount : "$ 1200",
          PlanExpires : "04/03/2023",
          PlanStatus : "Active",
          PaymentMode : "NetBanking",
          DownloadInvoice : ""
      }
  },
  {
      PurchaseOrderId : 46455234124,
      DateTimeofPayment : "08/01/2022 1:15 PM",
      SubscriptionPlanDetails : {
          PlanName : "Premium Plan",
          PlanValidity : "1 Year",
          AmountPerMonth : "$ 99",
          TotalAmount : "$ 1200",
          PlanExpires : "08/01/2022",
          PlanStatus : "Active",
          PaymentMode : "NetBanking",
          DownloadInvoice : ""
      }
  },
  {
      PurchaseOrderId : 1233412312314,
      DateTimeofPayment : "15/02/2022 1:15 PM",
      SubscriptionPlanDetails : {
          PlanName : "Premium Plan",
          PlanValidity : "1 Year",
          AmountPerMonth : "$ 99",
          TotalAmount : "$ 1200",
          PlanExpires : "15/02/2023",
          PlanStatus : "Active",
          PaymentMode : "NetBanking",
          DownloadInvoice : ""
      }
  },
  {
      PurchaseOrderId : 1233412312314,
      DateTimeofPayment : "03/03/2022 1:15 PM",
      SubscriptionPlanDetails : {
          PlanName : "Starter Plan",
          PlanValidity : "1 Year",
          AmountPerMonth : "$ 99",
          TotalAmount : "$ 1200",
          PlanExpires : "04/03/2022",
          PlanStatus : "Active",
          PaymentMode : "NetBanking",
          DownloadInvoice : ""
      }
  },
  {
      PurchaseOrderId : 456345233,
      DateTimeofPayment : "03/03/2022 1:15 PM",
      SubscriptionPlanDetails : {
          PlanName : "Advanced Plan",
          PlanValidity : "1 Year",
          AmountPerMonth : "$ 99",
          TotalAmount : "$ 1200",
          PlanExpires : "04/03/2022",
          PlanStatus : "Active",
          PaymentMode : "NetBanking",
          DownloadInvoice : ""
      }
  },
]