import React from "react";
import { withRouter } from "react-router-dom";
import ProfileImage from "./PO_ProfileImage/PO_ProfileImage";
import "./PO_Cover_Profile_Pic.css";

function PO_Cover_Profile_Pic(props) {

  return (
    <div className="profile-img-and-company-logo-container">
      <div className="profile-img-and-company-logo">
        <ProfileImage nochange={props.nochange} userGender={props.userGender} />
        <div className="sp-details">
          <h1 className="company-name">
              {props.userName}
          </h1>
          <div className="architect-name-and-profession">
            <p className="architect-name">
            Property Owner
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PO_Cover_Profile_Pic);
