import React, { useEffect, useLayoutEffect, useState } from "react";
import { propertydetails } from "./Data";
import { useDispatch } from "react-redux";
import "./UploadedPropertyDetails.css";
// import { suggestions } from "./Data";
import { withRouter } from "react-router-dom";
import ContactLimit from "../../../../../../asserts/OtherImages/ContactLimit.png";
import ValidityExpire from "../../../../../../asserts/OtherImages/Validity_Expired.svg";
import { Owner_propertydetailsbyID } from "../../../../../../../_actions/property_actions";
import PageBanner from "../../../../../../utils/PageBanner/PageBanner";

function UploadedPropertyDetails(props) {
  const dispatch = useDispatch();
  // const propertyid = props.id;

  const currentproperty = propertydetails[0];

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [dotelement, setdotelement] = useState("dots");

  const [Product, setProduct] = useState([]);
  // const [TopProperties, setTopProperties] = useState([]);
  const productId = props.match.params.ProjectId;
  // const [AddToCartButtonDisable, setAddToCartButtonDisable] = useState(true);
  // const [TopResidentialProperties, setTopResidentialProperties] = useState([]);

  useEffect(() => {
    dispatch(Owner_propertydetailsbyID(productId)).then((response) => {
      // console.log("Response ", response);
      setProduct(response.payload);
    });
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    // setTimeout(() => {
    //   if (screenwidth < 1290) {
    //     setdotelement("bottom-dots");
    //   } else {
    //     setdotelement("dots");
    //   }
    //   const dots = document.getElementById(`${dotelement}`).childNodes;
    //   dots[0].style.opacity = 1;
    //   dots[0].style.transform = "scale(1.3)";
    //   for (let i = 0; i < dots.length; i++) {
    //     dots[i].addEventListener("click", (e) => {
    //       for (let i = 0; i < dots.length; i++) {
    //         dots[i].style.opacity = 0.5;
    //         dots[i].style.transform = "scale(1)";
    //       }
    //       e.currentTarget.style.opacity = 1;
    //       e.currentTarget.style.transform = "scale(1.3)";
    //       if (screenwidth < 1290 && screenwidth > 1080) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-350 * i}px)`;
    //       }
    //       if (screenwidth < 1080 && screenwidth > 800) {
    //         // console.log(screenwidth);
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 110) * i}px)`;
    //       }
    //       if (screenwidth < 800) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 35) * i}px)`;
    //       }
    //       if (screenwidth > 1290) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-450 * i}px)`;
    //       }
    //     });
    //   }
    // }, 10);
  }, [screenwidth, dotelement]);

  const handlearrow = (e) => {
    const currentarrow = e.currentTarget.className;
    const transformwidth = parseInt(
      document.getElementById("Gallery").style.transform.replace(/[^\d.]/g, "")
    );

    var widthtomove = 703;
    var imageslength = currentproperty.Images.length - 2;
    if (screenwidth >= 1450) {
      widthtomove = 703;
    }
    if (screenwidth < 1450) {
      widthtomove = 610;
    }
    if (screenwidth < 1300) {
      widthtomove = 510;
    }
    if (screenwidth < 1090) {
      widthtomove = 510;
    }
    if (screenwidth < 850) {
      widthtomove = screenwidth - 10;
      imageslength = currentproperty.Images.length - 1;
    }

    if (currentarrow === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth <= 1) {
        document.getElementById("Gallery").style.transform = `translateX(-${
          widthtomove * imageslength
        }px)`;
      } else {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${Math.abs(
          transformwidth - widthtomove
        )}px)`;
      }
    }

    if (currentarrow === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${widthtomove}px)`;
      }
      if (transformwidth >= widthtomove * imageslength) {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${0}px)`;
      } else {
        document.getElementById("Gallery").style.transform = `translateX(-${
          transformwidth + widthtomove
        }px)`;
      }
    }
  };

  return (
    <div className="property-req-container">
      <PageBanner
        title="Property Requirement Details"
      />
      <div className="property-details">
        <div
          style={{
            width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            top: screenwidth < 850 ? (screenwidth - 30) / 3 + "px" : null,
          }}
          id="arrows"
          className="arrows"
        >
          <i id="left" onClick={handlearrow} className="fas fa-arrow-left"></i>
          <i
            id="right"
            onClick={handlearrow}
            className="fas fa-arrow-right"
          ></i>
        </div>
        <div
          style={{
            width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
          }}
          className="property-details-gallery-container"
        >
          <div id="Gallery" className="gallery">
            {currentproperty.Images.map((image, index) => {
              return (
                <img
                  style={{
                    width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
                  }}
                  id="property-details-img"
                  key={index}
                  src={image}
                  alt="property"
                />
              );
            })}
           
          </div>
          {/* <img
            id="sold-img"
              className="sold-out-img"
              src={ValidityExpire}
              alt="sold-out-img"
            /> */}
            {Product.sold > 0 ?
            <>
              {Product.Validity_Till <= 0 ? 
                <img
                    id="sold-imgg"
                    className="sold-out-img"
                    src={ValidityExpire}
                    alt="sold-out-img"
                  />      
                  :
                null
              }
            </>
            : 
            <>
              {Product.sold <= 0 ?
                <img
                    id="sold-imgg"
                    className="sold-out-img"
                    src={ContactLimit}
                    alt="sold-out-img"
                  />
                :
                null
              }
            </>
          }
          <br />
        </div>
        <div className="Details">
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="property"
          >
            <div className="about">
              <div className="tagsandprice">
                <div className="tags">
                  <p className="featured">{Product.Project_Type}</p>
                  <p className="rent">{Product.BuildingType}</p>
                </div>
                <div className="price-container">
                  <p className="price">Posted on: {Product.Posted_Date}</p>
                </div>
              </div>
              <h2 className="address">
                Address: &nbsp;&nbsp;
                <span className="titleid">
                  {Product.Land_Building}, {Product.State}, {Product.City},{" "}
                  {Product.Area_Name}
                </span>
              </h2>
              <h2 className="title">
                Property Owner Name: &nbsp;&nbsp;
                <span className="titleid">
                  {Product.Name_Of_property_Owner}
                </span>
              </h2>
              <h2 className="title">
                Property Requirement No.: &nbsp;&nbsp;
                <span className="titleid">{Product._id}</span>
              </h2>
              <div className="typeandsize">
                <p className="type">{Product.Area_Type}</p>
                <p className="size">({Product.Project_Area} sq.ft.)</p>
              </div>
              <div className="otherdetailsandrating">
                <div className="other-details">
                <p>
                    <b>Service Requirement:</b> <br />
                    {Product.Service_Type_Required}
                  </p>
                  <br />
                  <p>
                    <b>Time Frame:</b> <br />
                    {Product.Beginning_The_Project}
                  </p>
                  <br />
                  <p>
                    <b>Property Condition:</b> <br />
                    {Product.Property_Condition}
                  </p>
                  {/* <p>{Product.Service_Type_Required}</p>
                  <p>{Product.Beginning_The_Project}</p>
                  <p>{Product.Property_Condition}</p> */}
                </div>
                {/* <div className="rating">
                  <p>Verified</p>
                </div> */}
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>User Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Property Owner Profile Number :</p>
                  <p className="input">&nbsp;&nbsp;{Product.writer}</p>
                </div>
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>Personal Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Property Owner Name :</p>
                  <p className="input">
                    &nbsp;&nbsp;{Product.Name_Of_property_Owner}
                  </p>
                </div>
              </div>
            </div>
            <br />

            <br />
            <div className="address-container">
              <h2>Address</h2>
              <p className="title">
                Details of the property where the work must be completed :
              </p>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">
                      House Name / Building Name / Plot Name / Land Name
                      (Property Name):
                    </li>
                    <li>{Product.Land_Building}</li>
                    <li className="head">State:</li>
                    <li>{Product.State}</li>
                    <li className="head">City:</li>
                    <li>{Product.City}</li>
                    <li className="head">Area Name:</li>
                    <li>{Product.Area_Name}</li>
                    <li className="head">Pin Code:</li>

                    <li>{Product.Pin_Code}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Service Type Required </h2>
              <p className="title">
                Service Type, Property Type and Property Status:{" "}
              </p>
              <div className="features">
              <p className="item">{Product.Project_Type}</p>
              <p className="item">{Product.BuildingType}</p>
              <p className="item">{Product.Area_Type}</p>
                {/* {currentproperty.Features.map((feature, index) => {
                  return (
                    <p key={index} className="item">
                      {feature}
                    </p>
                  );
                })} */}
              </div>
              <br />
              <p className="title">
              Service Requirement:{" "}
              </p>
              <div className="features">
              <p className="item">
                 {Product.Service_Type_Required}
              </p>
                {/* {currentproperty.Features.map((feature, index) => {
                  return (
                    <p key={index} className="item">
                      {feature}
                    </p>
                  );
                })} */}
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Time Frame</h2>
              <p className="title">
                The property requirement work has to be started :{" "}
              </p>
              <div className="features">
                <p className="item">{Product.Beginning_The_Project}</p>
              </div>
            </div>
            <br />
            <div className="address-container">
              <h2>Property Information</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Property Requirement Number:</li>
                    <li>{Product._id}</li>
                    <li className="head">
                      Property Dimension Type:
                    </li>
                    <li>{Product.Dimension_Type}</li>
                    <li className="head">Property Area (sq. ft.):</li>
                    <li>{Product.Project_Area} sq. ft.</li>
                    <li className="head">Property Condition:</li>
                    <li>{Product.Property_Condition}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="address-container">
              <h2>Property Requirements</h2>
              <br />
              <p>{Product.description}</p>
            </div>
            <br />
            <div className="address-container">
              <h2>Declaration Agreed</h2>
              <br />
              <p>
                The Property Owner has agreed to this&nbsp;
                <a href="/PropertyOwner/Declaration/PropertyOwner" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                Declaration.
              </a>
              </p>
            </div>
          </div>
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="contact-or-share"
          >
          <br />
            <div className="shareanddownload">
              <div className="add-to-cart">
              {Product.sold > 0 && Product.Validity_Till > 0 ? 
                <button onClick={() => props.history.push(`/PropertyOwner/PostedPropertyDetails/Edit/${Product._id}`)} className="add-to-cart-btn">
                    Edit Property Requirement
                </button>
                :
                null
              }
              </div>
            </div>
            <br />
            <div className="shareanddownload">
              <div className="add-to-cart">
                <button onClick={() => props.history.push(`/PropertyOwner/PostedPropertyDetails/Renew/${Product._id}`)} className="add-to-cart-btn">
                    Renew Property Requirement
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UploadedPropertyDetails);
