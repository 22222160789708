import React from "react";
import "./SuccessPage.css";
import successimage from "./Success.svg";

function SuccessPage(props) {

  return (
    <div className="success-page-container">
      <img src={successimage} alt="success" />
      <h1 className="payment-success">{props.PurchaseDetails}</h1>
      {props.Redirect ? 
        <p>Redirecting to Transaction Page ...</p>
      : null}
    </div>
  );
}

export default SuccessPage;
