import React from "react";
import { useSelector } from "react-redux";
import "./Video.css";


function Video(props) {

  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  if(company.companyData && company.companyData.isAuth) {
    return (
      <div className="video-container">
      <div className="video">
        <p className="video-heading">Intro – For Service Professionals</p>
        <iframe
          src="https://www.youtube.com/embed/SL5yrVzHS54"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        ;
      </div>
    </div>
    )
  } else if(user.userData && user.userData.isAuth) {
    return (
      <div className="video-container">
      <div className="video">
        <p className="video-heading">Intro – For Property Owners</p>

        <iframe
          src="https://www.youtube.com/embed/jhdFI2QjlrM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    )
  } else {
    return (
      <div className="video-container">
      <div className="video">
        <p className="video-heading">Intro – For Property Owners</p>

        <iframe
          src="https://www.youtube.com/embed/jhdFI2QjlrM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video">
        <p className="video-heading">Intro – For Service Professionals</p>
        <iframe
          src="https://www.youtube.com/embed/SL5yrVzHS54"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        ;
      </div>
    </div>
    )
  }

  // return props.VideoFor === "POSP" ? (
  //   <div className="video-container">
  //     <div className="video">
  //       <p className="video-heading">Property Owner</p>

  //       <iframe
  //         src="https://www.youtube.com/embed/wlJLMY2FYuQ"
  //         title="YouTube video player"
  //         frameborder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //         allowfullscreen
  //       ></iframe>
  //     </div>
  //     <div className="video">
  //       <p className="video-heading">Service Professional</p>
  //       <iframe
  //         src="https://www.youtube.com/embed/_daTfgc4u3k"
  //         title="YouTube video player"
  //         frameborder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //         allowfullscreen
  //       ></iframe>
  //       ;
  //     </div>
  //   </div>
  // ) : props.VideoFor === "PO" ? (
  //   <div className="video-container">
  //     <div style={{
  //               width:props.containerwidth ? props.containerwidth : null

  //     }} className="video">
  //       <p className="video-heading">Property Owner</p>

  //       <iframe
  //         style={{
  //           minWidth: props.width ? props.width : null,
  //           height: props.width ? props.height : null,
  //         }}
  //         src="https://www.youtube.com/embed/wlJLMY2FYuQ"
  //         title="YouTube video player"
  //         frameborder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //         allowfullscreen
  //       ></iframe>
  //     </div>
  //   </div>
  // ) : props.VideoFor === "SP" ? (
  //   <div className="video-container">
  //     <div style={{
  //       width:props.containerwidth ? props.containerwidth : null
  //     }} className="video">
  //       <p className="video-heading">Service Professional</p>
  //       <iframe
  //         style={{
  //           minWidth: props.width ? props.width : null,
  //           height: props.height ? props.height : null,
  //         }}
  //         src="https://www.youtube.com/embed/_daTfgc4u3k"
  //         title="YouTube video player"
  //         frameborder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //         allowfullscreen
  //       ></iframe>
  //       ;
  //     </div>
  //   </div>
  // ) : null;
}

export default Video;