import {
    LOGIN_COMPANY,
    REGISTER_COMPANY,
    AUTH_COMPANY,
    LOGOUT_COMPANY,
    ADD_TO_CART_USER,
    GET_CART_ITEMS, 
    // ADD_TO_CART_USER,
    REMOVE_CART_ITEM_USER,
    REPLACEMENT_LEAD,
} from '../_actions/types';
 

export default function(state={},action){
    switch(action.type){ 
        case REGISTER_COMPANY:
            return {...state, register: action.payload }
        case LOGIN_COMPANY:
            return { ...state, loginSucces: action.payload }
        case AUTH_COMPANY:
            return {...state, companyData: action.payload }
        case LOGOUT_COMPANY:
            return {...state }
        case REPLACEMENT_LEAD: 
        return {...state, companyData: {
            ...state.companyData,
            history: action.payload
        }}
        case ADD_TO_CART_USER:
            return {
                ...state, companyData: {
                ...state.companyData,
                cart: action.payload
                }
                }
        case GET_CART_ITEMS:
            return {
                ...state, cartDetail: action.payload
            }
            case REMOVE_CART_ITEM_USER:
                return {
                    ...state,
                    cartDetail: action.payload.cartDetail,
                    companyData: {
                        ...state.companyData,
                        cart: action.payload.cart
                    }
    
                }
    
            
        default:
            return state;
    }
}