const achievements = [
    {
        Name : "Property Owners Joined",
        Numbers : "10,245+"
    },
    {
        Name : "Property Requirements Posted",
        Numbers : "24,245+"
    },
    {
        Name : "Service Professionals Joined",
        Numbers : "13,245+"
    },
    {
        Name : "Projects Uploaded ",
        Numbers : "90,245+"
    },
]

module.exports.achievements = achievements;