import React, { useLayoutEffect, useState } from "react";
import { testimonials } from "./Data";
import "./Howwework.css";

export default function Howwework() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  const handlearrow = (e) => {
    const Testimonials =
      e.currentTarget.parentElement.parentElement.childNodes[1].childNodes[0]
        .childNodes;

    const clickedarrow = e.currentTarget.className;
    const transformwidth = parseInt(
      document
        .getElementById("Testimonials")
        .style.transform.replace(/[^\d.]/g, "")
    );
    var widthtomove = 100;
    var lengthtomove = Testimonials.length - 1;

    setTimeout(() => {
      setTimeout(() => {
        if (clickedarrow === "fas fa-arrow-left") {
          if (transformwidth.toString() === "NaN" || transformwidth === 0) {
            document.getElementById(
              "Testimonials"
            ).style.transform = `translateX(-${widthtomove * lengthtomove}%)`;
          } else {
            document.getElementById(
              "Testimonials"
            ).style.transform = `translateX(-${transformwidth - widthtomove}%)`;
          }
        }
        if (clickedarrow === "fas fa-arrow-right") {
          if (transformwidth.toString() === "NaN" || transformwidth === 0) {
            document.getElementById(
              "Testimonials"
            ).style.transform = `translateX(-${100}%)`;
          }
          if (transformwidth >= widthtomove * lengthtomove) {
            document.getElementById(
              "Testimonials"
            ).style.transform = `translateX(0%)`;
          } else {
            document.getElementById(
              "Testimonials"
            ).style.transform = `translateX(-${widthtomove + transformwidth}%)`;
          }
        }
      }, 1500);
      for (let i = 0; i < Testimonials.length; i++) {
        setTimeout(() => {
          Testimonials[i].childNodes[0].childNodes[0].style.top = "50px";
          Testimonials[i].childNodes[0].childNodes[1].style.top = "50px";
          Testimonials[i].childNodes[1].childNodes[0].style.left = "200px";
          Testimonials[i].childNodes[1].childNodes[0].style.transition = "1.5s";
        }, 500);
        Testimonials[i].childNodes[0].childNodes[0].style.opacity = 0;
        Testimonials[i].childNodes[0].childNodes[1].style.opacity = 0;
        Testimonials[i].childNodes[1].childNodes[0].style.opacity = 0;
        setTimeout(() => {
          Testimonials[i].childNodes[0].childNodes[0].style.opacity = 1;
          Testimonials[i].childNodes[0].childNodes[0].style.top = 0;
        }, 1500);
        setTimeout(() => {
          Testimonials[i].childNodes[0].childNodes[1].style.top = 0;
          Testimonials[i].childNodes[0].childNodes[1].style.opacity = 1;
        }, 1500);

        setTimeout(() => {
          Testimonials[i].childNodes[1].childNodes[0].style.left = 0;
          Testimonials[i].childNodes[1].childNodes[0].style.opacity = 1;
        }, 1700);
        // console.log(Testimonials[i]);
      }
    }, 10);
  };

  return (
    <>
    <div style={{ textAlign: 'center', marginTop: '70px'}}>
      <p style={{ fontSize: '35px', margin: '0' }}>
        <b>How We Work</b>
      </p>
    </div>
    <div className="howwework-container">
      <div
        // style={{
        //   width: screenwidth < 890 ? screenwidth - 80 + "px" : null,
        //   top: screenwidth < 890 ? screenwidth / 2 + "px" : null,
        // }}
        className="arrows"
      >
        <i onClick={handlearrow} id="left" className="fas fa-arrow-left"></i>
        <i onClick={handlearrow} id="right" className="fas fa-arrow-right"></i>
      </div>
      <div
        // style={{ width: screenwidth < 890 ? screenwidth - 80 + "px" : null }}
        className="alltestimonials"
      >
        <div id="Testimonials" className="Testimonials">
          {testimonials.map((testimonial, index) => {
            return (
              <div
                // style={{
                //   minWidth: screenwidth < 890 ? screenwidth - 80 + "px" : null,
                // }}
                id="Testimonial"
                key={index}
                className="Testimonial"
              >
                <div className="leftside">
                  <p className="heading">
                    <b>{testimonial.heading}</b>
                  </p>
                  <div className="reviews">
                    {/* <ul>
                    {testimonial.Reviews.map((review, index) => {
                      return (
                        <li key={index} className="review">
                          {review}
                        </li>
                      );
                    })}
                    
                    </ul> */}
                    {testimonial.Reviews.map((review, index) => {
                      return (
                        <p key={index} className="review">
                          - {review}
                        </p>
                      );
                    })}
                  </div>

                  {/* <div className="author">
                    <img
                      src={testimonial.Author.Image}
                      alt={testimonial.Author.Name}
                    />
                    <div className="about-author">
                      <p className="author-name">{testimonial.Author.Name}</p>
                      <p className="profession">
                        {testimonial.Author.Profession}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="rightside">
                  <img
                    // style={{
                    //   width:
                    //     screenwidth < 890 ? screenwidth - 150 + "px" : null,
                    //   height:
                    //     screenwidth < 890 ? screenwidth - 150 + "px" : null,
                    // }}
                    src={testimonial.PropertyImage}
                    alt="Property"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <br />
    </div>
    </>
  );
}
