import React, { useLayoutEffect, useState } from "react";
import PageBanner from "../../utils/PageBanner/PageBanner";
import { resourcesData } from "./Data";
import "./Freeresources.css";

export default function Facilities() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  return (
    <div>
    <PageBanner title="Free Resources" />
      <div className="facilities-container">
        <p className="title">
          <b>Free Resources</b>
        </p>
        {/* <p
          style={{ width: screenwidth <= 820 ? screenwidth - 50 + "px" : null }}
          className="description"
        >
          Descriptions
        </p> */}
        <div
          style={{ width: screenwidth <= 820 ? screenwidth - 40 + "px" : null }}
          className="facilites"
        >
          {resourcesData.map((facility, index) => {
            return (
              <a href={facility.URL} target="_blank" style={{ textDecoration: 'none', color: 'black' }} >
                <div
                  style={{
                    width: screenwidth <= 820 ? screenwidth - 50 + "px" : null,
                  }}
                  key={index}
                  className="facility"
                >
                  <div className="img">
                    <img src={facility.Image} alt={facility.Facility} />
                  </div>
                  <p className="facility-name">
                    <b>{facility.Facility}</b>
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
