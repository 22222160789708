import React, { useState } from 'react';
import './SearchBox.css'; // Import the CSS file

const SearchBox = (props) => {
    function handleChange(e) {
            props.getSearchText(e.target.value.toLowerCase());
    }
  return (
    <div className="search-container">
      <input type="text" className="search-input" placeholder="Search by Company . . . ." onChange={handleChange} />
      {/* <button className="search-button">
        <span role="img" aria-label="search">&#128269;</span> Search icon
      </button> */}
    </div>
  );
};

export default SearchBox;