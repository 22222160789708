import axios from "axios";
import { PERFORMANCE_ANALYTICS } from "../Config";
import { COMPANY_GET_ALL_ANALYTICS, COMPANY_VISIT_COMPANY_PROFILE_ANALYTICS,
         COMPANY_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS, 
         OTHER_VIEW_PROFILE, 
         UPDATE_SELECTED_LEAD_STATUS, 
         USER_VISIT_COMPANY_PROFILE_ANALYTICS, 
         USER_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS 
        } from "./types";

export function PO_VisitsCompanyProfileAnalytics(dataToSubmit) {
    // console.log("Analytics Action Data TO Submit", dataToSubmit);
    const request = axios.post(`${PERFORMANCE_ANALYTICS}/userVisitCompanyProfile`, dataToSubmit)
                .then(response => response.data);

    return {
        type: USER_VISIT_COMPANY_PROFILE_ANALYTICS,
        payload: request
    }    
}

export function SP_VisitsCompanyProfileAnalytics(dataToSubmit) {
    // console.log("Analytics Action Data TO Submit", dataToSubmit);
    const request = axios.post(`${PERFORMANCE_ANALYTICS}/companyVisitCompanyProfile`, dataToSubmit)
                .then(response => response.data);

    return {
        type: COMPANY_VISIT_COMPANY_PROFILE_ANALYTICS,
        payload: request
    }    
}

export function PO_VisitsCompanySocialMediaAnalytics(dataToSubmit) {
    // console.log("Analytics Action Data TO Submit", dataToSubmit);
    const request = axios.post(`${PERFORMANCE_ANALYTICS}/userVisitCompanySocialMedia`, dataToSubmit)
                .then(response => response.data);

    return {
        type: USER_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS,
        payload: request
    }    
}

export function SP_VisitsCompanySocialMediaAnalytics(dataToSubmit) {
    // console.log("Analytics Action Data TO Submit", dataToSubmit);
    const request = axios.post(`${PERFORMANCE_ANALYTICS}/companyVisitCompanySocialMedia`, dataToSubmit)
                .then(response => response.data);

    return {
        type: COMPANY_VISIT_COMPANY_SOCIAL_MEDIA_ANALYTICS,
        payload: request
    }    
}

export function SP_GetAllAnalyticsData() {
    // console.log("Analytics Get All AnalyticsAction Data");
    const request = axios.get(`${PERFORMANCE_ANALYTICS}/getCompanyAnalytics`)
                .then(response => response.data);

    return {
        type: COMPANY_GET_ALL_ANALYTICS,
        payload: request
    }    
}

export function SP_UpdateSelectedLeadStatus(dataToSubmit) {
    // console.log("Update Selected Lead Status Company Analytic Action ", dataToSubmit);
    const request = axios.post(`${PERFORMANCE_ANALYTICS}/updateSelectedLeadStatus`, dataToSubmit)
                .then(response => response.data);

    return {
        type: UPDATE_SELECTED_LEAD_STATUS,
        payload: request
    }    
}

export function Other_View_Profile(_id) {
    // console.log("User Profile ID", _id);
    const request = axios.get(`${PERFORMANCE_ANALYTICS}/otherViewProfile?profileId=${_id}`)
        .then(response => response.data);

    return {
        type: OTHER_VIEW_PROFILE,
        payload: request
    }
}