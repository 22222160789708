import React from 'react';
import './MembershipDescription.css'
import { withRouter } from "react-router-dom";

function MembershipDescription(props) {
    if(props.Membership){
        return (<div className='membershipDescription-container'>
            <p className='p-tag'>
                <a onClick={() => props.history.push(`/ServiceProfessional/Pricing/Membership`)}>
                    Thank you for being a valued NebourHood Prime S.P. Member! If you haven’t already, you can click here to Renew your Prime Membership Now!
                </a>
            </p>
        </div>)
    } else {
        return (
            <div className='membershipDescription-container'>
              <p className='p-tag'>
                <a onClick={() => props.history.push(`/ServiceProfessional/Pricing/Membership`)}>
                    <b>You can save an additional Rs. {75 * props.cartLength} on this order</b> & unlock exclusive benefits and rewards by becoming a NebourHood Prime S.P. Member.
                </a>
              </p>
              <div className='buttons-div-container1'>
                <button className='div-button' onClick={() => props.history.push("/ServiceProfessional/Pricing/Membership")}>Click here to join the NebourHood Prime S.P. Community Now!</button>
                </div>
             </div>
        )
    }
}

export default withRouter(MembershipDescription)