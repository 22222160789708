import React from 'react'
import './MarqueeBanner.css'
import { withRouter } from 'react-router-dom';


function MarqueeBanner(props) {
    return (
        <div className="marquee" onClick={()=>props.history.push(`/RefundReplacement&Cancellation`)}>
          <div className="marquee-content">
            {props.text}
          </div>
        </div>
    )
}

export default withRouter(MarqueeBanner)