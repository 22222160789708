import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import "./Declarationcontent.css";

export default function Declarationcontent(props) {
  // console.log("Params ", props.match.params.user);

  return (
    <div>
    <PageBanner title="Declaration" />
      <div className="declaration-container">
        <div className="declaration">
              <br />
              <br />
                <ul className='main-list'>
                    <ul className='sub-list'>
                        <li>
                          For the purpose of this Declaration, the words ‘I’, ‘my’ and ‘me’ refer to the {props.match.params.user == "PropertyOwner" ? "Property Owner" : null}
                          {props.match.params.user == "ServiceProfessional" ? "Service Professional" : null}
                          .
                        </li>
                        <li>
                          I hereby declare that all the details entered / furnished / uploaded above are true and correct to the best of my knowledge and  belief  and  I  undertake  to  inform  NebourHood of  any  changes  therein,  immediately.  
                        </li>
                        <li>
                          Further, I am giving an approval of it’s display by NebourHood, implying that I either own the rights to the information entered / furnished / uploaded or that I have a license or permission to use it from the copyright holder, thereby agreeing to abide by all the policies of NebourHood. 
                        </li>
                        <li>
                          In case any of the above information is found to be false / untrue / misleading / misrepresenting, I am aware that it would be at my own discretion and responsibility and that I would be held liable for it.
                        </li>
                        <li>
                          NebourHood would not be held responsible under any circumstances whatsoever, for any information entered / furnished / uploaded by me.
                        </li>
                    </ul>
                </ul>
                <br />
        </div>
      </div>
    </div>
  );
}
