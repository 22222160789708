import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postpropertyrequirementswithoutauth } from "../../../_actions/user_actions";
import "./PostPropertyWithoutAuth.css";
import { withRouter } from "react-router-dom";
import PageBanner from "../../utils/PageBanner/PageBanner";
import { budgets, Location } from "./Data";
import Declaration from "../../utils/Declaration/Declaration";
import LoadingIcon from "../../utils/LoadingIcon/LoadingIcon";
import { ThankyouPage } from "../../../MetaTags/tags";

function PostPropertyWithoutAuth(props) {
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [interiorselected, setinteriorselected] = useState(false);
  const [constructionselected, setconstructionselected] = useState(false);
  const [residentialselected, setresidentialselected] = useState(false);
  const [commercialselected, setcommercialselected] = useState(false);
  const [newproperty, setnewproperty] = useState(false);
  const [existingproperty, setexistingproperty] = useState(false);

  //values
  const [housename, sethousename] = useState(null);
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);

  const cities = Location.Cities.filter((city) => city.State === state).length > 0 ? Location.Cities.filter((city) => city.State === state)[0].Cities : [];

  const [areaname, setareaname] = useState(null);
  const [pincode, setpincode] = useState(null);
  const [servicetype, setservicetype] = useState(null);
  const [interiortype, setinteriortype] = useState("");
  const [constructiontype, setconstructiontype] = useState("");
  const [buildingtype, setbuildingtype] = useState(null);
  const [propertystatus, setpropertystatus] = useState(null);
  const [propertytype, setpropertytype] = useState(null);
  const [servicerequirement, setservicerequirement] = useState(null);
  const [timeframe, settimeframe] = useState(null);
  const [propertydimensiontype, setpropertydimensiontype] = useState(null);
  const [propertyarea, setpropertyarea] = useState(null);
  const [propertycondition, setpropertycondition] = useState(null);
  const [propertyconditionyears, setpropertyconditionyears] = useState("");
  const [propertyconditionmonths, setpropertyconditionmonths] = useState(null);
  const [budget, setbudget] = useState(null);
  const [propertyrequirement, setpropertyrequirement] = useState(null);
  const [validity_till, setvalidity_till] = useState(0);
  const [postrequirementsfilled, setpostrequirementsfilled] = useState(false);
  const [userName, setuserName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [projectType, setProjectType] = useState("");
  const [userPhoneNumber, setuserPhoneNumber] = useState(0);
  const [LoadingStatus, setLoadingStatus] = useState(false);
  const [CivilWork, setCivilWork] = useState("");
  const [WoodWork, setWoodWork] = useState("");
  const [LooseFurniture, setLooseFurniture] = useState("");
  const [Decor, setDecor] = useState("");

  const requirementsarray = [
    housename,
    state,
    city,
    areaname,
    pincode,
    servicetype,
    buildingtype,
    propertystatus,
    propertytype,
    servicerequirement,
    timeframe,
    propertyarea,
    propertydimensiontype,
    propertycondition,
    propertyconditionyears,
    propertyconditionmonths,
    budget,
    propertyrequirement,
    validity_till,
    userName,
    userEmail,
    userPhoneNumber
  ];

  useEffect(() => {
    const requirementnotfilled = requirementsarray.filter(
      (req) => (req = !req)
    );
    if (requirementnotfilled.length === 0) {
      setpostrequirementsfilled(true);
    } else {
      setpostrequirementsfilled(false);
    }
  });

  useEffect(() => {
  }, [propertycondition]);

  const handleselectbox = (e) => {
    const list = e.currentTarget.nextSibling;
    if (list.style.opacity === "0") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      list.style.pointerEvents = "auto";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "visible";
      list.style.transform = "scale(.5)";
      list.style.pointerEvents = "none";
    }
  };

  const handlelistitem = (e) => {
    const currentselectedid = e.currentTarget.parentElement.id;

    const itemselected = e.currentTarget.innerText;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    const listitems = e.currentTarget.parentElement.childNodes;
    for (let i = 0; i < listitems.length; i++) {
      listitems[i].style.backgroundColor = "rgb(245, 242, 242)";
      listitems[i].style.color = "black";
    }

    if (e.currentTarget.innerText === "Residential") {
      setresidentialselected(true);
      setcommercialselected(false);
    }
    if (e.currentTarget.innerText === "Commercial") {
      setresidentialselected(false);
      setcommercialselected(true);
    }
    if (currentselectedid === "state") {
      setstate(e.currentTarget.innerText);
    }
    if (currentselectedid === "city") {
      setcity(e.currentTarget.innerText);
    }
    if (currentselectedid === "buildingtype") {
      setbuildingtype(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertytype") {
      setpropertytype(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertystatus") {
      setpropertystatus(e.currentTarget.innerText);
    }
    if (currentselectedid === "servicerequirement") {
      setservicerequirement(e.currentTarget.innerText);
    }
    if (currentselectedid === "timeframe") {
      settimeframe(e.currentTarget.innerText);
      setvalidity_till(parseInt(e.currentTarget.innerText.replace(/\D/g, "")));
    }
    if (currentselectedid === "years") {
      setpropertyconditionyears(e.currentTarget.innerText);
    }
    if (currentselectedid === "state") {
      setstate(e.currentTarget.innerText);
    }
    if (currentselectedid === "months") {
      setpropertyconditionmonths(e.currentTarget.innerText);
    }
    if (currentselectedid === "budget") {
      setbudget(e.currentTarget.innerText);
    }
    heading.innerText = itemselected;
    e.currentTarget.style.backgroundColor = "#fe5631";
    e.currentTarget.style.color = "white";
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
    e.currentTarget.parentElement.style.pointerEvents = "none";
  };

  const handleradiotype = (e) => {
    if (e.target.value === "Interior") {
      setinteriorselected(true);
      setconstructionselected(false);
      setProjectType("Interior");
    }
    if (e.target.value === "Construction") {
      setinteriorselected(false);
      setconstructionselected(true);
      setProjectType("Construction");
    }
    if (e.target.value === "New Property") {
      setnewproperty(true);
      setexistingproperty(false);
      setpropertycondition(e.target.value);
      setpropertyconditionmonths("null");
      setpropertyconditionyears("null");
    }
    if (e.target.value === "Existing Property") {
      setnewproperty(false);
      setexistingproperty(true);
      setpropertycondition(e.target.value);
    }
    
  };

  const handlehousename = (e) => {
    sethousename(e.target.value);
  };

  const handleareaname = (e) => {
    setareaname(e.target.value);
  };
  const handlepincode = (e) => {
    setpincode(e.target.value);
  };

  const handlepropertydimensiontype = (e) => {
    setpropertydimensiontype(e.target.value);
  };
  const handlepropertyarea = (e) => {
    setpropertyarea(e.target.value);
  };

  const handlepropertyrequirement = (e) => {
    setpropertyrequirement(e.target.value);
  };

  const handleUserName = (e) => {
    setuserName(e.target.value);
  };

  const handleEmailId = (e) => {
    setuserEmail(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    setuserPhoneNumber(e.target.value);
  };

  const handleCivilWork = (e) => {
    if(e.target.checked){
      setCivilWork("Civil Work, ");
    } else {
      setCivilWork("");
    }
  };

  const handleWoodWork = (e) => {
    if(e.target.checked){
      setWoodWork("Wood work, ");
    } else {
      setWoodWork("");
    }
  };
  const handleLoosefurniture = (e) => {
    if(e.target.checked){
      setLooseFurniture("Loose furniture, ");
    } else {
      setLooseFurniture("");
    }
  };
  const handleDecor = (e) => {
    if(e.target.checked){
      setDecor("Decor needed, ");
    } else {
      setDecor("");
    }
  };

  const OnPostRequirements = (e) => {
    e.preventDefault();
    setLoadingStatus(true);
    var variables = {
      Land_Building: housename,
      State: state,
      City: city,
      Area_Name: areaname,
      Pin_Code: pincode,
      Project_Type: projectType,
      BuildingType: buildingtype,
      Status_Of_Project: propertystatus,
      Area_Type: propertytype,
      Project_Area: propertyarea,
      Service_Type_Required: "Both - Design and Execution",
      Beginning_The_Project: "Urgent- Within 30 Days",
      Dimension_Type: propertydimensiontype,
      Property_Condition: "New Property",
      Budget: budget,
      description:  CivilWork + WoodWork + LooseFurniture + Decor + propertyrequirement,
      Posted_Date: new Date().toLocaleDateString(),
      Posted_Time: new Date().toTimeString(),
      Validity_Till: 90,
      userName: userName,
      userEmail: userEmail,
      userPhoneNumber: userPhoneNumber
    };
    console.log("final Value: " , variables);
    dispatch(postpropertyrequirementswithoutauth(variables))
        .then((response) => {
            if(response.payload.success){
              setLoadingStatus(false);
              // alert(response.payload.message)
              ThankyouPage();
              props.history.push('/PropertyOwner/ThankYouPage');
            } else {
              setLoadingStatus(false);
              alert(response.payload.message)
            }
        })
  };

  const [agreed, setagreed] = useState(false);

  const handleagreed = (val) => {
    setagreed(val);
  };

  return (
      <>
        {LoadingStatus ? 
        <>
        <LoadingIcon />
        </> 
        : 
        <>
        <div className="post-property-requirements-container">
      {/* <PageBanner title="Post Property Requirements" /> */}
      <div className="header">
        <p className="title">
          <b>Please take a few minutes and kindly fill in all the details correctly to get in touch with a suitable Architect or Interior Designer</b>
          {/* <b>Elevate Your Interiors with Top Design Talent hasslefree and budget friendly</b> */}
        </p>
        {/* <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Please take a few minutes and kindly fill in all the details correctly to get in touch with a suitable Architect or Interior Designer
        </p> */}
      </div>
      <br />
      <div className="post-property-requirements">
        <div
          style={{
            width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
          }}
          className="property-requirements"
        >
          <div className="requirement">
                <p className="requirement-title">Introduce yourself</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      Name
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <input
                      onChange={handleUserName}
                      style={{
                        width: "250px",
                      }}
                      className="text-input"
                      placeholder="Example : John"
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      Phone Number
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <input
                      onChange={handlePhoneNumber}
                      style={{
                        width: "250px",
                      }}
                      className="text-input"
                      type="Number"
                    />
                  </div>
                </div>
                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      E-mail
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <input
                      onChange={handleEmailId}
                      style={{
                        width: "250px",
                      }}
                      className="text-input"
                      placeholder="Example : john@gamil.com"
                      type={"text"}
                    />
                  </div>
                </div>
              </div>
          <div className="requirement">
            <p className="requirement-title">Could you share details about your project?</p>
            {/* <p className="requirement-desc">
              Please fill the details of your property where you want the work
              to be completed
            </p> */}
            <div className="requirement-details">
              <div className="input-container">
                <label className="input-title">
                  State <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="state" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    <p> Select State</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="state"
                    className="select-box-list"
                  >
                    {Location.States.map((state, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {state}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <div className="input-container">
                <label className="input-title">
                  City <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <ul id="city" className="selectbox-container">
                  <li onClick={handleselectbox} className="heading">
                    <p>Select City</p>
                    <span className="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="city"
                    className="select-box-list"
                  >
                    {cities.map((city, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {city}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <div className="input-container">
                <label className="input-title">
                  Address
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <input
                  onChange={handlehousename}
                  className="text-input"
                  type={"text"}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="requirement">
            <p className="requirement-title">Service Type Required</p>
            <div className="requirement-details">
              <div className="input-container">
                <label className="input-title">
                Type of Service you are looking for?
                  <b style={{ color: "red" }}>*</b> :{" "}
                </label>
                <div className="radios">
                  <div className="radio">
                    <label>Interior service</label>
                    <input
                      onChange={handleradiotype}
                      type={"radio"}
                      value={"Interior"}
                      name="propertytype"
                    />
                  </div>
                  <div className="radio">
                    <label>Architecture and construction service</label>
                    <input
                      onChange={handleradiotype}
                      type={"radio"}
                      value={"Construction"}
                      name="propertytype"
                    />
                  </div>
                </div>
              </div>
              
              <div className="input-container">
                    <label className="input-title">
                      Property Type <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p>Select</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertytype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Apartment</li>
                          <li onClick={handlelistitem}>Penthouse</li>
                          <li onClick={handlelistitem}>Stand Alone Building</li>
                          <li onClick={handlelistitem}>Villa / Independent House</li>
                          <li onClick={handlelistitem}>Farmhouse</li>
                          <li onClick={handlelistitem}>Bungalow</li>
                          <li onClick={handlelistitem}>Retail</li>
                          <li onClick={handlelistitem}>Hospitality</li>
                          <li onClick={handlelistitem}>Healthcare</li>
                          <li onClick={handlelistitem}>Office - Corporate and Tech</li>
                          <li onClick={handlelistitem}>Institutional</li>
                          <li onClick={handlelistitem}>Industrial</li>
                          <li onClick={handlelistitem}>Other</li>
                        </ul>
                    </ul>
                  </div>

              {/* <div className="requirement">
                <p className="requirement-title">Budget</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                      What is your approximate budget for the scope of work
                      involved?
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <ul id="state" className="selectbox-container">
                      <li onClick={handleselectbox} className="heading">
                        <p> Select Budget</p>
                        <span className="material-icons">expand_more</span>
                      </li>
                      <ul
                        style={{
                          opacity: 0,
                        }}
                        id="budget"
                        className="select-box-list"
                      >
                        {interiorselected
                          ? budgets.Interior.map((budget) => {
                              return <li onClick={handlelistitem}>{budget}</li>;
                            })
                          : null}
                        {constructionselected
                          ? budgets.Construction.map((budget) => {
                              return <li onClick={handlelistitem}>{budget}</li>;
                            })
                          : null}
                      </ul>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="requirement">
                <p className="requirement-title">Property Requirements</p>

                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                    Share more details about your requirement
                      <b style={{ color: "red" }}>*</b> :{" "}
                    </label>
                    <div className="radio">
                      <input type="checkbox" name="civilwork" onChange={handleCivilWork} />&nbsp;&nbsp;Civil work needed?
                    </div>
                    <div className="radio">
                      <input type="checkbox" name="woodword" onChange={handleWoodWork} />&nbsp;&nbsp;Wood work needed?
                    </div>
                  </div>
                </div>
                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                    </label>
                    <div className="radio">
                      <input type="checkbox" name="loosefurniture" onChange={handleLoosefurniture} />&nbsp;&nbsp;Loose furniture? (Bed, sofa, side tables, chairs)
                    </div>
                    <div className="radio">
                      <input type="checkbox" name="decor" onChange={handleDecor} />&nbsp;&nbsp;Decor needed?
                    </div>
                  </div>
                </div>
                <div className="requirement-details">
                  <div className="input-container">
                    <label className="input-title">
                    Any specific requirement or thing you would like us to consider?
                      {/* <b style={{ color: "red" }}>*</b> :{" "} */}
                    </label>
                    <textarea
                      onChange={handlepropertyrequirement}
                      className="text-area"
                    />
                  </div>
                </div>
              </div>
              
              <br />
            </div>
          </div>
          <Declaration agreed={handleagreed} user={"PO"} />
        </div>

          <button
            style={{
              opacity: agreed ? 1 : 0.5,
              pointerEvents: agreed ? "auto" : "none",
            }}
            onClick={OnPostRequirements}
            className="post-property-req-btn"
          >
            Post Property Requirements
          </button>
      </div>
    </div>
        </>}
      </>
  );
}

export default withRouter(PostPropertyWithoutAuth);
