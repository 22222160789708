import React from 'react'
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

function MyStatsComponent(props) {
    const company = useSelector((state) => state.company);
    if(company.companyData && company.companyData.isAuth)
        {
          return <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
                <a onClick={() => props.history.push('/ServiceProfessional/MyStats')} style={{ color: 'white' }}>My Stats</a>
          </div>
        } else {
          return null;
        }
      
}

export default withRouter(MyStatsComponent)