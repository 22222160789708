import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Property.css";
import { withRouter } from "react-router-dom";
// import Filters from "../../../utils/Filters/Filters";
// import { useDispatch } from "react-redux";
import Apartment from "../../../../../asserts/Property_Requirement_Images/Apartment.jpg";
import Bungalow from "../../../../../asserts/Property_Requirement_Images/Bungalow.jpg";
import Healthcare from "../../../../../asserts/Property_Requirement_Images/Healthcare.jpg";
import Hospitality from "../../../../../asserts/Property_Requirement_Images/Hospitality.jpg";
import Independent_Home from "../../../../../asserts/Property_Requirement_Images/Independent_Home.jpg";
import Industrial from "../../../../../asserts/Property_Requirement_Images/Industrial.jpg";
import Institutional from "../../../../../asserts/Property_Requirement_Images/Institutional.jpg";
import Office_Corporate_and_Tech from "../../../../../asserts/Property_Requirement_Images/Office_Corporate_and_Tech.jpg";
import Penthouse from "../../../../../asserts/Property_Requirement_Images/Penthouse.jpg";
import Retail from "../../../../../asserts/Property_Requirement_Images/Retail.jpg";
import Stand_Alone_Building from "../../../../../asserts/Property_Requirement_Images/Stand_Alone_Building.jpg";
import Villa from "../../../../../asserts/Property_Requirement_Images/Villa.jpg";
import soldout from "../../../../LandingPage/Property/Soldout.png"
import ValidityExpire from "../../../../../asserts/OtherImages/Validity_Expired.svg"
import NavigationNumbers from "../../../../../utils/NavigationNumbers/NavigationNumbers";
// import { getAllProperty } from "../../../../../../_actions/property_actions";
import Button from "../../../../../utils/Button/Button";
// import LoadingIcon from "../../../../../utils/LoadingIcon/LoadingIcon";

function Property(props) {
  // console.log("Props ", props.Property);
  // const dispatch = useDispatch();
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  // const [propertyheight, setpropertyheight] = useState();
  const [datareceived, setdatareceived] = useState(false);
  const [propertyheight, setpropertyheight] = useState(null);
  const [NoProduct, setNoProduct] = useState(false);

  const [featurespropertieslength, setfeaturespropertieslength] =
    useState(false);

  const propertyheightref = useRef(null);
  const [Products, setProducts] = useState([]);

  // const getProduct = (variables) => {
  //   setdatareceived(false);
    
  //   // if(filters){
  //   //   setdatareceived(true)
  //   // }

  //   dispatch(getAllProperty(variables)).then((response) => {
  //     if (response.payload.success) {
  //       if (response.payload.products.length > 0) {
  //         setNoProduct(false);
  //         setProducts(response.payload.products);
  //         setTimeout(() => {
  //           setpropertyheight(
  //             propertyheightref.current.getBoundingClientRect().height
  //           );
  //           const fplength =
  //             document.getElementById("Featured-properties").childNodes.length /
  //             3;
  //           if (fplength >= 8) {
  //             setfeaturespropertieslength(8);
  //           } else {
  //             if (fplength % 1 !== 0) {
  //               setfeaturespropertieslength(Math.floor(fplength) + 1);
  //             } else {
  //               setfeaturespropertieslength(fplength);
  //             }
  //           }
  //         }, 20);
  //       } else {
  //         setNoProduct(true);
  //         setTimeout(() => {
  //           setpropertyheight(0);
  //         }, 20);
  //       }
  //       setTimeout(() => {
  //         setdatareceived(true);
  //       }, 10);
  //     } else {
  //       alert("Failed to fetch product datas");
  //     }
  //     console.log("Response Dispatch ", response);
  //   });
  // };

  useEffect(() => {
    setdatareceived(false);
    // console.log(Products.length);
    if(props.Property.length > 0){
      setNoProduct(false);
      setProducts(props.Property);
      setTimeout(() => {
        setpropertyheight(propertyheightref.current.getBoundingClientRect().height);
        const fplength = document.getElementById("Featured-properties").childNodes.length / 3;
        if(fplength >= 8) {
          setfeaturespropertieslength(8);
        } else {
          if(fplength % 1 !== 0) {
            setfeaturespropertieslength(Math.floor(fplength) + 1);
          } else {
            setfeaturespropertieslength(fplength);
          }
        }
      }, 20);
    } else {
      setNoProduct(true);
      setTimeout(() => {
        setpropertyheight(0);
      }, 20);
    }
    setTimeout(() => {
      setdatareceived(true);
    }, 10);
  }, [Products.length]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  // useEffect(() => {
  //   let variables = {
  //     filter: false,
  //     State: "",
  //     City: "",
  //     Project_Type: "",
  //     BuildingType: "",
  //   };
  //   getProduct(variables);
  // }, []);

  // const getfiltersdata = (data) => {
  //   console.log("Filter Data ", data);
  //   let variables = {
  //     filter: true,
  //     State: data.State,
  //     City: data.City,
  //     Project_Type: data.ProjectType,
  //     BuildingType: data.ProjectSubType,
  //   };
  //   getProduct(variables);
  // };

  return (
    <div className="Property-req-container">
      <div>
        <div className="properties-featured-properties-container">
          <div className="titleanddesc">
            <p className="title">
              <b>My Property Requirements</b>
            </p>
            
          </div>
          {/* <Filters
            Location={true}
            ProjectType={2}
            ProjectTypeTitle="Property Requirement Type"
            getfiltersdata={getfiltersdata}
          /> */}
            {NoProduct ? (
              <div className="no-product-found">
                <p>No Property Requirements Found !</p>
              </div>
            ) : (
              <div className="Featured-properties-container">
                <div
                  style={{
                    height:
                      propertyheight * featurespropertieslength +
                      (featurespropertieslength - 1) * 20 +
                      "px",
                  }}
                  id="Featured-properties"
                  className="Ffeatured-properties"
                >
                  {Products.map((property, index) => {
                    return (
                      <div
                        id="featured-properties-property"
                        className="Featured-properties-property"
                        ref={propertyheightref}
                        key={index}
                      >
                        <div className="upper">
                          {property.sold > 0 ?
                            <>
                            {property.Validity_Till <= 0? 
                            <img className="sold-out-img" src={ValidityExpire} alt="sold-out-img" />
                            :
                            null
                            }
                            </>
                            :
                            <>
                            {property.sold <= 0?
                            <img className="sold-out-img" src={soldout} alt="sold-out-img" />
                            :
                            null
                            }
                            </>
                          }
                          {/* <img className="sold-out-img" src={soldout} alt="sold-out-img" /> */}
                          {property.Project_Type === "Interior" ? (
                            <p className="tag" id="interior-tag">
                              INTERIOR
                            </p>
                          ) : (
                            <p className="tag" id="construction-tag">
                              CONSTRUCTION
                            </p>
                          )}
                          {property.Area_Type === "Apartment" ? (
                            <img
                            className="upper-img" src={Apartment} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Bungalow" ? (
                            <img
                            className="upper-img" src={Bungalow} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Penthouse" ? (
                            <img
                            className="upper-img" src={Penthouse} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Institutional" ? (
                            <img
                            className="upper-img" src={Institutional} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Hospitality" ? (
                            <img
                            className="upper-img" src={Hospitality} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Healthcare" ? (
                            <img
                            className="upper-img" src={Healthcare} alt="featured-property" />
                          ) : null}
                          {property.Area_Type ===
                          "Office - Corporate and Tech" ? (
                            <img
                            className="upper-img"
                              src={Office_Corporate_and_Tech}
                              alt="featured-property"
                            />
                          ) : null}
                          {property.Area_Type === "Industrial" ? (
                            <img
                            className="upper-img" src={Industrial} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Retail" ? (
                            <img
                            className="upper-img" src={Retail} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Villa" ? (
                            <img
                            className="upper-img" src={Villa} alt="featured-property" />
                          ) : null}
                          {property.Area_Type === "Independent Home" ? (
                            <img
                            className="upper-img"
                              src={Independent_Home}
                              alt="featured-property"
                            />
                          ) : null}
                          {property.Area_Type === "Stand Alone Building" ? (
                            <img
                            className="upper-img"
                              src={Stand_Alone_Building}
                              alt="featured-property"
                            />
                          ) : null}
                          <p className="price">{property.BuildingType}</p>
                        </div>
                        <div className="middle">
                          <div className="address">
                            <p
                              className="title"
                              style={{ color: "black", minWidth: "70px" }}
                            >
                              Address :
                            </p>
                            <p className="addrss">
                              {property.Land_Building}, {property.State},{" "}
                              {property.City}, {property.Area_Name}
                            </p>
                          </div>
                          <div className="address">
                            <p className="title" style={{ color: "black" }}>
                              Property Owner Name :
                            </p>
                            <b
                              style={{
                                fontSize: "20px",
                              }}
                            >
                              {property.Name_Of_property_Owner}
                            </b>
                          </div>
                          {/* <h2 className="property-title">
                          Property Owner Name:&nbsp;&nbsp;{property.Name_Of_property_Owner}
                          </h2> */}
                          <div className="address">
                            <p className="title" style={{ color: "black" }}>
                              Property Req. No :
                            </p>
                            <p className="addrss">{property._id}</p>
                          </div>
                          {/* <p className="apartmentandsize">
                            Property Requirement No.&nbsp;&nbsp;{property._id}
                          </p> */}
                          <p className="apartmentandsize">
                            {property.Area_Type}&nbsp;({property.Project_Area}{" "}
                            sq.ft.)
                          </p>
                          <div
                            // style={{
                            //   width:
                            //     screenwidth <= 750 ? screenwidth - 50 + "px" : null,
                            // }}
                            className="other-details"
                          >
                            <div className="dtl" style={{ borderLeft: "none" }}>
                              {/* <span className="material-icons">bed</span> */}
                              <p>
                                {property.Service_Type_Required
                                  ? property.Service_Type_Required
                                  : "---"}
                              </p>
                            </div>
                            <div className="border"></div>
                            <div className="dtl">
                              {/* <span className="material-icons">bathtub</span> */}
                              <p>
                                {property.Beginning_The_Project
                                  ? property.Beginning_The_Project
                                  : "---"}
                              </p>
                            </div>
                            <div className="border"></div>
                            {/* {property.FreeParking ? ( */}
                            <div className="dtl">
                              {/* <span className="material-icons">directions_car</span> */}
                              <p
                              // onClick={() => {
                              //   console.log(randomdata);
                              // }}
                              >
                                {property.Property_Condition
                                  ? property.Property_Condition
                                  : "---"}
                              </p>
                            </div>
                            {/* ) : null} */}
                          </div>
                        </div>
                        <div className="bottom">
                        {/* <p>Posted on: {property.Posted_Date}</p> */}
                          <div className="rating">
                            {/* <div className="stars">
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                            </div> */}
                            {/* <div className="rating-name"> */}
                              {/* {" "} */}
                              {/* {5 > 4 ? "Excellent" : "Average"} */}
                              {/* Posted on: {property.Posted_Date} */}
                            {/* </div> */}
                          </div>
                          <div className="knowmore-btn">
                            <Button
                              width="150px"
                              name="KNOW DETAILS"
                              directto={`/ServiceProfessional/PropertyRequirementsDetails/${property._id}`}
                              background="#201c2d"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
           
        </div>

        <br />
        {datareceived ? (
          !NoProduct ? (
            <NavigationNumbers
              numberofitems={24}
              screenwidthfornav1={1200}
              numberofitems1={6}
              screenwidthfornav2={750}
              numberofitems2={3}
              itemscontainerid="Featured-properties"
              heighttomove={propertyheight * 8 + 160}
            />
          ) : null
        ) : null}
        <br />
      </div>
    </div>
  );
}

export default withRouter(Property);
