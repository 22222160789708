import React, { useEffect, useLayoutEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loginCompanyGoogle,
  registerCompany,
} from "../../../../_actions/company_actions";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./Register.css";

function Register(props) {
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  const [register, setregister] = useState(false);
  // const [username, setusername] = useState("0");
  const [email, setemail] = useState("@gmail.com");
  // const [phone, setphone] = useState("01234567891");
  const [password, setpassword] = useState("");
  const [passwordsmatch, setpasswordsmatch] = useState(false);
  const [detailsfilled, setdetailsfilled] = useState(false);

  const userdetails = {
    // name: username,
    email: email,
    // Phone_Number: phone,
    password: password,
  };

  const details = [email, password];

  useEffect(() => {
    details.forEach((e) => {
      if (e.length < 8) {
        setdetailsfilled(false);
      } else {
        setdetailsfilled(true);
      }
    });
  }, [details]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [screenwidth]);

  // const handleusername = (e) => {
  //   setusername(e.target.value);
  // };
  const handleemail = (e) => {
    setemail(e.target.value);
  };
  // const handlephone = (e) => {
  //   setphone(e.target.value);
  // };
  const handlepassword = (e) => {
    setpassword(e.target.value);
  };

  const handleconfirmpassword = (e) => {
    if (e.target.value !== password) {
      setpasswordsmatch(false);
    } else {
      setpasswordsmatch(true);
    }
  };

  const handleremember = (e) => {
    // console.log(e.target.checked);
  };

  const handleregister = (e) => {
    e.preventDefault();
    // console.log("userdetails 1", userdetails);
    dispatch(registerCompany(userdetails)).then((response) => {
      if (response.payload.success) {
        props.history.push("/serviceprofessional/Login");
      } else {
        alert("User Already Registered");
      }
    });

    // if (
    //   userdetails.Username.length <= 2 ||
    //   userdetails.Email.length <= 10 ||
    //   userdetails.Phone.length <= 10 ||
    //   userdetails.Password.length === 0
    // ) {
    //   //Do Nothing
    // } else {
    //   setregister(true);
    //   setTimeout(() => {
    //     setregister(false);
    //     props.history.push("/");
    //   }, 2000);
    // }
  };

  const handleLogin = (googleData) => {
    dispatch(loginCompanyGoogle(googleData)).then((response) => {
      // console.log("Google Response", response);
      if (response.payload.loginSuccess) {
        window.localStorage.setItem("companyId", response.payload.companyId);
        if(response.payload.Profile_Details_Filled) {
          props.history.push("/ServiceProfessional/Home");
        } else {
          props.history.push("/ServiceProfessional/MyProfile");
        }
      } else {
        alert("Check out your Account or Password again");
      }
    });
  };

  useEffect(() => {
    if (screenwidth <= 670) {
      // document.getElementById("username").style.width = `${screenwidth - 50}px`;
      document.getElementById("email").style.width = `${screenwidth - 50}px`;
      document.getElementById("password").style.width = `${screenwidth - 50}px`;
      document.getElementById("confirmpassword").style.width = `${
        screenwidth - 50
      }px`;
      // document.getElementById("phone").style.width = `${screenwidth - 50}px`;
    }
  }, [screenwidth]);

  return (
    <div className="register-container">
      <PageBanner title="Register as a Service Professional" />
      <div className="header">
        <p className="title">
          <b>Please Sign Up as Service Professional if you're an Interior Designer, Architect, Engineer or a Contractor.</b>
        </p>
        {/* <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Please Sign Up as Service Professional if you're an Interior Designer, Architect, Engineer or a Contractor.
        </p> */}
      </div>
      {/* <div style={{ textAlign: 'center', marginTop: '25px' }}>
        <p style={{ fontSize: '20px', color: 'red' }}>
          Please Sign Up as Service Professional if you're an Interior Designer, Architect, Engineer or a Contractor.
        </p>
      </div> */}
      <div id="register" className="register">
        <form>
          <div className="title">
            <p>
              <b>Register as a Service Professional</b>
            </p>
          </div>
          {/* <input
            className="username"
            id="username"
            type={"text"}
            onChange={handleusername}
            placeholder="Username"
          /> */}
          {/* <p className="error" >{userfound ? "Account exists !":""}</p> */}
          <input
            className="email"
            id="email"
            type={"email"}
            onChange={handleemail}
            placeholder="Email"
          />
          <p
            className="error"
            style={{
              display: email.length < 10 ? "block" : "none",
            }}
          >
            {email.length < 10 ? "Incorrect Email !" : ""}
          </p>
          {/* <input
            className="phone"
            id="phone"
            type={"text"}
            onChange={handlephone}
            placeholder="Phone"
          />
          <p className="error" style={{
            display : phone.length < 10 ? "block" : "none"
          }}>
            {phone.length < 10 ? "Incorrect Phone !" : ""}
          </p> */}
          <input
            className="password"
            id="password"
            type={"password"}
            onChange={handlepassword}
            placeholder="Password"
          />
          {password.length < 8 && password.length > 1 ? (
            <p className="error">Password should be atleast 8 characters</p>
          ) : null}
          <input
            className="password"
            id="confirmpassword"
            type={"password"}
            onChange={handleconfirmpassword}
            placeholder="Confirm Password"
          />
          <p className="error">
            {!passwordsmatch && password.length > 0
              ? "Passwords doesn't match !"
              : ""}{" "}
          </p>
          <div
            style={{
              width: screenwidth <= 670 ? screenwidth - 50 + "px" : null,
            }}
            className="rememberandlost"
          >
            <div className="rememberme">
              <input
                onChange={handleremember}
                name="remember"
                type={"checkbox"}
              />
              &nbsp;
              <label for="remember">Remember Me</label>
            </div>
            {/* <a href="/ServiceProfessional/forgotpassword">
              Lost your password ?
            </a> */}
          </div>
          <button
            style={{
              width: screenwidth <= 670 ? screenwidth - 50 + "px" : null,
              opacity: detailsfilled && passwordsmatch ? 1 : 0.5,
              pointerEvents: detailsfilled && passwordsmatch ? "auto" : "none",
            }}
            onClick={handleregister}
          >
            {register ? (
              <LoadingIcon
                width="30px"
                height="30px"
                outerwidth="30px"
                outerheight="30px"
                innerwidth="0"
                innerheight="0"
                position="relative"
                backgroundcolor="transparent"
              />
            ) : (
              "Register"
            )}
          </button>
        </form>
        <div className="other-methods-for-register">
          <div className="google-and-otp">
            {/* <button className="via-google">Login Via Google</button> */}
            <GoogleLogin
              clientId="737789981008-3kqljeg4a44qdbbiib27e9d8rtr8m34o.apps.googleusercontent.com"
              buttonText="Sign Up with Google"
              onSuccess={handleLogin}
              // onFailure={handleLoginFail}
              cookiePolicy={"single_host_origin"}
            />
            {/* <p className="or">Or</p>
            <button className="via-otp">Login Via OTP</button> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
      <p>
        By registering / creating an account, you agree to the 
        <a href="/PrivacyPolicy" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Privacy Policy </a>, 
        <a href="/terms&conditions" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Terms & Conditions and Replacement </a>, 
        <a href="/Communityguidelines" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Community Guidelines </a>, 
        <a href="/RefundReplacement&Cancellation" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Refund & Cancellation Policies </a>, 
        <a href="/PORules" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Property Owner Rules </a> 
        and 
        <a href="/SPRules" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Service Professional Rules </a>
        of NebourHood.
        </p>
        <p>
        We will occasionally send you account - related emails.
        </p>
        </div>
    </div>
  );
}

export default withRouter(Register);
