import React, { useEffect } from 'react'
import './UnsubscribeEmailNotification.css'
import { useDispatch } from 'react-redux';
import { unsubscribeForEmailNotification } from '../../../_actions/company_actions';

function UnsubscribeEmailNotification(props) {
    const dispatch = useDispatch();
    useEffect(() => {
    console.log(props.match.params.emailId);  
    dispatch(unsubscribeForEmailNotification(props.match.params.emailId)).then((response) => {
        if(response.payload.success) {
            window.alert("Successfully unsubscribed");
        } else {
            window.alert("Failed in unsubscribed");
        }
    })
    }, []);

  const emailId = props.match.params.emailId;
  

  return (
    <div className='divmain-container'>
        <div className='div1'>You have successfully unsubscribed for e-mail notification service</div>
        <div className='div2'>For e-mail subscription please connect us on <span style={{ color: 'blue'}}>hello@nebourhood.com</span>.</div>
        <div className='div3'>Thank You !!!</div>
        </div>
  )
}

export default UnsubscribeEmailNotification