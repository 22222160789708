import React from "react";
import "./PropertyOptions.css";
import Button from "../../../utils/Button/Button";
import { Options } from "./Options";

export default function PropertyOptions() {
  return (
    <div className="property-options-container">
      <div className="property-options">
        {Options.map((option, index) => {
          return (
            <div  key={index} className="optionn">
              <img src={option.link} alt={option.title} />
              <br />
              <h2>{option.title}</h2>
              <p>{option.description}</p>
              <div className="button">
                <Button
                  width="auto"
                  background="#fe5631"
                  name={option.action}
                  directto="/"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
