import React from "react";
import PleaseNoteDisclaimer from "../../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";
import PORazorpay from "../../../../utils/Razorpay/PO_Posting_Renew/PORazorpay";
import "./RenewPaymentModal.css";

export default function PaymentModal(props) {
  var modalopen = props.modalopen;

  // const details = props.filleddetails;
  // console.log("Data Filled Details",props.filleddetails);
  // console.log("Data To Renew", props.DataToSubmit);
  return (
    <div
      style={{
        transform: modalopen ? "scale(1)" : "scale(0)",
      }}
      className="payment-modal-container"
    >
      <form className="form">
        <h1 className="title">Renew Property Details</h1>
        <br />
        <br />
        <br />
        <div className="modal-details">
          <div className="property-details">
            <div className="detail">
              <p className="detail-title">Cost Price (Exclusive of G.S.T.)</p>
              <p className="cost">₹ 499 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">Final Amount (Exclusive of G.S.T.)</p>
              <p className="cost">₹ 499 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">G.S.T. Amount (18%) </p>
              <p className="cost">₹ 0 /-</p>
            </div>

            <div className="detail">
              <p className="detail-title">Total Amount</p>
              <p className="cost">₹ 499 /-</p>
            </div>
          </div>
          <br />
          <br />
            <PleaseNoteDisclaimer />
        </div>

        <button onClick={props.handlepostreq} className="submit-btn">
          <PORazorpay value="Renew" DetailsToPost={props.DataToSubmit} />
        </button>
        <span
          id="close-icon"
          onClick={props.handleclose}
          className="material-icons"
          style={{
            top: "5px",
            right: "10px",
          }}
        >
          close
        </span>
      </form>
    </div>
  );
}
