const data = {
  Images: [
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-3.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-5.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-4.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-6.jpg",
    "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-7.jpg",
  ],
  Address: "64 1st Avenue, High Street, NZ 1002",
  Title: "Blue Reef Properties",
  Type: "Apartment",
  Size: "78 sq.m",
  Price: 2500,
  Bedrooms: 4,
  Baths: 2,
  FreeParking: "Yes",
  Rating: 4,
};

const suggestions = [
  {
    Title: "Modern Apartment With Pool",
    Image:
      "https://templates.envytheme.com/fido/default/assets/images/featured/featured-1.jpg",
    Address: " 64 1st Avenue",
    for: "FOR SELL",
    Price: 2500,
  },
  {
    Title: "Luxury Villa in Los Angeles",
    Image:
      "https://templates.envytheme.com/fido/default/assets/images/featured/featured-2.jpg",
    Address: " 64 1st Avenue",
    for: "FOR SELL",
    Price: 2500,
  },
  {
    Title: "Blue Reef Properties",
    Image:
      "https://templates.envytheme.com/fido/default/assets/images/featured/featured-3.jpg",
    Address: " 64 1st Avenue",
    for: "FOR SELL",
    Price: 2500,
  },
  {
    Title: "Modern Apartment With Pool",
    Image:
      "https://templates.envytheme.com/fido/default/assets/images/featured/featured-4.jpg",
    Address: " 64 1st Avenue",
    for: "FOR SELL",
    Price: 2500,
  },
];

const propertydetails = [
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-3.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Description : "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu.Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin quis bibendum auctor, nisilit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi acnec tellus a odio tincidunt auctor a ornare odio.",
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "dfbdfbdfbdfbdf",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "wesgerfbdfgvef",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "tnegsdgswegfseg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "ef2egsrgwrgsrg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  },
  {
    id: "gwqefrhdrgsrgrsg",
    Images: [
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-2.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
      "https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg",
    ],
    Address: "64 1st Avenue, High Street, NZ 1002",
    Title: "Blue Reef Properties",
    Type: "Apartment",
    Size: "78 sq.m",
    Price: 2500,
    Bedrooms: 4,
    Baths: 2,
    FreeParking: "Yes",
    Rating: 4,
    Overview: {
      Type: "Entire Place / Apartment",
      Accomodation: "6 Guest / 900 SqFt",
      Bedrooms: "4 Bedrooms / 1 Guestroom",
      Bathrooms: "2 Attach Bathrooms",
      Parking: "Free Parking for 4 Cars",
    },
    FullAddress: {
      Address: "126 Repark, Kingston",
      Country: "Unites States",
      CityorTown: "Kingston",
      Province: "New York",
      Neighborhood: "South Kingdom",
    },
    Features: [
      "Gym",
      "Swimming Pool",
      "TV Cable",
      "Laundry",
      "Wifi",
      "Washer",
      "Microwave",
      "Window Coverings",
      "Refrigerator",
    ],
    FloorPlan : {
      Image : "https://templates.envytheme.com/fido/default/assets/images/property-details/plan.png"
    },
    Video : {
      id: "ODfy2YIKS1M"
    },
    Dealer : {
      Name : "Thomas Edison",
      Contact : +2199389832484,
      Image : "https://templates.envytheme.com/fido/default/assets/images/agents/agents-3.jpg"
    }
  }
];

module.exports.properties = data;
module.exports.suggestions = suggestions;
module.exports.propertydetails = propertydetails;



