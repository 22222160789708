import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateuserprofile } from "../../../../../../_actions/user_actions";
import Declaration from "../../../../../utils/Declaration/Declaration";
import "./PO_EditProfile.css";

function PO_EditProfile(props) {
  // console.log("User Details ", props.UserDetails);
  const dispatch = useDispatch();

  const [PropertyOwnerName, setPropertyOwnerName] = useState(props.UserDetails.name);
  const [DOB, setDOB] = useState(props.UserDetails.Personal_Details.DOB);
  const [Gender, setGender] = useState(props.UserDetails.Personal_Details.Gender);
  const [AlternativeEmail, setAlternativeEmail] = useState(props.UserDetails.Personal_Details.Alternative_Email_Id);
  const [AlternativePhoneNumber, setAlternativePhoneNumber] = useState(
    props.UserDetails.Personal_Details.Alternative_Phone_Number
  );
  const [WhatsAppNumber, setWhatsAppNumber] = useState(
    props.UserDetails.Personal_Details.Personal_WhatsApp_Number
  );
  const [Assamese, setAssamese] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Assamese
  );
  const [Bengali, setBengali] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Bengali
  );
  const [English, setEnglish] = useState(
    props.UserDetails.Personal_Details.Languages_Known.English
  );
  const [Gujarati, setGujarati] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Gujarati
  );
  const [Hindi, setHindi] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Hindi
  );
  const [Kannada, setKannada] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Kannada
  );
  const [Malayalam, setMalayalam] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Malayalam
  );
  const [Marathi, setMarathi] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Marathi
  );
  const [Odia, setOdia] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Odia
  );
  const [Punjabi, setPunjabi] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Punjabi
  );
  const [Tamil, setTamil] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Tamil
  );
  const [Telugu, setTelugu] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Telugu
  );
  const [Urdu, setUrdu] = useState(
    props.UserDetails.Personal_Details.Languages_Known.Urdu
  );
  // const [Adhaar, setAdhaar] = useState(props.UserDetails.Personal_Details.Aadhaar_Card);

  const onHabdlePropertyOwnerName = (e) => {
    // console.log(e.target.value);
    setPropertyOwnerName(e.target.value);
  };

  const onHandleDOBChange = (e) => {
    // console.log(e.target.value);
    setDOB(e.target.value);
  };

  const onAlternativeEmailChange = (e) => {
    // console.log(e.target.value);
    setAlternativeEmail(e.target.value);
  };

  const onAlternativePhoneNumberChange = (e) => {
    // console.log(e.target.value);
    setAlternativePhoneNumber(e.target.value);
  };

  const onPersonalWhatsAppChange = (e) => {
    // console.log(e.target.value);
    setWhatsAppNumber(e.target.value);
  };

  const onHandleAssameseChange = (e) => {
    // console.log(!Assamese);
    setAssamese(!Assamese);
  };

  const onHandleBengaliChange = (e) => {
    // console.log(!Bengali);
    setBengali(!Bengali);
  };

  const onHandleEnglishChange = (e) => {
    // console.log(!English);
    setEnglish(!English);
  };

  const onHandleGujaratiChange = (e) => {
    // console.log(!Gujarati);
    setGujarati(!Gujarati);
  };

  const onHandleHindiChange = (e) => {
    // console.log(!Hindi);
    setHindi(!Hindi);
  };

  const onHandleKannadaChange = (e) => {
    // console.log(!Kannada);
    setKannada(!Kannada);
  };

  const onHandleMalayalamChange = (e) => {
    // console.log(!Malayalam);
    setMalayalam(!Malayalam);
  };

  const onHandleMarathiChange = (e) => {
    // console.log(!Marathi);
    setMarathi(!Marathi);
  };

  const onHandleOdiaChange = (e) => {
    // console.log(!Odia);
    setOdia(!Odia);
  };

  const onHandlePunjabiChange = (e) => {
    // console.log(!Punjabi);
    setPunjabi(!Punjabi);
  };

  const onHandleTamilChange = (e) => {
    // console.log(!Tamil);
    setTamil(!Tamil);
  };

  const onHandleTeluguChange = (e) => {
    // console.log(!Telugu);
    setTelugu(!Telugu);
  };

  const onHandleUrduChange = (e) => {
    // console.log(!Urdu);
    setUrdu(!Urdu);
  };

  // const onAdhaarNoChange = (e) => {
  //   setAdhaar(e.target.value);
  // };

  const onSubmit = (e) => {
    // console.log("Submit Changes");
    var variables = {
      Property_Owner_Name: PropertyOwnerName,
      DOB: DOB,
      Gender: Gender,
      PhoneNumberEntered: props.UserDetails.phonenumber,
      AlternativeEmail: AlternativeEmail,
      AlternativeNumber: AlternativePhoneNumber,
      WhatsAppNumber: WhatsAppNumber,
      // AdhaarNo: Adhaar,
      Languages_Known: {
        Assamese: Assamese,
        Bengali: Bengali,
        English: English,
        Gujarati: Gujarati,
        Hindi: Hindi,
        Kannada: Kannada,
        Malayalam: Malayalam,
        Marathi: Marathi,
        Odia: Odia,
        Punjabi: Punjabi,
        Tamil: Tamil,
        Telugu: Telugu,
        Urdu: Urdu,
      },
    };
    dispatch(updateuserprofile(variables)).then((response) => {
      // console.log("Response ", response);
      window.location.reload();
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });
    });
  };
  const handlelistitem = (e) => {
    const currentselectedid = e.currentTarget.parentElement.id;
    const itemselected = e.currentTarget.innerText;
    const heading =
      e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    const listitems = e.currentTarget.parentElement.childNodes;
    for (let i = 0; i < listitems.length; i++) {
      listitems[i].style.backgroundColor = "rgb(245, 242, 242)";
      listitems[i].style.color = "black";
    }

    heading.innerText = itemselected;
    e.currentTarget.style.backgroundColor = "#fe5631";
    e.currentTarget.style.color = "white";
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
    e.currentTarget.parentElement.style.pointerEvents = "none";
    if (currentselectedid === "gender") {
      setGender(itemselected);
    }
  };

  const handleselectbox = (e) => {
    const list = e.currentTarget.nextSibling;
    if (list.style.opacity === "0" || list.style.opacity === "0.5") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      list.style.pointerEvents = "auto";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "hidden";
      list.style.transform = "scale(.5)";
      list.style.pointerEvents = "none";
    }
  };

  const [agreed, setagreed] = useState(false);

  const handleagreed = (val) => {
    setagreed(val);
  };

  return (
    <div className="edit-profile-container">
      <div className="Edit-profile">
        <button
          style={{
            opacity: agreed ? 1 : 0.5,
            pointerEvents: agreed ? "auto" : "none",
          }}
          onClick={onSubmit}
          className="edit-profile-btn"
        >
          Save Changes
        </button>
        <div className="details-container">
          <p className="details-title">Personal Details</p>
          <div className="detail">
            <label className="input-title">
              Property Owner Name <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              defaultValue={PropertyOwnerName}
              onChange={onHabdlePropertyOwnerName}
              className="text-input"
            />
          </div>
          <div className="detail">
            <label className="input-title">
              Date of Birth <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              type="Date"
              defaultValue={DOB}
              onChange={onHandleDOBChange}
              className="text-input"
            />
          </div>
          <div className="detail">
            <label className="input-title">
              Gender <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <ul id="Gender" className="selectbox-container">
              <li onClick={handleselectbox} className="heading">
                {Gender ? <p>{Gender}</p> : <p> Select Gender</p>}
                <span className="material-icons">expand_more</span>
              </li>
              <ul
                style={{
                  opacity: 0,
                }}
                id="gender"
                className="select-box-list"
              >
                <li onClick={handlelistitem}>Male</li>
                <li onClick={handlelistitem}>Female</li>
              </ul>
            </ul>
          </div>

          <div
            style={{
              alignItems: "normal",
            }}
            className="detail"
          >
            <label className="input-title">
              Languages Known <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <div className="detail-items-to-select">
              <ul id="languages" className="detail-items">
                <li id="language" className="item">
                  <p className="item-name">Assamese</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Assamese}
                    onChange={onHandleAssameseChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Bengali</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Bengali}
                    onChange={onHandleBengaliChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">English</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={English}
                    onChange={onHandleEnglishChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Gujarati</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Gujarati}
                    onChange={onHandleGujaratiChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Hindi</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Hindi}
                    onChange={onHandleHindiChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Kannada</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Kannada}
                    onChange={onHandleKannadaChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Malayalam</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Malayalam}
                    onChange={onHandleMalayalamChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Marathi</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Marathi}
                    onChange={onHandleMarathiChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Odia</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Odia}
                    onChange={onHandleOdiaChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Punjabi</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Punjabi}
                    onChange={onHandlePunjabiChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Tamil</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Tamil}
                    onChange={onHandleTamilChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Telugu</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Telugu}
                    onChange={onHandleTeluguChange}
                  />
                </li>
                <li id="language" className="item">
                  <p className="item-name">Urdu</p>
                  <input
                    className="checkbox-input"
                    type={"checkbox"}
                    defaultChecked={Urdu}
                    onChange={onHandleUrduChange}
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className="detail">
            <label className="input-title">
              Alternative Email ID <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              type="text"
              defaultValue={AlternativeEmail}
              onChange={onAlternativeEmailChange}
              className="text-input"
            />
          </div>

          <div className="detail">
            <label className="input-title">
              Alternative Phone Number <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              type="text"
              defaultValue={AlternativePhoneNumber}
              onChange={onAlternativePhoneNumberChange}
              className="text-input"
            />
          </div>

          <div className="detail">
            <label className="input-title">
              WhatsApp Number <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              type="text"
              defaultValue={WhatsAppNumber}
              onChange={onPersonalWhatsAppChange}
              className="text-input"
            />
          </div>
          {/* <div className="detail">
            <label className="input-title">
              Aadhaar Card No. <b style={{ color: "red" }}>*</b> :{" "}
            </label>
            <input
              defaultValue={Adhaar}
              onChange={onAdhaarNoChange}
              id="Aadhaar Card No."
              className="text-input"
              type={"text"}
            />
          </div> */}
        </div>
        <Declaration agreed={handleagreed} user={"PO"} />
      </div>
    
    </div>
  );
}

export default PO_EditProfile;
