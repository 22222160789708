import React from 'react'
import "./SignInRequired.css"

const SignInRequired = (props) => {
  return (
    // <div className='sign-in-required-modall-container'>
        <div className='sign-in-required-modall'>
            <h1>{props.title}</h1>
            {props.ShowButton ? 
            <a href={props.directto}>{props.ButtonTitle}</a>
            :
            null}
        </div>
    // </div>
  )
}

export default SignInRequired