const languages = [
  "Assamese",
  "Gujarati",
  "Malayalam",
  "Punjabi",
  "Urdu",
  "Bengali",
  "Hindi",
  "Marathi",
  "Tamil",
  "English",
  "Kannada",
  "Odia",
  "Telugu",
];

const Location = {
  States: ["Delhi", "Gujarat", "Karnataka", "Maharashtra", "National Capital Region (NCR)", "Tamil Nadu", "Telangana", "West Bengal"],
  Cities: [
    {
      State: "Karnataka",
      Cities: ["Bangalore"],
    },
    {
      State: "Gujarat",
      Cities: ["Ahmedabad"],
    },
    {
      State: "Tamil Nadu",
      Cities: ["Chennai"],
    },
    {
      State: "Delhi",
      Cities: ["Delhi"],
    },
    {
      State: "National Capital Region (NCR)",
      Cities: ["Faridabad", "Ghaziabad", "Greater Noida", "Gurgaon", "Meerut", "Noida", "Panipat", "Sonipat"],
    },
    {
      State: "Telangana",
      Cities: ["Hyderabad"],
    },
    {
      State: "West Bengal",
      Cities: ["Kolkata"],
    },
    {
      State: "Maharashtra",
      Cities: ["Mumbai", "Pune"],
    },
  ],
}

const ServicesProvided = {
  InitialConsultation: ["Company Office", "Site"],
  SoftwareUsed: [
    "Interior Design",
    "Architectural Construction",
    "Civil Engineering",
  ],
  DesignSchematics: [
    "Plans",
    "Drawings / Sketches ",
    "Designs",
    "Consultation",
    "Elevations",
    "Renders",
    "Walk Through ",
  ],
  BookingConfirmation: "BookingConfirmation",
  LegalAgreements: "LegalAgreements",
  ProjectTimeframe: "ProjectTimeframe",
  Execution: "Execution",
  TypesOfPropertiesServed: {
    Residential: [
      "Apartment",
      "Penthouse",
      "Stand Alone building",
      "Independent Home",
      "Villa",
      "Bungalow",
    ],
    Commercial: [
      "Retail",
      "Hospitality - (Accommodation, Food & Beverage, Travel & Tourism)",
      "Healthcare",
      "Office - Corporate & Tech",
      "Industrial",
      "Retail",
      "Retail",
    ],
    Institutional: [
      "Educational Sector",
      "Financial Sector",
      "Media & Entertainment Sector",
      "Research & Development Sector",
      "Transportation Sector",
    ],
  },
  InteriorDesignExecutionServices: [
    "Hard Finishes",
    "Furniture, Fixtures & Equipment",
    "Soft Finishes",
    "Art & Décor",
    "Eco – Friendly",
    "Tech – Savvy ",
    "Disabled / Handicap – Accessible Designs & Services ",
    "Health & Safety ",
    "Security Systems ",
  ],
  ArchitecturalConstructionExecutionServices: {
    GreyStructure: [
      "Soil Testing",
      "Site Clearance",
      "Site Layout",
      "Substructure Construction Phase",
      "Super Structure Construction Phase",
    ],
    DisabledHandicapAccessibleDesignsServices:
      "Disabled / Handicap – Accessible Designs & Services ",
    HealthSafety: "Health & Safety",
    SecuritySystems: "Security Systems ",
    Finishing: "Finishing",
  },
  CivilRenovationServices: [
    "Same as the above selected Interior Design Execution Services",
    "Same as the above selected Interior Design Execution Services",
    "Services Selected",
  ],
  OtherServices: [
    "Assembling Furniture",
    "Heavy Item Lifting and Moving",
    "Household Chores",
    "Packers and Movers",
    "Pest Control",
  ],
  ProductsAccessories: [
    "Appliances",
    "Artwork",
    "Décor",
    "Furniture",
    "Hardware",
    "Mirrors",
    "Modular / Customized Furniture ",
    "Painting",
    "Upholstery",
    "Wallpapers",
  ],
  FinalReviewHandover: [
    "Final Building Inspection (C.B.)",
    "Confirmation of Final Payment Received",
    "Documentation",
    "Handover",
    "Photoshoot & Videography",
  ],
  AfterSalesSupport: {
    WarrantyCertification: "Warranty Certification",
  },
  DesignSpecialization: {
    InteriorDesignStyles: [
      "Asian",
      "Beach / Nautical",
      "Contemporary Home Décor ",
      "Craftsman",
      "Eclectic",
      "Eco - Friendly ",
      "Farmhouse",
      "Hollywood Glam",
      "Industrial",
      "Mediterranean",
      "Mid – Century Modern",
      "Minimalist",
      "Modern",
      "Rustic",
      "Scandinavian",
      "Shabby – Chic",
      "Southwestern",
      "Traditional",
      "Transitional",
      "Tropical",
    ],
    ArchitecturalStyles: [
      "Art Deco",
      "Bauhaus",
      "Constructivism (Art)",
      "Constructivist Architecture",
      "Contemporary Architecture",
      "Eco - Friendly",
      "Functionalism",
      "Futurist Architecture",
      "High - Tech Architecture",
      "Industrial Architecture",
      "International Style",
      "Mid - Century Modern",
      "Minimalism",
      "Modern Architecture",
      "New Objectivity",
      "Organic Architecture",
      "Postmodern Architecture ",
      "Ranch - Style House",
      "Streamline Modern ",
      "Sustainable Architecture",
    ],
  },
};
module.exports.languages = languages;
module.exports.ServicesProvided = ServicesProvided;
module.exports.Location = Location;
