import React from "react";
import "./Utils.css";

function LeadsRejected(props) {

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2}>
            Sl. No.{" "}
          </td>
          <td className="heading" rowSpan={2}>
            Lead Selection Order ID{" "}
          </td>
          <td className="heading" colSpan={2}>
            Property Owner
          </td>
          <td className="heading" colSpan={2}>
            Property Requirement{" "}
          </td>
          <td className="heading" rowSpan={2}>
            {props.dataandtimeheading ? props.dataandtimeheading : null}
          </td>
          
            {props.thisleadwas ?<td className="heading" rowSpan={2} colSpan={1}>This lead was</td> : null}
        
          <td className="heading" rowSpan={2}>
            State
          </td>
          <td className="heading" rowSpan={2}>
            City
          </td>
          {/* <td className="heading" colSpan={2}>
            Download
          </td> */}
        </tr>
        <tr>
          <td className="subheading">Profile Number</td>
          <td className="subheading">Name</td>
          <td className="subheading">Property Number</td>
          <td className="subheading">Property Name</td>
          {/* <td className="subheading">Invoice</td> */}
          {/* <td className="subheading">Lead Details</td> */}
        </tr>
        {props.LeadsDetails.map((listanddetail, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {/* <td>{listanddetail.id}</td> */}
              <td>{listanddetail.OrderID}</td>
              {/* <td>{listanddetail.writer}</td> */}
              <td>
                <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.writer}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>View Details</a>
              </td>
              <td>
              <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.writer}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
              {listanddetail.name}
              </a>
              </td>
              <td>
              <a href={`/ServiceProfessional/PropertyRequirementsDetails/${listanddetail.id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
              {listanddetail.id}
              </a>
              </td>
              <td>{listanddetail.Land_Building}</td>
              <td>{listanddetail.timeOfPurchase}</td>
              <td style={{ color: "red" }}>{listanddetail.LeadStatus}</td>
              <td>{listanddetail.State}</td>
              <td>{listanddetail.City}</td>
              {/* <td>Download</td> */}
              {/* <td>Download</td> */}
            </tr>
          );
        })}{" "}
      </table>
    </div>
  );
}

export default LeadsRejected;