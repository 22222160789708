import React from "react";
import { withRouter } from "react-router-dom";
import "./Utils.css";

function POProfileVisitedDetails(props) {
    // console.log("PO Profile data ", props.ProfileDatas);
  // const listanddetails = [
  //   {
  //     SP: {
  //       ProfileName: "Lokesh Sharma",
  //       Name: "Lokesh",
  //     },
  //     Profession: "Engineer",
  //     CompanyName: "Web Projects",
  //     DateandTime: "19/02/2022",
  //   },
  //   {
  //     SP: {
  //       ProfileName: "Harsh Sharma",
  //       Name: "Harsh",
  //     },
  //     Profession: "Architect",
  //     CompanyName: "Web Projects",
  //     DateandTime: "19/02/2022",
  //   },
  // ];

  function DataToString(Value) {
    var d = new Date(Value);
    return d.toString('YYYY-MM-dd');
  }

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2}>
            Sl. No.{" "}
          </td>
          <td className="heading" colSpan={2}>
          {props.Identity === "PO" ? "Property Owner" :  null}
          {props.Identity === "SP" ? "Service Professional" :  null}
          </td>
          {props.Identity === "SP" ? (
            <td className="heading" rowSpan={2}>
              Profession
            </td>
          ) : null}
          {props.Identity === "SP" ? (
            <td className="heading" rowSpan={2}>
              Company Name{" "}
            </td>
          ) : null}
          <td className="heading" rowSpan={2}>
            {props.dataandtimeheading}
          </td>
        </tr>
        <tr>
          <td className="subheading">Profile Number</td>
          <td className="subheading">Name</td>
        </tr>
        {props.ProfileDatas.map((listanddetail, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
              <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.Visited_To_PO_Id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>View Details</a>
              </td>
              <td>
              <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.Visited_To_PO_Id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                {listanddetail.Visited_To_PO_Name}
              </a>
                </td>
              {/* {props.Identity === "SP" ? <td>{listanddetail.Profession}</td> : null}
              {props.Identity === "SP" ? <td>{listanddetail.CompanyName}</td> : null} */}
              <td>{DataToString(listanddetail.Visited_Date)}</td>
            </tr>
          );
        })}{" "}
      </table>
    </div>
  );
}

export default withRouter(POProfileVisitedDetails);