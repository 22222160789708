import React, { useEffect, useLayoutEffect, useState } from "react";
import "./TransactionTable.css";
import { table } from "./Data";
// import file from "./file.docx";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
// import DownloadPlan from "./DownloadPlan";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
// import Invoice from "../PlansInvoice/PlansInvoice";
import PlansInvoice from "../PlansInvoice/PlansInvoice";
import MembershipDisclaimer from "../MembershipDescription/MembershipDisclaimer";

function TransactionTable() {
  const company = useSelector((state) => state.company);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [primeCount, setprimeCount] = useState(0);
  const [nonprimeCount, setnonprimeCount] = useState(0);
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [invoicedetail, setinvoicedetail] = useState({
    InvoicePaymentId: "",
    PurchaseOrderId: "",
    DateandTimeOfPayment: "",
    SubscribedPlanDetails: {
      PlanName: "",
      PlanValidity: "",
      ExclusiveofGST: "",
      DiscountedExclusiveOfGST: "",
      GstAmount: "",
      InsclusiveOfGST: "",
      NqaReplacement: "",
      NonPrimeSavings: "",
      PrimeSavings: "",
      TotalSavings: "",
      // PlanExipres: "",
      PlanStatus: "",
    },
    PaymentStatus: "",
    PaymentMode: "",
  });

  // var detailss = {
  //   InvoicePaymentId: "123",
  // };

  const setdata = async (e) => {
    const details = e.currentTarget.parentElement.parentElement.childNodes;

    details.forEach((element) => {
      const content = element.innerText;
      // console.log(element.id)
      setinvoicedetail((prev) => {
        return {
          InvoicePaymentId: element.id === "invoiceid" ? content : prev.InvoicePaymentId,
          PurchaseOrderId: element.id === "purchaseorderid" ? content : prev.PurchaseOrderId,
          DateandTimeOfPayment: element.id === "dateandtime" ? content : prev.DateandTimeOfPayment,

          SubscribedPlanDetails: {
            PlanName: element.id === "planname" ? content : prev.SubscribedPlanDetails.PlanName,
            PlanValidity: element.id === "planvalidity" ? content : prev.SubscribedPlanDetails.PlanValidity,
            ExclusiveofGST: element.id === "exclusiveofgst" ? content : prev.SubscribedPlanDetails.ExclusiveofGST,
            DiscountedExclusiveOfGST: element.id === "discountexclusiveofgst" ? content : prev.SubscribedPlanDetails.DiscountedExclusiveOfGST,
            InsclusiveOfGST: element.id === "inclusiveofgst" ? content : prev.SubscribedPlanDetails.InsclusiveOfGST,
            GstAmount: element.id === "gstamountpermonth" ? content : prev.SubscribedPlanDetails.GstAmount,
            NqaReplacement: element.id === "nqareplacement" ? content : prev.SubscribedPlanDetails.NqaReplacement,
            NonPrimeSavings: element.id === "nonprimesaving" ? content : prev.SubscribedPlanDetails.NonPrimeSavings,
            PrimeSavings: element.id === "primesaving" ? content : prev.SubscribedPlanDetails.PrimeSavings,
            TotalSavings: element.id === "totalsaving" ? content : prev.SubscribedPlanDetails.TotalSavings,
            // PlanExipres: element.id === "planexipres" ? content : prev.SubscribedPlanDetails.PlanExipres,
            PlanStatus: element.id === "planstatus" ? content : prev.SubscribedPlanDetails.PlanStatus,
          },
          PaymentMode: element.id === "paymentmode" ? content : prev.PaymentMode,
          PaymentStatus: element.id === "paymentstatus" ? content : prev.PaymentStatus,
        };
      });
    });
  };

  const handledownloadpdf = (e) => {
    setdata(e).then(() => {
      const invoicecontainer = document.getElementById("plans-invoice-container");
      invoicecontainer.style.display = "block";
      html2canvas(document.getElementById("plans-invoice-container"), {
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);

        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
        invoicecontainer.style.display = "none";
      });
    })


  };

  useEffect(() => {
    // console.log(invoicedetail);
    let pv=0;
    let npv=0;
    company.companyData.Subscription_History.map((index) => {
      if(index.Subscription_For === "Baby Bear" || index.Subscription_For === "Startup Tiger" || index.Subscription_For === "Elite Tiger" || index.Subscription_For === "Classic Tiger" || index.Subscription_For === "Baby Tiger"){
        npv = npv + 1; 
      }
      if(index.Subscription_For === "Baby Bear Prime" || index.Subscription_For === "Startup Tiger Prime" || index.Subscription_For === "Elite Tiger Prime" || index.Subscription_For === "Classic Tiger Prime" || index.Subscription_For === "Baby Tiger Prime"){
        pv = pv + 1;
      }
    })
    setprimeCount(pv);
    setnonprimeCount(npv);
  }, [invoicedetail]);

  function DateFunctionCall(Subscribed_On_Date, Subscription_For) {
    let Time_Till = 0;
    if (Subscription_For === "Baby Bear") {
      Time_Till = 30;
    }
    if (Subscription_For === "Baby Tiger") {
      Time_Till = 30;
    }
    if (Subscription_For === "Papa Bear") {
      Time_Till = 90;
    }
    if (Subscription_For === "Mama Bear") {
      Time_Till = 180;
    }
    if (Subscription_For === "Teddy Bear") {
      Time_Till = 365;
    }

    var date = new Date(Subscribed_On_Date);
    date.setDate(date.getDate() + Time_Till);

    // console.log(date);

    return date.toLocaleString();
  }

  // function PlanStatusFunction(Subscribed_On_Date, Subscription_For) {
  //   let Time_Till = 0;
  //   if (Subscription_For === "Baby Bear") {
  //     Time_Till = 30;
  //   }
  //   if (Subscription_For === "Papa Bear") {
  //     Time_Till = 90;
  //   }
  //   if (Subscription_For === "Mama Bear") {
  //     Time_Till = 180;
  //   }
  //   if (Subscription_For === "Teddy Bear") {
  //     Time_Till = 365;
  //   }
  //   var date = new Date(Subscribed_On_Date);
  //   date.setDate(date.getDate() + Time_Till);
  //   var Subscribed_End = date.toLocaleDateString();
  //   // console.log("Subscription Expires on date ", date.toLocaleDateString());

  //   var Current_Date = new Date();

  //   var PlanStatus = "";

  //   if (Current_Date.getTime() < date.getTime()) {
  //     // console.log("Greater");
  //     PlanStatus = "Active"
  //   } else {
  //     // console.log("Lesser");
  //     PlanStatus = "Expired"
  //   }

  //   // console.log(date.getTime() + " vs " + Current_Date.getTime());

  //   return PlanStatus;
  // }

  if (company.companyData && company.companyData.isAuth) {
    // console.log(company.companyData.Subscription_History);
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '25px', fontWeight: 'bold' }}>My Plans</p>
        </div>
        <div
        className="MyPlan-container"
        >
          <div className="myplan-transaction-table">
            <table id="tablee">
              <tr className="table-titles">
                {table.titles.map((title, index) => {
                  return (
                    <th
                      key={index}
                      colSpan={title.colspan}
                      rowSpan={title.rowspan}
                    >
                      {title.Name}
                    </th>
                  );
                })}
              </tr>
              <tr className="table-sub-titles">
                {table.subtitles.map((subtitle, index) => {
                  return <td key={index}>{subtitle}</td>;
                })}
              </tr>

              {company.companyData.Subscription_History.map((data, index) => {
                return (
                  <tr className="transaction-data">
                    <td>{index + 1}</td>
                    <td id="invoiceid">{!data.PaymentID ? '-' : data.PaymentID}</td>
                    <td id="purchaseorderid">{!data.OrderID ? '-' : data.OrderID}</td>
                    <td id="dateandtime">{data.Subscribed_On_Date} - {data.Subscribed_On_Time}</td>
                    <td id="planname">{data.Subscription_For}</td>
                    <td id="planvalidity">{!data.Validity ? '-' : data.Validity}</td>
                    <td id="exclusiveofgst" style={{color: 'red'}}><del>{!data.OriginalExclusiveOfGST ? '-' : data.OriginalExclusiveOfGST}</del></td>
                    <td id="discountexclusiveofgst">{!data.DiscountedExclusiveOfGST ? '-' : data.DiscountedExclusiveOfGST}</td>
                    <td id="gstamountpermonth">{data.GSTPerMonth}</td>
                    <td id="inclusiveofgst">{!data.InsclusiveOfGST ? '-' : data.InsclusiveOfGST}</td>
                    <td id="nqareplacement" style={{ color: 'green' }}>{data.Subscription_For === "Startup Tiger" ? 3370 : data.Subscription_For === "Elite Tiger" ? 6740 : data.Subscription_For === "Classic Tiger" ? 10110 : data.Subscription_For === "Baby Tiger" ? 13480 : data.Subscription_For === "Startup Tiger Prime" ? 2995 : data.Subscription_For === "Elite Tiger Prime" ? 5990 : data.Subscription_For === "Classic Tiger Prime" ? 8985 : data.Subscription_For === "Baby Tiger Prime" ? 11980 : 0 }</td>
                    <td id="nonprimesaving" style={{ color: 'green' }}>{!data.NonPrimeSavings ? '0' : data.NonPrimeSavings}</td>
                    <td id="primesaving" style={{ color: 'green' }}>{!data.PrimeSavings ? '0' : data.PrimeSavings}</td>
                    <td id="totalsaving" style={{ color: 'green' }}>{!data.TotalSavings ? '-' : data.TotalSavings}</td>
                    {/* <td id="planexipres">{!data.Plan_Expire ? '-' : data.Plan_Expire}</td> */}
                    <td id="planstatus">{!data.PlanStatus ? '-' : data.PlanStatus}</td>
                    <td id="paymentstatus">{data.Success ? <p style={{ color: 'green' }}>Completed Successfully</p> : <p style={{ color: 'red' }}>Failed</p> }</td>
                    <td id="paymentmode">Online</td>
                    <td><p onClick={handledownloadpdf}><span className="material-icons">download</span></p></td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="invoice">
          {<PlansInvoice InvoiceDetails={invoicedetail} />}
        </div>
          <MembershipDisclaimer page="MyPlans" amount={company.companyData.Membership ? primeCount*300 : nonprimeCount*300} />
      </>
    );
  } else {
    return <LoadingIcon />;
  }
}
export default TransactionTable;
