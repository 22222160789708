import React, { useState } from "react";
import CompanyLogo from "./CompanyLogo/CompanyLogo";
import ProfileImage from "./ProfileImage/ProfileImage";
import "./ProfileImgandCompanyLogo.css";
import ViewImg from "./ViewImg/ViewImg";

export default function ProfileImgandCompanyLogo(props) {
  const [viewclicked, setviewclicked] = useState(false);
  const [viewimg, setviewimg] = useState("");

  const handleviewclick = (e) => {
    // console.log(e.target.innerText);
    const clickedelement = e.target.innerText;
    const clickedelementcontainer = e.target.parentElement.className;
    if (clickedelement === "open_in_full") {
      if (
        clickedelementcontainer === "profile-img-options" ||
        clickedelementcontainer === "company-logo-options"
      ) {
        setviewimg(e.target.parentElement.parentElement.childNodes[0].src);
        setviewclicked(true);
      }
    }
  };

  const handlecloseview = (e) => {
    setviewclicked(!viewclicked);
  };

  return (
    <div className="profile-img-and-company-logo-container">
      <ViewImg
        transform={viewclicked ? "scale(1)" : "scale(0)"}
        content={viewimg}
        handlecloseview={handlecloseview}
      />
      <div className="profile-img-and-company-logo" onClick={handleviewclick}>
        <ProfileImage nochange={props.nochange} Image_Src={props.ProfileImgSrc} />
        {props.companylogo ? (
          <CompanyLogo nochange={props.nochange} Image_Src={props.CompanyImgSrc} />
        ) : null}
        <div className="sp-details">
          <h1 className="company-name">
            {/* {props.companyDetails.Company_Details.Company_Name}{" "} */}
            {props.companyDetails.name}
          </h1>
          <div className="architect-name-and-profession">
            <p className="architect-name">
              {props.companyDetails.Professional_Details.Professional_Profession}
              </p>
            {props.SP ? <p className="border"></p> : null}
            {props.SP ? (
              <p className="profession">
                {props.companyDetails.Company_Details.Company_Name}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
