import React, { useEffect, useLayoutEffect, useState } from "react";
import "./UserProfile.css";
// import { languages } from "./Data";
import LoadingIcon from "../../../../utils/LoadingIcon/LoadingIcon";
import { useDispatch } from "react-redux";
import { updateuserprofile } from "../../../../../_actions/user_actions";
import Declaration from "../../../../utils/Declaration/Declaration";
// import VerifyNoModal from "../../../../utils/VerifyNoModal/VerifyNoModal";
// import { useSelector } from 'react-redux'

function UserProfileofOwner(props) {
  // const user = useSelector(state => state.user)
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  // const [verfied, setverified] = useState(props.userData.Verified_PhoneNumber);
  // phonenumber
  const verfied = props.userData.Verified_PhoneNumber;

  useEffect(() => {
    if (verfied) {
      document.getElementById("phn-number").disabled = true;
      document.getElementById("phn-number").style.opacity = 0.7;
    }
  }, [verfied]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);
  const [Property_Owner_Name, setProperty_Owner_Name] = useState(
    props.userData.name
  );
  const [PhoneNumberEntered, setPhoneNumberEntered] = useState("");
  const [DOB, setDOB] = useState("");
  const [Gender, setGender] = useState("");
  // const [LanguagesKnown, setLanguagesKnown] = useState([]);
  const LanguagesKnown = [];

  const [assamese, setassamese] = useState(false);
  const [gujarati, setgujarati] = useState(false);
  const [malayalam, setmalayalam] = useState(false);
  const [punjabi, setpunjabi] = useState(false);
  const [urdu, seturdu] = useState(false);
  const [bengali, setbengali] = useState(false);
  const [hindi, sethindi] = useState(false);
  const [marathi, setmarathi] = useState(false);
  const [tamil, settamil] = useState(false);
  const [english, setenglish] = useState(false);
  const [kannada, setkannada] = useState(false);
  const [odia, setodia] = useState(false);
  const [telugu, settelugu] = useState(false);

  const [AlternativeEmail, setAlternativeEmail] = useState("");
  const [AlternativeNumber, setAlternativeNumber] = useState("");
  const [WhatsAppNumber, setWhatsAppNumber] = useState("");

  const [submitclicked, setsubmitclicked] = useState(false);
  const [modalclose, setmodalclose] = useState(false);

  // const [ShowModal, setShowModal] = useState(false);
  // const [EnteredOTP, setEnteredOTP] = useState(null);

  const Details = {
    Property_Owner_Name,
    DOB,
    Gender,
    LanguagesKnown,
    AlternativeEmail,
    AlternativeNumber,
    WhatsAppNumber,
    PhoneNumberEntered,
  };

  const onNameChange = (e) => {
    setProperty_Owner_Name(e.currentTarget.value);
  };

  const onDOBChange = (e) => {
    setDOB(e.currentTarget.value);
  };

  const onGenderChange = (e) => {
    // console.log("Gender Target Value ", e.target.value);
    setGender(e.target.value);
  };

  const handleassamese = () => {
    // console.log(!assamese);
    setassamese(!assamese);
  };

  const handlegujarati = () => {
    // console.log(!gujarati);
    setgujarati(!gujarati);
  };

  const handlemalayalam = () => {
    // console.log(!malayalam);
    setmalayalam(!malayalam);
  };
  const handlepunjabi = () => {
    // console.log(!punjabi);
    setpunjabi(!punjabi);
  };

  const handleurdu = () => {
    // console.log(!urdu);
    seturdu(!urdu);
  };

  const handlebengali = () => {
    // console.log(!bengali);
    setbengali(!bengali);
  };

  const handlehindi = () => {
    // console.log(!hindi);
    sethindi(!hindi);
  };
  const handlemarathi = () => {
    // console.log(!marathi);
    setmarathi(!marathi);
  };

  const handletamil = () => {
    // console.log(!tamil);
    settamil(!tamil);
  };

  const handleenglish = () => {
    // console.log(!english);
    setenglish(!english);
  };

  const handlekannada = () => {
    // console.log(!kannada);
    setkannada(!kannada);
  };

  const handleodia = () => {
    // console.log(!odia);
    setodia(!odia);
  };

  const handletelugu = () => {
    // console.log(!telugu);
    settelugu(!telugu);
  };

  // const handlelanguages = (e) => {
  //   e.persist();
  //   if (e.target.checked) {
  //     setLanguagesKnown((prev) => {
  //       return [...prev, e.target.value];
  //     });
  //   } else {
  //     setLanguagesKnown(
  //       LanguagesKnown.filter((language) => language !== e.target.value)
  //     );
  //   }
  // };
  const onAlternativeEmailChange = (e) => {
    // console.log("Alternative Email = ", e.currentTarget.value);
    setAlternativeEmail(e.currentTarget.value);
  };

  const onAlternativeNumberChange = (e) => {
    // console.log("ALternative Change = ", e.currentTarget.value);
    setAlternativeNumber(e.currentTarget.value);
  };

  const onWhatsAppNumberChange = (e) => {
    // console.log("WhatsApp = ", e.currentTarget.value);
    setWhatsAppNumber(e.currentTarget.value);
  };

  const onChangeNumberEntered = (e) => {
    // console.log(e.target.value);
    setPhoneNumberEntered(e.target.value);
  };

  // const onHandleNumberEntered = () => {
  //   console.log("Submit Number ", PhoneNumberEntered);
  //   let variable = {
  //     number: PhoneNumberEntered,
  //   };
  //   setShowModal(true);
  //   dispatch(UserOTP_PhoneNumber(variable)).then((response) => {
  //     console.log("Response ", response);
  //   });
  // };

  // const onhandleOTPEnteredValue = (e) => {
  //   console.log(e.target.value);
  //   setEnteredOTP(e.target.value);
  // };

  // const OnHandleVerifyOTP = () => {
  //   console.log("Entered OTP ", EnteredOTP);
  //   let variable = {
  //     OTP: EnteredOTP,
  //     number: PhoneNumberEntered,
  //   };
  //   dispatch(UserOTP_VerifyNumberCall(variable)).then((response) => {
  //     console.log("Response", response);
  //     if (response.payload.success) {
  //       setShowModal(false);
  //       setverified(true);
  //     }
  //   });
  // };

  const onSubmit = (e) => {
    // console.log("Submitted");
    e.preventDefault();
    const dataToSubmit = {
      Property_Owner_Name: Details.Property_Owner_Name,
      DOB: DOB,
      Gender: Gender,
      Languages_Known: {
        Assamese: assamese,
        Bengali: bengali,
        English: english,
        Gujarati: gujarati,
        Hindi: hindi,
        Kannada: kannada,
        Malayalam: malayalam,
        Marathi: marathi,
        Odia: odia,
        Punjabi: punjabi,
        Tamil: tamil,
        Telugu: telugu,
        Urdu: urdu,
      },
      PhoneNumberEntered: PhoneNumberEntered,
      AlternativeEmail: AlternativeEmail,
      AlternativeNumber: AlternativeNumber,
      WhatsAppNumber: WhatsAppNumber,
    };
    // console.log(dataToSubmit);
    dispatch(updateuserprofile(dataToSubmit)).then((response) => {
      if (response.payload.success) {
        setsubmitclicked(true);
        setmodalclose(true);
        setsubmitclicked(false);
      } else {
        alert("Failed To Update Info. Please try again later !!!");
      }
    });

    // if (Property_Owner_Name) {
    //   setsubmitclicked(true);
    //   setTimeout(() => {
    //     setmodalclose(true);
    //     setsubmitclicked(false);
    //   }, 2000);
    // } else {
    //   console.log("no");
    // }
  };

  const handleselect = (e) => {
    const list = e.currentTarget.nextSibling;

    if (list.style.opacity === "0") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      e.currentTarget.childNodes[1].innerText = "expand_less";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "hidden";
      list.style.transform = "scale(.5)";
      e.currentTarget.childNodes[1].innerText = "expand_more";
    }
  };

  const handlelistitem = (e) => {
    // const currentlistid = e.currentTarget.parentElement.id;

    const currentselected = e.currentTarget.childNodes[0].innerText;
    const heading =
      e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    heading.innerText = currentselected;
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
  };

  const [DeclarationAgreed, setDeclarationAgreed] = useState(false)

  return (
    <div
      style={{
        transform: modalclose ? "scale(0)" : "scale(1)",
        opacity: modalclose ? 0 : 1,
      }}
      className="user-profile-owner-container"
    >
      <div
        className="form"
        style={{
          width: screenwidth <= 600 ? screenwidth - 30 + "px" : null,
        }}
        // onSubmit={onSubmit}
      >
        <h1>Complete Your Profile</h1>

        <div className="details-to-fill">
          <div>
            <label>Name : </label>
            <input
              placeholder="Please Enter the Name"
              value={Property_Owner_Name}
              onChange={onNameChange}
            />
          </div>

          <div>
            <label>Phone Number : </label>
            <input
              id="phn-number"
              type="Number"
              onChange={onChangeNumberEntered}
              // defaultValue={verfied ? props.userData.phonenumber : null}
            />
            {/* <button
              style={{
                backgroundColor: verfied ? "#1dc295" : null,
                pointerEvents: verfied ? "none" : "auto"
              }}
              className="verify-phn-no"
              onClick={onHandleNumberEntered}
            >
              {verfied ? "Verified" : "Verify Number"}
            </button> */}
          </div>

          <div>
            <label>Date of Birth : </label>
            <input type="date" value={DOB} onChange={onDOBChange} />
          </div>

          <div>
            <label>Gender : </label>
            <ul className="select-box">
              <li onClick={handleselect} className="heading">
                <p className="heading-title">Select Gender</p>
                <span className="material-icons">expand_more</span>
              </li>
              <ul
                style={{
                  opacity: 0,
                  width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                }}
                id="gender"
                className="select-box-list"
              >
                <li onClick={handlelistitem}>
                  <label className="list-item-name">Male</label>
                  <input
                    onChange={onGenderChange}
                    className="radio"
                    type={"radio"}
                    value={"Male"}
                    name="gender"
                  />
                </li>
                <li onClick={handlelistitem}>
                  <label className="list-item-name">Female</label>
                  <input
                    onChange={onGenderChange}
                    className="radio"
                    name="gender"
                    type={"radio"}
                    value={"Female"}
                  />
                </li>
              </ul>
            </ul>
          </div>

          <div>
            <label>Languages Known :</label>
            <ul className="select-box">
              <li onClick={handleselect} className="heading">
                Select Languages
                <span className="material-icons">expand_more</span>
              </li>
              <ul
                style={{
                  opacity: 0,
                  width: screenwidth <= 600 ? screenwidth - 100 + "px" : null,
                }}
                id="languages"
                className="select-box-list"
              >
                <li>
                  <p className="list-item-name">Assamese</p>
                  <input
                    onChange={handleassamese}
                    type={"checkbox"}
                    value={"Assamese"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Bengali</p>
                  <input
                    onChange={handlebengali}
                    type={"checkbox"}
                    value={"Bengali"}
                  />
                </li>
                <li>
                  <p className="list-item-name">English</p>
                  <input
                    onChange={handleenglish}
                    type={"checkbox"}
                    value={"English"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Gujarati</p>
                  <input
                    onChange={handlegujarati}
                    type={"checkbox"}
                    value={"Gujarati"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Hindi</p>
                  <input
                    onChange={handlehindi}
                    type={"checkbox"}
                    value={"Hindi"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Kannada</p>
                  <input
                    onChange={handlekannada}
                    type={"checkbox"}
                    value={"Kannada"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Malayalam</p>
                  <input
                    onChange={handlemalayalam}
                    type={"checkbox"}
                    value={"Malayalam"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Marathi</p>
                  <input
                    onChange={handlemarathi}
                    type={"checkbox"}
                    value={"Marathi"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Odia</p>
                  <input
                    onChange={handleodia}
                    type={"checkbox"}
                    value={"Odia"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Punjabi</p>
                  <input
                    onChange={handlepunjabi}
                    type={"checkbox"}
                    value={"Punjabi"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Tamil</p>
                  <input
                    onChange={handletamil}
                    type={"checkbox"}
                    value={"Tamil"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Telgu</p>
                  <input
                    onChange={handletelugu}
                    type={"checkbox"}
                    value={"Telgu"}
                  />
                </li>
                <li>
                  <p className="list-item-name">Urdu</p>
                  <input
                    onChange={handleurdu}
                    type={"checkbox"}
                    value={"Urdu"}
                  />
                </li>
              </ul>
            </ul>
          </div>

          <div>
            <label>Alternative Email ID : </label>
            <input
              type="email"
              value={AlternativeEmail}
              onChange={onAlternativeEmailChange}
            />
          </div>

          <div>
            <label>Alternative Phone Number : </label>
            <input
              type="Number"
              value={AlternativeNumber}
              onChange={onAlternativeNumberChange}
            />
          </div>

          <div>
            <label>WhatsApp Number : </label>
            <input
              type="Number"
              value={WhatsAppNumber}
              onChange={onWhatsAppNumberChange}
            />
          </div>
        </div>
<Declaration agreed={(e) => {
setDeclarationAgreed(e)
}} user={"PO"} />
        {submitclicked ? (
          <LoadingIcon
            position="relative"
            width="120px"
            height="45px"
            outerwidth="30px"
            outerheight="30px"
            innerwidth="0"
            innerheight="0"
          />
        ) : (
          <button
            style={{
              opacity:
                Property_Owner_Name && PhoneNumberEntered.length === 10 && DeclarationAgreed
                  ? 1
                  : 0.5,
              pointerEvents:
                Property_Owner_Name && PhoneNumberEntered.length === 10 && DeclarationAgreed
                  ? "auto"
                  : "none",
            }}
            onClick={onSubmit}
            className="submit-btn"
          >
            SAVE & CONTINUE
          </button>
        )}
      </div>
      {/* {ShowModal ? (
        <VerifyNoModal
          onClose={() => {
            setShowModal(false);
          }}
          onChange={onhandleOTPEnteredValue}
          onClick={OnHandleVerifyOTP}
        />
      ) : null} */}
      {/* <Modal
        title="Basic Modal"
        visible={ShowModal}
        zIndex={10000}
        onCancel={()=> setShowModal(false)}
      >

        <input onChange={onhandleOTPEnteredValue} />
        <button onClick={OnHandleVerifyOTP} >Verify OTP</button>
      </Modal> */}
    </div>
  );
}

export default UserProfileofOwner;
