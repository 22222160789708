import Axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { DeleteNewUploadProjectImage } from "../../../../../_actions/project_actions";
import LoadingIcon from "../../../../utils/LoadingIcon/LoadingIcon";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import "./SP_UploadNewProject.css";
import UploadedProjectModal from "./UploadedProjectModal/UploadedProjectModal";

const stateData = [
  "Delhi",
  "Gujarat",
  "Karnataka",
  "Maharashtra",
  "National Capital Region (NCR)",
  "Tamil Nadu",
  "Telangana",
  "West Bengal",
];
const cityData = {
  "--Select--": ["--Select--"],
  Delhi: ["Delhi"],
  Gujarat: ["Ahmedabad"],
  Karnataka: ["Bangalore"],
  Maharashtra: ["Mumbai", "Pune"],
  "National Capital Region (NCR)": [
    "Faridabad",
    "Ghaziabad",
    "Greater Noida",
    "Gurgaon",
    "Meerut",
    "Noida",
    "Panipat",
    "Sonipat",
  ],
  "Tamil Nadu": ["Chennai"],
  Telangana: ["Hyderabad"],
  "West Bengal": ["Kolkata"],
};
function SP_UploadNewProject(props) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);

  const [Images, setImages] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ClientName, setClientName] = useState("");
  const [YearCompletion, setYearCompletion] = useState("");
  const [ValueRupee, setValueRupee] = useState("");
  const [PageLink, setPageLink] = useState("");
  const [Description, setDescription] = useState("");
  const [ProjectServiceRequirement, setProjectServiceRequirement] = useState("");
  const [ProjectTypeCategory1, setProjectTypeCategory1] = useState("");
  const [ProjectTypeCategory2, setProjectTypeCategory2] = useState("");
  const [HouseName, setHouseName] = useState("");
  const [AreaName, setAreaName] = useState("");
  const [ProjectType, setProjectType] = useState("");
  const [Project_Architecture_Style, setProject_Architecture_Style] = useState("");
  const [State, setState] = useState([]);
  const [FinalState, setFinalState] = useState("");
  const [City, setCity] = useState([]);
  const [FinalCity, setFinalCity] = useState("");
  const [savecontinueclicked, setsavecontinueclicked] = useState(false);

  const onDrop = (files) => {
    // console.log("Form", files);
    const config = {
      header: { "content-type": "multipart/form-data" },
    };

    files.map(async (index) => {
      let formData = new FormData();
      formData.append("file", index);
      try {
        const response = await Axios.post(
          "/api/project/uploadProjects",
          formData,
          config
        );
        // console.log(response.data.data.Location);
        setImages((prev) => {
          return [...prev, response.data.data.Location];
        });
      } catch (error) {
        alert("Fail to save the images");
      }
    });
  };

  const onDelete = (image, e) => {
    const imgtoremove = e.currentTarget.parentElement;
    imgtoremove.style.bottom = "100px";
    imgtoremove.style.opacity = "0";
    imgtoremove.style.visibility = "hidden";

    // setTimeout(() => {
    const currentIndex = Images.indexOf(image);
    // let newImages = [...Images];
    // newImages.splice(currentIndex, 1);
    setTimeout(() => {
      setImages(Images.filter((img) => img !== image));
      imgtoremove.style.bottom = "0px";
      imgtoremove.style.opacity = "1";
      imgtoremove.style.visibility = "visible";
    }, 350);
    // console.log("Remove", image);
    // console.log("Image Removed", image.slice(50));
    const variable = {
      imageKey: image.slice(61),
      imageAddress: image,
    };
    dispatch(DeleteNewUploadProjectImage(variable)).then((response) => {
      // console.log("Response After delete", response);
    });
    // }, 300);
  };

  const onProjectNameChange = (e) => {
    // console.log(e.target.value);
    setProjectName(e.target.value);
  };
  const onClientNameChange = (e) => {
    // console.log(e.target.value);
    setClientName(e.target.value);
  };
  const onYearCompletionChange = (e) => {
    // console.log(e.target.value);
    setYearCompletion(e.target.value);
  };
  const onValueRupeeChange = (e) => {
    // console.log(e.target.value);
    setValueRupee(e.target.value);
  };
  const onPageLinkChange = (e) => {
    // console.log(e.target.value);
    setPageLink(e.target.value);
  };
  const onDescriptionChange = (e) => {
    // console.log(e.target.value);
    setDescription(e.target.value);
  };

  const onHouseNameChange = (e) => {
    setHouseName(e.target.value);
  };
  const OnAreaNameChange = (e) => {
    setAreaName(e.target.value);
  };

  const onHandleProjectTypeChange = (e) => {
    // console.log(e.target.value);
    setProjectType(e.target.value);
  };
  const onHandleProjectTypeSubcategort1 = (e) => {
    // console.log(e.target.value);
    setProjectTypeCategory1(e.target.value);
  };
  const onHandleProjectTypeCatrgory2Change = (e) => {
    // console.log(e.target.value);
    setProjectTypeCategory2(e.target.value);
  };
  const onHandleProjectArchitectureStyleChange = (e) => {
    // console.log(e.target.value);
    setProject_Architecture_Style(e.target.value);
  };
  const onHandleStateChange = (e) => {
    // console.log(e.target.value);
    setFinalState(e.target.value);
    setState(e.target.value);
    setCity(cityData[e.target.value]);
  };
  const onHandleCityNameChange = (e) => {
    // console.log(e.target.value);
    setFinalCity(e.target.value);
  };
  const [details, setdetails] = useState();

  const onSubmit = () => {
    // console.log("Image", Images);

    const variables = {
      WriterDetails: {
        Company_Name: company.companyData.Company_Details.Company_Name,
        Service_Professional_Name: company.companyData.name,
      },
      writer: company.companyData._id,
      Project_Name: ProjectName,
      Project_Client_Name: ClientName,
      Project_Completion: YearCompletion,
      Project_Value: ValueRupee,
      Project_Page_Link: PageLink,
      Project_Description: Description,
      Service_Requirement: ProjectServiceRequirement,
      Project_Type: ProjectType,
      Project_Type_Subcategory_1: ProjectTypeCategory1,
      Project_Type_Subcategory_2: ProjectTypeCategory2,
      Project_Architecture_Style: Project_Architecture_Style,
      Address: {
        House_Building_Plot_Land: HouseName,
        Areaname: AreaName,
        State: FinalState,
        City: FinalCity,
      },
      Images: Images,
    };
    // console.log("VARIABLES", variables);
    setdetails(variables);
    // dispatch(UploadNewProject(variables)).then((response) => {
    // if (response.payload.success) {
    // alert("Sucessfully Submitted");
    setsavecontinueclicked(true);
    // } else {
    //   alert("Failed to upload project");
    // }
    // });
  };

  const handelist = (e) => {
    const list = e.currentTarget.nextSibling;
    const heading = e.currentTarget;
    list.style.transform = "scale(1)";
    if (list.style.opacity === "0") {
      list.style.transform = "scale(1)";
      list.style.opacity = "1";
      heading.childNodes[1].innerText = "expand_less";
    } else {
      list.style.transform = "scale(0)";
      list.style.opacity = "0";
      heading.childNodes[1].innerText = "expand_more";
    }
  };

  const handlelistitem = (e) => {
    const list = e.currentTarget.parentElement;
    const listitem = e.currentTarget;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0];

    list.childNodes.forEach((e) => {
      e.style.color = "black";
      e.style.backgroundColor = "rgb(226, 225, 225)";
    });
    heading.childNodes[0].innerText = listitem.innerText;
    list.style.transform = "scale(0)";
    heading.childNodes[1].innerText = "expand_more";
    list.style.opacity = 0;
    listitem.style.color = "white";
    listitem.style.backgroundColor = "#fe5631";

    if (list.id === "project-service-requirement") {
      setProjectServiceRequirement(listitem.innerText);
    }
    if (
      list.id === "interior-project-type" ||
      list.id === "construction-project-type"
    ) {
      setProjectTypeCategory1(listitem.innerText);
    }
    if (
      list.id === "residential-property-type" ||
      list.id === "commercial-property-type"
    ) {
      setProjectTypeCategory2(listitem.innerText);
    }
    if (list.id === "architectural-styles") {
      setProject_Architecture_Style(listitem.innerText);
    }
    if (list.id === "state-list") {
      setFinalState(listitem.innerText);
      setState(listitem.innerText);
      setCity(cityData[listitem.innerText]);
      resetvalues(e);
    }
    if (list.id === "city-list") {
      setFinalCity(listitem.innerText);
    }
  };
  // RESETTING VALUES
  const resetvalues = (e) => {
    const heading =
      e.currentTarget.parentElement.parentElement.parentElement.nextSibling
        .childNodes[1].childNodes[0].childNodes[0];
    heading.innerText = "Select";
    const items = heading.parentElement.nextSibling.childNodes;
    items.forEach((item) => {
      item.style.color = "black";
      item.style.backgroundColor = "rgb(226, 225, 225)";
    });
  };

  const handleclosemodal = (e) => {
    setsavecontinueclicked(false);
  };

  if (company.companyData && company.companyData.isAuth) {
    if(company.companyData && company.companyData.isAuth && !company.companyData.Membership){
        return (
          <div className="sp-upload-project-container">
            <PageBanner title="Upload New Project" />
            <div className="uploadProject-nonmembership-container">
            <div className="uploadProject-nonmembership-container-div1" >
                <p className="uploadProject-nonmembership-container-ptag">You can unlock exclusive benefits by becoming a NebourHood Prime S.P. Member with the following features!</p>
                    <ul className="uploadProject-nonmembership-container-ul">
                        <li>Access to All India Community & Events</li>
                        <li>Early access to Fresh Property Requirement Lead Updates Daily</li>
                        <li>Exclusive Discounts on all property requirement Leads & all property requirement lead Plans</li>
                        <li>Access to Performance Metrics & Business Growth Analytics</li>
                        <li>Exclusive Job Postings & Freelance Opportunities</li>
                        <li>Networking & Collabs with other service professionals</li>
                        <li>Unlock your Prime S.P. Profile on NebourHood</li>
                        <li>Add unlimited Branches to your Prime S.P. Profile to appear in filtered searches from other cities on NebourHood</li>
                        <li>Upload unlimited projects on NebourHood</li>
                        <li>And much more!</li>
                    </ul>
                    <div className="uploadProject-nonmembership-container-div2">
                      <button className="uploadproject-membership-button" onClick={() => props.history.push("/ServiceProfessional/Pricing/Membership")}>Click here to join the NebourHood Prime S.P. Community Now!</button>
                    </div>
              </div>
            </div>
          </div>
        )
    } else {
      return (
        <div className="sp-upload-project-container">
          <PageBanner title="Upload New Project" />
          <UploadedProjectModal
            Images={Images}
            backgroundcolor={
              savecontinueclicked
                ? "rgba(132, 132, 132, 0.5)"
                : "rgba(132, 132, 132, 0)"
            }
            visibility={savecontinueclicked ? "visible" : "hidden"}
            opacity={savecontinueclicked ? 1 : 0}
            transform={savecontinueclicked ? "scale(1)" : "scale(0.5)"}
            handleclosemodal={handleclosemodal}
            details={details}
          />

          <h2 className="upload-project-title">Upload New Project</h2>
          <div className="upload-project-details-container">
            {/* Project Details */}
            <p className="upload-project-details-heading">Project Details</p>
            <div className="upload-project-detail">
              <div className="detail">
                <label className="detail-title">Project Name</label>
                <input
                  className="detail-input"
                  placeholder="Name"
                  onChange={onProjectNameChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">Project Client Name</label>
                <input
                  className="detail-input"
                  placeholder="Client Name"
                  onChange={onClientNameChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">Project Year of Completion</label>
                <input
                  className="detail-input"
                  type="Number"
                  placeholder="Year of Completion"
                  onChange={onYearCompletionChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">
                  Project Value in Indian Rupee (₹)
                </label>
                <input
                  className="detail-input"
                  placeholder="Value in Indian Rupee (Example: 5 lac, 10 lac)"
                  onChange={onValueRupeeChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">
                  Project Page link to Company Website / Company YouTube Channel
                  URL
                </label>
                <input
                  className="detail-input"
                  placeholder="Page link to Company Website / Company YouTube Channel URL"
                  onChange={onPageLinkChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">Project Description</label>
                <textarea
                  row={5}
                  placeholder="Description"
                  onChange={onDescriptionChange}
                />
              </div>
            </div>
            <br />

            {/* Project Service, Type & Style */}

            <p className="upload-project-details-heading">
              Project Service, Type & Style
            </p>
            <div className="upload-project-detail">
              <div className="detail">
                <label className="detail-title">
                  Project Service Requirement
                </label>
                <ul className="detail-select-list-container">
                  <li onClick={handelist} className="selected-heading">
                    <p className="heading">Select</p>
                    <span class="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="project-service-requirement"
                    className="detail-select-list"
                  >
                    <li onClick={handlelistitem}>Only Designs</li>
                    <li onClick={handlelistitem}>Only Execution</li>
                    <li onClick={handlelistitem}>Both - Designs & Execution</li>
                  </ul>
                </ul>
              </div>

              <div className="detail">
                <div className="detail-titles-container">
                  <label className="detail-title">Project Type & Style</label>
                  <label id="project-styles-title" className="detail-title">
                    {ProjectType === "Interior"
                      ? "Project Interior Style"
                      : ProjectType === "Construction"
                      ? "Project Architecture Style"
                      : null}
                  </label>
                </div>

                <br />
                <div className="radio-inputs">
                  <div className="inputs">
                    <input
                      type="radio"
                      name="projecttype"
                      value="Interior"
                      onChange={onHandleProjectTypeChange}
                    />
                    &nbsp; Interior &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      name="projecttype"
                      value="Construction"
                      onChange={onHandleProjectTypeChange}
                    />
                    &nbsp; Construction&nbsp;
                  </div>
                  <div className="select-inputs">
                    {ProjectType === "Interior" ? (
                      <>
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="interior-project-type"
                            className="detail-select-list"
                          >
                            <li onClick={handlelistitem}>Residential</li>
                            <li onClick={handlelistitem}>Commercial</li>
                          </ul>
                        </ul>
                        {/* <select onChange={onHandleProjectTypeSubcategort1}>
                        <option selected disabled>
                          select
                        </option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                      </select> */}
                      </>
                    ) : null}
                    {ProjectType === "Construction" ? (
                      <>
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="construction-project-type"
                            className="detail-select-list"
                          >
                            <li onClick={handlelistitem}>Residential</li>
                            <li onClick={handlelistitem}>Commercial</li>
                          </ul>
                        </ul>
                        {/* <select onChange={onHandleProjectTypeSubcategort1}>
                        <option selected disabled>
                          select
                        </option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                      </select> */}
                      </>
                    ) : null}
                    {ProjectTypeCategory1 === "Residential" ? (
                      <>
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="residential-property-type"
                            className="detail-select-list"
                          >
                            <li onClick={handlelistitem}>Apartment</li>
                            <li onClick={handlelistitem}>Penthouse</li>
                            <li onClick={handlelistitem}>Stand Alone Building</li>
                            <li onClick={handlelistitem}>Independent Home</li>
                            <li onClick={handlelistitem}>Villa</li>
                            <li onClick={handlelistitem}>Bungalow</li>
                          </ul>
                        </ul>
                        {/* <select onChange={onHandleProjectTypeCatrgory2Change}>
                        <option selected disabled>
                          select
                        </option>
                        <option value="Apartment">Apartment</option>
                        <option value="Penthouse">Penthouse</option>
                        <option value="Stand Alone Building">
                          Stand Alone Building
                        </option>
                        <option value="Independent Home">Independent Home</option>
                        <option value="Villa">Villa</option>
                        <option value="Bungalow">Bungalow</option>
                      </select> */}
                      </>
                    ) : null}
                    {ProjectTypeCategory1 === "Commercial" ? (
                      <>
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="commercial-property-type"
                            className="detail-select-list"
                          >
                            <li onClick={handlelistitem}>Retail</li>
                            <li onClick={handlelistitem}>Hospitality</li>
                            <li onClick={handlelistitem}>Healthcare</li>
                            <li onClick={handlelistitem}>
                              {" "}
                              Office – Corporate and Tech
                            </li>
                            <li onClick={handlelistitem}>Institutional</li>
                            <li onClick={handlelistitem}>Hospitality</li>
                            <li onClick={handlelistitem}>Industrial</li>
                          </ul>
                        </ul>
                        {/* <select onChange={onHandleProjectTypeCatrgory2Change}>
                        <option selected disabled>
                          select
                        </option>
                        <option value="Retail">Retail</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Office – Corporate and Tech">
                          Office – Corporate and Tech
                        </option>
                        <option value="Institutional">Institutional</option>
                        <option value="Industrial">Industrial</option>
                      </select> */}
                      </>
                    ) : null}
                    <label id="last-select-list-title" className="detail-title">
                      {" "}
                      {ProjectType === "Interior"
                        ? "Project Interior Style"
                        : ProjectType === "Construction"
                        ? "Project Architecture Style"
                        : null}
                    </label>
                    {ProjectType === "Interior" ? (
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">Select</p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="architectural-styles"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Asian</li>
                          <li onClick={handlelistitem}>Beach / Nautical</li>
                          <li onClick={handlelistitem}>
                            Contemporary Home Décor
                          </li>

                          <li onClick={handlelistitem}>Craftsman</li>
                          <li onClick={handlelistitem}>Eclectic</li>
                          <li onClick={handlelistitem}>Eco - Friendly</li>
                          <li onClick={handlelistitem}>Farmhouse</li>
                          <li onClick={handlelistitem}>Hollywood Glam</li>
                          <li onClick={handlelistitem}>Industrial</li>
                          <li onClick={handlelistitem}>Mediterranean</li>
                          <li onClick={handlelistitem}>Mid – Century Modern</li>
                          <li onClick={handlelistitem}>Minimalist</li>
                          <li onClick={handlelistitem}>Modern</li>
                          <li onClick={handlelistitem}>Rustic</li>
                          <li onClick={handlelistitem}>Scandinavian</li>
                          <li onClick={handlelistitem}>Shabby – Chic</li>
                          <li onClick={handlelistitem}>Southwestern</li>
                          <li onClick={handlelistitem}>Traditional</li>
                          <li onClick={handlelistitem}>Transitional</li>
                          <li onClick={handlelistitem}>Tropical</li>
                        </ul>
                      </ul>
                    ) : // <select onChange={onHandleProjectArchitectureStyleChange}>
                    //   <option selected disabled>
                    //     select
                    //   </option>
                    //   <option value="Asian">Asian</option>
                    //   <option value="Beach / Nautical">Beach / Nautical</option>
                    //   <option value="Contemporary Home Décor">
                    //     Contemporary Home Décor
                    //   </option>
                    //   <option value="Craftsman">Craftsman</option>
                    //   <option value="Eclectic">Eclectic</option>
                    //   <option value="Eco - Friendly">Eco - Friendly</option>
                    //   <option value="Farmhouse">Farmhouse</option>
                    //   <option value="Hollywood Glam">Hollywood Glam</option>
                    //   <option value="Industrial">Industrial</option>
                    //   <option value="Mediterranean">Mediterranean</option>
                    //   <option value="Mid – Century Modern">
                    //     Mid – Century Modern
                    //   </option>
                    //   <option value="Minimalist">Minimalist</option>
                    //   <option value="Modern">Modern</option>
                    //   <option value="Rustic">Rustic</option>
                    //   <option value="Scandinavian">Scandinavian</option>
                    //   <option value="Shabby – Chic">Shabby – Chic</option>
                    //   <option value="Southwestern">Southwestern</option>
                    //   <option value="Traditional">Traditional</option>
                    //   <option value="Transitional">Transitional</option>
                    //   <option value="Tropical">Tropical</option>
                    // </select>
                    null}
                    {ProjectType === "Construction" ? (
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">Select</p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Art Deco</li>
                          <li onClick={handlelistitem}>Bauhaus</li>
                          <li onClick={handlelistitem}>Constructivism (Art)</li>
                          <li onClick={handlelistitem}>
                            Constructivist Architecture
                          </li>
                          <li onClick={handlelistitem}>
                            Contemporary Architecture
                          </li>
                          <li onClick={handlelistitem}>
                            Eco - Friendly
                          </li>
                          <li onClick={handlelistitem}>Functionalism</li>
                          <li onClick={handlelistitem}>Futurist Architecture</li>
                          <li onClick={handlelistitem}>
                            High - Tech Architecture
                          </li>
                          <li onClick={handlelistitem}>
                            Industrial Architecture
                          </li>
                          <li onClick={handlelistitem}>International Style</li>
                          <li onClick={handlelistitem}>Mid - Century Modern</li>
                          <li onClick={handlelistitem}>Minimalism</li>
                          <li onClick={handlelistitem}>Modern Architecture</li>
                          <li onClick={handlelistitem}>New Objectivity</li>
                          <li onClick={handlelistitem}>Organic Architecture</li>
                          <li onClick={handlelistitem}>
                            Postmodern Architecture
                          </li>
                          <li onClick={handlelistitem}>Ranch - Style House</li>
                          <li onClick={handlelistitem}>Streamline Modern</li>
                          <li onClick={handlelistitem}>
                            Sustainable Architecture
                          </li>
                        </ul>
                      </ul>
                    ) : // <select onChange={onHandleProjectArchitectureStyleChange}>
                    //   <option selected disabled>
                    //     select
                    //   </option>
                    //   <option value="Art">Art Deco</option>
                    //   <option value="Bauhaus">Bauhaus</option>
                    //   <option value="Constructivism (Art)">
                    //     Constructivism (Art)
                    //   </option>
                    //   <option value="Constructivist Architecture">
                    //     Constructivist Architecture
                    //   </option>
                    //   <option value="Contemporary Architecture">
                    //     Contemporary Architecture
                    //   </option>
                    //   <option value="Eco - Friendly">c</option>
                    //   <option value="Functionalism">Functionalism</option>
                    //   <option value="Futurist Architecture">
                    //     Futurist Architecture
                    //   </option>
                    //   <option value="High - Tech Architecture">
                    //     High - Tech Architecture
                    //   </option>
                    //   <option value="Industrial Architecture">
                    //     Industrial Architecture
                    //   </option>
                    //   <option value="International Style">
                    //     International Style
                    //   </option>
                    //   <option value="Mid - Century Modern">
                    //     Mid - Century Modern
                    //   </option>
                    //   <option value="Minimalism">Minimalism</option>
                    //   <option value="Modern Architecture">
                    //     Modern Architecture
                    //   </option>
                    //   <option value="New Objectivity">New Objectivity</option>
                    //   <option value="Organic Architecture">
                    //     Organic Architecture
                    //   </option>
                    //   <option value="Postmodern Architecture">
                    //     Postmodern Architecture
                    //   </option>
                    //   <option value="Ranch - Style House">
                    //     Ranch - Style House
                    //   </option>
                    //   <option value="Streamline Modern">
                    //     Streamline Modern
                    //   </option>
                    //   <option value="Sustainable Architecture">
                    //     Sustainable Architecture
                    //   </option>
                    // </select>
                    null}
                  </div>
                </div>
              </div>
            </div>
            <br />
            {/* Address */}
            <p className="upload-project-details-heading">Address</p>
            <div className="upload-project-detail">
            <label className="detail-title">
              Please fill the details of the property work that you completed:
            </label>
              <div className="detail">
                <label className="detail-title">
                  House Name / Building Name / Plot Name / Land Name (Property
                  Name)
                </label>
                <input
                  className="detail-input"
                  type="text"
                  onChange={onHouseNameChange}
                />
              </div>
              <div className="detail">
                <label className="detail-title">State</label>
                <ul className="detail-select-list-container">
                  <li onClick={handelist} className="selected-heading">
                    <p className="heading">Select</p>
                    <span class="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="state-list"
                    className="detail-select-list"
                  >
                    {stateData.map((state, index) => {
                      return (
                        <li key={index} onClick={handlelistitem}>
                          {state}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
                {/* <select
              defaultValue={stateData[0]}
              onChange={onHandleStateChange}
              style={{ width: 120 }}
            >
              {stateData.map((index) => (
                <option key={index}>{index}</option>
              ))}
            </select> */}
              </div>
              <div className="detail">
                <label className="detail-title">City</label>
                {/* <input 
                                            type='text'
                                        /> */}
                <ul className="detail-select-list-container">
                  <li onClick={handelist} className="selected-heading">
                    <p className="heading">Select</p>
                    <span class="material-icons">expand_more</span>
                  </li>
                  <ul
                    style={{
                      opacity: 0,
                    }}
                    id="city-list"
                    className="detail-select-list"
                  >
                    {City.map((city, index) => (
                      <li onClick={handlelistitem} key={index}>
                        {city}
                      </li>
                    ))}
                  </ul>
                </ul>
                {/* <select onChange={onHandleCityNameChange}>
                    {City.map((index) => (
                      <option key={index}>{index}</option>
                    ))}
                  </select> */}
              </div>
              <div className="detail">
                <label className="detail-title">Area Name</label>
                <input
                  className="detail-input"
                  type="text"
                  onChange={OnAreaNameChange}
                />
              </div>
            </div>
            {/* Upload Images */}
            <br />
            <p className="upload-project-details-heading">Upload Images</p>
            <div className="upload-and-images-container">
              <div className="upload-btn">
                <Dropzone onDrop={onDrop} multiple={true} maxSize={800000000000}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {/* <Icon type="plus" style={{ fontSize: '3rem'}} /> */}
                      <span class="material-icons">add_box</span>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="images-container">
                {Images.map((img, index) => {
                  return (
                    <div key={index} className="uploaded-img">
                      <img src={img} alt="uploaded-img" />
                      <span
                        onClick={(e) => {
                          onDelete(img, e);
                        }}
                        className="material-icons"
                      >
                        remove
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <br />
            <br />
            <button
              className="save-and-continue-btn"
              style={{
                pointerEvents: Images.length > 0 ? "auto" : "none",
                opacity: Images.length > 0 ? 1 : 0.5,
              }}
              onClick={onSubmit}
              type="primary"
            >
              Proceed
            </button>
          </div>
          <br />

          {/* <div
              style={{
                display: "flex",
                width: "100%",
                height: "240px",
                overflowX: "scroll",
              }}
            >
              {Images.map((image, index) => (
                <div key={index} onClick={() => onDelete(image)}>
                  <Row gutter={[16, 16]}>
                    <Col lg={8}>
                      <img
                        key={image}
                        width={200}
                        src={image}
                        alt={`productImg-${index}`}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div> */}
        </div>
      );
    }
  } else {
    return <LoadingIcon />;
  }
}

export default SP_UploadNewProject;
