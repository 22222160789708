import React from "react";

export default function List(props) {
  const handelist = (e) => {
    const list = e.currentTarget.nextSibling;
    const heading = e.currentTarget;
    list.style.transform = "scale(1)";
    if (list.style.opacity === "0") {
      list.style.transform = "scale(1)";
      list.style.opacity = "1";
      heading.childNodes[1].innerText = "expand_less";
    } else {
      list.style.transform = "scale(0)";
      list.style.opacity = "0";
      heading.childNodes[1].innerText = "expand_more";
    }
  };
  const handlelistitem = (item,e) => {
    const list = e.currentTarget.parentElement;
    const listitem = e.currentTarget;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0];

    list.childNodes.forEach((e) => {
      e.style.color = "black";
      e.style.backgroundColor = "rgb(226, 225, 225)";
    });
    heading.childNodes[0].innerText = listitem.innerText;
    list.style.transform = "scale(0)";
    heading.childNodes[1].innerText = "expand_more";
    list.style.opacity = 0;
    listitem.style.color = "white";
    listitem.style.backgroundColor = "#fe5631";
  };
  return (
    <ul className="detail-select-list-container">
      <li onClick={handelist} className="selected-heading">
        <p className="heading">{props.DefaultHeading}</p>
        <span class="material-icons">expand_more</span>
      </li>
      <ul
        style={{
          opacity: 0,
        }}
        id={props.id}
        className="detail-select-list"
     
      >
        {props.listitems.map((item, index) => {
          return (
            <li key={index} onClick={(e) => {
                handlelistitem(item,e)
               props.Passvalue(item,e)
            }}>
              {item}
            </li>
          );
        })}
      </ul>
    </ul>
  );
}
