const facilites = [
    {
        Facility : "No Revenue Loss",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-1.png"
    },
    {
        Facility : "User – Friendly",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-2.png"
    },
    {
        Facility : "Timely Service",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-3.png"
    },
    {
        Facility : "Pocket Friendly",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-4.png"
    },
    {
        Facility : "Valuing Client – Friendly Relationships",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-5.png"
    },
    {
        Facility : "Lively Community",
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/AboutUs/facilities-6.png"
    },
]

module.exports.facilites = facilites;