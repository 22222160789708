import React from 'react'

function PleaseNoteDisclaimer() {
  return (
    <div className="disclaimer" style={{ marginLeft: '30px' }}>
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Please Note:
            </p>
          </div>
          <div>
          <p>•	All payments made to NebourHood at any stage are non-refundable. </p>
          <p>•	For more information, please visit the Replacement, Refund and Cancellation Policies page.</p>
          <p>•	Orders once placed / completed successfully cannot be cancelled. </p>
          <p>•	You would receive the details of your purchase on your NebourHood registered email.</p>
          <p>•	To view more details regarding your purchase on NebourHood, please visit the Transaction Details section of your profile.</p>
          </div>
          <br />
          <br />
          <div className="disclaimer">
            <b style={{ fontSize: '15px' }}>Disclaimer:</b>
            <p>
              •	<b>NebourHood would not be responsible for the acceptance or rejection of any deals between:</b>
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and property owners
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Service professionals and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Or any others involved.
            <br />
            <p>
              •	<b>NebourHood would not be responsible for any kind of terms (including service, payment or any other terms) made between:</b>
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and property owners
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Property owners and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Service professionals and service professionals
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Or any others involved.
          </div>
          </div>
  )
}

export default PleaseNoteDisclaimer