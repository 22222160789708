import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FindtheNeighbourhoodCount } from "../../../../_actions/property_actions";
import Button from "../../../utils/Button/Button"
import { neighborhoods } from "./Data";
import "./Neighborhood.css";

export default function Neighborhood() {
  const dispatch = useDispatch();
  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [Bangalore_Count, setBangalore_Count] = useState(0);
  const [Chennai_Count, setChennai_Count] = useState(0);
  const [Delhi_Count, setDelhi_Count] = useState(0);
  const [Hyderabad_Count, setHyderabad_Count] = useState(0);
  const [Mumbai_Count, setMumbai_Count] = useState(0);
  const [Pune_Count, setPune_Count] = useState(0);

  const user = useSelector((state) => state.user);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      
    });
  }, [screenwidth]);

  let bangaloreCount = 0;
  let chennaiCount = 0;
  let delhiCount = 0;
  let hyderabadCount = 0;
  let mumbaiCount = 0;
  let puneCount = 0;
  useEffect(() => {
    dispatch(FindtheNeighbourhoodCount())
        .then((response) => {
          if(response) {
            // console.log("Response ", response.payload.counts);
            response.payload.counts.map(index => {
              if(index.City === "Bangalore"){
                bangaloreCount = bangaloreCount + 1;
              }
              if(index.City === "Chennai"){
                chennaiCount = chennaiCount + 1;
              }
              if(index.City === "Delhi"){
                delhiCount = delhiCount + 1;
              }
              if(index.City === "Hyderabad"){
                hyderabadCount = hyderabadCount + 1;
              }
              if(index.City === "Mumbai"){
                mumbaiCount = mumbaiCount + 1;
              }
              if(index.City === "Pune"){
                puneCount = puneCount + 1;
              }
            })
            setBangalore_Count(bangaloreCount);
            setChennai_Count(chennaiCount);
            setDelhi_Count(delhiCount);
            setHyderabad_Count(hyderabadCount);
            setMumbai_Count(mumbaiCount);
            setPune_Count(puneCount);
          } else {
            console.log("Failed To Load Counts");
          }
        })
  }, [])

if(user.userData && user.userData.isAuth) {
  return null;
} else {
  return (
    <div className="neighborhood-container">
      <h1 style={{width:screenwidth<= 800 ? screenwidth-20 + 'px' :null}} className="title">Find The Neighbourhood For You</h1>
      <p style={{width:screenwidth<= 800 ? screenwidth-30 + 'px' :null}} className="description">
      Choose the locality which is suitable to you
      </p>
      <div className="neighborhood-countries">
        {neighborhoods.map((country, index) => {
          return (
            <div style={{width:screenwidth<= 800 ? screenwidth-30 + 'px' :null}} key={index} className="country" >
                <img style={{width:screenwidth<= 800 ? screenwidth-30 + 'px' :null}} src={country.Image} alt={country.Name} />
             <div style={{width:screenwidth<= 800 ? screenwidth-30 + 'px' :null}}>
             <h1>{country.Name}</h1>
              <p>{
              country.Name === "Bangalore"? Bangalore_Count : 
              country.Name === "Pune"? Pune_Count :
              country.Name === "Delhi"? Delhi_Count :
              country.Name === "Hyderabad"? Hyderabad_Count : 
              country.Name === "Chennai"? Chennai_Count :
              country.Name === "Mumbai"? Mumbai_Count :
              null              
              }&nbsp;Property Requirements</p>
             </div>
            </div>
          );
        })}
      </div>
      <br/>
      <div className="btn">
        <Button name="VIEW MORE NEIGBOURHOOD"  background="#fe5631" width="260px" directto="/ServiceProfessional/PropertyRequirements" />
      </div>
    </div>
  );
}
}
