import React from "react";
import "./MobileNav.css"

export default function AddListing(props){
    return <div id="add-listing-btn" className="add-listing-btn">
    <span onClick={props.handledot} className="material-icons">
      more_horiz
    </span>
    {props.Login==="ServiceProfessional" ? 
    <div
      style={{
        opacity: 0,
      }}
      id="add-listing"
      className="add-listing"
    >
      <div>
        {props.CompanyDetails.Subscribed? 
        <>
          {/* <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0'
          }} >
            <a href="/ServiceProfessional/Pricing" >Lead Plan: {props.CompanyDetails.Subscription_Time_Left} Days Left</a>
          </button> */}
          <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0',
            marginTop: '10px'
          }} >
            <a href="/ServiceProfessional/Pricing" style={{ textDecoration: 'none' }}>Leads: {props.CompanyDetails.leadLeft} Left</a>
          </button>

        </>
          :
          <>
          <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0',
            marginTop: '10px'
          }} >
            <a href="/ServiceProfessional/Pricing">Non-Sharing Lead Plans</a>
          </button>
          </>
          }
        {props.CompanyDetails.Membership? 
        <>
          <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0',
            marginTop: '10px'
          }} >
            <a href="/ServiceProfessional/Pricing/Membership">Prime: {props.CompanyDetails.MembershipValidity} Days Left</a>
          </button>
        </>
          :
          <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0',
            marginTop: '10px'
          }} >
            <a style={{ color: 'white' }} href="/ServiceProfessional/Pricing/Membership">Join Our Community Now!</a>
          </button>
          }
        {props.CompanyDetails.isAuth? 
        <>
          <button style={{
            minWidth:"250px",
            backgroundColor: '#1F80E0',
            marginTop: '10px'
          }} >
            <a href="/ServiceProfessional/MyStats">My Stats</a>
          </button>
        </>
          :
          null
          }

          {/* Upload Projects */}

          {/* <a href={props.directo1}>{props.Button1}</a> */}
        <button style={{
          minWidth:"250px",
          marginTop:"10px"
        }} >
          <a href="/ServiceProfessional/UploadNewProject">Upload Project</a>
        </button>
      </div>
    </div>
    : null }
    {props.Login==="PropertyOwner" ? 
    <div
      style={{
        opacity: 0,
      }}
      id="add-listing"
      className="add-listing"
    >
      <div>
        <button style={{
          minWidth:"150px"
        }} >
          <a href="/PropertyOwner/postpropertyrequirements">POST PROPERTY REQUIREMENTS</a>
        </button>
      </div>
    </div>
    : null }
  </div>
}