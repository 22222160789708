import React from "react";
import "./LoadingIcon.css";

export default function LoadingIcon(props) {
  return (
    <div
      style={{
        position: props.position,
        width: props.width,
        height: props.height,
        backgroundColor:props.backgroundcolor,  
        opacity: props.opacity,
        zIndex:9999     
      }}
      className="loading-icon-container"
    >
      <div className="icon">
        <div
          style={{
            width: props.outerwidth,
            height: props.outerheight,
          }}
          className="outer-circle"
        ></div>
        <div
          style={{
            width: props.innerwidth,
            height: props.innerheight,
            display : props.innerdisplay
          }}
          className="inner-circle"
        ></div>
      </div>
    </div>
  );
}
