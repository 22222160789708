import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Uploaded_Projects_OtherView.css";
import { useDispatch, useSelector } from "react-redux";
import NavigationNumbers from "../../../../../utils/NavigationNumbers/NavigationNumbers";
import Button from "../../../../../utils/Button/Button";
import SmallCarousel from "../../../../../utils/SmallCarousel/SmallCarousel";

function Uploaded_Projects_OtherView(props) {
    // props.ProjectsData, props.success
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [datareceived, setdatareceived] = useState(false);
  const [propertyheight, setpropertyheight] = useState(null);

  const propertyheightref = useRef(null);
  const [Projects, setProjects] = useState([]);

  const getPreviouslyUploadedProjects = () => {
    setdatareceived(false);
      if (props.success) {
        setdatareceived(true);

        if (props.ProjectsData.length > 0) {
          setProjects(props.ProjectsData);
          setTimeout(() => {
            setpropertyheight(
              propertyheightref.current.getBoundingClientRect().height
            );
          }, 10);

          // console.log("Product Response", props.ProjectsData);
        }
      } else {
        alert("Failed to fetch product datas");
      }
  };

useLayoutEffect(() => {
        window.addEventListener("resize", () => {
        setscreenwidth(window.innerWidth);
        // console.log(screenwidth);
    });
  }, [screenwidth]);

  const heighttomove =
    Projects.length <= 6 && Projects.length > 3 && screenwidth > 1050
      ? propertyheight * 2 + 20
      : Projects.length <= 3 && screenwidth > 1050
      ? propertyheight
      : Projects.length <= 3 && screenwidth < 1050 && screenwidth > 650
      ? propertyheight * 2 + 40
      : Projects.length >= 6 && Projects.length <= 9
      ? propertyheight * 3 + 60
      : propertyheight * 4 + 80;
  useEffect(() => {
    getPreviouslyUploadedProjects();
  }, []);
  return (
    <div className="previous-projects-container">
      {datareceived && Projects.length > 0 ? (
        <div>
          <div className="properties-featured-properties-container">
            <div className="titleanddesc">
              <p className="title">
                <b>Uploaded Projects</b>
              </p>
            </div>

            <div className="Featured-properties-container">
              <div
                style={{
                  height: heighttomove,
                }}
                id="Featured-properties"
                className="Featured-properties"
              >
                {Projects.map((property, index) => {
                  return (
                    <div
                      style={{
                        width:
                          screenwidth <= 510
                            ? screenwidth - 10 + "px"
                            : null,
                      }}
                      id="featured-properties-property"
                      className="featured-properties-propertyy"
                      ref={propertyheightref}
                      key={index}
                    >
                      <div className="upper">
                        {property.Project_Type === "Interior" ? (
                          <p className="tag" id="interior-tag">
                            INTERIOR
                          </p>
                        ) : (
                          <p className="tag" id="construction-tag">
                            CONSTRUCTION
                          </p>
                        )}
                        <p className="price">
                          {property.Project_Type_Subcategory_1}
                        </p>
                        <SmallCarousel
                          Images={property.Images}
                          style={{
                            width:
                              screenwidth <= 510
                                ? screenwidth - 30 + "px"
                                : null,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width:
                            screenwidth <= 510
                              ? screenwidth - 20 + "px"
                              : null,
                        }}
                        className="middle"
                      >
                        <div className="address">
                          <p className="left-side">Address :</p>
                          <p
                            style={{
                              color: "#fe5631",
                            }}
                            className="right-side"
                          >
                            {" "}
                            {property.Address.House_Building_Plot_Land},{" "}
                            {property.Address.State}, {property.Address.City},{" "}
                            {property.Address.Areaname}
                          </p>
                        </div>
                        <div className="address">
                          <p className="left-side">Project Name :</p>
                          <p
                            style={{
                              fontSize: "19.5px",
                            }}
                            className="right-side"
                          >
                            {/* {" "}
                              {property.Address.House_Building_Plot_Land},{" "}
                              {property.Address.State}, {property.Address.City},{" "}
                              {property.Address.Areaname} */}
                            {property.Project_Name}
                          </p>
                        </div>
                        <div className="address">
                          <p className="left-side">
                            Service Professional Name :
                          </p>
                          <p className="right-side">
                            {property.WriterDetails.Service_Professional_Name}
                          </p>
                        </div>
                        <div className="address">
                          <p className="left-side">Company Name :</p>
                          <p className="right-side">
                            {property.WriterDetails.Company_Name}
                            {/* Company */}
                          </p>
                        </div>
                        <div className="address">
                          <p className="left-side">Project No. :</p>
                          <p
                            style={{
                              color: "#fe5631",
                            }}
                            className="right-side"
                          >
                            {property._id}
                          </p>
                        </div>
                        <div className="address">
                          <p className="left-side">Property Type :</p>
                          <p className="right-side">
                            {property.Project_Type_Subcategory_2}
                          </p>
                        </div>
                        {/* <h2 className="property-title">
                            {property.Project_Name}
                          </h2> */}
                        {/* <p className="apartmentandsize">
                            {"Apartment"}&nbsp;({property.Project_Area} sq.ft.)
                          </p> */}
                        <div
                          style={{
                            width:
                              screenwidth <= 510
                                ? screenwidth - 20 + "px"
                                : null,
                          }}
                          className="other-detailss-container"
                        >
                          <p>{property.Service_Requirement}</p>
                          <div className="border"></div>
                          <p>
                            Year of Completion: {property.Project_Completion}
                          </p>
                          <div className="border"></div>
                          <p> No. of Images: {property.Images.length}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: screenwidth <= 510 ? screenwidth + "px" : null,
                        }}
                        className="bottom"
                      >
                        <div className="rating">
                        Uploaded on: {property.Uploaded_Date}
                          {/* <div className="stars">
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                            <span className="material-icons">star</span>
                          </div> */}
                          {/* <div className="rating-name">
                            {" "}
                            {5 > 4 ? "Excellent" : "Average"}
                          </div> */}
                        </div>
                        <div className="knowmore-btn">
                        <div className="knowmore-btn">
                        {company.companyData && company.companyData.isAuth ? (
                          <Button
                            width="150px"
                            name="KNOW DETAILS"
                            directto={`/ServiceProfessional/ProjectsDetails/${property._id}`}
                            background="#201c2d"
                          />
                        ) : (
                            <>
                                {user.userData && user.userData.isAuth ? (
                                  <Button
                                    width="150px"
                                    name="KNOW DETAILS"
                                    directto={`/PropertyOwner/ProjectsDetails/${property._id}`}
                                    background="#201c2d"
                                  />
                                ) : (
                                  <Button
                                    width="150px"
                                    name="KNOW DETAILS"
                                    directto={`/ProjectsDetails/${property._id}`}
                                    background="#201c2d"
                                  />
                                )}
                              </>
                        )}
                        </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <br />
          <NavigationNumbers
            numberofitems={9}
            screenwidthfornav1={1200}
            numberofitems1={6}
            screenwidthfornav2={750}
            numberofitems2={4}
            itemscontainerid="Featured-properties"
            heighttomove={propertyheight * 3 + 60}
          />

          <br />
        </div>
      ) : (
        <p className="no-data">No Projects Uploaded !</p>
      )}
    </div>
  );
}

export default Uploaded_Projects_OtherView;
