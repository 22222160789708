import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

function LeadLeft(props){

  const company = useSelector((state) => state.company);

  if(company.companyData && company.companyData.isAuth && company.companyData.Subscribed)
  {
    return <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
          <a style={{ color: 'white', textDecoration: 'none' }} onClick={() => props.history.push("/ServiceProfessional/Pricing")} >Leads: <span>{company.companyData.leadLeft} Left</span></a>
    </div>
  } else {
    return null;
  }

}

export default withRouter(LeadLeft);