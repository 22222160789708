import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom';

function NavLinks(props) {

  const user = useSelector(state => state.user);
  const company = useSelector(state => state.company);
  
  const ChangePageLink = () => {
    props.history.push('/PropertyOwner/postpropertyrequirements');
  }

  if(company.companyData && company.companyData.isAuth)
  {
    return(
      <div className="nav-links-container">
      <ul className="nav-links">
      <li>
        <a onClick={() => props.history.push('/ServiceProfessional/Home')}>Home</a>
      </li>
      <li>
        <a onClick={() => props.history.push('/ServiceProfessional/Projects')}>Projects</a>
      </li>
      <li>
        <a onClick={() => props.history.push('/ServiceProfessional/Gallery')}>Gallery</a>
      </li>
      <li>
        {/* <a onClick={() => props.history.push('/ServiceProfessional/AllServiceProfessionalPage')}>Service Professionals</a> */}
        <a href='/ServiceProfessional/AllServiceProfessionalPage'>Service Professionals</a>
      </li>
      <li>
        <a onClick={() => props.history.push('/ServiceProfessional/PropertyRequirements')}>Leads</a>
      </li>
      </ul>
        <div className="addlistingbtn">
          <div className="btn">
              <button onClick={()=> props.history.push('/ServiceProfessional/UploadNewProject')}>UPLOAD PROJECT</button>
          </div>
        </div>
    </div>
    )
  }
  else if(user.userData && user.userData.isAuth)
  {
    return (
      <div className="nav-links-container">
        <ul className="nav-links">
        <li>
          <a onClick={() => props.history.push("/PropertyOwner/Home")}>Home</a>
        </li>
        <li>
          <a onClick={() => props.history.push("/PropertyOwner/Projects")}>Projects</a>
        </li>
        <li>
          <a onClick={() => props.history.push("/PropertyOwner/Gallery")}>Gallery</a>
        </li>
        <li>
          {/* <a onClick={() => props.history.push("/PropertyOwner/AllServiceProfessionalPage")}>Service Professionals</a> */}
          <a href='/PropertyOwner/AllServiceProfessionalPage'>Service Professionals</a>
        </li>
        <li>
          <a onClick={() => props.history.push("/PropertyOwner/AboutUs")} href="">About Us</a>
        </li>
        </ul>
          <div className="addlistingbtn">
            <div className="btn">
                <button onClick={ChangePageLink}>POST PROPERTY REQUIREMENTS</button>
            </div>
          </div>
      </div>
    );
  }
  else {
    return (
      <>
      {/* <div className="nav-links-container">
      <ul className="nav-links">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/property">
            Property&nbsp;&nbsp;<i className="fas fa-chevron-down"></i>
          </a>
          <ul className="drop-down">
            <li>
              <a className="dropdown-item" href="/property">
                Property
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/propertydetails">
                Property Details
              </a>
            </li>
          </ul>
        </li>
        <li>
          Pages&nbsp;&nbsp;<i className="fas fa-chevron-down"></i>
          <ul className="drop-down">
            <li>
              <a className="dropdown-item" href="/#">
                Services
              </a>
              <i className="fas fa-chevron-right"></i>
              <ul className="drop-down" id="sub-drop-down">
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/servicedetails">Service Details</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="dropdown-item" href="/#">
                Case Study
              </a>
              <i className="fas fa-chevron-right"></i>
              <ul className="drop-down" id="sub-drop-down">
                <li>
                  <a href="/casestudy">Case Study</a>
                </li>
                <li>
                  <a href="/casestudydetails">Case Study Details</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="dropdown-item" href="/">
                Others
              </a>
              <i className="fas fa-chevron-right"></i>
              <ul className="drop-down" id="sub-drop-down">
                <li>
                  <a href="/testimonials">Testimonials</a>
                </li>
                <li>
                  <a href="/neighborhood">Neighborhood</a>
                </li>
                <li>
                  <a href="/404error">404 Error</a>
                </li>
                <li>
                  <a href="/searchpage">Search Page</a>
                </li>
                <li>
                  <a href="/comingsoon">Coming Soon</a>
                </li>
                <li>
                  <a href="/termsofservice">Terms of Service</a>
                </li>
                <li>
                  <a href="/privacypolicy">Privacy Policy</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="dropdown-item" href="/about">
                About
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/agents">
                Agents
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/pricing">
                Pricing
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/gallery">
                Gallery
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/listing">
                Listing
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/submitproperty">
                Submit Property
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/compareproperty">
                Compare Property
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/account">
                Account
              </a>
              <i className="fas fa-chevron-right"></i>
              <ul className="drop-down" id="sub-drop-down">
                <li>
                  <a href="/login">Log In</a>
                </li>
                <li>
                  <a href="/register">Register</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="/">
            Blog&nbsp;&nbsp;<i className="fas fa-chevron-down"></i>
          </a>
          <ul className="drop-down">
            <li>
              <a className="dropdown-item" href="/blog">
                Blog
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/blog">
                Blog Right Sidebar
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/blogdetails">
                Blog Details
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
      </ul>
      <div className="addlistingbtn">
            <div className="btn">
              <button>ADD LISTING +</button>
            </div>
          </div>
    </div> */}
      <div className="nav-links-container">
      <ul className="nav-links">
        <li>
          <a href="/Home">Home</a>
        </li>
        <li>
          <a href="/Projects">
            Projects&nbsp;&nbsp;
          </a>
        </li>
        <li>
          <a href='/Gallery'>
            Gallery&nbsp;&nbsp;
          </a>
        </li>
        <li>
          <a href="/AllServiceProfessionalPage">Service Professionals&nbsp;&nbsp;</a>
        </li>
        <li>
          <a href="/AboutUs">About Us</a>
        </li>
      </ul>
    </div>
    </>

    )
}
}


export default withRouter(NavLinks);