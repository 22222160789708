
export const animate = (dotno, id, dotscontainer) => {


  var dotnumber = dotno ? dotno : 0;
  const images = id;
  var widthtomove = 100 * dotnumber;
  const length = images.childNodes.length - 1;
  var moveimage = images.style;
  const dots = dotscontainer.childNodes;
  dots.forEach((dot) => {
    dot.style.backgroundColor = "transparent";

  });
  dots[dotnumber].style.backgroundColor = "#1dc295"
  if (dotno) {
    moveimage.transform = `translateX(-${widthtomove}%)`;
    widthtomove = widthtomove + 100;
    dots[dotnumber].style.backgroundColor = "#1dc295";
    dotnumber++
  } else {
    setInterval(() => {
      dots.forEach((dot) => {
        dot.style.backgroundColor = "transparent";
      });

      if (widthtomove > 100 * length) {
        widthtomove = 0;
        dotnumber = 0
      }
      moveimage.transform = `translateX(-${widthtomove}%)`;
      dots[dotnumber].style.backgroundColor = "#1dc295";
      widthtomove = widthtomove + 100;
      dotnumber++
    }, 2000);
  }


};
