import React, { useState, useEffect } from 'react'
import "./SP_Profile_OtherView.css"
import { useDispatch } from 'react-redux';
import { ServiceProfessionalProfile_Otherview } from '../../../../../_actions/company_actions';
import LoadingIcon from '../../../../utils/LoadingIcon/LoadingIcon';
import Uploaded_Projects_OtherView from './Uploaded_Projects_OtherView/Uploaded_Projects_OtherView';
import SP_Otherview_Overview from './Overview/SP_Otherview_Overview';
import SP_Cover_Image from './Overview/Cover_Image/SP_Cover_Image';
import SP_ProfileImgandCompanyLogo from './Overview/SP_ProfileImgandCompanyLogo/SP_ProfileImgandCompanyLogo';

function SPProfileOtherView(props) {
    const dispatch = useDispatch();
    const [ShowLoading, setShowLoading] = useState(true);
    const [ProfileDetails, setProfileDetails] = useState([]);
    const [Projects, setProjects] = useState([]);


    const [overviewclicked, setoverviewclicked] = useState(true);
    const [analyticsclicked, setanalyticsclicked] = useState(false);

  const handleprofiletabs = (e) => {
    const clickedel = e.target;
    const profiletabs = e.currentTarget.childNodes;
    profiletabs.forEach((tab) => {
      tab.style.backgroundColor = "rgb(233, 232, 232)";
      tab.style.color = "black";
    });
    clickedel.style.backgroundColor = "#1dc295";
    clickedel.style.color = "white";
    setoverviewclicked(false);
    setanalyticsclicked(false);
    if (clickedel.className === "overview") {
      setoverviewclicked(true);
    }
    if (clickedel.className === "analytics") {
      setanalyticsclicked(true);
    }
  };

  useEffect(() => {
      // console.log("Profile ID Params ", props.match.params.profileID);
        dispatch(ServiceProfessionalProfile_Otherview(props.ServiceProfessionalID))
            .then((response) => {
                if(response.payload.success) {
                    // console.log("Response ", response.payload.result);
                    setProfileDetails(response.payload.result.profileDetails[0]);
                    setProjects(response.payload.result.Projects);
                    setShowLoading(false);
                } else {
                    alert("Failed To Load !!!  Please try later");
                }
            })
    }, [])


    if(ShowLoading === true) {
        return ( <LoadingIcon /> )
    } else {
        return (
            <div className="profile-page-container" style={props.style} >
        <SP_Cover_Image
          Image_Src={ProfileDetails.Company_Details.Company_Cover_Picture}
        />
        <SP_ProfileImgandCompanyLogo
          companyDetails={ProfileDetails}
          ProfileImgSrc={ProfileDetails.Personal_Details.Profile_Pic}
          CompanyImgSrc={ProfileDetails.Company_Details.Company_Logo}
          userGender={ProfileDetails.Personal_Details.Gender}
          companylogo={true}
          SP={true}
          nochange={true}
        />
        <div
          onClick={handleprofiletabs}
          id="profile-tabs-container"
          className="profile-tabs-container"
        >
          <p className="overview">Overview</p>
          <p className="analytics">My Projects</p>
        </div> 
        {overviewclicked ? (
          <SP_Otherview_Overview
            companyDetails={ProfileDetails}
          />
        ) : null}
        {analyticsclicked ? <Uploaded_Projects_OtherView ProjectsData={Projects} success={true} /> : null} 
      </div>
        )
    }

}

export default SPProfileOtherView