import React from "react";
// import { table } from "./Data";
import "./LeadsInvoice.css";
import logo from "../../NavBar/DesktopNav/Logo.png";
// import PleaseNoteDisclaimer from "../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";

const LeadsInvoice = (props) => {
  const detailstopass = props.detailstopass;
  // props.InvoiceDetails;
  // console.log(detailstopass)

  return (
    <div id="leads-invoice-container" className="invoice-containerr">
      <img src={logo} alt="logo" />
      <p className="upper">Hey {props.SPName}!</p>
      <br />
      <p className="upper">Thank you for placing your order with us!</p>
      <p className="upper">
      We received your order and have completed it successfully. 
      Your NebourHood Purchase / Selection / Replacement Selection Order ID is {props.detailstopass.OrderID}.
      </p>
      <br />
      <p className="upper">
        While contacting the Property Owner, kindly notify them that 
        you received their Property Requirements from NebourHood.
      </p>
      <p className="upper">
      The details of the Property Requirement 
      lead that you purchased / selected / replaced from NebourHood are as follows:
      </p>
      <br />
      <h2>PROPERTY REQUIREMENT LEAD DETAILS </h2>
      <table class="table">
        <tr class="main-headings">
          <td>Category</td>
          <td colspan="2">Lead Details</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan="3"
          >
            PERSONAL DETAILS & CONTACT INFO
          </td>
        </tr>

        <tr>
          <td>Property Owner Name</td>
          <td colspan="2">{detailstopass.PersonalDetails.PropertyOwnerName}</td>
        </tr>

        <tr>
          <td>Email ID</td>
          <td colspan="2">{detailstopass.UserDetails.EmailId}</td>
        </tr>

        <tr>
          <td>Phone Number</td>
          <td colSpan={2}>{detailstopass.UserDetails.PhoneNumber}</td>
        </tr>

        <tr>
          <td>Alternative Email ID</td>
          <td colSpan={2}>{detailstopass.PersonalDetails.AlternativeEmailId}</td>
        </tr>
        <tr>
          <td>Alternative Phone Number</td>
          <td colSpan={2}>{detailstopass.PersonalDetails.AlternativePhoneNumber}</td>
        </tr>
        <tr>
          <td>WhatsApp Number</td>
          <td colSpan={2}>{detailstopass.PersonalDetails.PersonalWhatsappNumber}</td>
        </tr>
        <tr>
          <td>Property Owner Profile Number</td>
          <td colSpan={2}>{detailstopass.UserDetails.ProfileNumber}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            ADDRESS
          </td>
        </tr>

        <tr>
          <td>House Name / Building Name / Plot Name / Land Name</td>
          <td colspan="2">{detailstopass.Address.HouseName}</td>
        </tr>

        <tr>
          <td>State</td>
          <td colspan="2">{detailstopass.Address.State}</td>
        </tr>

        <tr>
          <td>City</td>
          <td colspan="2">{detailstopass.Address.City}</td>
        </tr>
        <tr>
          <td>Area Name</td>
          <td colspan="2">{detailstopass.Address.AreaName}</td>
        </tr>
        <tr>
          <td>Pin Code</td>
          <td colspan="2">{detailstopass.Address.Pincode}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            SERVICE TYPE REQUIRED
          </td>
        </tr>

        <tr>
          <td>Service Type</td>
          <td colspan="2">{detailstopass.ServiceTypeRequired.ServiceType}</td>
        </tr>

        <tr>
          <td>Property Type</td>
          <td colspan="2">{detailstopass.ServiceTypeRequired.PropertyType}</td>
        </tr>

        <tr>
          <td>Property Status</td>
          <td colSpan="2">{detailstopass.ServiceTypeRequired.PropertyStatus}</td>
        </tr>


        <tr>
          <td>Service Requirement</td>
          <td colspan="2">{detailstopass.ServiceTypeRequired.ServiceRequirement}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            TIME FRAME
          </td>
        </tr>

        <tr>
          <td>How soon would you like to get the work started?</td>
          <td colspan="2">{detailstopass.TimeFrame}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            PROPERTY INFORMATION
          </td>
        </tr>

        <tr>
          <td>Property Requirement Number</td>
          <td colSpan={2}>{detailstopass.PropertyInformation.PropertyRequirementNumber}</td>
        </tr>

        <tr>
          <td>Property Dimension Type</td>
          <td colspan="2">{detailstopass.PropertyInformation.PropertyDimensionType}</td>
        </tr>

        <tr>
          <td>Property Area</td>
          <td colspan="2">{detailstopass.PropertyInformation.PropertyArea} sq.ft.</td>
        </tr>

        <tr>
          <td>Property Condition</td>
          <td colspan="2">{detailstopass.PropertyInformation.PropertyCondition}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            BUDGET
          </td>
        </tr>

        <tr>
          <td>
            What is your approximate budget for the scope of work involved?
          </td>
          <td colspan="2">{detailstopass.Budget}</td>
        </tr>

        <tr>
          <td
            style={{
              textAlign: "center",
            }}
            colspan={3}
          >
            PROPERTY REQUIREMENTS
          </td>
        </tr>

        <tr>
          <td>
            Please describe your property requirements completely and as clearly
            as possible:
          </td>
          <td colspan="2">{detailstopass.PropertyRequirements}</td>
        </tr>
      </table>
      <br />
      <p>
        For more details about your purchase / selection, 
        please visit <span style={{ color: '#1DA1F2' }} >nebourhood.com</span> and 
        check for your order under the 
        Transaction Details section of your profile.
      </p>
      <br />
      <p>
        We’re glad that you found what you were looking for. 
        It is our goal that you are always happy with what you 
        bought from us. We look forward to seeing you again.
      </p>
      <br />
      <br />
      <p>Thank you,</p>
      <p>Team NebourHood.</p>
    </div>
  );
};

export default LeadsInvoice;
