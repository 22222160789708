import React from 'react';
import { withRouter } from "react-router-dom";

function CartPricingDisclamer() {
  return (
    <div style={{ width: '50%', textAlign: 'center'}}>
              <p>
              By completing your purchase / selection, you agree to the 
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/PrivacyPolicy`} > Privacy Policy</a>, 
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/terms&conditions`}> Terms & Conditions </a>,
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/Communityguidelines`}> Community Guidelines </a>,
              {/* and  */}
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/RefundReplacement&Cancellation`}> Replacement, Refund & Cancellation Policies</a>,
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/PORules`}> Property Owner Rules</a>,
              <a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/SPRules`}> Service Professional Rules </a>
              of NebourHood.
                <br />
                <br />
              You would receive the details of your purchase / selection on your NebourHood registered email. To view more details regarding your purchase / selection on NebourHood, please visit the Transaction Details section of your profile.
              </p>
            </div>
  )
}

export default withRouter(CartPricingDisclamer)