import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Comingsoon.css";

export default function ComingSoon() {
  const [expired, setexpired] = useState(false);
  const [correctemail, setcorrectemail] = useState(true);
  const [subscribeclicked, setsubscribeclicked] = useState(false);

  const [date, setdate] = useState({
    Days: Number,
    Hours: Number,
    Minutes: Number,
    Seconds: Number,
  });

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);
  useEffect(() => {
    if (screenwidth <= 550) {
      const timeleftcontainers =
        document.getElementById("timeleftcontainer").childNodes;
      for (let i = 0; i < timeleftcontainers.length; i++) {
        timeleftcontainers[i].style.width = (screenwidth - 50) / 5 + "px";
        timeleftcontainers[i].style.height = (screenwidth - 50) / 5 + "px";
      }
      // console.log("hello");
    }
  }, [screenwidth]);

  var time = setInterval(() => {
    var countdowndate = new Date("Jan 27, 2023 22:09:15").getTime();
    var now = new Date().getTime();
    var distance = countdowndate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setdate({
      Days: days,
      Hours: hours,
      Minutes: minutes,
      Seconds: seconds,
    });
    if (distance <= 0) {
      setexpired(true);
      clearInterval(time);
    }
  }, 1000);

  const ValidateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return setcorrectemail(true);
    }
    return setcorrectemail(false);
  };

  const handleemail = (e) => {
    ValidateEmail(e.target.value);
    setsubscribeclicked(false);
  };

  const handlesubscribe = (e) => {
    setsubscribeclicked(true);
    if (!correctemail) {
      for (let i = 1; i < 8; i++) {
        setTimeout(() => {
          if (i % 2 === 0) {
            document.getElementById("subscribe").style.left = "-30px";
          } else {
            document.getElementById("subscribe").style.left = "30px";
          }
          if (i === 7) {
            document.getElementById("subscribe").style.left = 0;
          }
        }, i * 80);
      }
    } else {
      document.getElementById("email").value = "";
    }
  };

  return (
    <div className="comingsoon-container">
      <div
        style={{
          width: screenwidth <= 550 ? screenwidth - 50 + "px" : null,
        }}
        className="comingsoon"
      >
        <p className="title">
          <b>NebourHood</b>
        </p>
        <p className="desc">We are Launching Soon</p>
        <div id="timeleftcontainer" className="time-left">
          <div className="days-container">
            <p id="days" className="number">
              <b>{date.Days}</b>
            </p>
            <p>Days</p>
          </div>
          <div className="hours-container">
            <p id="hours" className="number">
              <b>{date.Hours}</b>
            </p>
            <p>Hours</p>
          </div>
          <div className="minutes-container">
            <p id="minutes" className="number">
              <b>{date.Minutes}</b>
            </p>
            <p>Minutes</p>
          </div>
          <div className="seconds-container">
            <p id="seconds" className="number">
              <b>{date.Seconds}</b>
            </p>
            <p>Seconds</p>
          </div>
        </div>
        <div
          style={{
            width: screenwidth <= 550 ? screenwidth - 100 + "px" : null,
          }}
          className="subscribe"
          id="subscribe"
        >
          <input
            onChange={handleemail}
            type={"email"}
            placeholder="Enter your Email"
            id="email"
          />
          <button onClick={handlesubscribe}>
            <b>Subscribe</b>
          </button>
          <p
            className="onsubscribe"
            style={{
              color: correctemail ? "#1dc295" : "red",
              opacity: subscribeclicked ? 1 : 0,
            }}
          >
            <b>
              {correctemail
                ? "Thanks for Subscribing"
                : "Please Enter your Correct Email"}
            </b>
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
}
