import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { User_ForgotPassword } from '../../../../_actions/user_actions';
import PageBanner from '../../../utils/PageBanner/PageBanner';
import "./PO_ForgotPassword.css"

function PO_ForgotPassword() {
    const dispatch = useDispatch();
    const [EmailEntered, setEmailEntered] = useState("");

    const onEmailInput = (e) => {
        // console.log(e.target.value);
        setEmailEntered(e.target.value)
    }

    const onSubmitEmail = () => {
        // console.log(EmailEntered);
        let variable = {
            email: EmailEntered
        }
        dispatch(User_ForgotPassword(variable))
            .then((response) => {
                // console.log("Response ", response);
                alert("Reset Link has been sent.")
            })
    }


  return (
    <div className="sp-forgot-password-container">
      <PageBanner
        title="Property Owner Forgot Password Page"
      />
      <div className="header">
        <p className="title">
          <b>Kindly enter your NebourHood registered email to receive the details and reset your password:</b>
        </p>
      </div>
      <div className="sp-forgot-password">
          <label>Email</label>
          <input onChange={onEmailInput} type="text" placeholder="Enter your mail" />
          <button onClick={onSubmitEmail} className="reset-link-btn">
              Send Reset Password Link
          </button>
      </div>
    </div>
    // <div>

    // <h3>Enter e-mail</h3>
    
    // <input 
    //     onChange={onEmailInput}
    // />
    
    // <button onClick={onSubmitEmail} >Send Reset Email</button>
    
    // </div>
  )
}

export default PO_ForgotPassword