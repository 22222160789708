import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SUBSCRIPTION_SERVER } from '../../../../Config';
import { BABY_BEAR_VALIDITY, BABY_TIGER_PRIME_VALIDITY, BABY_TIGER_VALIDITY, CLASSIC_TIGER_PRIME_PRICE, CLASSIC_TIGER_PRIME_VALIDITY, CLASSIC_TIGER_VALIDITY, ELITE_TIGER_PRIME_VALIDITY, ELITE_TIGER_VALIDITY, MAMA_BEAR_VALIDITY, PAPA_BEAR_VALIDITY, POST_BABY_BEAR_PRIME_VALIDITY, POST_BABY_TIGER_PRIME_VALIDITY, STARTUP_BEAR_VALIDITY, STARTUP_TIGER_PRIME_VALIDITY, STARTUP_TIGER_VALIDITY, TEDDY_BEAR_VALIDITY, WEBY_BEAR_VALIDITY, WEBY_TIGER_VALIDITY } from '../../../../PricingValidityConfig';
import { onSubscriptionPackageBuy, onSubscriptionPackageBuyFailed } from '../../../../_actions/company_actions';

const BABY_URL = `${SUBSCRIPTION_SERVER}/babysubscription`;
const PAPA_URL = `${SUBSCRIPTION_SERVER}/papasubscription`;
const MAMA_URL = `${SUBSCRIPTION_SERVER}/mamasubscription`;
const TEDDY_URL = `${SUBSCRIPTION_SERVER}/teddysubscription`;
const STARTUP_TIGER_URL = `${SUBSCRIPTION_SERVER}/startuptigersubscription`;
const ELITE_TIGER_URL = `${SUBSCRIPTION_SERVER}/elitetigersubscription`;
const CLASSIC_TIGER_URL = `${SUBSCRIPTION_SERVER}/classictigersubscription`;
const BABY_TIGER_URL = `${SUBSCRIPTION_SERVER}/babytigersubscription`;
const WEBY_BEAR_URL = `${SUBSCRIPTION_SERVER}/webybearsubscription`;
const WEBY_TIGER_URL = `${SUBSCRIPTION_SERVER}/webytigersubscription`;
const STARTUP_BEAR_URL = `${SUBSCRIPTION_SERVER}/startupbearsubscription`;
const POST_BABY_BEAR_PRIME = `${SUBSCRIPTION_SERVER}/postbabybearSubscription`;
const STARTUP_TIGER_PRIME_URL = `${SUBSCRIPTION_SERVER}/startuptigerPrimeSubscription`;
const ELITE_TIGER_PRIME_URL = `${SUBSCRIPTION_SERVER}/elitetigerPrimeSubscription`;
const CLASSIC_TIGER_PRIME_URL = `${SUBSCRIPTION_SERVER}/classictigerPrimeSubscription`;
const BABY_TIGER_PRIME_URL = `${SUBSCRIPTION_SERVER}/babytigerPrimeSubscription`;

function Razorpay(props) {
    // console.log("Razorpay props: " , props);
    const dispatch = useDispatch();

    function onTransactionSuccess(data) {
        // console.log("Data Success: " , data);
        dispatch(onSubscriptionPackageBuy(data))
            .then(response => {
                window.location.replace("/ServiceProfessional/spsuccess")
            })
    }

    function CallexpireFunction(expdate, planName) {
        const planValidity = planName === "Baby Bear" ? BABY_BEAR_VALIDITY : 
                            planName === "Papa Bear" ? PAPA_BEAR_VALIDITY : 
                            planName === "Mama Bear" ? MAMA_BEAR_VALIDITY : 
                            planName === "Teddy Bear" ? TEDDY_BEAR_VALIDITY : 
                            planName === "Startup Tiger" ? STARTUP_TIGER_VALIDITY : 
                            planName === "Elite Tiger" ? ELITE_TIGER_VALIDITY : 
                            planName === "Classic Tiger" ? CLASSIC_TIGER_VALIDITY : 
                            planName === "Baby Tiger" ? BABY_TIGER_VALIDITY : 
                            planName === "Weby Bear" ? WEBY_BEAR_VALIDITY : 
                            planName === "Weby Tiger" ? WEBY_TIGER_VALIDITY : 
                            planName === "Startup Bear" ? STARTUP_BEAR_VALIDITY : 
                            planName === "Baby Bear Prime" ? POST_BABY_BEAR_PRIME_VALIDITY : 
                            planName === "Startup Tiger Prime" ? STARTUP_TIGER_PRIME_VALIDITY : 
                            planName === "Elite Tiger Prime" ? ELITE_TIGER_PRIME_VALIDITY : 
                            planName === "Classic Tiger Prime" ? CLASSIC_TIGER_PRIME_PRICE : 
                            planName === "Baby Tiger Prime" ? BABY_TIGER_PRIME_VALIDITY : 
                            0;
        let expireDate = new Date(expdate);
        expireDate.setDate(expireDate.getDate() + planValidity);
        return expireDate.toString();
    }

    function onTransactionFailed(data) {
        var dataToSubmit = {
            data: data,
            planname: props.Name,
            Validity: props.Name === "Baby Bear" ? BABY_BEAR_VALIDITY : 
            props.Name === "Papa Bear" ? PAPA_BEAR_VALIDITY : 
            props.Name === "Mama Bear" ? MAMA_BEAR_VALIDITY : 
            props.Name === "Teddy Bear" ? TEDDY_BEAR_VALIDITY : 
            props.Name === "Baby Tiger" ? BABY_TIGER_VALIDITY : 
            props.Name === "Weby Bear" ? WEBY_BEAR_VALIDITY : 
            props.Name === "Weby Tiger" ? WEBY_TIGER_VALIDITY : 
            props.Name === "Startup Bear" ? STARTUP_BEAR_VALIDITY : 
            props.Name === "Startup Tiger" ? STARTUP_TIGER_VALIDITY : 
            props.Name === "Baby Bear Prime" ? POST_BABY_BEAR_PRIME_VALIDITY : 
            props.Name === "Startup Tiger Prime" ? STARTUP_TIGER_PRIME_VALIDITY :
            props.Name === "Elite Tiger Prime" ? ELITE_TIGER_PRIME_VALIDITY :
            props.Name === "Classic Tiger Prime" ? CLASSIC_TIGER_PRIME_PRICE :
            props.Name === "Baby Tiger Prime" ? BABY_TIGER_PRIME_VALIDITY :
            0
        }
        dispatch(onSubscriptionPackageBuyFailed(dataToSubmit))
            .then(response => {
                // console.log("Response dispatch", response);
            })
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () =>{
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
    }

    function setValidity(value) {
        return value === "Baby Bear" ? BABY_BEAR_VALIDITY : 
                value === "Startup Tiger" ? STARTUP_TIGER_VALIDITY : 
                value === "Elite Tiger" ? ELITE_TIGER_VALIDITY : 
                value === "Classic Tiger" ? CLASSIC_TIGER_VALIDITY : 
                value === "Baby Tiger" ? BABY_TIGER_VALIDITY : 
                value === "Papa Bear" ? PAPA_BEAR_VALIDITY : 
                value === "Mama Bear" ? MAMA_BEAR_VALIDITY : 
                value === "Teddy Bear" ? TEDDY_BEAR_VALIDITY : 
                value === "Weby Bear" ? WEBY_BEAR_VALIDITY : 
                value === "Weby Tiger" ? WEBY_TIGER_VALIDITY : 
                value === "Startup Bear" ? STARTUP_BEAR_VALIDITY : 
                value === "Baby Bear Prime" ? POST_BABY_BEAR_PRIME_VALIDITY : 
                value === "Startup Tiger Prime" ? STARTUP_TIGER_PRIME_VALIDITY : 
                value === "Elite Tiger Prime" ? ELITE_TIGER_PRIME_VALIDITY : 
                value === "Classic Tiger Prime" ? CLASSIC_TIGER_PRIME_VALIDITY : 
                value === "Baby Tiger Prime" ? BABY_TIGER_PRIME_VALIDITY : 
                0;
    }
    function setTotalLeads(value) {
        return value === "Baby Bear" ? 35 : 
                value === "Startup Tiger" ? 5 : 
                value === "Elite Tiger" ? 10 : 
                value === "Classic Tiger" ? 15 : 
                value === "Baby Tiger" ? 20 : 
                value === "Weby Bear" ? 35 : 
                value === "Weby Tiger" ? 17 : 
                value === "Startup Bear" ? 7 :
                value === "Baby Bear Prime" ? 35 : 
                value === "Startup Tiger Prime" ? 5 : 
                value === "Elite Tiger Prime" ? 10 : 
                value === "Classic Tiger Prime" ? 15 : 
                value === "Baby Tiger Prime" ? 20 : 
                0;
    }
  
    async function callfunction() {
        // console.log("Function Call to Display Razorpay First call", props.Name, props.Validity);
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if(!res) {
            alert("Error in loading Razorpay Window");
            return;
        }
       const result = await axios.post(
        props.Name === "Baby Bear" ? BABY_URL : 
        props.Name === "Startup Tiger" ? STARTUP_TIGER_URL : 
        props.Name === "Elite Tiger" ? ELITE_TIGER_URL : 
        props.Name === "Classic Tiger" ? CLASSIC_TIGER_URL : 
        props.Name === "Baby Tiger" ? BABY_TIGER_URL : 
        props.Name === "Papa Bear" ? PAPA_URL : 
        props.Name === "Mama Bear" ? MAMA_URL : 
        props.Name === "Weby Bear" ? WEBY_BEAR_URL : 
        props.Name === "Weby Tiger" ? WEBY_TIGER_URL : 
        props.Name === "Startup Bear" ? STARTUP_BEAR_URL : 
        props.Name === "Baby Bear Prime" ? POST_BABY_BEAR_PRIME : 
        props.Name === "Startup Tiger Prime" ? STARTUP_TIGER_PRIME_URL : 
        props.Name === "Elite Tiger Prime" ? ELITE_TIGER_PRIME_URL : 
        props.Name === "Classic Tiger Prime" ? CLASSIC_TIGER_PRIME_URL : 
        props.Name === "Baby Tiger Prime" ? BABY_TIGER_PRIME_URL : 
        TEDDY_URL);
       if(!result) {
        alert("Server Error, Are you online? Please try again later.");
       }
       const { amount, id: order_id, currency } = result.data;
       const options = {
                "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                "amount": amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": currency,
                "name": "NebourHood",
                "description": "Plan Subscription",
                "image": "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/NebourHoodHomeLogo.jpg",
                "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response){
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        planname: props.Name,
                        // Validity: props.Name === "Baby Bear" ? 30 : props.Name === "Papa Bear" ? 90 : props.Name === "Mama Bear" ? 180 : props.Name === "Teddy Bear" ? 365 : 0,
                        Validity: setValidity(props.Name),
                        TotalLeads: setTotalLeads(props.Name),
                        Subscribed_On_Date: new Date().toLocaleDateString(), 
                        Subscribed_On_Time: new Date().toTimeString(),
                        Plan_Expire_Date: CallexpireFunction(new Date().toLocaleDateString(), props.Name),
                        Success: true
                    };
                    // console.log("Data ", data);
                    const verifyTransaction = await axios.post(`${SUBSCRIPTION_SERVER}/payment/success`, data);
                    // console.log("Verified transaction : ", verifyTransaction);
                    if(verifyTransaction.data.success) {
                        onTransactionSuccess(data);
                    } else {
                        alert("Invalid Signature");
                    }
                },
                "notes": {
                    "address": "NebourHood Plan Subscription Payment"
                },
                "theme": {
                    "color": "#44A4E6"
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response){
                // console.log("Fail", response);
                onTransactionFailed(response);
            });
            paymentObject.open();
    }


    return (
    <h3 style={{ color: 'white', margin: 0 }} onClick={callfunction}>Proceed to Pay</h3>
  )
}

export default withRouter(Razorpay)