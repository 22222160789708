import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { RemoveProfileImage, UploadCoverProfileLogo } from "../../../../../../../../_actions/company_actions";
// import ViewImg from "../ViewImg/ViewImg";
import "./ProfileImage.css";
import { withRouter } from "react-router-dom";
import LoadingIcon from "../../../../../../../utils/LoadingIcon/LoadingIcon";

function ProfileImage(props) {
  const dispatch = useDispatch();
  const [ImagesURL, setImagesURL] = useState(props.Image_Src);
  const [loading, setloading] = useState(false);

  const onDrop = (files) => {
    let formData = new FormData();
    const config = {
      header: { "centent-type": "multipart/form-data" },
    };
    formData.append("file", files[0]);
    formData.append("value", "Profile");
    setloading(true);
    // saving the image inside the node server
    dispatch(UploadCoverProfileLogo(formData, config)).then((response) => {
      if (response.payload.success) {
        // console.log("Result", response);
        setImagesURL(response.payload.result.Location);
        setloading(false);
        // console.log(response.payload.result.Location);
      } else {
        alert("Failed to save the image");
      }
    });
  };

  const handleviewimg = (e) => {
    const clickedel = e.currentTarget;
    const profileimgcontainer = e.currentTarget.parentElement.parentElement.parentElement;
  };

  const handledeleteimg = (image) => {
    
    // const firstindex = image.indexOf(".com/")+5
    // const img_id = image.slice(firstindex , image.length)
   
    // console.log("Delete image", image);
    const variable = {
      imageKey: image.slice(61),
    };
    dispatch(RemoveProfileImage(variable)).then((response) => {
      if(response.payload.success){
        setImagesURL("");
      } else {
        alert("Failed To Update Changes!!! Please Try Again Later")
      }
    })
  };

  return (
    <div className="profile-img-container">
      {ImagesURL ? (
        loading ? (
          <LoadingIcon
            position="absolute"
            width={"100%"}
            height={"100%"}
            outerwidth={"50px"}
            outerheight={"50px"}
            innerdisplay={"none"}
            backgroundcolor={"rgb(56, 56, 56,0.5)"}
          />
        ) : (
          <div className="profile-img">
            <img src={ImagesURL} alt="profile-img" />
            {props.nochange ? null : 
            <div className="profile-img-options">
              <span onClick={handleviewimg} className="material-icons">
                open_in_full
              </span>
              <>
                <Dropzone
                  onDrop={onDrop}
                  multiple={false}
                  maxSize={800000000000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <span className="material-icons">photo_camera</span>
                    </div>
                  )}
                </Dropzone>
              </>
              <span onClick={() => {handledeleteimg(ImagesURL)}} className="material-icons">
                delete_outline
              </span>
            </div>
            }
          </div>
        )
      ) : (
        <div className="no-img-profile-upload">
          <>
            <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000000}>
              {({ getRootProps, getInputProps }) => (
                <div  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="title">
                    <span className="material-icons">add</span>Profile Picture
                  </p>{" "}
                </div>
              )}
            </Dropzone>
          </>
        </div>
      )}
      <br />
      <br />
    </div>
  );
}

export default withRouter(ProfileImage);
