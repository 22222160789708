import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SP_GetAllAnalyticsData, SP_UpdateSelectedLeadStatus } from "../../../../_actions/company_analytics";
import LeadsSelected from "./Utils/LeadsSelected";
// import ListandDetails from "./Utils/ListandDetails";
import ParentTable from "./Utils/ParentTable";
import LoadingIcon from '../../../utils/LoadingIcon/LoadingIcon'
import "./SP_Analytics.css"
import LeadsConverted from "./Utils/LeadsConverted";
import LeadsRejected from "./Utils/LeadsRejected";
import SocialMediaView from "./Utils/SocialMediaView";
import SP_Visits_SP_Profile from "./Utils/SP_Visits_SP_Profile";
import POProfileVisitedDetails from "./Utils/POProfileVisitedDetails";
import SPProfileConnectsReceived from "./Utils/SPProfileConnectsReceived";
import POProfileDetails_Received from "./Utils/POProfileDetails_Received";
import SP_ProfileDetails_Received from "./Utils/SP_ProfileDetails_Received";
import SPVisites_SP_Profile from "./Utils/SPVisites_SP_Profile";
import {withRouter} from 'react-router-dom'

function SP_Analytics(props) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [TotalNumberofProjects, setTotalNumberofProjects] = useState(0);
  const [TotalNumberofImages, setTotalNumberofImages] = useState(0);
  const [TotalNumberLeadsBoughtLength, setTotalNumberLeadsBoughtLength] = useState(0);
  const [LeadsBoughtHistory, setLeadsBoughtHistory] = useState([]);
  const [ShowLoadingIcon, setShowLoadingIcon] = useState(true);
  const [LeadChangeLoadingIcon, setLeadChangeLoadingIcon] = useState(false);
  const [setConvertedLeadsCount, setsetConvertedLeadsCount] = useState(0);
  const [RejectedLeadCount, setRejectedLeadCount] = useState(0);
  const [ConvertedLeads, setConvertedLeads] = useState([]);
  const [RejectedLeads, setRejectedLeads] = useState([]);
  const [PO_Visites_SocialMedia_Facebook, setPO_Visites_SocialMedia_Facebook] = useState(0);
  const [PO_Visites_SocialMedia_Instagram, setPO_Visites_SocialMedia_Instagram] = useState(0);
  const [PO_Visites_SocialMedia_Linkedin, setPO_Visites_SocialMedia_Linkedin] = useState(0);
  const [PO_Visites_SocialMedia_Pinterest, setPO_Visites_SocialMedia_Pinterest] = useState(0);
  const [PO_Visites_SocialMedia_Quora, setPO_Visites_SocialMedia_Quora] = useState(0);
  const [PO_Visites_SocialMedia_Reddit, setPO_Visites_SocialMedia_Reddit] = useState(0);
  const [PO_Visites_SocialMedia_Twitter, setPO_Visites_SocialMedia_Twitter] = useState(0);
  const [PO_Visites_SocialMedia_Youtube, setPO_Visites_SocialMedia_Youtube] = useState(0);
  const [PO_Visites_SocialMedia_WhatsApp, setPO_Visites_SocialMedia_WhatsApp] = useState(0);
  const [PO_Visites_SocialMedia_PhoneCall, setPO_Visites_SocialMedia_PhoneCall] = useState(0);
  const [PO_Visites_SocialMedia_Website_URL, setPO_Visites_SocialMedia_Website_URL] = useState(0);
  const [PO_Visited_SocaialMedia_Instagram_Records, setPO_Visited_SocaialMedia_Instagram_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Facebook_Records, setPO_Visited_SocaialMedia_Facebook_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Linkedin_Records, setPO_Visited_SocaialMedia_Linkedin_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Pinterest_Records, setPO_Visited_SocaialMedia_Pinterest_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Quora_Records, setPO_Visited_SocaialMedia_Quora_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Reddit_Records, setPO_Visited_SocaialMedia_Reddit_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Twitter_Records, setPO_Visited_SocaialMedia_Twitter_Records] = useState([]);
  const [PO_Visited_SocaialMedia_Youtube_Records, setPO_Visited_SocaialMedia_Youtube_Records] = useState([]);
  const [PO_Visited_SocialMedia_WhatsApp_Records, setPO_Visited_SocialMedia_WhatsApp_Records] = useState([]);
  const [PO_Visited_SocialMedia_PhoneNumber_Records, setPO_Visited_SocialMedia_PhoneNumber_Records] = useState([]);
  const [PO_Visited_SocialMedia_Website_URL_Records, setPO_Visited_SocialMedia_Website_URL_Records] = useState([]);
  
  
  // SERVICE PROFESSIONAL
  const [SP_Visites_SocialMedia_Facebook, setSP_Visites_SocialMedia_Facebook] = useState(0);
  const [SP_Visites_SocialMedia_Instagram, setSP_Visites_SocialMedia_Instagram] = useState(0);
  const [SP_Visites_SocialMedia_Linkedin, setSP_Visites_SocialMedia_Linkedin] = useState(0);
  const [SP_Visites_SocialMedia_Pinterest, setSP_Visites_SocialMedia_Pinterest] = useState(0);
  const [SP_Visites_SocialMedia_Quora, setSP_Visites_SocialMedia_Quora] = useState(0);
  const [SP_Visites_SocialMedia_Reddit, setSP_Visites_SocialMedia_Reddit] = useState(0);
  const [SP_Visites_SocialMedia_Twitter, setSP_Visites_SocialMedia_Twitter] = useState(0);
  const [SP_Visites_SocialMedia_Youtube, setSP_Visites_SocialMedia_Youtube] = useState(0);
  const [SP_Visites_SocialMedia_WhatsApp, setSP_Visites_SocialMedia_WhatsApp] = useState(0);
  const [SP_Visites_SocialMedia_PhoneCall, setSP_Visites_SocialMedia_PhoneCall] = useState(0);
  const [SP_Visites_SocialMedia_Website_URL, setSP_Visites_SocialMedia_Website_URL] = useState(0);

  const [SP_Visited_SocaialMedia_Instagram_Records, setSP_Visited_SocaialMedia_Instagram_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Facebook_Records, setSP_Visited_SocaialMedia_Facebook_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Linkedin_Records, setSP_Visited_SocaialMedia_Linkedin_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Pinterest_Records, setSP_Visited_SocaialMedia_Pinterest_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Quora_Records, setSP_Visited_SocaialMedia_Quora_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Reddit_Records, setSP_Visited_SocaialMedia_Reddit_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Twitter_Records, setSP_Visited_SocaialMedia_Twitter_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Youtube_Records, setSP_Visited_SocaialMedia_Youtube_Records] = useState([]);
  const [SP_Visited_SocaialMedia_Youtube_WhatsApp, setSP_Visited_SocaialMedia_Youtube_WhatsApp] = useState([]);
  const [SP_Visited_SocaialMedia_Youtube_Phonecall, setSP_Visited_SocaialMedia_Youtube_Phonecall] = useState([]);
  const [SP_Visited_SocaialMedia_Website_URL_Phonecall, setSP_Visited_SocaialMedia_Website_URL_Phonecall] = useState([]);
  
  const [SP_Visited_SP_SocaialMedia_Instagram_Records, setSP_Visited_SP_SocaialMedia_Instagram_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Facebook_Records, setSP_Visited_SP_SocaialMedia_Facebook_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Linkedin_Records, setSP_Visited_SP_SocaialMedia_Linkedin_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Pinterest_Records, setSP_Visited_SP_SocaialMedia_Pinterest_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Quora_Records, setSP_Visited_SP_SocaialMedia_Quora_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Reddit_Records, setSP_Visited_SP_SocaialMedia_Reddit_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Twitter_Records, setSP_Visited_SP_SocaialMedia_Twitter_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Youtube_Records, setSP_Visited_SP_SocaialMedia_Youtube_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_WhatsApp_Records, setSP_Visited_SP_SocaialMedia_WhatsApp_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_PhoneCall_Records, setSP_Visited_SP_SocaialMedia_PhoneCall_Records] = useState([]);
  const [SP_Visited_SP_SocaialMedia_Website_URL_Records, setSP_Visited_SP_SocaialMedia_Website_URL_Records] = useState([]);
  

  // SERVICE PROFESSIONAL VISITS OTHER SP PROFILE
  const [SP_Visits_SP_Facebook, setSP_Visits_SP_Facebook] = useState(0);
  const [SP_Visits_SP_Instagram, setSP_Visits_SP_Instagram] = useState(0);
  const [SP_Visits_SP_Linkedin, setSP_Visits_SP_Linkedin] = useState(0);
  const [SP_Visits_SP_Pinterest, setSP_Visits_SP_Pinterest] = useState(0);
  const [SP_Visits_SP_Quora, setSP_Visits_SP_Quora] = useState(0);
  const [SP_Visits_SP_Reddit, setSP_Visits_SP_Reddit] = useState(0);
  const [SP_Visits_SP_Twitter, setSP_Visits_SP_Twitter] = useState(0);
  const [SP_Visits_SP_Youtube, setSP_Visits_SP_Youtube] = useState(0);
  const [SP_Visits_SP_WhatsApp, setSP_Visits_SP_WhatsApp] = useState(0);
  const [SP_Visits_SP_Phonecall, setSP_Visits_SP_Phonecall] = useState(0);
  const [SP_Visits_SP_Website_URL, setSP_Visits_SP_Website_URL] = useState(0);



  let imagesnumber = 0;
  let convetredleadcount = 0;
  let rejectedleadcount = 0;
  // PROPERTY OWNER
  let PO_visites_social_media_Facebook = 0;
  let PO_visites_social_media_Instagram = 0;
  let PO_visites_social_media_Linkedin = 0;
  let PO_visites_social_media_Pinterest = 0;
  let PO_visites_social_media_Quora = 0;
  let PO_visites_social_media_Reddit = 0;
  let PO_visites_social_media_Twitter = 0;
  let PO_visites_social_media_Youtube = 0;
  let PO_visites_social_media_WhatsApp = 0;
  let PO_visites_social_media_PhoneNumber = 0;
  let PO_visites_social_media_Website_URL = 0;

  // SERVICE PREOFESSIONAL
  let SP_visites_social_media_Facebook = 0;
  let SP_visites_social_media_Instagram = 0;
  let SP_visites_social_media_Linkedin = 0;
  let SP_visites_social_media_Pinterest = 0;
  let SP_visites_social_media_Quora = 0;
  let SP_visites_social_media_Reddit = 0;
  let SP_visites_social_media_Twitter = 0;
  let SP_visites_social_media_Youtube = 0;
  let SP_visites_social_media_WhatsApp = 0;
  let SP_visites_social_media_PhoneCall = 0;
  let SP_visites_social_media_Website_URL = 0;

  // SERVICE PREOFESSIONAL VISITS SP
  let SP_visits_SP_social_media_Facebook = 0;
  let SP_visits_SP_social_media_Instagram = 0;
  let SP_visits_SP_social_media_Linkedin = 0;
  let SP_visits_SP_social_media_Pinterest = 0;
  let SP_visits_SP_social_media_Quora = 0;
  let SP_visits_SP_social_media_Reddit = 0;
  let SP_visits_SP_social_media_Twitter = 0;
  let SP_visits_SP_social_media_Youtube = 0;
  let SP_visits_SP_social_media_WhatsApp = 0;
  let SP_visits_SP_social_media_Phonecall = 0;
  let SP_visits_SP_social_media_Website_URL = 0;

  // PROPERTY OWNER PROFILE VISITED
  const [PO_Profile_Visited, setPO_Profile_Visited] = useState([]);

  // PROPERTY OWNER PROFILE RECEIVED
  const [PO_Profile_Received, setPO_Profile_Received] = useState([]);
  const [Count_PO_Profile_Received, setCount_PO_Profile_Received] = useState(0);
  let PO_Profile_Received_Count = 0;
  
  // SERVICE PROFESSIONAL PROFILE RECEIVED
  const [SP_Profile_Received, setSP_Profile_Received] = useState([]);
  const [Count_SP_Profile_Received, setCount_SP_Profile_Received] = useState(0);
  let SP_Profile_Received_Count = 0;

  // SERVICE PROFESSIONAL VISITED OTHER SERVICE PROFESSIONAL PROFILE
  const [SP_ProfileVisted_by_SP, setSP_ProfileVisted_by_SP] = useState([]);

  function getAnalytics() {
    dispatch(SP_GetAllAnalyticsData())
    .then((response) => {
      if(response.payload.success){
        // console.log("Response Analytics Data ", response);
        setTotalNumberofProjects(response.payload.result.project.length);
        setTotalNumberLeadsBoughtLength(response.payload.result.analytics[0].history.length)
        // console.log("response.payload.result.analytics[0].history", response.payload.result.analytics[0].history);
        setLeadsBoughtHistory(response.payload.result.analytics[0].history);
        setPO_Profile_Visited(response.payload.result.analytics[0].Analytics.PO_Profile_Visited)
        setSP_ProfileVisted_by_SP(response.payload.result.analytics[0].Analytics.Profile_Visited_To)
        response.payload.result.project.map(index => {
          // console.log("Index ",index.Images.length);
            imagesnumber = imagesnumber + index.Images.length;
        })
        response.payload.result.analytics[0].history.map(index => {
          // console.log("Leads Details", index.LeadStatus);
          if(index.LeadStatus === "Converted"){
            convetredleadcount = convetredleadcount + 1;
            ConvertedLeads.push(index);
            // console.log("Converted Lead Details ", index);
          }
          if(index.LeadStatus === "Rejected"){
            rejectedleadcount = rejectedleadcount + 1;
            RejectedLeads.push(index);
          }
        })
        response.payload.result.analytics[0].Analytics.Profile_Visited_By.map(index => {
          if(index.Visiter === "PO"){
            PO_Profile_Received_Count = PO_Profile_Received_Count + 1;
            PO_Profile_Received.push(index);
          }
          if(index.Visiter === "SP"){
            SP_Profile_Received_Count = SP_Profile_Received_Count + 1;
            SP_Profile_Received.push(index);
          }
        })
        response.payload.result.analytics[0].Analytics.Social_Media_Visited_By.map(index => {
          // console.log("Social Media ", index);
  
          // PROPERTY OWNER
          if(index.Social_Media === "Instagram" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Instagram = PO_visites_social_media_Instagram + 1;
            PO_Visited_SocaialMedia_Instagram_Records.push(index);
          }
          if(index.Social_Media === "Facebook" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Facebook = PO_visites_social_media_Facebook + 1;
            PO_Visited_SocaialMedia_Facebook_Records.push(index);
          }
          if(index.Social_Media === "Linkedin" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Linkedin = PO_visites_social_media_Linkedin + 1;
            PO_Visited_SocaialMedia_Linkedin_Records.push(index);
          }
          if(index.Social_Media === "Pinterest" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Pinterest = PO_visites_social_media_Pinterest + 1;
            PO_Visited_SocaialMedia_Pinterest_Records.push(index);
          }
          if(index.Social_Media === "Quora" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Quora = PO_visites_social_media_Quora + 1;
            PO_Visited_SocaialMedia_Quora_Records.push(index);
          }
          if(index.Social_Media === "Reddit" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Reddit = PO_visites_social_media_Reddit + 1;
            PO_Visited_SocaialMedia_Reddit_Records.push(index);
          }
          if(index.Social_Media === "Twitter" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Twitter = PO_visites_social_media_Twitter + 1;
            PO_Visited_SocaialMedia_Twitter_Records.push(index);
          }
          if(index.Social_Media === "Youtube" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Youtube = PO_visites_social_media_Youtube + 1;
            PO_Visited_SocaialMedia_Youtube_Records.push(index);
          }
          if(index.Social_Media === "WhatsApp" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_WhatsApp = PO_visites_social_media_WhatsApp + 1;
            PO_Visited_SocialMedia_WhatsApp_Records.push(index);
          }
          if(index.Social_Media === "PhoneCall" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_PhoneNumber = PO_visites_social_media_PhoneNumber + 1;
            PO_Visited_SocialMedia_PhoneNumber_Records.push(index);
          }
          if(index.Social_Media === "Website_URL" && index.Visiter === "PropertyOwner"){
            PO_visites_social_media_Website_URL = PO_visites_social_media_Website_URL + 1;
            PO_Visited_SocialMedia_Website_URL_Records.push(index);
          }
          
          // SERVICE PROFESSIONAL
          if(index.Social_Media === "Instagram" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Instagram = SP_visites_social_media_Instagram + 1;
            SP_Visited_SocaialMedia_Instagram_Records.push(index);
          }
          if(index.Social_Media === "Facebook" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Facebook = SP_visites_social_media_Facebook + 1;
            SP_Visited_SocaialMedia_Facebook_Records.push(index);
          }
          if(index.Social_Media === "Linkedin" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Linkedin = SP_visites_social_media_Linkedin + 1;
            SP_Visited_SocaialMedia_Linkedin_Records.push(index);
          }
          if(index.Social_Media === "Pinterest" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Pinterest = SP_visites_social_media_Pinterest + 1;
            SP_Visited_SocaialMedia_Pinterest_Records.push(index);
          }
          if(index.Social_Media === "Quora" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Quora = SP_visites_social_media_Quora + 1;
            SP_Visited_SocaialMedia_Quora_Records.push(index);
          }
          if(index.Social_Media === "Reddit" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Reddit = SP_visites_social_media_Reddit + 1;
            SP_Visited_SocaialMedia_Reddit_Records.push(index);
          }
          if(index.Social_Media === "Twitter" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Twitter = SP_visites_social_media_Twitter + 1;
            SP_Visited_SocaialMedia_Twitter_Records.push(index);
          }
          if(index.Social_Media === "Youtube" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Youtube = SP_visites_social_media_Youtube + 1;
            SP_Visited_SocaialMedia_Youtube_Records.push(index);
          }
          if(index.Social_Media === "WhatsApp" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_WhatsApp = SP_visites_social_media_WhatsApp + 1;
            SP_Visited_SocaialMedia_Youtube_WhatsApp.push(index);
          }
          if(index.Social_Media === "PhoneCall" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_PhoneCall = SP_visites_social_media_PhoneCall + 1;
            SP_Visited_SocaialMedia_Youtube_Phonecall.push(index);
          }
          if(index.Social_Media === "Website_URL" && index.Visiter === "ServiceProfessional"){
            SP_visites_social_media_Website_URL = SP_visites_social_media_Website_URL + 1;
            SP_Visited_SocaialMedia_Website_URL_Phonecall.push(index);
          }
  
        })
        // console.log("SP Profile Visited To ", response.payload.result.analytics[0].Analytics.Social_Media_Visited_To);
        response.payload.result.analytics[0].Analytics.Social_Media_Visited_To.map(index=> {
          if(index.Social_Media === "Instagram"){
            SP_visits_SP_social_media_Instagram = SP_visits_SP_social_media_Instagram + 1;
            SP_Visited_SP_SocaialMedia_Instagram_Records.push(index);
          }
          if(index.Social_Media === "Facebook"){
            SP_visits_SP_social_media_Facebook = SP_visits_SP_social_media_Facebook + 1;
            SP_Visited_SP_SocaialMedia_Facebook_Records.push(index);
          }
          if(index.Social_Media === "Linkedin"){
            SP_visits_SP_social_media_Linkedin = SP_visits_SP_social_media_Linkedin + 1;
            SP_Visited_SP_SocaialMedia_Linkedin_Records.push(index);
          }
          if(index.Social_Media === "Pinterest"){
            SP_visits_SP_social_media_Pinterest = SP_visits_SP_social_media_Pinterest + 1;
            SP_Visited_SP_SocaialMedia_Pinterest_Records.push(index);
          }
          if(index.Social_Media === "Quora"){
            SP_visits_SP_social_media_Quora = SP_visits_SP_social_media_Quora + 1;
            SP_Visited_SP_SocaialMedia_Quora_Records.push(index);
          }
          if(index.Social_Media === "Reddit"){
            SP_visits_SP_social_media_Reddit = SP_visits_SP_social_media_Reddit + 1;
            SP_Visited_SP_SocaialMedia_Reddit_Records.push(index);
          }
          if(index.Social_Media === "Twitter"){
            SP_visits_SP_social_media_Twitter = SP_visits_SP_social_media_Twitter + 1;
            SP_Visited_SP_SocaialMedia_Twitter_Records.push(index);
          }
          if(index.Social_Media === "Youtube"){
            SP_visits_SP_social_media_Youtube = SP_visits_SP_social_media_Youtube + 1;
            SP_Visited_SP_SocaialMedia_Youtube_Records.push(index);
          }
          if(index.Social_Media === "WhatsApp"){
            SP_visits_SP_social_media_WhatsApp = SP_visits_SP_social_media_WhatsApp + 1;
            SP_Visited_SP_SocaialMedia_WhatsApp_Records.push(index);
          }
          if(index.Social_Media === "PhoneCall"){
            SP_visits_SP_social_media_Phonecall = SP_visits_SP_social_media_Phonecall + 1;
            SP_Visited_SP_SocaialMedia_PhoneCall_Records.push(index);
          }
          if(index.Social_Media === "Website_URL"){
            SP_visits_SP_social_media_Website_URL = SP_visits_SP_social_media_Website_URL + 1;
            SP_Visited_SP_SocaialMedia_Website_URL_Records.push(index);
          }
        })
        setTotalNumberofImages(imagesnumber);
        setsetConvertedLeadsCount(convetredleadcount);
        setRejectedLeadCount(rejectedleadcount);
        // PROPERTY OWNER
        setPO_Visites_SocialMedia_Facebook(PO_visites_social_media_Facebook);
        setPO_Visites_SocialMedia_Instagram(PO_visites_social_media_Instagram);
        setPO_Visites_SocialMedia_Linkedin(PO_visites_social_media_Linkedin);
        setPO_Visites_SocialMedia_Pinterest(PO_visites_social_media_Pinterest);
        setPO_Visites_SocialMedia_Quora(PO_visites_social_media_Quora);
        setPO_Visites_SocialMedia_Reddit(PO_visites_social_media_Reddit);
        setPO_Visites_SocialMedia_Twitter(PO_visites_social_media_Twitter);
        setPO_Visites_SocialMedia_Youtube(PO_visites_social_media_Youtube);
        setPO_Visites_SocialMedia_WhatsApp(PO_visites_social_media_WhatsApp);
        setPO_Visites_SocialMedia_PhoneCall(PO_visites_social_media_PhoneNumber);
        setPO_Visites_SocialMedia_Website_URL(PO_visites_social_media_Website_URL);
        
        // SERVICE PREOFESSIONAL
        setSP_Visites_SocialMedia_Facebook(SP_visites_social_media_Facebook);
        setSP_Visites_SocialMedia_Instagram(SP_visites_social_media_Instagram);
        setSP_Visites_SocialMedia_Linkedin(SP_visites_social_media_Linkedin);
        setSP_Visites_SocialMedia_Pinterest(SP_visites_social_media_Pinterest);
        setSP_Visites_SocialMedia_Quora(SP_visites_social_media_Quora);
        setSP_Visites_SocialMedia_Reddit(SP_visites_social_media_Reddit);
        setSP_Visites_SocialMedia_Twitter(SP_visites_social_media_Twitter);
        setSP_Visites_SocialMedia_Youtube(SP_visites_social_media_Youtube);
        setSP_Visites_SocialMedia_WhatsApp(SP_visites_social_media_WhatsApp);
        setSP_Visites_SocialMedia_PhoneCall(SP_visites_social_media_PhoneCall);
        setSP_Visites_SocialMedia_Website_URL(SP_visites_social_media_Website_URL);
  
        // SERVICE PROFESSIONAL VISITS SP
        setSP_Visits_SP_Facebook(SP_visits_SP_social_media_Facebook);
        setSP_Visits_SP_Instagram(SP_visits_SP_social_media_Instagram);
        setSP_Visits_SP_Linkedin(SP_visits_SP_social_media_Linkedin);
        setSP_Visits_SP_Pinterest(SP_visits_SP_social_media_Pinterest);
        setSP_Visits_SP_Quora(SP_visits_SP_social_media_Quora);
        setSP_Visits_SP_Reddit(SP_visits_SP_social_media_Reddit);
        setSP_Visits_SP_Twitter(SP_visits_SP_social_media_Twitter);
        setSP_Visits_SP_Youtube(SP_visits_SP_social_media_Youtube);
        setSP_Visits_SP_WhatsApp(SP_visits_SP_social_media_WhatsApp);
        setSP_Visits_SP_Phonecall(SP_visits_SP_social_media_Phonecall);
        setSP_Visits_SP_Website_URL(SP_visits_SP_social_media_Website_URL);
  
        // PROPERTY OWNER VISITED SERVICE PROFESSIONAL PROFILE
        setCount_PO_Profile_Received(PO_Profile_Received_Count);
  
        // SERVICE PROFESSIONAL VISITED SERVICE PROFESSIONAL PROFILE
        setCount_SP_Profile_Received(SP_Profile_Received_Count);
  
        setShowLoadingIcon(false)
      }
    });
  }
  useEffect(() => {
    getAnalytics();
  }, []);

  // Change of Lead Status
  function ChangeofLeadStatus(LeadId, StatusToChange, OrderId) {
    // console.log("Change of Lead Status Parent Page ", LeadId, StatusToChange, OrderId);
    setLeadChangeLoadingIcon(true);
    setShowLoadingIcon(true);
    var variables = {
      LeadId: LeadId,
      StatusToChange: StatusToChange,
      OrderId: OrderId,
    };
    window.scroll({
      top: 500,
      left: 100,
      behavior: 'smooth'
    });
    dispatch(SP_UpdateSelectedLeadStatus(variables)).then((response) => {
      // console.log("Lead Status Updated ", response);
      setLeadChangeLoadingIcon(false);
      setShowLoadingIcon(false);
          getAnalytics();
        })
  }

  const handlelistview = (e) => {
    const heading = e.currentTarget;
    const analyticscontainer =
      heading.parentElement.parentElement.parentElement.parentElement
        .childNodes;

    if (heading.innerText === "View List and Details") {
      analyticscontainer.forEach((e) => {
        if (e.id === heading.id) {
          e.style.display = "block";
          heading.innerText = "Close List and Details";
          heading.style.color = "red";
          heading.style.textDecorationColor = "red";
          setTimeout(() => {
            e.style.opacity = 1;
            e.style.top = 0;
          }, 10);
        }
      });
    } else {
      analyticscontainer.forEach((e) => {
        if (e.id === heading.id) {
          e.style.opacity = 0;
          e.style.top = "-100px";
          heading.innerText = "View List and Details";
          heading.style.color = "#1dc295";

          heading.style.textDecorationColor = "#1dc295";
          setTimeout(() => {
            e.style.display = "none";
          }, 300);
        }
      });
    }
  };

  if(company.companyData && company.companyData.isAuth && (company.companyData.Subscribed || company.companyData.Membership))
  {
    return (
      <div>
        { ShowLoadingIcon ? 
        <>
          {LeadChangeLoadingIcon ? 
          <LoadingIcon backgroundcolor={"grey"} opacity={0.5} /> 
            :
          <LoadingIcon /> 
          }
        </>
          :
          <div className="sp-analytics-container">
          <div className="header">
          <p className="title">
            <b>Service Professional Business Profile Performance & Analytics</b>
          </p>
          <p
            style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
            className="description"
          >
            Here, you can view all the Stats related to your Business Profile
          </p>
      </div>
        <div className="sp-analytics">
          <br />
          <br />
          <div className="analytics-container">
            <div className="analytics-table-title">
              <p>Business Profile Activity</p>
            </div>
            <ParentTable
              subheadings={["Project Uploaded", "Images Uploaded"]}
              data={[TotalNumberofProjects, TotalNumberofImages]}
              handlelistview={handlelistview}
            />
            <br />
          </div>
  <br/>
          <div className="analytics-container">
            <div className="analytics-table-title">
              <p>Business Profile Performance</p>
            </div>
            <ParentTable
              mainheading="Business Profile Productivity"
              subheadings={[
                "Leads Selected",
                "Leads Converted",
                "Leads Rejected",
                "Productivity Percentage (%)",
              ]}
              data={[TotalNumberLeadsBoughtLength, setConvertedLeadsCount, RejectedLeadCount, parseFloat((setConvertedLeadsCount/TotalNumberLeadsBoughtLength)*100).toFixed(2)]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <LeadsSelected
            ChangeofLeadStatus={ChangeofLeadStatus}
              dataandtimeheading="Date & Time of Lead Selection"
              id="Leads Selected"
              thisleadwas={"Converted"}
              LeadsDetails={LeadsBoughtHistory}
              SPName={company.companyData.name}
            />
            <LeadsConverted
              dataandtimeheading="Date & Time of Lead Selection"
              LeadsDetails={ConvertedLeads}
              thisleadwas={"Converted"}
              id="Leads Converted"
              SPName={company.companyData.name}
            />
            <LeadsRejected
              dataandtimeheading="Date & Time of Lead Selection"
              LeadsDetails={RejectedLeads}
              thisleadwas={"Rejected"}
              id="Leads Rejected"
              SPName={company.companyData.name}
            />
           
          </div>
          <br />
          <div className="analytics-container">
            <div className="analytics-table-title">
              <p>Contact Activity & Engagement</p>
            </div>
            <ParentTable
              mainheading={"Visitor Stats"}
              subheadings={[
                "Property Owner Profiles Visited",
                "Property Owner Profile Visits Received",
                "Service Professional Profiles Visited",
                "Service Professional Profile Visits Received",
              ]}
              data={[PO_Profile_Visited.length, Count_PO_Profile_Received, SP_ProfileVisted_by_SP.length, Count_SP_Profile_Received]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <POProfileVisitedDetails
              dataandtimeheading="Date & Time of Visiting the Property Owner Profile"
              id="Property Owner Profiles Visited"
              Identity="PO"
              ProfileDatas={PO_Profile_Visited}
            />
            <POProfileDetails_Received
              dataandtimeheading="Date & Time of Profile Visit Received from the Property Owner Profile"
              id="Property Owner Profile Visits Received"
              Identity="PO"
              ProfileDatas={PO_Profile_Received}
            />
            <SPVisites_SP_Profile
              dataandtimeheading="Date & Time of Visiting the Service Professional Profile "
              id="Service Professional Profiles Visited"
              Identity="SP"
              ProfileDatas={SP_ProfileVisted_by_SP}
            />
            <SP_ProfileDetails_Received
              dataandtimeheading="Date & Time of Profile Visit Received from the Service Professional Profile"
              id="Service Professional Profile Visits Received"
              Identity="SP"
              ProfileDatas={SP_Profile_Received}
            />
           
          </div>
          <br />
          <div className="analytics-container">
            <ParentTable
              mainheading={
                "Property Owner Profile Connects Received Via NebourHood On  "
              }
              subheadings={[
                "Phone Call",
                "WhatsApp",
                "Website Url",
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Pinterest",
                "Quora",
                "Reddit",
                "Twitter",
                "Youtube",
              ]}
              data={[
                PO_Visites_SocialMedia_PhoneCall, 
                PO_Visites_SocialMedia_WhatsApp, 
                PO_Visites_SocialMedia_Website_URL, 
                PO_Visites_SocialMedia_Facebook, 
                PO_Visites_SocialMedia_Instagram, 
                PO_Visites_SocialMedia_Linkedin, 
                PO_Visites_SocialMedia_Pinterest, 
                PO_Visites_SocialMedia_Quora, 
                PO_Visites_SocialMedia_Reddit, 
                PO_Visites_SocialMedia_Twitter, 
                PO_Visites_SocialMedia_Youtube]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <SocialMediaView
              dataandtimeheading="Date & Time of Phone Call Connect Received "
              id="Phone Call"
              Identity="PO"
              LeadsDetails={PO_Visited_SocialMedia_PhoneNumber_Records}
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of WhatsApp Connect Received "
              id="WhatsApp"
              Identity="PO"
              LeadsDetails={PO_Visited_SocialMedia_WhatsApp_Records}
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Website Url Connect Received "
              id="Website Url"
              Identity="PO"
              LeadsDetails={PO_Visited_SocialMedia_Website_URL_Records}
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Facebook Connect Received "
              id="Facebook"
              LeadsDetails={PO_Visited_SocaialMedia_Facebook_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Instagram Connect Received "
              id="Instagram"
              LeadsDetails={PO_Visited_SocaialMedia_Instagram_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of LinkedIn Connect Received "
              id="LinkedIn"
              LeadsDetails={PO_Visited_SocaialMedia_Linkedin_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Pinterest Connect Received "
              id="Pinterest"
              LeadsDetails={PO_Visited_SocaialMedia_Pinterest_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Quora Connect Received "
              id="Quora"
              LeadsDetails={PO_Visited_SocaialMedia_Quora_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Reddit Connect Received "
              id="Reddit"
              LeadsDetails={PO_Visited_SocaialMedia_Reddit_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Twitter Connect Received "
              id="Twitter"
              LeadsDetails={PO_Visited_SocaialMedia_Twitter_Records}
              Identity="PO"
            />
  
            <SocialMediaView
              dataandtimeheading="Date & Time of Youtube Connect Received "
              id="Youtube"
              LeadsDetails={PO_Visited_SocaialMedia_Youtube_Records}
              Identity="PO"
            />
  
           
          </div>
          {/* <br />
          <div className="analytics-container">
            <ParentTable
              mainheading={
                "Property Owner Profiles Contacted Via NebourHood On  "
              }
              subheadings={["Phone Call", "WhatsApp"]}
              data={[1, 2]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <ListandDetails
              dataandtimeheading="Date & Time of Contact by Phone Call "
              id="Phone Call"
              Identity="PO"
            />
  
            <ListandDetails
              dataandtimeheading="Date & Time of Contact by WhatsApp"
              id="WhatsApp"
              Identity="PO"
            />
          </div> */}
          <br />
          <div className="analytics-container">
            <ParentTable
              mainheading={
                "Service Professional Profile Connects Received Via NebourHood On  "
              }
              subheadings={[
                "Phone Call",
                "WhatsApp",
                "Website Url",
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Pinterest",
                "Quora",
                "Reddit",
                "Twitter",
                "Youtube",
              ]}
              data={[
                    SP_Visites_SocialMedia_PhoneCall, 
                    SP_Visites_SocialMedia_WhatsApp, 
                    SP_Visites_SocialMedia_Website_URL, 
                    SP_Visites_SocialMedia_Facebook,
                    SP_Visites_SocialMedia_Instagram, 
                    SP_Visites_SocialMedia_Linkedin, 
                    SP_Visites_SocialMedia_Pinterest, 
                    SP_Visites_SocialMedia_Quora, 
                    SP_Visites_SocialMedia_Reddit, 
                    SP_Visites_SocialMedia_Twitter, 
                    SP_Visites_SocialMedia_Youtube
                    ]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Phone Call Connect Received "
              id="Phone Call"
              Identity="SP"
              LeadsDetails={SP_Visited_SocaialMedia_Youtube_Phonecall}
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of WhatsApp Connect Received "
              id="WhatsApp"
              Identity="SP"
              LeadsDetails={SP_Visited_SocaialMedia_Youtube_WhatsApp}
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Website Url Connect Received "
              id="Website Url"
              Identity="SP"
              LeadsDetails={SP_Visited_SocaialMedia_Website_URL_Phonecall}
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Facebook Connect Received "
              id="Facebook"
              LeadsDetails={SP_Visited_SocaialMedia_Facebook_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Instagram Connect Received "
              id="Instagram"
              LeadsDetails={SP_Visited_SocaialMedia_Instagram_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of LinkedIn Connect Received "
              id="LinkedIn"
              LeadsDetails={SP_Visited_SocaialMedia_Linkedin_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Pinterest Connect Received "
              id="Pinterest"
              LeadsDetails={SP_Visited_SocaialMedia_Pinterest_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Quora Connect Received "
              id="Quora"
              LeadsDetails={SP_Visited_SocaialMedia_Quora_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Reddit Connect Received "
              id="Reddit"
              LeadsDetails={SP_Visited_SocaialMedia_Reddit_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Twitter Connect Received "
              id="Twitter"
              LeadsDetails={SP_Visited_SocaialMedia_Twitter_Records}
              Identity="SP"
            />
  
            <SPProfileConnectsReceived
              dataandtimeheading="Date & Time of Youtube Connect Received "
              id="Youtube"
              LeadsDetails={SP_Visited_SocaialMedia_Youtube_Records}
              Identity="SP"
            />
          </div>
          <br />


          <div className="analytics-container">
            <ParentTable
              mainheading={
                "Service Professional Profiles Contacted Via NebourHood On  "
              }
              subheadings={[
                "Phone Call",
                "WhatsApp",
                "Website Url",
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Pinterest",
                "Quora",
                "Reddit",
                "Twitter",
                "Youtube",
              ]}
              data={[
                    SP_Visits_SP_Phonecall, 
                    SP_Visits_SP_WhatsApp, 
                    SP_Visits_SP_Website_URL, 
                    SP_Visits_SP_Facebook, 
                    SP_Visits_SP_Instagram, 
                    SP_Visits_SP_Linkedin, 
                    SP_Visits_SP_Pinterest, 
                    SP_Visits_SP_Quora, 
                    SP_Visits_SP_Reddit, 
                    SP_Visits_SP_Twitter,
                    SP_Visits_SP_Youtube
                    ]}
              listanddetails={true}
              handlelistview={handlelistview}
            />
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Phone Call "
              id="Phone Call"
              Identity="SP"
              LeadsDetails={SP_Visited_SP_SocaialMedia_PhoneCall_Records}
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by WhatsApp"
              id="WhatsApp"
              Identity="SP"
              LeadsDetails={SP_Visited_SP_SocaialMedia_WhatsApp_Records}
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Website Url"
              id="Website Url"
              Identity="SP"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Website_URL_Records}
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Facebook"
              id="Facebook"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Facebook_Records}
              Identity="SP"
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Instagram"
              id="Instagram"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Instagram_Records}
              Identity="SP"
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by LinkedIn"
              id="LinkedIn"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Linkedin_Records}
              Identity="SP"
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Pinterest"
              id="Pinterest"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Pinterest_Records}
              Identity="SP"
            />
  
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Quora"
              id="Quora"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Quora_Records}
              Identity="SP"
            />
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Reddit"
              id="Reddit"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Reddit_Records}
              Identity="SP"
            />
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Twitter"
              id="Twitter"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Twitter_Records}
              Identity="SP"
            />
            <SP_Visits_SP_Profile
              dataandtimeheading="Date & Time of Contact by Youtube"
              id="Youtube"
              LeadsDetails={SP_Visited_SP_SocaialMedia_Youtube_Records}
              Identity="SP"
            />
          </div>
        </div>
        <br />
        <br />
      </div>
        }
      </div>
    );
  } else {
    return (
      <div>
      <br />
      <br />
      <div>
                <p style={{ fontSize: '1.8em', marginTop: '10px', fontWeight: '700', color: '#1F80E0' }}>You can unlock exclusive benefits by becoming a NebourHood Prime S.P. Member with the following features!</p>
                    <ul style={{ fontSize: '1.2em', marginTop: '10px' }}>
                        <li>Access to All India Community & Events</li>
                        <li>Early access to Fresh Property Requirement Lead Updates Daily</li>
                        <li>Exclusive Discounts on all property requirement Leads & all property requirement lead Plans</li>
                        <li>Access to Performance Metrics & Business Growth Analytics</li>
                        <li>Exclusive Job Postings & Freelance Opportunities</li>
                        <li>Networking & Collabs with other service professionals</li>
                        <li>Unlock your Prime S.P. Profile on NebourHood</li>
                        <li>Add unlimited Branches to your Prime S.P. Profile to appear in filtered searches from other cities on NebourHood</li>
                        <li>Upload unlimited projects on NebourHood</li>
                        <li>And much more!</li>
                    </ul>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <button style={{ fontSize: '1.5em', backgroundColor: '#1F80E0', color: 'white', fontWeight: '600', padding: '10px', borderRadius: '5px', border: 'none' }} onClick={() => props.history.push("/ServiceProfessional/Pricing/Membership")}>Click here to join the NebourHood Prime S.P. Community Now!</button>
                    </div>
              </div>
      </div>
    )
  }
}

export default withRouter(SP_Analytics);
