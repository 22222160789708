import React from 'react'
import './MembershipDisclaimer.css'
import { withRouter } from "react-router-dom";
import { useSelector } from 'react-redux';

function MembershipDisclaimer(props) {
    const company = useSelector((state) => state.company);
    
    if(company.companyData && company.companyData.isAuth && company.companyData.Membership){
        return (
            <div className='Membership-div1'>
                {props.page === "Cart" || props.page === "MyStats" ? 
                <div>
                    <p className='ptagwithoutcolor'>Thank you for being a valued NebourHood Prime S.P. Member! If you haven’t already, you can <a onClick={() => props.history.push(`/ServiceProfessional/Pricing/Membership`)} style={{ color: '#1F80E0' }}>click here</a> to Renew your Prime Membership Now!</p>
                </div>
                : null}
                {props.page === "MyPlanInvoice" ? 
                <div>
                    <p className='ptagwithoutcolor'>Thank you for being a valued NebourHood Prime S.P. Member! You have saved an additional overall total of Rs. {props.amount} across all the above orders! If you haven’t already, you can click here to Renew your Prime Membership Now!</p>
                </div>
                : null}
                {props.page === "MyPrime" ? 
                <div>
                    <p className='ptagwithcolor'>Thank you for being a valued NebourHood Prime S.P. Member! You have saved an additional overall total of Rs. {props.amount} across all the above orders! If you haven’t already, you can click here to Renew your Prime Membership Now!</p>
                </div>
                : null}
                {props.page === "MyPrimeInvoice" ? 
                <div>
                    <p className='ptagwithcolor'>Thank you for being a valued NebourHood Prime S.P. Member! You have saved an additional overall total of Rs. {props.amount} across all the above orders! If you haven’t already, you can click here to Renew your Prime Membership Now!</p>
                </div>
                : null}
                {props.page === "MyPlans" || props.page === "MyLeads"  ? 
                <div>
                    <p className='ptagwithcolor'>Thank you for being a valued NebourHood Prime S.P. Member! You have saved an additional overall total of Rs. {props.amount} across all the above orders! If you haven’t already, you can click here to Renew your Prime Membership Now!</p>
                </div>
                : null}
            </div>
        )
    } else {
        return (
            <div className='Membership-div1'>
                {props.page === "Cart" ? 
                    <div>
                        <p className='ptagwithcolor'>You could save an additional overall total of Rs. {props.amount} on the above order & unlock exclusive benefits by becoming a NebourHood Prime S.P. Member with the following features!</p>
                    </div>
                : null}
                {props.page === "MyPlans" || props.page === "MyLeads" ?
                    <div>
                        <p className='ptagwithcolor'>You could save an additional overall total of Rs. {props.amount} across all the above orders & unlock exclusive benefits by becoming a NebourHood Prime S.P. Member with the following features!</p>
                    </div>
                : null}
                {props.page === "MyPrime" || props.page === "MyStats" ?
                    <div>
                        <p className='ptagwithcolor'>You can unlock exclusive benefits by becoming a NebourHood Prime S.P. Member!</p>
                    </div>
                : null}
                {props.page === "MyPlanInvoice" ?
                    <div>
                        <p className='ptagwithcolor'>You could save an additional Rs. {props.amount} on this order & unlock exclusive benefits by becoming a NebourHood Prime S.P. Member with the following features!</p>
                    </div>
                : null}
                <div className='primeFeaturesList'>
                    <ul>
                        <li>Access to All India Community & Events</li>
                        <li>Early access to Fresh Property Requirement Lead Updates Daily</li>
                        <li>Exclusive Discounts on all property requirement Leads & all property requirement lead Plans</li>
                        <li>Access to Performance Metrics & Business Growth Analytics</li>
                        <li>Exclusive Job Postings & Freelance Opportunities</li>
                        <li>Networking & Collabs with other service professionals</li>
                        <li>And much more!</li>
                    </ul>
                </div>
                <div className='button-container'>
                <button className='buttonDesign' onClick={() => props.history.push("/ServiceProfessional/Pricing/Membership")}>Click here to join the NebourHood Prime S.P. Community Now!</button>
                </div>
            </div>
        )
    }
}

export default withRouter(MembershipDisclaimer)