import React, { useEffect, useLayoutEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import "./Login.css";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import { useDispatch } from "react-redux";
import { loginUser, loginUserGoogle } from "../../../../_actions/user_actions";
import GoogleLogin from 'react-google-login'
import PageBanner from "../../../utils/PageBanner/PageBanner";

function Login(props) {
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [login, setlogin] = useState(false);
  const [email, setemail] = useState("@gmail.com");
  const [phone, setphone] = useState("No__Number");
  const [password, setpassword] = useState("");

  const userdetails = {
    email: email,
    phone: phone,
    password: password,
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [screenwidth]);

  useEffect(() => {
    if (screenwidth <= 670) {
      // document.getElementById("username").style.width = `${screenwidth - 50}px`;
      document.getElementById("email").style.width = `${screenwidth - 50}px`;
      document.getElementById("password").style.width = `${screenwidth - 50}px`;
    }
  }, [screenwidth]);


  const handleemailorphone = (e) => {
    
    const isemail = e.target.value.includes("@");
    if (isemail) {
      setemail(e.target.value);
   setphone("NoNumberProvided")
    } else {
      setphone(e.target.value);
      setemail("NoEmailProvided")
    }
  };

  const handlepassword = (e) => {
    setpassword(e.target.value);
  };

  const handleremember = (e) => {
    // console.log(e.target.checked);
  };

  const handlelogin = (e) => {
    e.preventDefault();
    setlogin(true);
    // console.log(userdetails);
    dispatch(loginUser(userdetails))
    .then(response => {
      if (response.payload.loginSuccess) {
        window.localStorage.setItem('userId', response.payload.userId);
        if(response.payload.First_Property) {
          props.history.push("/PropertyOwner/postpropertyrequirements");
        } else {
          props.history.push("/PropertyOwner/Home");
        }
      } else {
        alert('Check out your Account or Password again')
      }
    })
    // if (
    //   userdetails.Email.length <= 10 ||
    //   userdetails.Phone.length <= 10 ||
    //   userdetails.Password.length === 0
    // ) {
    //   //Do Nothing
    // } else {
    //   setlogin(true);
    //   setTimeout(() => {
    //     setlogin(false);
    //     props.history.push("/");
    //   }, 2000);
    // }
  };

  const handleLogin = (googleData) => {

    dispatch(loginUserGoogle(googleData))
    .then(response => {
      // console.log("Google Response", response);
      if (response.payload.loginSuccess) {
        window.localStorage.setItem('userId', response.payload.userId);
        if(response.payload.First_Property) {
          props.history.push("/PropertyOwner/postpropertyrequirements");
        } else {
          props.history.push("/PropertyOwner/Home");
        }
      } else {
        alert('Check out your Account or Password again')
      }
    })
    
    // const res = await fetch("/api/company/google", {
    //     method: "POST",
    //     body: JSON.stringify({
    //     token: googleData.tokenId
    //   }),
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // })
    // const data = await res.json()
    // console.log(data);
    // store returned user somehow
  }

  return (
    <div className="login-container">
      <PageBanner
        title="Property Owner Login"
      />
      <div className="header">
        <p className="title">
          <b>Please Log In as Property Owner if you want to Construct your Building or Design it’s Interiors.</b>
        </p>
        </div>
      <div id="login" className="login">
        <form>
          <div className="title">
            <p>
              <b>Property Owner Login</b>
            </p>
          </div>
         
          <input
            className="email"
            onChange={handleemailorphone}
            id="email"
            type={"text"}
            placeholder="Email"
          />
          <p className="error">
            {email.length < 10 || phone.length < 10 ? "Incorrect !" : null}
          </p>
          <input
            className="password"
            id="password"
            onChange={handlepassword}
            type={"password"}
            placeholder="Password"
          />

          <div
            style={{
              width: screenwidth <= 670 ? screenwidth - 50 + "px" : null,
            }}
            className="rememberandlost"
          >
            <div className="rememberme">
              <input
                name="remember"
                onChange={handleremember}
                type={"checkbox"}
              />
              &nbsp;
              <label for="remember">Remember Me</label>
            </div>
            <a href="/PropertyOwner/Forgotpassword">Lost your password ?</a>
          </div>
          <button
            style={{
              width: screenwidth <= 670 ? screenwidth - 50 + "px" : null,
            }}
            onClick={handlelogin}
          >
            {login ? (
              <LoadingIcon
                width="30px"
                height="30px"
                outerwidth="30px"
                outerheight="30px"
                innerwidth="0"
                innerheight="0"
                position="relative"
                backgroundcolor="transparent"
              />
            ) : (
              "Log In"
            )}
          </button>
        </form>
        <div className="other-methods-for-login">
          <div className="google-and-otp">
          {/* <button className="via-google">Login Via Google</button> */}
                <GoogleLogin 
                  clientId="737789981008-3kqljeg4a44qdbbiib27e9d8rtr8m34o.apps.googleusercontent.com"
                  buttonText="Log in with Google"
                  onSuccess={handleLogin}
                  // onFailure={handleLoginFail}
                  cookiePolicy={'single_host_origin'}
                />
            {/* <p className="or">Or</p>
            <button className="via-otp">Login Via OTP</button> */}
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
        <div style={{ textAlign: 'center' }}>
        <p>
        By registering / creating an account, you agree to the 
        <a href="/PrivacyPolicy" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Privacy Policy </a>, 
        <a href="/terms&conditions" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Terms & Conditions and Replacement </a>, 
        <a href="/Communityguidelines" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Community Guidelines </a>, 
        <a href="/RefundReplacement&Cancellation" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Refund & Cancellation Policies </a>, 
        <a href="/PORules" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Property Owner Rules </a> 
        and 
        <a href="/SPRules" target="_blank" style={{ color: '#1DA1F2', textDecoration: 'none' }}> Service Professional Rules </a>
        of NebourHood.
        </p>
        <p>
        We will occasionally send you account - related emails.
        </p>
        </div>
    </div>
  );
}

export default withRouter(Login);