import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import "./CoverImage.css";
import { RemoveCoverImage, UploadCoverProfileLogo } from "../../../../../../../_actions/company_actions";
import LoadingIcon from "../../../../../../utils/LoadingIcon/LoadingIcon";

function Cover_Image(props) {
  const dispatch = useDispatch();
  const [ImagesURL, setImagesURL] = useState(props.Image_Src);
  const [loading, setloading] = useState(false);

  const onDrop = (files) => {
    let formData = new FormData();
    const config = {
      header: { "centent-type": "multipart/form-data" },
    };
    formData.append("file", files[0]);
    formData.append("value", "Cover");
    setloading(true);
    // saving the image inside the node server
    dispatch(UploadCoverProfileLogo(formData, config)).then((response) => {
      if (response.payload.success) {
        // console.log("Result", response);
        setImagesURL(response.payload.result.Location);
        setloading(false);
      } else {
        alert("Failed to save the image");
      }
    });
  };

  const handleviewimg = (e) => {
    const currentclickedel = e.currentTarget;
    const currentcoverimg =
      e.currentTarget.parentElement.parentElement.parentElement;

    // console.log(currentclickedel.innerText);

    if (currentclickedel.innerText === "open_in_full") {
      currentcoverimg.style.position = "fixed";
      currentcoverimg.style.zIndex = 99999;
      currentclickedel.innerText = "close_fullscreen";
    } else {
      currentcoverimg.style.position = "relative";
      currentcoverimg.style.zIndex = 0;
      currentclickedel.innerText = "open_in_full";
    }
  };

  const handledeleteimg = (image) => {
    // console.log("Delete image", image);
    const variable = {
      imageKey: image.slice(50),
    };
    dispatch(RemoveCoverImage(variable)).then((response) => {
      if(response.payload.success){
        setImagesURL("");
      } else {
        alert("Failed To Update Changes!!! Please Try Again Later")
      }
    })
  };

  return (
    <div className="cover-img-container">
      {ImagesURL ? (
        loading ? (
          <LoadingIcon
            position="relative"
            width={"100%"}
            height={"100%"}
            outerwidth={"50px"}
            outerheight={"50px"}
            innerdisplay={"none"}
            left={0}
            top={0}
          />
        ) : (
          <div className="cover-img">
            <img src={ImagesURL} alt="cover-img" />
            {props.nochange ? null
            : 
            <div className="cover-img-options">
              <div className="options">
                <span
                  id="view-img-option"
                  onClick={handleviewimg}
                  className="material-icons"
                >
                  open_in_full
                </span>

                <Dropzone
                  onDrop={onDrop}
                  multiple={false}
                  maxSize={800000000000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <span id="change-img-option" className="material-icons">
                        photo_camera
                      </span>
                    </div>
                  )}
                </Dropzone>
                <span
                  id="dlt-img-option"
                  onClick={()=>{handledeleteimg(ImagesURL)}}
                  className="material-icons"
                >
                  delete_outline
                </span>
              </div>
            </div>
      }
          </div>
        )
      ) : (
        <div className="no-image-upload-title">
          <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000000}>
            {({ getRootProps, getInputProps }) => (
              <div className="title-container" {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="title">
                  <span className="material-icons">add</span>Cover Image
                </p>
              </div>
            )}
          </Dropzone>
        </div>
      )}

      {/* <div className="upload-btn">
        <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000000}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button>Upload</button>
            </div>
          )}
        </Dropzone>
      </div> */}
    </div>
  );
}

export default Cover_Image;
