import React, { useLayoutEffect, useState } from "react";
import Declaration from "../../../../../utils/Declaration/Declaration";
import "./PO_Overview.css";

function PO_Overview(props) {
  const [screenwidth, setscreenwidth] = useState();
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  return (
    <div className="po-profile-overview-container">
      <div className="profile-overview">
        <div className="profile-detail">
          <fieldset>
            <legend>User Details</legend>
            <table className="profile-detail-table">
              <tr>
                <td className="title">User Email Id</td>
                <td>{props.UserDetails.email}</td>
                <td className="title">User Phone Number</td>
                <td>{props.UserDetails.phonenumber}</td>
                <td className="title">Property Owner Profile No.</td>
                <td>{props.UserDetails._id}</td>
              </tr>
            </table>
          </fieldset>
        </div>

        <div className="profile-detail">
          <fieldset>
            <legend>Personal Details</legend>
            <table className="profile-detail-table">
              <tr>
                <td className="title">Property Owner Name</td>
                <td>{props.UserDetails.name}</td>
                <td className="title">Date of Birth</td>
                <td>{props.UserDetails.Personal_Details.DOB}</td>
                {/* <td className="title">Property Owner Name</td>
                <td>{props.UserDetails.name}</td> */}
                <td className="title">Gender</td>
                <td>{props.UserDetails.Personal_Details.Gender}</td>
                <td className="title">Alternative Email ID</td>
                <td>
                  {props.UserDetails.Personal_Details.Alternative_Email_Id}
                </td>{" "}
                <td className="title">Alternative Phone Number</td>
                <td>
                  {props.UserDetails.Personal_Details.Alternative_Phone_Number}
                </td>
                <td className="title">WhatsApp Number</td>
                <td>
                  {props.UserDetails.Personal_Details.Personal_WhatsApp_Number}
                </td>
                {/* <td className="title">Aadhaar Card</td>
                <td>{props.UserDetails.Personal_Details.Aadhaar_Card}</td> */}
                <tr className="titles-and-list">
                  <td className="title">Languages Known</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {languages.map((language, index) => {
                    return <li key={index}>{language}</li>;
                  })} */}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Assamese ? (
                        <li>
                          <i className="fas fa-check"></i>Assamese
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Bengali ? (
                        <li>
                          <i className="fas fa-check"></i>Bengali
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .English ? (
                        <li>
                          <i className="fas fa-check"></i>English
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Gujarati ? (
                        <li>
                          <i className="fas fa-check"></i>Gujarati
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Hindi ? (
                        <li>
                          <i className="fas fa-check"></i>Hindi
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Kannada ? (
                        <li>
                          <i className="fas fa-check"></i>Kannada
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Malayalam ? (
                        <li>
                          <i className="fas fa-check"></i>Malayalam
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Marathi ? (
                        <li>
                          <i className="fas fa-check"></i>Marathi
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Odia ? (
                        <li>
                          <i className="fas fa-check"></i>Odia
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Punjabi ? (
                        <li>
                          <i className="fas fa-check"></i>Punjabi
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Tamil ? (
                        <li>
                          <i className="fas fa-check"></i>Tamil
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Telugu ? (
                        <li>
                          <i className="fas fa-check"></i>Telugu
                        </li>
                      ) : null}
                      {props.UserDetails.Personal_Details.Languages_Known
                        .Urdu ? (
                        <li>
                          <i className="fas fa-check"></i>Urdu
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
              </tr>
            </table>
          </fieldset>
        </div>
          <br />
        <div className="profile-detail">
          <fieldset>
            <legend style={{ textAlign: 'center' }}>Declaration Agreed</legend>
            <table className="profile-detail-table">
              <tr style={{ padding: '20px', fontSize: '15px' }}>
              <p>
                The Property Owner has agreed to this&nbsp;
                <a href="/PropertyOwner/Declaration/PropertyOwner" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                Declaration.
              </a>
              </p>
              </tr>
            </table>
          </fieldset>
        </div>
      </div>
    </div>
  );
}

export default PO_Overview;
