import React from "react";
import "./PO_ProfileImage.css";
import femaleProfile from '../../../../../../asserts/ProfileImages/femaleProfile.jpg'
import maleProfile from '../../../../../../asserts/ProfileImages/maleProfile.jpg'
import { withRouter } from "react-router-dom";

function ProfileImage(props) {

  return (
    <div className="profile-img-container">
          <div className="profile-img">
            {props.userGender == "Female" ? 
            <img src={femaleProfile}
             alt="profile-img" />
              :
            <img src={maleProfile}
             alt="profile-img" />
            }
          </div>
      <br />
      <br />
    </div>
  );
}

export default withRouter(ProfileImage);
