module.exports.table = {
  titles: [
    {
      Name: "SL NO.",
      rowspan: 2,
    },
    {
      Name: "Invoice Payment ID",
      rowspan: 2,
    },
    {
      Name: "Property Owner",
      colspan: 2,
    },
    {
      Name: "Property Requirement",
      colspan: 2,
    },
    {
      Name: "Date & Time of Self – Approval after Self – Declaration",
      rowspan: 2,
    },
    {
      Name: "State",
      rowspan: 2,
    },
    {
      Name: "City",
      rowspan: 2,
    },
    {
      Name: "Duration Selected",
      rowspan: 2,
    },
    {
      Name: "Amount in Rs.",
      colspan: 5,
    },
    {
      Name: "Payment Status",
      rowspan: 2,
    },
    {
      Name: "Payment Mode",
      rowspan: 2,
    },
    {
      Name: "Reason for Expiry (If Expired)",
      rowspan: 2,
    },
  ],
  subtitles: [
    "Profile Number",
    "Name",
    "Property Number",
    "Property Name",
    "Cost Price (Exclusive of G.S.T.)",
    "Final Amount (Exclusive of G.S.T.) = Sum of all Cost Prices",
    "G.S.T. Amount (18%) = Final Amount * (18/100)",
    "Total Amount = Final Amount + G.S.T. Amount",
    "Status",
  ],
};
//Demo Data
// module.exports.transactiondata = [
//   {
//     PurchaseOrderId: 1233412312314,
//     DateTimeofPayment: "03/03/2022 1:15 PM",
//     SubscriptionPlanDetails: {
//       PlanName: "Premium Plan",
//       PlanValidity: "1 Year",
//       AmountPerMonth: "$ 99",
//       TotalAmount: "$ 1200",
//       PlanExpires: "04/03/2023",
//       PlanStatus: "Active",
//       PaymentMode: "NetBanking",
//       DownloadInvoice: "",
//     },
//   },
//   {
//     PurchaseOrderId: 46455234124,
//     DateTimeofPayment: "08/01/2022 1:15 PM",
//     SubscriptionPlanDetails: {
//       PlanName: "Premium Plan",
//       PlanValidity: "1 Year",
//       AmountPerMonth: "$ 99",
//       TotalAmount: "$ 1200",
//       PlanExpires: "08/01/2022",
//       PlanStatus: "Active",
//       PaymentMode: "NetBanking",
//       DownloadInvoice: "",
//     },
//   },
//   {
//     PurchaseOrderId: 1233412312314,
//     DateTimeofPayment: "15/02/2022 1:15 PM",
//     SubscriptionPlanDetails: {
//       PlanName: "Premium Plan",
//       PlanValidity: "1 Year",
//       AmountPerMonth: "$ 99",
//       TotalAmount: "$ 1200",
//       PlanExpires: "15/02/2023",
//       PlanStatus: "Active",
//       PaymentMode: "NetBanking",
//       DownloadInvoice: "",
//     },
//   },
//   {
//     PurchaseOrderId: 1233412312314,
//     DateTimeofPayment: "03/03/2022 1:15 PM",
//     SubscriptionPlanDetails: {
//       PlanName: "Starter Plan",
//       PlanValidity: "1 Year",
//       AmountPerMonth: "$ 99",
//       TotalAmount: "$ 1200",
//       PlanExpires: "04/03/2022",
//       PlanStatus: "Active",
//       PaymentMode: "NetBanking",
//       DownloadInvoice: "",
//     },
//   },
//   {
//     PurchaseOrderId: 456345233,
//     DateTimeofPayment: "03/03/2022 1:15 PM",
//     SubscriptionPlanDetails: {
//       PlanName: "Advanced Plan",
//       PlanValidity: "1 Year",
//       AmountPerMonth: "$ 99",
//       TotalAmount: "$ 1200",
//       PlanExpires: "04/03/2022",
//       PlanStatus: "Active",
//       PaymentMode: "NetBanking",
//       DownloadInvoice: "",
//     },
//   },
// ];
