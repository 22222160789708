import React, { useLayoutEffect,  useState } from "react";
import DesktopNav from "./DesktopNav/DesktopNav";
import MobileNav from "./MobileNav/MobileNav";
import "./Nav.css";

export default function Nav() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  document.querySelector("body").style.WebkitOverflow = "hidden";
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
  
      
    });
  }, [screenwidth]);

  


  return (
    <div className="nav-container">
     
      {screenwidth >= 1200 ? <DesktopNav /> : <MobileNav />}
      <br/><br/><br/><br/>
    <div style={{
      visibility:"hidden"
    }}>
<DesktopNav/>
<MobileNav/>

    </div>
    </div>
  );
}
