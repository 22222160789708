import React, { useLayoutEffect, useState } from "react";
import "./PostPricing.css";
import PageBanner from "../../utils/PageBanner/PageBanner";
import { Plans } from "./Data";
import CartPricingDisclamer from "../../utils/CartPricingDisclamer/CartPricingDisclamer";
import PlanBreakDown from "../Pricing/PlanBreakDown/PlanBreakDown";
import MarqueeBanner from "../../utils/MarqueeBanner/MarqueeBanner";

export default function PostPricing() {
  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [isplanselected, setisplanselected] = useState(false);
  const [selectedplan, setselectedplan] = useState(0);
  const [plan, setplan] = useState({
    PlanName: "",
    PlanPrice: "",
  });
  document.querySelector("body").style.overflow = isplanselected ?  "hidden" : "auto"
  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const handleplan = (e) => {
    const clickedplan = e.currentTarget.parentElement.parentElement;

    const planprice = clickedplan.childNodes[1].innerText;
    const planname = clickedplan.childNodes[0].innerText;
    // console.log(planprice, planname);
    setplan({
      PlanName: planname,
      PlanPrice: planprice,
    });
  };

  const closemodal = (e) => {
    setisplanselected(false);
  };

  const planbreakdownstyles = {
    visibility: isplanselected ? "visible" : "hidden",
    opacity: isplanselected ? 1 : 0,
  };

  const handleplanselection = (e) => {
    setisplanselected(true);
    const selectedplanindex = parseInt(
      e.currentTarget.parentElement.parentElement.id
    );
    setselectedplan(selectedplanindex);
  };

  

  return (
    <div className="pricing-container">
      <PageBanner title="Service Professional Pricing" />
      <MarqueeBanner text="Buy 1 Lead, Get 2 Replacement Leads - No Questions Asked, with the NebourHood Guarantee! For more details, click here" />
      <div className="header">
        <p className="title">
          <b>Explore Our 1:1 Non-Sharing Exclusive Post-Prime Roaring Tiger Plan Series</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Kindly select the Plan as per your preferences from the following
        </p>
      </div>
      <div id="pricing-plans" className="pricing-plans">
        {Plans.map((plan, index) => {
          return (
            <div
              style={{
                width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
              }}
              key={index}
              id={index}
              className="plan"
            >
              <p className="title">
                <b>{plan.Name}</b>
              </p>
              <p className="price" style={{ color: 'red' }}>
                <b><del>{plan.FalsePrice}</del></b>
              </p>
              <p className="price" style={{ color: '#1dc295' }}>
                <b>{plan.Price}</b>
              </p>
              <div className="features">
                <ul>
                  {plan.Features.map((feature, index) => {
                    return (
                      <li key={index}>
                        <i className="fas fa-check"></i>
                        <p className="feature">{feature}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="btn">
                <button onClick={handleplanselection} className="pricing-btn">
                  Select
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CartPricingDisclamer />
      </div>
      <PlanBreakDown
        styles={planbreakdownstyles}
        isplanselected={isplanselected}
        handleclose={closemodal}
        plan={Plans[selectedplan]}
      />

    </div>
  );
}
