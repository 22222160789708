import React, { useEffect, useLayoutEffect, useState } from "react";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import { propertydetails } from "./Data";
import { useDispatch, useSelector } from "react-redux";
import "./PropertyDetails.css";
// import Button from "../../../utils/Button/Button";
// import { suggestions } from "./Data";
// import { featured } from "../Property/Data";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import { addToCart } from "../../../../_actions/company_actions";
import { getTopFiveProperties, propertydetailsbyID } from "../../../../_actions/property_actions";
import { withRouter } from "react-router-dom";
import Apartment from "../../../asserts/Property_Requirement_Images/Apartment.jpg";
import Bungalow from "../../../asserts/Property_Requirement_Images/Bungalow.jpg";
import Healthcare from "../../../asserts/Property_Requirement_Images/Healthcare.jpg";
import Hospitality from "../../../asserts/Property_Requirement_Images/Hospitality.jpg";
import Independent_Home from "../../../asserts/Property_Requirement_Images/Independent_Home.jpg";
import Industrial from "../../../asserts/Property_Requirement_Images/Industrial.jpg";
import Institutional from "../../../asserts/Property_Requirement_Images/Institutional.jpg";
import Office_Corporate_and_Tech from "../../../asserts/Property_Requirement_Images/Office_Corporate_and_Tech.jpg";
import Penthouse from "../../../asserts/Property_Requirement_Images/Penthouse.jpg";
import Retail from "../../../asserts/Property_Requirement_Images/Retail.jpg";
import Stand_Alone_Building from "../../../asserts/Property_Requirement_Images/Stand_Alone_Building.jpg";
import Villa from "../../../asserts/Property_Requirement_Images/Villa.jpg";
import soldout from "../Property/SoldOut.svg";
import Soldout from "../../../asserts/OtherImages/Soldout.png"
import ValidityExpire from "../Property/Validity_Expired.svg";
import maleProfile from "../../../asserts/ProfileImages/maleProfile.jpg";
import MarqueeBanner from "../../../utils/MarqueeBanner/MarqueeBanner";

function PropertyDetails(props) {
  const dispatch = useDispatch();
  // const propertyid = props.id;

  const currentproperty = propertydetails[0];

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [dotelement, setdotelement] = useState("dots");

  const [Product, setProduct] = useState([]);
  const [TopProperties, setTopProperties] = useState([]);
  const productId = props.match.params.ProjectId;
  const [AddToCartButtonDisable, setAddToCartButtonDisable] = useState(true);
  const [TopResidentialProperties, setTopResidentialProperties] = useState([]);
  const company = useSelector((state) => state.company);
  // const [name, setname] = useState(null);
  // const [phone, setphone] = useState(null);
  // const [description, setdescription] = useState(null);
  // const [formfilled, setformfilled] = useState(false);
  // const [submitclicked, setsubmitsclicked] = useState(false);
  // const [requestsubmittedbackend, setrequestsubmittedbackend] = useState(false);
  // const requestdetails = { Name: name, Phone: phone, Description: description, };

  useEffect(() => {
    dispatch(propertydetailsbyID(productId)).then((response) => {
      // console.log("Response", response.payload);
      setProduct(response.payload);
      dispatch(
        getTopFiveProperties(
          response.payload.City,
          "Residential",
          response.payload.Project_Type
        )
      ).then((response) => {
        if (response) {
          // console.log("Top five Residential Products ", response);
          setTopResidentialProperties(response.payload.topfiveproperties);
        } else {
          setTopResidentialProperties([]);
        }
      });
      setTimeout(() => {
        dispatch(
          getTopFiveProperties(
            response.payload.City,
            "Commercial",
            response.payload.Project_Type
          )
        ).then((response) => {
          if (response) {
            // console.log("Five Properties Response ", response);
            setTopProperties(response.payload.topfiveproperties);
          } else {
            setTopProperties([]);
          }
        });
      }, 3000);
    });
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
    setTimeout(() => {
      if (screenwidth < 1290) {
        setdotelement("bottom-dots");
      } else {
        setdotelement("dots");
      }
      const dots = document.getElementById(`${dotelement}`).childNodes;
      dots[0].style.opacity = 1;
      dots[0].style.transform = "scale(1.3)";
      // console.log(dots[0]);
      for (let i = 0; i < dots.length; i++) {
        dots[i].addEventListener("click", (e) => {
          for (let i = 0; i < dots.length; i++) {
            dots[i].style.opacity = 0.5;
            dots[i].style.transform = "scale(1)";
          }
          e.currentTarget.style.opacity = 1;
          e.currentTarget.style.transform = "scale(1.3)";
          if (screenwidth < 1290 && screenwidth > 1080) {
            document.getElementById(
              "properties"
            ).style.transform = `translateX(${-350 * i}px)`;
          }
          if (screenwidth < 1080 && screenwidth > 800) {
            // console.log(screenwidth);
            document.getElementById(
              "properties"
            ).style.transform = `translateX(${-(screenwidth - 110) * i}px)`;
          }
          if (screenwidth < 800) {
            document.getElementById(
              "properties"
            ).style.transform = `translateX(${-(screenwidth - 35) * i}px)`;
          }
          if (screenwidth > 1290) {
            document.getElementById(
              "properties"
            ).style.transform = `translateX(${-450 * i}px)`;
          }
        });
      }
    }, 10);
  }, [screenwidth, dotelement]);

  const handlearrow = (e) => {
    const currentarrow = e.currentTarget.className;
    const transformwidth = parseInt(
      document.getElementById("Gallery").style.transform.replace(/[^\d.]/g, "")
    );

    var widthtomove = 703;
    var imageslength = currentproperty.Images.length - 2;
    if (screenwidth >= 1450) {
      widthtomove = 703;
    }
    if (screenwidth < 1450) {
      widthtomove = 610;
    }
    if (screenwidth < 1300) {
      widthtomove = 510;
    }
    if (screenwidth < 1090) {
      widthtomove = 510;
    }
    if (screenwidth < 850) {
      widthtomove = screenwidth - 10;
      imageslength = currentproperty.Images.length - 1;
    }

    if (currentarrow === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth <= 1) {
        document.getElementById("Gallery").style.transform = `translateX(-${widthtomove * imageslength
          }px)`;
      } else {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${Math.abs(
          transformwidth - widthtomove
        )}px)`;
      }
    }

    if (currentarrow === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${widthtomove}px)`;
      }
      if (transformwidth >= widthtomove * imageslength) {
        document.getElementById(
          "Gallery"
        ).style.transform = `translateX(-${0}px)`;
      } else {
        document.getElementById("Gallery").style.transform = `translateX(-${transformwidth + widthtomove
          }px)`;
      }
    }
  };

  const addToCartHandler = (e) => {
    e.preventDefault();
    // console.log("addToCartHandler");
    setAddToCartButtonDisable(false);
    dispatch(addToCart(productId)).then((response) => {
      // console.log("Add to Cart response", response);
      setAddToCartButtonDisable(true);
    });
  };

  return (
    <div className="property-req-container">
      <PageBanner
        title="Lead Details"
      />
      <MarqueeBanner text="Buy 1 Lead, Get 2 Replacement Leads - No Questions Asked, with the NebourHood Guarantee! For more details, click here" />
      <div className="property-details">
        <div
          style={{
            width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            top: screenwidth < 850 ? (screenwidth - 30) / 3 + "px" : null,
          }}
          id="arrows"
          className="arrows"
        >
          <i id="left" onClick={handlearrow} className="fas fa-arrow-left"></i>
          <i
            id="right"
            onClick={handlearrow}
            className="fas fa-arrow-right"
          ></i>
        </div>
        <div
          style={{
            width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
          }}
          className="property-details-gallery-container"
        >
          <div id="Gallery" className="gallery">
            {currentproperty.Images.map((image, index) => {
              return (
                <img
                  style={{
                    width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
                  }}
                  id="property-details-img"
                  key={index}
                  src={image}
                  alt="property"
                />
              );
            })}

          </div>
          {Product.sold > 0 ?
            <>
              {Product.Validity_Till <= 0 ?
                <img id="sold-imgg" className="sold-out-img" src={ValidityExpire} alt="sold-out-img" />
                :
                <>
                  {company.companyData && company.companyData.isAuth && (company.companyData.currentPackage === "Baby Tiger" || company.companyData.currentPackage === "Startup Tiger Prime" || company.companyData.currentPackage === "Elite Tiger Prime" || company.companyData.currentPackage === "Classic Tiger Prime" || company.companyData.currentPackage === "Baby Tiger Prime" || company.companyData.currentPackage === "Startup Tiger"  || company.companyData.currentPackage === "Elite Tiger" || company.companyData.currentPackage === "Classic Tiger") ?
                    <>
                      {Product.sold !== 3 ?
                        <img id="sold-imgg" className="sold-out-img" src={Soldout} alt="sold-out-img" /> : null
                      }
                    </>
                            :
                            null
                          }
                </>
              }
            </>
            :
            <>
              {Product.sold <= 0 ?
                <img
                  id="sold-imgg"
                  className="sold-out-img"
                  src={Soldout}
                  alt="sold-out-img"
                />
                :
                null
              }
            </>
          }
          {/* <img
            id="sold-imgg"
              className="sold-out-img"
              src={ValidityExpire}
              alt="sold-out-img"
            /> */}
          {/* <img
            id="sold-img2"
              className="sold-out-img"
              src={ValidityExpire}
              alt="sold-out-img"
            /> */}
          <br />
        </div>
        <div className="Details">
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="property"
          >
            <div className="about">
            {/* {Product.sold > 0 ?
              <>
                {Product.Validity_Till <= 0 ? 
                  <img
                    id="sold-imgg-about"
                    className="sold-out-img"
                    src={ValidityExpire}
                    alt="sold-out-img"
                  />
              :
              null
                }
              </>
              :
              <>
                {Product.sold <= 0 ?
                  <img
                    id="sold-imgg-about"
                    className="sold-out-img"
                    src={soldout}
                    alt="sold-out-img"
                  />
                :
                null
                }
              </>
            } */}
              <div className="tagsandprice">
                <div className="tags">
                  <p className="featured">{Product.Project_Type}</p>
                  <p className="rent">{Product.BuildingType}</p>
                </div>
                <div className="price-container">
                  {/* <p className="price">Posted on: {Product.Posted_Date}</p> */}
                  {Product.sold >= 1 ?
                        <>
                        {company.companyData && company.companyData.isAuth && company.companyData.Subscribed && (company.companyData.currentPackage === "Baby Tiger" || company.companyData.currentPackage === "Startup Tiger Prime" || company.companyData.currentPackage === "Elite Tiger Prime" || company.companyData.currentPackage === "Classic Tiger Prime" || company.companyData.currentPackage === "Baby Tiger Prime" || company.companyData.currentPackage === "Startup Tiger"  || company.companyData.currentPackage === "Elite Tiger"  || company.companyData.currentPackage === "Classic Tiger") ?
                        <>
                          {Product.sold === 3 ? 
                        <b style={{color: 'green', fontSize: '2em'}}> 
                          In Stock
                        </b>
                          : 
                          <b style={{color: 'red', fontSize: '2em'}}> 
                            Out of Stock
                            </b>
                          }
                        </>
                        : 
                        <b style={{color: 'green', fontSize: '2em'}}> In Stock: {Product.sold}</b> 
                      }
                      </>
                        : 
                        <b style={{color: 'red', fontSize: '2em'}}>Out of Stock</b>
                        }
                </div>
              </div>
              <h2 className="address">
                Address: &nbsp;&nbsp;
                <span className="titleid">
                  {Product.Land_Building}, {Product.State}, {Product.City},{" "}
                  {Product.Area_Name}
                </span>
              </h2>
              <h2 className="title">
                Property Owner Name: &nbsp;&nbsp;
                <span className="titleid">
                  {Product.Name_Of_property_Owner}
                </span>
              </h2>
              <h2 className="title">
                Property Requirement No.: &nbsp;&nbsp;
                <span className="titleid">{Product._id}</span>
              </h2>
              <div className="typeandsize">
                <p className="type">{Product.Area_Type}</p>
                <p className="size">({Product.Project_Area} sq.ft.)</p>
              </div>
              <div className="otherdetailsandrating">
                <div className="other-details">
                <p>
                    <b>Service Requirement:</b> <br />
                    {Product.Service_Type_Required}
                  </p>
                  <br />
                  <p>
                    <b>Time Frame:</b> <br />
                    {/* {Product.Beginning_The_Project} */}
                    {Product.Beginning_The_Project
                           ?
                          <>
                          {
                          Product.Validity_Till <= 30 ? "Within 1 Month" :
                            Product.Validity_Till <= 45 ? "Within 2 Months" : 
                            Product.Validity_Till <= 75 ? "Within 3 Months" : 
                            Product.Validity_Till <= 105 ? "Within 4 Months" : 
                            Product.Validity_Till <= 135 ? "Within 5 Months" : 
                            Product.Validity_Till <= 165 ? "Within 6 Months" : 
                            Product.Validity_Till <= 195 ? "Within 7 Months" : 
                            Product.Validity_Till <= 225 ? "Within 8 Months" : 
                            Product.Validity_Till <= 255 ? "Within 9 Months" : 
                            Product.Validity_Till <= 285 ? "Within 10 Months" : 
                            Product.Validity_Till <= 315 ? "Within 11 Months" : 
                            Product.Validity_Till <= 345 ? "Within 12 Months" :
                            "---"
                          }
                          </>
                           : "---"}
                  </p>
                  <br />
                  <p>
                    {Product.Property_Condition ? 
                    <>
                    <b>Property Condition:</b> <br />
                    {Product.Property_Condition}
                    </>
                    : null}
                  </p>
                  {/* <p>
                  {Product.Service_Type_Required}
                  </p>
                  <p>{Product.Beginning_The_Project}</p>
                  <p>{Product.Beginning_The_Project}</p> */}
                </div>
                {/* <div className="rating">
                  <p>Verified</p>
                </div> */}
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>User Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Property Owner Profile Number :</p>
                  <p className="input">&nbsp;&nbsp;{Product.writer}</p>
                </div>
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>Personal Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Property Owner Name :</p>
                  <p className="input">
                    &nbsp;&nbsp;{Product.Name_Of_property_Owner}
                  </p>
                </div>
              </div>
            </div>
            <br />

            <br />
            <div className="address-container">
              <h2>Address</h2>
              <p className="title">
                Details of the property where the work must be completed :
              </p>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    {Product.Land_Building ? 
                    <>
                      <li className="head">
                        House Name / Building Name / Plot Name / Land Name
                        (Property Name):
                      </li>
                      <li>{Product.Land_Building}</li>
                    </>
                      :null}
                    <li className="head">State:</li>
                    <li>{Product.State}</li>
                    <li className="head">City:</li>
                    <li>{Product.City}</li>
                    {Product.Area_Name ? 
                    <>
                      <li className="head">Area Name:</li>
                      <li>{Product.Area_Name}</li>
                    </>
                    :null}
                    {Product.Pin_Code ? 
                    <>
                      <li className="head">Pin Code:</li>
                      <li>{Product.Pin_Code}</li>
                    </>
                    :null}
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Service Type Required </h2>
              <p className="title">
                Service Type, Property Type and Property Status:{" "}
              </p>
              <div className="features">
                {Product.Project_Type ? 
                    <p className="item">
                      {Product.Project_Type}
                    </p>
                    : null}
                    {Product.BuildingType ? 
                    <p className="item">
                      {Product.BuildingType}
                    </p>
                    : null}
                    {Product.Area_Type ? 
                      <p className="item">
                        {Product.Area_Type}
                      </p>
                    : null}
                    {Product.Status_Of_Project ? 
                      <p className="item">
                        {Product.Status_Of_Project}
                      </p>
                    : null}
              </div>
              <br />
              <p className="title">
                Service Requirement:{" "}
              </p>
              <div className="features">
                <p className="item">
                  {Product.Service_Type_Required}
                </p>
              </div>
              {/* <div className="features">
                {currentproperty.Features.map((feature, index) => {
                  return (
                    <p key={index} className="item">
                      {feature}
                    </p>
                  );
                })}
              </div> */}
            </div>
            <br />
            <div className="features-container">
              <h2>Time Frame</h2>
              <p className="title">
                The property requirement work has to be started :{" "}
              </p>
              <div className="features">
                <p className="item">
                  {/* {Product.Beginning_The_Project} */}
                  {Product.Beginning_The_Project
                           ?
                          <>
                          {
                          Product.Validity_Till <= 30 ? "Within 1 Month" :
                            Product.Validity_Till <= 45 ? "Within 2 Months" : 
                            Product.Validity_Till <= 75 ? "Within 3 Months" : 
                            Product.Validity_Till <= 105 ? "Within 4 Months" : 
                            Product.Validity_Till <= 135 ? "Within 5 Months" : 
                            Product.Validity_Till <= 165 ? "Within 6 Months" : 
                            Product.Validity_Till <= 195 ? "Within 7 Months" : 
                            Product.Validity_Till <= 225 ? "Within 8 Months" : 
                            Product.Validity_Till <= 255 ? "Within 9 Months" : 
                            Product.Validity_Till <= 285 ? "Within 10 Months" : 
                            Product.Validity_Till <= 315 ? "Within 11 Months" : 
                            Product.Validity_Till <= 345 ? "Within 12 Months" :
                            "---"
                          }
                          </>
                           : "---"}
                  </p>
              </div>
            </div>
            <br />
            <div className="address-container">
              <h2>Property Information</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Property Requirement Number:</li>
                    <li>{Product._id}</li>
                    {Product.Dimension_Type ?
                    <>
                      <li className="head">
                        Property Dimension Type:
                      </li>
                      <li>{Product.Dimension_Type}</li>
                    </>
                    :null}
                    {Product.Project_Area ? 
                    <>
                      <li className="head">Property Area (sq. ft.):</li>
                      <li>{Product.Project_Area} sq. ft.</li>
                    </>
                    : null}
                    {Product.Property_Condition ? 
                    <>
                      <li className="head">Property Condition:</li>
                      <li>{Product.Property_Condition}</li>
                    </>
                    :null}
                  </ul>
                </div>
              </div>
            </div>
            <br />
              {Product.description ? 
              <>
            <div className="address-container">
                <>
                  <h2>Property Requirements</h2>
                  <p>{Product.description}</p>
                </>
              {/* <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Property Requirement Number:</li>
                    <li>{Product.Land_Building}</li>
                    <li className="head">
                      Property Dimension Type (B.H.K. for Residential Property):
                    </li>
                    <li>{Product.State}</li>
                    <li className="head">Property Area (sq. ft.):</li>
                    <li>{Product.City}</li>
                    <li className="head">Property Condition:</li>
                    <li>{Product.Area_Name}</li>
                  </ul>
                </div>
              </div> */}
            </div>
            <br />
            </>
              :null}

            <div className="address-container">
              <h2>Declaration Agreed</h2>
              <p>The Property Owner has agreed to this&nbsp; 
                <a href="/ServiceProfessional/Declaration/PropertyOwner" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                  Declaration.
                </a>
              </p>
            </div>
          </div>

          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="contact-or-share"
          >
            <div className="shareanddownload">
              <div className="add-to-cart">
                {Product.sold > 0 ? (
                  <>
                    {Product.Validity_Till <= 0 ? <>
                      <img
                        className="sold-out-img"
                        src={ValidityExpire}
                        alt="sold-out-img"
                      />
                    </> : <>
                    {company.companyData && company.companyData.isAuth && (company.companyData.currentPackage === "Baby Tiger" || company.companyData.currentPackage === "Startup Tiger Prime" || company.companyData.currentPackage === "Elite Tiger Prime" || company.companyData.currentPackage === "Classic Tiger Prime" || company.companyData.currentPackage === "Baby Tiger Prime" || company.companyData.currentPackage === "Startup Tiger"  || company.companyData.currentPackage === "Elite Tiger" || company.companyData.currentPackage === "Classic Tiger") ?
                      <>
                        {Product.sold !== 3 ? 
                        <img
                        className="sold-out-img"
                        src={soldout}
                        alt="sold-out-img"
                      />
                      : 
                      <>
                        {AddToCartButtonDisable ? (
                          <button
                            onClick={addToCartHandler}
                            className="add-to-cart-btn"
                          >
                            ADD TO CART
                          </button>
                        ) : (
                          <button className="add-to-cart-btn">
                            <LoadingIcon
                              width="30px"
                              height="30px"
                              outerwidth="30px"
                              outerheight="30px"
                              innerwidth="0"
                              innerheight="0"
                              position="relative"
                              backgroundcolor="transparent"
                            />
                          </button>
                        )}
                      </>
                        }
                      </>
                    :
                    <>
                        {AddToCartButtonDisable ? (
                          <button
                            onClick={addToCartHandler}
                            className="add-to-cart-btn"
                          >
                            ADD TO CART
                          </button>
                        ) : (
                          <button className="add-to-cart-btn">
                            <LoadingIcon
                              width="30px"
                              height="30px"
                              outerwidth="30px"
                              outerheight="30px"
                              innerwidth="0"
                              innerheight="0"
                              position="relative"
                              backgroundcolor="transparent"
                            />
                          </button>
                        )}
                      </>
                    }
                    </>
                    }
                  </>
                ) : (
                  <>
                    {Product.sold <= 0 ? (
                      <img
                        className="sold-out-img"
                        src={soldout}
                        alt="sold-out-img"
                      />
                    ) : (
                      <>
                        {AddToCartButtonDisable ? (
                          <button
                            onClick={addToCartHandler}
                            className="add-to-cart-btn"
                          >
                            ADD TO CART
                          </button>
                        ) : (
                          <button className="add-to-cart-btn">
                            <LoadingIcon
                              width="30px"
                              height="30px"
                              outerwidth="30px"
                              outerheight="30px"
                              innerwidth="0"
                              innerheight="0"
                              position="relative"
                              backgroundcolor="transparent"
                            />
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* {AddToCartButtonDisable ? (
                  <button
                    onClick={addToCartHandler}
                    className="add-to-cart-btn"
                  >
                    ADD TO CART
                  </button>
                ) : (
                  <button className="add-to-cart-btn">
                    <LoadingIcon
                      width="30px"
                      height="30px"
                      outerwidth="30px"
                      outerheight="30px"
                      innerwidth="0"
                      innerheight="0"
                      position="relative"
                      backgroundcolor="transparent"
                    />
                  </button>
                )} */}
              </div>
            </div>
            <br />
            <div className="contact">
              <div className="dealer-details">
                <img
                  src={maleProfile}
                  alt={currentproperty.Dealer.Name}
                />
                <div className="dealer-name">
                  <h3>Posted By</h3>
                  <a
                    onClick={() => {
                      props.history.push(
                        `/OtherViewProfile/PropertyOwner/${Product.writer}`
                      );
                    }}
                  >
                    {Product.Name_Of_property_Owner}
                  </a>
                </div>
              </div>
            </div>
            <div className="suggestions-container">
              <h2>Featured Property Requirements</h2>
              <div className="suggestions">
                {TopResidentialProperties.map((suggestion, index) => {
                  return (
                    <div key={index} className="suggestion">
                      <img
                        src="https://templates.envytheme.com/fido/default/assets/images/property-details/property-details-1.jpg"
                        alt={suggestion.Area_Type}
                      />
                      <div className="detail">
                      <a href={`/ServiceProfessional/PropertyRequirementsDetails/${suggestion._id}`} style={{ textDecoration: "none" }}>
                        <h4>
                          <a href={`/ServiceProfessional/PropertyRequirementsDetails/${suggestion._id}`} style={{ color: "#24292E", textDecoration: "none" }} >
                            {suggestion.Land_Building}
                          </a>
                        </h4>
                        <p className="address">
                          <i class="fas fa-map-marker-alt"></i>&nbsp;
                          {suggestion.City} , {suggestion.State}
                        </p>
                        <div
                          style={{
                            width:
                              screenwidth < 1080
                                ? screenwidth - 280 + "px"
                                : null,
                          }}
                          className="for-and-price"
                        >
                          <p className="for">Residential</p>
                          <p className="for">{suggestion.Project_Type}</p>
                          <p className="for">{suggestion.Area_Type}</p>
                        </div>
                      </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <br />
            <div className="tagcloud">
              <h2>Tag Cloud</h2>
              <div className="tags">
                <ul>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Apartment</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Penthouse</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Stand Alone Building</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Independent Home</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Villa</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Bungalow</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Retail</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Hospitality</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Healthcare</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Office – Corporate and Tech</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Institutional</a>
                  </li>
                  <li>
                    <a href="/ServiceProfessional/PropertyRequirements">Industrial</a>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <div className="topproperties-container">
              <div className="titleanddots">
                <h2>Top Prop Requirements</h2>
                <div
                  style={{ display: screenwidth < 1290 ? "none" : "block" }}
                  id="dots"
                  className="dots"
                >
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                </div>
              </div>
              <div className="properties-container">
                <div id="properties" className="properties">
                  {TopProperties.map((property, index) => {
                    return (
                      <div
                        style={{
                          width:
                            screenwidth < 1080 && screenwidth > 800
                              ? screenwidth - 150 + "px"
                              : screenwidth < 800
                                ? screenwidth - 70 + "px"
                                : null,
                        }}
                        id="property"
                        key={index}
                        className="property"
                      >
                        <a href={`/ServiceProfessional/PropertyRequirementsDetails/${property._id}`} style={{ textDecoration: "none" }}>
                          <div
                            style={{
                              background: `linear-gradient(to top,
                              rgb(37, 37, 37),
                              transparent 100%), url(${property.Area_Type === "Penthouse"
                                  ? Penthouse
                                  : property.Area_Type === "Apartment"
                                    ? Apartment
                                    : property.Area_Type === "Bungalow"
                                      ? Bungalow
                                      : property.Area_Type === "Retail"
                                        ? Retail
                                        : property.Area_Type === "Hospitality"
                                          ? Hospitality
                                          : property.Area_Type === "Healthcare"
                                            ? Healthcare
                                            : property.Area_Type ===
                                              "Office - Corporate and Tech"
                                              ? Office_Corporate_and_Tech
                                              : property.Area_Type === "Institutional"
                                                ? Institutional
                                                : property.Area_Type === "Industrial"
                                                  ? Industrial
                                                  : property.Area_Type === "Independent Home"
                                                    ? Independent_Home
                                                    : property.Area_Type === "Villa"
                                                      ? Villa
                                                      : property.Area_Type === "Stand Alone Building"
                                                        ? Stand_Alone_Building
                                                        : null
                                })`,
                              backgroundSize: "cover",

                              width:
                                screenwidth < 1080 && screenwidth > 800
                                  ? screenwidth - 150 + "px"
                                  : screenwidth < 800
                                    ? screenwidth - 75 + "px"
                                    : null,
                            }}
                            className="details"
                          >
                            <p className="tag">{property.Project_Type}</p>

                            <div>
                              <p className="address">
                                {property.Land_Building}, {property.State},{" "}
                                {property.City}, {property.Area_Name}
                              </p>
                              <h2>{property.Name_Of_property_Owner}</h2>
                              <p className="price">
                                {property.Area_Type} ({property.Project_Area}{" "}
                                sq.ft.)
                              </p>
                              <div className="other-details-container">
                                <p>{property.Service_Type_Required}</p>
                                <p>{property.Beginning_The_Project}</p>
                                <p>{property.Property_Condition}</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: screenwidth < 1290 ? "flex" : "none" }}
                id="bottom-dots"
                className="bottom-dots"
              >
                <i id="first" className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PropertyDetails);
