import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutCompany } from "../../../../_actions/company_actions";
import { logoutUser } from "../../../../_actions/user_actions";
import Cart from "./Cart";
import "./DesktopNav.css";

function Profile(props) {
  const user = useSelector((state) => state.user);
  const company = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const logoutServiceProfessionalHandler = () => {
    dispatch(logoutCompany()).then((response) => {
      if (response.payload.success === true) {
        props.history.push("/serviceprofessional/Login");
      } else {
        alert("Log Out Failed");
      }
    });
  };

  const logoutHandler = () => {
    dispatch(logoutUser()).then((response) => {
      if (response.payload.success === true) {
        props.history.push("/PropertyOwner/Login");
      } else {
        alert("Log Out Failed");
      }
    });
  };

  document.querySelector("html").addEventListener("click", (e) => {
    const contains = document.getElementById("profile").contains(e.target);
    if (!contains) {
      document.getElementById("profile-drop-down").style.opacity = 0;
      document.getElementById("profile-drop-down").style.visibility = "hidden";
    }
  });

  const handleuser = (e) => {
    const profiledropdown = document.getElementById("profile-drop-down").style;
    // console.log(profiledropdown.display);
    if (profiledropdown.display === "none") {
      profiledropdown.visibility = "visible";
      profiledropdown.opacity = 1;
      profiledropdown.display = "block";
    } else {
      profiledropdown.visibility = "hidden";
      profiledropdown.opacity = 0;
      profiledropdown.display = "none";
    }
  };

  const handleprofiledropdown = (e) => {
    e.currentTarget.style.display = "none";
    // console.log(e.currentTarget.style.display);
  };

  if (
    (company.companyData && company.companyData.isAuth) ||
    (user.userData && user.userData.isAuth)
  ) {
    if (company.companyData && company.companyData.isAuth) {
      return (
        <>
          {/* {company.companyData.Subscribed? 
          <button onClick={() => props.history.push('/ServiceProfessional/Pricing')} >Plan Validity Left: <span style={{ color: 'red' }}>{company.companyData.Subscription_Time_Left}  Days</span></button>
          :
          <button onClick={() => props.history.push('/ServiceProfessional/Pricing')}>SUBSCRIBE NOW!</button>
          } */}
          <Cart />

          <div id="profile" className="profile">
            {" "}
            <span onClick={handleuser} className="material-icons">
              person
            </span>
            <ul
              style={{
                display: "none",
              }}
              onClick={handleprofiledropdown}
              id="profile-drop-down"
              className="profile-drop-down"
            >
              <li onClick={() => props.history.push("/ServiceProfessional/MyProfile")} >
                  My Profile
              </li>
              <li onClick={() => props.history.push("/ServiceProfessional/Pricing")} >
                Non-Sharing Lead Plans
              </li>
              <li onClick={() => props.history.push("/ServiceProfessional/Pricing/Membership")} >
                  S.P. Prime
              </li>
              <li>
                <a onClick={() => props.history.push("/ServiceProfessional/MyPurchasedLead")} >
                  My Purchased Leads
                </a>
              </li>
              <li onClick={() => props.history.push("/ServiceProfessional/FreeResources")} >
                  Free Resources
              </li>
              {/* <li>
                <a onClick={() => props.history.push('/ServiceProfessional/MyTransactionsPlans')}>My Plans</a>
              </li> */}
              {/* <li>
                <a onClick={() => props.history.push('/ServiceProfessional/Pricing')}>Pricing</a>
              </li> */}
              <li onClick={logoutServiceProfessionalHandler}>Log Out</li>
            </ul>
          </div>
        </>
      );
    } else if (user.userData && user.userData.isAuth) {
      return (
        <div id="profile" className="profile">
          {" "}
          <span onClick={handleuser} className="material-icons">
            person
          </span>
          <ul
            style={{
              display: "none",
            }}
            id="profile-drop-down"
            className="profile-drop-down"
          >
            <li>
              <a href="/PropertyOwner/MyProfile">My Profile</a>
            </li>
            <li>
              <a href="/PropertyOwner/POPricing">PO Pricing</a>
            </li>
            <li>
              <a href="/PropertyOwner/FreeResources">Free Resources</a>
            </li>
            <li onClick={logoutHandler}>
              Log Out
            </li>
          </ul>
        </div>
      );
    }
  } else {
    return (
      // Display is None ******************************
      <div style={{ display: "none" }} id="profile" className="profile">
        {" "}
        <span onClick={handleuser} className="material-icons">
          person
        </span>
        <ul
          style={{
            opacity: 0,
          }}
          id="profile-drop-down"
          className="profile-drop-down"
        >
          <li>
            <a href="/profile">View Profile</a>
          </li>
          <li>
            <a href="/">Account</a>
          </li>
          <li>Log Out</li>
        </ul>
      </div>
    );
  }
}
export default withRouter(Profile);
