import React from "react";
import { useSelector } from "react-redux";
import "./DesktopNav.css";

export default function LoginSignup() {

  const user = useSelector(state => state.user)
  const company = useSelector(state => state.company)
  

  document.querySelector("html").addEventListener("click", (e) => {
    const contains = document
      .getElementById("login-signup-container")
      .contains(e.target);
    const elementstoremove = document.getElementById(
      "login-signup-container"
    ).childNodes;
 

    if (!contains) {
      for (let i = 0; i < elementstoremove.length; i++) {
        elementstoremove[i].childNodes[1].style.opacity = 0;
        elementstoremove[i].childNodes[1].style.visibility = "hidden";
      }
    }
  });


  const handleclick = (e) => {
    const currentelement = e.currentTarget.nextSibling.style;
    if (currentelement.opacity === "0") {
      currentelement.opacity = 1;
      currentelement.visibility = "visible";
    } else {
      currentelement.opacity = 0;
      currentelement.visibility = "hidden";
    }
  };

  if((user.userData && user.userData.isAuth) || (company.companyData && company.companyData.isAuth))
{
  return (
    <div style={{ display: 'none' }} id="login-signup-container" className="login-signup-container">
      {/* <div className="login-container">
        <p onClick={handleclick}>Log In</p>
        <ul
          style={{
            opacity: 0,
          }}
          id="login-signup-drop-down"
          className="login-signup-drop-down"
        >
          <li>
            <a href="/">Property Owner</a>
          </li>
          <li>
            <a href="/">Company</a>
          </li>
        </ul>
      </div>
      <div className="signup-container">
        <p onClick={handleclick}>Sign Up</p>
        <ul
          style={{
            opacity: 0,
          }}
          className="login-signup-drop-down"
        >
          <li>
            <a href="/">Property Owner</a>
          </li>
          <li>
            <a href="/">Company</a>
          </li>
        </ul>
      </div> */}
    </div>
  );
} else {
  return (
    <div id="login-signup-container" className="login-signup-container">
      <div className="login-container">
        <p onClick={handleclick}>Log In</p>
        <ul
          style={{
            opacity: 0,
          }}
          id="login-signup-drop-down"
          className="login-signup-drop-down"
        >
          <li>
            <a href="/PropertyOwner/Login">Property Owner</a>
          </li>
          <li>
            <a href="/serviceprofessional/Login">Service Professional</a>
          </li>
        </ul>
      </div>
      <div className="signup-container">
        <p onClick={handleclick}>Sign Up</p>
        <ul
          style={{
            opacity: 0,
          }}
          className="login-signup-drop-down"
        >
          <li>
            <a href="/PropertyOwner/Register">Property Owner</a>
          </li>
          <li>
            <a href="/serviceprofessional/Register">Service Professional</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
}
