import React, { useState } from "react";
import MyPurchasedLeadTable from "../../../../../ServiceProfessional/MyPurchasedLead/MyPurchasedLeadTable";
import TransactionTable from "../../../../../ServiceProfessional/TransactionTable/TransactionTable";
import "../../ProfilePage/ProfilePage.css";
import MembershipTable from "../../../../../ServiceProfessional/MembershipTable/MembershipTable";

export default function Transaction() {
  const [myplansclicked, setmyplansclicked] = useState(true);
  const [myleadsclicked, setmyleadsclicked] = useState(false);
  const [mymembershipclicked, setmymembershipclicked] = useState(false);

  const handletab = (e) => {
    const clickedel = e.currentTarget.id;
    const clickedelement = e.currentTarget;

    const transactiontabs =
      document.getElementById("transaction-tabs").childNodes;

      transactiontabs.forEach(tab => {
        tab.style.color = "black";
        tab.style.backgroundColor= "rgb(233, 232, 232)";
      })

    clickedelement.style.backgroundColor = "#1dc295";
    clickedelement.style.color = "white";
    setmyleadsclicked(false);
    setmyplansclicked(false);
    setmymembershipclicked(false);
    if (clickedel === "myleads") {
      setmyleadsclicked(true);
    } else if(clickedel === "mymembership") {
      setmymembershipclicked(true)
    } else {
      setmyplansclicked(true);
    }
  };

  return (
    <div className="transactions-container">
      <h1>Transaction Details</h1>
      <br/>
      <p style={{ fontSize: '17px', textAlign: 'center', width: '700px' }}>Here, you can view all the Payments related to your Subscription Plans & Leads</p>
      <br/>
      <div id="transaction-tabs" className="profile-tabs-container">
        <p onClick={handletab} id="myplans" className="transaction">
          My Plans
        </p>
        <p onClick={handletab} id="mymembership" className="analytics" style={{ borderRadius: '0px' }}>
          My Prime Membership
        </p>
        <p onClick={handletab} id="myleads" className="analytics">
          My Leads
        </p>
      </div>

      {myplansclicked ? (
        <>
          <TransactionTable />
        </>
      ) : null}
      {mymembershipclicked ? (
        <>
          <MembershipTable />
        </>
      ) : null}
      {myleadsclicked ? (
        <>
          <MyPurchasedLeadTable />
        </>
      ) : null}
    </div>
  );
}
