import React, { useLayoutEffect, useState } from "react";
import "./Achievements.css";
import { achievements } from "./Data";

export default function Achievements() {
  
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      
    });
  }, [screenwidth]);

  return (
    <div className="achievements-container">
      <p className="title">
        <b>Our Achievements</b>
      </p>
      <p style={{width:screenwidth<=730 ? screenwidth-30 + 'px' : null}} className="description">
      Our humble beginnings along with your love and support has led us to accomplish the following achievements 
      </p>
      <div className="achievements">
        {achievements.map((achievement, index) => {
          return (
            <div key={index} className="achievement">
              <p className="numbers"><b>{achievement.Numbers}</b></p>
              <p className="name">{achievement.Name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
