import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { AiOutlineCloseCircle, AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { projectDetailsByID } from "../../../../../../_actions/project_actions";
import "./ShowImage.css";

const ShowImage = ({ imgid, galleryimages , closemodal, projectID }) => {
  const company = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const imageref = useRef(null);
  document.querySelector("body").style.overflow = 'hidden'


  const [ProjectDetails, setProjectDetails] = useState([]);
  const [ServiceProfessionalNameDetails, setServiceProfessionalNameDetails] = useState([]);
  const [SPProfileName, setSPProfileName] = useState("");
  const currentslide = galleryimages.filter((image) => image.Id === imgid)[0];
  const handlezoom = (action) => {
    imageref.current.style.transform =action === 'in' ? "scale(1.2)" : "scale(1)";
  };

  const CloseModal = () => {
    closemodal();
  }

  function getProjectIdofImage(imageId) {
    projectID.forEach(element => {
      element.Imagedetails.forEach(jindex => {
        if (jindex.Id === imageId) {
          dispatch(projectDetailsByID(element._id))
            .then((response) => {
              if(response.payload.success){
                // console.log("Response Data: ", response.payload.result);
                  setProjectDetails(response.payload.result.ProjectDetails[0]);
                  setServiceProfessionalNameDetails(response.payload.result.CompanyDetails.Company_Details);
                  setSPProfileName(response.payload.result.CompanyDetails.name);
                } else {
                setProjectDetails([]);
              }
            })
        }
      })
    });
  }

  useEffect(() => {
    getProjectIdofImage(imgid);
  }, [])

  return (
    <div className="show-image-container">
      <div className="image-preview-and-details-container">
        <div className="image-preview">
          <div className="image-options">
            <div className="close-icon">
              <AiOutlineCloseCircle onClick={CloseModal} className="option-icon" />
            </div>
            <div className="zoom">
              <AiOutlineZoomIn
                onClick={() => {
                  handlezoom("in");
                }}
                className="option-icon"
              />
              <AiOutlineZoomOut
                onClick={() => {
                  handlezoom("out");
                }}
                className="option-icon"
              />
            </div>
          </div>
          <div className="image">
          <img src={currentslide.Image} alt="image-preview" ref={imageref} />

          </div>
        </div>
        <div className="image-details-container">
          <p className="image-details-title">Image Details</p>
          <div className="image-details">
            <p className="title">Service Professional Profile ID :</p>
            {company.companyData && company.companyData.isAuth ? (
              <a
                href={`/ServiceProfessional/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                View Info
              </a>
            ) : (
              <a
                href={`/AllServiceProfessionalPage/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                View Info
              </a>
            )}

            <p className="title">Service Professional Name :</p>
            {company.companyData && company.companyData.isAuth ? (
              <a
                href={`/ServiceProfessional/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                {SPProfileName}
              </a>
            ) : (
              <a
                href={`/AllServiceProfessionalPage/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                {SPProfileName}
              </a>
            )}

            <p className="title">Company Name :</p>
            {company.companyData && company.companyData.isAuth ? (
              <a
                href={`/ServiceProfessional/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                {ServiceProfessionalNameDetails.Company_Name}
              </a>
            ) : (
              <a
                href={`/AllServiceProfessionalPage/Profile/OtherView/${ProjectDetails.writer}`}
                style={{ textDecoration: "none", color: "#1F80E0" }}
                className="link"
              >
                {ServiceProfessionalNameDetails.Company_Name}
              </a>
            )}

            <p className="title">Project Number :</p>
            <a
              href={`/ProjectsDetails/${ProjectDetails._id}`}
              style={{ textDecoration: "none", color: "#1F80E0" }}
              className="link"
            >
              View Info
            </a>

            <p className="title">Project Name :</p>
            <p>{ProjectDetails.Project_Name}</p>

            <p className="title">Project Client Name :</p>
            <p>{ProjectDetails.Project_Client_Name}</p>

            <p className="title">Project Year of Completion :</p>
            <p>{ProjectDetails.Project_Completion}</p>

            <p className="title">
              Project Page link to Company Website / Company YouTube Channel URL
              :
            </p>
            <a
              href={ProjectDetails.Project_Page_Link}
              target="_blank"
              style={{ textDecoration: "none", color: "#1F80E0" }}
              className="link"
            >
              {ProjectDetails.Project_Page_Link}
            </a>

            <p className="title">Project Service Requirement :</p>
            <p>{ProjectDetails.Service_Requirement}</p>

            <p className="title">Project Type & Style :</p>
            <p>
              {ProjectDetails.Project_Type} -{" "}
              {ProjectDetails.Project_Type_Subcategory_1} -{" "}
              {ProjectDetails.Project_Type_Subcategory_2} -{" "}
              {ProjectDetails.Project_Architecture_Style}
            </p>

            <p className="title">Image Title :</p>
            <p>{currentslide.Title}</p>

            <p className="title">Image Category :</p>
            <div className="categories">
              {currentslide.Category.map((item) => {
                return <p className="category">{item}</p>;
              })}
            </div>
            <p className="title">Image Description :</p>
            <p>{currentslide.Description}</p>
            {/* <p className="title">Company Name</p>
                <p>
                  {companyDetials(currentslide.Image)}
                </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowImage;
