import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./NavigationNumbers.css";

export default function NavigationNumbers(props) {
  const [navigatenumbers, setnavigatenumbers] = useState([]);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const numbersref = useRef(null);

  useEffect(() => {
    var numberofitems = props.numberofitems;

    var numbers = [];
    if (screenwidth <= props.screenwidthfornav1) {
      numberofitems = props.numberofitems1;
    }
    if (screenwidth <= props.screenwidthfornav2) {
      numberofitems = props.numberofitems2;
    }
    var itemslength = Math.floor(
      document.getElementById(`${props.itemscontainerid}`).childNodes.length /
        numberofitems
    );

    for (let i = 0; i < itemslength + 1; i++) {
      numbers.push(i + 1);
    }
    setnavigatenumbers(numbers);
  }, [
    screenwidth,
    props.numberofitems,
    props.itemscontainerid,
    props.numberofitems1,
    props.numberofitems2,
    props.screenwidthfornav1,
    props.screenwidthfornav2,
  ]);

  useEffect(() => {
    setTimeout(() => {
      numbersref.current.childNodes[0].style.color = "white";
      numbersref.current.childNodes[0].style.backgroundColor = "#fe5631";
    }, 5);
  }, []);

  const handlenumber = (e) => {
    const Numbers = numbersref.current.childNodes;
    var heighttomove = props.heighttomove;

    if(screenwidth < props.screenwidth1){
      heighttomove = props.heighttomove1;
    }
    if(screenwidth < props.screenwidth2){
      heighttomove = props.heighttomove2;
    }
    if(screenwidth < props.screenwidth3){
      heighttomove = props.heighttomove3;
    }
    if(screenwidth < props.screenwidth4){
      heighttomove = props.heighttomove4;
    }
    if(screenwidth < props.screenwidth5){
      heighttomove = props.heighttomove5;
    }
    if(screenwidth < props.screenwidth6){
      heighttomove = props.heighttomove6;
    }

    for (let i = 0; i < Numbers.length; i++) {
      Numbers[i].style.color = "black";
      Numbers[i].style.backgroundColor = "white";
    }
    e.currentTarget.style.color = "white";
    e.currentTarget.style.backgroundColor = "#fe5631";
    document.getElementById(
      `${props.itemscontainerid}`
    ).style.transform = `translateY(-${
      heighttomove * (e.currentTarget.innerText - 1)
    }px)`;
  };

  const handlearrow = (e) => {
    const widthtomove = 258;
    const transformwidth = parseInt(
      numbersref.current.style.transform.replace(/[^\d.]/g, "")
    );

    var numberslength = numbersref.current.childNodes.length / 4;

    if (e.currentTarget.className === "fas fa-chevron-left") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        numbersref.current.style.transform = `translateX(-${
          widthtomove * (numberslength - 1)
        }px)`;
      } else {
        numbersref.current.style.transform = `translateX(-${
          transformwidth - widthtomove
        }px)`;
      }
    }
    if (e.currentTarget.className === "fas fa-chevron-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        numbersref.current.style.transform = `translateX(-${widthtomove}px)`;
      }

      if (transformwidth >= widthtomove * (numberslength - 1)) {
        numbersref.current.style.transform = `translateX(-${0}px)`;
      } else {
        numbersref.current.style.transform = `translateX(-${
          transformwidth + widthtomove
        }px)`;
      }
    }
    if (navigatenumbers.length <= 4) {
      numbersref.current.style.transform = `translateX(-${0}px)`;
    }
  };

  return (
    <div className="navigation-numbers-container">
      <div className="navigations-container">
        <div className="navigations">
          <i onClick={handlearrow} className="fas fa-chevron-left"></i>
          <div className="all-numbers">
            <div ref={numbersref} id="numbers" className="numbers-container">
              {navigatenumbers.map((number, index) => {
                return (
                  <p onClick={handlenumber} key={index}>
                    {number}
                  </p>
                );
              })}
            </div>
          </div>
          <i onClick={handlearrow} className="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
  );
}
