import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Membership(props){

  const company = useSelector((state) => state.company);

  if(company.companyData && company.companyData.isAuth)
  {
    return <div>
        {company.companyData.Membership? 
        <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
          <a style={{ color: 'white' }} onClick={() => props.history.push('/ServiceProfessional/Pricing/Membership')} >Prime: <span>{company.companyData.MembershipValidity} Days Left</span></a>
        </div>
          : 
          <>
          <div className='subscription' style={{ backgroundColor: '#1F80E0' }}>
            <a style={{ color: 'white' }} onClick={() => props.history.push('/ServiceProfessional/Pricing/Membership')}>Join Our Community Now!</a>
          </div>
          </>
          }
    </div>
  } else {
    return null;
  }

}

export default withRouter(Membership);