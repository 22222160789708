import React, { useLayoutEffect, useState } from "react";
import "./Reviews.css";
import { reviews } from "./Data";

export default function Reviews() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  const handlearrow = (e) => {
    const clickedarrow = e.currentTarget.className;

    const fullreviewslength =
      document.getElementById("reviews").childNodes.length;
    const transformwidth = parseInt(
      document.getElementById("reviews").style.transform.replace(/[^\d.]/g, "")
    );
    var widthtomove = 340;
    var reviewslength = fullreviewslength;
    if (screenwidth > 1410) {
      widthtomove = 340;
      reviewslength = fullreviewslength-4
    }
    if (screenwidth <= 1410) {
      widthtomove = 410;
      reviewslength = fullreviewslength - 3;
    }
    if (screenwidth <= 1275) {
      widthtomove = 333;
      reviewslength = fullreviewslength - 3;
    }
    if (screenwidth <= 1070) {
      widthtomove = 350;
      reviewslength = fullreviewslength - 2;
    }
    if (screenwidth <= 780) {
      widthtomove =screenwidth+20;
      reviewslength = fullreviewslength - 1;
    }
    if (clickedarrow === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById("reviews").style.transform = `translateX(-${
          widthtomove * reviewslength
        }px)`;
      } else {
        document.getElementById("reviews").style.transform = `translateX(-${
          transformwidth - widthtomove
        }px)`;
      }
    }
    if (clickedarrow === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        document.getElementById(
          "reviews"
        ).style.transform = `translateX(-${widthtomove}px)`;
      }
      if (transformwidth >= widthtomove * reviewslength) {
        document.getElementById(
          "reviews"
        ).style.transform = `translateX(-${0}px)`;
      } else {
        document.getElementById("reviews").style.transform = `translateX(-${
          widthtomove + transformwidth
        }px)`;
      }
    }
  };

  return (
    <div className="reviews-container">
      <h1 className="title">Social Reviews By Our Clients</h1>
      <p
        style={{ width: screenwidth <= 780 ? screenwidth - 50 + "px" : null }}
        className="description"
      >
    Read on to see what our customers have to say about us !
      </p>
      <div className="allreviews-container">
        <div
          style={{ width: screenwidth <= 780 ? screenwidth - 10 + "px" : null }}
          className="arrows"
        >
          <i onClick={handlearrow} id="left" className="fas fa-arrow-left"></i>
          <i
            onClick={handlearrow}
            id="right"
            className="fas fa-arrow-right"
          ></i>
        </div>
        <div
          style={{ width: screenwidth <= 780 ? screenwidth + "px" : null }}
          className="allreviews"
        >
          <div id="reviews" className="reviews">
            {reviews.map((review, index) => {
              return (
                <div
                  style={{
                    minWidth:
                      screenwidth <= 780 ? screenwidth-10 + "px" : null,
                  }}
                  key={index}
                  className="review"
                >
                  <img src={review.Image} alt={review.Name} />
                  <h3 className="customer-name">{review.Name}</h3>
                  <p className="customer-profession">{review.Profession}</p>
                  <p className="customer-review">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{review.Review}</p>
                  <div className="rating">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i style={{
                          display: review.Rating === 4 ? "none" : null,
                        }} className="fas fa-star"></i>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}
