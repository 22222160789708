import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PO_VisitsCompanySocialMediaAnalytics, SP_VisitsCompanySocialMediaAnalytics } from "../../../../../../_actions/company_analytics";
import "./SP_Otherview_Overview.css";

function SP_Otherview_Overview(props) {

    let companyDetails = props.companyDetails;
    // console.log(companyDetails);
    const dispatch = useDispatch();
    const company = useSelector((state) => state.company);
    const user = useSelector((state) => state.user);

    const onHandleFacebookClick = (companyId, Social_Media) => {
      const variables = {
        Social_Media: Social_Media,
        Company: companyId,
        Visited_Date: new Date().toLocaleDateString(),
        Visited_Time: new Date().toTimeString(),
      };
      if (user.userData && user.userData.isAuth) {
        // console.log("Handle Facebook Click User", variables);
        dispatch(PO_VisitsCompanySocialMediaAnalytics(variables)).then(
          (response) => {
            // console.log("Response", response);
          }
        );
      }
      if (company.companyData && company.companyData.isAuth) {
        if (company.companyData._id !== companyId) {
          // console.log("Handle Facebook Click Company");
          dispatch(SP_VisitsCompanySocialMediaAnalytics(variables)).then(
            (response) => {
              // console.log("Response", response);
            }
          );
        }
      }
    };

    return (
      <div className="profile-overview-container">
        <br />
        <div className="profile-overview">
          <div className="profile-detail">
            {/* <p className="profile-detail-title">User Details</p> */}
            <fieldset>
              <legend>User Details</legend>
              <table className="profile-detail-table">
                <tr>
                  {/* <td className="title">User Name</td>
                  <td>{companyDetails.name}</td> */}
                  <td className="title">User Email Id</td>
                  <td>{companyDetails.email}</td>
                  <td className="title">User Phone Number</td>
                  <td>
                    {companyDetails.Contact_Details.Main_Head_Office_Number.User_Phone_Number}
                  </td>
                  <td className="title">S.P. Profile No.</td>
                  <td>
                    {props.companyDetails._id}
                  </td>
                </tr>
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            {/* <p className="profile-detail-title">Personal Details</p> */}

            <fieldset>
              <legend>Personal Details</legend>
              <table className="profile-detail-table">
                <tr>
                  <td className="title">Service Professional Name</td>
                  <td>{companyDetails.name}</td>
                  <td className="title">Date of Birth</td>
                  <td>{companyDetails.Personal_Details.DOB}</td>
                  <td className="title">Gender</td>
                  <td>{companyDetails.Personal_Details.Gender}</td>
                </tr>
                {/* Languages */}

                <tr className="titles-and-list">
                  <td className="title">Languages Known</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {languages.map((language, index) => {
                    return <li key={index}>{language}</li>;
                  })} */}
                      {companyDetails.Personal_Details.Languages_Known
                        .Assamese ? (
                        <li>
                          <i className="fas fa-check"></i>Assamese
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Bengali ? (
                        <li>
                          <i className="fas fa-check"></i>Bengali
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .English ? (
                        <li>
                          <i className="fas fa-check"></i>English
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Gujarati ? (
                        <li>
                          <i className="fas fa-check"></i>Gujarati
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known.Hindi ? (
                        <li>
                          <i className="fas fa-check"></i>Hindi
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Kannada ? (
                        <li>
                          <i className="fas fa-check"></i>Kannada
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Malayalam ? (
                        <li>
                          <i className="fas fa-check"></i>Malayalam
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Marathi ? (
                        <li>
                          <i className="fas fa-check"></i>Marathi
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known.Odia ? (
                        <li>
                          <i className="fas fa-check"></i>Odia
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Punjabi ? (
                        <li>
                          <i className="fas fa-check"></i>Punjabi
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known.Tamil ? (
                        <li>
                          <i className="fas fa-check"></i>Tamil
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known
                        .Telugu ? (
                        <li>
                          <i className="fas fa-check"></i>Telugu
                        </li>
                      ) : null}
                      {companyDetails.Personal_Details.Languages_Known.Urdu ? (
                        <li>
                          <i className="fas fa-check"></i>Urdu
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
                {/* Personal Skills and Hobbies */}
                {companyDetails.Personal_Details.Personal_Skills_Hobbies
                  .length > 0 ? (
                  <tr className="titles-and-list">
                    <td className="title">Personal Skills and Hobbies</td>
                    <td className="profile-detail-list-container">
                      <ul className="profile-detail-list">
                        {companyDetails.Personal_Details.Personal_Skills_Hobbies.map(
                          (hobbie, index) => {
                            return (
                              <li key={index}>
                                <i className="fas fa-check"></i>
                                {hobbie}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </td>
                  </tr>
                ) : null}
                {/* Personal Awards & Achievements */}
                {companyDetails.Personal_Details.Personal_Awards_Achievements
                  .length > 0 ? (
                  <>
                    <tr className="titles-and-list">
                      <td className="title">Personal Awards & Achievements</td>
                      <td className="profile-detail-list-container">
                        <ul className="profile-detail-list">
                          {companyDetails.Personal_Details.Personal_Awards_Achievements.map(
                            (award, index) => {
                              return (
                                <li key={index}>
                                  <i className="fas fa-check"></i>
                                  {award}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : null}
                {/* {companyDetails.Personal_Details.Aadhaar_Card ? (
                  <tr>
                    <td className="title">Government Proof</td>
                  </tr>
                ) : null}
                {companyDetails.Personal_Details.Aadhaar_Card ? (
                  <tr>
                    <td className="title">Adhaar Card</td>
                    <td>{companyDetails.Personal_Details.Aadhaar_Card}</td>
                  </tr>
                ) : null} */}
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            <fieldset>
              <legend>Professional Details</legend>
              <table className="profile-detail-table">
                <tr>
                  <td className="title">Educational Qualification</td>
                  <td>
                    {
                      companyDetails.Professional_Details
                        .Educational_Qualification
                    }
                  </td>
                  <td className="title">Profession</td>
                  <td>
                    {
                      companyDetails.Professional_Details
                        .Professional_Profession
                    }
                  </td>
                  <td className="title">Profession Type</td>
                  <td>{ companyDetails.Professional_Details.Profession_Type.Type }</td>
                </tr>
                {/* Professional Skills */}
                {companyDetails.Professional_Details.Personal_Skills.length >
                0 ? (
                  <>
                    <tr className="titles-and-list">
                      <td className="title">Professional Skills</td>
                      <td className="profile-detail-list-container">
                        <ul className="profile-detail-list">
                          {companyDetails.Professional_Details.Personal_Skills.map(
                            (skill, index) => {
                              return (
                                <li key={index}>
                                  <i className="fas fa-check"></i>
                                  {skill}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : null}
                {/* Professional Awards and Achievements */}
                {companyDetails.Professional_Details
                  .Personal_Awards_Achievements.length > 0 ? (
                  <>
                    <tr className="titles-and-list">
                      <td className="title">
                        Professional Awards and Achievements
                      </td>
                      <td className="profile-detail-list-container">
                        <ul className="profile-detail-list">
                          {companyDetails.Professional_Details.Personal_Awards_Achievements.map(
                            (award, index) => {
                              return (
                                <li key={index}>
                                  <i className="fas fa-check"></i>
                                  {award}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : null}

                <tr>
                  <td className="title">Overall Experience in years</td>
                  <td>
                    {companyDetails.Professional_Details.Overall_Experience}
                  </td>
                  <td className="title">Number of Projects Completed</td>
                  <td>
                    {companyDetails.Professional_Details.Project_Completed}
                  </td>
                </tr>
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            {/* <p className="profile-detail-title">Company Details</p> */}

            <fieldset>
              <legend>Company Details</legend>
              <table className="profile-detail-table">
                <tr>
                  <td className="title">Company Name</td>
                  <td>{companyDetails.Company_Details.Company_Name}</td>
                  <td className="title">Year of Establishment</td>
                  <td>
                    {companyDetails.Company_Details.Year_of_Establishment}
                  </td>
                  <td className="title">Number of Employees</td>
                  <td>{companyDetails.Company_Details.Number_of_Employees}</td>
                </tr>

                {/*Workdays*/}

                <tr className="titles-and-list">
                  <td className="title">Workdays</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {workdays.map((workday, index) => {
                    return <li key={index}>{workday}</li>;
                  })} */}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Monday ? (
                        <li>
                          <i className="fas fa-check"></i>Monday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Tuesday ? (
                        <li>
                          <i className="fas fa-check"></i>Tuesday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Wednesday ? (
                        <li>
                          <i className="fas fa-check"></i>Wednesday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Thursday ? (
                        <li>
                          <i className="fas fa-check"></i>Thursday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Friday ? (
                        <li>
                          <i className="fas fa-check"></i>Friday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Saturday ? (
                        <li>
                          <i className="fas fa-check"></i>Saturday
                        </li>
                      ) : null}
                      {companyDetails.Company_Details.Workdays_Timings.Days
                        .Sunday ? (
                        <li>
                          <i className="fas fa-check"></i>Sunday
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
                {/* Timings */}
                <tr>
                  <td className="title">Timings</td>
                  <td>
                    From{" "}
                    {
                      companyDetails.Company_Details.Workdays_Timings.Timing
                        .From
                    }{" "}
                    To{" "}
                    {companyDetails.Company_Details.Workdays_Timings.Timing.To}
                  </td>
                </tr>
                {/* Company Awards and Achievements */}
                {companyDetails.Professional_Details.Personal_Skills.length >
                0 ? (
                  <>
                    <tr className="titles-and-list">
                      <td className="title">Company Awards & Achievements</td>
                      <td className="profile-detail-list-container">
                        <ul className="profile-detail-list">
                          {companyDetails.Company_Details.Company_Award_Achievements.map(
                            (skill, index) => {
                              return (
                                <li key={index}>
                                  <i className="fas fa-check"></i>
                                  {skill}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <td className="title" id="fullwidth">About the Company</td>
                  <td id="fullwidth">{companyDetails.Company_Details.About_the_Company}</td>
                </tr>
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            {/* <p className="profile-detail-title">Contact Details</p> */}

            <fieldset>
              <legend>Contact Details</legend>

              <table className="profile-detail-table">
                {/* Main Head Office Phone Number */}
                <p className="sub-title">Main Head Office Phone Number</p>
                <tr>
                  <td className="title">User Phone Number</td>
                  <td>
                  <a
                        className="tel"
                        href={`tel:${companyDetails.Contact_Details.Main_Head_Office_Number
                        .User_Phone_Number}`}
                        style={{ color: '#1F80E0', textDecoration: 'none' }}
                      >
                        <i className="fas fa-phone-alt"></i>
                        &nbsp;
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Number
                        .User_Phone_Number
                    }
                        </a>
                  </td>
                  <td className="title">Phone No.2</td>
                  <td>
                  <a
                        className="tel"
                        href={`tel:${companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_2}`}
                        style={{ color: '#1F80E0', textDecoration: 'none' }}
                      >
                        <i className="fas fa-phone-alt"></i>
                        &nbsp;
                    {companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_2}
                        </a>
                  </td>
                  <td className="title">Phone No.3</td>
                  <td>
                  <a
                        className="tel"
                        href={`tel:${companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_3}`}
                        style={{ color: '#1F80E0', textDecoration: 'none' }}
                      >
                        <i className="fas fa-phone-alt"></i>
                        &nbsp;
                    {companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_3}
                        </a>
                  </td>
                  <td className="title">Whatsapp Phone No.</td>
                  <td>
                    {companyDetails.Contact_Details.Main_Head_Office_Number.Company_WhatsApp}
                  </td>
                </tr>
                {/* Main Head Office Email ID */}
                <p className="sub-title">Main Head Office Email ID</p>
                <tr>
                  <td className="title">User Email ID</td>
                  <td>
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Email
                        .User_Email
                    }
                  </td>
                  <td className="title">Email ID 2</td>
                  <td>
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Email
                        .Email_1
                    }
                  </td>
                  <td className="title">Email ID 3</td>
                  <td>
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Email
                        .Email_2
                    }
                  </td>
                  {companyDetails.Contact_Details.Website_URL ? (
                    <>
                      <td className="title">
                        Website Url
                      </td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Website_URL}`}
                        target="_blank"
                        style={{ color: '#1F80E0' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Website_URL")
                              }
                        >
                        Click Here
                        {/* {companyDetails.Contact_Details.Website_URL} */}
                        </a>
                      </td>
                    </>
                  ) : null}
                </tr>

                {/* Social Media Links */}
                <p className="sub-title">Social Media Links</p>
                <tr>
                  {companyDetails.Contact_Details.Social_Media.Facebook ? (
                    <>
                      <td className="title">Facebook</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Facebook}`}
                        target="_blank"
                        style={{ color: '#4867AA' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Facebook")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Facebook} */}
                        <i id="fb" className="fab fa-facebook-f fa-lg"></i>
                      </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Instagram ? (
                    <>
                      <td className="title">Instagram</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Instagram}`}
                        target="_blank"
                        style={{ color: 'none' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Instagram")
                              }
                        >
                          {/* {companyDetails.Contact_Details.Social_Media.Instagram} */}
                          <i id="insta" className="fab fa-instagram fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Linkedin ? (
                    <>
                      <td className="title">LinkedIn</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Linkedin}`}
                        target="_blank"
                        style={{ color: '#0077B5' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Linkedin")
                              }
                        >
                          {/* {companyDetails.Contact_Details.Social_Media.Linkedin} */}
                          <i id="linkedin" className="fab fa-linkedin fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Pinterest ? (
                    <>
                      <td className="title">Pinterest</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Pinterest}`}
                        target="_blank"
                        style={{ color: '#C51F27' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Pinterest")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Pinterest} */}
                        <i id="pin" className="fab fa-pinterest-p fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Quora ? (
                    <>
                      <td className="title">Quora</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Quora}`}
                        target="_blank"
                        style={{ color: '#A82400' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Quora")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Quora} */}
                        <i id="quora" className="fab fa-quora fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Reddit ? (
                    <>
                      <td className="title">Reddit</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Reddit}`}
                        target="_blank"
                        style={{ color: '#FF4500' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Reddit")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Reddit} */}
                        <i id="reddit" className="fab fa-reddit fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Twitter ? (
                    <>
                      <td className="title">Twitter</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Twitter}`}
                        target="_blank"
                        style={{ color: '#1DA1F2' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Twitter")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Twitter} */}
                        <i id="twt" className="fab fa-twitter fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                  {companyDetails.Contact_Details.Social_Media.Youtube ? (
                    <>
                      <td className="title">Youtube</td>
                      <td>
                      <a
                        href={`${companyDetails.Contact_Details.Social_Media.Youtube}`}
                        target="_blank"
                        style={{ color: '#FF0000' }}
                        onClick={() =>
                                onHandleFacebookClick(companyDetails._id, "Youtube")
                              }
                        >
                        {/* {companyDetails.Contact_Details.Social_Media.Youtube} */}
                        <i id="youtube" className="fab fa-youtube fa-lg"></i>
                        </a>
                      </td>
                    </>
                  ) : null}
                </tr>

                {/* Main Head Office Address */}
                <p className="sub-title">Main Head Office Address</p>
                <tr className="small-inputs">
                  <td className="title">House name / Building Name</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .House_Builing_Name
                    }
                  </td>
                  <td className="title">Street Name / Road</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .Street_Road_Name
                    }
                  </td>
                  <td className="title">Locality Name</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .Locality
                    }
                  </td>
                  <td className="title">Landmark</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .Landmark
                    }
                  </td>
                  <td className="title">State</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .State
                    }
                  </td>
                  <td className="title">City</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .City
                    }
                  </td>
                  <td className="title">Area Name</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .Area_Name
                    }
                  </td>
                  <td className="title">Pin Code</td>
                  <td className="small-input">
                    {
                      companyDetails.Contact_Details.Main_Head_Office_Address
                        .Pincode
                    }
                  </td>
                </tr>
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            {/* <p className="profile-detail-title">Branch Details</p> */}
            <fieldset
              style={{
                width: "92%",
              }}
            >
              <legend>Branch Details</legend>
              <div className="branches-array">
                {companyDetails.Branches.length > 0
                  ? companyDetails.Branches.map((branch, index) => {
                      return (
                        <table className="profile-detail-table">
                          <p
                            className="sub-title"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Branch - {index + 1}
                          </p>
                          {/* <p className="sub-title">Branch Office Phone Number</p>
                      <tr>
                        <td className="title">Phone No. 1</td>
                        <td>{branch.BranchOfficePhoneNumber.PhoneNo1}</td>
                        <td className="title">Phone No. 2</td>
                        <td>{branch.BranchOfficePhoneNumber.PhoneNo2}</td>
                        <td className="title">Whatsapp No.</td>
                        <td>{branch.BranchOfficePhoneNumber.WhatsappNo}</td>
                      </tr> */}
                          {/* <p className="sub-title">Branch Office Email ID</p>
                      <tr>
                        <td className="title">Email ID 1</td>
                        <td>{branch.BranchOfficeEmailID.EmailID1}</td>
                        <td className="title">Email ID 2</td>
                        <td>{branch.BranchOfficeEmailID.EmailID2}</td>
                      </tr> */}
                          <p className="sub-title">Branch Office Address</p>
                          <tr className="small-inputs">
                            {/* <td className="title">House Name / Building Name</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.HouseName}
                        </td>
                        <td className="title">Street Name / Road</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.StreetName}
                        </td>
                        <td className="title">Locality Name</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.LocalityName}
                        </td>
                        <td className="title">Landmark</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.Landmark}
                        </td> */}
                            <td className="title">State</td>
                            <td className="small-input">
                              {branch.BranchOfficeAddress.State}
                            </td>
                            <td className="title">City</td>
                            <td className="small-input">
                              {branch.BranchOfficeAddress.City}
                            </td>
                            {/* <td className="title">AreaName</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.Areaname}
                        </td>
                        <td className="title">Pincode</td>
                        <td className="small-input">
                          {branch.BranchOfficeAddress.Pincode}
                        </td> */}
                          </tr>
                        </table>
                      );
                    })
                  : null}
              </div>
            </fieldset>
            <div className="profile-detail">
              {/* <p className="profile-detail-title">Projects Catered To</p> */}

              <fieldset>
                <legend>Projects Catered To</legend>

                <table className="profile-detail-table">
                  <p className="sub-title" style={{ textAlign: "center" }}>
                    What kind of projects do you undertake?
                  </p>
                  <p className="sub-title">Interior Design</p>
                  <tr>
                    <td className="title">Residential</td>
                    <td>
                      {
                        companyDetails.Project_Catered.Interior_Design
                          .Residential
                      }
                    </td>
                    <td className="title">Commercial</td>
                    <td>
                      {
                        companyDetails.Project_Catered.Interior_Design
                          .Commercial
                      }
                    </td>
                  </tr>

                  <p className="sub-title">Architectural Construction</p>
                  <tr>
                    <td className="title">Residential</td>
                    <td>
                      {
                        companyDetails.Project_Catered
                          .Architectural_Construction.Residential
                      }
                    </td>
                    <td className="title">Commercial</td>
                    <td>
                      {
                        companyDetails.Project_Catered
                          .Architectural_Construction.Commercial
                      }
                    </td>
                  </tr>

                  <p className="sub-title">Renovation</p>
                  <tr>
                    <td className="title">Residential</td>
                    <td>
                      {companyDetails.Project_Catered.Renovation.Residential}
                    </td>
                    <td className="title">Commercial</td>
                    <td>
                      {companyDetails.Project_Catered.Renovation.Commercial}
                    </td>
                  </tr>

                  <tr>
                    <td className="title">
                      Do you provide consultation as per Vaastu ?
                    </td>
                    <td>{companyDetails.Project_Catered.Consultation}</td>
                  </tr>
                </table>
              </fieldset>
            </div>
          </div>

          <div className="profile-detail">
            {/* <p className="profile-detail-title">Services Provided</p> */}

            <fieldset>
              <legend>Services Provided</legend>

              <table className="profile-detail-table">
                <tr className="titles-and-list">
                  <td className="title">Initial Consultation</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {initialconsultations.map((award, index) => {
                    return <li key={index}>{award}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Initial_Consultation
                        .Company_Office ? (
                        <li>
                          <i className="fas fa-check"></i>Company Office
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Initial_Consultation
                        .Site ? (
                        <li>
                          <i className="fas fa-check"></i>Site
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">Softwares Used</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {softwaresused.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Software_Used
                        .Interior_Design ? (
                        <li>
                          <i className="fas fa-check"></i>Interior Design
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Software_Used
                        .Architectural_Construction ? (
                        <li>
                          <i className="fas fa-check"></i>Architectural
                          Construction
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Software_Used
                        .Civil_Engineering ? (
                        <li>
                          <i className="fas fa-check"></i>Civil Engineering
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">Design Schematics</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {designschematics.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Plans ? (
                        <li>
                          <i className="fas fa-check"></i>Plans
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Drawing_Sketches ? (
                        <li>
                          <i className="fas fa-check"></i>Drawings / Sketches
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Design ? (
                        <li>
                          <i className="fas fa-check"></i>Designs
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Consultation ? (
                        <li>
                          <i className="fas fa-check"></i>Consultation
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Elevations ? (
                        <li>
                          <i className="fas fa-check"></i>Elevations
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Renders ? (
                        <li>
                          <i className="fas fa-check"></i>Renders
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Schematics
                        .Walk_Through ? (
                        <li>
                          <i className="fas fa-check"></i>Walk Through
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td className="title">Booking Confirmation</td>
                  {companyDetails.Service_Provided.Booking_Confirmation ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                  <td className="title">Legal Agreements</td>
                  {companyDetails.Service_Provided.Legal_Agreements ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                  <td className="title">Project Timeframe</td>
                  {companyDetails.Service_Provided.Project_Timeframe ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                  <td className="title">Execution</td>
                  {companyDetails.Service_Provided.Execution ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>
                <tr>
                  <td className="title" id="fullwidth">
                    Types of Properties Served
                  </td>
                </tr>
                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Residential</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {typeofpropertiesserved.Residential.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Apartment ? (
                        <li>
                          <i className="fas fa-check"></i>Apartment
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Penthouse ? (
                        <li>
                          <i className="fas fa-check"></i>Penthouse
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Stan_Alone_Building ? (
                        <li>
                          <i className="fas fa-check"></i>Stan Alone Building
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Independent_House ? (
                        <li>
                          <i className="fas fa-check"></i>Independent House
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Villa ? (
                        <li>
                          <i className="fas fa-check"></i>Villa
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Residential.Bungalow ? (
                        <li>
                          <i className="fas fa-check"></i>Bungalow
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Commercial</td>
                  <td className="profile-detail-list-container ">
                    <ul className="profile-detail-list">
                      {/* {typeofpropertiesserved.Commercial.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Retail ? (
                        <li>
                          <i className="fas fa-check"></i>Retail
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Hospitality ? (
                        <li>
                          <i className="fas fa-check"></i>Hospitality -
                          (Accommodation, Food & Beverage, Travel & Tourism)
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Healthcare ? (
                        <li>
                          <i className="fas fa-check"></i>Healthcare
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Office ? (
                        <li>
                          <i className="fas fa-check"></i>Office - Corporate &
                          Tech
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Industrial ? (
                        <li>
                          <i className="fas fa-check"></i>Industrial
                        </li>
                      ) : null}
                      <br />
                    </ul>
                  </td>
                </tr>

                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Institutional</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {typeofpropertiesserved.Institutional.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Institutional.Educational_Sector ? (
                        <li>
                          <i className="fas fa-check"></i>Educational Sector
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Institutional.Financial_Sector ? (
                        <li>
                          <i className="fas fa-check"></i>Financial Sector
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Institutional.Media_Entertainment_Sector ? (
                        <li>
                          <i className="fas fa-check"></i>Media & Entertainment
                          Sector
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Institutional
                        .Research_Development_Sector ? (
                        <li>
                          <i className="fas fa-check"></i>Research & Development
                          Sector
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Type_of_Properties_Served
                        .Commercial.Institutional.Transportation_Sector ? (
                        <li>
                          <i className="fas fa-check"></i>Transportation Sector
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
                <br />
                <tr className="titles-and-list">
                  <td className="title">Interior Design Execution Services</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {InteriorDesignExecutionServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Hard_Finishes ? (
                        <li>
                          <i className="fas fa-check"></i>Hard Finishes
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Furniture ? (
                        <li>
                          <i className="fas fa-check"></i>Furniture, Fixtures &
                          Equipment
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Soft_Finishes ? (
                        <li>
                          <i className="fas fa-check"></i>Soft Finishes
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Art_Decore ? (
                        <li>
                          <i className="fas fa-check"></i>Art & Décor
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Eco_Friendly ? (
                        <li>
                          <i className="fas fa-check"></i>Eco – Friendly
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Entertainment ? (
                        <li>
                          <i className="fas fa-check"></i>Entertainment
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Tech_Savvy ? (
                        <li>
                          <i className="fas fa-check"></i>Tech – Savvy
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services
                        .Disabled_Handicap ? (
                        <li>
                          <i className="fas fa-check"></i>Disabled / Handicap –
                          Accessible Designs & Services
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Health_Safety ? (
                        <li>
                          <i className="fas fa-check"></i>Health & Safety
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Interior_Design_Execution_Services.Security_Systems ? (
                        <li>
                          <i className="fas fa-check"></i>Security Systems
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="title" id="fullwidth">
                    Architectural Construction Execution Services
                  </td>
                </tr>

                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Grey Structure</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {ArchitecturalConstructionExecutionServices.GreyStructure.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                      {/* Grey Structure */}
                      {companyDetails.Service_Provided
                        .Architectural_Construction_Execution.Grey_Structure
                        .Soil_Testing ? (
                        <li>
                          <i className="fas fa-check"></i>Soil Testing
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Architectural_Construction_Execution.Grey_Structure
                        .Site_Clearance ? (
                        <li>
                          <i className="fas fa-check"></i>Site Clearance
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Architectural_Construction_Execution.Grey_Structure
                        .Site_Layout ? (
                        <li>
                          <i className="fas fa-check"></i>Site Layout
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Architectural_Construction_Execution.Grey_Structure
                        .Substructure_Construction ? (
                        <li>
                          <i className="fas fa-check"></i>Substructure
                          Construction Phase
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided
                        .Architectural_Construction_Execution.Grey_Structure
                        .Super_Structure ? (
                        <li>
                          <i className="fas fa-check"></i>Super Structure
                          Construction Phase
                        </li>
                      ) : null}
                    </ul>
                  </td>
                  <tr className="small-inputs">
                    <td className="title">
                      Disabled / Handicap – Accessible Designs & Services
                    </td>
                    {companyDetails.Service_Provided
                      .Architectural_Construction_Execution
                      .Disabled_Handicap ? (
                      <td className="small-input">Yes</td>
                    ) : (
                      <td className="small-input">No</td>
                    )}
                    <td className="title">Health & Safety</td>
                    {companyDetails.Service_Provided
                      .Architectural_Construction_Execution.Health_Safety ? (
                      <td className="small-input">Yes</td>
                    ) : (
                      <td className="small-input">No</td>
                    )}
                    <td className="title">Security Systems</td>
                    {companyDetails.Service_Provided
                      .Architectural_Construction_Execution.Security_System ? (
                      <td className="small-input">Yes</td>
                    ) : (
                      <td className="small-input">No</td>
                    )}
                    <td className="title">Finishing</td>
                    {companyDetails.Service_Provided
                      .Architectural_Construction_Execution.Finishing ? (
                      <td className="small-input">Yes</td>
                    ) : (
                      <td className="small-input">No</td>
                    )}
                  </tr>
                </tr>
                <br />
                <tr className="titles-and-list">
                  <td className="title">Civil & Renovation Services</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {CivilRenovationServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Civil_Renovation
                        .Interior_Design ? (
                        <li>
                          <i className="fas fa-check"></i>Same as the above
                          selected Interior Design Execution Services
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Civil_Renovation
                        .Architectural_Construction ? (
                        <li>
                          <i className="fas fa-check"></i>Same as the above
                          selected Architectural Construction Execution Services
                          Selected
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">Other Services</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {OtherServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Other_Services
                        .Assembling ? (
                        <li>
                          <i className="fas fa-check"></i>Assembling Furniture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Other_Services
                        .Heavy_Items ? (
                        <li>
                          <i className="fas fa-check"></i>Heavy Item Lifting and
                          Moving
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Other_Services
                        .Household ? (
                        <li>
                          <i className="fas fa-check"></i>Household Chores
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Other_Services
                        .Packers_Movers ? (
                        <li>
                          <i className="fas fa-check"></i>Packers and Movers
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Other_Services
                        .Packers_Movers ? (
                        <li>
                          <i className="fas fa-check"></i>Pest Control
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">Products & Accessories</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {ProductsAccessories.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Appilances ? (
                        <li>
                          <i className="fas fa-check"></i>Appliances
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Artwork ? (
                        <li>
                          <i className="fas fa-check"></i>Artwork
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Decor ? (
                        <li>
                          <i className="fas fa-check"></i>Décor
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Furniture ? (
                        <li>
                          <i className="fas fa-check"></i>Furniture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Hardware ? (
                        <li>
                          <i className="fas fa-check"></i>Hardware
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Mirrors ? (
                        <li>
                          <i className="fas fa-check"></i>Mirrors
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Modular_Customized ? (
                        <li>
                          <i className="fas fa-check"></i>Modular / Customized
                          Furniture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Painting ? (
                        <li>
                          <i className="fas fa-check"></i>Painting
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Upholstery ? (
                        <li>
                          <i className="fas fa-check"></i>Upholstery
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Product_Accessories
                        .Wallpapers ? (
                        <li>
                          <i className="fas fa-check"></i>Wallpapers
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">Final Review & Handover</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {FinalReviewHandover.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                      {companyDetails.Service_Provided.Final_Review_Handover
                        .Final_Builing ? (
                        <li>
                          <i className="fas fa-check"></i>Final Building
                          Inspection
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Final_Review_Handover
                        .Confirmation_Payment ? (
                        <li>
                          <i className="fas fa-check"></i>Confirmation of Final
                          Payment Received
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Final_Review_Handover
                        .Documentation ? (
                        <li>
                          <i className="fas fa-check"></i>Documentation
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Final_Review_Handover
                        .Handover ? (
                        <li>
                          <i className="fas fa-check"></i>Handover{" "}
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Final_Review_Handover
                        .Photoshoot_Videography ? (
                        <li>
                          <i className="fas fa-check"></i>Photoshoot &
                          Videography
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="titles-and-list">
                  <td className="title">After – Sales Support</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {companyDetails.Service_Provided.After_Sales ? (
                        <li>
                          <i className="fas fa-check"></i>Warranty Certification
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="title" id="fullwidth">
                    Design Specialization
                  </td>
                </tr>
                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Interior Design Styles</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {DesignSpecialization.InteriorDesignStyles.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Asian ? (
                        <li>
                          <i className="fas fa-check"></i>Asian
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Beach_Nautical ? (
                        <li>
                          <i className="fas fa-check"></i>Beach / Nautical
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Contemporary_Home ? (
                        <li>
                          <i className="fas fa-check"></i>Contemporary Home
                          Décor
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Craftsman ? (
                        <li>
                          <i className="fas fa-check"></i>Craftsman
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Eclectic ? (
                        <li>
                          <i className="fas fa-check"></i>Eclectic
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Eco_Friendly ? (
                        <li>
                          <i className="fas fa-check"></i>Eco - Friendly
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Farmhouse ? (
                        <li>
                          <i className="fas fa-check"></i>Farmhouse
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Hollywood_Glam ? (
                        <li>
                          <i className="fas fa-check"></i>Hollywood Glam
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Industrial ? (
                        <li>
                          <i className="fas fa-check"></i>Industrial
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Mediterranean ? (
                        <li>
                          <i className="fas fa-check"></i>Mediterranean
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Mid_Century ? (
                        <li>
                          <i className="fas fa-check"></i>Mid – Century Modern
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Minimalist ? (
                        <li>
                          <i className="fas fa-check"></i>Minimalist
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Modern ? (
                        <li>
                          <i className="fas fa-check"></i>Modern
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Rustic ? (
                        <li>
                          <i className="fas fa-check"></i>Rustic
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Scandinavian ? (
                        <li>
                          <i className="fas fa-check"></i>Scandinavian
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Shabby_Chic ? (
                        <li>
                          <i className="fas fa-check"></i>Shabby – Chic
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Southwestern ? (
                        <li>
                          <i className="fas fa-check"></i>Southwestern
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Traditional ? (
                        <li>
                          <i className="fas fa-check"></i>Traditional
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Transitional ? (
                        <li>
                          <i className="fas fa-check"></i>Transitional
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Interior_Design.Tropical ? (
                        <li>
                          <i className="fas fa-check"></i>Tropical
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>

                <tr className="sub-categories" id="titles-and-list">
                  <td className="title">Architectural Styles</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {/* {DesignSpecialization.ArchitecturalStyles.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Art_Deco ? (
                        <li>
                          <i className="fas fa-check"></i>Art Deco
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Bauhaus ? (
                        <li>
                          <i className="fas fa-check"></i>Bauhaus
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Constructivism ? (
                        <li>
                          <i className="fas fa-check"></i>Constructivism (Art)
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Constructivist_Architecture ? (
                        <li>
                          <i className="fas fa-check"></i>Constructivist
                          Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Contemporary_Architecture ? (
                        <li>
                          <i className="fas fa-check"></i>Contemporary
                          Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Eco_Friendly ? (
                        <li>
                          <i className="fas fa-check"></i>Eco - Friendly
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Functionalism ? (
                        <li>
                          <i className="fas fa-check"></i>Functionalism
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Futurist_Architecture ? (
                        <li>
                          <i className="fas fa-check"></i>Futurist Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.High_Tech ? (
                        <li>
                          <i className="fas fa-check"></i>High - Tech
                          Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Industrial ? (
                        <li>
                          <i className="fas fa-check"></i>Industrial
                          Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.International ? (
                        <li>
                          <i className="fas fa-check"></i>International Style
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Mid_Century ? (
                        <li>
                          <i className="fas fa-check"></i>Mid - Century Modern
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Minimalism ? (
                        <li>
                          <i className="fas fa-check"></i>Minimalism
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Modern ? (
                        <li>
                          <i className="fas fa-check"></i>Modern Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.New_Objectivity ? (
                        <li>
                          <i className="fas fa-check"></i>New Objectivity
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Organic ? (
                        <li>
                          <i className="fas fa-check"></i>Organic Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Postmodern ? (
                        <li>
                          <i className="fas fa-check"></i>Postmodern
                          Architecture
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Ranch ? (
                        <li>
                          <i className="fas fa-check"></i>Ranch - Style House
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Streamline ? (
                        <li>
                          <i className="fas fa-check"></i>Streamline Modern
                        </li>
                      ) : null}
                      {companyDetails.Service_Provided.Design_Specialization
                        .Architectural_Style.Sustainable ? (
                        <li>
                          <i className="fas fa-check"></i>Sustainable
                          Architecture
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
              </table>
            </fieldset>
          </div>

          <div className="profile-detail">
            <fieldset>
              <legend>Declaration</legend>
              <table className="profile-detail-table">
                <tr style={{ padding: '20px', fontSize: '15px' }}>
                  <div>
                {company.companyData && company.companyData.isAuth ? 
                <>
                The Service Professional has agreed to this&nbsp;  
                  <a href="/ServiceProfessional/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                  Declaration.
                  </a>
                </>
                :
                  <>
                  {

                    user.userData && user.userData.isAuth ? 
                    <>
                      The Service Professional has agreed to this&nbsp;  
                      <a href="/PropertyOwner/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                      Declaration.
                      </a>
                    </>
                    :
                    <>
                      The Service Professional has agreed to this&nbsp;  
                      <a href="/Commonpage/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                      Declaration.
                      </a>
                    </>
                  }
                  </>
                }
                
                </div>
                </tr>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  
}

export default SP_Otherview_Overview;
