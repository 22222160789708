/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'; 
import { useSelector, useDispatch } from "react-redux";
import { company_auth } from '../_actions/company_actions'; 

export default function (SpecificComponent, option, adminRoute = null) {
    function AuthenticationCheck(props) {

        let company = useSelector(state => state.company);
        const dispatch = useDispatch();

        useEffect(() => {
            //To know my current status, send Auth request 
            dispatch(company_auth()).then(response => {
                //Not Loggined in Status 
                if (!response.payload.isAuth) {
                    if (option) {
                        props.history.push('/serviceprofessional/Login')
                    }
                    //Loggined in Status 
                } else {
                    //supposed to be Admin page, but not admin person wants to go inside
                    if (adminRoute && !response.payload.isAdmin) {
                        props.history.push('/ServiceProfessional/Home')
                    }
                    //Logged in Status, but Try to go into log in page 
                    else {
                        if (option === false) {
                            props.history.push('/ServiceProfessional/Home')
                        }
                    }
                }
            })

        }, [])

        return (
            <SpecificComponent {...props} company={company} />
        )
    }
    return AuthenticationCheck
}