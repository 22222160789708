import React from "react";
// import ArticleandBlogs from "./Article&Blogs/ArticleandBlogs";
import Banner from "./Banner/Banner";
import CaseStudy from "./Case Study/CaseStudy";
import Categories from "./Categories/Categories";
// import CompaniesListed from "./Companies Listed/CompaniesListed";
// import ContactUs from "./ContactUs/ContactUs";
import FeaturedProperties from "./FeaturedProperties/FeaturedProperties";
import Howwework from "./Howwework/Howwework";
import Neighborhood from "./Neighbourhood/Neighborhood";
// import NewProperties from "./NewAddedProperties/NewProperties";
import PropertyOptions from "./PropertyOptions/PropertyOptions";
import Video from "./Video/Video";
import Achievements from "../About/Achievements/Achievements";
import ServiceProfessionals from "./ServiceProfessionals/ServiceProfessionals";
import Reviews from "../Testimonials And Reviews/Reviews/Reviews";
import { useLayoutEffect } from "react";

export default function Home() {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    })

  return (
    <div className="home-container">
      {/* Banner and options to search for sell and rent property */}
      <Banner />

    {/* Videos */}

      <Video
        VideoFor="POSP"
        // width="90%"
        // height="400px"
        // containerwidth="100%"
      />
      {/* COMPANIES LISTED */}
      {/* <CompaniesListed /> */}
      {/* How We Work */}
      <Howwework />
      {/* Achievements */}
      <Achievements />
      {/* Buy, Sell and Rent Property */}
      <PropertyOptions />

      {/* Featured Properties */}
      <FeaturedProperties />

      {/* Contact Us Now */}
      {/* <ContactUs /> */}

      {/* Service Professionals */}
      <ServiceProfessionals />

      {/* Find the Neighborhood */}
      <Neighborhood />
      {/* Case Study */}
      <CaseStudy />

      {/* Categories */}
      <Categories />

      {/* Reviews */}
      <Reviews />
      {/* Articles and Blogs */}
      {/* <ArticleandBlogs /> */}
    </div>
  );
}
