import React, { useLayoutEffect, useState } from "react";
import EditProfile from "./EditProfile/EditProfile";
import "./EditProfileandOverview.css";
import ProfileOverview from "./ProfileOverview/ProfileOverview";

export default function EditProfileandOverview(props) {
  const [editclicked, seteditclicked] = useState(!props.Profile_Details_Filled);

  const handleedit = (e) => {
    seteditclicked(!editclicked);
  };

  useLayoutEffect(() => {
    window.scroll({
      top: 500,
      left: 0,
      behavior: 'smooth'
    });
  })

  return (
    <div className="edit-profile-and-overview-container">
      <div className="edit-profile-and-overview">
        <button
          style={{
            visibility: editclicked ? "hidden" : "visible",
          }}
          onClick={handleedit}
          className="editt-and-goback-btn"
        >
          Edit Profile
        </button>

        {editclicked ? <EditProfile /> : <ProfileOverview />}
      </div>
    </div>
  );
}
