import React from "react";
import "./ImageUpload.css";

export default function ViewImg(props) {
  return (
    <div
      style={{
        visibility: props.visibility,
      }}
      className="view-img-container"
    >
      <div
        style={{
          transform: props.transform,
        }}
        className="view-img"
      >
        <span onClick={props.handlecloseimg} className="material-icons">
          cancel
        </span>
        <img src={props.img} alt="view-img" />
      </div>
    </div>
  );
}
