export const Location = {
  States: ["Delhi", "Gujarat", "Karnataka", "Maharashtra", "National Capital Region (NCR)", "Tamil Nadu", "Telangana", "West Bengal"],
  Cities: [
    {
      State: "Karnataka",
      Cities: ["Bangalore"],
    },
    {
      State: "Gujarat",
      Cities: ["Ahmedabad"],
    },
    {
      State: "Tamil Nadu",
      Cities: ["Chennai"],
    },
    {
      State: "Delhi",
      Cities: ["Delhi"],
    },
    {
      State: "National Capital Region (NCR)",
      Cities: ["Faridabad", "Ghaziabad", "Greater Noida", "Gurgaon", "Meerut", "Noida", "Panipat", "Sonipat"],
    },
    {
      State: "Telangana",
      Cities: ["Hyderabad"],
    },
    {
      State: "West Bengal",
      Cities: ["Kolkata"],
    },
    {
      State: "Maharashtra",
      Cities: ["Mumbai", "Pune"],
    },
  ],
}