import React, { useEffect, useLayoutEffect, useState } from "react";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import "./RenewedPropertyDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { productDetailsbyID } from "../../../../../_actions/property_actions";
import LoadingIcon from "../../../../utils/LoadingIcon/LoadingIcon";

export default function RenewedPropertyDetails(props) {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [ProductDetails, setProductDetails] = useState([]);
  const [PreviousltyProductDetails, setPreviousltyProductDetails] = useState([]);

  const productId = props.match.params.PostedPropertyId;
  // console.log("productId =",productId);

  useEffect(() => {
    dispatch(productDetailsbyID(productId)).then((response) => {
      // console.log("Response", response);
      setProductDetails(response.payload);
      setPreviousltyProductDetails(response.payload.Changes_Made[0]);
    });
  }, []);

  if (user.userData && user.userData.isAuth) {
    let userDetails = user.userData;
    return (
      <div className="compare-property-container">
        <PageBanner
          title="Renewed Property Requirement Details"
        />
        <div className="compare-property">
        <div className="header">
          <p className="title">
            <b>Renewed Property Requirement Details</b>
          </p>
          <p
            style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
            className="description"
          >
            Here, you can view all the details of the Property Requirement that you Renewed
          </p>
        </div>
          <div
            style={{
              width: screenwidth <= 1200 ? screenwidth - 20 + "px" : null,
            }}
            className="compare"
          >
            <table>

              <tr>
                <td className="heading">CATEGORY</td>
                <td className="heading">PREVIOUSLY SELF – DECLARED / <br /> SELF – APPROVED POST</td>
                <td className="heading">RENEWED INFO</td>
              </tr>
              <p className="title" >User Details</p>
              <tr>
                <td className="heading">User Email ID</td>
                <td>{userDetails.email}</td>
              </tr>
              <tr>
                <td className="heading">User Phone Number</td>
                <td>{userDetails.phonenumber}</td>
              </tr>
              <tr>
                <td className="heading">Property Owner Profile Number</td>
                <td>{userDetails._id}</td>
              </tr>
              <p className="title" >Personal Details</p>
              <tr>
                <td className="heading">Property Owner Name</td>
                <td>{userDetails.name}</td>
              </tr>

              <tr>
                <td className="heading">Alternative Phone Number</td>
                <td>{userDetails.Personal_Details.Alternative_Phone_Number}</td>
              </tr>
              <tr>
                <td className="heading">Alternative Email ID</td>
                <td>{userDetails.Personal_Details.Alternative_Email_Id}</td>
              </tr>
              <tr>
                <td className="heading">WhatsApp Number</td>
                <td>{userDetails.Personal_Details.Personal_WhatsApp_Number}</td>
              </tr>
              <p className="title" >Address</p>
              <tr>
                <td className="heading">
                  House Name / Building Name / Plot Name / Land Name
                </td>
                <td>{PreviousltyProductDetails.Land_Building}</td>
                <td>{ProductDetails.Land_Building}</td>
              </tr>
              <tr>
                <td className="heading">State</td>
                <td>{PreviousltyProductDetails.State}</td>
                <td>{ProductDetails.State}</td>
              </tr>
              <tr>
                <td className="heading">City</td>
                <td>{PreviousltyProductDetails.City}</td>
                <td>{ProductDetails.City}</td>
              </tr>
              <tr>
                <td className="heading">Area Name</td>
                <td>{PreviousltyProductDetails.Area_Name}</td>
                <td>{ProductDetails.Area_Name}</td>
              </tr>
              <tr>
                <td className="heading">Pin Code</td>
                <td>{PreviousltyProductDetails.Pin_Code}</td>
                <td>{ProductDetails.Pin_Code}</td>
              </tr>
              <p className="title" >Service Type Required</p>
              <tr>
                <td className="heading">Service Type</td>
                <td>{PreviousltyProductDetails.Project_Type}</td>
                <td>{ProductDetails.Project_Type}</td>
              </tr>

              <tr>
                <td className="heading">Property Type</td>
                <td>{PreviousltyProductDetails.BuildingType} - {PreviousltyProductDetails.Area_Type}</td>
                <td>{ProductDetails.BuildingType} - {ProductDetails.Area_Type}</td>
              </tr>
              <tr>
                <td className="heading">Property Status</td>
                <td>{PreviousltyProductDetails.Status_Of_Project}</td>
                <td>{ProductDetails.Status_Of_Project}</td>
              </tr>
              <tr>
                <td className="heading">Service Requirement</td>
                <td>{PreviousltyProductDetails.Service_Type_Required}</td>
                <td>{ProductDetails.Service_Type_Required}</td>
              </tr>
              <p className="title" >Time Frame</p>
              <tr>
                <td className="heading">
                  How soon would you like to get the work started?
                </td>
                <td>{PreviousltyProductDetails.Beginning_The_Project}</td>
                <td>{ProductDetails.Beginning_The_Project}</td>
              </tr>
              <p className="title" >Property Information</p>
              <tr>
                <td className="heading">Property Requirement Number</td>
                <td>{ProductDetails._id}</td>
              </tr>
              <tr>
                <td className="heading">Property Dimension Type</td>
                <td>{PreviousltyProductDetails.Dimension_Type}</td>
                <td>{ProductDetails.Dimension_Type}</td>
              </tr>
              <tr>
                <td className="heading">Property Area</td>
                <td>{PreviousltyProductDetails.Project_Area}sq. ft.</td>
                <td>{ProductDetails.Project_Area}sq. ft.</td>
              </tr>
              <tr>
                <td className="heading">Property Condition</td>
                <td>{PreviousltyProductDetails.Property_Condition}</td>
                <td>{ProductDetails.Property_Condition}</td>
              </tr>
              <p className="title" >Budget</p>
              <tr>
                <td className="heading">
                  What is your approximate budget for the scope of work
                  involved?
                </td>
                <td>{PreviousltyProductDetails.Budget}</td>
                <td>{ProductDetails.Budget}</td>
              </tr>
              <p className="title" >Property Requirements</p>
              <tr>
                <td className="heading">
                  Please describe your property requirements completely and as
                  clearly as possible
                </td>
                <td>{PreviousltyProductDetails.description}</td>
                <td>{ProductDetails.description}</td>
              </tr>
            </table>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}
