import React, { useEffect } from "react";
import { table } from "./Data";
import "./PlansInvoice.css";
import logo from "../../NavBar/DesktopNav/Logo.png";
import PleaseNoteDisclaimer from "../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";

const PlansInvoice = ({ InvoiceDetails }) => {

  useEffect(() => {
    // console.log(InvoiceDetails)
  }, [InvoiceDetails])

  return (
    <div id="plans-invoice-container" className="invoice-container">
      <img src={logo} alt="logo" />
      <p className="upper">Thank you for placing your order with us!</p>
      <p className="upper">
        We received your order and have completed it successfully. Your
        NebourHood Invoice Payment ID is <b>{InvoiceDetails.InvoicePaymentId}</b>
      </p>
      <br />
      <p className="upper">
        The details of the NebourHood Subscription Plan that you purchased from
        NebourHood are as follows:{" "}
      </p>
      <table id="tablee">
        <tr className="table-titles">
          {table.titles.map((title, index) => {
            return (
              <th key={index} colSpan={title.colspan} rowSpan={title.rowspan}>
                {title.Name}
              </th>
            );
          })}
        </tr>
        <tr className="table-sub-titles">
          {table.subtitles.map((subtitle, index) => {
            return <td key={index}>{subtitle}</td>;
          })}
        </tr>

        <tr className="transaction-data">
          <td>{1}</td>
          <td>{InvoiceDetails.InvoicePaymentId}</td>
          <td>{InvoiceDetails.PurchaseOrderId}</td>
          <td>{InvoiceDetails.DateandTimeOfPayment}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.PlanName}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.PlanValidity}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.ExclusiveofGST}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.DiscountedExclusiveOfGST}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.GstAmount}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.InsclusiveOfGST}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.NqaReplacement}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.NonPrimeSavings}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.PrimeSavings}</td>
          <td>{InvoiceDetails.SubscribedPlanDetails.TotalSavings}</td>
          {/* <td>{InvoiceDetails.SubscribedPlanDetails.PlanExipres}</td> */}
          <td>{InvoiceDetails.SubscribedPlanDetails.PlanStatus}</td>
          <td>{InvoiceDetails.PaymentStatus}</td>
          <td>{InvoiceDetails.PaymentMode}</td>
          {/* <td>{InvoiceDetails.SubscribedPlanDetails.AmountPerMonth}</td> */}
        </tr>
      </table>
      <br />
      <div style={{ marginLeft: '30px' }}>
        {InvoiceDetails.SubscribedPlanDetails.PlanName === "Baby Bear" || InvoiceDetails.SubscribedPlanDetails.PlanName === "Baby Tiger" ? 
          <div>
            <p style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'green'}}>You could save an additional Rs. 1500 on this order </span> & unlock exclusive benefits and rewards by being a NebourHood Prime S.P. Member. Click here to Join Now!
            </p>
          <br />
          </div>
        : null}
        {InvoiceDetails.SubscribedPlanDetails.PlanName === "Baby Bear Prime" || InvoiceDetails.SubscribedPlanDetails.PlanName === "Baby Tiger Prime" ? 
          <div>
          <p style={{ fontWeight: 'bold' }}>
            Thank you for being a valued NebourHood Prime S.P. Member! If you haven’t already, you can click here to Renew your Prime Membership Now!
          </p>
          <br />
        </div>
        : null}
        <div>
            <p style={{ fontWeight: 'bold' }}>
              Plan Status:
            </p>
          </div>
          <div>
          <p>•	Active: The subscribed plan is active. </p>
          <p>•	Expired: The subscribed plan has expired. </p>
          <p>•	Pending Activation: The next subscribed plan would automatically activate after the current active plan expires.</p>
          </div>
      </div>
          <br />
      <br />
          <PleaseNoteDisclaimer />
      <br />
      <br />
      <p>For more details about your purchase, please visit <span style={{ color: '#1DA1F2' }} >nebourhood.com</span> and check for your order under the <span style={{ color: '#1DA1F2' }}>Transaction Details</span> section of your profile.</p>
      <br />
      <p>We’re glad that you found what you were looking for. It is our goal that you are always happy with what you bought from us. We look forward to seeing you again.</p>
      <br />
      <p>Thank you,</p>
      <p>Team NebourHood.</p>
      <br />
    </div>
  );
};

export default PlansInvoice;
