import React from "react";
import "./CartPageTable.css";

function CartPageTable(props) {
  // console.log("Lead Left ", props.Leadleft);
  return (
    <div className="cart-page-table-container">
      <table>
        <tr>
          <th rowSpan={2}>SL NO.</th>
          <th colSpan={2}>Property Owner</th>
          <th colSpan={2}>Property Requirement</th>
          <th rowSpan={2}>State</th>
          <th rowSpan={2}>City</th>
          <th rowSpan={2}>Category</th>
          <th rowSpan={2}>Quantity</th>
          <th rowSpan={2} style={{ color:'red'}}>Original Cost Per Lead (Rs.)</th>
          <th rowSpan={2}>Discounted Cost Per Lead (Rs.)</th>
          <th rowSpan={2}>Remove Product</th>
          <th rowSpan={2}>Lead Status</th>
        </tr>

        <tr>
          <td>Profile Number</td>
          <td>Name</td>
          <td>Property Number</td>
          <td className="long-text">Property Name</td>
        </tr>
        {props.products && props.products.map((product, index) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td className="long-text"><a style={{ color: '#1F80E0' }} href={`/OtherViewProfile/PropertyOwner/${product.writer}`}>View</a></td>
              <td>{product.Name_Of_property_Owner}</td>
              <td className="long-text"><a style={{ color: '#1F80E0' }} href={`/ServiceProfessional/PropertyRequirementsDetails/${product._id}`}>View</a></td>
              <td className="long-text">{product.Land_Building}</td>
              <td>{product.State}</td>
              <td>{product.City}</td>
              <td>
                {product.Project_Type} - {product.BuildingType}
              </td>
              <td>{product.quantity}</td>
              <td style={{color:'red'}}>{props.Subscribed && props.Leadleft > 0 ? 0 : <s>524</s>}</td>
              <td>{props.Subscribed && props.Leadleft > 0 ? 0 : 
                <>
                {props.Membership ? 324 : 399}
                </>
              }</td>
              <td>
                <a onClick={()=>props.removeItem(product._id)} className="delete" 
                style={{ 
                  borderRadius: "5px",
                  fontWeight: '500',
                  letterSpacing: '1px' 
                }}>
                  Delete
                </a>
              </td>
              <td>
              {props.currentPackage == "Startup Tiger" || props.currentPackage === "Elite Bear" 
              || props.currentPackage === "Classic Bear" || props.currentPackage === "Baby Bear" 
              || props.currentPackage === "Baby Bear" 
              || props.currentPackage === "Weby Bear" || props.currentPackage === "Weby Tiger" 
              || props.currentPackage === "Startup Bear" || props.currentPackage === "Startup Tiger" 
              || props.currentPackage === "Baby Bear Prime" || props.currentPackage === "Startup Tiger Prime" 
              || props.currentPackage === "Elite Tiger Prime" || props.currentPackage === "Classic Tiger Prime" 
              || props.currentPackage === "Baby Tiger Prime" ?
                <>
                  {(props.currentPackage === "Startup Tiger" || props.currentPackage === "Elite Bear" || props.currentPackage === "Classic Bear" || props.currentPackage === "Baby Bear" || props.currentPackage === "Weby Tiger" || props.currentPackage === "Startup Tiger" || props.currentPackage === "Startup Tiger Prime" || props.currentPackage === "Elite Tiger Prime" || props.currentPackage === "Classic Tiger Prime" || props.currentPackage === "Baby Tiger Prime") ? 
                  <>
                  {product.sold === 3 ? <p style={{color:'green'}}>In Stock</p> : <p style={{color:'red'}}>Out of Stock</p>}
                  </>
                  : null}
                  {(props.currentPackage === "Startup Bear" || props.currentPackage === "Elite Bear" || props.currentPackage === "CLassic Bear" || props.currentPackage === "Baby Bear" || props.currentPackage === "Weby Bear" || props.currentPackage === "Startup Bear" || props.currentPackage === "Startup Bear Prime" || props.currentPackage === "Elite Bear Prime" || props.currentPackage === "Classic Bear Prime" || props.currentPackage === "Baby Bear Prime" ) ? 
                  <>
                  {product.sold > 0 ? <p style={{color:'green'}}>In Stock</p> : <p style={{color:'red'}}>Out of Stock</p>}
                  </>
                  : null}
                </>
                : 
                <>
                   {product.sold > 0 ? <p style={{color:'green'}}>In Stock</p> : <p style={{color:'red'}}>Out of Stock</p>}
                </>
                }
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default CartPageTable;
