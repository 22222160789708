import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Footer.css";
// import {FaGooglePlay} from "react-icons/fa"
// import {RiAppStoreFill} from "react-icons/ri"

export default function Footer() {
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div style={{width:screenwidth<610 ? screenwidth-50 : null}} className="logoandabout">
          <span>
            <img src="https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Only_Logo.jpg" alt="logo" />
            &nbsp;&nbsp;
            <h1>NebourHood</h1>
          </span>
          <p style={{width:screenwidth<610 ? screenwidth-50 : null}} className="description">
            Properties That Feel Good !
          </p>
          <div className="social-icons">
            <a href="https://www.facebook.com/nebour.hood.23/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/nebour.hood.23/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/nebourhood/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            {/* <a href="/" target="_blank" >
              <i className="fab fa-twitter"></i>
            </a> */}
            <a href="https://www.youtube.com/channel/UCx3Hmog2IXPg-MnkD7PmFHQ/featured" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div style={{width:screenwidth<610 ? screenwidth-50 : null}} id="explore-links" className="links">
          <h1>Explore</h1>
          <div className="lists">
            <ul>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/AboutUs">About Us</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/AboutUs">About Us</a>
                  :
                  <a href="/AboutUs">About Us</a>
                  }
                </>
              }
              </li>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/ContactUs">Contact Us</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/ContactUs">Contact Us</a>
                  :
                  <a href="/ContactUs">Contact Us</a>
                  }
                </>
              }
              </li>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/Testimonials">Testimonials</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/Testimonials">Testimonials</a>
                  :
                  <a href="/Testimonials">Testimonials</a>
                  }
                </>
              }
              </li>
              <li>
                {/* <a href="/">Privacy Policy</a> */}
                {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/PrivacyPolicy">Privacy Policy</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/PrivacyPolicy">Privacy Policy</a>
                  :
                  <a href="/PrivacyPolicy">Privacy Policy</a>
                  }
                </>
              }
              </li>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/Communityguidelines">Community Guidelines</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/Communityguidelines">Community Guidelines</a>
                  :
                  <a href="/Communityguidelines">Community Guidelines</a>
                  }
                </>
              }
              </li>
            </ul>
            <ul>
              <li>
                {/* <a href="/">Terms & Conditions</a> */}
                {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/terms&conditions">Terms & Conditions</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/terms&conditions">Terms & Conditions</a>
                  :
                  <a href="/terms&conditions">Terms & Conditions</a>
                  }
                </>
              }
              </li>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/PORules">Property Owner Rules</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/PORules">Property Owner Rules</a>
                  :
                  <a href="/PORules">Property Owner Rules</a>
                  }
                </>
              }
              </li>
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/SPRules">Service Professional Rules</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/SPRules">Service Professional Rules</a>
                  :
                  <a href="/SPRules">Service Professional Rules</a>
                  }
                </>
              }
              </li>
              {/* <li>
                <a href="/">COMMUNITY GUIDELINES</a>
              </li> */}
              <li>
              {company.companyData && company.companyData.isAuth ? 
                <a href="/ServiceProfessional/RefundReplacement&Cancellation">Replacement, Refund & Cancellation Policies</a>
                :
                <>
                  {user.userData && user.userData.isAuth ? 
                  <a href="/PropertyOwner/RefundReplacement&Cancellation">Replacement, Refund & Cancellation Policies</a>
                  :
                  <a href="/RefundReplacement&Cancellation">Replacement, Refund & Cancellation Policies</a>
                  }
                </>
              }
              </li>
              {/* <li>
                <a href="/">New PROPERTIES</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div style={{width:screenwidth<610 ? screenwidth-50 : null}} id="contact-links" className="links">
          <h1>Contact</h1>
          < div className="lists">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt">&nbsp;&nbsp;</i>
                <a href="/">
                BTM Layout, Bangalore, India
                </a>
              </li>
              {/* <li>
                <i className="fas fa-phone-alt">&nbsp;&nbsp;</i>
                <a href="tel:+000 123 456 789">+000 123 456 789</a>
              </li> */}
              <li>
                <i className="far fa-envelope">&nbsp;&nbsp;</i>
                <a href="mailto:hello@fido.com">hello@nebourhood.com</a>
              </li>
              <li>
                <i className="far fa-clock">&nbsp;&nbsp;</i>
                <a id="time" href="/" aria-disabled>
                  9 AM - 5 PM (Mon-Fri)
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="newsletter">
          <h1>Download the App</h1>
<div className="app-icons">
  {/* <a className="app-icn" href="/comingsoon" ><FaGooglePlay/></a>
  <a className="app-icn" href="/comingsoon" ><RiAppStoreFill/></a> */}
</div>
          {/* <div className="input"> */}
            {/* <input style={{width:screenwidth<610 ? screenwidth-50 : null}} type="email" placeholder="Email Address" />
            <i style={{left:screenwidth<610 ? screenwidth-115 : null}} className="far fa-envelope"></i> */}
          {/* </div> */}
          {/* <p style={{width:screenwidth<610 ? screenwidth-50 : null}}>
            Sign up for our latest news and articles. We won’t give you spam
            emails.
          </p> */}
        </div>
      </div>
    </div>
  );
}
