const Plans = [
  {
    Name: "PROPERTY REQUIREMENT POSTING",
    Price: "₹ 499 + G.S.T. per posting",
    Features: [
      "The first Property Requirement (P.R.) posting is free",
      "All P.R. postings after the first P.R. posting would be chargeable",
      "Contact unlimited Service Professionals",
      "Gain access to Analytics",
      "Contact additional unlimited Service Professionals using Analytics",
    ],
    Route: "/PropertyOwner/postpropertyrequirements",
  },
  {
    Name: "PROPERTY REQUIREMENT RENEWAL",
    Price: "₹ 499 + G.S.T. per renewal",
    Features: [
      "Modify specific details of your existing Property Requirements (P.R.)",
      "Change the validity of your existing P.R.",
      "Contact unlimited Service Professionals",
      "Gain access to Analytics",
      "Contact additional unlimited Service Professionals using Analytics",
    ],
    Route: "/PropertyOwner/TransactionPage",
  },
];

module.exports.Plans = Plans;
