import React, { useEffect, useLayoutEffect, useState } from "react";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import { propertydetails } from "./Data";
import "./ProjectDetails.css";
// import Button from "../../../utils/Button/Button";
// import { suggestions } from "./Data";
// import { featured } from "../Projects/Data";
// import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import { useDispatch, useSelector } from "react-redux";
import { projectDetailsByID } from "../../../../_actions/project_actions";
import Carousel from "../../Profiles/Service_Professional/Profile/Views/EditPreviouslyUploadedProjectDetailedPage/Views/EditProject/Carousel/Carousel";
import ProjectImagesCarousel from "./ProjectImagesCarousel/ProjectImagesCarousel";
import maleProfile from "../../../asserts/ProfileImages/maleProfile.jpg";

export default function ProjectDetails(props) {

  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  // const propertyid = props.id;
  const dispatch = useDispatch();
  const projectId = props.match.params.ProjectID;

  const [ProjectDetails, setProjectDetails] = useState([]);
  const [ProjectImages, setProjectImages] = useState([]);
  const [ProjectAddress, setProjectAddress] = useState([]);
  const [ServiceProfessionalName, setServiceProfessionalName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [ServiceProfessionalID, setServiceProfessionalID] = useState();
  const [ProfessionalDetails, setProfessionalDetails] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState(maleProfile);

  const currentproperty = propertydetails[0];

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [dotelement, setdotelement] = useState("dots");

  const [name, setname] = useState(null);
  const [phone, setphone] = useState(null);
  const [description, setdescription] = useState(null);
  const [formfilled, setformfilled] = useState(false);
  const [submitclicked, setsubmitsclicked] = useState(false);
  const [requestsubmittedbackend, setrequestsubmittedbackend] = useState(false);
  const requestdetails = {
    Name: name,
    Phone: phone,
    Description: description,
  };
  const [projectcarousel, setprojectcarousel] = useState(false);

  useEffect(() => {
    dispatch(projectDetailsByID(projectId)).then((response) => {
      // console.log("Response", response);
      setProjectDetails(response.payload.result.ProjectDetails[0]);
      setProjectImages(response.payload.result.ProjectDetails[0].Images);
      setProjectAddress(response.payload.result.ProjectDetails[0].Address);
      setServiceProfessionalName(response.payload.result.CompanyDetails.name);
      setCompanyName(response.payload.result.CompanyDetails.Company_Details.Company_Name);
      setServiceProfessionalID(response.payload.result.CompanyDetails._id);
      setProfessionalDetails(response.payload.result.CompanyDetails.Professional_Details.Professional_Profession);
      setCompanyLogo(response.payload.result.CompanyDetails.Personal_Details.Profile_Pic == "" ? maleProfile : response.payload.result.CompanyDetails.Personal_Details.Profile_Pic);
      setprojectcarousel(true);
      // console.log("Company Name ", response.payload.result.CompanyDetails.Company_Details.Company_Name);
    });
  }, []);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
    {
      /* For Others */
    }

    // setTimeout(() => {
    //   if (screenwidth < 1290) {
    //     setdotelement("bottom-dots");
    //   } else {
    //     setdotelement("dots");
    //   }
    //   const dots = document.getElementById(`${dotelement}`).childNodes;
    //   dots[0].style.opacity = 1;
    //   dots[0].style.transform = "scale(1.3)";
    //   console.log(dots[0]);
    //   for (let i = 0; i < dots.length; i++) {
    //     dots[i].addEventListener("click", (e) => {
    //       for (let i = 0; i < dots.length; i++) {
    //         dots[i].style.opacity = 0.5;
    //         dots[i].style.transform = "scale(1)";
    //       }
    //       e.currentTarget.style.opacity = 1;
    //       e.currentTarget.style.transform = "scale(1.3)";
    //       if (screenwidth < 1290 && screenwidth > 1080) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-350 * i}px)`;
    //       }
    //       if (screenwidth < 1080 && screenwidth > 800) {
    //         console.log(screenwidth);
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 110) * i}px)`;
    //       }
    //       if (screenwidth < 800) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-(screenwidth - 35) * i}px)`;
    //       }
    //       if (screenwidth > 1290) {
    //         document.getElementById(
    //           "properties"
    //         ).style.transform = `translateX(${-450 * i}px)`;
    //       }
    //     });
    //   }
    // }, 10);
  }, [screenwidth, dotelement]);

  const handlename = (e) => {
    setname(e.target.value);
  };
  const handlephone = (e) => {
    setphone(e.target.value);
  };
  const handledescription = (e) => {
    setdescription(e.target.value);
  };
  useEffect(() => {
    if (name && phone && description) {
      setformfilled(true);
    } else {
      setformfilled(false);
    }
  }, [name, phone, description]);

  const handlesubmitform = (e) => {
    e.preventDefault();
    if (formfilled) {
      // console.log(requestdetails);
      setsubmitsclicked(true);
      setTimeout(() => {
        setsubmitsclicked(false);
        setrequestsubmittedbackend(true);
      }, 2000);
    } else {
      setsubmitsclicked(false);
    }
  };

  return (
    <div className="project-details-container">
      <PageBanner
        title="Project Details"
      />
      <Carousel Auto={true} imgwidth="50%" widthtomove={50} Images={ProjectImages} />
      <div className="project-details">
        <div className="Details">
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="property"
          >
            <div className="about">
              <div className="tagsandprice">
                <div className="tags">
                  <p className="featured">{ProjectDetails.Project_Type}</p>
                  <p className="rent">
                    {ProjectDetails.Project_Type_Subcategory_1}
                  </p>
                </div>
                <div className="price-container">
                  <p className="price">
                    Uploaded on: {ProjectDetails.Uploaded_Date}
                  </p>
                </div>
              </div>
              <p className="address">
                {ProjectAddress.House_Building_Plot_Land},{" "}
                {ProjectAddress.State}, {ProjectAddress.City},{" "}
                {ProjectAddress.Areaname}
              </p>
              <div className="dtl">
                <h2 className="title">Project Name: </h2>
                <p className="ans">{ProjectDetails.Project_Name}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Service Professional Name:</h2>
                <p className="ans">
                  {ServiceProfessionalName}
                </p>
              </div>
              <div className="dtl">
                <h2 className="title">Company Name:</h2>
                <p className="ans">{CompanyName}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Project No. :</h2>
                <p className="ans">{ProjectDetails._id}</p>
              </div>
              <div className="dtl">
                <h2 className="title">Property Type:</h2>
                <p className="ans">
                  {ProjectDetails.Project_Type_Subcategory_2}
                </p>
              </div>

              <div className="otherdetailsandrating">
                <div className="other-details">
                  <p>
                    <b>Project Service Requirement:</b> <br />
                    {ProjectDetails.Service_Requirement}
                  </p>
                  <br />
                  <p>
                    <b>Project Year of Completion:</b> <br />
                    {ProjectDetails.Project_Completion}
                  </p>
                  <br />
                  <p>
                    <b>No. of Images. :</b> <br />
                    {ProjectImages.length}
                  </p>
                </div>
                {/* <div className="rating">
                  <p>Verified</p>
                </div> */}
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>User Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Service Professional Profile Number :</p>
                  <p className="input">{ServiceProfessionalID}</p>
                </div>
              </div>
            </div>
            <br />
            <div className="description-container">
              <h2>Personal Details</h2>
              <div className="description">
                <div className="detail">
                  <p className="title">Service Professional Name :</p>
                  <p className="input">
                    &nbsp;&nbsp;
                    {ServiceProfessionalName}
                  </p>
                </div>
              </div>
            </div>
            <br />

            <div className="address-container">
              <h2>Address</h2>
              <p className="title">
                Details of the property where the work must be completed :
              </p>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">
                      House Name / Building Name / Plot Name / Land Name
                      (Property Name):
                    </li>
                    <li>{ProjectAddress.House_Building_Plot_Land}</li>
                    <li className="head">State:</li>
                    <li>{ProjectAddress.State}</li>
                    <li className="head">City:</li>
                    <li>{ProjectAddress.City}</li>
                    <li className="head">Area Name:</li>
                    <li>{ProjectAddress.Areaname}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Professional Details</h2>
              <p className="title">Profession: </p>
              <div className="features">
                <p className="item">{ProfessionalDetails}</p>
              </div>
              <br />
            </div>
            <br />
            <div className="address-container">
              <h2>Company Details</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Company Name:</li>
                    <li>{CompanyName}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="address-container">
              <h2>Project Information</h2>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">Project Number:</li>
                    <li className="head-content">{ProjectDetails._id}</li>
                    <li className="head">Project Name:</li>
                    <li className="head-content">{ProjectDetails.Project_Name}</li>
                    <li className="head">Project Client Name:</li>
                    <li className="head-content">{ProjectDetails.Project_Client_Name}</li>
                    <li className="head">Project Year of Completion:</li>
                    <li className="head-content">{ProjectDetails.Project_Completion}</li>
                    <li className="head">Project Value in Indian Rupee:</li>
                    <li className="head-content">{ProjectDetails.Project_Value}</li>
                    <li className="head">
                      Project Page link to Company Website / Company YouTube
                      Channel URL:
                    </li>
                    <li>
                    <a href={ProjectDetails.Project_Page_Link} target="_blank" >
                    {ProjectDetails.Project_Page_Link}
                    </a>
                    </li>
                    <li className="head">Project Description:</li>
                    <li className="head-content">{ProjectDetails.Project_Description}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <div className="features-container">
              <h2>Project Service, Type & Style</h2>
              <p className="title">Project Service Requirement: </p>
              <div className="features">
                <p className="item">{ProjectDetails.Service_Requirement}</p>
              </div>
              <br />
              <p className="title">Project Type & Style: </p>
              <div className="features">
                {/* {currentproperty.Features.map((feature, index) => {
                  return (
                    <p key={index} className="item">
                      {feature}
                    </p>
                  );
                })} */}
                <p className="item">{ProjectDetails.Project_Type}</p>
                <p className="item">
                  {ProjectDetails.Project_Type_Subcategory_1}
                </p>
                <p className="item">
                  {ProjectDetails.Project_Type_Subcategory_2}
                </p>
                <p className="item">
                  {ProjectDetails.Project_Architecture_Style}
                </p>
              </div>
            </div>
            <br />
            {/* <div className="address-container">
              <h2>Address</h2>
              <p className="title">
                Details of the property where the work must be completed :
              </p>

              <div className="addresss">
                <div>
                  <ul className="address-items">
                    <li className="head">
                      House Name / Building Name / Plot Name / Land Name
                      (Property Name):
                    </li>
                    <li>Land_Building</li>
                    <li className="head">State:</li>
                    <li>State</li>
                    <li className="head">City:</li>
                    <li>City</li>
                    <li className="head">Area Name:</li>
                    <li>Area_Name</li>
                    <li className="head">Pin Code:</li>
                    <li>Pin_Code</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <br />
            {projectcarousel ? (
              <div className="address-container">
                <h2>Images</h2>
                <ProjectImagesCarousel
                  ProjectDetails={ProjectDetails}
                  categories={[1, 2, 3, 4]}
                  Images={ProjectImages}
                />
              </div>
            ) : null}
              
              <br />
            <div className="address-container">
              <h2>Declaration Agreed</h2>
              <div className="addresss">
                <div>
                {company.companyData && company.companyData.isAuth ? 
                <>
                The Service Professional has agreed to this&nbsp;  
                  <a href="/ServiceProfessional/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                  Declaration.
                  </a>
                </>
                :
                  <>
                  {

                    user.userData && user.userData.isAuth ? 
                    <>
                      The Service Professional has agreed to this&nbsp;  
                      <a href="/PropertyOwner/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                      Declaration.
                      </a>
                    </>
                    :
                    <>
                      The Service Professional has agreed to this&nbsp;  
                      <a href="/Commonpage/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                      Declaration.
                      </a>
                    </>
                  }
                  </>
                }
                
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: screenwidth < 850 ? screenwidth - 30 + "px" : null,
            }}
            className="contact-or-share"
          >
            <div className="shareanddownload">
              <button id="edit-project" className="add-to-cart-btn" >
                Share Project
              </button>
            </div>
            <br />
            <div className="contact">
              <div className="dealer-details">
                <img src={CompanyLogo} alt={currentproperty.Dealer.Name} />
                <div className="dealer-name">
                  <h3>Uploaded By</h3>
                  {company.companyData && company.companyData.isAuth ? 
                    <a href={`/ServiceProfessional/Profile/OtherView/${ProjectDetails.writer}`}>
                      {ServiceProfessionalName}
                    </a>
                    :
                    <>
                      {user.userData && user.userData.isAuth ? 
                      <a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${ProjectDetails.writer}`}>
                        {ServiceProfessionalName}
                      </a>
                        :
                      <a href={`/AllServiceProfessionalPage/Profile/OtherView/${ProjectDetails.writer}`}>
                        {ServiceProfessionalName}
                      </a>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            {/* For Others */}
            {/* <div className="suggestions-container">
              <h2>Featured Properties</h2>
              <div className="suggestions">
                {suggestions.map((suggestion, index) => {
                  return (
                    <div key={index} className="suggestion">
                      <img src={suggestion.Image} alt={suggestion.Title} />
                      <div className="detail">
                        <h4>{suggestion.Title}</h4>
                        <p className="address">
                          <i class="fas fa-map-marker-alt"></i>&nbsp;
                          {suggestion.Address}
                        </p>
                        <div
                          style={{
                            width:
                              screenwidth < 1080
                                ? screenwidth - 280 + "px"
                                : null,
                          }}
                          className="for-and-price"
                        >
                          <p className="for">Project_Type</p>
                          <p className="for">Project_Type</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
            <br />
            <div className="tagcloud">
              <h2>Tags Cloud</h2>
              <div className="tags">
                <ul>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Apartment</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Apartment</a>
                        :
                          <a href="/Projects">Apartment</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Penthouse</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Penthouse</a>
                        :
                          <a href="/Projects">Penthouse</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Stand Alone Building</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Stand Alone Building</a>
                        :
                          <a href="/Projects">Stand Alone Building</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Independent Home</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Independent Home</a>
                        :
                          <a href="/Projects">Independent Home</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Villa</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Villa</a>
                        :
                          <a href="/Projects">Villa</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Bungalow</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Bungalow</a>
                        :
                          <a href="/Projects">Bungalow</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Retail</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Retail</a>
                        :
                          <a href="/Projects">Retail</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Hospitality</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Hospitality</a>
                        :
                          <a href="/Projects">Hospitality</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Healthcare</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Healthcare</a>
                        :
                          <a href="/Projects">Healthcare</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Office – Corporate and Tech</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Office – Corporate and Tech</a>
                        :
                          <a href="/Projects">Office – Corporate and Tech</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Institutional</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Institutional</a>
                        :
                          <a href="/Projects">Institutional</a>
                        }
                      </>
                    }
                  </li>
                  <li>
                  {company.companyData && company.companyData.isAuth ? 
                      <a href="/ServiceProfessional/Projects">Industrial</a>
                    : 
                      <>
                        {user.userData && user.userData.isAuth ? 
                          <a href="/PropertyOwner/Projects">Industrial</a>
                        :
                          <a href="/Projects">Industrial</a>
                        }
                      </>
                    }
                  </li>
                </ul>
              </div>
            </div>
            <br />
            {/* For Others */}

            {/* <div className="topproperties-container">
              <div className="titleanddots">
                <h2>Top Properties</h2>
                <div
                  style={{ display: screenwidth < 1290 ? "none" : "block" }}
                  id="dots"
                  className="dots"
                >
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                  <i className="fas fa-circle"></i>
                </div>
              </div>
              <div className="properties-container">
                <div id="properties" className="properties">
                  {featured.map((property, index) => {
                    return (
                      <div
                        style={{
                          width:
                            screenwidth < 1080 && screenwidth > 800
                              ? screenwidth - 150 + "px"
                              : screenwidth < 800
                              ? screenwidth - 70 + "px"
                              : null,
                        }}
                        id="property"
                        key={index}
                        className="property"
                      >
                        <div
                          style={{
                            background: `linear-gradient(to top,
                            rgb(37, 37, 37),
                            transparent 100%), url(${property.Image})`,
                            backgroundSize: "cover",

                            width:
                              screenwidth < 1080 && screenwidth > 800
                                ? screenwidth - 150 + "px"
                                : screenwidth < 800
                                ? screenwidth - 75 + "px"
                                : null,
                          }}
                          className="details"
                        >
                          <p className="tag">FEATURED</p>

                          <div>
                            <p className="address">{property.Address}</p>
                            <h2>{property.Title}</h2>
                            <p className="price">{property.Price}</p>
                            <div className="other-details">
                              <i className="fas fa-bed"></i>&nbsp;
                              {property.Bedrooms} Bedrooms&nbsp;&nbsp;&nbsp;
                              <i className="fas fa-bath"></i>&nbsp;
                              {property.Baths} Baths&nbsp;&nbsp;&nbsp;
                              <i className="fas fa-car"></i>&nbsp;
                              {property.FreeParking ? "Free Parking" : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: screenwidth < 1290 ? "flex" : "none" }}
                id="bottom-dots"
                className="bottom-dots"
              >
                <i id="first" className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
                <i className="fas fa-circle"></i>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
