import React from "react";
import "./Button.css";
import { withRouter } from 'react-router-dom';

function Button(props) {
  const handlemouseenter = (e) => {
  (e.currentTarget.style.backgroundColor = "#1dc295");
  };
  const handlemouseleave = (e) => {
   (e.currentTarget.style.backgroundColor = props.background);
  };
  return (
    <div className="button-container">
      <a
        onMouseEnter={handlemouseenter}
        onMouseLeave={handlemouseleave}
        style={{  width: props.width, backgroundColor: props.background }}
        // href={`${props.directto}`}
        onClick={() => props.history.push(props.directto)}
      >
        {props.name}
      </a>
    </div>
  );
}

export default withRouter(Button)