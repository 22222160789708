import React, { useLayoutEffect, useState } from "react";
import "./Ceo.css";
import { about } from "./Data";

export default function Ceo() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  return (
    <div className="ceo-container">
      <div
        style={{ width: screenwidth <= 820 ? screenwidth - 40 + "px" : null }}
        className="about"
      >
        <div className="left-side">
          <div className="images">
            {about[0].Images.map((img, index) => {
              return (
                <img
                  key={index}
                  style={{
                    width:
                      screenwidth <= 820 ? (screenwidth - 50) / 2 + "px" : null,
                  }}
                  src={img}
                  alt="img"
                />
              );
            })}
          </div>
        </div>
        <div
          style={{ width: screenwidth <= 820 ? screenwidth - 40 + "px" : null }}
          className="right-side"
        >
          <p className="title">
            <b>{about[0].Title}</b>
          </p>
          <p className="description">
            {about[0].Description.substring(0, 90)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(90, 205)}
            <br />
            &nbsp;&nbsp;&nbsp;{about[0].Description.substring(205, 315)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(315, 640)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(640, 1101)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(1101, 2063)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(2063, 2881)}
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{about[0].Description.substring(2881, 3075)}
            <br />
          </p>
          {/* <div className="ceo-quote">
            <p className="quote">
              Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin,
              lorem quis bibendum auctor, nisi elit consequat ipsum, nec
              sagittis. Proin gravida nibh vel velit auctor aliquet.
            </p>
            <div className="ceo">
              <p className="founder">
                <b>Founder & CEO -</b>
              </p>
              <p className="name">T Makeng</p>
            </div>
          </div> */}
        </div>
      </div>
      <div
        style={{
          width: screenwidth <= 820 ? screenwidth - 40 + "px" : null,
 
        }}
        className="about"
        id="second-abt"
      >
        <div className="left-side">
          <div className="images">
            {about[0].Images.map((img, index) => {
              return (
                <img
                  key={index}
                  style={{
                    width:
                      screenwidth <= 820 ? (screenwidth - 50) / 2 + "px" : null,
                  }}
                  src={img}
                  alt="img"
                />
              );
            })}
          </div>
        </div>
        <div
          style={{ width: screenwidth <= 820 ? screenwidth - 40 + "px" : null }}
          className="right-side"
        >
         
          <p className="description">
            {" "}
            {about[0].Description.substring(3075, 3125)}
              <br/>
              <br />              
              &nbsp;&nbsp;&nbsp;&nbsp;•	&nbsp;{about[0].Description.substring(3126, 3147)}
              <br/>
              <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o &nbsp;	{about[0].Description.substring(3148, 3410)}<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o &nbsp;	{about[0].Description.substring(3410, 3610)}
              <br/>
              <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;•	&nbsp;{about[0].Description.substring(3610, 3638)}
            <br/>
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o &nbsp;{about[0].Description.substring(3638, 3765)}
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o &nbsp;{about[0].Description.substring(3765, 3981)}<br/>
            <br />
          </p>
          {/* <div className="ceo-quote">
            <p className="quote">
              Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin,
              lorem quis bibendum auctor, nisi elit consequat ipsum, nec
              sagittis. Proin gravida nibh vel velit auctor aliquet.
            </p>
            <div className="ceo">
              <p className="founder">
                <b>Founder & CEO -</b>
              </p>
              <p className="name">T Makeng</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
