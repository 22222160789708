import React, { useLayoutEffect, useState } from "react";
import "./ImageUpload.css";
import pic from "./pic.jpg";
import DeletePopup from "./DeletePopup";
import DefaultImg from "./DefaultImg.svg";
import ViewImg from "./ViewImg";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../../../utils/LoadingIcon/LoadingIcon";

export default function ImageUpload() {

  const company = useSelector(state => state.company);
  
  const [coverviewclicked, setcoverviewclicked] = useState(false);
  const [deleteclicked, setdeleteclicked] = useState(false);
  const [coverimg, setcoverimg] = useState(DefaultImg);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      // console.log(window.innerWidth);
    });
  }, [screenwidth]);

  const handleviewcover = (e) => {
    setcoverviewclicked(!coverviewclicked);
    var widthonclose = "1400px";
    if (screenwidth < 1420) {
      widthonclose = "1200px";
    }
    if (screenwidth < 1220) {
      widthonclose = "1000px";
    }
    if (screenwidth < 1020) {
      widthonclose = "800px";
    }
    if (screenwidth < 820) {
      widthonclose = "600px";
    }
    if (screenwidth < 620) {
      widthonclose = "100%";
    }

    const clickedel = e.currentTarget.innerText;
    if (clickedel === "open_in_full") {
      document.querySelector("body").style.overflow = "hidden";
      e.currentTarget.parentElement.parentElement.style.position = "fixed";
      e.currentTarget.parentElement.parentElement.style.top = 0;
      e.currentTarget.parentElement.parentElement.style.width = "100%";
      e.currentTarget.parentElement.parentElement.style.height = "100%";
      e.currentTarget.parentElement.parentElement.style.zIndex = 99999;
    } else {
      document.querySelector("body").style.overflow = "scroll";
      e.currentTarget.parentElement.parentElement.style.position = "relative";
      e.currentTarget.parentElement.parentElement.style.top = 0;
      e.currentTarget.parentElement.parentElement.style.width = widthonclose;
      e.currentTarget.parentElement.parentElement.style.height = "500px";
      e.currentTarget.parentElement.parentElement.style.zIndex = 1;
    }
  };

  const handleupdatecover = (e) => {
    // console.log( document.getElementById("cover-img").src = "");
    setcoverimg(pic);
  };

  const handledeletecover = (e) => {
    setdeleteclicked(!deleteclicked);
  };

  const handleyes = (e) => {
    // console.log(e.currentTarget);
    setcoverimg(DefaultImg);
    setdeleteclicked(!deleteclicked);
  };

  const handleno = (e) => {
    // console.log(e.currentTarget);
    setdeleteclicked(!deleteclicked);
  };

  const [viewimgclicked, setviewimgclicked] = useState(false);
  const [imgsrc, setimgsrc] = useState("");

  const handleview = (e) => {
    setviewimgclicked(!viewimgclicked);
    setimgsrc(e.currentTarget.parentElement.parentElement.childNodes[0].src);
    // console.log(e.currentTarget.parentElement.parentElement.childNodes[0].src);
  };

  const handlecloseimg = (e) => {
    setviewimgclicked(!viewimgclicked);
  };

  const handlechangeimg = (e) => {
    // console.log(
    //   (e.currentTarget.parentElement.parentElement.parentElement.childNodes[0].src =
    //     pic)
    // );
  };

  if(company.companyData && company.companyData.isAuth) {

    let companyDetails = company.companyData;

  return (
    <div className="profile-image-upload-container">
      <DeletePopup
        handleyes={handleyes}
        handleno={handleno}
        transform={deleteclicked ? "scale(1)" : "scale(0)"}
        visibility={deleteclicked ? "visible" : "hidden"}
      />
      <ViewImg
        visibility={viewimgclicked ? "visible" : "hidden"}
        transform={viewimgclicked ? "scale(1)" : "scale(0)"}
        handlecloseimg={handlecloseimg}
        img={imgsrc}
      />
      <div className="cover-image-container">
        <img className="cover-img" id="cover-img" src={companyDetails.Company_Details.Company_Cover_Picture}></img>
        <div className="cover-image-options">
          <span onClick={handleviewcover} className="material-icons">
            {coverviewclicked ? "close_fullscreen" : "open_in_full"}
          </span>
          <div className="update-image">
            {" "}
            <label htmlFor="update-profile-image">
              <span className="material-icons">photo_camera</span>
            </label>
            <input
              type="file"
              id="update-profile-image"
              onChange={handlechangeimg}
              hidden
            />
          </div>
          <span onClick={handledeletecover} className="material-icons">
            delete_outline
          </span>
        </div>
      </div>
      <div className="logo-and-profile-img-container">
        <div className="logo-and-profile-img">
          <div className="logo-container">
            <img src={companyDetails.Personal_Details.Profile_Pic} />
            <div className="logo-and-profile-img-options">
              <span onClick={handleview} className="material-icons">
                open_in_full
              </span>
              <div className="update-image">
                {" "}
                <label htmlFor="update-logo-image">
                  <span className="material-icons">photo_camera</span>
                </label>
                <input
                  type="file"
                  id="update-logo-image"
                  onChange={handlechangeimg}
                  hidden
                />
              </div>
              <span onClick={handledeletecover} className="material-icons">
                delete_outline
              </span>
            </div>
          </div>
          <div className="profile-img-container">
            <img src={companyDetails.Company_Details.Company_Logo} />
            <div className="logo-and-profile-img-options">
              <span onClick={handleview} className="material-icons">
                open_in_full
              </span>
              <div className="update-image">
                {" "}
                <label htmlFor="update-cover-image">
                  <span className="material-icons">photo_camera</span>
                </label>
                <input
                  type="file"
                  id="update-cover-image"
                  onChange={handlechangeimg}
                  hidden
                />
              </div>
              <span onClick={handledeletecover} className="material-icons">
                delete_outline
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

} else {
  return (
    <LoadingIcon />
  )
}
}
