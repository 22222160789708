import React, { useLayoutEffect } from "react";
import Button from "../../../utils/Button/Button";
import PleaseNoteDisclaimer from "../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";
import SuccessPage from "../SuccessPage/SuccessPage";
import "./SPSuccess.css";

const SPSuccess = () => {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  })

  return (
    <div className="sp-success-page-container">
      <SuccessPage PurchaseDetails={"Order Placed Successfully"} Redirect={false} />
      <br />
      <h2>SERVICE PROFESSIONAL ORDER CONFIRMATION & SUCCESS PAGE</h2>
      <br />
      <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
        <li>Thank you for placing your order with us!</li>
        <li>
          We received your order and have completed it successfully. 
        </li>
      </ul>
    
      <br />
      <br />

      <PleaseNoteDisclaimer />

      <Button name="Proceed to Cart Page" directto="/serviceprofessional/CartPage" />
    </div>
  );
};

export default SPSuccess;
