import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "./DesktopNav.css"
import companylogo  from "./Logo.png"

function Logo(props){

  const user = useSelector((state) => state.user);
  const company = useSelector((state) => state.company);


return   <div className="logo-container">

    {/* <div className="logo">
      <img src={companylogo} alt="logo" />
    </div> */}
    {company.companyData && company.companyData.isAuth ? 
          <div className="logo">
            <a onClick={() => { props.history.push('/ServiceProfessional/Home') }}>
              <img src={companylogo} alt="logo" />
            </a>
          </div>
            : 
            <>
            {user.userData && user.userData.isAuth ? 
          <div className="logo">
            <a onClick={() => { props.history.push('/PropertyOwner/Home') }}>
              <img src={companylogo} alt="logo" />
            </a>
          </div> 
            :
          <div className="logo">
            <a onClick={() => { props.history.push('/Home') }}>
              <img src={companylogo} alt="logo" />
            </a>
          </div> 
            
            }
            </>
          }
    
    <div className="vertical-line"></div>
   
    {/* <div className="moto">
      <p>Properties That Feel Good !</p>
    </div> */}
  </div>
}

export default withRouter(Logo);