import React from 'react'
import { useLayoutEffect } from 'react';
import PageBanner from '../../../utils/PageBanner/PageBanner'

function ThankYou() {

    useLayoutEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        })

  return (
    <div>
        {/* <PageBanner 
            title="Thank You!!!"
        /> */}
        <br />
        <div>
            <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: '500', color: '#fe5631' }}>
                <p>Thank you for completing the form.</p>
            </div>
            <br />
            <ul style={{ marginLeft: '50px', marginRight: '50px' }}>
                <li>
                    You can visit your profile and give us some more details by editing the property requirements.
                </li>
                <br />
                <li>
                    You can visit the gallery page to check the previously completed projects and select your likings for the same.
                </li>
                <br />
                <li>
                    You can also visit the service professionals page to view their details and select which one you would like to work with.
                </li>
            </ul>
        </div>
        <br />
    </div>
  )
}

export default ThankYou