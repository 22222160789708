import React from 'react'
import { useLayoutEffect } from 'react'
import PageBanner from '../../../utils/PageBanner/PageBanner'
import "./Rules.css"

const SPRules = () => {

    useLayoutEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        })

    return (
        <div className='rules-container'>
            <PageBanner title=" Service Professional Rules" />
            <br />
            <br />
            <div className="rules">
                <h1>SERVICE PROFESSIONAL PROFILE COMPLETION / EDITING & </h1>
                <h1>PROJECT UPLOADING / EDITING RULES</h1>                
                <br /><br />
                <ul className='main-list'>
                    <li className='heading' style={{ listStyleType: 'none' }}>Information & Image Guidelines:
                    </li>
                    <ul className='sub-list'>
                        <li>
                            Please do not input your contact information (Phone Number, WhatsApp Number, Email ID, Social Media links, etc.) in any other input box, apart from the ones that they have been asked for.
                        </li>
                        <li>
                            The image file format must be JPG / JPEG / PNG / Single Page TIFF.
                        </li>
                        <li>
                            Please upload only:
                            <ul className='sub-list' style={{ marginLeft: '20px' }}>
                                <li>Each image file size can be up to 30 mb.
                                </li>
                                <li>Large photos (1200 pixels wide or more).
                                </li>
                                <li>Good quality, clear & High Definition Images.
                                </li>
                                <li>The appropriate & relevant details and kindly refrain from uploading any personal, private, sensitive, profane and / or obscene information in any form, as it would hamper the reputation and business interests of your profile / projects.
                                </li>
                            </ul>
                        </li>
                        <li>Please do not upload 

                            <ul className='sub-list' style={{ marginLeft: '20px' }}>
                                <li>Low quality, small size & blurry images.</li>
                            </ul>
                        </li>
                    </ul>
                </ul>
                <br />
                <ul className='main-list'>
                    <li className='heading' style={{ listStyleType: 'none' }}>            Please Note:
                    </li>
                    <ul className='sub-list'>
                        <li>            Information entered that does not meet the above-mentioned Information Guidelines would be removed.
                        </li>
                        <li>           
                        NebourHood may suspend and / or terminate the Service Professional Profile / Uploaded Projects at any time without prior notice, if the Service Professional Profile / Uploaded Projects do not meet the above-mentioned Information Guidelines. No further queries and / or complaints shall be entertained in this regard, whatsoever.
                        </li>
                    </ul>
                </ul>


            </div>

        </div>
    )
}

export default SPRules