import React, { useEffect, useLayoutEffect, useState } from "react";
import "./MembershipTable.css";
import { table } from "./Data";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import MembershipInvoice from "../MembershipInvoice/MembershipInvoice";
import MembershipDisclaimer from "../MembershipDescription/MembershipDisclaimer";


function MembershipTable() {
  const company = useSelector((state) => state.company);

  const bodywidth = document.querySelector("body").scrollWidth;
  const [totalSavings, settotalSavings] = useState(0);

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [invoicedetail, setinvoicedetail] = useState({
    InvoicePaymentId: "",
    PurchaseOrderId: "",
    DateandTimeOfPayment: "",
    SubscribedPlanDetails: {
      PlanName: "",
      PlanValidity: "",
      OriginalExclusiveOfGST: "",
      DiscountedExclusiveOfGST: "",
      GstAmount: "",
      InsclusiveOfGST: "",
      TotalSavings: "",
      PlanExpiresOn: "",
      PlanStatus: "",
    },
    PaymentStatus: "",
    PaymentMode: "",
  });


  const setdata = async (e) => {
    const details = e.currentTarget.parentElement.parentElement.childNodes;

    details.forEach((element) => {
      const content = element.innerText;
      setinvoicedetail((prev) => {
        return {
          InvoicePaymentId: element.id === "invoiceid" ? content : prev.InvoicePaymentId,
          PurchaseOrderId: element.id === "purchaseorderid" ? content : prev.PurchaseOrderId,
          DateandTimeOfPayment: element.id === "dateandtime" ? content : prev.DateandTimeOfPayment,

          SubscribedPlanDetails: {
            PlanName: element.id === "planname" ? content : prev.SubscribedPlanDetails.PlanName,
            PlanValidity: element.id === "planvalidity" ? content : prev.SubscribedPlanDetails.PlanValidity,
            OriginalExclusiveOfGST: element.id === "originalexclusiveofgst" ? content : prev.SubscribedPlanDetails.OriginalExclusiveOfGST,
            DiscountedExclusiveOfGST: element.id === "discountexclusiveofgst" ? content : prev.SubscribedPlanDetails.DiscountedExclusiveOfGST,
            GstAmount: element.id === "gstamount" ? content : prev.SubscribedPlanDetails.GstAmount,
            InsclusiveOfGST: element.id === "inclusiveofgst" ? content : prev.SubscribedPlanDetails.InsclusiveOfGST,
            TotalSavings: element.id === "totalsaving" ? content : prev.SubscribedPlanDetails.TotalSavings,
            PlanExpiresOn: element.id === "planexpireson" ? content : prev.SubscribedPlanDetails.PlanExpiresOn,
            PlanStatus: element.id === "planstatus" ? content : prev.SubscribedPlanDetails.PlanStatus,
          },
          PaymentMode: element.id === "paymentmode" ? content : prev.PaymentMode,
          PaymentStatus: element.id === "paymentstatus" ? content : prev.PaymentStatus,
        };
      });
    });
  };

  const handledownloadpdf = (e) => {
    setdata(e).then(() => {
      const invoicecontainer = document.getElementById("plans-invoice-container");
      invoicecontainer.style.display = "block";
      html2canvas(document.getElementById("plans-invoice-container"), {
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);

        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
        invoicecontainer.style.display = "none";
      });
    })


  };

  useEffect(() => {
    let ts = 0;
    company.companyData.CommunityMembership.map((index) => {
      ts = ts + index.totalSavings
    })
    settotalSavings(ts);
  }, [invoicedetail]);


  if (company.companyData && company.companyData.isAuth) {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '25px', fontWeight: 'bold' }}>My Prime Membership</p>
        </div>
        <div
          className="MyPrimeMembers-container"
        >
          <div className="myprimemembers-transaction-table">
            <table id="tablee">
              <tr className="table-titles">
                {table.titles.map((title, index) => {
                  return (
                    <th
                      key={index}
                      colSpan={title.colspan}
                      rowSpan={title.rowspan}
                    >
                      {title.Name}
                    </th>
                  );
                })}
              </tr>
              <tr className="table-sub-titles">
                {table.subtitles.map((subtitle, index) => {
                  return <td key={index}>{subtitle}</td>;
                })}
              </tr>

              {company.companyData.CommunityMembership.map((data, index) => {
                return (
                  <tr className="transaction-data">
                    <td>{index + 1}</td>
                    <td id="invoiceid">{!data.razorpayPaymentId ? '-' : data.razorpayPaymentId}</td>
                    <td id="purchaseorderid">{!data.razorpayOrderId ? '-' : data.razorpayOrderId}</td>
                    <td id="dateandtime">{data.Subscribed_On_Date} - {data.Subscribed_On_Time}</td>
                    <td id="planname">{data.planname}</td>
                    <td id="planvalidity">{!data.validity ? '-' : data.validity +' Days'}</td>
                    <td id="originalexclusiveofgst">{!data.OriginalExclusiveOfGST ? '-' : data.OriginalExclusiveOfGST}</td>
                    <td id="discountexclusiveofgst">{!data.DiscountedExclusiveOfGST ? '-' : data.DiscountedExclusiveOfGST}</td>
                    <td id="gstamount">{data.gstAmount}</td>
                    <td id="inclusiveofgst">{!data.InsclusiveOfGST ? '-' : data.InsclusiveOfGST}</td>
                    <td id="totalsaving">{!data.totalSavings ? '-' : data.totalSavings}</td>
                    {/* <td id="planexpireson">{!data.Plan_Subscribed_On ? '-' : data.Plan_Subscribed_On}</td> */}
                    <td id="planstatus">{!data.planStatus ? '-' : data.planStatus}</td>
                    <td id="paymentstatus">{data.Success ? <p style={{ color: 'green' }}>Completed Successfully</p> : <p style={{ color: 'red' }}>Failed</p> }</td>
                    <td id="paymentmode">Online</td>
                    <td>
                      <p onClick={handledownloadpdf}>
                        <span className="material-icons">download</span>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="invoice">
          {<MembershipInvoice InvoiceDetails={invoicedetail} />}
        </div>
        <MembershipDisclaimer page="MyPrime" amount={totalSavings} /> 
      </>
    );
  } else {
    return <LoadingIcon />;
  }
}
export default MembershipTable;
