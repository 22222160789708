import React, { useLayoutEffect } from 'react';
import "./MembershipFeatures.css"

function MembershipFeatures() {

    useLayoutEffect(() => {
        window.scroll({
          top: 300,
          left: 0,
          behavior: 'smooth'
        });
        })
    
  return (
    <div className="refund-page-container">
      {/* <p className="title">
        <b>REPLACEMENT, REFUND & CANCELLATION POLICIES</b>
      </p> */}
      <div className="container">
        <p className="sub-title">
            All Plans Include:
        </p>
        <div className="feature-box">
            <div className='box1'>
            <ul className="list">
                <li className="feature">
                    Access to All India Community & Events
                </li>
                <li className="feature">
                    Access to State-Wise Community & Events
                </li>
                <li className="feature">
                    Access to City-Wise Community & Events
                </li>
                <li className="feature">
                    Early Access to Fresh Property Requirement Lead Updates
                </li>
                <li className="feature">
                    Exclusive Prime Member-Only Additional Discounts on all Property Requirement Leads & Grizzly Bear, Golden Wolf & Roaring Tiger Subscription Plan Series
                </li>
                <li className="feature">
                    Access to Performance Metrics & Business Growth Analytics
                </li>
                <li className="feature">
                    Exclusive Job Postings & Freelance Opportunities
                </li>
                <li className="feature">
                    Networking Opportunities / Collabs with other Professionals
                </li>
                <li className="feature">
                    Q & A with Experts & Community Members
                </li>
            </ul>
            </div>
            <div className='box2'>
            <ul className="list">
                <li className="feature">
                    Unlock your Prime S.P. Profile on NebourHood & Share it with potential clients
                </li>
                <li className="feature">
                Add unlimited Branches to your Prime S.P. Profile to appear in filtered searches from other cities on NebourHood
                </li>
                <li className="feature">
                Upload & Share unlimited projects on NebourHood with potential clients <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Upload & Share unlimited high-quality images on NebourHood <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Tips to Improve your Business via NebourHood <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Feature with us at NebourHood <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Monthly Contests & Challenges <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Win Exclusive Gifts & Rewards <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
                <li className="feature">
                Much More Features! <span style={{ color: '#FE5631' }}>(Coming Soon!)</span>
                </li>
            </ul>
            </div>
        </div>
      </div>
    </div>
  )
}

export default MembershipFeatures