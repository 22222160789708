import React, { useLayoutEffect, useState } from 'react'
import "./ProfileOverview.css";

function ProfileOverview(props) {

    const [screenwidth, setscreenwidth] = useState();
    useLayoutEffect(() => {
        window.addEventListener("resize", () => {
        setscreenwidth(window.innerWidth);
        });
    });

  return (
    <div className="po-profile-overview-container">
        <div className="profile-overview">
        <div className="profile-detail">
        <fieldset>
            <legend>Personal Details</legend>
            <table className="profile-detail-table">
              <tr>
                <td className="title">Property Owner Name</td>
                <td>{props.UserDetails.Property_Owner_Name}</td>
                <td className="title">Date of Birth</td>
                <td>{props.UserDetails.DOB}</td>
                <td className="title">Gender</td>
                <td>{props.UserDetails.Gender}</td>
                <tr className="titles-and-list">
                  <td className="title">Languages Known</td>
                  <td className="profile-detail-list-container">
                    <ul className="profile-detail-list">
                      {props.UserDetails.Languages_Known
                        .Assamese ? (
                        <li>
                          <i className="fas fa-check"></i>Assamese
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Bengali ? (
                        <li>
                          <i className="fas fa-check"></i>Bengali
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .English ? (
                        <li>
                          <i className="fas fa-check"></i>English
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Gujarati ? (
                        <li>
                          <i className="fas fa-check"></i>Gujarati
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Hindi ? (
                        <li>
                          <i className="fas fa-check"></i>Hindi
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Kannada ? (
                        <li>
                          <i className="fas fa-check"></i>Kannada
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Malayalam ? (
                        <li>
                          <i className="fas fa-check"></i>Malayalam
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Marathi ? (
                        <li>
                          <i className="fas fa-check"></i>Marathi
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Odia ? (
                        <li>
                          <i className="fas fa-check"></i>Odia
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Punjabi ? (
                        <li>
                          <i className="fas fa-check"></i>Punjabi
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Tamil ? (
                        <li>
                          <i className="fas fa-check"></i>Tamil
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Telugu ? (
                        <li>
                          <i className="fas fa-check"></i>Telugu
                        </li>
                      ) : null}
                      {props.UserDetails.Languages_Known
                        .Urdu ? (
                        <li>
                          <i className="fas fa-check"></i>Urdu
                        </li>
                      ) : null}
                    </ul>
                  </td>
                </tr>
              </tr>
            </table>
          </fieldset>
        </div>
        </div>
    </div>
  )
}

export default ProfileOverview