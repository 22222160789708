import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { gallerypageview } from "../../../../../_actions/gallery_action";
import Pagination from "../../../../Pagination/Pagination";
import Filters from "../../../../utils/Filters/Filters";
import LoadingIcon from "../../../../utils/LoadingIcon/LoadingIcon";
import PageBanner from "../../../../utils/PageBanner/PageBanner";
import "./Gallery.css";
import ShowImage from "./ShowImage/ShowImage";

function Gallery() {
  document.querySelector("body").style.overflowY = 'scroll'

  const dispatch = useDispatch();

  const [imgclicked, setimgclicked] = useState(false);
  const [currentimgid, setcurrentimgid] = useState(String);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [GalleryImages, setGalleryImages] = useState([]);
  const [galleryResponse, setgalleryResponse] = useState([]);

  const gallery_img_height = useRef(null);

  const [galleryimageheight, setgalleryimageheight] = useState(0);
  var ShuffledImages = [];
  const [nodata, setnodata] = useState(false);

  document.querySelector("body").style.WebkitOverflow = "hidden";

  const shuffle = async (array) => {
    array.sort(() => {
      return 0.5 - Math.random();
    });
    return array;
  };
  useEffect(() => {
  }, [ShuffledImages]);

  const GetAllGalleryImages = (variables) => {
    dispatch(gallerypageview(variables)).then((response) => {
      // console.log("Project Response: ", response);
      if (response.payload.projects.length > 0) {
        setgalleryResponse(response.payload.projects);
        ShuffledImages = [];
        response.payload.projects.map((index) => {
          index.Imagedetails.map((image) => {
            ShuffledImages.push(image);
          });
        });
        shuffle(ShuffledImages).then((res) => {
          setGalleryImages(res);
          setgalleryimageheight(gallery_img_height.current.height);
        });
        setnodata(false);
      } else {
        setGalleryImages([]);
        setnodata(true);
      }
    });
  };

  useEffect(() => {
    let variables = {
      filter: false,
      ProjectOption: "",
      ProjectServiceRequirement: "",
      ProjectSubType: "",
      ProjectType: "",
      SpacesServedOption: "",
      SpacesServedSubType: "",
    };
    GetAllGalleryImages(variables);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [screenwidth]);

  const handleimg = (e) => {
    setimgclicked(!imgclicked);
    setcurrentimgid(e.currentTarget.id);
  };

  const filterdata = (data) => {
    // console.log(data);
    let variables = {
      filter: true,
      ProjectOption: data.ProjectOption,
      ProjectServiceRequirement: data.ProjectServiceRequirement,
      ProjectSubType: data.ProjectSubType,
      ProjectType: data.ProjectType,
      SpacesServedOption: data.SpacesServedOption,
      SpacesServedSubType: data.SpacesServedSubType,
    };
    GetAllGalleryImages(variables);
  };

  return (
    <div className="gallery-container">
      <PageBanner title="Gallery" />
      <div className="header">
        <p className="title">
          <b>Explore Our Creative Collection of Project Images</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Check Out Amazing Project Pictures Accomplished By Well - Versed
          Professionals
        </p>
      </div>
      <Filters
        // Location={true}
        GalleryFilter={true}
        PSR={true}
        ProjectType={true}
        position="static"
        SpacesServed={true}
        getfiltersdata={filterdata}
      />
      <br />
      {GalleryImages.length > 0 ? (
        <>
          {imgclicked ? (
            <div
              style={{}}
              onClick={(e) => {
                if (e.target.innerText === "close") {
                  setimgclicked(false);
                }
              }}
              className="gallery-slides"
            >
              <ShowImage closemodal={() =>{
                setimgclicked(false)
              }} imgid={currentimgid} galleryimages={GalleryImages} projectID={galleryResponse} />
            </div>
          ) : null}
          <Pagination Collection={GalleryImages}>
            {GalleryImages.map((image, index) => {
              return (
                <div
                  onClick={handleimg}
                  className="img"
                  key={image.Id}
                  id={image.Id}
                >
                  <img
                    src={
                      image.Image
                        ? image.Image
                        : "https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
                    }
                    alt="gallery-img"
                    ref={gallery_img_height}
                  />
                </div>
              );
            })}
          </Pagination>
          <br />
          
        </>
      ) : nodata ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "25px",
            color: "gray",
            marginTop: "25px",
          }}
        >
          No Images Found !
        </p>
      ) : (
        <LoadingIcon />
      )}
      <br />
      
    </div>
  );
}

export default Gallery;
