import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../../utils/LoadingIcon/LoadingIcon";
import PO_EditProfile from "./PO_EditProfile/PO_EditProfile";
import PO_Overview from "./PO_Overview/PO_Overview";
import "./PO_MyProfile.css";
import Cover_Image from "../../Service_Professional/Profile/Views/CoverImage/Cover_Image";
import PO_Cover_Profile_Pic from "./PO_Cover_Profile_Pic/PO_Cover_Profile_Pic";
import PO_Analytics from "../../../Analytics/PropertyOwner/PO_Analytics";
import POTransactionPage from "../../../PropertyOwner/TransactionPage/POTransactionPage";
import PO_UploadedProperties from "./PO_UploadedProperties/PO_UploadedProperties";

function PO_MyProfile(props) {
  const user = useSelector((state) => state.user);
  const [EditProfile, setEditProfile] = useState(true);
  const [overviewclicked, setoverviewclicked] = useState(true);
  const [uploadclicked, setuploadclicked] = useState(false);
  const [transactionclicked, settransactionclicked] = useState(false);
  const [analyticsclicked, setanalyticsclicked] = useState(false);


  const ShowEditProfile = () => {
    // console.log(EditProfile);
    setEditProfile(false);
  };

  const handleprofiletabs = (e) => {
    const clickedel = e.target;
    const profiletabs = e.currentTarget.childNodes;
    profiletabs.forEach((tab) => {
      tab.style.backgroundColor = "rgb(233, 232, 232)";
      tab.style.color = "black";
    });
    clickedel.style.backgroundColor = "#1dc295";
    clickedel.style.color = "white";
    setoverviewclicked(false);
    setuploadclicked(false);
    settransactionclicked(false);
    setanalyticsclicked(false);
    if (clickedel.className === "overview") {
      setoverviewclicked(true);
    }
    if (clickedel.className === "upload") {
      setuploadclicked(true);
    }
    if (clickedel.className === "transaction") {
      settransactionclicked(true);
    }
    if (clickedel.className === "analytics") {
      setanalyticsclicked(true);
    }
  };


  if (props.user.userData && props.user.userData.isAuth) {
    return (
      <div className="po-profile-container">
        <Cover_Image nochange={true}
        Image_Src={"https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/DefaultImages/image-1.jpg"}
        />
        <PO_Cover_Profile_Pic 
          nochange={true}
          userName={user.userData.name}
          userGender={user.userData.Personal_Details.Gender}
        />

      <div
          onClick={handleprofiletabs}
          id="profile-tabs-container"
          className="profile-tabss-container"
        >
          <p className="overview">Overview</p>
          <p className="upload">My Property Requirements</p>
          <p className="transaction">Transaction Details</p>
          <p className="analytics">Analytics</p>
        </div>
      

        {overviewclicked ? 
        <div className="po-profile">
          {EditProfile ? (
            <button className="edit-poo-btn" onClick={ShowEditProfile}>
              Edit Profile
            </button>
          ) : null}
          <br />
          {EditProfile ? (
            <PO_Overview UserDetails={user.userData} />
          ) : (
            <PO_EditProfile UserDetails={user.userData} />
          )}
        </div>
        : null}

        {uploadclicked ? <PO_UploadedProperties /> : null}
        {transactionclicked ? <div style={{ width: '100%' }}> <POTransactionPage /> </div> : null}
        {analyticsclicked ? <PO_Analytics /> : null}
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}

export default PO_MyProfile;
