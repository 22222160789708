import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAllServicePreofessionalAdminDetails, updateSPForReplacement } from '../../../_actions/company_actions';
import "./ReplacementPage.css"
import ReplacementUpdates from './ReplacementUpdates';

function ReplacementPage() {
  const dispatch = useDispatch();
  const [ServiceProfessional, setServiceProfessional] = useState([]);
  const [leadLeftValue, setleadLeftValue] = useState(0);
  const [SubscriptionTimeLeft, setSubscriptionTimeLeft] = useState(0);

  const handleLeadLeftChange = (e) => {
    setleadLeftValue(e.target.value);
  }
  const handleSubscriptionTimeLeft = (e) => {
    setSubscriptionTimeLeft(e.target.value);
  }

  useEffect(() => {
    dispatch(getAllServicePreofessionalAdminDetails()).then((response) => {
      if(response.payload.success) {
        console.log("Response ", response.payload);
        setServiceProfessional(response.payload.ServiceProfessional);
      } else {
        setServiceProfessional([]);
      }
    });
  }, []);

  return (
    <div className='tablecontainer'>
      <table style={{ borderCollapse: 'collapse' }}>
        <tr>
          <th style={{ color: 'red' }}>S.P ID</th>
          <th style={{ color: '#1F80E0' }}>E-mail</th>
          <th style={{ color: 'green' }}>Name</th>
          <th>Lead Left</th>
          <th>Subscribed Time Left</th>
          <th>Leads Purchased</th>
          <th>Replacement Pending</th>
          <th>Prime Membership</th>
          <th>Prime Membership Validity</th>
          <th>Action</th>
        </tr>
        {ServiceProfessional.map((sp, index) => {
          return (
          <tr key={index}>
            <td style={{ color: 'red' }}>
            <a href={`/ServiceProfessional/Profile/OtherView/${sp._id}`} style={{ color: 'red' }} target='_blank'>{sp._id}</a>
            </td>
            <td style={{ color: '#1F80E0' }}>{sp.email}</td>
            <td style={{ color: 'green' }}>{sp.name}</td>
            <td><input className='inputbox' type='number' defaultValue={sp.leadLeft} onInput={handleLeadLeftChange} /></td>
            <td><input className='inputbox' type='number' defaultValue={sp.Subscription_Time_Left} onInput={handleSubscriptionTimeLeft} /></td>
            <td style={{ color: 'green' }}>{sp.history.length}</td>
            <td style={{ color: 'green' }}>{sp.ReplacementLeads.length}</td>
            <td style={{ color: 'green' }}>{sp.Membership ? "Prime" : "-"}</td>
            <td style={{ color: 'green' }}>{sp.Membership ? sp.MembershipValidity : "-"}</td>
            <td>
              <ReplacementUpdates 
                UserId={sp._id}
                leadLeft={leadLeftValue}
                SubscriptionTimeLeft={SubscriptionTimeLeft}
              />
            </td>
          </tr>
          )
        })}
      </table>
    </div>
  )
}

export default ReplacementPage