import React, { useEffect, useState } from "react";
import "./Declaration.css";

export default function Declaration(props) {
  const [agreed, setagreed] = useState(false);

  const handleagreed = (e) => {
    setagreed(e.target.checked);
  };
  useEffect(() => {
props.agreed(agreed)
  },[agreed])

  return (
    <div className="declaration-container">
      <div className="declaration">
        <p>
          <b>Declaration :</b>
        </p>
        <div className="checkbox-container">
          <input onChange={handleagreed} type="checkbox" />
          {props.user == "PO" ? 
          <label>
            I agree to this&nbsp;  
              <a href="/PropertyOwner/Declaration/PropertyOwner" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                Declaration.
              </a>
          </label>
          : null }
          {props.user == "SP" ? 
          <label>
            I agree to this&nbsp; 
              <a href="/ServiceProfessional/Declaration/ServiceProfessional" style={{ color: '#1DA1F2', textDecoration: 'none' }} target="_blank">
                Declaration.
              </a>
          </label>
          : null }
        </div>
      </div>
    </div>
  );
}
