const testimonials = [
  {
    heading: "Platform Registration",
    PropertyImage: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/HowWeWork/image-4.jpg",
    Reviews: [
      "Property Owners can create accounts on NebourHood and post their Property Requirements based on their preferences.",
      "Service professionals (Interior Designers, Architects, Engineers and Contractors) can create accounts on NebourHood and complete their Business profiles with their previous work history.",
    ],
    Author: {
      Name: "Mickle Alvas",
      Image:
        "https://templates.envytheme.com/fido/default/assets/images/testimonial/image-2.jpg",
      Profession: "Director",
    },
  },
  {
    heading: "Property Details",
    PropertyImage: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/HowWeWork/image-1.jpg",
    Reviews: [
      "Property Owners can use NebourHood to post the requirements of their property.",
      "They can answer questions to help categorize their property details and match with the right service professional for the job.",
      "Property Owners can view the Business profiles and previously uploaded Projects of service professionals and can contact them via the same, based on their likes and interests.",
    ],
    Author: {
      Name: "Angila Denia",
      Image:
        "https://templates.envytheme.com/fido/default/assets/images/testimonial/image-3.jpg",
      Profession: "Manager",
    },
  },

  {
    heading: "Daily Updates",
    PropertyImage: "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/HowWeWork/image-2.jpg",
    Reviews: [
      "The Property Requirement postings, Profiles, Business Profiles & Projects can be updated on NebourHood using necessary parameters & relevant guidelines of NebourHood",
      "Only self – declared Property Requirements, Profiles, Business Profiles & Projects can go live on NebourHood.",
    ],
    Author: {
      Name: "Thomas Joseph",
      Image:
        "https://templates.envytheme.com/fido/default/assets/images/testimonial/image-1.jpg",
      Profession: "CEO and Founder",
    },
  },
  {
    heading: "Detailed Estimates",
    PropertyImage:
      "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Home/HowWeWork/image-3.jpg",
    Reviews: [
      "The Service professionals can contact the Property Owner and send them quotations on basis of the work involved. ",
      "The Property Owners can choose from the designs and project estimates that best suit their requirements and can decide as to which firm they would like to handover their property work to.",
    ],
    Author: {
      Name: "Mickle Alvas",
      Image:
        "https://templates.envytheme.com/fido/default/assets/images/testimonial/image-2.jpg",
      Profession: "Director",
    },
  },
];

module.exports.testimonials = testimonials;
