import React from "react";
import { useSelector } from "react-redux";
import "./MobileNav.css";
import { withRouter } from "react-router-dom";
import Subscription from "../DesktopNav/Subscription";

function NavigationLink(props) {
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  document.querySelector("html").addEventListener("click", (e) => {
    if (
      e.target.id !== "open-navbar-icon" &&
      !document.getElementById("mobile-nav-items").contains(e.target)
    ) {
      document.getElementById("open-navbar-icon").innerText = "menu";
      document.getElementById("mobile-nav-items").style.height = 0;
    }
  });

  const removeeffect = () => {
    document.getElementById("open-navbar-icon").innerText = "menu";
    document.getElementById("mobile-nav-items").style.height = 0;
  };

  const handlesubdropdown = (e) => {
    if (e.currentTarget.childNodes.length === 2) {
      const clickeditem = e.currentTarget.childNodes[1];

      const currentsubdropdownheight = e.currentTarget.nextSibling.style.height;
      if (currentsubdropdownheight === "0px") {
        e.currentTarget.nextSibling.style.height = "100%";
        clickeditem.innerText = "remove";
      } else {
        e.currentTarget.nextSibling.style.height = "0px";
        clickeditem.innerText = "add";
      }
    }
  };

  const handleloginoptions = (e) => {
    const clickedel = e.currentTarget.previousSibling.innerText;
    const icon = e.currentTarget;
    const loginoptions = document.querySelectorAll("#login-opt");
    const signupoptions = document.querySelectorAll("#signup-opt");

    if (icon.innerText === "add") {
      icon.innerText = "remove";
      if (clickedel === "Login") {
        loginoptions.forEach((opt) => {
          opt.style.display = "block";
        });
      }else{
        signupoptions.forEach((opt) => {
          opt.style.display = "block";
        });
      }
    } else {
      icon.innerText = "add";
      if (clickedel === "Signup") {
        signupoptions.forEach((opt) => {
          opt.style.display = "none";
        });
      }else{
        loginoptions.forEach((opt) => {
          opt.style.display = "none";
        });
      }
    }
  };

  if (company.companyData && company.companyData.isAuth) {
    return (
      <div className="mobile-nav-items-container">
        <div id="mobile-nav-items" className="mobile-nav-items">
          <ul className="mobile-nav-items-drop-down">
            <li>
              <a href="/ServiceProfessional/Home" className="lnk" onClick={handlesubdropdown}>
                Home
              </a>
            </li>
            <li>
              <a
                href="/ServiceProfessional/Projects"
                className="lnk"
                onClick={handlesubdropdown}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                href="/ServiceProfessional/Gallery"
                className="lnk"
                onClick={handlesubdropdown}
              >
                Gallery
              </a>
            </li>

            <li>
              <a
                href="/ServiceProfessional/AllServiceProfessionalPage"
                className="lnk"
                onClick={handlesubdropdown}
              >
                Service Professionals
              </a>
            </li>
            <li>
              <a
                className="lnk"
                href="/ServiceProfessional/PropertyRequirements"
              >
                Leads
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (user.userData && user.userData.isAuth) {
    return (
      <div className="mobile-nav-items-container">
        <div id="mobile-nav-items" className="mobile-nav-items">
          <ul className="mobile-nav-items-drop-down">
            <li>
              <a
                className="lnk"
                href="/PropertyOwner/Home"
                onClick={handlesubdropdown}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="lnk"
                href="/PropertyOwner/Projects"
                onClick={handlesubdropdown}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                className="lnk"
                href="/PropertyOwner/Gallery"
                onClick={handlesubdropdown}
              >
                Gallery
              </a>
            </li>

            <li>
              <a
                className="lnk"
                href="/PropertyOwner/AllServiceProfessionalPage"
                onClick={handlesubdropdown}
              >
                Service Professionals
              </a>
            </li>
            <li>
              <a className="lnk" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mobile-nav-items-container">
        <div id="mobile-nav-items" className="mobile-nav-items">
          <ul className="mobile-nav-items-drop-down">
          
             <Subscription/>
           
            <li className="login">
              {/* <p className="lnk">Login</p>
              <span onClick={handleloginoptions} className="material-icons">
                add
              </span>
            </li>
            <li id="login-opt">
              <a className="lnk" href="/PropertyOwner/login" onClick={handlesubdropdown}>
                &emsp; Property Owner
              </a>
            </li>
            <li id="login-opt">
              <a className="lnk" href="/serviceprofessional/Login" onClick={handlesubdropdown}>
                &emsp; Service Professional
              </a> */}
            </li>
            <li className="signup">
              <p className="lnk">Signup</p>
              <span onClick={handleloginoptions} className="material-icons">
                add
              </span>
            </li>
            <li id="signup-opt">
              <a className="lnk" href="/PropertyOwner/Register" onClick={handlesubdropdown}>
                &emsp; Property Owner
              </a>
            </li>
            <li id="signup-opt">
              <a className="lnk" href="/serviceprofessional/Register" onClick={handlesubdropdown}>
                &emsp; Service Professional
              </a>
            </li>
            <li>
              <a className="lnk" href="/home" onClick={handlesubdropdown}>
                Home
              </a>
            </li>
            <li onClick={() => props.history.push("Projects")}>
              <a className="lnk" href="/Projects" onClick={handlesubdropdown}>
                Projects
              </a>
            </li>
            <li>
              <a className="lnk" href="/Gallery" onClick={handlesubdropdown}>
                Gallery
              </a>
            </li>
            <li>
              <a
                className="lnk"
                href="/AllServiceProfessionalPage"
                onClick={handlesubdropdown}
              >
                Service Professionals
              </a>
            </li>
            <li>
              <a className="lnk" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(NavigationLink);
