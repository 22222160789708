import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoginSignup from "../DesktopNav/LoginSignup";
import Profile from "../DesktopNav/Profile";
import AddListing from "./AddListing";
import Burger from "./Burger";
import "./MobileNav.css";
import NavigationLink from "./NavigationLink";
import companylogo from "./Logo.png";
import Subscription from "../DesktopNav/Subscription";
import { withRouter } from "react-router-dom";

function MobileNav(props) {
  document.querySelector("html").addEventListener("click", (e) => {
    const contains = document
      .getElementById("login-signup-container-mob")
      .contains(e.target);
    const elementstoremove = document.getElementById(
      "login-signup-container-mob"
    ).childNodes;

    if (!contains) {
        elementstoremove[0].childNodes[1].style.opacity = 0;
        elementstoremove[0].childNodes[1].style.visibility = "hidden";
      
    }
  });

  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const handledot = (e) => {
    const btn = document.getElementById("add-listing").style;

    if (btn.opacity === "0") {
      btn.opacity = 1;
      btn.visibility = "visible";
    } else {
      btn.opacity = 0;
      btn.visibility = "hidden";
    }
  };

  //If logged in then,

  const handleburger = (e) => {
    const currentelement = e.currentTarget.childNodes[0];
    if (currentelement.innerText === "close") {
      currentelement.innerText = "menu";
      document.getElementById("mobile-nav-items").style.height = "0px";
    } else {
      currentelement.innerText = "close";
      document.getElementById("mobile-nav-items").style.height = "263px";
    }
  };

  const handleuser = (e) => {
    const currentelement = e.currentTarget.nextSibling.childNodes[0].style;
    if (currentelement.opacity === "0") {
      currentelement.display = "block";
      setTimeout(() => {
        currentelement.opacity = 1;
        currentelement.visibility = "visible";
      }, 10);
    } else {
      currentelement.opacity = 0;
      currentelement.visibility = "hidden";
      setTimeout(() => {
        currentelement.display = "none";
      }, 500);
    }
  };

  const handleclick = (e) => {
    const currentelement = e.currentTarget.nextSibling.style;
    if (currentelement.opacity === "0") {
      currentelement.opacity = 1;
      currentelement.visibility = "visible";
    } else {
      currentelement.opacity = 0;
      currentelement.visibility = "hidden";
    }
  };

  return (
    <div className="mobile-nav-container">
      <div className="mobile-nav">
        <div className="left-side">
          {company.companyData && company.companyData.isAuth ? (
            <div className="logo">
              <a
                onClick={() => {
                  props.history.push("/ServiceProfessional/Home");
                }}
              >
                <img src={companylogo} alt="logo" />
              </a>
            </div>
          ) : (
            <>
              {user.userData && user.userData.isAuth ? (
                <div className="logo">
                  <a
                    onClick={() => {
                      props.history.push("/PropertyOwner/Home");
                    }}
                  >
                    <img src={companylogo} alt="logo" />
                  </a>
                </div>
              ) : (
                <div className="logo">
                  <a
                    onClick={() => {
                      props.history.push("/Home");
                    }}
                  >
                    <img src={companylogo} alt="logo" />
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        <div className="right-side">
          {/* Profile */}
          {/* If logged in 
          <Profile handleuser={handleuser} />

         
*/}

          {/* If SP 
          <Button name="Upload Project" background="#fe5631" directto="/ServiceProfessional/UploadNewProject" />
          */}
          {/* If PO 
          <Button
            name="Post Property Requirements"
            background="#fe5631"
            directto="PropertyOwner/postpropertyrequirements"
          />
*/}
          {/* If Not Logged In */}

          <div className="account-container">
            {/* <Subscription/>
            <LoginSignup /> */}
            {company.companyData && company.companyData.isAuth ? 
            <div id="login-signup-container-mob" className="login-signup-container" style={{ display: 'none' }}>
              <div className="login-container">
                <p onClick={handleclick}>Log In</p>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="login-signup-drop-down"
                  className="login-signup-drop-down"
                >
                  <li>
                    <a href="/PropertyOwner/Login">Property Owner</a>
                  </li>
                  <li>
                    <a href="/serviceprofessional/Login">
                      Service Professional
                    </a>
                  </li>
                </ul>
              </div>{" "}
            </div>
            : 
            <>
              {user.userData && user.userData.isAuth ?
            <div id="login-signup-container-mob" className="login-signup-container" style={{ display: 'none' }}>
              <div className="login-container">
                <p onClick={handleclick}>Log In</p>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="login-signup-drop-down"
                  className="login-signup-drop-down"
                >
                  <li>
                    <a href="/PropertyOwner/Login">Property Owner</a>
                  </li>
                  <li>
                    <a href="/serviceprofessional/Login">
                      Service Professional
                    </a>
                  </li>
                </ul>
              </div>{" "}
            </div>
               :
            <div id="login-signup-container-mob" className="login-signup-container">
              <div className="login-container">
                <p onClick={handleclick}>Log In</p>
                <ul
                  style={{
                    opacity: 0,
                  }}
                  id="login-signup-drop-down"
                  className="login-signup-drop-down"
                >
                  <li>
                    <a href="/PropertyOwner/Login">Property Owner</a>
                  </li>
                  <li>
                    <a href="/serviceprofessional/Login">
                      Service Professional
                    </a>
                  </li>
                </ul>
              </div>{" "}
            </div>
               }
            </>}
            
            <Profile />
          </div>

          {/* <AddListing
            Button="Upload Project"
            directo="/postpropertyrequirements"
            handledot={handledot}
          /> */}

          {company.companyData && company.companyData.isAuth ? (
            <AddListing
              Login={'ServiceProfessional'}
              CompanyDetails={company.companyData}
              handledot={handledot}
            />
          ) : null}
          {user.userData && user.userData.isAuth ? (
            <AddListing
              Login={'PropertyOwner'}
              handledot={handledot}
            />
          ) : null}

          {/* If Logged in  */}
          <Burger handleburger={handleburger} />
        </div>
      </div>

      <NavigationLink />
    </div>
  );
}

export default withRouter(MobileNav);
