import React from "react";
import LoginSignup from "./LoginSignup";
import Profile from "./Profile";


export default function Account() {
  
  return (
    <div className="account-container">
      <Profile />

      <LoginSignup />

    </div>
  );
}
