import React from "react";
import Account from "./Account";
import "./DesktopNav.css";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import Subscription from "./Subscription";
import Membership from "./Membership";
import LeadLeft from "./LeadLeft";
import MyStatsComponent from "./MyStatsComponent";

export default function DesktopNav() {
  return (
    <div className="desktop-nav-container">
      <div className="desktop-nav">
        <Logo />

        <NavLinks />

        <Subscription />

        <Membership />

        <LeadLeft />

        <MyStatsComponent />
        
        <Account />
      </div>
  
    </div>
  );
}
