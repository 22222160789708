const Plans = [
  // {
  //   Name: "Baby Bear Prime",
  //   FalsePrice: "₹ 700",
  //   Price: "₹ 175 per lead",
  //   Validity: "30 Days",
  //   Features: [
  //     "Select 35 (29 Regular Leads + 6 Extra FREE Leads)",
  //     "1 Lead is shared with 3 Service Professionals",
  //     "Gain access to Profile Performance & Analytics",
  //     "Contact additional unlimited clients using Analytics",
  //     "Featured Profile in relevant cities & categories for 30 days",
  //     "Featured Projects in relevant cities & categories for 30 days",
  //   ],
  // },
  {
    Name: "Startup Tiger Prime",
    FalsePrice: "₹ 1,996",
    Price: "₹ 599 per lead",
    Validity: "15 Days",
    Features: [
      "Select 5 Regular Leads",
      "Can Apply For 10 NQA Double Replacement Leads",
      "Total = 15 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 15 days",
      "Featured Projects in relevant cities & categories for 15 days",
    ],
  },
  {
    Name: "Elite Tiger Prime",
    FalsePrice: "₹ 1,996",
    Price: "₹ 599 per lead",
    Validity: "30 Days",
    Features: [
      "Select 10 Regular Leads",
      "Can Apply For 20 NQA Double Replacement Leads",
      "Total = 30 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 30 days",
      "Featured Projects in relevant cities & categories for 30 days",
    ],
  },
  {
    Name: "Classic Tiger Prime",
    FalsePrice: "₹ 1,996",
    Price: "₹ 599 per lead",
    Validity: "45 Days",
    Features: [
      "Select 15 Regular Leads",
      "Can Apply For 30 NQA Double Replacement Leads",
      "Total = 45 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 45 days",
      "Featured Projects in relevant cities & categories for 45 days",
    ],
  },
  {
    Name: "Baby Tiger Prime",
    FalsePrice: "₹ 1,996",
    Price: "₹ 599 per lead",
    Validity: "60 Days",
    Features: [
      "Select 20 Regular Leads",
      "Can Apply For 40 NQA Double Replacement Leads",
      "Total = 60 Leads",
      "1 Lead is shared with 1 Service Professional",
      "Gain access to Profile Performance & Analytics",
      "Contact additional unlimited clients using Analytics",
      "Featured Profile in relevant cities & categories for 60 days",
      "Featured Projects in relevant cities & categories for 60 days",
    ],
  },
];

module.exports.Plans = Plans;
