import React, { useState } from "react";
import "./MembershipPlanBreakDown.css";
import MembershipPricing from "../../../utils/Razorpay/MembershipPricing/MembershipPricing";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { loginCompanyGoogle } from "../../../../_actions/company_actions";

const MembershipPlanBreakDown = (props) => {
  const plan = props.plan;
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const [showModal, setshowModal] = useState(false);

  const handleLogin = (googleData) => {
    dispatch(loginCompanyGoogle(googleData))
    .then(response => {
      if (response.payload.loginSuccess) {
        window.localStorage.setItem('companyId', response.payload.companyId);
        window.location.reload();
      } else {
        alert('Check out your Account or Password again')
      }
    })
  }

  return (
    <div style={props.styles} className="plan-breakdown-container">
      <div className="plan-breakdown">
        <span onClick={props.handleclose} id="close" className="material-icons">
          cancel
        </span>
        <div className="plan-breakdown-details">
          <div className="plan-title-and-price">
            <p className="title">{plan.Name}</p>
            <p className="pricing">{plan.Price}</p>
          </div>
            <p className="features-title">Features :</p>
            <div className="features-container">
              <div className="features">
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      Do not refresh the page, go back or navigate away after completing the payment.
                    </p>
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      The joining link for the exclusive NebourHood Prime S.P. Member WhatsApp community will appear only on the thank you screen.
                    </p>
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      This link will disappear within 1 minute.
                    </p>
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      The joining link will not be shared again.
                    </p>
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      If you completed the payment but could not join the WhatsApp community, you can WhatsApp us.
                    </p>
                    <p className="feature">
                      <span id="dot" class="material-icons">
                        fiber_manual_record
                      </span>
                      Our team will verify your information and add you to the community if your details are accurate.
                    </p>
              </div>
              <div className="billing-details">
              <div className="fee-and-taxes">
                <h3 style={{ textAlign: 'center' }}>Membership Duration</h3>
                <br />
                <div className="fee">
                  <p className="title">Regular No. of Months:</p>
                  <p className="amount">
                    {
                    plan.Name === "NebourHood Prime S.P. 3 Months" ? 2 
                    :  plan.Name === "NebourHood Prime S.P. 6 Months"? 4 
                    : plan.Name === "NebourHood Prime S.P. 9 Months"? 6 
                    : plan.Name === "NebourHood Prime S.P. 1 Year"? 8 
                    : 0
                    }
                  </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>Extra FREE No. of Months:</p>
                  <p className="amount" style={{color: 'green'}}>
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 1 
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 2 
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 3 
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 4 
                      : 0
                    }
                  </p>
                </div>
                <div className="fee">
                  <p className="title">Total No. of Months:</p>
                  <p className="amount">
                  {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 3 
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 6 
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 9 
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 12 
                      : 0
                    }
                  </p>
                </div>
                <br />
                <h3 style={{ textAlign: 'center' }}>Amount To Be Paid</h3>
                <br />
                <div className="fee">
                  <p className="title" style={{color: 'red'}}>Original Amount (Exclusive of G.S.T.)</p>
                  <p className="amount" style={{color: 'red'}}><del>₹ 
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 2997
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 4995
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 6993
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 8991
                      : 0
                    }
                    </del></p>
                </div>
                <div className="fee">
                  <p className="title">Discounted Amount (Exclusive of G.S.T.)</p>
                  <p className="amount">₹ 
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 999
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 1998
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 2997
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 3996
                      : 0
                    }
                  </p>
                </div>
                <div className="fee">
                  <p className="title">G.S.T. Amount (18%)</p>
                  <p className="amount">₹ 
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 0
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 0
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 0
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 0
                      : 0
                    }
                  </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: '#1F80E0'}}>Discounted Amount (Inclusive of G.S.T.)</p>
                  <p className="amount" style={{color: '#1F80E0'}}>₹ 
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 999
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 1998
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 2997
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 3996
                      : 0
                    }
                  </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>Total Savings</p>
                  <p className="amount" style={{color: 'green'}}>₹ 
                    {
                      plan.Name === "NebourHood Prime S.P. 3 Months" ? 1998
                      :  plan.Name === "NebourHood Prime S.P. 6 Months"? 2997
                      : plan.Name === "NebourHood Prime S.P. 9 Months"? 3996
                      : plan.Name === "NebourHood Prime S.P. 1 Year"? 4995
                      : 0
                    }
                  </p>
                </div>
                <br />
              </div>
              <br />
              {company.companyData && company.companyData.isAuth ?
              <button className="proceed">
                  <MembershipPricing
                    Name={plan.Name}
                    Price={plan.Price}
                  />
                </button>
              :
              <button className="proceed" onClick={()=>setshowModal(true)}>
                  Proceed To Pay
                </button>
              }
            </div>
          </div>
        </div>
      </div>
      {showModal ? 
          <div className='sign-in-required-modall'>
            <div>
              <h1>Kindly login to view the Prime Membership Plan details!</h1>
            </div>
            <div style={{border: '2px solid lightgrey'}}>
              <GoogleLogin
                  clientId="737789981008-3kqljeg4a44qdbbiib27e9d8rtr8m34o.apps.googleusercontent.com"
                  buttonText="Log in with Google"
                  onSuccess={handleLogin}
                  // onFailure={handleLoginFail}
                  cookiePolicy={'single_host_origin'}
                    />
              </div>
            <span className="close-icon" onClick={()=>setshowModal(false)} style={{ backgroundColor: '#fe5631', color: 'white', fontSize: '1.1em', padding: '5px', border: 'none', borderRadius: '5px', letterSpacing: '2px' }} >
              Close
            </span>
          </div>
        : null}
    </div>
  );
};

export default MembershipPlanBreakDown;
