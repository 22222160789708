import React from 'react';
import { useDispatch } from 'react-redux';
import { USER_SERVER } from "../../../../Config"
import { renewPropertyWithPayment, secondPropertyWithPayment, User_PaymentFailure } from '../../../../_actions/user_actions';
import { withRouter } from 'react-router-dom'

const POST_PROPERTY = `${USER_SERVER}/postProperty`;
const RENEWAL_PROPERTY = `${USER_SERVER}/renewProperty`;

function PORazorpay(props) {

  const dispatch = useDispatch();

    async function callfunction() {
      var URL_TO_CALL = "";
      // console.log("callfunction");
      // console.log("Value", props.value);
      if(props.value === "Renew")
      {
        // console.log("Renewal URL Called");
        URL_TO_CALL = RENEWAL_PROPERTY;
      }
      if(props.value === "Post")
      {
        // console.log("POST URL Called");
        // console.log("Property Requirements ", props.DetailsToPost);
          URL_TO_CALL = POST_PROPERTY;
        }

        try {
          // console.log("URL & VALUE", URL_TO_CALL, props.value);
          openRazorpayWindow(URL_TO_CALL, props.value);
        } catch (error) {
          alert("Failed To Load Razor pay")
        }
    }

    async function openRazorpayWindow(URL_TO_CALL, value)
    {
        const ServerData = await fetch(URL_TO_CALL, { method: 'POST' })
            .then(response => response.json())    // one extra step
            .catch(error => console.error(error));
    
            var options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": ServerData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": ServerData.currency,
            "name": "NebourHood",
            "description": "Property Posting",
            "image": "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/NebourHoodHomeLogo.jpg",
            "order_id": ServerData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response){
                // console.log("Success Response ", response);
                if(props.value === "Post") {
                  // console.log("Post Dispatch");
                  var variables = {
                    propertyDetails: props.DetailsToPost,
                    payment: response
                  }
                  dispatch(secondPropertyWithPayment(variables))
                    .then(response => {
                      setTimeout(() => {
                        // console.log("Response for Second Property ", response);
                      props.history.push(`/PropertyOwner/PropertyRequirementsDetails/${response.payload.result.ProductID}`);
                      }, 2000);
                    })
                }
                if(props.value === "Renew") {
                  // console.log("Renew Dispatch");
                  var variables = {
                    propertyDetails: props.DetailsToPost,
                    payment: response
                  }
                  // console.log("Renewal data to save in DB", variables);
                  dispatch(renewPropertyWithPayment(variables))
                    .then((response) => {
                      setTimeout(() => {
                        props.history.push(`/PropertyOwner/PropertyRequirementsDetails/${response.payload.result._id}`);
                        }, 2000);
                        // console.log("Renewal Response ", response);
                    })
                }
            },
            "prefill": {
                "name": "",
                "email": "",
                "contact": ""
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#44A4E6"
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response){
            // console.log("Fail", response);
            dispatch(User_PaymentFailure())
              .then((response) => {
                // console.log("Payment Failure ", response);
              })
    });
        paymentObject.open();
    }

  return (
    <div onClick={callfunction}>Checkout</div>
  )
}

export default withRouter(PORazorpay);