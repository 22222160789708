import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../../../../../utils/Button/Button";
import "./PreviouslyUploadedProjects.css";
import NavigationNumbers from "../../../../../../utils/NavigationNumbers/NavigationNumbers";
import { useDispatch } from "react-redux";
import { SP_PreviouslyUploadedProjects } from "../../../../../../../_actions/project_actions";
import SmallCarousel from "../../../../../../utils/SmallCarousel/SmallCarousel";
import Pagination from "../../../../../../Pagination/Pagination";

function PreviouslyUploadedProjects() {
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [datareceived, setdatareceived] = useState(false);
  const [propertyheight, setpropertyheight] = useState(null);

  const propertyheightref = useRef(null);
  const [Projects, setProjects] = useState([]);

  const getPreviouslyUploadedProjects = (variables) => {
    setdatareceived(false);
    dispatch(SP_PreviouslyUploadedProjects()).then((response) => {
      if (response.payload.success) {
        setdatareceived(true);

        if (response.payload.projects.length > 0) {
          setProjects(response.payload.projects);
          setTimeout(() => {
            setpropertyheight(
              propertyheightref.current.getBoundingClientRect().height
            );
          }, 10);

          // console.log("Product Response", response.payload.projects);
        }
      } else {
        alert("Failed to fetch product datas");
      }
    });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
  }, [screenwidth]);

  const heighttomove =
    Projects.length <= 6 && Projects.length > 3 && screenwidth > 1050
      ? propertyheight * 2 + 20
      : Projects.length <= 3 && screenwidth > 1050
      ? propertyheight
      : Projects.length <= 3 && screenwidth < 1050 && screenwidth > 650
      ? propertyheight * 2 + 40
      : Projects.length >= 6 && Projects.length <= 9
      ? propertyheight * 3 + 60
      : propertyheight * 4 + 80;
  useEffect(() => {
    getPreviouslyUploadedProjects();
  }, []);
  return (
    <div className="previous-projects-container">
      {datareceived && Projects.length > 0 ? (
        <div>
          <div className="properties-featured-properties-container">
            <div className="header">
              <p className="title">
                <b>My Projects</b>
              </p>
              <p
                style={{
                  width: screenwidth <= 650 ? screenwidth - 30 + "px" : null,
                }}
                className="description"
              >
                Here, you can view all the Projects that you have Uploaded or
                Edited
              </p>
            </div>
            <br />
            <Pagination Collection={Projects}>
              {Projects.map((property, index) => {
                return (
                  <div
                    style={{
                      width:
                        screenwidth <= 510 ? screenwidth - 10 + "px" : null,
                    }}
                    id="featured-properties-property"
                    className="featured-properties-propertyy"
                    ref={propertyheightref}
                    key={index}
                  >
                    <div className="upper">
                      {property.Project_Type === "Interior" ? (
                        <p className="tag" id="interior-tag">
                          INTERIOR
                        </p>
                      ) : (
                        <p className="tag" id="construction-tag">
                          CONSTRUCTION
                        </p>
                      )}
                      <p className="price">
                        {property.Project_Type_Subcategory_1}
                      </p>
                      <SmallCarousel
                        Images={property.Images}
                        style={{
                          width:
                            screenwidth <= 510 ? screenwidth - 30 + "px" : null,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width:
                          screenwidth <= 510 ? screenwidth - 20 + "px" : null,
                      }}
                      className="middle"
                    >
                      <div className="address">
                        <p className="left-side">Address :</p>
                        <p
                          style={{
                            color: "#fe5631",
                          }}
                          className="right-side"
                        >
                          {" "}
                          {property.Address.House_Building_Plot_Land},{" "}
                          {property.Address.State}, {property.Address.City},{" "}
                          {property.Address.Areaname}
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Project Name :</p>
                        <p
                          style={{
                            fontSize: "19.5px",
                          }}
                          className="right-side"
                        >
                          {/* {" "}
                          {property.Address.House_Building_Plot_Land},{" "}
                          {property.Address.State}, {property.Address.City},{" "}
                          {property.Address.Areaname} */}
                          {property.Project_Name}
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Service Professional Name :</p>
                        <p className="right-side">
                          {property.WriterDetails.Service_Professional_Name}
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Company Name :</p>
                        <p className="right-side">
                          {property.WriterDetails.Company_Name}
                          {/* Company */}
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Project No. :</p>
                        <p
                          style={{
                            color: "#fe5631",
                          }}
                          className="right-side"
                        >
                          {property._id}
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Property Type :</p>
                        <p className="right-side">
                          {property.Project_Type_Subcategory_2}
                        </p>
                      </div>
                      {/* <h2 className="property-title">
                        {property.Project_Name}
                      </h2> */}
                      {/* <p className="apartmentandsize">
                        {"Apartment"}&nbsp;({property.Project_Area} sq.ft.)
                      </p> */}
                      <div
                        style={{
                          width:
                            screenwidth <= 510 ? screenwidth - 20 + "px" : null,
                        }}
                        className="other-detailss-container"
                      >
                        <p>{property.Service_Requirement}</p>
                        <div className="border"></div>
                        <p>Year of Completion: {property.Project_Completion}</p>
                        <div className="border"></div>
                        <p> No. of Images: {property.Images.length}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: screenwidth <= 510 ? screenwidth + "px" : null,
                      }}
                      className="bottom"
                    >
                      <div className="rating">
                        Uploaded on: {property.Uploaded_Date}
                        {/* <div className="stars">
                        <span className="material-icons">star</span>
                        <span className="material-icons">star</span>
                        <span className="material-icons">star</span>
                        <span className="material-icons">star</span>
                        <span className="material-icons">star</span>
                      </div> */}
                        {/* <div className="rating-name">
                        {" "}
                        {5 > 4 ? "Excellent" : "Average"}
                      </div> */}
                      </div>
                      <div className="knowmore-btn">
                        <Button
                          width="150px"
                          name="KNOW DETAILS"
                          directto={`/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/${property._id}`}
                          background="#201c2d"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Pagination>
          </div>
          <br />
          {/* <NavigationNumbers
            numberofitems={9}
            screenwidthfornav1={1200}
            numberofitems1={6}
            screenwidthfornav2={750}
            numberofitems2={4}
            itemscontainerid="Featured-properties"
            heighttomove={propertyheight * 3 + 60}
          /> */}

          <br />
        </div>
      ) : (
        <p className="no-data">No Projects Uploaded !</p>
      )}
    </div>
  );
}

export default PreviouslyUploadedProjects;
