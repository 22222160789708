import React from 'react'
import "./SignInRequired.css"

const SignInRequired = (props) => {
    return (
        <div className="sign-in-required-modal-container" >
            <div className="sign-in-required-modal">
            </div>
            <div className="sign-in-required-modal-2">
            </div>
            <div className="sign-in-required">
                <h1>{props.title}</h1>
                {props.ShowButton ? 
                <a href={props.directto} className='btn'>{props.ButtonTitle}</a>
                :
                null}
            </div>
        </div>
    )
}

export default SignInRequired