import React, { useLayoutEffect, useState } from "react";
import "./Categories.css";
import { categories } from "./Data";

export default function Categories() {
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      
    });
  }, [screenwidth]);

  
  return (
    <div className="category-container">
      <div className="categories">
        {categories.map((category, index) => {
          return <div key={index} style={{width:screenwidth<=600 ? screenwidth-30 :null}} className="category">
              <img src={category.Image} alt={category.Name} />
              <a href="/Projects" >{category.Name}</a>
          </div>;
        })}
      </div>
    </div>
  );
}
