import React, { useEffect } from "react";
import "./Utils.css";

export default function ParentTable(props) {
  const subheadings = props.subheadings;
  const data = props.data;

  useEffect(() => {
    if (props.productivity) {
    }
  }, []);

  return (
    <div style={{
      
    }} className="parent-table-container">
      <table
      style={{
width : "1000px"
      }}
      >
        <tr>
          {props.mainheading ? (
            <td className="heading" colSpan={subheadings.length}>
              {props.mainheading}{" "}
            </td>
          ) : null}
        </tr>
        <tr>
          {subheadings.map((subheading, index) => {
            return (
              <td className="subheading" key={index}>
                {subheading}
              </td>
            );
          })}
        </tr>
        <tr>
          {data.map((data, index) => {
            return <td key={index}>{data}</td>;
          })}
        </tr>
        <tr
          style={{
            display: props.listanddetails ? "" : "none",
          }}
        >
          {subheadings.map((subheading, index) => {
            if (subheading === "Productivity Percentage (%)") {
              return (
                <td
                  id={subheading}
                  className="view-list-and-detail"
                  onClick={props.handlelistview}
                  key={index}
                >
                  <div class="tooltip">Calculation
                    <span class="tooltiptext">Productivity Percentage = (Number of Leads Converted / Number of Leads Selected) * 100</span>
                  </div>
                </td>
              );
            }else{
              return (
                <td
               
                  id={subheading}
                  className="view-list-and-detail"
                  onClick={props.handlelistview}
                  key={index}
                >
                  View List and Details
                </td>
              );
            }
          })}
        </tr>
      </table>
    </div>
  );
}
