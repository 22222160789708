import React, { useState } from 'react'
import { updateSPForReplacement } from '../../../_actions/company_actions';
import { useDispatch } from 'react-redux';

function ReplacementUpdates(props) {
  const dispatch = useDispatch();
    
  const [pendingStatus, setpendingStatus] = useState(false);

    const updateReplacementDetails = (Id, leadValue, subscriptionTime) => {
        setpendingStatus(true)
        dispatch(updateSPForReplacement(Id, leadValue, subscriptionTime)).then((response) =>{
          if(response.payload.success) {
            console.log("Button Response ", response.payload);
            setpendingStatus(false);
          } else {
            window.alert("Unable to Update For Replacement:")
          }
        })
      }

  return (
    <>
    {pendingStatus ? 
            <td><button className='pendingbutton'>Pending</button></td>
        :
        <button className='replacementbutton' 
            onClick={()=>updateReplacementDetails(
                props.UserId, 
                props.leadLeft, 
                props.SubscriptionTimeLeft)}>
                    Update
        </button>
    }
    </>
  )
}

export default ReplacementUpdates