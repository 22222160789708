import React from "react";
import { withRouter } from "react-router-dom";
import "./Utils.css";

function SocialMediaView(props) {

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2}>
            Sl. No.{" "}
          </td>
          <td className="heading" colSpan={7}>
          {props.Identity === "PO" ? "Property Owner" :  null}
          {props.Identity === "SP" ? "Service Professional" :  null}
          </td>
          {props.Identity === "SP" ? (
            <td className="heading" rowSpan={2}>
              Profession
            </td>
          ) : null}
          {props.Identity === "SP" ? (
            <td className="heading" rowSpan={2}>
              Company Name{" "}
            </td>
          ) : null}
          <td className="heading" rowSpan={2}>
            {props.dataandtimeheading}
          </td>
        </tr>
        <tr>
          <td className="subheading">Profile Number</td>
          <td className="subheading">Name</td>
          <td className="subheading">Email Id</td>
          <td className="subheading">Phone Number</td>
          <td className="subheading">Alternative Email Id</td>
          <td className="subheading">Alternative Phone Number</td>
          <td className="subheading">WhatsApp Number</td>
        </tr>
        {props.LeadsDetails.map((listanddetail, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.Visited_By}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                  View
                </a>
              </td>
              <td>
                <a href={`/OtherViewProfile/PropertyOwner/${listanddetail.Visited_By}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                  {listanddetail.Name}
                </a>
              </td>
              <td>{listanddetail.Email === null ? " - " : listanddetail.Email}</td>
              <td>{listanddetail.PhoneNumber === null ? " - " : listanddetail.PhoneNumber}</td>
              <td>{listanddetail.AlternativeEmail === "" ? " - " : listanddetail.AlternativeEmail}</td>
              <td>{listanddetail.AlternativeNumber === null ? " - " : listanddetail.AlternativeNumber}</td>
              <td>{listanddetail.Whatsapp == null ? " - " : listanddetail.Whatsapp}</td>
              {/* {props.Identity === "SP" ? <td>{listanddetail.Profession}</td> : null}
              {props.Identity === "SP" ? <td>{listanddetail.CompanyName}</td> : null} */}
              <td>{listanddetail.Visited_Date} - {listanddetail.Visited_Time}</td>
            </tr>
          );
        })}{" "}
      </table>
    </div>
  );
}

export default withRouter(SocialMediaView)