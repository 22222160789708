import React, { useEffect } from "react";
import { table } from "./Data";
import "./PropertyRequirementInvoice.css";
import logo from "./Logo.png";
import PleaseNoteDisclaimer from "../../../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";

const PropertyRequirementInvoice = ({ InvoiceDetails, userDetails }) => {

  useEffect(() => {
// console.log(InvoiceDetails )
// console.log("User Details ", userDetails.userData.Personal_Details.Property_Owner_Name)
  },[InvoiceDetails])

  return (
    <div id="plans-invoice-container" className="invoice-container">
      <div>
        <center>
          <img src={logo} alt="logo" />
        </center>
      </div>
      <br />
      <p className="upper">
        Hey <span style={{ color: '#1DA1F2' }}>{userDetails.userData.Personal_Details.Property_Owner_Name}</span>,{" "}
      </p>
      <br />
      <p className="upper">Greetings! Your Self – Declared / Self – Approved {InvoiceDetails.Posted == "Active" ? "Posted" : ""} {InvoiceDetails.Edited == "Active" ? "Edited" : ""} {InvoiceDetails.Renewed == "Active" ? "Renewed" : ""}  Property Requirement Is Now Live on NebourHood! </p>
      <p>Your Property Requirement Number is <span style={{ color: '#1DA1F2' }}>{InvoiceDetails.PropertyNumber}</span></p>
      <br />
      {InvoiceDetails.Edited == "Active" ?
      <>
        <p>Your Property Requirement Editing Details are as follows:</p>
      </>
      :
      <>
        <p>We received your order and have completed it successfully. Your NebourHood Invoice Payment ID is <span style={{ color: '#1DA1F2' }}>{InvoiceDetails.InvoicePaymentId}</span>.</p>
        <br />
        <p>Your Payment Details are as follows:</p>
      </>
      }
      <br />
      <center><p>PROPERTY REQUIREMENT SELF – DECLARED / SELF – APPROVED REQUESTS – FOR PROPERTY OWNERS</p></center>
      <table id="tablee">
        <tr className="table-titles">
          {table.titles.map((title, index) => {
            return (
              <th key={index} colSpan={title.colspan} rowSpan={title.rowspan}>
                {title.Name}
              </th>
            );
          })}
        </tr>
        <tr className="table-sub-titles">
          {table.subtitles.map((subtitle, index) => {
            return <td key={index}>{subtitle}</td>;
          })}
        </tr>

        <tr className="transaction-data">
          <td>{1}</td>
          <td>{InvoiceDetails.InvoicePaymentId}</td>
          <td>{InvoiceDetails.ProfileNumber}</td>
          <td>{InvoiceDetails.Name}</td>
          <td>{InvoiceDetails.PropertyNumber}</td>
          <td>{InvoiceDetails.LandBuilding}</td>
          <td>{InvoiceDetails.DateandTimeofApproval}</td>
          <td>{InvoiceDetails.State}</td>
          <td>{InvoiceDetails.City}</td>
          <td>{InvoiceDetails.DurationSelected}</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.CostPrice }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.FinalAmount }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.GSTAmount }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.TotalAmount }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "Free" : InvoiceDetails.Status }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.PaymentStatus }</td>
          <td>{InvoiceDetails.Edited == "Active" ? "-" : InvoiceDetails.PaymentMode }</td>
          <td>{InvoiceDetails.ReasonForExpire}</td>
        </tr>
      </table>
      <br />
     <PleaseNoteDisclaimer />
      <br />
      <br />
    </div>
  );
};

export default PropertyRequirementInvoice;
