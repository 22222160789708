import axios from "axios";
import { PERFORMANCE_ANALYTICS } from "../Config";
import { USER_GET_ALL_ANALYTICS } from "./types";

export function PO_GetAllAnalyticsData() {
    // console.log("Analytics Get All Analytics Action Data");
    const request = axios.get(`${PERFORMANCE_ANALYTICS}/getPropertyOwnerAnalytics`)
                .then(response => response.data);

    return {
        type: USER_GET_ALL_ANALYTICS,
        payload: request
    }    
}