import React from "react";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../../../../utils/LoadingIcon/LoadingIcon";
// import {
//   personalawards,
//   personalhobbies,
//   professionalawards,
//   professionalskills,
// } from "./Data";
import "./ProfileOverview.css";

function ProfileOverview() {

  const company = useSelector(state => state.company);

  if(company.companyData && company.companyData.isAuth) {
    
    let companyDetails = company.companyData;

  return (
    <div className="profile-overview-container">
      <div className="profile-overview">
        <div className="profile-detail">
          {/* <p className="profile-detail-title">User Details</p> */}
          <h2>User Details</h2>
          <table>
            <tr>
              <td className="title">User Name</td>
              <td>{companyDetails.name}</td>
              <td className="title">User Email Id</td>
              <td>{companyDetails.email}</td>
              <td className="title">User Phone Number</td>
              <td>{companyDetails.Phone_Number}</td>
            </tr>
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Personal Details</p> */}
          <h2>Personal Details</h2>
          <table>
            <tr>
              <td className="title">Service Professional Name</td>
              <td>{companyDetails.Personal_Details.Service_Professional_Name}</td>
              <td className="title">Date of Birth</td>
              <td>{companyDetails.Personal_Details.DOB}</td>
              <td className="title">Gender</td>
              <td>{companyDetails.Personal_Details.Gender}</td>
            </tr>
            {/* Languages */}
            <tr>
              <td className="title" id="fullwidth">
                Languages
              </td>
            </tr>
            <tr>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {languages.map((language, index) => {
                    return <li key={index}>{language}</li>;
                  })} */}
                  { companyDetails.Personal_Details.Languages_Known.Assamese ? <li><i className="fas fa-check"></i>Assamese</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Bengali ? <li><i className="fas fa-check"></i>Bengali</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.English ? <li><i className="fas fa-check"></i>English</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Gujarati ? <li><i className="fas fa-check"></i>Gujarati</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Hindi ? <li><i className="fas fa-check"></i>Hindi</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Kannada ? <li><i className="fas fa-check"></i>Kannada</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Malayalam ? <li><i className="fas fa-check"></i>Malayalam</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Marathi ? <li><i className="fas fa-check"></i>Marathi</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Odia ? <li><i className="fas fa-check"></i>Odia</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Punjabi ? <li><i className="fas fa-check"></i>Punjabi</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Tamil ? <li><i className="fas fa-check"></i>Tamil</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Telugu ? <li><i className="fas fa-check"></i>Telugu</li> : null }
                  { companyDetails.Personal_Details.Languages_Known.Urdu ? <li><i className="fas fa-check"></i>Urdu</li> : null }
                </ul>
              </td>
            </tr>
            {/* Personal Skills and Hobbies */}
            {companyDetails.Personal_Details.Personal_Skills_Hobbies.length > 0 ? 
            <>
              <tr>
                <td className="title" id="fullwidth">
                  Personal Skills and Hobbies
                </td>
              </tr>
              <tr>
                <td id="fullwidth">
                  <ul className="profile-detail-list">
                    {companyDetails.Personal_Details.Personal_Skills_Hobbies.map((hobbie, index) => {
                      return <li key={index}><i className="fas fa-check"></i>{hobbie}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            </> : null}
            {/* Personal Awards & Achievements */}
            {companyDetails.Personal_Details.Personal_Awards_Achievements.length > 0 ? 
            <>
              <tr>
                <td className="title" id="fullwidth">
                  Personal Awards & Achievements
                </td>
              </tr>
              <tr>
                <td id="fullwidth">
                  <ul className="profile-detail-list">
                    {companyDetails.Personal_Details.Personal_Awards_Achievements.map((award, index) => {
                      return <li key={index}><i className="fas fa-check"></i>{award}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            </> : null}
                    
                {companyDetails.Personal_Details.Aadhaar_Card? 
                  <tr>
                    <td className="title">Government Proof Id</td>
                    <td>{companyDetails.Personal_Details.Aadhaar_Card}</td>
                  </tr>
                : null}
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Professional Details</p> */}
          <h2>Professional Details</h2>
          <table>
            <tr>
              <td className="title">Educational Qualification</td>
              <td>{companyDetails.Professional_Details.Educational_Qualification}</td>
              <td className="title">Profession</td>
              <td>{companyDetails.Professional_Details.Professional_Profession}</td>
              <td className="title">Profession Type</td>
              <td>Engineer</td>
            </tr>
            {/* Professional Skills */}
            {companyDetails.Professional_Details.Personal_Skills.length > 0 ? 
            <>
              <tr>
                <td className="title" id="fullwidth">
                  Professional Skills
                </td>
              </tr>
              <tr>
                <td id="fullwidth">
                  <ul className="profile-detail-list">
                    {companyDetails.Professional_Details.Personal_Skills.map((skill, index) => {
                      return <li key={index}><i className="fas fa-check"></i>{skill}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            </> : null}
            {/* Professional Awards and Achievements */}
            {companyDetails.Professional_Details.Personal_Awards_Achievements.length > 0 ? 
            <>
              <tr>
                <td className="title" id="fullwidth">
                  Professional Awards and Achievements
                </td>
              </tr>
              <tr>
                <td id="fullwidth">
                  <ul className="profile-detail-list">
                    {companyDetails.Professional_Details.Personal_Awards_Achievements.map((award, index) => {
                      return <li key={index}><i className="fas fa-check"></i>{award}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            </>: null}

            <tr>
              <td className="title">Overall Experience in years</td>
              <td>{companyDetails.Professional_Details.Overall_Experience}</td>
              <td className="title">Number of Projects Completed</td>
              <td>{companyDetails.Professional_Details.Project_Completed}</td>
            </tr>
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Company Details</p> */}
          <h2>Company Details</h2>
          <table>
            <tr>
              <td className="title">Company Name</td>
              <td>{companyDetails.Company_Details.Company_Name}</td>
              <td className="title">Year of Establishment</td>
              <td>{companyDetails.Company_Details.Year_of_Establishment}</td>
              <td className="title">Number of Employees</td>
              <td>{companyDetails.Company_Details.Number_of_Employees}</td>
            </tr>

            {/*Workdays*/}
            <tr>
              <td className="title" id="fullwidth">
                Workdays
              </td>
            </tr>
            <tr>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {workdays.map((workday, index) => {
                    return <li key={index}>{workday}</li>;
                  })} */}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Monday? <li><i className="fas fa-check"></i>Monday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Tuesday? <li><i className="fas fa-check"></i>Tuesday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Wednesday? <li><i className="fas fa-check"></i>Wednesday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Thursday? <li><i className="fas fa-check"></i>Thursday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Friday? <li><i className="fas fa-check"></i>Friday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Saturday? <li><i className="fas fa-check"></i>Saturday</li> : null}
                  {companyDetails.Company_Details.Workdays_Timings.Days.Sunday? <li><i className="fas fa-check"></i>Sunday</li> : null}
                </ul>
              </td>
            </tr>
            {/* Timings */}
            <tr>
              <td className="title" id="fullwidth">
                Timings
              </td>
              <td id="fullwidth">From {companyDetails.Company_Details.Workdays_Timings.Timing.From} To {companyDetails.Company_Details.Workdays_Timings.Timing.To}</td>
            </tr>
            <tr>
              <td className="title" id="fullwidth">
                About the Company
              </td>
              <td id="fullwidth">{companyDetails.Company_Details.About_the_Company}</td>
            </tr>
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Contact Details</p> */}
          <h2>Contact Details</h2>
          <table>
            {/* Main Head Office Phone Number */}
            <p className="sub-title">Main Head Office Phone Number</p>
            <tr>
              <td className="title">User Phone Number</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Number.User_Phone_Number}</td>
              <td className="title">Phone No.2</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_2}</td>
              <td className="title">Phone No.3</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Number.Phone_Number_3}</td>
              <td className="title">Whatsapp Phone No.</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Number.Company_WhatsApp}</td>
            </tr>
            {/* Main Head Office Phone Number */}
            <p className="sub-title">Main Head Office Phone Number</p>
            <tr>
              <td className="title">User Email ID</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Email.User_Email}</td>
              <td className="title">Email ID 2</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Email.Email_1}</td>
              <td className="title">Email ID 3</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Email.Email_2}</td>
              {companyDetails.Contact_Details.Website_URL? 
              <>
              <td className="title" id="fullwidth">
                Website Url
              </td>
              <td id="fullwidth">{companyDetails.Contact_Details.Website_URL}</td>
              </>
              : null}
            </tr>

            {/* Social Media Links */}
            <p className="sub-title">Social Media Links</p>
            <tr>
              {companyDetails.Contact_Details.Social_Media.Facebook? 
              <>
              <td className="title">Facebook</td>
              <td>{companyDetails.Contact_Details.Social_Media.Facebook}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Instagram? 
              <>
              <td className="title">Instagram</td>
              <td>{companyDetails.Contact_Details.Social_Media.Instagram}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Linkedin? 
              <>
              <td className="title">LinkedIn</td>
              <td>{companyDetails.Contact_Details.Social_Media.Linkedin}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Pinterest? 
              <>
              <td className="title">Pinterest</td>
              <td>{companyDetails.Contact_Details.Social_Media.Pinterest}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Quora? 
              <>
              <td className="title">Quora</td>
              <td>{companyDetails.Contact_Details.Social_Media.Quora}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Reddit? 
              <>
              <td className="title">Reddit</td>
              <td>{companyDetails.Contact_Details.Social_Media.Reddit}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Twitter? 
              <>
              <td className="title">Twitter</td>
              <td>{companyDetails.Contact_Details.Social_Media.Twitter}</td>
              </>
              : null}
              {companyDetails.Contact_Details.Social_Media.Youtube? 
              <>
              <td className="title">Youtube</td>
              <td>{companyDetails.Contact_Details.Social_Media.Youtube}</td>
              </>
              : null}
            </tr>

            {/* Main Head Office Address */}
            <p className="sub-title">Main Head Office Address</p>
            <tr>
              <td className="title" id="fullwidth">
                House name / Building Name
              </td>
              <td id="fullwidth">{companyDetails.Contact_Details.Main_Head_Office_Address.House_Builing_Name}</td>
              <td className="title" id="fullwidth">
                Street Name / Road
              </td>
              <td id="fullwidth">{companyDetails.Contact_Details.Main_Head_Office_Address.Street_Road_Name}</td>
              <td className="title" id="fullwidth">
                Locality Name
              </td>
              <td id="fullwidth">{companyDetails.Contact_Details.Main_Head_Office_Address.Locality}</td>
              <td className="title" id="fullwidth">
                Landmark
              </td>
              <td id="fullwidth">{companyDetails.Contact_Details.Main_Head_Office_Address.Landmark}</td>
              <td className="title">State</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Address.State}</td>
              <td className="title">City</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Address.City}</td>
              <td className="title">Area Name</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Address.Area_Name}</td>
              <td className="title">Pin Code</td>
              <td>{companyDetails.Contact_Details.Main_Head_Office_Address.Pincode}</td>
            </tr>
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Branch Details</p> */}
          <h2>Branch Details</h2>
          <div className="branches-array">
          <table>
            <p className="sub-title">Branch - 1</p>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Phone Number
            </p>
            <tr>
              <td className="title">Phone No. 1</td>
              <td>5325235235</td>
              <td className="title">Phone No. 2</td>
              <td>5325235235</td>
              <td className="title">Whatsapp No.</td>
              <td>35232325</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Email ID
            </p>
            <tr>
              <td className="title">Email ID 1</td>
              <td>branch@gmail.com</td>
              <td className="title">Email ID 2</td>
              <td>branch@gmail.com</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Address
            </p>
            <tr>
              <td className="title" id="fullwidth">
                House Name / Building Name
              </td>
              <td id="fullwidth">189 GP</td>
              <td className="title" id="fullwidth">
                Street Name / Road
              </td>
              <td id="fullwidth">2nd cross street</td>
              <td className="title" id="fullwidth">
                Locality Name
              </td>
              <td id="fullwidth">Opposite Auto Room</td>
              <td className="title" id="fullwidth">
                Landmark
              </td>
              <td id="fullwidth">Near Church</td>
              <td className="title">State</td>
              <td>Karnataka</td>
              <td className="title">City</td>
              <td>Banagalore</td>
              <td className="title">AreaName</td>
              <td>2nd cross street</td>
              <td className="title">Pincode</td>
              <td>24124133</td>
            </tr>
          </table>

          <table>
            <p className="sub-title">Branch - 1</p>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Phone Number
            </p>
            <tr>
              <td className="title">Phone No. 1</td>
              <td>5325235235</td>
              <td className="title">Phone No. 2</td>
              <td>5325235235</td>
              <td className="title">Whatsapp No.</td>
              <td>35232325</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Email ID
            </p>
            <tr>
              <td className="title">Email ID 1</td>
              <td>branch@gmail.com</td>
              <td className="title">Email ID 2</td>
              <td>branch@gmail.com</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Address
            </p>
            <tr>
              <td className="title" id="fullwidth">
                House Name / Building Name
              </td>
              <td id="fullwidth">189 GP</td>
              <td className="title" id="fullwidth">
                Street Name / Road
              </td>
              <td id="fullwidth">2nd cross street</td>
              <td className="title" id="fullwidth">
                Locality Name
              </td>
              <td id="fullwidth">Opposite Auto Room</td>
              <td className="title" id="fullwidth">
                Landmark
              </td>
              <td id="fullwidth">Near Church</td>
              <td className="title">State</td>
              <td>Karnataka</td>
              <td className="title">City</td>
              <td>Banagalore</td>
              <td className="title">AreaName</td>
              <td>2nd cross street</td>
              <td className="title">Pincode</td>
              <td>24124133</td>
            </tr>
          </table>

          <table>
            <p className="sub-title">Branch - 1</p>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Phone Number
            </p>
            <tr>
              <td className="title">Phone No. 1</td>
              <td>5325235235</td>
              <td className="title">Phone No. 2</td>
              <td>5325235235</td>
              <td className="title">Whatsapp No.</td>
              <td>35232325</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Email ID
            </p>
            <tr>
              <td className="title">Email ID 1</td>
              <td>branch@gmail.com</td>
              <td className="title">Email ID 2</td>
              <td>branch@gmail.com</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Address
            </p>
            <tr>
              <td className="title" id="fullwidth">
                House Name / Building Name
              </td>
              <td id="fullwidth">189 GP</td>
              <td className="title" id="fullwidth">
                Street Name / Road
              </td>
              <td id="fullwidth">2nd cross street</td>
              <td className="title" id="fullwidth">
                Locality Name
              </td>
              <td id="fullwidth">Opposite Auto Room</td>
              <td className="title" id="fullwidth">
                Landmark
              </td>
              <td id="fullwidth">Near Church</td>
              <td className="title">State</td>
              <td>Karnataka</td>
              <td className="title">City</td>
              <td>Banagalore</td>
              <td className="title">AreaName</td>
              <td>2nd cross street</td>
              <td className="title">Pincode</td>
              <td>24124133</td>
            </tr>
          </table>

          <table>
            <p className="sub-title">Branch - 1</p>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Phone Number
            </p>
            <tr>
              <td className="title">Phone No. 1</td>
              <td>5325235235</td>
              <td className="title">Phone No. 2</td>
              <td>5325235235</td>
              <td className="title">Whatsapp No.</td>
              <td>35232325</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Email ID
            </p>
            <tr>
              <td className="title">Email ID 1</td>
              <td>branch@gmail.com</td>
              <td className="title">Email ID 2</td>
              <td>branch@gmail.com</td>
            </tr>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Branch Office Address
            </p>
            <tr>
              <td className="title" id="fullwidth">
                House Name / Building Name
              </td>
              <td id="fullwidth">189 GP</td>
              <td className="title" id="fullwidth">
                Street Name / Road
              </td>
              <td id="fullwidth">2nd cross street</td>
              <td className="title" id="fullwidth">
                Locality Name
              </td>
              <td id="fullwidth">Opposite Auto Room</td>
              <td className="title" id="fullwidth">
                Landmark
              </td>
              <td id="fullwidth">Near Church</td>
              <td className="title">State</td>
              <td>Karnataka</td>
              <td className="title">City</td>
              <td>Banagalore</td>
              <td className="title">AreaName</td>
              <td>2nd cross street</td>
              <td className="title">Pincode</td>
              <td>24124133</td>
            </tr>
          </table>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Projects Catered To</p> */}
          <h2>Projects Catered To</h2>
          <table>
            <p className="sub-title">What kind of projects do you undertake?</p>
            <p className="sub-title" style={{ textAlign: "center" }}>
              Interior Design
            </p>
            <tr>
              <td className="title">Residential</td>
              <td>{companyDetails.Project_Catered.Interior_Design.Residential}</td>
              <td className="title">Commercial</td>
              <td>{companyDetails.Project_Catered.Interior_Design.Commercial}</td>
            </tr>

            <p className="sub-title" style={{ textAlign: "center" }}>
              Architectural Construction
            </p>
            <tr>
              <td className="title">Residential</td>
              <td>{companyDetails.Project_Catered.Architectural_Construction.Residential}</td>
              <td className="title">Commercial</td>
              <td>{companyDetails.Project_Catered.Architectural_Construction.Commercial}</td>
            </tr>

            <p className="sub-title" style={{ textAlign: "center" }}>
              Renovation
            </p>
            <tr>
              <td className="title">Residential</td>
              <td>{companyDetails.Project_Catered.Renovation.Residential}</td>
              <td className="title">Commercial</td>
              <td>{companyDetails.Project_Catered.Renovation.Commercial}</td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Do you provide consultation as per Vaastu and / or Feng Shui?
              </td>
              <td id="fullwidth">{companyDetails.Project_Catered.Consultation}</td>
            </tr>
          </table>
          </div>
        </div>

        <div className="profile-detail">
          {/* <p className="profile-detail-title">Services Provided</p> */}
          <h2>Services Provided</h2>
          <table>
            <tr>
              <td className="title" id="fullwidth">
                Initial Consultation
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {initialconsultations.map((award, index) => {
                    return <li key={index}>{award}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Initial_Consultation.Company_Office? 
                  <li><i className="fas fa-check"></i>Company Office</li>
                  : null}
                  {companyDetails.Service_Provided.Initial_Consultation.Site? 
                  <li><i className="fas fa-check"></i>Site</li>
                  : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Softwares Used
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {softwaresused.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Software_Used.Interior_Design? <li><i className="fas fa-check"></i>Interior Design</li> : null}
                  {companyDetails.Service_Provided.Software_Used.Architectural_Construction? <li><i className="fas fa-check"></i>Architectural Construction</li> : null}
                  {companyDetails.Service_Provided.Software_Used.Civil_Engineering? <li><i className="fas fa-check"></i>Civil Engineering</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Design Schematics
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {designschematics.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Design_Schematics.Plans? <li><i className="fas fa-check"></i>Plans</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Drawing_Sketches? <li><i className="fas fa-check"></i>Drawings / Sketches</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Design? <li><i className="fas fa-check"></i>Designs</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Consultation? <li><i className="fas fa-check"></i>Consultation</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Elevations? <li><i className="fas fa-check"></i>Elevations</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Renders? <li><i className="fas fa-check"></i>Renders</li> : null}
                  {companyDetails.Service_Provided.Design_Schematics.Walk_Through? <li><i className="fas fa-check"></i>Walk Through</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title">Booking Confirmation</td>
              {companyDetails.Service_Provided.Booking_Confirmation? <td>Yes</td> : <td>No</td>}
              <td className="title">Legal Agreements</td>
              {companyDetails.Service_Provided.Legal_Agreements? <td>Yes</td> : <td>No</td>}
              <td className="title">Project Timeframe</td>
              {companyDetails.Service_Provided.Project_Timeframe? <td>Yes</td> : <td>No</td>}
              <td className="title">Execution</td>
              {companyDetails.Service_Provided.Execution? <td>Yes</td> : <td>No</td>}
            </tr>
            <p className="sub-title">Type of Properties Served</p>
            <tr>
              <td className="title" id="fullwidth">
                Residential
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {typeofpropertiesserved.Residential.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Apartment? <li><i className="fas fa-check"></i>Apartment</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Penthouse? <li><i className="fas fa-check"></i>Penthouse</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Stan_Alone_Building? <li><i className="fas fa-check"></i>Stan Alone Building</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Independent_House? <li><i className="fas fa-check"></i>Independent House</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Villa? <li><i className="fas fa-check"></i>Villa</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Residential.Bungalow? <li><i className="fas fa-check"></i>Bungalow</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Commercial
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {typeofpropertiesserved.Commercial.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Retail? <li><i className="fas fa-check"></i>Retail</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Hospitality? <li><i className="fas fa-check"></i>Hospitality - (Accommodation, Food & Beverage, Travel & Tourism)</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Healthcare? <li><i className="fas fa-check"></i>Healthcare</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Office? <li><i className="fas fa-check"></i>Office - Corporate & Tech</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Industrial? <li><i className="fas fa-check"></i>Industrial</li> : null}
                  <br />

                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Institutional
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {typeofpropertiesserved.Institutional.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Institutional.Educational_Sector? <li><i className="fas fa-check"></i>Educational Sector</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Institutional.Financial_Sector? <li><i className="fas fa-check"></i>Financial Sector</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Institutional.Media_Entertainment_Sector? <li><i className="fas fa-check"></i>Media & Entertainment Sector</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Institutional.Research_Development_Sector? <li><i className="fas fa-check"></i>Research & Development Sector</li> : null}
                  {companyDetails.Service_Provided.Type_of_Properties_Served.Commercial.Institutional.Transportation_Sector? <li><i className="fas fa-check"></i>Transportation Sector</li> : null}
                </ul>
              </td>
            </tr>
            <br />
            <tr>
              <td className="title" id="fullwidth">
                Interior Design Execution Services
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {InteriorDesignExecutionServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Hard_Finishes? <li><i className="fas fa-check"></i>Hard Finishes</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Furniture? <li><i className="fas fa-check"></i>Furniture, Fixtures & Equipment</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Soft_Finishes? <li><i className="fas fa-check"></i>Soft Finishes</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Art_Decore? <li><i className="fas fa-check"></i>Art & Décor</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Eco_Friendly? <li><i className="fas fa-check"></i>Eco – Friendly</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Entertainment? <li><i className="fas fa-check"></i>Entertainment</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Tech_Savvy? <li><i className="fas fa-check"></i>Tech – Savvy</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Disabled_Handicap? <li><i className="fas fa-check"></i>Disabled / Handicap – Accessible Designs & Services</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Health_Safety? <li><i className="fas fa-check"></i>Health & Safety</li> : null}
                  {companyDetails.Service_Provided.Interior_Design_Execution_Services.Security_Systems? <li><i className="fas fa-check"></i>Security Systems</li> : null}
                </ul>
              </td>
            </tr>

            <p className="sub-title">
              Architectural Construction Execution Services
            </p>
            <tr>
              <td className="title" id="fullwidth">
                Grey Structure
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {ArchitecturalConstructionExecutionServices.GreyStructure.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                  {/* Grey Structure */}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Grey_Structure.Soil_Testing? <li><i className="fas fa-check"></i>Soil Testing</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Grey_Structure.Site_Clearance? <li><i className="fas fa-check"></i>Site Clearance</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Grey_Structure.Site_Layout? <li><i className="fas fa-check"></i>Site Layout</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Grey_Structure.Substructure_Construction? <li><i className="fas fa-check"></i>Substructure Construction Phase</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Grey_Structure.Super_Structure? <li><i className="fas fa-check"></i>Super Structure Construction Phase</li> : null}
                  

                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Disabled_Handicap? <li><i className="fas fa-check"></i>Disabled / Handicap – Accessible Designs & Services</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Health_Safety? <li><i className="fas fa-check"></i>Health & Safety</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Security_System? <li><i className="fas fa-check"></i>Security Systems</li> : null}
                  {companyDetails.Service_Provided.Architectural_Construction_Execution.Finishing? <li><i className="fas fa-check"></i>Finishing</li> : null}
                </ul>
              </td>
            </tr>
            <br />
            <tr>
              <td className="title" id="fullwidth">
                Civil & Renovation Services
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {CivilRenovationServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Civil_Renovation.Interior_Design? <li><i className="fas fa-check"></i>Same as the above selected Interior Design Execution Services</li> : null}
                  {companyDetails.Service_Provided.Civil_Renovation.Architectural_Construction? <li><i className="fas fa-check"></i>Same as the above selected Architectural Construction Execution Services Selected</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Other Services
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {OtherServices.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Other_Services.Assembling? <li><i className="fas fa-check"></i>Assembling Furniture</li> : null}
                  {companyDetails.Service_Provided.Other_Services.Heavy_Items? <li><i className="fas fa-check"></i>Heavy Item Lifting and Moving</li> : null}
                  {companyDetails.Service_Provided.Other_Services.Household? <li><i className="fas fa-check"></i>Household Chores</li> : null}
                  {companyDetails.Service_Provided.Other_Services.Packers_Movers? <li><i className="fas fa-check"></i>Packers and Movers</li> : null}
                  {companyDetails.Service_Provided.Other_Services.Packers_Movers? <li><i className="fas fa-check"></i>Pest Control</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Products & Accessories
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {ProductsAccessories.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Product_Accessories.Appilances? <li><i className="fas fa-check"></i>Appliances</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Artwork? <li><i className="fas fa-check"></i>Artwork</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Decor? <li><i className="fas fa-check"></i>Décor</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Furniture? <li><i className="fas fa-check"></i>Furniture</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Hardware? <li><i className="fas fa-check"></i>Hardware</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Mirrors? <li><i className="fas fa-check"></i>Mirrors</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Modular_Customized? <li><i className="fas fa-check"></i>Modular / Customized Furniture</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Painting? <li><i className="fas fa-check"></i>Painting</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Upholstery? <li><i className="fas fa-check"></i>Upholstery</li> : null}
                  {companyDetails.Service_Provided.Product_Accessories.Wallpapers? <li><i className="fas fa-check"></i>Wallpapers</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Final Review & Handover
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {FinalReviewHandover.map((software, index) => {
                    return <li key={index}>{software}</li>;
                  })} */}
                  {companyDetails.Service_Provided.Final_Review_Handover.Final_Builing? <li><i className="fas fa-check"></i>Final Building Inspection</li> : null}
                  {companyDetails.Service_Provided.Final_Review_Handover.Confirmation_Payment? <li><i className="fas fa-check"></i>Confirmation of Final Payment Received</li> : null}
                  {companyDetails.Service_Provided.Final_Review_Handover.Documentation? <li><i className="fas fa-check"></i>Documentation</li> : null}
                  {companyDetails.Service_Provided.Final_Review_Handover.Handover? <li><i className="fas fa-check"></i>Handover </li> : null}
                  {companyDetails.Service_Provided.Final_Review_Handover.Photoshoot_Videography? <li><i className="fas fa-check"></i>Photoshoot & Videography</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                After – Sales Support
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {companyDetails.Service_Provided.After_Sales ?<li><i className="fas fa-check"></i>Warranty Certification</li>: null}
                </ul>
              </td>
            </tr>
            <p className="sub-title">Design Specialization</p>
            <tr>
              <td className="title" id="fullwidth">
                Interior Design Styles
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {DesignSpecialization.InteriorDesignStyles.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Asian? <li><i className="fas fa-check"></i>Asian</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Beach_Nautical? <li><i className="fas fa-check"></i>Beach / Nautical</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Contemporary_Home? <li><i className="fas fa-check"></i>Contemporary Home Décor</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Craftsman? <li><i className="fas fa-check"></i>Craftsman</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Eclectic? <li><i className="fas fa-check"></i>Eclectic</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Eco_Friendly? <li><i className="fas fa-check"></i>Eco - Friendly</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Farmhouse? <li><i className="fas fa-check"></i>Farmhouse</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Hollywood_Glam? <li><i className="fas fa-check"></i>Hollywood Glam</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Industrial? <li><i className="fas fa-check"></i>Industrial</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Mediterranean? <li><i className="fas fa-check"></i>Mediterranean</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Mid_Century? <li><i className="fas fa-check"></i>Mid – Century Modern</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Minimalist? <li><i className="fas fa-check"></i>Minimalist</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Modern? <li><i className="fas fa-check"></i>Modern</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Rustic? <li><i className="fas fa-check"></i>Rustic</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Scandinavian? <li><i className="fas fa-check"></i>Scandinavian</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Shabby_Chic? <li><i className="fas fa-check"></i>Shabby – Chic</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Southwestern? <li><i className="fas fa-check"></i>Southwestern</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Traditional? <li><i className="fas fa-check"></i>Traditional</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Transitional? <li><i className="fas fa-check"></i>Transitional</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Interior_Design.Tropical? <li><i className="fas fa-check"></i>Tropical</li> : null}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="title" id="fullwidth">
                Architectural Styles
              </td>
              <td id="fullwidth">
                <ul className="profile-detail-list">
                  {/* {DesignSpecialization.ArchitecturalStyles.map(
                    (software, index) => {
                      return <li key={index}>{software}</li>;
                    }
                  )} */}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Art_Deco? <li><i className="fas fa-check"></i>Art Deco</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Bauhaus? <li><i className="fas fa-check"></i>Bauhaus</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Constructivism? <li><i className="fas fa-check"></i>Constructivism (Art)</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Constructivist_Architecture? <li><i className="fas fa-check"></i>Constructivist Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Contemporary_Architecture? <li><i className="fas fa-check"></i>Contemporary Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Eco_Friendly? <li><i className="fas fa-check"></i>Eco - Friendly</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Functionalism? <li><i className="fas fa-check"></i>Functionalism</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Futurist_Architecture? <li><i className="fas fa-check"></i>Futurist Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.High_Tech? <li><i className="fas fa-check"></i>High - Tech Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Industrial? <li><i className="fas fa-check"></i>Industrial Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.International? <li><i className="fas fa-check"></i>International Style</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Mid_Century? <li><i className="fas fa-check"></i>Mid - Century Modern</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Minimalism? <li><i className="fas fa-check"></i>Minimalism</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Modern? <li><i className="fas fa-check"></i>Modern Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.New_Objectivity? <li><i className="fas fa-check"></i>New Objectivity</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Organic? <li><i className="fas fa-check"></i>Organic Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Postmodern? <li><i className="fas fa-check"></i>Postmodern Architecture</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Ranch? <li><i className="fas fa-check"></i>Ranch - Style House</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Streamline? <li><i className="fas fa-check"></i>Streamline Modern</li> : null}
                  {companyDetails.Service_Provided.Design_Specialization.Architectural_Style.Sustainable? <li><i className="fas fa-check"></i>Sustainable Architecture</li> : null}
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
} else {
  return (
    <LoadingIcon />
  )
}
}

export default ProfileOverview;