import React from "react";
import "./Utils.css";

export default function ParentTable(props) {
  
  const subheadings =props.subheadings ;
  const data = props.data;

  return (
    <div className="parent-table-container">
      <table>
        <tr>
          <td className="heading" colSpan={subheadings.length}>
          {props.mainheading}
          </td>
        </tr>
        <tr>
          {subheadings.map((subheading, index) => {
            return (
              <td className="subheading" key={index}>
                {subheading}
              </td>
            );
          })}
        </tr>
        <tr>
          {data.map((data, index) => {
            return <td key={index}>{data}</td>;
          })}
        </tr>
        <tr style={{
          display : props.listanddetails ? "" : "none"
        }}>
          {subheadings.map((subheading, index) => {
            return <td id={subheading} className="view-list-and-detail" onClick={props.handlelistview} key={index}>View List and Details</td>;
          })}
        </tr>
      </table>
    </div>
  );
}
