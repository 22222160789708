import React, { useLayoutEffect, useState } from "react";
import "./Carousel.css";

export default function Carousel(props) {
  const Images = props.Images;

  const [screenwidth, setscreenwidth] = useState(
    document.querySelector("body").scrollWidth
  );


  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
    // console.log(screenwidth);
  }, [screenwidth]);

  if (props.Auto) {
    setTimeout(() => {
      const carousels = document.querySelectorAll("#carousel");
      carousels.forEach((carousel) => {
        
        // console.log(carousel.childNodes.length);

        var widthtomove = props.Full ? 100 : 50;
        setInterval(() => {
          if (carousel.childNodes.length > 1) {
            carousel.style.transform = `translateX(-${widthtomove}%)`;
            widthtomove = widthtomove + 50;
            if (widthtomove > (carousel.childNodes.length - 1) * 50) {
              widthtomove = 0;
            }
          }
        }, 5000);
      });
    }, 50);
  }

  const handlearrows = (e) => {
    const clickedel = e.target;

    const carousel = document.getElementById("carousel");
    const transformwidth = parseInt(
      carousel.style.transform.replace(/[^\d.]/g, "")
    );

    var widthtomove = props.widthtomove;
    if (screenwidth < 1000) {
      widthtomove = 100;
    }
    if (clickedel.id === "right-carousel-arrow") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        carousel.style.transform = `translate(-${widthtomove}%)`;
      }
      if (transformwidth > widthtomove * (Images.length - 2)) {
        carousel.style.transform = `translate(-${0}%)`;
      } else {
        carousel.style.transform = `translate(-${transformwidth + widthtomove
          }%)`;
      }
    }
    if (clickedel.id === "left-carousel-arrow") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        carousel.style.transform = `translate(-${widthtomove * (Images.length - 1)
          }%)`;
      } else {
        carousel.style.transform = `translate(-${transformwidth - widthtomove
          }%)`;
      }
    }
  };

  return (
    <div className="carousel-container">
      <div
        style={{
          display: props.NotArrows ? "none" : "",
        }}
        className="arrows"
      >
        <span
          onClick={handlearrows}
          id="left-carousel-arrow"
          className="material-icons"
        >
          chevron_left
        </span>
        <span
          onClick={handlearrows}
          id="right-carousel-arrow"
          className="material-icons"
        >
          chevron_right
        </span>
      </div>
      <div className="carousel-images">
        <div id="carousel" className="carousel">
          {Images.map((img, index) => {
            return (
              <div
                style={{
                  minWidth: screenwidth > 1000 ? props.imgwidth : null,
                }}
                key={index}
                className="carousel-img"
              >
                <img
                  style={{
                    borderLeft:
                      props.imgwidth === "50%" && screenwidth > 1000
                        ? "10px solid white"
                        : null,
                    borderRadius:
                      props.imgwidth === "50%" && screenwidth > 1000
                        ? "0"
                        : null,
                  }}
                  src={img}
                  alt={img}
                />
              </div>
            );
          })}
          {props.Dots ? (
            <div className="dots-container">
              <h1>Hello</h1>
              <span class="material-symbols">radio_button_unchecked</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
