import React from "react";
import "./ProjectImagesCarousel.css";

export default function ProjectImagesCarousel(props) {
  const Images = props.Images;

  var widthtomove = 0;

  const handlecarousel = (e) => {
    const clickedel = e.target.id;
    const container = document.getElementById("project-images-carousel");

    if (clickedel === "left-icn") {
      // console.log(widthtomove);
      if (widthtomove === 0) {
        widthtomove = (Images.length - 1) * 100;
      } else {
        widthtomove = widthtomove - 100;
      }

      container.style.transform = `translateX(-${widthtomove}%)`;
    }
    if (clickedel === "right-icn") {
      // console.log(widthtomove);
      if (widthtomove >= (Images.length - 1) * 100) {
        widthtomove = 0;
      } else {
        widthtomove = widthtomove + 100;
      }
      container.style.transform = `translateX(-${widthtomove}%)`;
    }
  };

  // const categories = props.categories
  const ProjectDetails = props.ProjectDetails
// console.log("ProjectDetails.Imagedetails", ProjectDetails.Imagedetails);

  return (
    <div className="project-images-carousel-container">
      <div onClick={handlecarousel} className="arrowss">
        <span id="left-icn" className="material-icons">
          chevron_left
        </span>
        <span id="right-icn" className="material-icons">
          chevron_right
        </span>
      </div>
      <div id="project-images-carousel" className="project-images-carousel">
        {ProjectDetails.Imagedetails.map((imgdetail, index) => {
          return (
            <div key={index} className="img-dtl">
              <img src={imgdetail.Image} alt="img" />
              <br />
              <div className="dtl">
                <div className="lbl-input">
                  <label>Image No :</label>
                  <p className="input">{index + 1}</p>
                  {/* <p className="input">{imgdetail.Image.slice(
                      imgdetail.Image.length / 2,
                      imgdetail.Image.length
                    )}</p> */}
                </div>
                <div className="lbl-input">
                  <label>Title :</label>
                  <p className="input">{imgdetail.Title}</p>
                </div>
                <div className="lbl-input">
                  <label>Category :</label>
                  
                  <div className="multi-inputs">
                    {imgdetail.Category.map((cat , index) => {
                      return  <p key={index} className="category">{cat}</p>
                    })}
                   
        
                  </div>
                </div>
                <div className="lbl-input">
                  <label>Description :</label>
                  <p className="input">
                    {
                    imgdetail.Description
                    }
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
