import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { UploadNewProject } from "../../../../../../_actions/project_actions";
import Declaration from "../../../../../utils/Declaration/Declaration";
import LoadingIcon from "../../../../../utils/LoadingIcon/LoadingIcon";
import { Commercial, Residential } from "./Data";
import "./UploadedProjectModal.css";

function UploadedProjectModal(props) {
  const dispatch = useDispatch();
  const [residential, setresidential] = useState(false);
  const [commercial, setcommercial] = useState(false);
  const [institutional, setinstitutional] = useState(false);

  const [projectdetails, setprojectdetails] = useState([]);
  const [imgtitle, setimgtitle] = useState("");
  const [imgdescription, setimgdescription] = useState("");
  const [residentialsuboptions, setresidentialsuboptions] = useState([]);
  const [commercialoptions, setcommercialoptions] = useState([]);
  const [institutionaloptions, setinstitutionaloptions] = useState([]);
  const [upload, setupload] = useState(false);
  const [loading, setloading] = useState(false);
  const [uploadprojectclicked, setuploadprojectclicked] = useState(false);

  var Imagedetail = {
    Id: "",
    Image: "",
    Title: imgtitle,
    Category: [],
    Description: imgdescription,
  };

  const handleimgtitle = (e) => {
    setimgtitle(e.target.value);
  };

  const handleimgdescription = (e) => {
    setimgdescription(e.target.value);
  };

  useEffect(() => {
    if (uploadprojectclicked) {
      handleuploadproject();
      setuploadprojectclicked(false);
    }
  }, [uploadprojectclicked]);

  const handlenext = (e) => {
    const transformwidth = parseInt(
      document
        .getElementById("imganddetail")
        .style.transform.replace(/[^\d.]/g, "")
    );
    var widthtomove = 100;
    if (transformwidth.toString() === "NaN" || transformwidth === 0) {
      widthtomove = 100;
    } else {
      widthtomove = transformwidth + widthtomove;
    }
    if (transformwidth >= 100 * (props.Images.length - 2)) {
      setupload(true);
    }

    if (transformwidth === 100 * (props.Images.length - 1)) {
      widthtomove = transformwidth;
      setupload(true);
    }
    if (props.Images.length === 1) {
      widthtomove = 0;
    }
    if (transformwidth.toString() === "NaN" && props.Images.length === 2) {
      setupload(true);
    }

    // console.log(transformwidth);
    // console.log(props.Images.length - 1);

    document.getElementById(
      "imganddetail"
    ).style.transform = `translateX(-${widthtomove}%)`;
    const imgaddress =
      e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0]
        .childNodes[0].src;
    Imagedetail.Image = imgaddress;

    Imagedetail.Id = imgaddress.slice(imgaddress.length / 2, imgaddress.length);
    Imagedetail.Title = imgtitle;
    const residentiallists =
      e.currentTarget.parentElement.parentElement.childNodes[1].childNodes[0]
        .childNodes[4].childNodes;
    const commerciallists =
      e.currentTarget.parentElement.parentElement.childNodes[1].childNodes[0]
        .childNodes[5].childNodes;

    const residentialorcommercial =
      e.currentTarget.parentElement.parentElement.childNodes[1].childNodes[0]
        .childNodes[3].childNodes[0].childNodes[0].innerText;

    if (residentialorcommercial === "Residential") {
      Imagedetail.Category.push("Residential");
      residentiallists.forEach((e) => {
        if (e.childNodes[0].childNodes[0].innerText !== "Select") {
          Imagedetail.Category.push(e.childNodes[0].childNodes[0].innerText);
        }
      });
    }
    if (residentialorcommercial === "Commercial") {
      Imagedetail.Category.push("Commercial");
      commerciallists.forEach((e) => {
        if (e.childNodes[0].childNodes[0].innerText !== "Select") {
          Imagedetail.Category.push(e.childNodes[0].childNodes[0].innerText);
        }
      });
    }

    Imagedetail.Description = imgdescription;

    const projectexists = projectdetails.filter(
      (project) => project.Id === Imagedetail.Id
    )[0];

    setprojectdetails((prev) => {
      if (projectexists) {
        const previousprojects = prev.filter(
          (prj) => prj.Id !== projectexists.Id
        );
        if (previousprojects.length > 0) {
          return [...previousprojects, Imagedetail];
        } else {
          return [Imagedetail];
        }
      } else {
        return [...prev, Imagedetail];
      }
    });

    if (e.currentTarget.innerText === "Upload Project") {
      setuploadprojectclicked(true);
    }
  };

  useEffect(() => {
    if (props.Images.length === 1) {
      setupload(true);
    } else {
      setupload(false);
    }
  }, [props.Images]);

  const handleback = (e) => {
    const transformwidth = parseInt(
      document
        .getElementById("imganddetail")
        .style.transform.replace(/[^\d.]/g, "")
    );
    var widthtomove = 100;
    if (transformwidth.toString() === "NaN") {
      widthtomove = 100;
    } else {
      widthtomove = transformwidth - widthtomove;
    }

    setupload(false);
    if (props.Images.length === 1) {
      setupload(true);
      widthtomove = 0;
    }
    document.getElementById(
      "imganddetail"
    ).style.transform = `translateX(-${widthtomove}%)`;
  };

  const handelist = (e) => {
    const list = e.currentTarget.nextSibling;
    const heading = e.currentTarget;
    list.style.transform = "scale(1)";
    if (list.style.opacity === "0") {
      list.style.transform = "scale(1)";
      list.style.opacity = "1";
      heading.childNodes[1].innerText = "expand_less";
    } else {
      list.style.transform = "scale(0)";
      list.style.opacity = "0";
      heading.childNodes[1].innerText = "expand_more";
    }
  };

  const handlelistitem = (e) => {
    const list = e.currentTarget.parentElement;
    const listitem = e.currentTarget;
    const heading = e.currentTarget.parentElement.parentElement.childNodes[0];

    list.childNodes.forEach((e) => {
      e.style.color = "black";
      e.style.backgroundColor = "rgb(226, 225, 225)";
    });
    heading.childNodes[0].innerText = listitem.innerText;
    list.style.transform = "scale(0)";
    heading.childNodes[1].innerText = "expand_more";
    list.style.opacity = 0;
    listitem.style.color = "white";
    listitem.style.backgroundColor = "#fe5631";

    if (list.id === "residential-or-commercial") {
      if (listitem.innerText === "Residential") {
        setresidential(true);
        setcommercial(false);
      }
      if (listitem.innerText === "Commercial") {
        setcommercial(true);
        setresidential(false);
      }
    }

    if (
      list.id === "residential-options" ||
      list.id === "residential-sub-sub-options"
    ) {
      if (list.parentElement.nextSibling) {
        list.parentElement.nextSibling.style.display = "block";
      }
    }

    if (list.id === "residential-sub-options") {
      const suboptions = Residential.SubCategories.Options.filter(
        (option) => option.Title === listitem.innerText
      )[0].SubOptions;
      if (suboptions.length > 0) {
        setresidentialsuboptions(suboptions);
        list.parentElement.nextSibling.style.display = "block";
        list.parentElement.nextSibling.childNodes[0].childNodes[0].innerText =
          "Select";
      } else {
        list.parentElement.nextSibling.style.display = "none";
      }
    }
    if (
      list.id === "commercial-options" &&
      listitem.innerText !== "Institutional"
    ) {
      setinstitutional(false);
      const options = Commercial.SubCategories.filter(
        (cat) => cat.Title === listitem.innerText
      )[0].Options;
      if (options.length > 0) {
        setcommercialoptions(options);
        list.parentElement.nextSibling.style.display = "block";
        list.parentElement.nextSibling.childNodes[0].childNodes[0].innerText =
          "Select";
      } else {
        list.parentElement.nextSibling.style.display = "none";
      }
    }
    if (
      list.id === "commercial-options" &&
      listitem.innerText === "Institutional"
    ) {
      list.parentElement.nextSibling.style.display = "none";
      setinstitutional(true);
      document.getElementById(
        "instititional-options"
      ).parentElement.style.display = "block";
    }
    if (list.id === "instititional-options") {
      const options = Commercial.SubCategories[5].Options.filter(
        (option) => option.Title === listitem.innerText
      )[0].SubOptions;
      if (options.length > 0) {
        setinstitutionaloptions(options);
        list.parentElement.nextSibling.style.display = "block";
        list.parentElement.nextSibling.childNodes[0].childNodes[0].innerText =
          "Select";
      } else {
        list.parentElement.nextSibling.style.display = "none";
      }
    }
  };

  const handleuploadproject = (e) => {
    // setloading(true);
    // const otherdetails = props.details;
    var variables = {
      WriterDetails: {
        Company_Name: props.details.WriterDetails.Company_Name,
        Service_Professional_Name: props.details.WriterDetails.Service_Professional_Name,
      },
      writer: props.details.writer,
      Project_Name: props.details.Project_Name,
      Project_Client_Name: props.details.Project_Client_Name,
      Project_Completion: props.details.Project_Completion,
      Project_Value: props.details.Project_Value,
      Project_Page_Link: props.details.Project_Page_Link,
      Project_Description: props.details.Project_Description,
      Service_Requirement: props.details.Service_Requirement,
      Project_Type: props.details.Project_Type,
      Project_Type_Subcategory_1: props.details.Project_Type_Subcategory_1,
      Project_Type_Subcategory_2: props.details.Project_Type_Subcategory_2,
      Project_Architecture_Style: props.details.Project_Architecture_Style,
      Uploaded_Date: new Date().toLocaleDateString(),
      Address: {
        House_Building_Plot_Land: props.details.Address.House_Building_Plot_Land,
        Areaname: props.details.Address.Areaname,
        State: props.details.Address.State,
        City: props.details.Address.City,
      },
      Images: props.details.Images,
      Imagedetails: projectdetails,
    };
    // console.log(variables);

    dispatch(UploadNewProject(variables)).then((response) => {
      if (response.payload.success) {
        // console.log("Response ", response);
        props.history.push(`/ServiceProfessional/ProjectsDetails/${response.payload.result}`);
      } else {
        alert("Failed to upload project. Please try again later");
      }
    });
  };

  const [agreed, setagreed] = useState(false);

  const handleagreed = (val) => {
    // console.log(val);
    setagreed(val);
  };

  useEffect(() => {
    // console.log(agreed);
  });

  return (
    <div
      style={{
        backgroundColor: props.backgroundcolor,
        visibility: props.visibility,
        opacity: props.opacity,
      }}
      id="uploaded-project-modal-container"
      className="uploaded-project-modal-container"
    >
      <span
        id="close-modal"
        onClick={props.handleclosemodal}
        className="material-icons"
      >
        close
      </span>
      <div
        style={{
          transform: props.transform,
        }}
        id="uploaded-project-modal"
        className="uploaded-project-modal"
      >
        {loading ? (
          <LoadingIcon
            position="absolute"
            width="100%"
            height="100%"
            backgroundcolor="rgb(56,56,56,0.5)"
          />
        ) : null}
        <div className="project-details-heading">
          <p className="heading">Project Details</p>
        </div>
        {/* Forwards and Backwards btns */}

        <div
          id="images-and-details-container"
          className="images-and-details-container"
        >
          <div id="imganddetail" className="imganddetail">
            {props.Images.map((img, index) => {
              return (
                <div
                  id="images-and-details"
                  key={index}
                  className="images-and-details"
                >
                  <div className="images-container">
                    <div className="images">
                      <img src={img} alt="modal-pic" />
                    </div>
                  </div>
                  <div className="uploaded-project-details">
                    <div className="project-details">
                      <label>Title</label>
                      <input onChange={handleimgtitle} type="text" />
                      <label>Category</label>
                      <ul className="detail-select-list-container">
                        <li onClick={handelist} className="selected-heading">
                          <p className="heading">Select</p>
                          <span class="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="residential-or-commercial"
                          className="detail-select-list"
                        >
                          <li onClick={handlelistitem}>Residential</li>
                          <li onClick={handlelistitem}>Commercial</li>
                        </ul>
                      </ul>
                      {/* Residential Lists */}
                      <div
                        style={{
                          display: residential ? "block" : "none",
                        }}
                        id="residential-lists"
                        className="residential-lists"
                      >
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="residential-options"
                            className="detail-select-list"
                          >
                            {Residential.SubCategories.Titles.map(
                              (title, index) => {
                                return (
                                  <li key={index} onClick={handlelistitem}>
                                    {title}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </ul>

                        <ul
                          style={{
                            display: "none",
                          }}
                          className="detail-select-list-container"
                        >
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="residential-sub-options"
                            className="detail-select-list"
                          >
                            {Residential.SubCategories.Options.map(
                              (option, index) => {
                                return (
                                  <li key={index} onClick={handlelistitem}>
                                    {option.Title}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </ul>
                        <ul
                          style={{
                            display: "none",
                          }}
                          id="residential-sub-sub-options"
                          className="detail-select-list-container"
                        >
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            className="detail-select-list"
                          >
                            {residentialsuboptions.map((suboption, index) => {
                              return (
                                <li key={index} onClick={handlelistitem}>
                                  {suboption}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                      {/* Commercial Lists */}
                      <div
                        style={{
                          display: commercial ? "block" : "none",
                        }}
                        id="commercial-lists"
                        className="commercial-lists"
                      >
                        <ul className="detail-select-list-container">
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="commercial-options"
                            className="detail-select-list"
                          >
                            {Commercial.SubCategories.map(
                              (sucategory, index) => {
                                return (
                                  <li key={index} onClick={handlelistitem}>
                                    {sucategory.Title}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </ul>

                        <ul
                          style={{
                            display: "none",
                          }}
                          className="detail-select-list-container"
                        >
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            className="detail-select-list"
                          >
                            {commercialoptions.map((option, index) => {
                              return (
                                <li key={index} onClick={handlelistitem}>
                                  {option}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>

                        <ul
                          style={{
                            display: institutional ? "block" : "none",
                          }}
                          className="detail-select-list-container"
                        >
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="instititional-options"
                            className="detail-select-list"
                          >
                            {Commercial.SubCategories[5].Options.map(
                              (option, index) => {
                                return (
                                  <li key={index} onClick={handlelistitem}>
                                    {option.Title}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </ul>

                        <ul
                          style={{
                            display: institutional ? "block" : "none",
                          }}
                          className="detail-select-list-container"
                        >
                          <li onClick={handelist} className="selected-heading">
                            <p className="heading">Select</p>
                            <span class="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            className="detail-select-list"
                          >
                            {institutionaloptions.map((option, index) => {
                              return (
                                <li key={index} onClick={handlelistitem}>
                                  {option}
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                      <label>Description</label>
                      <textarea onChange={handleimgdescription} type="text" />
                    </div>
                  </div>
                  {upload ? <Declaration agreed={handleagreed} user={"SP"} /> : null}
                  <div className="forward-backwards-btns">
                    <p id="back-btn" onClick={handleback} className="back-btn">
                      Back
                    </p>
                    {upload ? null : (
                      <p
                        id="next-btn"
                        onClick={handlenext}
                        className="next-btn"
                      >
                        Next
                      </p>
                    )}
                    {upload ? (
                      <p
                        style={{
                          opacity: agreed ? 1 : 0.5,
                          pointerEvents: agreed ? "auto" : "none",
                        }}
                        id="next-btn"
                        onClick={handlenext}
                        className="next-btn"
                      >
                        Upload Project
                      </p>
                    ) : null}
                    {/* <p
                      style={{
                        transform: upload ? "scale(1)" : "scale(0)",
                      }}
                      id="back-btn"
                      onClick={handleuploadproject}
                      className="back-btn"
                    >
                      Upload Project
                    </p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UploadedProjectModal)