import React, { useRef } from "react";
import { useEffect } from "react";
import { animate } from "./Animate";
import "./SmallCarousel.css";

const SmallCarousel = ({ Images, style }) => {

  const imagesref = useRef(null);
  const dotsref = useRef(null);


  useEffect(() => {
    // animate(0, "small-carousel-images-container");

  }, [])
  useEffect(() => {
    if (Images.length > 0) {
      animate(false ,imagesref.current, dotsref.current);
    }
  }, [Images])
  //   if (document.readyState === "complete") {
  //     animate(0, imagesref.current, dotsref.current);
  // // console.log("loaded")
  //   }
  return (
    <div style={style} className="small-carousel-container">
      <div ref={imagesref} id="small-carousel-images-container" className="images-container">
        {Images.map((img, index) => {
          return <img src={img} alt={"image" - index} />;
        })}
      </div>

      <div ref={dotsref} id="small-carousel-dots" className="dots">
        {Images.map((dot, index) => {
          return (
            <div
              onClick={(e) => {
                animate(index, imagesref.current, dotsref.current);

              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default SmallCarousel;
