import axios from "axios";
import { FIND_NEIGHBOURHOOD_COUNT, GET_ALL_PROPERTY, GET_ALL_PROPERTY_WITHOUT_AUTH, OWNER_PROPERTY_BY_ID, PRODUCT_BY_ID, PROPERTY_BY_ID, TOP_FIVE_PROPERTIES, UPDATE_PROPERTY_DETAILS } from './types';
import { PRODUCT_SERVER } from '../Config';

export function getAllProperty(variables) {
    // console.log("Get All Property Action Details",variables);
    const request = axios.post(`${PRODUCT_SERVER}/getallproperty`,variables)
        .then(response => response.data);

        return {
            type: GET_ALL_PROPERTY,
            payload: request
        }
}

export function getAllProductsWIthoutAuth(variables) {
    const request = axios.post(`${PRODUCT_SERVER}/getAllProductsWIthoutAuth`,variables)
        .then(response => response.data);
        return {
            type: GET_ALL_PROPERTY_WITHOUT_AUTH,
            payload: request
        }
}

export function propertydetailsbyID(id) {
    // console.log("Action Details", id);
    const request = axios.get(`${PRODUCT_SERVER}/propertybyID?propertyId=${id}`)
        .then(response => response.data);

        return {
            type: PROPERTY_BY_ID,
            payload: request
        }
}
export function Owner_propertydetailsbyID(id) {
    // console.log("Action Details", id);
    const request = axios.get(`${PRODUCT_SERVER}/ownerPropertybyID?propertyId=${id}`)
        .then(response => response.data);

        return {
            type: OWNER_PROPERTY_BY_ID,
            payload: request
        }
}

export function productDetailsbyID(id) {
    // console.log("Product ID", id);
    const request = axios.get(`${PRODUCT_SERVER}/product_by_id?propertyId=${id}`)
        .then(response => response.data);

        return {
            type: PRODUCT_BY_ID,
            payload: request
        }
}

export function OnUpdatePropertyDetails(dataToSumit, id) {
    // console.log("Action Data To Submit", dataToSumit);
    const request = axios.post(`${PRODUCT_SERVER}/updateProduct?productId=${id}`, dataToSumit)
        .then(response => response.data);

        return {
            type: UPDATE_PROPERTY_DETAILS,
            payload: request
        }
}

export function getTopFiveProperties(City, PropertyType, type) {
    // console.log("Action Get top 5 Properties");
    const request = axios.get(`${PRODUCT_SERVER}/getTopFiveProperties?city=${City}&projecttype=${PropertyType}&type=${type}`)
        .then(response => response.data);

        return {
            type: TOP_FIVE_PROPERTIES,
            payload: request
        }
}


export function FindtheNeighbourhoodCount() {
    // console.log("Get Neighborhood Count");
    const request = axios.get(`${PRODUCT_SERVER}/getAllNeighbourhoodCount`)
        .then(response => response.data);

        return {
            type: FIND_NEIGHBOURHOOD_COUNT,
            payload: request
        }
}