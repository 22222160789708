import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

function Cart(props){

  const company = useSelector(state => state.company)

  const handleCartClick = () => {
    props.history.push(`/serviceprofessional/CartPage`);
  }


  if(company.companyData && company.companyData.isAuth) {
      return (
          <a onClick={handleCartClick} style={{ color: '#24292E' }}>
          <div className="nav-cart-container">
              <span className="material-icons">
              shopping_cart
              </span>
      
          <p className="number-of-items-in-cart" >{company.companyData && company.companyData.cart.length}</p>
          </div>
          </a>
          )
  } else {
      return(
          null
      )
  }

}

export default withRouter(Cart)