const categories = [
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-1.png",
        Name : "Apartment"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-2.png",
        Name : "Penthouse"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-3.png",
        Name : "Stand Alone Building"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-4.png",
        Name : "Independent Home"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-5.png",
        Name : "Villa"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-6.png",
        Name : "Bungalow"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-7.png",
        Name : "Retail"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-8.png",
        Name : "Hospitality"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-9.png",
        Name : "Healthcare"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-10.png",
        Name : "Office – Corporate and Tech"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-11.png",
        Name : "Institutional"
    },
    {
        Image : "https://nebourhood-website-logo-images.s3.ap-south-1.amazonaws.com/Categories/image-12.png",
        Name : "Industrial"
    }
]

module.exports.categories = categories;