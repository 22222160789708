import React, { useLayoutEffect, useState } from "react";
import "./MembershipPricing.css";
import PageBanner from "../../utils/PageBanner/PageBanner";
import { Plans } from "./Data";
import PlanBreakDown from "./PlanBreakDown/MembershipPlanBreakDown";
import PleaseNoteDisclaimer from "../../utils/PleaseNoteDisclaimer/PleaseNoteDisclaimer";
import MembershipFeatures from "./MembershipFeatures/MembershipFeatures";

export default function MembershipPricing() {
  const bodywidth = document.querySelector("body").scrollWidth;
  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [isplanselected, setisplanselected] = useState(false);
  const [selectedplan, setselectedplan] = useState(0);
  const [plan, setplan] = useState({
    PlanName: "",
    PlanPrice: "",
  });
  document.querySelector("body").style.overflow = isplanselected ?  "hidden" : "auto"
  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const handleplan = (e) => {
    const clickedplan = e.currentTarget.parentElement.parentElement;

    const planprice = clickedplan.childNodes[1].innerText;
    const planname = clickedplan.childNodes[0].innerText;
    // console.log(planprice, planname);
    setplan({
      PlanName: planname,
      PlanPrice: planprice,
    });
  };

  const closemodal = (e) => {
    setisplanselected(false);
  };

  const planbreakdownstyles = {
    visibility: isplanselected ? "visible" : "hidden",
    opacity: isplanselected ? 1 : 0,
  };

  const handleplanselection = (e) => {
    setisplanselected(true);
    const selectedplanindex = parseInt(
      e.currentTarget.parentElement.parentElement.id
    );
    setselectedplan(selectedplanindex);
  };

  

  return (
    <div className="pricing-container">
      <PageBanner title="Service Professional Membership Pricing" />
      <div className="header">
        <p className="title">
          <b>Become A NebourHood Prime Service Professional (S.P.) Member Today!</b>
        </p>
        <p
          style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
          className="description"
        >
          Kindly find detailed information about the Membership Plans outlined below.
        </p>
      </div>
      <div>
        <MembershipFeatures />
      </div>
      <div className="header">
    <fieldset style={{ border: '1px solid rgb(144, 142, 142)', padding: '1em'  }}>
        <legend style={{ textAlign: 'center' }}>
          <p className="title">
            Select Your Plan
          </p>
        </legend>
      <div id="pricing-plans" className="pricing-plans">
        {Plans.map((plan, index) => {
          return (
            <div
              style={{
                width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
              }}
              key={index}
              id={index}
              className="plan"
            >
              <div style={{ textAlign: 'center' }}>
                <p className="title1">
                  <b>{plan.Name}</b>
                </p>
                <br />
                <p className="falseprice">
                  <b><s>{plan.Falseprice}</s></b>
                </p>
                <br />
                <p className="price1">
                  <b>{plan.Price}</b>
                </p>
                <br />
                <p className="validity1">
                  <b>{plan.Validity}</b>
                </p>
                <br />
              </div>
              <div className="btn">
                <button onClick={handleplanselection} className="pricing-btn">
                  Select
                </button>
              </div>
            </div>
          );
        })}
      </div>
      </fieldset>
      </div>
      <br />
      <br />
      <br />

        <PleaseNoteDisclaimer />

      <PlanBreakDown
        styles={planbreakdownstyles}
        isplanselected={isplanselected}
        handleclose={closemodal}
        plan={Plans[selectedplan]}
      />

    </div>
  );
}
