import React, { useLayoutEffect, useState } from "react";
import Cover_Image from "../Views/CoverImage/Cover_Image";
import "./ProfilePage.css";
import { useSelector } from "react-redux";
import LoadingIcon from "../../../../../utils/LoadingIcon/LoadingIcon";
import ProfileImgandCompanyLogo from "../Views/ProfileImgandCompanyLogo/ProfileImgandCompanyLogo";
import EditProfileandOverview from "../Views/EditProfileandOverview/EditProfileandOverview";
import PreviouslyUploadedProjects from "../Views/PreviouslyUploadedProjects/PreviouslyUploadedProjects";
import SP_Analytics from "../../../../Analytics/ServiceProfessional/SP_Analytics";
import Transaction from "../Views/Transaction/Transaction";

function ProfilePage() {
  const company = useSelector((state) => state.company);

  const [overviewclicked, setoverviewclicked] = useState(true);
  const [uploadclicked, setuploadclicked] = useState(false);
  const [transactionclicked, settransactionclicked] = useState(false);
  const [analyticsclicked, setanalyticsclicked] = useState(false);

  const handleprofiletabs = (e) => {
    const clickedel = e.target;
    const profiletabs = e.currentTarget.childNodes;
    profiletabs.forEach((tab) => {
      tab.style.backgroundColor = "rgb(233, 232, 232)";
      tab.style.color = "black";
    });
    clickedel.style.backgroundColor = "#1dc295";
    clickedel.style.color = "white";
    setoverviewclicked(false);
    setuploadclicked(false);
    settransactionclicked(false);
    setanalyticsclicked(false);
    if (clickedel.className === "overview") {
      setoverviewclicked(true);
    }
    if (clickedel.className === "upload") {
      setuploadclicked(true);
    }
    if (clickedel.className === "transaction") {
      settransactionclicked(true);
    }
    if (clickedel.className === "analytics") {
      setanalyticsclicked(true);
    }
  };

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  })

  if (company.companyData && company.companyData.isAuth) {
    return (
      <div className="profile-page-container">
        <Cover_Image
          Image_Src={company.companyData.Company_Details.Company_Cover_Picture}
        />
        <ProfileImgandCompanyLogo
          companyDetails={company.companyData}
          ProfileImgSrc={company.companyData.Personal_Details.Profile_Pic}
          CompanyImgSrc={company.companyData.Company_Details.Company_Logo}
          companylogo={true}
          SP={true}
        />
        <div
          onClick={handleprofiletabs}
          id="profile-tabs-container"
          className="profile-tabs-container"
        >
          <p className="overview">Overview</p>
          <p className="upload">My Projects</p>
          <p className="transaction">Transaction Details</p>
          <p className="analytics"> Business Profile Performance & Analytics</p>
        </div>
        {overviewclicked ? (
          <EditProfileandOverview
            Profile_Details_Filled={company.companyData.Profile_Details_Filled}
          />
        ) : null}
        {uploadclicked ? <PreviouslyUploadedProjects /> : null}
        {transactionclicked ? <Transaction /> : null}
        {analyticsclicked ? <SP_Analytics /> : null}
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}

export default ProfilePage;
