import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Razorpay from "../../../utils/Razorpay/SP_subscription/Razorpay";
import "./PlanBreakDown.css";
import { BABY_BEAR_PRICE, MAMA_BEAR_PRICE, PAPA_BEAR_PRICE, TEDDY_BEAR_PRICE } from "../../../../PricingValidityConfig";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { loginCompanyGoogle } from "../../../../_actions/company_actions";

const PlanBreakDown = (props) => {
  const plan = props.plan;
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const [showModal, setshowModal] = useState(false);

  const handleLogin = (googleData) => {
    dispatch(loginCompanyGoogle(googleData))
    .then(response => {
      if (response.payload.loginSuccess) {
        window.localStorage.setItem('companyId', response.payload.companyId);
        window.location.reload();
      } else {
        alert('Check out your Account or Password again')
      }
    })
  }
  
  return (
    <div style={props.styles} className="plan-breakdown-container">
      <div className="plan-breakdown">
        <span onClick={props.handleclose} id="close" className="material-icons">
          cancel
        </span>
        <div className="plan-breakdown-details">
          <div className="plan-title-and-price">
            <p className="title">{plan.Name}</p>
            <p className="pricing" style={{ color: 'red', fontSize: '2em' }}><del>{plan.FalsePrice}</del></p>
            <p className="pricing" style={{ fontSize: '2.5em' }}>{plan.Price}</p>
          </div>
          <p className="features-title">Features :</p>
          <div className="features-container">
            <div className="features">
              {plan.Features.map((feature, index) => {
                return (
                  <p key={index} className="feature">
                    <span id="dot" class="material-icons">
                      fiber_manual_record
                    </span>
                    {feature}
                  </p>
                );
              })}
            </div>
            <div className="billing-details">
              <div className="fee-and-taxes">
                <h3 style={{ textAlign: 'center' }}>Amount Per Month</h3>
                <br />
                <div className="fee">
                  <p className="title">Regular Leads:</p>
                  <p className="amount">
                    {plan.Name === "Baby Bear" ? 29 : 
                      plan.Name === "Baby Tiger" ? 20 : 
                      plan.Name === "Startup Bear" ? 4 : 
                      plan.Name === "Startup Tiger" ? 5 : 
                      plan.Name === "Startup Tiger Prime" ? 5 : 
                      plan.Name === "Elite Tiger Prime" ? 10 : 
                      plan.Name === "Classic Tiger Prime" ? 15 : 
                      plan.Name === "Baby Tiger Prime" ? 20 : 
                      plan.Name === "Baby Bear Prime" ? 29 : 
                      plan.Name === "Elite Tiger" ? 10 : 
                      plan.Name === "Classic Tiger" ? 15 : 0}
                  </p>
                </div>
                <div className="fee">
                  <p className="title">NQA Double Replacement Leads:</p>
                  <p className="amount">
                    {plan.Name === "Baby Bear" ? 6 : 
                    plan.Name === "Baby Tiger" ? 40 : 
                    plan.Name === "Startup Bear" ? 2 : 
                    plan.Name === "Startup Tiger" ? 10 : 
                    plan.Name === "Startup Tiger Prime" ? 10 : 
                    plan.Name === "Elite Tiger Prime" ? 20 : 
                    plan.Name === "Classic Tiger Prime" ? 30 : 
                    plan.Name === "Baby Tiger Prime" ? 40 : 
                    plan.Name === "Baby Bear Prime" ? 6 : 
                    plan.Name === "Elite Tiger" ? 20 : 
                    plan.Name === "Classic Tiger" ? 30 : 0}
                  </p>
                </div>
                <div className="fee">
                  <p className="title">Total No. of Leads:</p>
                  <p className="amount">
                    {plan.Name === "Baby Bear" ? 35 : 
                      plan.Name === "Baby Tiger" ? 60 : 
                      plan.Name === "Startup Bear" ? 7 : 
                      plan.Name === "Startup Tiger" ? 15 : 
                      plan.Name === "Startup Tiger Prime" ? 15 : 
                      plan.Name === "Elite Tiger Prime" ? 30 : 
                      plan.Name === "Classic Tiger Prime" ? 45 : 
                      plan.Name === "Baby Tiger Prime" ? 60 : 
                      plan.Name === "Baby Bear Prime" ? 35 : 
                      plan.Name === "Elite Tiger" ? 30 : 
                      plan.Name === "Classic Tiger" ? 45 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'red'}}>Original Cost Per Regular Lead:</p>
                  <p className="amount" style={{color: 'red'}}><del>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 700 : 
                      plan.Name === "Baby Tiger" ? 2296 : 
                      plan.Name === "Startup Bear" ? 7 : 
                      plan.Name === "Startup Tiger" ? 2296 : 
                      plan.Name === "Startup Tiger Prime" ? 2296 : 
                      plan.Name === "Elite Tiger Prime" ? 2296 : 
                      plan.Name === "Classic Tiger Prime" ? 2296 : 
                      plan.Name === "Baby Tiger Prime" ? 2296 : 
                      plan.Name === "Baby Bear Prime" ? 700 : 
                      plan.Name === "Elite Tiger" ? 2296 : 
                      plan.Name === "Classic Tiger" ? 2296 : 0}
                    </del></p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: '#1F80E0'}}>Discounted Cost Per Regular Lead:</p>
                  <p className="amount" style={{color: '#1F80E0'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 250 : 
                      plan.Name === "Baby Tiger" ? 674 : 
                      plan.Name === "Startup Bear" ? 7 : 
                      plan.Name === "Startup Tiger" ? 674 : 
                      plan.Name === "Startup Tiger Prime" ? 599 : 
                      plan.Name === "Elite Tiger Prime" ? 599 : 
                      plan.Name === "Classic Tiger Prime" ? 599 : 
                      plan.Name === "Baby Tiger Prime" ? 599 : 
                      plan.Name === "Baby Bear Prime" ? 175 : 
                      plan.Name === "Elite Tiger" ? 674 : 
                      plan.Name === "Classic Tiger" ? 674 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title">Platform Service Ops & Adjustment Fee:</p>
                  <p className="amount">₹&nbsp;
                    {plan.Name === "Baby Bear" ? -751 : 
                    plan.Name === "Baby Tiger" ? 519 : 
                    plan.Name === "Startup Bear" ? 99 : 
                    plan.Name === "Startup Tiger" ? 129 : 
                    plan.Name === "Startup Tiger Prime" ? 204 : 
                    plan.Name === "Elite Tiger Prime" ? 709 : 
                    plan.Name === "Classic Tiger Prime" ? 1214 : 
                    plan.Name === "Baby Tiger Prime" ? 1719 : 
                    plan.Name === "Baby Bear Prime" ? -76 : 
                    plan.Name === "Elite Tiger" ? 259 : 
                    plan.Name === "Classic Tiger" ? 389 : 0}
                  </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>NQA Double Replacement Lead Savings:</p>
                  <p className="amount" style={{color: 'green'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? -751 : 
                      plan.Name === "Baby Tiger" ? 13480 : 
                      plan.Name === "Startup Bear" ? 99 : 
                      plan.Name === "Startup Tiger" ? 3370 : 
                      plan.Name === "Startup Tiger Prime" ? 2995 : 
                      plan.Name === "Elite Tiger Prime" ? 5990 : 
                      plan.Name === "Classic Tiger Prime" ? 8985 : 
                      plan.Name === "Baby Tiger Prime" ? 1980 : 
                      plan.Name === "Baby Bear Prime" ? -76 : 
                      plan.Name === "Elite Tiger" ? 6740 : 
                      plan.Name === "Classic Tiger" ? 10110 : 0}
                    </p>
                </div>
                <br />
                <h3 style={{ textAlign: 'center' }}>Duration</h3>
                <br />
                <div className="fee">
                  <p className="title">No. of Days:</p>
                  <p className="amount">
                    {plan.Name === "Baby Bear" ? 30 : 
                      plan.Name === "Papa Bear" ? 3 : 
                      plan.Name === "Mama Bear" ? 6 : 
                      plan.Name === "Teddy Bear" ? 12 : 
                      plan.Name === "Baby Tiger" ? 60 : 
                      plan.Name === "Startup Bear" ? 7 : 
                      plan.Name === "Startup Tiger" ? 15 : 
                      plan.Name === "Startup Tiger Prime" ? 15 : 
                      plan.Name === "Elite Tiger Prime" ? 30 : 
                      plan.Name === "Classic Tiger Prime" ? 45 : 
                      plan.Name === "Baby Tiger Prime" ? 60 : 
                      plan.Name === "Baby Bear Prime" ? 30 : 
                      plan.Name === "Elite Tiger" ? 30 : 
                      plan.Name === "Classic Tiger" ? 45 : 0}
                    </p>
                </div>
                <br />
                <h3 style={{ textAlign: 'center' }}>Amount To Be Paid</h3>
                <br />
                <div className="fee">
                  <p className="title" style={{color: 'red'}}>Original Amount (Exclusive of G.S.T.)</p>
                  <p className="amount" style={{color: 'red'}}><del>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 12998 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 27998 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 6998 : 
                      plan.Name === "Startup Tiger Prime" ? 6998 : 
                      plan.Name === "Elite Tiger Prime" ? 13998 : 
                      plan.Name === "Classic Tiger Prime" ? 20998 : 
                      plan.Name === "Baby Tiger Prime" ? 27998 : 
                      plan.Name === "Baby Bear Prime" ? 12998 : 
                      plan.Name === "Elite Tiger" ? 13998 : 
                      plan.Name === "Classic Tiger" ? 20998 : 0}
                  </del></p>
                </div>
                <div className="fee">
                  <p className="title">Discounted Amount (Exclusive of G.S.T.)</p>
                  <p className="amount">₹&nbsp;
                    {plan.Name === "Baby Bear" ? 6499 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 13999 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 3499 : 
                      plan.Name === "Startup Tiger Prime" ? 3199 : 
                      plan.Name === "Elite Tiger Prime" ? 6699 : 
                      plan.Name === "Classic Tiger Prime" ? 10199 : 
                      plan.Name === "Baby Tiger Prime" ? 13699 : 
                      plan.Name === "Baby Bear Prime" ? 4999 : 
                      plan.Name === "Elite Tiger" ? 6999 : 
                      plan.Name === "Classic Tiger" ? 10499 : 0}
                  </p>
                </div>
                <div className="fee">
                  <p className="title">G.S.T. Amount (18%)</p>
                  <p className="amount">₹&nbsp;
                    {plan.Name === "Baby Bear" ? 0 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 0 : 
                      plan.Name === "Startup Bear" ? 0 : 
                      plan.Name === "Startup Tiger" ? 0 : 
                      plan.Name === "Startup Tiger Prime" ? 0 : 
                      plan.Name === "Elite Tiger Prime" ? 0 : 
                      plan.Name === "Classic Tiger Prime" ? 0 : 
                      plan.Name === "Baby Tiger Prime" ? 0 : 
                      plan.Name === "Baby Bear Prime" ? 0 : 
                      plan.Name === "Elite Tiger" ? 0 : 
                      plan.Name === "Classic Tiger" ? 0 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: '#1F80E0'}}>Discounted Amount (Inclusive of G.S.T.)</p>
                  <p className="amount" style={{color: '#1F80E0'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 6499 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 13999 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 3499 : 
                      plan.Name === "Startup Tiger Prime" ? 3199 : 
                      plan.Name === "Elite Tiger Prime" ? 6699 : 
                      plan.Name === "Classic Tiger Prime" ? 10199 : 
                      plan.Name === "Baby Tiger Prime" ? 13699 : 
                      plan.Name === "Baby Bear Prime" ? 4999 : 
                      plan.Name === "Elite Tiger" ? 6999 : 
                      plan.Name === "Classic Tiger" ? 10499 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>Non-Prime Member Savings</p>
                  <p className="amount" style={{color: 'green'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 6499 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 13999 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 3499 : 
                      plan.Name === "Startup Tiger Prime" ? 3499 : 
                      plan.Name === "Elite Tiger Prime" ? 6999 : 
                      plan.Name === "Classic Tiger Prime" ? 10499 : 
                      plan.Name === "Baby Tiger Prime" ? 13999 : 
                      plan.Name === "Baby Bear Prime" ? 6499 : 
                      plan.Name === "Elite Tiger" ? 6999 : 
                      plan.Name === "Classic Tiger" ? 10499 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>NebourHood Prime S.P. Savings</p>
                  <p className="amount" style={{color: 'green'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 0 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 0 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 0 : 
                      plan.Name === "Startup Tiger Prime" ? 300 : 
                      plan.Name === "Elite Tiger Prime" ? 300 : 
                      plan.Name === "Classic Tiger Prime" ? 300 : 
                      plan.Name === "Baby Tiger Prime" ? 300 :
                      plan.Name === "Baby Bear Prime" ? 1500 : 
                      plan.Name === "Elite Tiger" ? 0 : 
                      plan.Name === "Classic Tiger" ? 0 : 0}
                    </p>
                </div>
                <div className="fee">
                  <p className="title" style={{color: 'green'}}>Total Savings</p>
                  <p className="amount" style={{color: 'green'}}>₹&nbsp;
                    {plan.Name === "Baby Bear" ? 6499 : 
                      plan.Name === "Papa Bear" ? PAPA_BEAR_PRICE : 
                      plan.Name === "Mama Bear" ? MAMA_BEAR_PRICE : 
                      plan.Name === "Teddy Bear" ? TEDDY_BEAR_PRICE : 
                      plan.Name === "Baby Tiger" ? 13999 : 
                      plan.Name === "Startup Bear" ? 799 : 
                      plan.Name === "Startup Tiger" ? 3499 : 
                      plan.Name === "Startup Tiger Prime" ? 3799 : 
                      plan.Name === "Elite Tiger Prime" ? 7299 : 
                      plan.Name === "Classic Tiger Prime" ? 10799 : 
                      plan.Name === "Baby Tiger Prime" ? 14299 : 
                      plan.Name === "Baby Bear Prime" ? 7999 : 
                      plan.Name === "Elite Tiger" ? 6999 : 
                      plan.Name === "Classic Tiger" ? 10499 : 0}
                    </p>
                </div>
                <br />
                <div>
                {company.companyData && company.companyData.isAuth ? 
                <>
                  {company.companyData && company.companyData.isAuth && company.companyData.Membership ? 
                    <p style={{ fontSize: '1em' }}>Thank you for being a valued NebourHood Prime S.P. Member! If you haven’t already, you can click here to Renew your Prime Membership Now!</p>
                    : 
                    <p style={{ fontSize: '1em' }}><span style={{ color: 'green' }}>You can save an additional Rs. 300 on this order</span> & unlock exclusive benefits and rewards by becoming a NebourHood Prime S.P. Member. Click here to Join Now!</p>
                  }
                </> 
                : 
                null
                }
                </div>
              </div>
              <br />
              {company.companyData && company.companyData.isAuth ? 
              <button className="proceed">
                <Razorpay
                  Name={plan.Name}
                  Price={plan.Price}
                  Validity={plan.Validity}
                />
              </button>
              :
              <button className="proceed" onClick={()=>setshowModal(true)}>
                Proceed To Pay
              </button>
              }
            </div>
          </div>
        </div>
        {showModal ? 
          <div className='sign-in-required-modall'>
            <div>
              <h1>Kindly login to view the Lead Plan details!</h1>
            </div>
            <div style={{border: '2px solid lightgrey'}}>
              <GoogleLogin
                  clientId="737789981008-3kqljeg4a44qdbbiib27e9d8rtr8m34o.apps.googleusercontent.com"
                  buttonText="Log in with Google"
                  onSuccess={handleLogin}
                  // onFailure={handleLoginFail}
                  cookiePolicy={'single_host_origin'}
                    />
              </div>
            <span className="close-icon" onClick={()=>setshowModal(false)} style={{ backgroundColor: '#fe5631', color: 'white', fontSize: '1.1em', padding: '5px', border: 'none', borderRadius: '5px', letterSpacing: '2px' }} >
              Close
            </span>
          </div>
        : null}
      </div>
    </div>
  );
};

export default withRouter(PlanBreakDown);
