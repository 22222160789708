import React from "react";
import "./Utils.css";

export default function ListandDetails(props) {

  return (
    <div
      style={{
        display: "none",
        opacity: 0,
        position: "relative",
        top: "-100px",
      }}
      id={props.id}
      className="list-and-details-table"
    >
      <div className="list-and-details-table-title">
        <p>{props.id}</p>
      </div>
      <table>
        <tr>
          <td className="heading" rowSpan={2}>
            Sl. No.{" "}
          </td>
          <td className="heading" colSpan={2}>
            Service Professional{" "}
          </td>
          <td className="heading" rowSpan={2}>
            Profession
          </td>
          <td className="heading" rowSpan={2}>
            Company Name{" "}
          </td>
          <td className="heading" rowSpan={2}>
            {props.dataandtimeheading}
          </td>
        </tr>
        <tr>
          <td className="subheading">Profile Number</td>
          <td className="subheading">Name</td>
        </tr>
        {props.ProfileDatas.map((listanddetail, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                  {props.id === "Service Professional Profile Visits Received" ? 
                    <a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${listanddetail.Visited_By}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                    View Details
                    </a>
                  : 
                    <a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${listanddetail.Visited_To}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                    View Details
                    </a>
                    }
                </td>
              <td>
                  {props.id === "Service Professional Profile Visits Received" ? 
                    <a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${listanddetail.Visited_By}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                    {listanddetail.Name === "" ? " - " : listanddetail.Name}
                    </a>
                  : 
                    <a href={`/AllServiceProfessionalPage/SP/Profile/OtherView/${listanddetail.Visited_To}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                    {listanddetail.Name === "" ? " - " : listanddetail.Name}
                    </a>
                    }
                </td>
              <td>{listanddetail.Profession === "" ? " - " : listanddetail.Profession}</td>
              <td>{listanddetail.Company_Name === '' ? " - " : listanddetail.Company_Name}</td>
              <td>{listanddetail.Visited_Date} - {listanddetail.Visited_Time}</td>
            </tr>
          );
        })}{" "}
      </table>
    </div>
  );
}
