import React from 'react'
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ServiceProfessionalPage from '../ServiceProfessionalPage';
import SignInRequired from '../SignInRequired/SignInRequired';

function VerificationBeforeDetails() {

    const company = useSelector((state) => state.company);
    const user = useSelector((state) => state.user);
    
    if(company.companyData && company.companyData.isAuth){
        return (
            <ServiceProfessionalPage />
        )
    } else if(user.userData && user.userData.isAuth){
        if(user.userData && user.userData.isAuth && !user.userData.Profile_Details_Filled){
            return (
                <div>
                    <SignInRequired title={"Please Complete Your Profile Details"} directto={"/PropertyOwner/postpropertyrequirements"} ButtonTitle={"Complete Your Profile"} ShowButton={true} />
                    <ServiceProfessionalPage style={{ filter: "blur(10px)", pointerEvents: "none" }} />
                </div>
        )
    } else if(user.userData && user.userData.isAuth && user.userData.First_Property){
        return (
            <div>
                <SignInRequired title={"Please Post Your First Property"} directto={"/PropertyOwner/postpropertyrequirements"} ButtonTitle={"Post Your First Property Requirement"} ShowButton={true} />
                <ServiceProfessionalPage style={{ filter: "blur(10px)", pointerEvents: "none" }} />
            </div>
        )
    } else {
        return (
            <div>
                <ServiceProfessionalPage  />
            </div>
        )
    }
    }  else {
        return (
            <div>
                <SignInRequired title={"Kindly Login to view the Service Professional Profiles and get in touch with them"} directto={"/"} ShowButton={false} />
                <ServiceProfessionalPage style={{ filter: "blur(10px)", pointerEvents: "none" }} />
            </div>
        )
    }
}

export default withRouter(VerificationBeforeDetails);