import React from "react";
import { useLayoutEffect } from "react";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./RefundReplacement.css";

const RefundReplacement = () => {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    })

  return (
    <div className="refund-page-container">
      <PageBanner title="Replacement, Refund & Cancellation Policies" />
      <p className="title">
        <b>REPLACEMENT, REFUND & CANCELLATION POLICIES</b>
      </p>
      <div className="container">
        <p className="sub-title">
          <b>REPLACEMENT POLICY</b>
        </p>
        <ul className="list">
          <br/>
          <li className="header">
            <b> For Service Professionals:</b>
            <ul className="sub-list">
              <li>
              We have Single Property Requirement Leads, where: 
                <ul className="sub-list-2">
                  <li>
                  1 Property Requirement Lead can be purchased by 3 Service Professionals.
                  </li>
                </ul>
              </li>
              <li>
              We have the following Subscription Plan Series: 
                <ul className="sub-list-2">
                  <li>
                  The Grizzly Bear Plan Series: Here, 1 Property Requirement Lead can be selected by 3 Service Professionals, after purchasing any plan under this series.
                  </li>
                  <li>
                  The Golden Wolf Plan Series: Here, 1 Property Requirement Lead can be selected by 2 Service Professionals, after purchasing any plan under this series                  
                  </li>
                  <li>
                  The Roaring Tiger Plan Series: Here, 1 Property Requirement Lead can be selected by 1 Service Professional, after purchasing any plan under this series
                  </li>
                </ul>
              </li>
              <li>
              The features of all the plans under the above – mentioned subscription plan series can be viewed on the S.P. Lead Plans page for service professionals.
              </li>
              <li>
              We have the NebourHood Prime Service Professional (S.P.) Membership Subscription Plans where service professionals can:
                <ul className="sub-list-2">
                  <li>
                    Access daily property requirement leads,
                  </li>
                  <li>
                    Enjoy additional exclusive discounts on all Property Requirement Leads & Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series,
                  </li>
                  <li>
                    Participate in various community groups for networking, personal and professional growth,
                  </li>
                  <li>
                    Receive official announcements and updates from NebourHood, etc.
                  </li>
                </ul>
              </li>
              <li>
                The rest of the features of the NebourHood Prime S.P. Membership Subscription Plans can be viewed on the S.P. Prime Membership page for service professionals.
              </li>
              <li>
                The NebourHood Prime S.P. Membership is non-transferable and must not be shared with others. Each membership is linked to a single account holder.
              </li>
              <li>
                During the NebourHood Webinar, all the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series would be renamed and offered at an additional discounted price, with all other features remaining unchanged. These changes would only be available during the webinar payment period, after which the additional discounted pricing and plan names would revert to their regular discounted pricing and original names.
              </li>
              <li>
                The property requirement leads are posted by the property owners themselves after they agree to the property owner declaration, which ensures authenticity and relevance, providing service professionals with high-quality leads to maximize their chances of conversion.
              </li>
              <li>
                Service professionals would receive daily notifications or alerts for new property requirement leads matching their criteria, enabling them to act promptly and increase their opportunities for business.
              </li>
              <li>
                Service professionals can either:
                <ul className="sub-list-2">
                  <li>
                    Purchase the Single property requirement leads at a discounted price individually or
                  </li>
                  <li>
                    Select the property requirement leads at a discounted price, after purchasing one of the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series.
                  </li>
                </ul>
              </li>
              <li>
                For more benefits, service professionals can purchase one of the NebourHood Prime S.P. Membership Subscription Plans and:
                <ul className="sub-list-2">
                  <li>
                    Purchase the Single property requirement leads at an additional discounted price individually or
                  </li>
                  <li>
                    Select the property requirement leads at an additional discounted price, after purchasing one of the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series.
                  </li>
                </ul>
              </li>
              <li>
                1 Posted Property requirement lead can be: 
                <ul className="sub-list-2">
                  <li>
                    Purchased by a maximum of 3 service professionals if they purchase the Single property requirement leads individually, after which the validity of the property requirement lead would expire.
                  </li>
                  <li>
                    Selected by a maximum of 3 service professionals if they purchase any plan under the Grizzly Bear Plan Series, after which the validity of the property requirement lead would expire.
                  </li>
                  <li>
                    Selected by a maximum of 2 service professionals if they purchase any plan under the Golden Wolf Plan Series, after which the validity of the property requirement lead would expire.
                  </li>
                  <li>
                    Selected by a maximum of 1 service professionals if they purchase any plan under the Roaring Tiger Plan Series, after which the validity of the property requirement lead would expire. 
                  </li>
                </ul>
              </li>
              <li>
                After the Renewal of 1 Property requirement lead, it can be:
                <ul className="sub-list-2">
                  <li>
                    Purchased by a maximum of 3 service professionals if they purchase the Single property requirement leads individually, after which the validity of the property requirement lead would expire.
                  </li>
                  <li>
                    Selected by a maximum of 3 service professionals if they purchase any plan under the Grizzly Bear Plan Series, after which the validity of the property requirement lead would expire. 
                  </li>
                  <li>
                    Selected by a maximum of 2 service professionals if they purchase any plan under the Golden Wolf Plan Series, after which the validity of the property requirement lead would expire.
                  </li>
                  <li>
                    Selected by a maximum of 1 service professionals if they purchase any plan under the Roaring Tiger Plan Series, after which the validity of the property requirement lead would expire.
                  </li>
                </ul>
              </li>
              <li>
                All the 
                <ul className="sub-list-2">
                  <li>
                    Property Requirement Leads, 
                  </li>
                  <li>
                    Replacement Period Validity,
                  </li>
                  <li>
                    Grizzly Bear, Golden Wolf and Roaring Tiger Subscription Plans and 
                  </li>
                  <li>
                    NebourHood Prime S.P. Membership Subscription Plans would have different validity periods.
                  </li>
                </ul>
              </li>
              <li>
              The validity period of the Property Requirement Leads would depend on the following:
                <ul className="sub-list-2">
                  <li>
                    The duration of their respective days (from the time that they were posted / renewed to the time that the property requirement lead validity time period exhausts) or
                  </li>
                  <li>
                    Till the time that they are sold out, whichever comes first. 
                  </li>
                </ul>
              </li>
              <li>
              The validity period of the Replacement Period Validity would depend on the following:
                <ul className="sub-list-2">
                  <li>
                    The duration of their respective days (from the time that it was approved & got activated automatically / completed the ‘Pending Activation’ stage & got activated automatically, to the time that the replacement period validity time period exhausts) or
                  </li>
                  <li>
                    Till the time that the total number of replacement property requirement leads are exhausted / used up, whichever comes first. 
                  </li>
                </ul>
              </li>


              <li>
                The validity period of the Grizzly Bear, Golden Wolf and Roaring Tiger Subscription Plans would depend on the following:
                <ul className="sub-list-2">
                  <li>
                    The duration of their respective days (from the time that they were purchased & got activated automatically / completed the ‘Pending Activation’ stage & got activated automatically, to the time that the subscription plan validity time period exhausts) or
                  </li>
                  <li>
                    Till the time that the total number of property requirement leads within the subscription plan are sold out, whichever comes first.
                  </li>
                </ul>
              </li>
              
              <li>
                The validity period of the NebourHood Prime S.P. Membership Subscription Plans would depend on the following:
                <ul className="sub-list-2">
                  <li>
                    The duration of their respective days (from the time that they were purchased & got activated automatically / completed the ‘Pending Activation’ stage & got activated automatically, to the time that the membership subscription plan validity time period exhausts).
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Plan Status: After 
                <ul className="sub-list-2">
                  <li>
                    A subscription plan is purchased, or
                  </li>
                  <li>
                    An NQA Double Replacement request is approved for a Single Property Requirement Lead or for a Property Requirement Lead of any Lead Plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series, then
                  </li>
                </ul>
              </li>
              <br />
              <li>
                It can be under any 1 of the following stages:
                <br />
                <ul className="sub-list-2">
                  <li>
                  Active: The subscribed plan is active.
                  </li>
                  <li>
                  Active: The approved NQA Double Replacement request is active.
                  </li>
                  <br />
                  <li>
                  Expired: The subscribed plan has expired.
                  </li>
                  <li>
                  Expired: The approved NQA Double Replacement request has expired.
                  </li>
                  <br />
                  <li>
                  Pending Activation: The next subscribed plan / approved NQA Double Replacement request would automatically activate after the current active plan / approved NQA Double Replacement request expires. This applies in both directions, meaning that if either a plan or an approved NQA Double Replacement request expires, the other can become active.
                  </li>
                  <br />
                  <li>
                  If there is an ‘Active’ plan and a second plan is purchased, the second plan would remain in the ‘Pending Activation’ stage and proceed to the next stage only after the plan before it has completed the stage that it was in. This applies in both directions, meaning that if a plan is active, any subsequent plan or approved NQA Double Replacement request will follow the same sequence.
                  </li>
                  <br />
                  <li>
                  If there is an ‘Active’ approved NQA Double Replacement request and a second NQA Double Replacement request is approved, the second approved NQA Double Replacement request would remain in the ‘Pending Activation’ stage and proceed to the next stage only after the approved NQA Double Replacement request before it has completed the stage that it was in. This applies in both directions, meaning that if an approved NQA Double Replacement request is active, any subsequent approved NQA Double Replacement request or plan will follow the same sequence.
                  </li>
                  <li>
                  If there is an ‘Active’ plan or an approved NQA Double Replacement request, the sequence of activation applies in both directions, meaning that whichever is active first (the plan or the approved NQA Double Replacement request), the subsequent plan or approved NQA Double Replacement request will remain in the ‘Pending Activation’ until the first plan or approved NQA Double Replacement request has completed its active stage.
                  </li>
                  <br />
                  <li>
                  Which means, after the validity period of the first plan expires, the second plan would get activated automatically.
                  </li>
                  <li>
                  Which means, after the validity period of the first approved NQA Double Replacement request expires, the second approved NQA Double Replacement request would get activated automatically.
                  </li>
                  <li>
                  This automatic activation procedure applies in both directions, whether it's plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan.
                  </li>
                  <li>
                  For Example: If a service professional subscribes to Plan ‘A’ and then subscribes again to Plan ‘B’, while the initial Plan ‘A’ is active, then the subsequent Plan ‘B’ would get activated only after the validity period of the initial Plan ‘A’ exhausts. This applies in both directions, meaning whether Plan ‘A’ or Plan ‘B’ is active first, the other will only activate after the currently active plan's validity period ends.
                  </li>
                  <li>
                  For Example: If a service professional initiates and receives an approval of an NQA Double Replacement request ‘A’ and then initiates and receives an approval of another plan’s NQA Double Replacement request ‘B’, while the initial approved NQA Double Replacement request ‘A’ is active, then the subsequent approved NQA Double Replacement request ‘B’ would get activated only after the validity period of the initial approved NQA Double Replacement request ‘A’ exhausts. This applies in both directions, meaning whichever approved NQA Double Replacement request is active first, the other approved NQA Double Replacement request will only activate after the currently active approved NQA Double Replacement request’s validity period ends.
                  </li>
                  <li>
                  This sequence also applies between a plan and an approved NQA Double Replacement request, or vice versa. For instance, if Plan ‘A’ is active and an NQA Double Replacement request ‘B’ is approved, the approved NQA Double Replacement request ‘B’ will only activate after the validity period of Plan ‘A’ expires, and vice versa. Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  For Example: If a service professional subscribes to the Startup Bear plan and then subscribes again to a different plan Classic Wolf, while the initial Startup Bear plan is active, then the subsequent Classic Wolf plan would get activated only after the validity period of the initial Startup Bear plan exhausts. 
                  </li>
                  <li>
                  For Example: If a service professional initiates and receives an approval of the Startup Bear NQA Double Replacement request and then initiates and receives an approval of another plan Classic Wolf NQA Double Replacement request, while the initial Startup Bear approved NQA Double Replacement request is active, then the subsequent Classic Wolf approved NQA Double Replacement request would get activated only after the validity period of the initial Startup Bear approved NQA Double Replacement request exhausts. 
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  Similarly, if a service professional subscribes to Plan ‘A’ and then subscribes again to the same Plan ‘A’, while the initial Plan ‘A’ is active, then the subsequent Plan ‘A’ would get activated only after the validity period of the initial Plan ‘A’ exhausts.
                  </li>
                  <li>
                  Similarly, if a service professional initiates and receives an approval of an Approved NQA Double Replacement request ‘A’ and then initiates and receives an approval of the same plan’s Approved NQA Double Replacement request ‘A’, while the initial Approved NQA Double Replacement request ‘A’ is active, then the subsequent Approved NQA Double Replacement request ‘A’ would get activated only after the validity period of the initial Approved NQA Double Replacement request ‘A’ exhausts.
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  Similarly, if a service professional subscribes to the Elite Tiger plan and then subscribes again to the same Elite Tiger plan, while the initial Elite Tiger plan is active, then the subsequent Elite Tiger plan would get activated only after the validity period of the initial Elite Tiger plan exhausts. 
                  </li>
                  <li>
                  Similarly, if a service professional initiates and receives an approval of the Elite Tiger Approved NQA Double Replacement request and then initiates and receives an approval of the same plan Elite Tiger Approved NQA Double Replacement request, while the initial Elite Tiger Approved NQA Double Replacement request is active, then the subsequent Elite Tiger Approved NQA Double Replacement request would get activated only after the validity period of the initial Elite Tiger Approved NQA Double Replacement request exhausts. 
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  If there is an ‘Active’ plan, a second plan in the ‘Pending Activation’ stage, and a third plan is purchased, then the third plan would also remain in the ‘Pending Activation’ stage in queue, behind the second plan, & proceed to the next stage only after the second plan has completed the stage that it was in. 
                  </li>
                  <li>
                  If there is an ‘Active’ Approved NQA Double Replacement request, a second Approved NQA Double Replacement request in the ‘Pending Activation’ stage, and a third Approved NQA Double Replacement request is approved, then the third Approved NQA Double Replacement request would also remain in the ‘Pending Activation’ stage in queue, behind the second Approved NQA Double Replacement request, & proceed to the next stage only after the second Approved NQA Double Replacement request has completed the stage that it was in. 
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  Which means, after the validity period of the first plan expires, the second plan would get activated automatically, & the third plan would remain in the ‘Pending Activation’ stage, & would get activated automatically after the validity period of the second plan expires. 
                  </li>
                  <li>
                  Which means, after the validity period of the first Approved NQA Double Replacement request expires, the second Approved NQA Double Replacement request would get activated automatically, & the third Approved NQA Double Replacement request would remain in the ‘Pending Activation’ stage, & would get activated automatically after the validity period of the second Approved NQA Double Replacement request expires. 
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                  <br />
                  <li>
                  So, only 1 plan would be in the ‘Active’ stage and the rest of the consecutive plans would remain in the ‘Pending Activation’ stages in form of a queue, & proceed to the next stage only after the plan before it has completed the stage that it was in. This applies to any or all plans that are purchased.
                  </li>
                  <li>
                  So, only 1 Approved NQA Double Replacement request would be in the ‘Active’ stage and the rest of the consecutive Approved NQA Double Replacement requests would remain in the ‘Pending Activation’ stages in form of a queue, & proceed to the next stage only after the Approved NQA Double Replacement request before it has completed the stage that it was in. This applies to any or all Approved NQA Double Replacement requests that are approved.
                  </li>
                  <li>
                  Whether it’s plan to plan, approved NQA Double Replacement request to approved NQA Double Replacement request, plan to approved NQA Double Replacement request, or approved NQA Double Replacement request to plan, the activation follows the same sequence.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                The Plan Status is applicable to the following:
                <ul className="sub-list-2">
                  <li>
                  All the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series    
                  </li>
                  <li>
                  All the plans under the NebourHood Prime S.P. Membership Subscription Plan series
                  </li>
                  <li>
                  All the Approved NQA Double Replacement requests for the purchased Pre-Prime Single Property Requirement Leads and Post-Prime Single Property Requirement Leads
                  </li>
                  <li>
                  All the Approved NQA Double Replacement requests under the Pre-Prime (Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series) and the Post-Prime (Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series).
                  </li>
                </ul>
                </li>
                <br />
              <li>
                When a NebourHood Prime S.P. Membership Subscription Plan is active: 
                <ul className="sub-list-2">
                  <li>
                    All the Single Property Requirement Leads would be offered at an additional discounted price, 
                  </li>
                  <li>
                    All the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series would be renamed and offered at an additional discounted price, with all other features remaining unchanged. 
                  </li>
                </ul>
              </li>
              <br />
              <li>
                When a NebourHood Prime S.P. Membership Subscription Plan expires: 
                <ul className="sub-list-2">
                  <li>
                    All the Single Property Requirement Leads would revert to their regular discounted pricing, 
                  </li>
                  <li>
                    All the plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series would revert to their regular discounted pricing and original names, with all other features remaining unchanged. 
                  </li>
                </ul>
              </li>

              <li>
                After purchasing 1 Single Property Requirement Lead / selecting 1 Property Requirement Lead after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series, if a service professional can't work on it for any reason whatsoever, they can request for a No Questions Asked (NQA) Double Replacement where they can select a maximum of 2 replacement leads by following these steps:
                <ul className="sub-list-2">
                  <li>
                    Log in and navigate to My Profile ➡️ Transaction Details ➡️ My Leads ➡️ Replacement Request and then click on the Initiate button.
                  </li>
                  <li>
                    A NQA Double Replacement request can be initiated only within 120 days of: 
                    <ul className="sub-list-2">
                      <li>
                        Purchasing 1 Single Property Requirement Lead or
                      </li>
                      <li>
                        Selecting 1 Property Requirement Lead after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series. 
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    If multiple: 
                    <ul className="sub-list-2">
                      <li>
                        Single Property Requirement Leads are purchased or
                      </li>
                      <li>
                        Property Requirement Leads are selected after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series 
                      </li>
                      <li>
                        At the same time, then the NQA Double Replacement request can still be initiated only within 120 days of the:
                      </li>
                      <li>
                        Purchase of the Single Property Requirement Leads or
                      </li>
                      <li>
                        Selection of the Property Requirement Leads after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    If multiple:
                    <ul className="sub-list-2">
                      <li>
                        Single Property Requirement Leads are purchased or
                      </li>
                      <li>
                        Property Requirement Leads are selected after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series 
                      </li>
                      <li>
                        At different times, then the 120-day NQA Double Replacement request window applies to each:
                      </li>
                      <li>
                        Single Property Requirement Lead from the time of its purchase or
                      </li>
                      <li>
                        Property Requirement Leads that are selected after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series from the time of its selection.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Our team would review the details provided, and the service professional would receive the notification of the approval of their NQA Double Replacement request on their NebourHood registered email within 3 working days / 72 hours from the initiation. 
                  </li>
                  <br />
                  <li>
                    If approved, the service professional would be able to select 2 property requirement leads on NebourHood within 120 days of the approval notification.
                  </li>
                  <br />
                  <li>
                    Only 1 NQA Double Replacement request can be initiated for:
                  <ul className="sub-list-2">
                    <li>
                      1 purchased Single Property Requirement Lead or 
                    </li>
                    <li>
                      1 selected Property Requirement Lead after purchasing any plan under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series,
                    </li>
                    <li>
                      Which would result in the approval of 2 Property Requirement Leads as replacement leads.
                    </li>
                  </ul>
                  </li>
                  <br />
                  <li>
                    A NQA Double Replacement request cannot be initiated for an already replaced Single Property Requirement Lead(s) / Property Requirement Lead(s).
                  </li>
                </ul>
              </li>
              <br />
              <li>
                The No Questions Asked (NQA) Double Replacement policy is NebourHood's commitment to providing value to service professionals even if their initial property requirement leads do not materialize into successful deals.
              </li>
              <br />
              <li>
                The conversion / closure of the property requirement leads into a successful deal solely depends on the caliber of the service professional. This means that the responsibility for success or failure is completely dependent on the service professional's abilities and not on NebourHood's services.
              </li>
              <br />
              <li>
                All the decisions regarding the property requirement leads are owned by the property owners themselves. This means that NebourHood merely facilitates the connection between property owners and service professionals, but does not dictate the outcomes of their interactions.
              </li>
              <br />
              <li>
                NebourHood invests resources in curating and maintaining the platform to ensure the delivery of high-quality property requirement leads to service professionals. 
              </li>
              <br />
              <li>
                Our responsive customer support team is here to address any queries or concerns service professionals may have regarding their subscription plans, lead purchase / selection, technical assistance, or any other related issue.
              </li>

              {/* <li>
                <ul className="sub-list-2">
                  <li>
                     
                  </li>
                  <li>
                  </li>
                </ul>
              </li> */}

            </ul>
          </li>
        </ul>
      </div>

      <div className="container">
        <p className="sub-title">
          <b>REFUND POLICY</b>
        </p>
        <ul className="list">
          <br />
          <li className="header">
            <b>For Service Professionals</b>
            <ul className="sub-list">
              <li>
                All payments made to NebourHood at any stage are non-refundable.  
              </li>
              <li>
                The conversion / closure of the property requirement leads into a successful deal solely depends on the caliber of the service professional. This means that the responsibility for success or failure is completely dependent on the service professional's abilities and not on NebourHood's services.
              </li>
              <li>
                We do not have an option of refund for the service professionals if they purchase any Single Property Requirement Leads, any plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series or any plans under the NebourHood Prime S.P. Membership Subscription Plan series because:
              <ul className="sub-list-2">
                <li>
                  With every Single Property Requirement Leads or any plans under the Grizzly Bear, Golden Wolf and Roaring Tiger subscription plan series, the service professionals have an option to apply for the NQA Double Replacement leads. This is NebourHood's commitment to providing value to service professionals even if their initial property requirement leads do not materialize into successful deals.
                </li>
                  <li>
                    All the decisions regarding the property requirement leads are owned by the property owners themselves. This means that NebourHood merely facilitates the connection between property owners and service professionals, but does not dictate the outcomes of their interactions.
                  </li>
                  <li>
                    NebourHood invests resources in curating and maintaining the platform to ensure the delivery of high-quality property requirement leads to service professionals. Refunding payments would undermine this investment and could potentially lead to misuse of the platform.
                  </li>
                  <li>
                    Immediate Access to Extensive Benefits and Exclusive Content: Upon subscribing to the NebourHood Prime S.P. Membership Subscription Plan series, members gain immediate access to a wide range of exclusive benefits such as access to national and state-wise communities and events, early updates on property requirements, significant discounts, performance metrics, exclusive job postings, networking opportunities, unlimited uploads for projects and high-quality images, plus early access to future updates. These resources and privileges provide intrinsic value from the moment of subscription, making refunds impractical as these benefits are immediately utilized and cannot be reclaimed.
                  </li>
                </ul>
              </li>
              <li>
                Our responsive customer support team is here to address any queries or concerns service professionals may have regarding their subscription plans, lead purchase / selection, technical assistance, or any other related issue.
              </li>
            </ul>
          </li>
          <br />
          <li className="header">
            <b>For Property Owners:</b>
            <ul className="sub-list">
              <li>
                The first property requirement posting is free.
              </li>
              <li>
                All property requirement postings (after the first property
                requirement posting) would be chargeable.
              </li>
              <li>
                All property requirements can be renewed.
              </li>
              <li>
                Every property requirement renewal would be chargeable.
              </li>
              <li>
                Detailed features of the above – mentioned points can be viewed
                on the Pricing page for property owners.
              </li>
              <li>
                All payments made to NebourHood at any stage are
                non-refundable.
              </li>
              <li>
              We do not have an option of refund for the property owners, as all the decisions regarding the property requirements are owned by the property owners themselves. This means that NebourHood merely facilitates the connection between property owners and service professionals, but does not dictate the outcomes of their interactions.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="container">
        <p className="sub-title">
          <b>CANCELLATION POLICY</b>
        </p>
        <br/>
        <ul className="list">
          <li className="header">
          Orders once placed / completed successfully cannot be cancelled as we do not have an option of cancellation.
          </li>
        </ul>
      </div>

      <div className="container">
        <p className="sub-title">
          <b>PLEASE NOTE</b>
        </p>
        <br/>
        <ul className="list">
          <li className="header">
            You would receive the details of your purchase / selection on your NebourHood registered email.
          </li>
          <li className="header">
            To view more details regarding your purchase / selection on NebourHood, please visit the Transaction Details section of your profile.
          </li>
          <li className="header">
            Verification Responsibility: NebourHood does not independently verify the details of property owners, property requirement leads, service professionals, projects, people, products, services, listings, advertisements, offers, profiles, transactions, or anything else. Users are responsible for verifying the authenticity and accuracy of the information provided by other users. All users agree to NebourHood's declaration, acknowledging their responsibility for verification.
          </li>
          <li className="header">
            Qualified Leads: On NebourHood, qualified leads are tailored to meet specific requirements, offering detailed information to assist users in making informed decisions. While these leads ensure relevance and suitability, users should understand that they are not verified and must exercise due diligence before engaging. 
          </li>
          <li className="header">
            Account Security: Users are advised to maintain the security of their NebourHood accounts by using strong, unique passwords and enabling two-factor authentication for added protection against unauthorized access.
          </li>
          <li className="header">
            Reporting Concerns: Users are encouraged to promptly report any suspicious activity, fraudulent behaviour, or violations of terms of service to NebourHood's customer support team for swift resolution and assistance.
          </li>
        </ul>
      </div>

      <div className="container">
        <p className="sub-title">
          <b>DISCLAIMER</b>
        </p>
        <br/>
        <ul className="list">
          <br/>
          <li className="header">
            <b>NebourHood would not be responsible</b> for the acceptance or rejection of any deals between:
            <ul className="sub-list">
              <li>
              Property owners and property owners
              </li>
              <li>
              Property owners and service professionals
              </li>
              <li>
              Service professionals and service professionals
              </li>
              <li>
              Or any others involved.
              </li>
            </ul>
          </li>
          <li className="header">
            <b>NebourHood would not be responsible</b> for any kind of terms (including service, payment or any other terms) made between:
            <ul className="sub-list">
              <li>
              Property owners and property owners
              </li>
              <li>
              Property owners and service professionals
              </li>
              <li>
              Service professionals and service professionals
              </li>
              <li>
              Or any others involved.
              </li>
            </ul>
          </li>
          <li className="header">
            Limited Liability: NebourHood disclaims responsibility for any outcomes resulting from deals or interactions between users, including property owners, service professionals, or any other parties involved. Users engage with each other at their own risk, and NebourHood does not assume liability for any disputes, losses, damages or anything else arising from such interactions.
          </li>
          <li className="header">
            Terms Negotiation: NebourHood does not intervene in the negotiation or establishment of terms, including service agreements, payments, or any other non - contractual / contractual arrangements, between users. Users are solely responsible for negotiating and adhering to mutually agreed-upon terms.
          </li>
          <li className="header">
            Third-Party Content: NebourHood may contain links or references to third-party websites, services, or content. NebourHood does not endorse or control these third-party entities and is not responsible for the accuracy, legality, or quality of their content or services.
          </li>
          <li className="header">
            Indemnification: Users agree to indemnify and hold NebourHood harmless from any claims, damages, losses, liabilities or anything else arising from their use of the platform, including but not limited to disputes with other users, breach of terms, or violation of rights.
          </li>
          <li className="header">
            Legal Compliance: Users are responsible for complying with all applicable laws, regulations, and contractual obligations when using NebourHood's services. NebourHood does not condone or facilitate any illegal activities or behaviour on / via its platform.
          </li>
          <li className="header">
            Changes to Policies: NebourHood reserves the right to modify, update, or amend its policies, terms of service, and features at any time without prior notice. Users are advised to regularly review these documents for any changes or updates that may affect their use of the platform.
          </li>
        </ul>
      </div>



    </div>
  );
};

export default RefundReplacement;
