import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllProject } from "../../_actions/project_actions";
import "./Pagination.css";

const Pagination = ({ Collection, children }) => {
  const [currentphotoindex, setcurrentphotoindex] = useState(1);
  const [paginationopacity, setpaginationopacity] = useState(1);
  // const photos = [
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  //   "https://images.pexels.com/photos/15286/pexels-photo.jpg",
  // ];

  const HandleCurrentPhotsIndex = (e) => {
    setpaginationopacity(0);
    setTimeout(() => {
      setpaginationopacity(1);
      setcurrentphotoindex(e);
    }, 500);
  };

  const Styles = (index) => {
    return {
      color: index === currentphotoindex ? "white" : "black",
      backgroundColor: index === currentphotoindex ? "#fe5631" : "white",
    };
  };

  const handlearrow = (direction) => {
    const navnumbers = document.getElementById("nav-numbers-container");
    navnumbers.scrollBy(
      direction === "right" ? navnumbers.clientWidth : -navnumbers.clientWidth,
      0
    );
  };

  return (
    // <div className="pagination-container">
    //   <div className="pagination">
    //     {photos.map((photo, index) => {
    //       return (
    //         index <= currentphotoindex * 9 &&
    //         index >= (currentphotoindex - 1) * 9 && (
    //           <img
    //             style={{
    //               opacity: paginationopacity,
    //             }}
    //             src={photo}
    //             key={index}
    //           />
    //         )
    //       );
    //     })}
    //   </div>

    //   <div className="navigations">
    //     {photos.map((ph, index) => {
    //       return (
    //         index < photos.length / 9 && (
    //           <p
    //             onClick={(e) => {
    //               HandleCurrentPhotsIndex(index + 1);
    //             }}
    //           >
    //             {index + 1}
    //           </p>
    //         )
    //       );
    //     })}
    //   </div>
    // </div>
    <div className="pagination-container">
      <div
        style={{
          opacity: paginationopacity,
        }}
        className="pagination"
      >
        {children.map((child, index) => {
          return (
            index <= currentphotoindex * 9 &&
            index >= (currentphotoindex - 1) * 9 &&
            child
          );
        })}
      </div>

      <div className="navigations">
        <i
          onClick={() => {
            handlearrow("left");
          }}
          className="fas fa-chevron-left"
        ></i>
        <div id="nav-numbers-container" className="nav-numbers-container">
          {Collection.map((ph, index) => {
            return (
              index < Collection.length / 9 && (
                <p
                  style={Styles(index + 1)}
                  onClick={(e) => {
                    HandleCurrentPhotsIndex(index + 1);
                  }}
                >
                  {index + 1}
                </p>
              )
            );
          })}
         
        </div>

        <i
          onClick={() => {
            handlearrow("right");
          }}
          className="fas fa-chevron-right"
        ></i>
      </div>
    </div>
  );
};

export default Pagination;
