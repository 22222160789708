import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productDetailsbyID } from "../../../../_actions/property_actions";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import { Location } from "./Data";
import RenewPaymentModal from "./RenewPaymentModal/RenewPaymentModal";
import "./Renew.css";

function RenewPropertyDetails(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const productId = props.match.params.PostedPropertyId;

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [Writer, setWriter] = useState("");

  const [ProductDetails, setProductDetails] = useState([]);
  const [BuildingLandName, setBuildingLandName] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [AreaName, setAreaName] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [DimensionType, setDimensionType] = useState("");
  const [PropertyArea, setPropertyArea] = useState("");
  const [PropertyCondition, setPropertyCondition] = useState("");
  const [Budget, setBudget] = useState("");
  const [Requirements, setRequirements] = useState("");
  const [StatusofProject, setStatusofProject] = useState("");
  const [ServiceRequired, setServiceRequired] = useState("");
  const [ServiceType, setServiceType] = useState("");
  const [BuildingType, setBuildingType] = useState("");
  const [AreaType, setAreaType] = useState("");
  const [timeframe, settimeframe] = useState("");
  const [postrequirementsfilled, setpostrequirementsfilled] = useState(false);
  const [Changes_Made, setChanges_Made] = useState([]);

  const [
    postpropertyrequirementsbtnclicked,
    setpostpropertyrequirementsbtnclicked,
  ] = useState(false);

  useEffect(() => {
    dispatch(productDetailsbyID(productId)).then((response) => {
      // console.log("Response", response);
      // console.log("Changes Made", response.payload.Changes_Made[0]);
      setProductDetails(response.payload);
      setChanges_Made(response.payload.Changes_Made[0]);
      setWriter(response.payload.writer);
      setStatusofProject(response.payload.Status_Of_Project);
      setServiceRequired(response.payload.Service_Type_Required);
      setBuildingLandName(response.payload.Land_Building);
      setState(response.payload.State);
      setCity(response.payload.City);
      setAreaName(response.payload.Area_Name);
      setPinCode(response.payload.Pin_Code);
      setDimensionType(response.payload.Dimension_Type);
      setPropertyArea(response.payload.Project_Area);
      setPropertyCondition(response.payload.Property_Condition);
      setBudget(response.payload.Budget);
      setRequirements(response.payload.description);
      setServiceType(response.payload.Project_Type);
      setBuildingType(response.payload.BuildingType);
      setAreaType(response.payload.Area_Type);
      settimeframe("");
    });
  }, []);

  const [interiorselected, setinteriorselected] = useState(
    ServiceType === "Interior"
  );
  // console.log(ServiceType === "Interior");
  const [constructionselected, setconstructionselected] = useState(
    ServiceType === "Construction"
  );
  const [residentialselected, setresidentialselected] = useState(false);
  const [commercialselected, setcommercialselected] = useState(false);
  const [newproperty, setnewproperty] = useState(false);
  const [existingproperty, setexistingproperty] = useState(false);

  const [interiortype, setinteriortype] = useState("");
  const [constructiontype, setconstructiontype] = useState("");
  const [propertycondition, setpropertycondition] = useState(null);

  const [propertyconditionyears, setpropertyconditionyears] = useState("");
  const [propertyconditionmonths, setpropertyconditionmonths] = useState(null);
  useEffect(() => {
    if (PropertyCondition !== "New Property" && PropertyCondition.length > 0) {
      setexistingproperty(true);
    } else {
      setexistingproperty(false);
    }
  }, [PropertyCondition]);
  const cities =
    Location.Cities.filter((city) => city.State === State).length > 0
      ? Location.Cities.filter((city) => city.State === State)[0].Cities
      : [];
  var PropCondition =
    propertycondition === "New Property"
      ? propertycondition
      : propertyconditionyears.length > 2
      ? propertyconditionyears
      : propertyconditionyears +
        " Years " +
        propertyconditionmonths +
        " Months ";
  const requirementsarray = {
    productId: props.match.params.PostedPropertyId,
    writer: Writer,
    BuildingLandName,
    State,
    City,
    AreaName,
    PinCode,
    DimensionType,
    PropertyArea,
    PropCondition,
    Budget,
    Requirements,
    StatusofProject,
    ServiceRequired,
    ServiceType,
    BuildingType,
    AreaType,
    // Beginning_The_Project
    Beginning_The_Project: timeframe,
    ValidityTill: parseInt(timeframe.replace(/[^\d.]/g, "")),
    Changes_Made: [
      {
        Name_Of_property_Owner: Changes_Made.Name_Of_property_Owner,
        Alternative_Number: Changes_Made.Alternative_Number,
        writer: Writer,
        State: ProductDetails.State,
        City: ProductDetails.City,
        Land_Building: ProductDetails.Land_Building,
        Pin_Code: ProductDetails.Pin_Code,
        Area_Name: ProductDetails.Area_Name,
        Dimension_Type: ProductDetails.Dimension_Type,
        Project_Area: ProductDetails.Project_Area,
        Property_Condition: ProductDetails.Property_Condition,
        Budget: ProductDetails.Budget,
        description: ProductDetails.description,
        Project_Type: ProductDetails.Project_Type,
        Area_Type: ProductDetails.Area_Type,
        BuildingType: ProductDetails.BuildingType,
        Status_Of_Project: ProductDetails.Status_Of_Project,
        Service_Type_Required: ProductDetails.Service_Type_Required,
        Beginning_The_Project: ProductDetails.Beginning_The_Project,
        // ValidityTill: parseInt(timeframe.replace(/[^\d.]/g, "")),
      },
    ],
  };

  const handleselectbox = (e) => {
    const list = e.currentTarget.nextSibling;
    if (list.style.opacity === "0") {
      list.style.opacity = 1;
      list.style.visibility = "visible";
      list.style.transform = "scale(1)";
      list.style.pointerEvents = "auto";
    } else {
      list.style.opacity = 0;
      list.style.visibility = "visible";
      list.style.transform = "scale(.5)";
      list.style.pointerEvents = "none";
    }
  };

  const handlelistitem = (e) => {
    // console.log(e.currentTarget.parentElement.id);

    const currentselectedid = e.currentTarget.parentElement.id;

    const itemselected = e.currentTarget.innerText;
    const heading =
      e.currentTarget.parentElement.parentElement.childNodes[0].childNodes[0];
    const listitems = e.currentTarget.parentElement.childNodes;
    for (let i = 0; i < listitems.length; i++) {
      listitems[i].style.backgroundColor = "rgb(245, 242, 242)";
      listitems[i].style.color = "black";
    }

    if (e.currentTarget.innerText === "Residential") {
      setresidentialselected(true);
      setcommercialselected(false);
    }
    if (e.currentTarget.innerText === "Commercial") {
      setresidentialselected(false);
      setcommercialselected(true);
    }
    if (currentselectedid === "state") {
      setState(e.currentTarget.innerText);
    }
    if (currentselectedid === "city") {
      setCity(e.currentTarget.innerText);
    }
    if (currentselectedid === "buildingtype") {
      setBuildingType(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertytype") {
      setAreaType(e.currentTarget.innerText);
    }
    if (currentselectedid === "propertystatus") {
      setStatusofProject(e.currentTarget.innerText);
    }
    if (currentselectedid === "servicerequirement") {
      setServiceRequired(e.currentTarget.innerText);
    }

    if (currentselectedid === "years") {
      setpropertyconditionyears(e.currentTarget.innerText);
    }
    if (currentselectedid === "state") {
      setState(e.currentTarget.innerText);
    }
    if (currentselectedid === "months") {
      setpropertyconditionmonths(e.currentTarget.innerText);
    }
    if (currentselectedid === "budget") {
      setBudget(e.currentTarget.innerText);
    }
    if (currentselectedid === "time-frame") {
      settimeframe(e.currentTarget.innerText);
    }
    heading.innerText = itemselected;
    e.currentTarget.style.backgroundColor = "#fe5631";
    e.currentTarget.style.color = "white";
    e.currentTarget.parentElement.style.opacity = 0;
    e.currentTarget.parentElement.style.visibility = "hidden";
    e.currentTarget.parentElement.style.transform = "scale(.5)";
    e.currentTarget.parentElement.style.pointerEvents = "none";
  };

  const handleradiotype = (e) => {
    if (e.target.value === "Interior") {
      setinteriorselected(true);
      setconstructionselected(false);
      setinteriortype("Interior");
      setconstructiontype("");
      setServiceType(e.target.value);
    }
    if (e.target.value === "Construction") {
      setinteriorselected(false);
      setconstructionselected(true);
      setServiceType(e.target.value);
      setconstructiontype("Construction");
      setinteriortype("");
    }
    if (e.target.value === "New Property") {
      setnewproperty(true);
      setexistingproperty(false);
      setpropertycondition(e.target.value);
      setPropertyCondition(e.target.value);
      setpropertyconditionmonths("null");
      setpropertyconditionyears("null");
    }
    if (e.target.value === "Existing Property") {
      setnewproperty(false);
      setexistingproperty(true);
      setpropertycondition(e.target.value);
      setPropertyCondition(e.target.value);
    }
    if (e.target.value === "Interior" || e.target.value === "Construction") {
      const selectboxes =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement
          .childNodes[1].childNodes;
      if (selectboxes) {
        for (let i = 0; i < selectboxes.length; i++) {
          selectboxes[i].childNodes[1].childNodes[0].childNodes[0].innerText =
            "Select";
          selectboxes[i].childNodes[1].childNodes[1].childNodes.forEach(
            (li) => {
              li.style.backgroundColor = "rgb(245, 242, 242)";
              li.style.color = "black";
            }
          );

          selectboxes[i].childNodes.forEach((e) => {
            if (e.className === "selectbox-container") {
              e.childNodes[1].style.opacity = 0;
              e.childNodes[1].style.visibility = "hidden";
              e.childNodes[1].style.transform = "scale(0)";
            }
          });
        }
      }
    }
  };

  const onHandleHouseLandName = (e) => {
    // console.log(e.target.value);
    setBuildingLandName(e.target.value);
  };

  const onHandleAreaName = (e) => {
    // console.log(e.target.value);
    setAreaName(e.target.value);
  };
  const onHandleStatusofProperty = (e) => {
    // console.log(e.target.value);
    setStatusofProject(e.target.value);
  };
  const onHandlePinCode = (e) => {
    // console.log(e.target.value);
    setPinCode(e.target.value);
  };
  const onHandelDimensionType = (e) => {
    // console.log(e.target.value);
    setDimensionType(e.target.value);
  };
  const onHandlePropertyArea = (e) => {
    // console.log(e.target.value);
    setPropertyArea(e.target.value);
  };
  const onHandlePropertyCondition = (e) => {
    // console.log(e.target.value);
    setPropertyCondition(e.target.value);
  };
  const onHandleBudget = (e) => {
    // console.log(e.target.value);
    setBudget(e.target.value);
  };
  const onHandleRequirement = (e) => {
    // console.log(e.target.value);
    setRequirements(e.target.value);
  };
  const onHandleServiceRequirement = (e) => {
    // console.log(e.target.value);
    setServiceRequired(e.target.value);
  };

  const onHandleBuildingType = (e) => {
    // console.log(e.target.value);
    setBuildingType(e.target.value);
  };
  const onHandleAreaType = (e) => {
    // console.log(e.target.value);
    setAreaType(e.target.value);
  };

  const detailstopass = [
    {
      Heading: "Area Name",
      Content: AreaName,
    },
    {
      Heading: "Area Type",
      Content: AreaType,
    },
    {
      Heading: "Budget",
      Content: Budget,
    },
    {
      Heading: "Building Type",
      Content: BuildingType,
    },
    {
      Heading: "City",
      Content: City,
    },
    {
      Heading: "Dimension Type",
      Content: DimensionType,
    },
    {
      Heading: "Building Land Name",
      Content: BuildingLandName,
    },
    {
      Heading: "PinCode",
      Content: PinCode,
    },
    {
      Heading: "Property Area",
      Content: PropertyArea,
    },
    {
      Heading: "Service Type",
      Content: ServiceType,
    },
    {
      Heading: "Property Condition",
      Content:
        propertycondition === "New Property"
          ? propertycondition
          : propertyconditionyears.length > 2
          ? propertyconditionyears
          : propertyconditionyears +
            " Years " +
            propertyconditionmonths +
            " Months ",
    },
    {
      Heading: "Service Type Required",
      Content: ServiceRequired,
    },
    {
      Heading: "State",
      Content: State,
    },
    {
      Heading: "Status of Project",
      Content: StatusofProject,
    },
    {
      Heading: "Time Frame",
      Content: timeframe,
    },
    {
      Heading: "Project Beginning",
      Content: timeframe,
    },
    {
      Heading: "Validity Till",
      Content: timeframe.replace(/[^\d.]/g, ""),
    },
  ];

  useEffect(() => {
    // const requirementnotfilled = requirementsarray.filter(
    //   (req) => (req = !req)
    // );
    // if (requirementnotfilled.length === 0) {
    //   setpostrequirementsfilled(true);
    // } else {
    //   setpostrequirementsfilled(false);
    // }
    // console.log(requirementnotfilled);
    if (timeframe.length > 0) {
      setpostrequirementsfilled(true);
    } else {
      setpostrequirementsfilled(false);
    }
    // console.log(timeframe.length);
  }, [timeframe]);

  const handlepostreq = (e) => {
    e.preventDefault();
  };
  if (user.userData && user.userData.isAuth) {
    let userDetails = user.userData;
    return (
      <div className="post-property-requirements-container">
        <PageBanner title="Renew Property Requirements" />
        <br />
        <div className="post-property-requirements">
          <div
            style={{
              width: screenwidth <= 500 ? screenwidth - 20 + "px" : null,
            }}
            className="property-requirements"
          >
            <div className="requirement">
              <p className="requirement-title">User Details</p>
              {/* <p className="requirement-desc">
                Please fill the details of your property where you want the work
                to be completed
              </p> */}
              <div className="requirement-details">
                <div className="input-container">
                  <label className="input-title">
                    Property Owner Profile Number
                    <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    className="text-input"
                    type={"text"}
                    defaultValue={userDetails._id}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="requirement">
              <p className="requirement-title">Personal Details</p>
              {/* <p className="requirement-desc">
                Please fill the details of your property where you want the work
                to be completed
              </p> */}
              <div className="requirement-details">
                <div className="input-container">
                  <label className="input-title">
                    Property Owner Name
                    <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    className="text-input"
                    type={"text"}
                    defaultValue={userDetails.name}
                    disabled
                  />
                </div>

                <div className="input-container">
                  <label className="input-title">
                    Alternative Email ID
                    <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    className="text-input"
                    type={"text"}
                    defaultValue={
                      userDetails.Personal_Details.Alternative_Email_Id
                    }
                    disabled
                  />
                </div>
                <div className="input-container">
                  <label className="input-title">
                    Alternative Phone Number
                    <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    className="text-input"
                    type={"text"}
                    defaultValue={
                      userDetails.Personal_Details.Alternative_Phone_Number
                    }
                    disabled
                  />
                </div>
                <div className="input-container">
                  <label className="input-title">
                    WhatsApp Number
                    <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    className="text-input"
                    type={"text"}
                    defaultValue={
                      userDetails.Personal_Details.Personal_WhatsApp_Number
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="requirement">
              <p className="requirement-title">Address</p>
              <p className="requirement-desc">
                Please fill the details of your property where you want the work
                to be completed
              </p>
              <div className="requirement-details">
                <div className="input-container">
                  <label className="input-title">
                    House Name / Building Name / Plot Name / Land Name (Property
                    Name) <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    defaultValue={BuildingLandName}
                    onChange={onHandleHouseLandName}
                    className="text-input"
                    type={"text"}
                  />
                </div>
                <div className="input-container">
                  <label className="input-title">
                    State <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <ul id="state" className="selectbox-container">
                    <li onClick={handleselectbox} className="heading">
                      <p>{State.length > 0 ? State : "Select State"}</p>
                      <span className="material-icons">expand_more</span>
                    </li>
                    <ul
                      style={{
                        opacity: 0,
                      }}
                      id="state"
                      className="select-box-list"
                    >
                      {Location.States.map((state, index) => {
                        return (
                          <li key={index} onClick={handlelistitem}>
                            {state}
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
                <div className="input-container">
                  <label className="input-title">
                    City <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <ul id="city" className="selectbox-container">
                    <li onClick={handleselectbox} className="heading">
                      <p>{City.length > 0 ? City : "Select City"}</p>
                      <span className="material-icons">expand_more</span>
                    </li>
                    <ul
                      style={{
                        opacity: 0,
                      }}
                      id="city"
                      className="select-box-list"
                    >
                      {cities.map((city, index) => {
                        return (
                          <li key={index} onClick={handlelistitem}>
                            {city}
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
                <div className="input-container">
                  <label className="input-title">
                    Area Name <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    defaultValue={AreaName}
                    onChange={onHandleAreaName}
                    className="text-input"
                    type={"text"}
                  />
                </div>
                <div className="input-container">
                  <label className="input-title">
                    Pin Code <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <input
                    defaultValue={PinCode}
                    onChange={onHandlePinCode}
                    className="text-input"
                    type={"text"}
                    id="pincode"
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="requirement">
              <p className="requirement-title">Service Type Required</p>
              <div className="requirement-details">
                <div className="input-container">
                  <label className="input-title">
                    Please Select your Service Type, Property Type and Property
                    Status <b style={{ color: "red" }}>*</b> :{" "}
                  </label>
                  <div className="radios">
                    <div className="radio">
                      <label>Interior</label>
                      {ServiceType === "Interior" ? (
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"Interior"}
                          name="propertytype"
                          checked
                        />
                      ) : (
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"Interior"}
                          name="propertytype"
                        />
                      )}
                    </div>
                    <div className="radio">
                      <label>Construction</label>
                      {ServiceType === "Construction" ? (
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"Construction"}
                          name="propertytype"
                          checked
                        />
                      ) : (
                        <input
                          onChange={handleradiotype}
                          type={"radio"}
                          value={"Construction"}
                          name="propertytype"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {ServiceType === "Interior" ? (
                  <div
                    className="service-inputs-container"
                    id="interior-inputs"
                  >
                    <div className="input-container">
                      <label className="input-title">
                        Building Type <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {BuildingType.length > 0 ? (
                            BuildingType
                          ) : (
                            <p>Select</p>
                          )}

                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="buildingtype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Residential</li>
                          <li onClick={handlelistitem}>Commercial</li>
                        </ul>
                      </ul>
                    </div>

                    <div className="input-container">
                      <label className="input-title">
                        Property Type <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {AreaType.length > 0 ? AreaType : <p>Select</p>}
                          <span className="material-icons">expand_more</span>
                        </li>
                        {residentialselected ? (
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="propertytype"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>Apartment</li>
                            <li onClick={handlelistitem}>Penthouse</li>
                            <li onClick={handlelistitem}>
                              Stand Alone Building
                            </li>
                            <li onClick={handlelistitem}>Independent Home</li>
                            <li onClick={handlelistitem}>Villa</li>
                            <li onClick={handlelistitem}>Bungalow</li>
                          </ul>
                        ) : (
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="propertytype"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>Retail</li>
                            <li onClick={handlelistitem}>Hospitality</li>
                            <li onClick={handlelistitem}>Healthcare</li>
                            <li onClick={handlelistitem}>
                              Office - Corporate and Tech
                            </li>
                            <li onClick={handlelistitem}>Institutional</li>
                            <li onClick={handlelistitem}>Industrial</li>
                          </ul>
                        )}
                      </ul>
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Status of Property <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {StatusofProject.length > 0 ? (
                            StatusofProject
                          ) : (
                            <p>Select</p>
                          )}
                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertystatus"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>
                            Have Received Possession, Ready to Move-In
                          </li>
                          <li onClick={handlelistitem}>
                            Waiting for Possession and Registration / Work
                            Completion
                          </li>
                        </ul>
                      </ul>
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Service Requirement <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {ServiceRequired.length > 0 ? (
                            ServiceRequired
                          ) : (
                            <p>Select</p>
                          )}
                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="servicerequirement"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Only Designs</li>
                          <li onClick={handlelistitem}>Only Execution</li>
                          <li onClick={handlelistitem}>
                            Both - Design and Execution
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                ) : ServiceType === "Construction" ? (
                  <div
                    className="service-inputs-container"
                    id="construction-inputs"
                  >
                    <div className="input-container">
                      <label className="input-title">
                        Building Type <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {BuildingType.length > 0 ? (
                            BuildingType
                          ) : (
                            <p>Select</p>
                          )}

                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="buildingtype"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Residential</li>
                          <li onClick={handlelistitem}>Commercial</li>
                        </ul>
                      </ul>
                    </div>

                    <div className="input-container">
                      <label className="input-title">
                        Property Type <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {AreaType.length > 0 ? AreaType : <p>Select</p>}{" "}
                          <span className="material-icons">expand_more</span>
                        </li>
                        {residentialselected ? (
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="propertytype"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>Apartment</li>
                            <li onClick={handlelistitem}>Penthouse</li>
                            <li onClick={handlelistitem}>
                              Stand Alone Building
                            </li>
                            <li onClick={handlelistitem}>Independent Home</li>
                            <li onClick={handlelistitem}>Villa</li>
                            <li onClick={handlelistitem}>Bungalow</li>
                          </ul>
                        ) : (
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="propertytype"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>Retail</li>
                            <li onClick={handlelistitem}>Hospitality</li>
                            <li onClick={handlelistitem}>Healthcare</li>
                            <li onClick={handlelistitem}>
                              Office - Corporate and Tech
                            </li>
                            <li onClick={handlelistitem}>Institutional</li>
                            <li onClick={handlelistitem}>Industrial</li>
                          </ul>
                        )}
                      </ul>
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Status of Property <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          {StatusofProject.length > 0 ? (
                            StatusofProject
                          ) : (
                            <p>Select</p>
                          )}
                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="propertystatus"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>
                            Have Received Possession, Ready to Build
                          </li>
                          <li onClick={handlelistitem}>
                            Waiting for Possession and Registration / Work
                            Completion
                          </li>
                        </ul>
                      </ul>
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Service Requirement <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          <p>
                            {ServiceRequired.length > 0
                              ? ServiceRequired
                              : "Select"}
                          </p>
                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="servicerequirement"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>Only Designs</li>
                          <li onClick={handlelistitem}>Only Execution</li>
                          <li onClick={handlelistitem}>
                            Both- Design and Execution
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="requirement">
                  <p className="requirement-title">Time Frame</p>

                  <div className="requirement-details">
                    <div className="input-container">
                      <label className="input-title">
                      How soon would you like to get the work started?
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <ul id="state" className="selectbox-container">
                        <li onClick={handleselectbox} className="heading">
                          <p>{timeframe ? timeframe : "Select"}</p>
                          <span className="material-icons">expand_more</span>
                        </li>
                        <ul
                          style={{
                            opacity: 0,
                          }}
                          id="time-frame"
                          className="select-box-list"
                        >
                          <li onClick={handlelistitem}>
                            Urgently – Within 30 Days
                          </li>
                          <li onClick={handlelistitem}>Within 60 Days</li>
                          <li onClick={handlelistitem}>Within 90 Days</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="requirement">
                  <p className="requirement-title">Property Information</p>

                  <div className="requirement-details">
                    <div className="input-container">
                      <label className="input-title">
                        Property Requirement Number{" "}
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        defaultValue={ProductDetails._id}
                        disabled
                        style={{
                          width: "250px",
                        }}
                        className="text-input"
                        type={"text"}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Property Dimension Type{" "}
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        onChange={onHandelDimensionType}
                        style={{
                          width: "250px",
                        }}
                        className="text-input"
                        defaultValue={DimensionType}
                        type={"text"}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Property Area (sq. ft.){" "}
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <input
                        style={{
                          width: "250px",
                        }}
                        className="text-input"
                        defaultValue={PropertyArea}
                        onChange={onHandlePropertyArea}
                        type={"text"}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-title">
                        Property Condition <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <div className="radios">
                        <div className="radio">
                          <label>New Property (New Requirement)</label>
                          {PropertyCondition === "New Property" ? (
                            <input
                              onChange={handleradiotype}
                              type={"radio"}
                              value={"New Property"}
                              name="propertycondition"
                              checked
                            />
                          ) : (
                            <input
                              onChange={handleradiotype}
                              type={"radio"}
                              value={"New Property"}
                              name="propertycondition"
                            />
                          )}
                        </div>
                        <div className="radio">
                          <label>
                            Existing Property (Renovation & Remodelling)
                          </label>
                          {PropertyCondition !== "New Property" &&
                          PropertyCondition.length > 0 ? (
                            <input
                              onChange={handleradiotype}
                              type={"radio"}
                              value={"Existing Property"}
                              name="propertycondition"
                              checked
                            />
                          ) : (
                            <input
                              onChange={handleradiotype}
                              type={"radio"}
                              value={"Existing Property"}
                              name="propertycondition"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {existingproperty ? (
                      <div className="property-condition-inputs">
                        <div className="input-container">
                          <label className="input-title">
                            Years <b style={{ color: "red" }}>*</b> :{" "}
                          </label>
                          <ul className="selectbox-container">
                            <li onClick={handleselectbox} className="heading">
                              <p>Select</p>
                              <span className="material-icons">
                                expand_more
                              </span>
                            </li>
                            <ul
                              style={{
                                opacity: 0,
                              }}
                              id="years"
                              className="select-box-list"
                            >
                              <li onClick={handlelistitem}>Less than a Year</li>
                              <li onClick={handlelistitem}>1</li>
                              <li onClick={handlelistitem}>2</li>
                              <li onClick={handlelistitem}>3</li>
                              <li onClick={handlelistitem}>4</li>
                              <li onClick={handlelistitem}>5</li>
                              <li onClick={handlelistitem}>6</li>
                              <li onClick={handlelistitem}>7</li>
                              <li onClick={handlelistitem}>8</li>
                              <li onClick={handlelistitem}>9</li>
                              <li onClick={handlelistitem}>10</li>
                              <li onClick={handlelistitem}>
                                More than 10 Years
                              </li>
                            </ul>
                          </ul>
                        </div>

                        <div className="input-container">
                          <label className="input-title">
                            Months <b style={{ color: "red" }}>*</b> :{" "}
                          </label>
                          <ul className="selectbox-container">
                            <li onClick={handleselectbox} className="heading">
                              <p>Select</p>
                              <span className="material-icons">
                                expand_more
                              </span>
                            </li>
                            <ul
                              style={{
                                opacity: 0,
                              }}
                              id="months"
                              className="select-box-list"
                            >
                              <li onClick={handlelistitem}>0</li>
                              <li onClick={handlelistitem}>1</li>
                              <li onClick={handlelistitem}>2</li>
                              <li onClick={handlelistitem}>3</li>
                              <li onClick={handlelistitem}>4</li>
                              <li onClick={handlelistitem}>5</li>
                              <li onClick={handlelistitem}>6</li>
                              <li onClick={handlelistitem}>7</li>
                              <li onClick={handlelistitem}>8</li>
                              <li onClick={handlelistitem}>9</li>
                              <li onClick={handlelistitem}>10</li>
                              <li onClick={handlelistitem}>11</li>
                              <li onClick={handlelistitem}>12</li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="requirement">
                  <p className="requirement-title">Budget</p>

                  <div className="requirement-details">
                    <div className="input-container">
                      <label className="input-title">
                        What is your approximate budget for the scope of work
                        involved?
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      {ServiceType === "Interior" ? (
                        <ul id="state" className="selectbox-container">
                          <li onClick={handleselectbox} className="heading">
                            <p>{Budget ? Budget : "Select"}</p>
                            <span className="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="budget"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>0 – 5 Lacs</li>
                            <li onClick={handlelistitem}>5 – 10 Lacs</li>
                            <li onClick={handlelistitem}>10 – 15 Lacs</li>
                            <li onClick={handlelistitem}>15 – 20 Lacs</li>
                            <li onClick={handlelistitem}>20 – 25 Lacs</li>
                            <li onClick={handlelistitem}>25 – 30 Lacs</li>
                            <li onClick={handlelistitem}>Above 30 Lacs</li>
                          </ul>
                        </ul>
                      ) : null}

                      {ServiceType === "Construction" ? (
                        <ul id="state" className="selectbox-container">
                          <li onClick={handleselectbox} className="heading">
                            <p>{Budget ? Budget : "Select"}</p>
                            <span className="material-icons">expand_more</span>
                          </li>
                          <ul
                            style={{
                              opacity: 0,
                            }}
                            id="budget"
                            className="select-box-list"
                          >
                            <li onClick={handlelistitem}>0 – 15 Lacs</li>
                            <li onClick={handlelistitem}>15 – 30 Lacs</li>
                            <li onClick={handlelistitem}>30 – 45 Lacs</li>
                            <li onClick={handlelistitem}>45 – 60 Lacs</li>
                            <li onClick={handlelistitem}>60 – 75 Lacs</li>
                            <li onClick={handlelistitem}>75 Lacs – 1 Cr.</li>
                            <li onClick={handlelistitem}>Above 1 Cr.</li>
                          </ul>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="requirement">
                  <p className="requirement-title">PROPERTY REQUIREMENTS</p>

                  <div className="requirement-details">
                    <div className="input-container">
                      <label className="input-title">
                        Please describe your property requirements completely
                        and as clearly as possible
                        <b style={{ color: "red" }}>*</b> :{" "}
                      </label>
                      <textarea
                        defaultValue={Requirements}
                        onChange={onHandleRequirement}
                        className="text-area"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            style={{
              opacity: postrequirementsfilled ? 1 : 0.5,
              pointerEvents: postrequirementsfilled ? "auto" : "none",
            }}
            onClick={() => {
              setpostpropertyrequirementsbtnclicked(true);
            }}
            className="post-property-req-btn"
          >
            Renew Property Requirements
          </button>
          <RenewPaymentModal
            filleddetails={detailstopass}
            handlepostreq={handlepostreq}
            modalopen={postpropertyrequirementsbtnclicked}
            DataToSubmit={requirementsarray}
            handleclose={() => {
              setpostpropertyrequirementsbtnclicked(false);
            }}
          />
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  } else {
    return <LoadingIcon />;
  }
}

export default RenewPropertyDetails;
