import React, { useEffect, useLayoutEffect, useState } from "react";
import { filters } from "./Data";
import "./Filters.css";
import List from "./List";

export default function Filters(props) {
  const [screenwidth, setscreenwidth] = useState();

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  });

  const [applyfiltersclicked, setapplyfiltersclicked] = useState(false);

  const [currentstate, setcurrentstate] = useState("");
  const [currentcity, setcurrencity] = useState("");

  const [projectservicerequirement, setprojectservicerequirement] = useState("");
  const [timeframe, settimeframe] = useState("");

  const [residential, setresidential] = useState(false);
  const [commercial, setcommercial] = useState(false);
  const [institutional, setinstitutional] = useState(false);
  const [projecttype, setprojecttype] = useState(false);
  const [projectsubtype, setprojectsubtype] = useState(false);
  const [projectoption, setprojectoption] = useState(false);

  const [spacesservedtype, setspacesservedtype] = useState("");
  const [spacesservedoption, setspacesservedoption] = useState("");

  const filterdetail = {
    State: currentstate,
    City: currentcity,
    ProjectServiceRequirement: projectservicerequirement,
    TimeFrame: timeframe ? timeframe : "" ,
    ProjectType: projecttype ? projecttype : "",
    ProjectSubType: projectsubtype ? projectsubtype : "",
    ProjectOption: projectoption ? projectoption : "",
    SpacesServedType: spacesservedtype,
    SpacesServedSubType: institutional ? "Institutional" : "",
    SpacesServedOption: spacesservedoption,
  };

  const [detailsfilled, setdetailsfilled] = useState(false);

  const alldetails = [
    props.Location ? currentstate : "nothing",
    props.Location ? currentcity : "nothing",
    props.PSR ? projectservicerequirement : "nothing",
    props.TimeFrame ? timeframe : "nothing",
    projecttype,
    props.HideotherLists ? "nothing" : projectsubtype,
    props.ProjectType !== 2 ? projectoption : "nothing",
    props.SpacesServed ? spacesservedtype : "nothing",
    props.SpacesServed ? spacesservedoption : "nothing",
  ];

  useEffect(() => {
    if (alldetails.filter((detail) => !detail.length > 0).length > 0) {
      setdetailsfilled(false);
    } else {
      setdetailsfilled(true);
    }
  }, [alldetails]);
  useEffect(() => {
    // console.log(alldetails);
  }, [alldetails]);

  const handleapplyfilter = (e) => {
    props.getfiltersdata(filterdetail);
    setapplyfiltersclicked(false);
  };

  const handleheading = (item, e) => {
    const list = e.currentTarget.parentElement;
    if (list.id === "list-state") {
      setcurrentstate(item);
    }
    if (list.id === "list-city") {
      setcurrencity(item);
    }
    if (list.id === "list-psr") {
      setprojectservicerequirement(item);
    }
    if (list.id === "list-timeframe") {
      settimeframe(item);
    }
    if (list.id === "list-projecttype-type") {
      setprojecttype(item);
    }
    if (list.id === "list-projecttype-subtypes") {
      if (item === "Residential") {
        setresidential(true);
        setcommercial(false);
        setprojectsubtype("Residential");
        setspacesservedtype("Residential");
      }
      if (item === "Commercial") {
        setcommercial(true);
        setresidential(false);
        setprojectsubtype("Commercial");
        setspacesservedtype("Commercial");
      }
    }
    if (list.id === "list-projecttype-subtypes-sub-types") {
      setprojectoption(item);
    }
    if (list.id === "list-spaces-served") {
      setspacesservedoption(item);
      if (item === "Institutional") {
        setinstitutional(true);
      } else {
        setinstitutional(false);
      }
    }
    if (list.id === "list-spaces-served-institutional") {
      setinstitutional(true);
      setspacesservedoption(item);
    }
  };

  const handlefiltersbtn = (e) => {
    setapplyfiltersclicked(!applyfiltersclicked);
  };

  return (
    <div className="filters-container">
      <div className="filters-btn-container">
        <p className="filter-head">Filters</p>
        <span
          onClick={handlefiltersbtn}
          id="filters-btn"
          class="material-icons"
        >
          filter_list
        </span>
      </div>

      <div
        style={{
          display: applyfiltersclicked ? "block" : null,
        }}
        className="filters"
      >
        {props.Location ? (
          <div className="list-and-heading-container">
            <p className="heading-container">Location</p>
            <div className="lists-container">
              <List
                Passvalue={handleheading}
                DefaultHeading={"State"}
                listitems={filters.Location.States}
                id="list-state"
              />
              <List
                Passvalue={handleheading}
                DefaultHeading={"City"}
                listitems={
                  currentstate
                    ? filters.Location.Cities.filter(
                        (city) => city.State === currentstate
                      )[0].Cities
                    : []
                }
                id="list-city"
              />
            </div>
          </div>
        ) : null}
        {props.PSR ? (
          <div className="list-and-heading-container">
          {props.GalleryFilter  ? 
            <p className="heading-container">Project Service Requirement</p>
          : 
            <p className="heading-container">Property Service Requirement</p>
          }
            <div className="lists-container">
              <List
                Passvalue={handleheading}
                DefaultHeading={"Select"}
                listitems={filters.PSR}
                id="list-psr"
              />
            </div>
          </div>
        ) : null}
        {props.ProjectType ? (
          <div className="list-and-heading-container">
            <p className="heading-container">
              {" "}
              {props.ProjectTypeTitle ? props.ProjectTypeTitle : "Project Type"}
            </p>
            <div className="lists-container">
              <List
                Passvalue={handleheading}
                DefaultHeading={"Select"}
                listitems={
                  props.ChangedArray
                    ? props.ChangedArray
                    : filters.ProjectType.Types
                }
                id="list-projecttype-type"
              />
              {props.HideotherLists ? null : (
                <List
                  Passvalue={handleheading}
                  DefaultHeading={"Select"}
                  listitems={projecttype ? filters.ProjectType.SubTypes : []}
                  id="list-projecttype-subtypes"
                />
              )}
              {props.ProjectType !== 2 ? (
                <List
                  Passvalue={handleheading}
                  DefaultHeading={"Select"}
                  listitems={
                    residential
                      ? filters.ProjectType.Residential
                      : commercial
                      ? filters.ProjectType.Commercial
                      : []
                  }
                  id="list-projecttype-subtypes-sub-types"
                />
              ) : null}
            </div>
          </div>
        ) : null}
        {props.SpacesServed ? (
          <div className="list-and-heading-container">
            <p className="heading-container">Spaces Served</p>
            <div className="lists-container">
              <List
                Passvalue={handleheading}
                DefaultHeading={"Select"}
                listitems={
                  residential
                    ? filters.ProjectType.SpacesServed.Residential
                    : commercial
                    ? filters.ProjectType.SpacesServed.Commercial
                    : []
                }
                id="list-spaces-served"
              />
              {institutional ? (
                <List
                  Passvalue={handleheading}
                  DefaultHeading={"Select"}
                  listitems={filters.ProjectType.SpacesServed.Institutional}
                  id="list-spaces-served-institutional"
                />
              ) : null}
            </div>
          </div>
        ) : null}
        {/* Time Frames */}
        {props.ProjectTypeTitle === "Property Requirement Type"? 
          <div className="list-and-heading-container">
            <p className="heading-container">Time Frame</p>
            <div className="lists-container">
              <List
                  Passvalue={handleheading}
                  DefaultHeading={"Select"}
                  listitems={filters.TimeFrame}
                  id="list-timeframe"
                />
            </div>
          </div>
        : null}
        <div
          style={{ position: props.position }}
          className="apply-btn-container"
        >
          <button
            style={{
              opacity: detailsfilled ? 1 : 0.6,
              pointerEvents: detailsfilled ? "auto" : "none",
            }}
            onClick={handleapplyfilter}
            className="apply-btn"
          >
            Apply
          </button>
        </div>
      </div>
      <br />
    </div>
  );
}
