import React from "react";
import "./ViewImg.css";

export default function ViewImg(props) {


  return (
    <div style={{
      transform:props.transform
    }} className="view-img-container">
      <span onClick={props.handlecloseview} className="material-icons">
        cancel
      </span>
      <br/>
      <img src={props.content} />
    </div>
  );
}
