import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./FeaturedProperties.css";
import Button from "../../../utils/Button/Button";
// import { properties } from "./Data";
import { getAllProject } from "../../../../_actions/project_actions";
import { useDispatch } from "react-redux";
// import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
// import Verified from "../../../utils/Verified/Verified.svg"
import SmallCarousel from "../../../utils/SmallCarousel/SmallCarousel";
import PrimeProjectCover from "../../../asserts/Logo/PrimeProjectCover.png";

export default function FeaturedProperties(props) {
  const dispatch = useDispatch();
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [featuredpropertywidth, setfeaturedpropertywidth] = useState();
  const [Projects, setProjects] = useState([]);
  const [datareceived, setdatareceived] = useState(false);
  const propertyheightref = useRef(null);



  const getProduct = (variables) => {
    setdatareceived(false);

    dispatch(getAllProject(variables)).then((response) => {
      if (response.payload.success) {
        setProjects(response.payload.projects);

        setTimeout(() => {
          setfeaturedpropertywidth(
            propertyheightref.current.getBoundingClientRect().width
          );
        }, 20);

        setTimeout(() => {
          setdatareceived(true);
        }, 10);

        // console.log("Product Response", response.payload.projects);
      } else {
        alert("Failed to fetch product datas");
      }
    });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  useEffect(() => {
    getProduct();
  }, []);

  const handlearrowclick = (e) => {
    const Featuredproperties = document.getElementById(
      "home-featured-properties"
    );
    const transformwidth = parseInt(
      Featuredproperties.style.transform.replace(/[^\d.]/g, "")
    );
    var featuredpropertieslength = Projects.length - 3;
    if (screenwidth <= 1260) {
      featuredpropertieslength = Projects.length - 2;
    }
    if (screenwidth <= 870) {
      featuredpropertieslength = Projects.length - 1;
    }
    if (e.currentTarget.className === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        Featuredproperties.style.transform = `translate(-${(featuredpropertywidth + 20) * featuredpropertieslength
          }px)`;
      } else {
        Featuredproperties.style.transform = `translate(-${transformwidth - featuredpropertywidth - 20
          }px)`;
      }
    }
    if (e.currentTarget.className === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        Featuredproperties.style.transform = `translate(-${featuredpropertywidth + 20
          }px)`;
      }
      if (transformwidth > featuredpropertywidth * featuredpropertieslength) {
        Featuredproperties.style.transform = `translate(-${0}px)`;
      } else {
        Featuredproperties.style.transform = `translate(-${featuredpropertywidth + transformwidth + 20
          }px)`;
      }
    }
  };

  return (
    <div className="featured-properties-container">
      <h1 className="title">
        {" "}
        <b>Explore Our Spectacular Projects</b>
      </h1>
      <p
        // style={{ width: screenwidth <= 800 ? screenwidth - 100 + "px" : null }}
        className="descriptionn"
      >
        View fabulous Projects completed by skilled professionals
      </p>
      <br />
      <div
        style={{
          width:
            screenwidth <= 870
              ? featuredpropertywidth + "px"
              : screenwidth <= 1260
                ? featuredpropertywidth * 2 + 20 + "px"
                : featuredpropertywidth * 3 + 20 + "px",
        }}
        id="arrows"
        className="arrowss"
      >
        <i
          id="left-arrow"
          onClick={handlearrowclick}
          className="fas fa-arrow-left"
        ></i>
        <i
          id="right-arrow"
          onClick={handlearrowclick}
          className="fas fa-arrow-right"
        ></i>
      </div>
      <div
        className="featured-properties"
        style={{
          width:
            screenwidth <= 870
              ? featuredpropertywidth + "px"
              : screenwidth <= 1260
                ? featuredpropertywidth * 2 + 20 + "px"
                : featuredpropertywidth * 3 + 40 + "px",
        }}
      >
        <div id="home-featured-properties" className="properties">
          {Projects.map((property, index) => {
            return (
              <div
                id="home-featured-property"
                className="featured-properties-property"
                ref={propertyheightref}
                key={index}
                style={{
                width:
                  screenwidth <= 510 ? screenwidth - 10 + "px" : null,
                }}
              >
                <div className="upper">
                  {property.Project_Type === "Interior" ? (
                    <p className="tag" id="interior-tag">
                      INTERIOR
                    </p>
                  ) : (
                    <p className="tag" id="construction-tag">
                      CONSTRUCTION
                    </p>
                  )}
                  {/* <img
                    style={{
                      width:
                        screenwidth <= 510
                          ? screenwidth - 20 + "px"
                          : null,
                    }}
                    src="https://templates.envytheme.com/fido/default/assets/images/featured/featured-1.jpg"
                    alt="featured-property"
                  /> */}
                  <p className="price">{property.Project_Type_Subcategory_1}</p>
                  <SmallCarousel
                    Images={property.Images}
                    style={{
                      width:
                        screenwidth <= 510
                          ? screenwidth-20  + "px"
                          : null,
                    }}
                  />
                  {property.writerDetails.Membership ?
                    <div className="prime-project-container">
                      <img className="prime-project-logo" src={PrimeProjectCover} alt="prime-project-logo" />
                    </div>
                      : null
                    }
                </div>
                <div style={{
                  width:
                    screenwidth <= 510
                      ? screenwidth - 20 + "px"
                      : null,
                }} className="middle">
                  <div className="address">
                    <p className="left-side">Address :</p>
                    <p style={{
                      color: "#fe5631"
                    }} className="right-side">
                      {" "}
                      {property.Address.House_Building_Plot_Land},{" "}
                      {property.Address.State}, {property.Address.City},{" "}
                      {property.Address.Areaname}
                    </p>
                  </div>
                  <div className="address">
                    <p className="left-side">Project Name :</p>
                    <p style={{
                      fontSize: "19.5px"
                    }} className="right-side">
                      {/* {" "}
                              {property.Address.House_Building_Plot_Land},{" "}
                              {property.Address.State}, {property.Address.City},{" "}
                              {property.Address.Areaname} */}
                      {property.Project_Name}
                    </p>
                  </div>
                  <div className="address">
                    <p className="left-side">Service Professional Name :</p>
                    <p className="right-side">
                    {property.WriterDetails.Service_Professional_Name}
                    </p>
                  </div>
                  <div className="address">
                    <p className="left-side">Company Name :</p>
                    <p className="right-side">
                    {property.WriterDetails.Company_Name}
                    </p>
                  </div>
                  <div className="address">
                    <p className="left-side">Project No :</p>
                    <p style={{
                      color: "#fe5631"
                    }} className="right-side">
                      {property._id}
                    </p>
                  </div>
                  <div className="address">
                    <p className="left-side">Project Type :</p>
                    <p className="right-side">
                      {property.Project_Type_Subcategory_2}
                    </p>
                  </div>
                  {/* <h2 className="property-title">
                            {property.Project_Name}
                          </h2> */}
                  {/* <p className="apartmentandsize">
                            {"Apartment"}&nbsp;({property.Project_Area} sq.ft.)
                          </p> */}
                  <div
                    style={{
                      width:
                        screenwidth <= 510
                          ? screenwidth - 20 + "px"
                          : null,
                    }}
                    className="other-detailss-container"
                  >
                    <p>{property.Service_Requirement}</p>
                    <div className="border"></div>
                    <p>Year of Completion: {property.Project_Completion}</p>
                    <div className="border"></div>
                    <p> No. of Images: {property.Images.length}</p>
                  </div>
                </div>
                <div className="bottom">
                  <div className="rating">
                    {/* <div className="stars"> */}
                    {/* <span className="material-icons">star</span> */}
                    {/* <span className="material-icons">star</span> */}
                    {/* <span className="material-icons">star</span> */}
                    {/* <span className="material-icons">star</span> */}
                    {/* <span className="material-icons">star</span> */}
                    {/* </div> */}
                    {/* <div className="rating-name"> */}
                    {/* {" "} */}
                    {/* {5 > 4 ? "Excellent" : "Average"} */}
                    {/* Posted on: {property.Posted_Date} */}
                    {/* </div> */}
                    {/* <img className="verified-icon" src={Verified} alt="verified" /> */}
                    Uploaded on: {property.Uploaded_Date}
                  </div>
                  <div className="knowmore-btn">
                    <Button
                      width="150px"
                      name="KNOW DETAILS"
                      directto={`/ServiceProfessional/ProjectsDetails/${property._id}`}
                      background="#201c2d"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ display: props.btn }} className="viewallapartment">
        <Button
          width="250px"
          background="#fe5631"
          name="VIEW ALL PROJECTS"
          directto="/projects"
        />
      </div>
    </div>
  );
}
