import axios from "axios";
import {
  LOGIN_COMPANY,
  REGISTER_COMPANY,
  AUTH_COMPANY,
  LOGOUT_COMPANY,
  ADD_TO_CART_USER,
  GET_CART_ITEMS,
  REMOVE_CART_ITEM_USER,
  ON_SUCCESS_BUY_USER,
  ON_SUBSCRIPTION_PAYMENT_SUCCESS,
  ON_SUBSCRIPTION_PAYMENT_FAILED,
  UPLOAD_COVER_PROFILE_LOGO,
  SP_UPLOAD_PROFILE_DETAILS,
  REMOVE_COVER_IMAGE,
  REMOVE_PROFILE_IMAGE,
  REMOVE_LOGO_IMAGE,
  VIEW_ALL_SERVICE_PROFESSIONAL,
  SP_PHONENUMBER_VERIFICATION,
  SP_OTP_VERIFY_PHONENUMBER,
  SERVICE_PROFESSIONAL_FORGOT_PASSWORD,
  SERVICE_PROFESSIONAL_RESET_PASSWORD,
  DOWNLOAD_PRODUCT_DETAILS_PDF,
  ON_SERVICE_PROFESSIONAL_PROFILE_OTHERVIEW,
  SUBSCRIPTION_PAYMENT_VERIFICATION,
  ON_SUCCESS_BUY_INDIVIDUAL_LEADS,
  REPLACEMENT_LEAD,
  ON_SUCCESS_BUY_COMMUNITY_MEMBERSHIP,
  ON_SUCCESS_BUY_PRIME_MEMBERSHIP,
  ON_PRIME_PAYMENT_FAILED,
  SERVICE_PROFESSIONAL_ADMIN_DETAILS,
  UPDATE_SERVICE_PROFESSIONAL_REPLACEMENT,
  UNSUBSCRIBED_FOR_EMAIL_NOTIFICATION,
} from "./types";
import { COMPANY_SERVER, SUBSCRIPTION_SERVER } from "../Config";

export function registerCompany(dataToSubmit) {
  const request = axios
    .post(`${COMPANY_SERVER}/company_register`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: REGISTER_COMPANY,
    payload: request,
  };
}

export function loginCompany(dataToSubmit) {
  const request = axios
    .post(`${COMPANY_SERVER}/company_login`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: LOGIN_COMPANY,
    payload: request,
  };
}
export function loginCompanyGoogle(googleData) {
  // console.log("Google login");
  const request = axios
    .post(`${COMPANY_SERVER}/companygoogleLogin`, googleData)
    .then((response) => response.data);

  return {
    type: LOGIN_COMPANY,
    payload: request,
  };
}

export function company_auth() {
  const request = axios
    .get(`${COMPANY_SERVER}/company_auth`)
    .then((response) => response.data);

  return {
    type: AUTH_COMPANY,
    payload: request,
  };
}

export function logoutCompany() {
  const request = axios
    .get(`${COMPANY_SERVER}/logout`)
    .then((response) => response.data);

  return {
    type: LOGOUT_COMPANY,
    payload: request,
  };
}

export function addToCart(_id) {
  const request = axios
    .get(`${COMPANY_SERVER}/addToCart?productId=${_id}`)
    .then((response) => response.data);

  return {
    type: ADD_TO_CART_USER,
    payload: request,
  };
}

export function getCartItems(cartItems, companyCart) {
  const request = axios
    .get(`/api/product/products_by_id?id=${cartItems}&type=array`)
    .then((response) => {
      //Make CartDetail inside Redux Store
      // We need to add quantity data to Product Information that come from Product Collection.

      companyCart.forEach((cartItem) => {
        //something to be changed for not showing details in react extenstion
        response.data.forEach((productDetail, i) => {
          if (cartItem.id === productDetail._id) {
            response.data[i].quantity = cartItem.quantity;
          }
        });
      });

      return response.data;
    });

  return {
    type: GET_CART_ITEMS,
    payload: request,
  };
}

export function removeCartItem(id) {
  const request = axios
    .get(`/api/company/removeFromCart?_id=${id}`)
    .then((response) => {
      response.data.cart.forEach((item) => {
        response.data.cartDetail.forEach((k, i) => {
          if (item.id === k._id) {
            response.data.cartDetail[i].quantity = item.quantity;
          }
        });
      });
      return response.data;
    });

  return {
    type: REMOVE_CART_ITEM_USER,
    payload: request,
  };
}

export function onSuccessBuy(data) {
  const request = axios
    .post(`${COMPANY_SERVER}/successBuy`, data)
    .then((response) => response.data);

  return {
    type: ON_SUCCESS_BUY_USER,
    payload: request,
  };
}

export function onBuyIndividual(data, paymentData) {
  const request = axios
    .post(`${COMPANY_SERVER}/BuyIndividualLeads`, data, paymentData)
    .then((response) => response.data);

  return {
    type: ON_SUCCESS_BUY_INDIVIDUAL_LEADS,
    payload: request,
  };
}

export function onSubscriptionPackageBuy(data) {
  // console.log("DATA IN USER ACTION", data);

  const request = axios
    .post(`${COMPANY_SERVER}/companySubscriptionSuccess`, data)
    .then((response) => response.data);

  return {
    type: ON_SUBSCRIPTION_PAYMENT_SUCCESS,
    payload: request,
  };
}

export function onSubscriptionPackageBuyFailed(data) {
  const request = axios
    .post(`${COMPANY_SERVER}/companySubscriptionFailed`, data)
    .then((response) => response.data);

  return {
    type: ON_SUBSCRIPTION_PAYMENT_FAILED,
    payload: request,
  };
}

export function onPrimeMembershipBuyFailed(data) {
  const request = axios
    .post(`${COMPANY_SERVER}/companyPrimePaymentFailed`, data)
    .then((response) => response.data);

  return {
    type: ON_PRIME_PAYMENT_FAILED,
    payload: request,
  };
}

export function UploadCoverProfileLogo(formData, config) {
  const request = axios
    .post(`${COMPANY_SERVER}/UploadCoverProfileLogo`, formData, config)
    .then((response) => response.data);

  return {
    type: UPLOAD_COVER_PROFILE_LOGO,
    payload: request,
  };
}

export function SPUpdateProfileDetails(dataToSubmit) {
  // console.log("Action Data To Send", dataToSubmit);
  const request = axios
    .post(`${COMPANY_SERVER}/UpdateCompanyProfile`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: SP_UPLOAD_PROFILE_DETAILS,
    payload: request,
  };
}

export function RemoveCoverImage(dataToSubmit) {
  const request = axios
    .post(`${COMPANY_SERVER}/RemoveCoverImage`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: REMOVE_COVER_IMAGE,
    payload: request,
  };
}
export function RemoveProfileImage(dataToSubmit) {
  const request = axios
    .post(`${COMPANY_SERVER}/RemoveProfileImage`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: REMOVE_PROFILE_IMAGE,
    payload: request,
  };
}
export function RemoveLogoImage(dataToSubmit) {
  const request = axios
    .post(`${COMPANY_SERVER}/RemoveLogoImage`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: REMOVE_LOGO_IMAGE,
    payload: request,
  };
}
export function GetAllServicePreofessional(variables) {
  // console.log("Action Get All Service Preofessional", variables);
  const request = axios
    .post(`${COMPANY_SERVER}/GetAllServicePreofessional`, variables)
    .then((response) => response.data);

  return {
    type: VIEW_ALL_SERVICE_PROFESSIONAL,
    payload: request,
  };
}

export function SP_Verify_PhoneNumber(variable) {
  // console.log("Action Verify Service Preofessional Number", variable);
  const request = axios
    .post(`${COMPANY_SERVER}/companyOTPVerifyPhoneNumber`, variable)
    .then((response) => response.data);

  return {
    type: SP_PHONENUMBER_VERIFICATION,
    payload: request,
  };
}

export function SP_Verify_PhoneNumber_ViaOTP(variable) {
  // console.log("Action Verify Service Preofessional Phone Number Via OTP", variable);
  const request = axios
    .post(`${COMPANY_SERVER}/companyVerifyPhoneNumberViaOTP`, variable)
    .then((response) => response.data);

  return {
    type: SP_OTP_VERIFY_PHONENUMBER,
    payload: request,
  };
}

export function SP_ForgotPasswordAction(variable) {
  // console.log("Service Preofessional Forgot Password Action", variable);
  const request = axios
    .post(`${COMPANY_SERVER}/forgotPassword`, variable)
    .then((response) => response.data);

  return {
    type: SERVICE_PROFESSIONAL_FORGOT_PASSWORD,
    payload: request,
  };
}

export function SP_ResetPasswordAction(variable) {
  // console.log("Service Preofessional Reset Password Action", variable);
  const request = axios
    .post(`${COMPANY_SERVER}/resetcompany/token`, variable)
    .then((response) => response.data);

  return {
    type: SERVICE_PROFESSIONAL_RESET_PASSWORD,
    payload: request,
  };
}

export function DownloadLeadPDF(dataToSubmit) {
  // console.log("Download Lead PDF Action ");
  const request = axios
    .get(`${COMPANY_SERVER}/downloadLeadPDF?LeadId=${dataToSubmit.LeadId}&UserId=${dataToSubmit.UserID}`)
    .then((response) => response.data);

  return {
    type: DOWNLOAD_PRODUCT_DETAILS_PDF,
    payload: request,
  };
}

export function ServiceProfessionalProfile_Otherview(profileId) {
  // console.log("Service Preofessional Profile Otherview Overview", profileId);
  const request = axios.get(`${COMPANY_SERVER}/ServicePreofessionalProfile_OtherView?profileID=${profileId}`)
    .then((response) => response.data);

  return {
    type: ON_SERVICE_PROFESSIONAL_PROFILE_OTHERVIEW,
    payload: request,
  };
}

export function replacementLead(OrderId, leadId, planName, dateTime) {
  const request = axios.get(`${COMPANY_SERVER}/replacementLead?OrderId=${OrderId}&leadId=${leadId}&planName=${planName}&dateTime=${dateTime}`)
    .then((response) => response.data.data);

  return {
    type: REPLACEMENT_LEAD,
    payload: request,
  };
}

// export function communityMembershipSuccess(data) {
//   const request = axios.post(`${COMPANY_SERVER}/communityPaymentSuccess`, data)
//     .then((response) => response.data.data);

//   return {
//     type: ON_SUCCESS_BUY_COMMUNITY_MEMBERSHIP,
//     payload: request,
//   };
// }

export function primeMembershipSuccess(data) {
  const request = axios.post(`${COMPANY_SERVER}/membershipPaymentSuccess`, data)
    .then((response) => response.data.data);

  return {
    type: ON_SUCCESS_BUY_PRIME_MEMBERSHIP,
    payload: request,
  };
}

// export function subscriptionPaymentVerification(paymentDetails) {
//   const request = axios.post(`${SUBSCRIPTION_SERVER}/payment/success`, paymentDetails)
//     .then((response) => response.data);

//   return {
//     type: SUBSCRIPTION_PAYMENT_VERIFICATION,
//     payload: request,
//   };
// }

export function getAllServicePreofessionalAdminDetails() {
  const request = axios.get(`${COMPANY_SERVER}/getAllServiceProfessionalsAdminDetails`)
    .then((response) => response.data);

  return {
    type: SERVICE_PROFESSIONAL_ADMIN_DETAILS,
    payload: request,
  };
}

export function updateSPForReplacement(Id, leadLeft, time) {
  const request = axios.get(`${COMPANY_SERVER}/updateSPForReplacement?spId=${Id}&leadLeft=${leadLeft}&time=${time}`)
    .then((response) => response.data);

  return {
    type: UPDATE_SERVICE_PROFESSIONAL_REPLACEMENT,
    payload: request,
  };
}

export function unsubscribeForEmailNotification(email) {
  const request = axios.get(`${COMPANY_SERVER}/unsubscribeForEmailNotification?email=${email}`)
    .then((response) => response.data);

  return {
    type: UNSUBSCRIBED_FOR_EMAIL_NOTIFICATION,
    payload: request,
  };
}