import React from "react";
import { useLayoutEffect } from "react";
import PageBanner from "../../utils/PageBanner/PageBanner";
import "./About.css";
import Achievements from "./Achievements/Achievements";
import Ceo from "./Ceo/Ceo";
import Facilites from "./Facilities/Facilities";
// import FeatureAgents from "./FeatureAgents/FeatureAgents";

export default function About() {

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    })

  return (
    <div className="about-container">
      <PageBanner
        title="About Us"        
      />
      <div className="about-parts">
      <Ceo />
      <Facilites/>
      <Achievements/>
      {/* <FeatureAgents/> */}
      
      </div>

    </div>
  );
}
