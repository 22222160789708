import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SP_DeleteProject,
  SP_EditPreviouslyUploadedProjects,
} from "../../../../../../../_actions/project_actions";
import LoadingIcon from "../../../../../../utils/LoadingIcon/LoadingIcon";
// import Edit from "./Views/Edit";
import EditProject from "./Views/EditProject/EditProject";
import "./EditPreviouslyUploadedProjectDetailedPage.css"
import { withRouter } from "react-router-dom";
// import ProjectOverview from "./Views/ProjectOverview/ProjectOverview";

function EditPreviouslyUploadedProjectDetailedPage(props) {
  const dispatch = useDispatch();
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [EditProjectState, setEditProjectState] = useState(false);
  const [ShowLoadingIcon, setShowLoadingIcon] = useState(true);

  useEffect(() => {
    // console.log("Project ID:- ", props.match.params.ProjectID);
    dispatch(
      SP_EditPreviouslyUploadedProjects(props.match.params.ProjectID)
    ).then((response) => {
      // console.log("Response", response);
      setProjectDetails(response.payload.project[0]);
      setShowLoadingIcon(false);
    });
  }, []);

  const DeleteProject = (ProjectID) => {
    // console.log("Delete Project", ProjectID);
    dispatch(SP_DeleteProject(ProjectID, ProjectDetails.Images)).then(
      (response) => {
        if(response.payload.success) {
            props.history.push("/ServiceProfessional/MyProfile");
        } else {
          alert("Unable to delete project. Please try again later.");
        }
        console.log("Response", response);
      }
    );
  };
  
  return (
    <div className="project-overview-and-edit-container">
      {ShowLoadingIcon ? (
        <LoadingIcon />
      ) : (
        <>
        <div className="edit-and-delete-project-btns">
           <button
           style={{
             display : !EditProjectState ? "block" : "none"
           }}
                       className="delete-btn"
            onClick={() => DeleteProject(props.match.params.ProjectID)}
          >
            Delete Project
          </button>
          <br />
          <button
          className="edit-btn"
            // onClick={() => setEditProjectState(!EditProjectState)}
            onClick={() => props.history.push(`/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/${props.match.params.ProjectID}`)}
          >
            {!EditProjectState ? "Back" : "Edit Project"}
          </button>
        </div>
         
          {/* {
            EditProjectState ? (
              <ProjectOverview ProjectDetails={ProjectDetails} />
            ) : 
              // <Edit ProjectDetails={ProjectDetails} />
             */}
            <EditProject ProjectDetails={ProjectDetails} ProjectID={props.match.params.ProjectID} />
          {/* } */}
        </>
      )}
    </div>
  );
}

export default withRouter(EditPreviouslyUploadedProjectDetailedPage);
