const Plans = [
  {
    Name: "NebourHood Prime S.P. 3 Months",
    Falseprice: "₹ 1998",
    Price: "₹ 999 + G.S.T.",
    Validity: "Validity: 2 Months + 1 Extra FREE Month",
    Features: [],
  },
  {
    Name: "NebourHood Prime S.P. 6 Months",
    Falseprice: "₹ 2,997",
    Price: "₹ 1,998 + G.S.T.",
    Validity: "Validity: 4 Months + 2 Extra FREE Months",
    Features: [],
  },
  {
    Name: "NebourHood Prime S.P. 9 Months",
    Falseprice: "₹ 3,996",
    Price: "₹ 2,997 + G.S.T.",
    Validity: "Validity: 6 Months + 3 Extra FREE Months",
    Features: [],
  },
  {
    Name: "NebourHood Prime S.P. 1 Year",
    Falseprice: "₹ 4,995",
    Price: "₹ 3,996 + G.S.T.",
    Validity: "Validity: 8 Months + 4 Extra FREE Months",
    Features: [],
  }
];

module.exports.Plans = Plans;