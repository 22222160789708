import React from "react";
import "./SP_ProfileImg.css";
import { withRouter } from "react-router-dom";
import LoadingIcon from "../../../../../../../utils/LoadingIcon/LoadingIcon";
import maleProfile from '../../../../../../../asserts/ProfileImages/maleProfile.jpg'
import femaleProfile from '../../../../../../../asserts/ProfileImages/femaleProfile.jpg'

function SP_ProfileImg(props) {
  const loading = false;
  // const [ImagesURL, setImagesURL] = useState(props.Image_Src);
  // const [loading, setloading] = useState(false);

  return (
    <div className="profile-img-container">
      {loading ? (
          <LoadingIcon
            position="absolute"
            width={"100%"}
            height={"100%"}
            outerwidth={"50px"}
            outerheight={"50px"}
            innerdisplay={"none"}
            backgroundcolor={"rgb(56, 56, 56,0.5)"}
          />
        ) : (
          <div className="profile-img">
          {props.Image_Src ? 
            <img src={props.Image_Src} alt="profile-img" />
            :
            <>
            {props.userGender == "Female" ?
              <img src={femaleProfile} alt="profile-img" />
            :
              <img src={maleProfile} alt="profile-img" />
            }
            </>
          }
          </div>
        )
      }
    </div>
  );
}

export default withRouter(SP_ProfileImg);
