import React from 'react'
import "./CommunityLink.css"

const CommunityLink = (props) => {

  function openHelp() {
    window.open(`https://wa.me/+918494804605`, '_blank');
  }

  return (
        <div className='community-link-required-modall'>
          <p className='header'>NebourHood Prime SERVICE PROFESSIONAL ORDER CONFIRMATION & SUCCESS PAGE</p>
          <p>Thank you for placing your order with us! We received your order and have completed it successfully.</p>
          <p>Kindly click the button below to join the exclusive NebourHood Prime S.P. Member WhatsApp community.</p>
            <a href="https://chat.whatsapp.com/EzfTETSyGrhF8JQqK6FwN5" target='_blank'>Click Here to Join our Community</a>
            {props.ShowButton ? 
            <a href={props.directto}>{props.ButtonTitle}</a>
            :
            null}
            <p className='footer'></p>

            <p style={{ color: '#fe5631' }}>Please Note:</p>
            <ul style={{ color: 'black', textAlign: 'left' }}>
              <li>This link will disappear within 1 minute.</li>
              <li>The joining link will not be shared again.</li>
              <li>If you completed the payment but could not join the WhatsApp community, you can <span onClick={()=>openHelp()} style={{ color: '#1F80E0' }}>WhatsApp us from here.</span></li>
              <li>Our team will verify your information and add you to the community if your details are accurate.</li>
            </ul>
        </div>
  )
}

export default CommunityLink